import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createXpath, deleteOneXpath, fetchAllXpaths, updateXpath} from "../../../actions";
import SourceXpathForm from "./sourceXpathForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createXpath,
            updateXpath,
            deleteOneXpath,
            fetchAllXpaths
        },
        dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(SourceXpathForm);
