import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    deleteCustomerSource,
    deletePredictoSource,
    fetchCustomerSources,
    getCustomerPredictoSourceDetails
} from "../../../../../actions";
import PredictionSources from "./predictionSources";

const mapStateToProps = (state, ownProps) => ({
    predictoData: state.customer.list[ownProps.currentCustomer.id].predictoSourceData,
    sourcePermission: state.auth.permissions && state.auth.permissions.customer.sources.create,
    customerSource: state.customer.list[ownProps.currentCustomerId] && state.customer.list[ownProps.currentCustomerId].sources,
    customer: state.customer.list[ownProps.currentCustomerId],
    sourceDelete: state.auth.permissions && state.auth.permissions.customer.sources.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerSources,
            deleteCustomerSource,
            deletePredictoSource,
            getCustomerPredictoSourceDetails,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PredictionSources);
