import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAllCustomers, saveCustomer} from "../../../actions/index";
import PropertiesForm from "./propertiesForm";

const mapStateToProps = (state) => ({
    customerList: state.customer.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            saveCustomer,
            fetchAllCustomers
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesForm);
