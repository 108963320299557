import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';

import {PROPERTY_REPORT_SUBSCRIPTION,} from '../../../../../constants/appConstants';
import {formatDate} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents/index';
import AlertBox from '../../../alertBox';
import CustomerReportSubscriptionForm from '../../../customerReportSubscriptionForm';

class CustomerReportSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportTemplateModal: false,
            reportSubscriptionGridData: [],
            loader: true,
            colDef: [
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.VIEW_SUBSCRIPTION_NAME,
                    field: "subscription_name",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.VIEW_REPORT_NAME,
                    field: "report_name",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.VIEW_DELIVERY_FORMAT,
                    field: "delivery_format",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.VIEW_SCHEDULE,
                    field: "report_periodicity",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.VIEW_DATA_COVERAGE_PERIOD,
                    field: "data_coverage_units&data_coverage_unit_frequency",
                    filterParams: {clearButton: true},
                    valueGetter: this.getDataCoverage
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.LAST_EXECUTION,
                    field: "last_execution_date",
                    filterParams: {clearButton: true},
                    valueGetter: this.getExecutionDate
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.VIEW_STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    valueGetter: this.getStatus
                }
            ]
        };
    }

    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    getDataCoverage = (params) => {
        return params.data.data_coverage_units + ' ' + params.data.data_coverage_unit_frequency;
    };

    getExecutionDate = (params) => {

        return params.data.last_execution_date ? formatDate(params.data.last_execution_date) : "";
    };

    getStatus = (params) => {
        if (params.data.status === APPLICATION_MODES.ACTIVE) {
            return APPLICATION_MODES.ACTIVE;
        } else {
            return APPLICATION_MODES.INACTIVE;
        }
    };

    _addReportSubscription = () => {
        this.setState({
            selectedReportSubscriptionData: [],
            applicationMode: APPLICATION_MODES.CREATE,
            title: PROPERTY_REPORT_SUBSCRIPTION.ADD_REPORT_SUBSCRIPTION,
            reportTemplateModal: true,
            customerInformation: this.props.currentCustomer ? this.props.currentCustomer : null
        }, () => {
            this.setState({
                reportTemplateModal: true
            });
        });
    };

    closeModal = () => {
        this.setState({
            reportTemplateModal: false
        });
    };

    _openViewModal = data => {
        this.setState({
            selectedReportSubscriptionData: data[0],
            applicationMode: APPLICATION_MODES.VIEW,
            reportTemplateModal: false,
            title: "",
            customerInformation: this.props.currentCustomer ? this.props.currentCustomer : null
        }, () => {
            this.setState({
                reportTemplateModal: true
            });
        });
    };

    componentDidMount() {
        this.setState({
            loader: true
        });
        this.props.actions.fetchCustomerReportSubscription(this.props.currentCustomerId).then(() => {
            this.setState({
                loader: false
            });

            let reportSubscriptionGridData = [];
            if (this.props.reportSubscriptionLst) {
                if (Array.isArray(this.props.reportSubscriptionLst)) {
                    Object.values(this.props.reportSubscriptionLst).map((data) => {
                        let status = data.status;
                        if (data.status === APPLICATION_MODES.ACTIVE || data.status === APPLICATION_MODES.IN_ACTIVE) {
                            if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && this.props.currentCustomer.is_inapp) {
                                reportSubscriptionGridData.push(data);
                            }
                            if (data.application_name === APPLICATION_MODES.PREDICTO && this.props.currentCustomer.is_predicto) {
                                reportSubscriptionGridData.push(data);
                            }
                        }
                    });
                } else {
                    if (this.props.reportSubscriptionLst.data) {
                        Object.values(this.props.reportSubscriptionLst.data).map((data) => {
                            let status = data.status;
                            if (data.status === APPLICATION_MODES.ACTIVE || data.status === APPLICATION_MODES.IN_ACTIVE) {
                                if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && this.props.currentCustomer.is_inapp) {
                                    reportSubscriptionGridData.push(data);
                                }
                                if (data.application_name === APPLICATION_MODES.PREDICTO && this.props.currentCustomer.is_predicto) {
                                    reportSubscriptionGridData.push(data);
                                }
                            }
                        });
                    }
                }

            }
            this.setState({
                reportSubscriptionGridData
            }, () => {
                const gridData = this.state.reportSubscriptionGridData;
                const noOfActive = gridData.filter(eachItem => eachItem.status === APPLICATION_MODES.ACTIVE);
                this.props.countPropagate({
                    'reportSubscription': {
                        'active': noOfActive.length,
                        'total': gridData.length
                    }
                });
            });
        });
    }

    componentWillReceiveProps(props) {
        let reportSubscriptionGridData = [];
        if (props.reportSubscriptionLst) {
            if (Array.isArray(this.props.reportSubscriptionLst)) {
                Object.values(props.reportSubscriptionLst).map((data) => {
                    let status = data.status;
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.IN_ACTIVE) {
                        if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.currentCustomer.is_inapp) {
                            reportSubscriptionGridData.push(data);
                        }
                        if (data.application_name === APPLICATION_MODES.PREDICTO && props.currentCustomer.is_predicto) {
                            reportSubscriptionGridData.push(data);
                        }
                    }
                });
            } else {
                if (props.reportSubscriptionLst.data) {
                    Object.values(props.reportSubscriptionLst.data).map((data) => {
                        let status = data.status;
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.IN_ACTIVE) {
                            if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.currentCustomer.is_inapp) {
                                reportSubscriptionGridData.push(data);
                            }
                            if (data.application_name === APPLICATION_MODES.PREDICTO && props.currentCustomer.is_predicto) {
                                reportSubscriptionGridData.push(data);
                            }
                        }
                    });
                }
            }
        }
        this.setState({
            reportSubscriptionGridData
        }, () => {
            const gridData = this.state.reportSubscriptionGridData;
            const noOfActive = gridData.filter(eachItem => eachItem.status === APPLICATION_MODES.ACTIVE);
            this.props.countPropagate({'reportSubscription': {'active': noOfActive.length, 'total': gridData.length}});
        });
    }

    render() {
        return (
            <Fragment>
                {/* {this.props.responsePermission === APP_BOOLEANS.TRUE && ( */}
                <TatvamButton
                    className="mt-1 ml-2 mr-2 right btn_primary"
                    name="addRole"
                    onClick={this._addReportSubscription}
                >
                    {PROPERTY_REPORT_SUBSCRIPTION.ADD_REPORT_SUBSCRIPTION}
                </TatvamButton>
                {/* )}  */}

                {
                    this.state.reportTemplateModal && (
                        <TatvamModal
                            open={this.state.reportTemplateModal}
                            className="hideFooter"
                            header={this.state.title}
                            options={{dismissible: false}}
                            fixedFooter={false}
                            children={(
                                <CustomerReportSubscriptionForm
                                    applicationMode={this.state.applicationMode}
                                    closeModal={this.closeModal}
                                    currentCustomerId={this.props.currentCustomerId}
                                    selectedReportSubscriptionData={this.state.selectedReportSubscriptionData}
                                    customerInformation={this.state.customerInformation}
                                />
                            )}
                        />
                    )}

                {this.state.reportSubscriptionGridData && this.state.reportSubscriptionGridData.length > 0 ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.reportSubscriptionGridData}
                            colData={this.state.colDef}
                            enableRowClick
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.responseDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                        />

                    </TatvamCol>
                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {PROPERTY_REPORT_SUBSCRIPTION.NO_REPORT_SUBSCRIPTION_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default CustomerReportSubscription;
