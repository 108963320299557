import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createCustomerAlertRules, deleteCustomerAlertRules, fetchCustomerAlertRules} from "../../../../../actions";
import Rules from "./rules";

const mapStateToProps = (state, ownProps) => ({
    gridData: state.customer.list[ownProps.currentCustomerId].rules,
    rulePermission: state.auth.permissions && state.auth.permissions.customer.rules.create,
    ruleDelete: state.auth.permissions && state.auth.permissions.customer.rules.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerAlertRules,
            createCustomerAlertRules,
            deleteCustomerAlertRules
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
