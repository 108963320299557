import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';

import {ALERT_MESSAGES, PROPERTY,} from '../../../../../constants/appConstants';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import PropertiesForm from '../../../propertiesForm';

class Property extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyModal: false,
            colDef: [{headerName: PROPERTY.VIEW_PROPERTY_ID, field: "id", filterParams: {clearButton: true}},
                {headerName: PROPERTY.VIEW_PROPERTY_NAME, field: "name", filterParams: {clearButton: true}},
                {headerName: PROPERTY.STATUS, field: "status", filterParams: {clearButton: true}}
            ],
            propertyList: [],
            loader: true
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: this.props.currentCustomerId,
            id: data.id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.id;
            let propertyDeleteData = {
                customer_id,
                id

            };
            this.props.actions.deleteCustomerProperty(propertyDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _addProperty = () => {
        this.setState({
            propertyModal: true,
            title: PROPERTY.ADD_PROPERTY

        });
    };
    __onEditRowClick = (data) => {
        this.props.history.push({
            pathname: "/customerdetails",
            state: {
                applicationMode: APPLICATION_MODES.VIEW,
                id: data[0].id
            }
        });
    };
    __onDeleteRowClick = () => {
    };
    closeModal = () => {
        this.setState({
            propertyModal: false

        });
    };

    componentDidMount() {

        let propertyList = [];
        this.setState({
            loader: true
        });
        if (this.props.category !== "Competitor" && this.props.category !== "Tenants") {
            if (this.props.gridData) {
                this.props.gridData.map((data) => {
                    if (this.props.customerList[data]) {
                        propertyList = [...propertyList, {
                            id: this.props.customerList[data].id,
                            name: this.props.customerList[data].name,
                            status: this.props.customerList[data].status
                        }];
                    }
                });
            }
        } else {
            if (this.props.currentCustomerId && this.props.category) {
                let customerData = {customerId: this.props.currentCustomerId, type: this.props.category};
                this.props.actions.fetchCompetitorTenantCustomers(customerData).then(() => {
                    if (this.props.competitorTenantCustomerList && this.props.competitorTenantCustomerList.competitorTenantCustomers) {
                        this.props.competitorTenantCustomerList.competitorTenantCustomers.map((data) => {
                            propertyList = [...propertyList, {
                                id: data.id,
                                name: data.name,
                                status: data.status
                            }];
                        });
                    }
                });
            }
        }


        this.setState({
            propertyList,
            loader: false
        }, () => {

            const gridData = this.state.propertyList;
            const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
            this.props.countPropagate({'propertySource': {'active': noOfActive.length, 'total': gridData.length}});
        });
    }

    componentWillReceiveProps(props) {
        let propertyList = [];
        if (this.props.category !== "Competitor" && this.props.category !== "Tenants") {
            if (props.gridData) {
                props.gridData.map((data) => {
                    if (props.customerList[data])
                        propertyList = [...propertyList, {
                            id: props.customerList[data].id,
                            name: props.customerList[data].name,
                            status: props.customerList[data].status
                        }];
                });
            }
        } else {
            if (props.competitorTenantCustomerList && props.competitorTenantCustomerList.competitorTenantCustomers) {
                props.competitorTenantCustomerList.competitorTenantCustomers.map((data) => {
                    propertyList = [...propertyList, {
                        id: data.id,
                        name: data.name,
                        status: data.status
                    }];
                });
            }
        }


        this.setState({
            propertyList,
            loader: false
        }, () => {

            const gridData = this.state.propertyList;
            const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
            this.props.countPropagate({'propertySource': {'active': noOfActive.length, 'total': gridData.length}});
        });
    }

    render() {
        return (
            <Fragment>
                {this.props.propertyPermission === APP_BOOLEANS.TRUE && (this.props.category !== "Competitor" && this.props.category !== "Tenants") && (
                    <TatvamButton className="mt-2 ml-2 mr-2 right btn_primary" onClick={this._addProperty}>
                        {PROPERTY.ADD_PROPERTY}
                    </TatvamButton>
                )}
                {this.state.propertyModal && (
                    <TatvamModal
                        open={this.state.propertyModal}
                        header={this.state.title}
                        options={{dismissible: false}}
                        fixedFooter={false}
                        className="hideFooter"
                        children={(
                            <PropertiesForm
                                closeModal={this.closeModal}
                                customerId={this.props.currentCustomerId}
                                selectedPropertyData={this.state.propertyList}
                                parent_customer_id={this.props.parent_customer_id}
                            />
                        )}
                    />
                )}
                {this.state.propertyList.length > 0 ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.propertyList}
                            colData={this.state.colDef}
                            enableDeleteColumn={(this.props.category && this.props.category !== "Competitor" && this.props.category !== "Tenants" && this.props.propertyDelete === APP_BOOLEANS.TRUE) ? true : false}
                            onRowDelete={this._handleDeleteConfirmation}
                        />
                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {(this.props.category && this.props.category !== "Competitor" && this.props.category !== "Tenants") ? PROPERTY.NO_PROPERTY_AVAILABLE : PROPERTY.NO_LINKED_PROPERTY_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Property;
