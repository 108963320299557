import React from "react";
import {BaseDatePicker} from "../../baseComponents";

const TatvamDatePicker = (props) => {
    return (
        <BaseDatePicker {...props} />
    )
}

export default TatvamDatePicker;
