const initialState = {
    list: {},
    error: {}
};

const SubscriptionReportsReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "GET_SUBSCRIPTION_REPORTS_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;

        case "GET_SUBSCRIPTION_REPORTS_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;

        default:
            return state;
    }
};

export default SubscriptionReportsReducer;

