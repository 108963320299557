export function authHeaderInitial() {
    const token = localStorage.getItem("token");
    if (token) {
        return {"Authorization": `Bearer ${token}`};
    }
    return {};

}

export function authHeaderFinal() {
    const token = localStorage.getItem("token");
    const finaltoken = localStorage.getItem("finaltoken");
    if (token && finaltoken) {
        return {"Authorization": `Bearer ${finaltoken}`};
    }
    return {};

}

export function xrapterBounds() {
    const bounds = localStorage.getItem("bounds");
    if (bounds) {
        return {"x-rapter-bounds": localStorage.getItem("bounds")};
    }
    return {};

}

export function finalToken() {
    const finaltoken = localStorage.getItem("finaltoken");
    if (finaltoken) {
        return finaltoken;
    }
    return {};

}

export function getAuthToken() {

}
