import {apiUrl} from 'apiConstants';
import axios from 'axios';

const actions = {
    GetAllReportsSuccess: (payload) => ({
        type: "FETCH_REPORTS_SUCCESS",
        payload
    }),
    GetAllReportsError: (payload) => ({
        type: "FETCH_REPORTS_ERROR",
        payload
    })
};

export const fetchAllReports = () => (dispatch, getState) => {
    const state = getState();
    if (state.reports.list.length > 0) {
        return Promise.resolve(true);
    }
    const url = apiUrl.BASE_URL + apiUrl.GET_REPORTS;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetAllReportsSuccess(response.data.data));
                return response;
            }


            dispatch(actions.GetAllReportsError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetAllReportsError(error.message));
            return error.message;
        });
};
