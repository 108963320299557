import './userImport.scss';

import React, {Fragment} from 'react';

import {CSVLink} from 'react-csv';
import Loader from 'react-loaders';
import {Col, Row,} from 'react-materialize';

import {Button, Typography, withStyles,} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import {FILE_UPLOAD_CONSTANTS} from '../../../constants/appConstants';
import {TatvamButton, TatvamCol,} from '../../functionalComponents/index';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

class UserImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
            sizeExceedMessage: "",
            selectedFileName: undefined,
            disableFileUpload: true,
            selectedCategory: [],
            categoryListsOptions: [],
            preloader: false,
            isLoaded: true,
            errorData: [],
            validFileMessage: ""
        };

        this.selectFile = this.selectFile.bind(this);
        this.upload = this.upload.bind(this);
        this.reset = this.reset.bind(this);
    }


    componentDidMount() {

    }

    componentWillReceiveProps(props) {

    }

    selectFile(event) {
        var fileSize = event.target.files[0] ? event.target.files[0].size / 1024 : 0;
        var filename = event.target.files[0].name.toLowerCase();
        var reg = /(.*?)\.(csv)$/;
        var hasValidFileExtension = !filename.match(reg) ? false : true;
        this.setState({
            progress: 0,
            selectedFiles: event.target.files[0],
            sizeExceedMessage: fileSize > 1024 ? FILE_UPLOAD_CONSTANTS.FILE_SIZE_EXCEED : "",
            message: "",
            selectedFileName: event.target.files[0] ? FILE_UPLOAD_CONSTANTS.SELECTED_FILE_NAME + event.target.files[0].name : "",
            disableFileUpload: (fileSize > 1024 || !hasValidFileExtension) ? true : false,
            validFileMessage: !hasValidFileExtension ? FILE_UPLOAD_CONSTANTS.CSV_FILE_ALLOWED : ""
        });
    }


    upload() {
        let uploadData = {};
        uploadData.currentFile = this.state.selectedFiles;
        this.setState({
            preloader: true,
            currentFile: uploadData.currentFile,
            isLoaded: false
        });
        this.props.actions.importUsers(uploadData).then((response) => {

            this.setState({
                message: response.data.message,
                isError: false,
                selectedFileName: "",
                disableFileUpload: true,
                selectedCategory: [],
                preloader: false,
                isLoaded: true,
                errorData: response.data.data && response.data.data.length > 0 ? response.data.data : []
            });
            this.setState({
                selectedFiles: undefined,
            });

            this.props.actions.fetchAllUsers();
        });
    }

    reset() {
        this.setState({
            selectedFiles: undefined,
            progress: 0,
            sizeExceedMessage: "",
            message: "",
            selectedFileName: "",
            errorData: "",
            disableFileUpload: true,
            validFileMessage: ""
        });
    }

    _closeDialog = () => {
        this.props.closeModal();
    }

    _handleCategorySelect = selectedOption => {
        this.setState({
            selectedCategory: selectedOption
        });
    };

    render() {
        return (
            <Fragment>
                <Fragment>
                    <Row
                        className={this.state.isLoaded ? "card-container" : "card-container loader-wait"}
                    >
                        <Col
                            s={12}
                            className={this.state.preloader ? "valign-wrapper loader-overlay" : "hide"}
                        >
                            {this.state.preloader && <Loader type="ball-beat"/>}
                        </Col>
                    </Row>
                </Fragment>
                <Fragment>
                    <TatvamCol className="z-depth-2 centered mt-2 form-wrapper" s={12} m={12} l={12} xl={12}>
                        <div className="page_heading mt-0 ml-1">
                            <h5>Import Users</h5>
                        </div>

                        <TatvamCol s={6} m={6}>
                            {/* <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
              {(
                <Box className="mb25" display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <BorderLinearProgress variant="determinate" value={this.state.progress} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${this.state.progress}%`}</Typography>
                  </Box>
                </Box>)
              }
            </TatvamCol> */}
                            <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
                                <i>Note: Max File Size 1MB. File with .csv extension allowed.</i>
                            </TatvamCol>
                            <TatvamCol s={12} m={12} l={12} xl={12} className="project_name">
                                <label htmlFor="btn-upload">
                                    <input
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{display: 'none'}}
                                        type="file"
                                        accept=".csv"
                                        onChange={this.selectFile}
                                        value={""}/>
                                    <Button
                                        className="btn-choose btn-no-mrg"
                                        variant="outlined"
                                        component="span">
                                        Choose File
                                    </Button>
                                </label>
                                <TatvamButton
                                    waves="light"
                                    className="btn_primary waves ml-2"
                                    disabled={this.state.disableFileUpload}
                                    onClick={this.upload}>
                                    Import
                                </TatvamButton>
                                <TatvamButton
                                    waves="light"
                                    className="btn_primary waves ml-2"
                                    onClick={this.reset}>
                                    Reset
                                </TatvamButton>
                                <TatvamButton
                                    waves="light"
                                    className="btn_primary waves ml-2"
                                    onClick={this._closeDialog}>
                                    Close
                                </TatvamButton>
                            </TatvamCol>
                            <TatvamCol s={12} m={12} l={12} xl={12} className="project_name">
                                <Typography variant="subtitle2" className={`upload-message`}>
                                    {this.state.selectedFileName}
                                </Typography>
                                <Typography variant="subtitle2"
                                            className={`upload-message ${this.state.isError ? "error" : ""}`}>
                                    {this.state.message}
                                </Typography>
                                <Typography variant="subtitle2"
                                            className={`upload-message ${this.state.sizeExceedMessage || this.state.validFileMessage ? "error" : ""}`}>
                                    {this.state.sizeExceedMessage + " " + this.state.validFileMessage}
                                </Typography>

                            </TatvamCol>
                            {this.state.errorData.length > 0 && (
                                <TatvamCol s={12} m={12} l={12} xl={12} className="project_name">
                                    {FILE_UPLOAD_CONSTANTS.DOWNLOAD_ERROR_TEXT} <CSVLink
                                    data={this.state.errorData}
                                    filename={"error-details.csv"}
                                    className={"under-line"}
                                    target="_blank"
                                >
                                    Download
                                </CSVLink>
                                </TatvamCol>
                            )}
                        </TatvamCol>
                    </TatvamCol>
                </Fragment>
            </Fragment>
        );
    }
}

export default UserImport;
