import './customerList.scss';

import React, {Component, Fragment,} from 'react';

import {trimCapitalize} from 'stringUtil';

import {ALERT_MESSAGES, APP_BOOLEANS, APPLICATION_MODES, PROPERTY,} from '../../../constants/appConstants';
import {TatvamGrid} from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerList extends Component {
    constructor(props) {
        const customComparator = (valueA, valueB) => {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };
        const stringToNumComparator = (valueA, valueB) => {
            return Number(valueA) - Number(valueB);
        };
        super(props);
        this.state = {
            colDef: [
                {
                    headerName: PROPERTY.VIEW_PROPERTY_ID,
                    field: "id",
                    comparator: stringToNumComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY.VIEW_PROPERTY_NAME,
                    field: "name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {headerName: PROPERTY.VIEW_CATEGORY, field: "category", filterParams: {clearButton: true}, hide: false},
                {headerName: PROPERTY.VIEW_INDUSTRY, field: "industry", filterParams: {clearButton: true}},
                {headerName: PROPERTY.EXECUTION_BATCH, field: "execution_batch", filterParams: {clearButton: true}},
                {headerName: PROPERTY.VIEW_LICENCE_TYPE, field: "license_type", filterParams: {clearButton: true}, hide: false},
                {headerName: PROPERTY.VIEW_STATUS, field: "status", filterParams: {clearButton: true}},
                {
                    headerName: PROPERTY.VIEW_LICENSE_START_DATE,
                    field: "valid_from",
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        if (data.value !== "") {
                            return data.value.substring(0, 10);
                        } else {
                            return "";
                        }

                    }, hide: false
                },
                {
                    headerName: PROPERTY.VIEW_LICENSE_END_DATE,
                    field: "valid_upto",
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        if (data.value !== "") {
                            return data.value.substring(0, 10);
                        } else {
                            return "";
                        }

                    }, hide: false
                }
            ],
            rowClassRules: {
                'expiry-warning': (params) => {
                    let validUpto_date = params.data.valid_upto.substring(0, 10);
                    if (new Date(validUpto_date) < new Date()) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            customers: []
        };
    }


    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok" && this.props.category !== "Tenants") {
            let customer_id = this.state.idCustomer;
            let customerDeleteData = {
                customer_id,
            };
            this.props.actions.deleteCustomer(customerDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }else if (data === "ok" && this.props.category === "Tenants"){
            let customer_id = this.state.idCustomer;
            let customerDeleteData = {
                customer_id,
            };
            this.props.actions.deleteTenant(customerDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }

    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    componentDidMount() {
        if (this.props.customers && Object.keys(this.props.customers).length > 0) {
            const tempCustomers = [];
            const customerCpy = Object.values(this.props.allCustomers).filter((customerInd) => {
                return (customerInd.category === "Live" || customerInd.category === "Demo") && customerInd.status === "Active"
            });
            for (let data of Object.values(this.props.customers)) {
                if (this.props.category === "Live" || this.props.category === "Demo" || this.props.category === "Competitor") {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate === undefined || trimCapitalize(data.corporate) === APPLICATION_MODES.NO) {

                            tempCustomers.push(data);
                        }

                    }
                } else if (this.props.category === APPLICATION_MODES.GROUP_INSTANCE) {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate !== undefined && trimCapitalize(data.corporate) === APPLICATION_MODES.YES) {
                            tempCustomers.push(data);
                        }
                    }
                } else {
                    if (trimCapitalize(data.status) === APPLICATION_MODES.INACTIVE) {
                        tempCustomers.push(data);
                    }

                }
            }
            this.setState({customers: tempCustomers});
        }
        if (this.props.category === "Competitor") {
            this.setState({
                colDef: [
                    {
                        headerName: PROPERTY.VIEW_PROPERTY_ID,
                        field: "id",
                        comparator: this.stringToNumComparator,
                        filterParams: {clearButton: true}
                    },
                    {
                        headerName: PROPERTY.VIEW_PROPERTY_NAME,
                        field: "name",
                        comparator: this.customComparator,
                        filterParams: {clearButton: true}
                    },
                    {
                        headerName: PROPERTY.VIEW_CATEGORY,
                        field: "category",
                        filterParams: {clearButton: true},
                        hide: false
                    },
                    {headerName: PROPERTY.VIEW_INDUSTRY, field: "industry", filterParams: {clearButton: true}},
                    {headerName: PROPERTY.EXECUTION_BATCH, field: "execution_batch", filterParams: {clearButton: true}},
                    {
                        headerName: PROPERTY.TOTAL_CUSTOMERS, field: "totalCustomers",
                        filterParams: {clearButton: true},
                        filter: "agNumberColumnFilter"
                    },
                    {headerName: PROPERTY.VIEW_STATUS, field: "status", filterParams: {clearButton: true}},
                    {
                        headerName: PROPERTY.LICENSE_START_DATE,
                        field: "valid_from",
                        filterParams: {clearButton: true},
                        cellRenderer: (data) => {
                            if (data.value !== '') {
                                return data.value.substring(0, 10);
                            } else {
                                return "";
                            }

                        }
                    },
                    {
                        headerName: PROPERTY.VIEW_LICENSE_END_DATE,
                        field: "valid_upto",
                        filterParams: {clearButton: true},
                        cellRenderer: (data) => {
                            if (data.value !== '') {
                                return data.value.substring(0, 10);
                            } else {
                                return "";
                            }

                        }
                    }
                ]
            });
        }

        if (this.props.category === "InActive") {
            this.hideCategoryColumn(false);
        } else {
            this.hideCategoryColumn(true);
        }

    }

    componentWillReceiveProps(props) {
        if (Object.keys(props.customers).length > 0) {
            const customerCpy = Object.values(props.allCustomers).filter((customerInd) => {
                return (customerInd.category === "Live" || customerInd.category === "Demo") && customerInd.status === "Active"
            });
            const tempCustomers = [];
            for (let data of Object.values(props.customers)) {
                if (props.category === "Live" || props.category === "Demo" || props.category === "Competitor" || props.category === "Tenants") {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate === undefined || trimCapitalize(data.corporate) === APPLICATION_MODES.NO) {
                            tempCustomers.push(data);
                        }

                        if (data.category === "Competitor") {
                            const matchedCompetitor = customerCpy.filter((customer) => {
                                if (customer.competitors) {
                                    return customer.competitors.includes(data.id)
                                }
                            })
                            data.totalCustomers = matchedCompetitor.length;
                        }
                        if (data.category === "Tenants") {
                            const matchedTenant = customerCpy.filter((customer) => {
                                if (customer.tenants) {
                                    return customer.tenants.includes(data.id)
                                }
                            })
                            data.totalCustomers = matchedTenant.length;
                        }
                    }
                } else if (props.category === APPLICATION_MODES.GROUP_INSTANCE) {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate !== undefined && trimCapitalize(data.corporate) === APPLICATION_MODES.YES) {
                            tempCustomers.push(data);
                        }
                    }
                } else {
                    if (trimCapitalize(data.status) === APPLICATION_MODES.INACTIVE) {
                        tempCustomers.push(data);
                    }

                }
            }
            this.setState({customers: tempCustomers});
        }


        if (props.category === "InActive") {
            this.hideCategoryColumn(false);
        } else {
            this.hideCategoryColumn(true);
        }

    }

    customComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    stringToNumComparator = (valueA, valueB) => {
        return Number(valueA) - Number(valueB);
    };

    hideCategoryColumn = (status) => {
        if (this.state.colDef && this.state.colDef.length > 0) {
            let columns = this.state.colDef;
            var index = columns.findIndex(item => item.field === "category");
            this.state.colDef[index].hide = status;
            this.setState({
                colDef: columns
            });
        }

        if(this.props.category === "Tenants") {
            this.state.colDef.forEach((item, index) => {
                if (item.field === "valid_upto" || item.field === "license_type" || item.field === "valid_from" || item.field === "industry") {
                    this.state.colDef[index].hide = status;
                }
            });
            this.state.colDef.push({
                headerName: 'Industry Classification', 
                field: "industry_classification",
                filterParams: {clearButton: true}
            },
            );
        }
    }

    render() {        
        return (
            <Fragment>

                <TatvamGrid
                    gridData={this.state.customers}
                    colData={this.state.colDef}
                    enableRowClick
                    enablePagination
                    onRowSelect={this.props.editCustomer}
                    enableDeleteColumn={this.props.customerDelete === APP_BOOLEANS.TRUE}
                    onRowDelete={this._handleDeleteConfirmation}
                    rowClassRules={this.state.rowClassRules}
                />
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default CustomerList;
