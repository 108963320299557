const initialState = {};

const ErrorReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }

    if (action.type === "GET_ERROR") {
        state = {
            ...state,
            error: action.payload
        };
    }
    return state;

};

export default ErrorReducer;
