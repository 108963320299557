import {
    populateDefaultConfiguration,
    populateDefaultFilter,
    populateFilterPanelControl,
} from '../components/applicationComponents/reportSubscriptionFIlter/shared/utility/filter.utility';

const initialState = {
    appliedFilter: populateDefaultFilter(),
    configuration: populateDefaultConfiguration(),
    control: populateFilterPanelControl(),
    pageDefaultFilter: populateDefaultFilter(),
};

const globalFilterPanelReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_GLOBAL_FILTER": {
            return {
                ...state,
                configuration: action.value.configuration,
                control: action.value.control,
                appliedFilter: action.value.appliedFilter,
                pageDefaultFilter: action.value.pageDefaultFilter,
            };
        }
        case "SET_DEFAULT_GLOBAL_FILTER": {
            return {
                ...state,
                configuration: initialState.configuration,
                control: initialState.control,
                appliedFilter: initialState.appliedFilter,
                pageDefaultFilter: initialState.pageDefaultFilter,
            };
        }
        default:
            return state;
    }
};

export default globalFilterPanelReducer;
