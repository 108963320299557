import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetCustomerCompetitorSuccess: payload => ({
        type: "FETCH_COMPETITOR_SUCCESS",
        payload
    }),
    GetCustomerCompetitorError: payload => ({
        type: "FETCH_COMPETITOR_ERROR",
        payload
    }),
    DeleteCustomerCompetitorSuccess: payload => ({
        type: "FETCH_COMPETITOR_SUCCESS",
        payload
    }),
    DeleteCustomerCompetitorError: payload => ({
        type: "FETCH_COMPETITOR_ERROR",
        payload
    }),
    GetCompetitorTenantCustomerSuccess: payload => ({
        type: "FETCH_COMPETITOR_TENANT_CUSTOMER_SUCCESS",
        payload
    }),
    GetCompetitorTenantCustomerError: payload => ({
        type: "FETCH_COMPETITOR_TENANT_CUSTOMER_ERROR",
        payload
    }),
    UpdateCustomerCompetitorSuccess: payload => ({
        type: "FETCH_COMPETITOR_SUCCESS",
        payload
    }),
    UpdateCustomerCompetitorError: payload => ({
        type: "FETCH_COMPETITOR_ERROR",
        payload
    }),
};

export const fetchCustomerCompetitor = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        let customer = state.customer.list[id];
        if (customer && customer.competitors) {
            if (customer.competitors.length > 0) {
                return Promise.resolve(true);
            }
        }
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_COMPETITORS.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    customer = {
                        ...customer,
                        competitors: response.data.data
                    };
                    dispatch(actions.GetCustomerCompetitorSuccess(customer));
                    return response;
                }

                dispatch(actions.GetCustomerCompetitorError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerCompetitorError(error.message));
                return Promise.resolve(error.response);
            });
    }
};

export const deleteCustomerCompetitor = (competitorData) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[competitorData.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_COMPETITORS.format(competitorData.customer_id, competitorData.id);

    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (customer.competitors) {
                    const competitorsAfterDelete = customerData.competitors.filter((competitors) => {
                        return competitors !== competitorData.id;
                    });
                    customerData = {
                        ...customerData,
                        competitors: competitorsAfterDelete
                    };
                } else {
                    customerData = {
                        ...customerData,
                        competitors: []

                    };
                }
                dispatch(actions.DeleteCustomerCompetitorSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerCompetitorError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteCustomerCompetitorError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerIndustry = (industry) => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_TENANT_INDUSTRY.format("Competitor");

    return axios
        .get(url)
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};


export const fetchCompetitorTenantCustomers = (customerData) => (dispatch, getState) => {

    const state = getState();
    let customer = state.customer.competitorTenantCustomers;
    const url = apiUrl.BASE_URL + (customerData.type === "Competitor" ? apiUrl.GET_COMPETITOR_CUSTOMERS.format(customerData.customerId) : apiUrl.GET_TENANT_CUSTOMERS.format(customerData.customerId));

    return axios
        .get(url)
        .then(response => {
            let data = [];
            if (response.status === 200) {
                data = response.data.data;
            }
            customer = {
                ...customer,
                competitorTenantCustomers: data
            };
            dispatch(actions.GetCompetitorTenantCustomerSuccess(customer));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetCompetitorTenantCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerCompetitors = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        let customer = state.customer.list[id];
        const url = apiUrl.BASE_URL + apiUrl.GET_COMPETITORS_CUSTOMERS.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    console.log('response', response)
                    customer = {
                        ...customer,
                        competitors: response.data.data
                    };
                    dispatch(actions.GetCustomerCompetitorSuccess(customer));
                    return response;
                }

                dispatch(actions.GetCustomerCompetitorError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerCompetitorError(error.message));
                return Promise.resolve(error.response);
            });
    }
};

export const updateCustomerCompetitors = (customerId, competitorId, competitorPayload) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[customerId];
    const url = apiUrl.BASE_URL + apiUrl.EDIT_COMPETITORS_CUSTOMERS.format(customerId, competitorId);

    return axios
        .patch(url, competitorPayload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (response.data.data) {
                    customerData.insights = response.data.data[0].insights;
                }

                dispatch(actions.UpdateCustomerCompetitorSuccess(customerData));
                return response;
            }

            dispatch(actions.UpdateCustomerCompetitorError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.UpdateCustomerCompetitorError(error.message));
            return Promise.resolve(error.response);
        });
}
