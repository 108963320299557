export function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        if (a[property] && b[property]) {
            if (typeof a[property] === "string" && typeof b[property] === "string") {
                let result = a[property].localeCompare(b[property]);
                return result * sortOrder;
            }

        }
    };
}

export function sortObjKeys(obj) {
    return Object.keys(obj).sort((a, b) => a > b).reduce((result, key) => {
        result[key] = obj[key];
        return result;
    }, {});
}

export const getObjectPropValue = (data, column) => {
    const keys = column.split('.');
    let result = data;
    for (const prop of keys) {
        result = result[prop];
    }
    return result;
};

export const setObjectPropValue = (
    column,
    row,
    value,
) => {
    var schema = row;
    var pList = column.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;

    return schema;
};
