import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    CreateCustomerDimensionSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_SUCCESS",
        payload
    }),
    CreateCustomerDimensionError: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_ERROR",
        payload
    }),
    DeleteCustomerDimensionSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_SUCCESS",
        payload
    }),
    DeleteCustomerDimensionError: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_ERROR",
        payload
    }),
    UpdateCustomerDimensionSuccess: (payload) => ({
        type: "UPDATE_CUSTOMER_DIMENSION_SUCCESS",
        payload
    }),
    UpdateCustomerDimensionError: (payload) => ({
        type: "UPDATE_CUSTOMER_DIMENSION_ERROR",
        payload
    }),
    GetCustomerDimensionSuccess: (payload) => ({
        type: "GET_CUSTOMER_DIMENSION_SUCCESS",
        payload
    }),
    GetCustomerDimensionError: (payload) => ({
        type: "GET_CUSTOMER_DIMENSION_ERROR",
        payload
    }),
};


export const createCustomerDimension = (customerDimension) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerDimension
    const customer = store.customer.list[customerDimension.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_DIMENSIONS;

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.dimensions) {
                    customerData["dimensions"] = [...customerData.dimensions, ...response.data.data];
                } else {
                    customerData["dimensions"] = [...response.data.data];
                }
                dispatch(actions.CreateCustomerDimensionSuccess(customerData));
                return response;
            }

            dispatch(actions.CreateCustomerDimensionError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateCustomerDimensionError(error.message));
            return Promise.resolve(error.response);
        });

};

export const updateCustomerDimension = (customerDimension, id) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerDimension
    const customer = store.customer.list[customerDimension.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_DIMENSIONS.format(id);

    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.dimensions.filter(data => data._id !== id);
                let updatedDimensionData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    dimensions: updatedDimensionData
                };
                dispatch(actions.UpdateCustomerDimensionSuccess(customerData));
                return response;
            }


            dispatch(actions.UpdateCustomerDimensionError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.UpdateCustomerDimensionError(error.message));
            return Promise.resolve(error.response);
        });


};

export const getCustomerDimensions = (customerId) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[customerId];

    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_DIMENSIONS.format(customerId);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData,
                    dimensions: response.data.data
                };

                dispatch(actions.GetCustomerDimensionSuccess(customerData));
                return response;

            }

            dispatch(actions.GetCustomerDimensionError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetCustomerDimensionError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteCustomerDimension = (customerDimension) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerDimension
    const customer = store.customer.list[customerDimension.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_DIMENSIONS.format(customerDimension.customer_id, customerDimension.entity_type);


    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.dimensions) {
                    const dimensionAfterDelete = customerData.dimensions.filter((dimensions) => {
                        return dimensions.entity_type !== customerDimension.entity_type;
                    });
                    customerData["dimensions"] = dimensionAfterDelete;
                } else {
                    customerData["dimensions"] = [];
                }
                dispatch(actions.DeleteCustomerDimensionSuccess(customerData));
                return response;
            }


            dispatch(actions.DeleteCustomerDimensionError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteCustomerDimensionError(error.message));
            return Promise.resolve(error.response);
        });

};
