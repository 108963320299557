import React from "react";
import {BaseTextInput} from "../../baseComponents";

const TatvamTextBox = (props) => {
    return (
        <BaseTextInput {...props} />
    )
}

export default TatvamTextBox;
