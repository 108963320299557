import ApiSourceDetailsForm from './apiSourceDetailsForm';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createAPISource, updateAPISource} from "../../../actions";

const mapStateToProps = (state) => ({
    userName: state.auth.userName
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createAPISource,
            updateAPISource

        },
        dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(ApiSourceDetailsForm);
