/* eslint-disable no-console */
import {apiUrl} from 'apiConstants';
import axios from 'axios';
import storage from 'redux-persist/lib/storage';

import {persistor} from '../utils/helpers';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    AuthSuccess: payload => ({
        type: "SIGNIN_SUCCESS",
        payload
    }),
    AuthError: payload => ({
        type: "SIGNIN_ERROR",
        payload
    }),
    SignOutSuccess: payload => ({
        type: "SIGNOUT_SUCCESS",
        payload: payload
    }),
    userPersmissionsSuccess: payload => ({
        type: "USER_PERMISSION_SUCCESS",
        payload: payload
    }),
    userPersmissionsError: payload => ({
        type: "USER_PERMISSION_ERROR",
        payload: payload
    }),
    purgeStore: payload => ({
        type: "PURGE_STORE",
        payload: payload
    })
};

export const UserSignIn = userData => (dispatch) => {
    const payload = userData;
    const url = apiUrl.BASE_URL + apiUrl.AUTH_USER;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                localStorage.setItem("authToken", response.data.data.authToken);
                localStorage.setItem("refreshToken", response.data.data.refreshToken);
                const login = {
                    userName: userData.user_name,
                    ...response.data.data
                };
                dispatch(actions.AuthSuccess(login));
                return response;
            }
            dispatch(actions.AuthError(response.message));
            return response;
        })
        .catch(error => {
            if (error.response)
                dispatch(actions.AuthError(error.response.data.message));
            else
                dispatch(actions.AuthError(error.message));
            return Promise.resolve(error.response);
        });
};

export const UserSignOut = () => async (dispatch, getState) => {
    const auth = getState().auth;
    await persistor.purge();
    await storage.removeItem("persist:root");
    delete auth.authToken;
    delete auth.refreshToken;
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    dispatch(actions.SignOutSuccess());
};

export const UserPermissions = () => (dispatch, getState) => {
    const auth = getState().auth;
    if (auth.userName) {
        const url = apiUrl.BASE_URL + apiUrl.GET_USER_PERMISSIONS.format(auth.userName);
        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    dispatch(actions.userPersmissionsSuccess(response.data.data));
                    return response;
                } else {
                    dispatch(actions.userPersmissionsError(response.message));
                    return response;
                }
            }).catch(error => {
                dispatch(actions.userPersmissionsError(error.message));
                return error;

            });
    }
};


export const refreshToken = () => (dispatch, getState) => {
    let tokenData = localStorage.getItem("refreshToken");
    if (tokenData !== "") {
        var requestData = {
            refreshtoken: tokenData
        }
        const payload = requestData;
        const url = apiUrl.BASE_URL + apiUrl.REFRESH_TOKEN_ENDPOINT;
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        return axios
            .post(url, payload)
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem("authToken", response.data.data.authToken);
                    localStorage.setItem("refreshToken", response.data.data.refreshToken);
                    const login = {
                        userName: response.data.data.user_name,
                        ...response.data.data
                    };
                    dispatch(actions.AuthSuccess(login));
                    return response;
                }
                dispatch(actions.AuthError(response.message));
                return response;
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        dispatch(UserSignOut());
                    } else {
                        dispatch(actions.AuthError(error.response.data.message));
                    }
                } else
                    dispatch(actions.AuthError(error.message));
                return Promise.resolve(error.response);
            });
    } else {
        dispatch(UserSignOut());
    }
};
