import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchApplicationNames, getCustomerUsersReportsLogDetails} from "../../../actions";
import CustomerUsersReportsUsageLogs from "./customerUsersReportsUsageLog";

const mapStateToProps = (state) => ({
    allCustomers: state.customer.list,
    applicationList: state.applicationName.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getCustomerUsersReportsLogDetails,
            fetchApplicationNames
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsersReportsUsageLogs);
