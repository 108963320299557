import './multi-tatvam-rating.component.scss';

import {Accordion, Card, Form} from 'react-bootstrap';
import React, {Fragment, useEffect, useState} from 'react';
import SelectableContext from 'react-bootstrap/esm/SelectableContext';
import EmotionRating from '../emotion-rating/emotion-rating.component';
import {Collapsible, CollapsibleItem} from "react-materialize";

const MultiTatvamRating = (props) => {
    const [ratingItems, setRatingItems] = useState([])

    useEffect(() => {
        if (props.ratingItems && props.ratingItems.length > 0) {
            setRatingItems(props.ratingItems);
        } else {
            setRatingItems([]);
        }
    }, [props.ratingItems]);

    const onRatingSelected = (e) => {
        if (e.target) {
            const uniqueName = props.usedFor ? (props.usedFor + '_' + props.name) : props.name;

            let isChecked = e.target.checked;
            let id = Number(e.target.id.toString().replace(uniqueName, ''));
            let clonedRatings = ratingItems.map((rating) => {
                const item = {...rating, isChecked: rating.id === id ? isChecked : rating.isChecked}
                return item;
            });

            setRatingItems(clonedRatings);

            if (props.onRatingSelect) {
                props.onRatingSelect(clonedRatings, props.name);
            }
        }
    };

    const onClearClick = (e) => {
        const ratings = ratingItems.map((ratingItem => {
            ratingItem.isChecked = false;
            return ratingItem;
        }));
        setRatingItems(ratings);

        if (props.onRatingSelect) {
            props.onRatingSelect([], props.name);
        }
        e.stopPropagation();
        e.preventDefault();
    };

    const renderBody = () => {
        if (!props.showCollapseIcon) {
            return <Fragment>
                <Card.Body>{renderRating()}</Card.Body>
            </Fragment>;
        }
        return <Fragment>
            <SelectableContext.Provider value={null}>
                <Accordion.Collapse eventKey={`${props.caption}`}>
                    <Card.Body>{renderRating()}</Card.Body>
                </Accordion.Collapse>
            </SelectableContext.Provider>
        </Fragment>;
    };

    const renderClear = () => {
        if (
            !ratingItems ||
            ratingItems.filter((rating) => rating.isChecked).length <= 0 ||
            !props.enableReset
        ) {
            return;
        }
        return (
            <button
                type="button"
                className="btn-link border-none bg-white p-0"
                onClick={onClearClick}
            >
                clear
            </button>
        );
    };

    const renderHeader = () => {
        if (!props.showCollapseIcon) {
            return (
                <Fragment>
                    <p className="m-0 mr-2">{props.caption}</p>
                    {renderClear()}
                </Fragment>
            );
        }
        return (
            <Fragment>
                <p className="m-0 mr-2">{props.caption}</p>
                {renderClear()}
            </Fragment>
        );
    };

    const renderRating = () => {
        const ratings = [];
        if (ratingItems) {
            ratingItems.forEach((item, rowIndex) => {
                const uniqueId = (props.usedFor ? (props.usedFor + '_' + props.name) : props.name) + item.id.toString();
                ratings.push(
                    <div className="ml-1 emotion-wrapper">
                        <Form.Check
                            inline
                            label={<EmotionRating rating={item.id} isAllRatingVisible={false}/>}
                            name={uniqueId}
                            type={'checkbox'}
                            id={uniqueId}
                            checked={item.isChecked}
                            onChange={onRatingSelected}
                        />
                    </div>,
                );
            });
        }
        return ratings;
    };

    return (
        <div className="multi-tatvam-rating">
            {props.showCollapseIcon ? (
                <Collapsible accordion className="m-0">
                    <CollapsibleItem
                        expanded={props.isExpanded}
                        header={renderHeader()}
                        node="div"
                    >
                        <div>
                            {renderBody()}
                        </div>
                    </CollapsibleItem>
                </Collapsible>
            ) : (
                <Card>
                    {renderHeader()}
                    {renderBody()}
                </Card>
            )}
        </div>
    );
};

export default MultiTatvamRating;
