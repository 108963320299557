import './sourceXpathForm.scss';

import React, {Fragment} from 'react';

import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    SOURCE_XPATH,
    SWITCH_TEXT,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import {trimCapitalize} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class SourceXpathForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            editFlag: {},
            isActive: false,
            isMandate: false,
            status: "Inactive",
            isValid: true,
            isValidMandate: true

        };

    }

    _handleInput = (e) => {
        if (e.target.id === "status") {
            this.setState({
                isActive: e.target.checked,
                [e.target.id]: e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE,
                editFlag: {
                    [e.target.id]: true
                }
            });
        }
        if (e.target.id === "locator") {
            this.setState({
                isMandate: e.target.checked,
                [e.target.id]: e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE,
                editFlag: {
                    [e.target.id]: true
                }
            });
        }
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                [e.target.name]: true
            }
        });
    };
    _triggerDiscard = () => {
        if (Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });

        } else {
            this.props.closeModal();
        }
    };

    _discard = (data) => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    _handleDeleteConfirmation = () => {
        this.setState({
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteAction = (data) => {

        if (data === "ok") {
            this._handleDelete();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    };
    _handleDelete = () => {

        let {name} = this.state;
        let source_id = this.props.sourceId;
        let xpathDeleteData = {
            source_id,
            name

        };
        this.props.actions.deleteOneXpath(xpathDeleteData).then((response) => {
            this.setState({
                preLoader: false
            });

            if (response.status === 200) {
                this.props.actions.fetchAllXpaths(this.props.sourceId)
                this.setState({
                    shouldCloseModal: true,
                    isAlertBoxOpen: true,
                    isSuccess: true,
                    alert_information: response.data.message
                });
            } else {
                this.setState({
                    shouldCloseModal: false,
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.data ? response.data.message : response.message
                });

            }

        });

    }
    _triggerSubmit = () => {
        this.refs.submit.click();

    };

    _validation = () => {
        if (!this.state.xpath && this.state.locator !== APPLICATION_MODES.ACTIVE) {
            this.setState({
                isValid: false
            });
            return false;
        } else if (this.state.locator === APPLICATION_MODES.ACTIVE && !this.state.xpath) {
            this.setState({
                isValidMandate: false
            });
            return false;
        } else {
            this.setState({
                isValid: true,
                isValidMandate: true
            });
            return true;
        }
    };

    _handleSave = e => {

        e.preventDefault();

        let {name, xpath, status, locator} = this.state;
        let xpathData = {
            name,
            source_id: this.props.sourceId,
            xpath,
            status,
            locator
        };

        if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            if (this._validation()) {
                xpathData.created_by = this.props.userName;
                this.setState({
                    preLoader: true
                });
                this.props.actions.createXpath(xpathData).then((response) => {
                    this.setState({
                        preLoader: false
                    });

                    if (response.status === 200) {
                        this.setState({
                            shouldCloseModal: true,
                            isAlertBoxOpen: true,
                            isSuccess: true,
                            alert_information: response.data.message
                        });
                    } else {
                        this.setState({
                            shouldCloseModal: false,
                            isAlertBoxOpen: true,
                            isSuccess: false,
                            alert_information: response.data ? response.data.message : response.message
                        });

                    }
                });
            }
        }
        if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
            if (this._validation()) {
                xpathData = {
                    ...this.props.formData
                };
                xpathData.name = name;
                xpathData.source_id = this.props.sourceId;
                xpathData.xpath = xpath;
                xpathData.status = status;
                xpathData.locator = locator;
                xpathData.updated_by = this.props.userName;
                this.setState({
                    preLoader: true
                });
                this.props.actions.updateXpath(xpathData).then((response) => {
                    this.setState({
                        preLoader: false
                    });
                    if (response.status === 200) {
                        this.setState({
                            shouldCloseModal: true,
                            isAlertBoxOpen: true,
                            isSuccess: true,
                            alert_information: response.data.message
                        });
                    } else {
                        this.setState({
                            shouldCloseModal: false,
                            isAlertBoxOpen: true,
                            isSuccess: false,
                            alert_information: response.data ? response.data.message : response.message
                        });

                    }

                });
            }
        }
    };

    componentDidMount() {
        let isActive;


        if (this.props.formData) {
            this.setState({
                ...this.props.formData,
                isActive: trimCapitalize(this.props.formData.status) === APPLICATION_MODES.ACTIVE,
                isMandate: trimCapitalize(this.props.formData.locator) === APPLICATION_MODES.ACTIVE
            });
            if (this.props.formData.status) {
                isActive = trimCapitalize(this.props.formData.status) === APPLICATION_MODES.ACTIVE;
            }
        }
        this.setState({
            isActive
        });
    }

    componentWillReceiveProps(props) {

        let isActive;
        let isMandate;
        if (props.formData) {
            this.setState({
                ...props.formData,
                isActive: trimCapitalize(props.formData.status) === APPLICATION_MODES.ACTIVE,
                isMandate: trimCapitalize(props.formData.locator) === APPLICATION_MODES.ACTIVE
            });
            if (props.formData.status)
                isActive = trimCapitalize(props.formData.status) === APPLICATION_MODES.ACTIVE;
            if (props.formData.locator)
                isMandate = trimCapitalize(props.formData.locator) === APPLICATION_MODES.ACTIVE;
        }
        this.setState({
            isActive,
            isMandate
        });
    }

    render() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({inValidForm: true});
                }, true);
        }
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{SOURCE_XPATH.VIEW_TITLE_XPATH}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (<h4>{SOURCE_XPATH.EDIT_XPATH}</h4>) : ""}
                </Row>
                <Row className="mt-3">
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <form onSubmit={(e) => this._handleSave(e)}>
                            <TatvamTextBox
                                className={(this.state.inValidForm && !this.state.name) ? "labelText mt-0 validate project_name mb-4 invalid" : "labelText mt-0 validate project_name mb-4"}
                                s={12}
                                required
                                aria-required={true}
                                label={SOURCE_XPATH.NAME}
                                name="name"
                                onChange={this._handleInput}
                                value={this.state.name}
                                disabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                placeholder=""
                                autocomplete="off"
                                maxlength={255}
                            />
                            <TatvamTextArea
                                className={(this.state.inValidForm && !this.state.xpath) ? "labelText mt-0 validate project_name mb-2 invalid" : "labelText mt-0 validate project_name mb-2"}
                                s={12}
                                required
                                aria-required={true}
                                label={
                                    <span>
                    {SOURCE_XPATH.XPATH}
                                        <span style={{color: 'red'}}> *</span>
                  </span>
                                }
                                name="xpath"
                                onChange={this._handleInput}
                                value={this.state.xpath}
                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                            />
                            <TatvamCol m={12} l={12} className="p-0 d-flex">
                                <TatvamCol m={6} l={6}>
                                    <Switch
                                        id="status"
                                        m={6}
                                        s={12}
                                        offLabel={SOURCE_XPATH.STATUS}
                                        checked={this.state.isActive}
                                        onClick={this._handleInput}
                                    />
                                </TatvamCol>
                                <TatvamCol s={12} m={6} l={6}>
                                    <Switch
                                        id="locator"
                                        m={6}
                                        s={12}
                                        offLabel={SOURCE_XPATH.MANDATE}
                                        checked={this.state.isMandate}
                                        onClick={this._handleInput}
                                    />
                                </TatvamCol>
                            </TatvamCol>

                            <button type="submit" ref="submit" className="hide">
                                Submit
                            </button>
                        </form>
                    ) : (
                        <TatvamCol m={12} className="ml-40">
                            <div className="col s12">
                                <span className="property-label col s2"> {SOURCE_XPATH.VIEW_NAME} : </span>
                                <span className="col s10 url-wrap">{this.state.name} </span>
                            </div>
                            <div className="col s12">
                                <span className="property-label col s2">{SOURCE_XPATH.VIEW_XPATH} : </span>
                                <TatvamTextArea
                                    className="labelText mt-0 validate project_name mb-2"
                                    s={10}
                                    name="xpath"
                                    onChange={this._handleInput}
                                    value={this.state.xpath}
                                    disabled
                                />
                            </div>
                            <div className="col s12 m6">
                                <span className="property-label col s4">{SOURCE_XPATH.STATUS} : </span>
                                <span
                                    className="col s8">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                            <div className="col s12 m6">
                                <span className="property-label col s6">{SOURCE_XPATH.MANDATE} : </span>
                                <span
                                    className="col s6">{this.state.isMandate ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                            </div>
                        </TatvamCol>
                    )}
                </Row>
                <Row className="modalButtons mt-4 pr-1">
                    {!this.state.isValid && (
                        <p className="errorText">{
                            VALIDATION_MESSAGES.ENTER_XPATH_OR_REGEX_FIELD
                        }
                        </p>
                    )}
                    {!this.state.isValidMandate && (
                        <p className="errorText">{
                            VALIDATION_MESSAGES.ENTER_XPATH
                        }
                        </p>
                    )}
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                            <TatvamButton
                                waves="light"
                                className="CreateProjectPublish btn_primary "
                                onClick={this._triggerSubmit}
                                disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                            >
                                {FORM_OPERATIONS.SAVE}
                            </TatvamButton>
                        ) :
                        (
                            <Fragment>


                                <TatvamButton waves="light" className=" CreateProjectPublish btn_primary " name="EDIT"
                                              onClick={this._handleAppMode}>
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>

                            </Fragment>

                        )}
                    <TatvamButton waves="light" className=" mr-1 CreateProjectPublish btn_primary"
                                  onClick={this._triggerDiscard}>
                        {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                            FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                    </TatvamButton>
                </Row>
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}

                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteAction}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>

        );
    }
}

export default SourceXpathForm;
