import React, {Fragment} from "react";
import Footer from "../../components/applicationComponents/layout/footer";
import Header from "../../components/applicationComponents/layout/header";
import SideMenu from "../../components/applicationComponents/layout/sideMenu";

const ApplicationView = ({Component, ...rest}) => (
    <Fragment>
        <Header/>
        <div id="main">
            <div className="wrapper">
                <section id="content">
                    <div className="container cards-container">
                        <div className="row">
                            <SideMenu/>
                            <div className="col m10 xl11 appView">
                                <Component {...rest} />
                            </div>
                            <Footer className="side-bar-visible"/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Fragment>
);

export default ApplicationView;
