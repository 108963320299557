const initialState = {
    list: {},
    error: {}
};

const CommentTypeReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "GET_COMMENT_TYPE_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };

            return state;
        case "GET_COMMENT_TYPE_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        default:
            return state;
    }
};

export default CommentTypeReducer;
