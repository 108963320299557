import './industryDimensionValueForm.scss';

import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    INDUSTRY_DIMENSION_VALUE,
    SWITCH_TEXT,
    TEXT_VALIDATION,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {
    trimCapitalize,
    validateCustomPattern,
    validatePattern,
    validateSequenceNumber,
} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class IndustryDimensionValueForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            selectedIndustry: [],
            industryTypeListsOptions: [],
            entityTypeListOptions: [],
            selectedEntityValue: [],
            entityTypeValueOptions: [],
            applicationMode: props.applicationMode,
            preLoader: false,
            status: APPLICATION_MODES.INACTIVE,
            entity_value: "",
            parent_entity_value: "",
            sequence_no: "",
            isActive: false,
            pattern: "",
            isValid: true,
            editFlag: false,
            parent_entity: "",
            validEntityTypeValue: true,
            validEntityParentTypeValue: true,
            validPattern: true,
            validSequenceNumber: true,
            valid: true,
            isPatternFieldMandatory: false,
            isParentDimensionMandatory: false
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    }

    _deleteAction = (data) => {
        if (data === "ok") {
            if (this.state.pathDivertedForValidation) {
                this.setState({passThrough: true});
                const e = new Event('click');
                this._handleSave(e);
            } else {
                this._handleDelete();
            }

        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    };

    fetchIndustryData = () => {
        this.props.actions.getCustomerDimensions(this.props.customerId).then(() => {
            let dimensionsGridData = [];
            if (this.props.customerDimensions) {
                this.props.customerDimensions.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            dimensionsGridData.push(data);
                        }
                    }
                });

                this.setState({
                    dimensionsGridData
                });
            }
        });
    }


    componentDidMount() {
        this.props.actions.fetchAllIndustry().then((response) => {
            this.setState({
                sourceIndustryList: response.data.data
            });


            if (!Array.isArray(this.props.industryDimensionValueData)) {
                this.initDropdownValues(this.props.industryDimensionValueData.industry_name);
                let selectedEntityValue = this.props.industryDimensionValueData.parent_entity_value ? {
                    label: this.props.industryDimensionValueData.parent_entity_value,
                    value: this.props.industryDimensionValueData.parent_entity_value
                } : {label: INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
                let selectedIndustry = {label: INDUSTRY_DIMENSION_VALUE.SELECT_INDUSTRY, value: ""};

                if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
                    for (let data of Object.values(response.data.data)) {
                        if (this.props.industryDimensionValueData.industry_name === data.name) {
                            selectedIndustry = {label: data.name, value: data.name};
                        }
                    }
                }

                this.setState({
                    ...this.props.industryDimensionValueData,
                    selectedEntityValue,
                    selectedIndustry
                });
            } else {
                let selectedIndustry = {};
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    if (this.props.industryName) {
                        this.initDropdownValues(this.props.industryName);
                        selectedIndustry = {label: this.props.industryName, value: this.props.industryName};
                    }
                }
                let selectedEntityValue = {label: INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
                this.setState({
                    ...this.props.industryDimensionValueData,
                    selectedEntityValue,
                    selectedIndustry
                });
            }

            this.setState({
                applicationMode: this.props.applicationMode,
                status: this.props.industryDimensionValueData && trimCapitalize(this.props.industryDimensionValueData.status) ? this.props.industryDimensionValueData.status : APPLICATION_MODES.INACTIVE,
                isActive: this.props.industryDimensionValueData && this.props.industryDimensionValueData.status ? trimCapitalize(this.props.industryDimensionValueData.status) === APPLICATION_MODES.ACTIVE : false
            });
        });

    }

    componentWillReceiveProps(props) {

        if (!Array.isArray(props.industryDimensionValueData)) {
            this.initDropdownValues(props.industryDimensionValueData.industry_name);
            let selectedEntityValue = props.industryDimensionValueData.parent_entity_value ? {
                label: props.industryDimensionValueData.parent_entity_value,
                value: props.industryDimensionValueData.parent_entity_value
            } : {label: INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedIndustry = {label: INDUSTRY_DIMENSION_VALUE.SELECT_INDUSTRY, value: ""};
            if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
                for (let data of Object.values(props.sourceIndustryList.industry)) {
                    if (this.props.industryDimensionValueData.industry_name === data.name) {
                        selectedIndustry = {label: data.name, value: data.name};
                    }
                }
            }

            this.setState({
                ...props.industryDimensionValueData,
                selectedEntityValue,
                selectedIndustry
            });
        } else {

            let selectedIndustry = {};
            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                if (props.industryName) {
                    this.initDropdownValues(props.industryName);
                    selectedIndustry = {label: props.industryName, value: props.industryName};
                }
            }
            let selectedEntityValue = {label: INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};

            this.setState({
                ...props.industryDimensionValueData,
                selectedEntityValue,
                selectedIndustry
            });
        }

        this.setState({
            applicationMode: props.applicationMode,
            status: props.industryDimensionValueData && trimCapitalize(props.industryDimensionValueData.status) ? props.industryDimensionValueData.status : APPLICATION_MODES.INACTIVE,
            isActive: props.industryDimensionValueData && props.industryDimensionValueData.status ? trimCapitalize(props.industryDimensionValueData.status) === APPLICATION_MODES.ACTIVE : false
        });
    }

    initDropdownValues = (industry_name) => {

        const industryLst = [];
        industryLst.push({"label": "", value: ""});

        if (this.state.sourceIndustryList) {
            for (let data of Object.values(this.state.sourceIndustryList)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE) {
                    industryLst.push({label: data.name, value: data.name});
                }
            }
            industryLst.sort(dynamicSort("label"));
        }
        industryLst[0].label = INDUSTRY_DIMENSION_VALUE.SELECT_INDUSTRY;

        const entityTypeLst = [];
        entityTypeLst.push({label: "", value: ""});
        if (industry_name !== "") {
            for (let data of Object.values(this.props.industryDimensionValueList)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE && data.industry_name === industry_name) {
                    entityTypeLst.push({label: data.entity_value, value: data.entity_value});
                }
            }
            entityTypeLst.sort(dynamicSort("label"));
        }
        entityTypeLst[0].label = INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY;

        this.setState({
            entityTypeListOptions: entityTypeLst,
            industryTypeListsOptions: industryLst
        });
    };

    _handleDelete = () => {

        let industry_dimension_id = this.props.industryDimensionValueData._id;
        let industryDimensionData = {
            industry_dimension_id

        };
        this.props.actions.deleteIndustryDimension(industryDimensionData).then((response) => {
            this.setState({
                preLoader: false
            });
            if (response.status === 200) {
                this.props.actions.getIndustryDimensionValues();
                this.setState({
                    shouldCloseModal: true,
                    isAlertBoxOpen: true,
                    isSuccess: true,
                    alert_information: response.data.message
                });
            } else {
                this.setState({
                    shouldCloseModal: false,
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.data ? response.data.message : response.message
                });

            }

        });

    }
    validateIfIndustryInUse = () => {
        const industryInUse = Object.values(this.props.customers).filter(customer => customer.industry === this.state.selectedIndustry.label);
        if (industryInUse.length > 0) {
            if (this.state.passThrough) {
                return Promise.resolve(true);
            } else {
                this.setState({
                    pathDivertedForValidation: true,
                    isConfirmBox: true,
                    isAlertBoxOpen: false,
                    isDeleteAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: ALERT_MESSAGES.INDUSTRY_EXIST
                });
                return Promise.resolve(false);
            }

        }
        return Promise.resolve(true);
    };
    _handleSave = e => {
        e.preventDefault();

        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            }
            if (response) {
                if (this.state.isValid && this.state.validEntityTypeValue && this.state.validPattern && this.state.validSequenceNumber) {
                    const dimensionValue = {
                        entity_type: INDUSTRY_DIMENSION_VALUE.CLASSIFIER,
                        entity_value: this.state.entity_value.charAt(0).toUpperCase() + this.state.entity_value.slice(1),
                        parent_entity_type: this.state.selectedEntityValue.label !== INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY ? INDUSTRY_DIMENSION_VALUE.CLASSIFIER : "",
                        parent_entity_value: this.state.selectedEntityValue.label !== INDUSTRY_DIMENSION_VALUE.SELECT_PARENT_ENTITY ? this.state.selectedEntityValue.label : "",
                        sequence_no: $.trim(this.state.sequence_no) !== "" ? parseInt(this.state.sequence_no) : 0,
                        status: this.state.status,
                        pattern: this.state.pattern
                    };

                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        let industryDimensionValueData = {
                            ...dimensionValue,
                            industry_name: this.state.selectedIndustry.label,
                            created_by: this.props.userName
                        };

                        this.props.actions.createIndustryDimensionValue(industryDimensionValueData).then((responseIndustryDimensionValueData) => {
                            this.setState({preLoader: false});
                            if (responseIndustryDimensionValueData && responseIndustryDimensionValueData.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseIndustryDimensionValueData.data && responseIndustryDimensionValueData.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseIndustryDimensionValueData.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseIndustryDimensionValueData.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseIndustryDimensionValueData.data.message,
                                });
                            }
                        });
                    } else {
                        this.validateIfIndustryInUse().then((returnRes) => {
                            if (returnRes) {
                                let dimensionData = {
                                    ...dimensionValue,
                                    industry_name: this.state.selectedIndustry.value,
                                    updated_by: this.props.userName
                                };
                                this.props.actions.updateIndustryDimensionValue(dimensionData, this.props.industryDimensionValueData._id).then((responseUpdateIndustryDimensionValue) => {
                                    this.setState({preLoader: false});
                                    if (responseUpdateIndustryDimensionValue && responseUpdateIndustryDimensionValue.status === 200) {
                                        this.setState({shouldCloseModal: true});
                                        if (responseUpdateIndustryDimensionValue.data && responseUpdateIndustryDimensionValue.data.message) {
                                            this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: responseUpdateIndustryDimensionValue.data.message
                                            });
                                        } else {
                                            this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: responseUpdateIndustryDimensionValue.message
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            shouldCloseModal: false,
                                            isAlertBoxOpen: true,
                                            isSuccess: false,
                                            alert_information: responseUpdateIndustryDimensionValue.message,
                                        });
                                    }
                                });
                            }
                        });
                    }

                }
            }
        });
    }

    _validations = () => {
        let isValidEntityTypeValue = true;
        let mandatoryFieldsValid = true;
        let patternFieldValid = true;
        let sequenceNoFieldValid = true;

        if (this.state.selectedIndustry.label === INDUSTRY_DIMENSION_VALUE.SELECT_INDUSTRY || !this.state.entity_value || !this.state.pattern) {
            mandatoryFieldsValid = false;
        } else {

            if (this.state.sequence_no) {
                sequenceNoFieldValid = validateSequenceNumber(this.state.sequence_no.toString());
            }

            if (this.state.pattern) {
                patternFieldValid = validatePattern(this.state.pattern);
            }

        }

        this.setState({
            isValid: mandatoryFieldsValid,
            validEntityTypeValue: isValidEntityTypeValue,
            validPattern: patternFieldValid,
            validSequenceNumber: sequenceNoFieldValid
        });

        return Promise.resolve(true);
    }

    _handleInput = e => {
        if (e.target.name === "entity_value") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^[a-zA-Z0-9 _-]{1,25}$/g)) {
                return false;
            }
        }
        if (e.target.id === "dimensionValueStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status: status,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else {

            if (e.target.id === "sequence_no") {
                if (e.target.value !== "" && !Number(e.target.value)) {
                    return;
                }
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _handleIndustryTypeSelect = selectedOption => {
        if (selectedOption.value !== "") {
            this.initDropdownValues(selectedOption.value);
        }

        this.setState({
            editFlag: {
                ...this.state.editFlag,
                industry_name: true
            },
            selectedIndustry: selectedOption
        });
    };

    _handleEntityTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                entity_type: true
            },
            selectedEntityValue: selectedOption,

        });
    };

    _handleEntityValueSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                parent_entity_value: true
            },
            selectedEntityValue: selectedOption
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    selectedEntityType: [],
                    selectedIndustry: []
                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {

        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{INDUSTRY_DIMENSION_VALUE.VIEW_INDUSTRY_DIMENSION_VALUE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{INDUSTRY_DIMENSION_VALUE.EDIT_INDUSTRY_DIMENSION_VALUE}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row>
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12} className="align-flex-end">
                            <TatvamCol m={12} xl={6} className="pr-3 mr-1">
                                <label htmlFor="industry">{INDUSTRY_DIMENSION_VALUE.INDUSTRY_NAME}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.selectedIndustry.value === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedIndustry}
                                    name="industry_name"
                                    id="industry_name"
                                    onChange={this._handleIndustryTypeSelect}
                                    options={this.state.industryTypeListsOptions}
                                    isDisabled={true}
                                />
                            </TatvamCol>
                            <TatvamCol m={12} xl={6}>
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12} className="align-flex-end">
                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="entity_value">{}</label>}
                                <TatvamTextBox
                                    required
                                    validate
                                    className={(this.state.inValidForm && !this.state.entity_value) ? "labelText mb-0 validate txt-no-h captalize invalid" : "labelText mb-0 validate txt-no-h captalize"}
                                    s={12}
                                    id="entity_value"
                                    name="entity_value"
                                    onChange={this._handleInput}
                                    value={this.state.entity_value}
                                    autocomplete="off"
                                />
                            </TatvamCol>
                            <TatvamCol m={12} xl={6}>
                                <label htmlFor="entity_type">{INDUSTRY_DIMENSION_VALUE.ENTITY_TYPE}</label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className="validate select-ht "
                                    value={this.state.selectedEntityValue}
                                    name="entity_type"
                                    id="entity_type"
                                    onChange={this._handleEntityTypeSelect}
                                    options={this.state.entityTypeListOptions}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12} className="mt-2">
                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                <label htmlFor="sequence_no">{INDUSTRY_DIMENSION_VALUE.SEQUENCE_NO}</label>
                                <TatvamTextBox
                                    m={12}
                                    id="sequence_no"
                                    name="sequence_no"
                                    onChange={this._handleInput}
                                    className="labelText mb-0 validate txt-no-h"
                                    value={this.state.sequence_no.toString()}
                                    autocomplete="off"
                                    maxlength={3}
                                    title={TEXT_VALIDATION.SEQ_NO}
                                />
                            </TatvamCol>

                            <TatvamCol s={6} className="switch-wrapper">
                                <Switch
                                    id="dimensionValueStatus"
                                    offLabel={INDUSTRY_DIMENSION_VALUE.STATUS}
                                    checked={this.state.isActive}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12} className="no-pad-left">
                            <TatvamCol m={12} xl={12} className="pr-0">
                                <label htmlFor="pattern">{INDUSTRY_DIMENSION_VALUE.PATTERN}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    className={(this.state.inValidForm && !this.state.pattern) ? "labelText mt-0 txtarea-border invalid" : "labelText mt-0 txtarea-border"}
                                    name="pattern"
                                    id="pattern"
                                    value={this.state.pattern}
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>
                        </TatvamCol>

                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12}>
                            <div className="mt-0 col s12 pl-3">
                                <span
                                    className="property-label col s2 p-0"> {INDUSTRY_DIMENSION_VALUE.VIEW_INDUSTRY_NAME} :</span>
                                <span className="col s9 url-wrap">  {this.state.selectedIndustry.label}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol m={6} className="mt-.75 ">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s5">{INDUSTRY_DIMENSION_VALUE.VIEW_ENTITY_TYPE_VALUE} :</span>
                                <span className="col s7">{this.state.entity_value}</span>
                            </div>
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s5"> {INDUSTRY_DIMENSION_VALUE.VIEW_SEQUENCE_NO} :</span>
                                <span className="col s7">{this.state.sequence_no.toString()}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol m={6} className="mt-.75 ">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s5 url-wrap"> {INDUSTRY_DIMENSION_VALUE.VIEW_ENTITY_TYPE} : </span>
                                <span className="col s7"> {this.state.parent_entity_value}</span>
                            </div>
                            <div className="mt-0 col s12">
                                <span className="property-label col s5"> {INDUSTRY_DIMENSION_VALUE.STATUS} : </span>
                                <span
                                    className="col s7">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12}>
                            <div className="mt-0 col s12 pl-3">
                                <span
                                    className="property-label col s2 p-0"> {INDUSTRY_DIMENSION_VALUE.VIEW_PATTERN} :</span>
                                <TatvamTextArea
                                    className="labelText mt-0 validate project_name"
                                    s={10}
                                    value={this.state.pattern}
                                    disabled
                                />
                            </div>
                        </TatvamCol>
                    </Row>
                )}
                <Row className="modalButtons pl-1 pr-1">
                    <TatvamCol s={12} m={6}>
                        {!this.state.isValid && (
                            <p className="errorText"></p>
                        )}
                        {!this.state.validEntityTypeValue && (
                            <p className="errorText">{VALIDATION_MESSAGES.ENTITY_NAME_VALUE_INVALID}</p>
                        )}
                        {!this.state.validEntityParentTypeValue && (

                            <p className="errorText">{VALIDATION_MESSAGES.PARENT_ENTITY_NAME_VALUE_INVALID}</p>
                        )}
                        {!this.state.validPattern && (
                            <p className="errorText">{VALIDATION_MESSAGES.PATTERN_INVALID}</p>
                        )}
                        {!this.state.validSequenceNumber && (
                            <p className="errorText">{VALIDATION_MESSAGES.SEQUENCE_NO_INVALID}</p>
                        )}
                    </TatvamCol>
                    <TatvamCol s={12} m={6}>
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                            this.props.industryDimensionPermission === APP_BOOLEANS.TRUE && (
                                <Fragment>
                                    {/* <TatvamButton
                waves="light"
                className="mt-1 CreateProjectIndustry btn_primary "
                name="DELETE"
                onClick={this._handleDeleteConfirmation}>
                {FORM_OPERATIONS.DELETE}
                </TatvamButton> */}
                                    <TatvamButton
                                        waves="light"
                                        className="mt-1 CreateProjectPublish btn_primary "
                                        name="EDIT"
                                        onClick={this._handleAppMode}
                                        disabled={this.props.nonEditable}
                                    >
                                        {FORM_OPERATIONS.EDIT}
                                    </TatvamButton>
                                </Fragment>
                            )}
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteAction}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default IndustryDimensionValueForm;
