import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetPredictionInfluencersSuccess: (payload) => ({
        type: "FETCH_PREDICTION_INFLUENCERS",
        payload
    }),
    GetPredictionInfluencersError: (payload) => ({
        type: "FETCH_PREDICTION_INFLUENCERS_ERROR",
        payload
    }),
    CreateCustomerInfluencer: (payload) => ({
        type: "CREATE_CUSTOMER_INFLUENCER",
        payload
    }),
    CreateCustomerInfluencersSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_INFLUENCERS_SUCCESS",
        payload
    }),
    CreateCustomerInfluencersError: (payload) => ({
        type: "CREATE_CUSTOMER_INFLUENCERS_ERRORS",
        payload
    }),
    EditCustomerInfluencer: (payload) => ({
        type: "EDIT_CUSTOMER_INFLUENCERS",
        payload
    }),
    EditCustomerInfluencerSuccess: (payload) => ({
        type: "EDIT_CUSTOMER_INFLUENCERS_SUCCESS",
        payload
    }),
    EditCustomerInfluencerError: (payload) => ({
        type: "EDIT_CUSTOMER_INFLUENCERS_ERRORS",
        payload
    }),
    DeleteCustomerInfluencer: (payload) => ({
        type: "DELETE_CUSTOMER_INFLUENCERS",
        payload
    }),
    DeleteCustomerInfluencersSuccess: (payload) => ({
        type: "DELETE_CUSTOMER_INFLUENCERS_SUCCESS",
        payload
    }),
    DeleteCustomerInfluencersErrors: (payload) => ({
        type: "DELETE_CUSTOMER_INFLUENCERS_ERRORS",
        payload
    })
};

export const fetchPredictionInfluencers = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        let customer = state.customer.list[id];
        const url = apiUrl.BASE_URL + apiUrl.GET_INFLUENCERS_BY_CUSTOMER_ID.format(id);

        return axios
            .get(url).then((response) => {
                if (response.status === 200) {
                    let customerData = customer;
                    customerData = {
                        ...customerData,
                        influencersData: response.data.data
                    };
                    dispatch(actions.GetPredictionInfluencersSuccess(customerData));
                    return response;
                }
                dispatch(actions.GetPredictionInfluencersError(response.message));
                return response;
            }).catch((error) => {
                dispatch(actions.GetPredictionInfluencersError(error.message));
                return Promise.resolve(error.response);
            })
    }
}

export const createPredictionInfluencer = (id, influencersData) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[id];
    const payload = influencersData;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_INFLUENCERS.format(id);

    return axios
        .post(url, payload)
        .then((response) => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.influencersData) {
                    customerData["influencersData"] = [...customerData.influencersData, ...response.data.data];
                } else {
                    customerData["influencersData"] = [...response.data.data];
                }

                dispatch(actions.CreateCustomerInfluencersSuccess(customerData));
                return response;
            }
            dispatch(actions.CreateCustomerInfluencersError(response.message));
            return response;
        }).catch((error) => {
            dispatch(actions.CreateCustomerInfluencersError(error.message));
            return Promise.resolve(error.response);
        })
}

export const editPredictionInfluencers = (id, influencersFormData, influencerId) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[id];
    const payload = influencersFormData;
    const url = apiUrl.BASE_URL + apiUrl.EDIT_CUSTOMER_INFLUENCER.format(id, influencerId);

    return axios
        .patch(url, payload)
        .then((response) => {
            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.influencersData.filter(data => data.influencer_id !== influencerId);
                let updatedinfluencersData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    influencersData: updatedinfluencersData
                };

                dispatch(actions.EditCustomerInfluencerSuccess(customerData));
                return response;
            }
            dispatch(actions.EditCustomerInfluencerError(response.message));
            return response;
        }).catch((responseError) => {
            dispatch(actions.EditCustomerInfluencerError(responseError.message));
            return Promise.resolve(responseError.response)
        })
}

export const deletePredictionInfluencer = (customerId, influencerId) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[customerId];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_INFLUENCER.format(customerId, influencerId)
    return axios
        .delete(url)
        .then((response) => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.influencersData) {
                    const influencersAfterDelete = customerData.influencersData.filter((data) => {
                        return data.influencer_id !== influencerId;
                    });
                    customerData["influencersData"] = influencersAfterDelete;
                } else {
                    customerData["influencersData"] = [];
                }

                dispatch(actions.DeleteCustomerInfluencersSuccess(customerData));
                return response;
            }
            dispatch(actions.DeleteCustomerInfluencersErrors(response.message));
            return response;
        }).catch((error) => {
            dispatch(actions.DeleteCustomerInfluencersErrors(error.message))
            return Promise.resolve(error.response);
        })
}

