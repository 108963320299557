import React from 'react';
import {Checkbox} from "react-materialize";

const BaseCheckbox = (props) => {
    return (
        <Checkbox {...props}/>
    )
}

export default BaseCheckbox;
