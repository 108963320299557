import {applyMiddleware, compose, createStore,} from 'redux';
import {persistReducer, persistStore,} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from '../../reducers/index';

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: "root",
    storage: storage,
    stateReconciler: autoMergeLevel2
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, composeEnhancers(applyMiddleware(...middleware)));

/**
 * Subscribing to the store makes it possible to
 * execute some code every time the state is updated.
 */
store.subscribe(() => {
});

export const persistor = persistStore(store);
