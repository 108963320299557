const initialState = {
    list: [],
    error: {}
};

const UserReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "FETCH_USER_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;
        case "FETCH_USER_ERROR":
        case "CREATE_USER_ERROR":
        case "GET_USER_CUSTOMER_ERROR":
        case "DELETE_USER_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        case "EDIT_USER_SUCCESS":
        case "CREATE_USER_SUCCESS":
        case "GET_USER_CUSTOMER_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.user_name]: action.payload
                }
            };
            return state;
        case "DELETE_USER_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;
        case "PURGE_STORE":
            return {};
        default:
            return state;
    }
};

export default UserReducer;
