import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    createIndustryDimensionValue,
    deleteIndustryDimension,
    fetchAllIndustry,
    getIndustryDimensionValues,
    updateIndustryDimensionValue,
} from '../../../actions';
import IndustryDimensionValueForm from './industryDimensionValueForm';

const mapStateToProps = (state, ownProps) => (
    {
        userName: state.auth.userName,
        customers: state.customer.list,
        industryDimensionPermission: state.auth.permissions && state.auth.permissions.industryDimensionValue.create,
        sourceIndustryList: state.sources ? state.sources.industry : []

    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryDimensionValues,
            deleteIndustryDimension,
            createIndustryDimensionValue,
            updateIndustryDimensionValue,
            fetchAllIndustry
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(IndustryDimensionValueForm);
