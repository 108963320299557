import Moment from "moment";

const dateUtil = {
    formatDate(inputFormat) {
        function pad(s) {
            return s < 10 ? `0${s}` : s;
        }

        const d = new Date(inputFormat);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(".");
    },
    getLastYear(numberOfYear) {
        if (!numberOfYear) {
            numberOfYear = 1;
        }
        return new Date(new Date().setFullYear(new Date().getFullYear() - numberOfYear));
    },
    substractDate(numberOfdays) {
        if (!numberOfdays) {
            numberOfdays = 30;
        }
        const currentDate = new Date();
        return currentDate.setDate(currentDate.getDate() - numberOfdays);
    },

    converToDate(date, format) {
        if (!date) {
            return date;
        }

        if (!format) {
            format = "DD-MMM-yyyy hh:mm a";
        }

        return Moment(date).format(format);
    }
};

export default dateUtil;
