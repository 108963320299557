const initialState = {
    list: [],
    error: {}
};

const IndustryDimensionValueReducer = (state, action) => {

    if (state === null || state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case "UPDATE_INDUSTRY_DIMENSION_VALUE_ERROR":
        case "CREATE_INDUSTRY_DIMENSION_VALUE_ERROR":
        case "DELETE_SOURCE_INDUSTRY_DIMENSION_ERROR":

            state = {
                ...state,
                error: action.payload
            };
            return state;
        case "CREATE_INDUSTRY_DIMENSION_VALUE_SUCCESS":
        case "UPDATE_INDUSTRY_DIMENSION_VALUE_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload._id]: action.payload
                }
            };
            return state;
        case "GET_INDUSTRY_DIMENSION_VALUE_ERROR":
            state = {
                ...state,
                list: [],
                error: action.payload
            };
            return state;

        case "GET_INDUSTRY_DIMENSION_VALUE_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;
        case "DELETE_SOURCE_INDUSTRY_DIMENSION_SUCCESS":
            state = {
                ...state,
                industryDimensionValue: {
                    list: action.payload
                }
            };
            return state;
        default:
            return state;
    }
};

export default IndustryDimensionValueReducer;
