import {apiUrl} from "apiConstants";
import axios from "axios";

const actions = {
    GetUserTypeSuccess: (payload) => ({
        type: "GET_USER_TYPE_SUCCESS",
        payload
    }),
    GetUserTypeError: (payload) => ({
        type: "GET_USER_TYPE_ERROR",
        payload
    })
};

export const fetchUserType = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_USER_TYPE;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetUserTypeSuccess(response.data.data));
                return response;

            }
            dispatch(actions.GetUserTypeError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetUserTypeError(error.message));
            return Promise.resolve(error);
        });
};
