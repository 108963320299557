import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createResponseTemplate,
    deleteCustomerResponseTemplate,
    editResponseTemplate,
    fetchCustomerResponseTemplates
} from "../../../../../actions/index";
import ResponseTemplates from "./responseTemplates";

const mapStateToProps = (state, ownProps) => ({
    responseTemplateLst: state.customer && state.customer.list ? state.customer.list[ownProps.currentCustomerId].responseTemplates : [],
    responsePermission: state.auth.permissions && state.auth.permissions.customer.responseTemplate.create,
    responseDelete: state.auth.permissions && state.auth.permissions.customer.responseTemplate.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerResponseTemplates,
            createResponseTemplate,
            editResponseTemplate,
            deleteCustomerResponseTemplate
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseTemplates);
