import './customerUserSendMailForm.scss';

import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Row} from 'react-materialize';

import {ALERT_MESSAGES, APPLICATION_MODES, FORM_OPERATIONS, PROPERTY_USERS,} from '../../../constants/appConstants';
import {TatvamButton, TatvamCol, TatvamMultiSelect,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerUserSendMailForm extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            preLoader: false,
            mailTemplateOptions: [{"label": "Welcome Mail", "value": "Welcome Mail"}],
            selectedMailTemplate: []
        };
    }


    _discard = data => {
        if (data === "ok") {
            this.props.gridApi.deselectAll();
            if (this.state.shouldCloseModal === true) {
                this.props.closeModal();
                this.setState({
                    applicationMode: APPLICATION_MODES.CREATE
                });
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };


    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.gridApi.deselectAll();
            this.props.closeModal();
        }
    };



    componentDidMount() {

    }

    componentWillReceiveProps(props) {

    }


    _sendMail = (data) => {
        if (data === "ok") {
            this.setState({
                isSendMailBoxOpen: false,
                preLoader: true
            });


            let userMap = new Map();
            let userNames = [];
            this.props.checkedRows.map(function (elem) {
                if (!userMap.has(elem.user_name)) {
                    userMap.set(elem.user_name, true);
                    userNames.push(elem.user_name);
                }
            });
            let reqObject = {"usernames": userNames, "mail_template": this.state.selectedMailTemplate.label};
            this.props.actions.sendUserMail(reqObject).then(response => {
                this.props.gridApi.deselectAll();
                if (response && response.status === 200) {
                    this.setState({
                        isAlertBoxOpen: true,
                        preLoader: false,
                        isSuccess: true,
                        alert_information: response.data.message,
                        shouldCloseModal: true,
                        notDiscard: true,
                        isConfirmBox: false
                    });
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        preLoader: false,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message,
                        notDiscard: true,
                        isConfirmBox: false
                    });
                }
            });
        } else {
            this.setState({
                isSendMailBoxOpen: false
            });
        }

    };

    _handleSave = e => {
        e.preventDefault();
        this._validations().then((response) => {
            if (this.state.isValid) {
                this.setState({
                    isConfirmBox: true,
                    isSendMailBoxOpen: true,
                    isSuccess: false,
                    alert_information: ALERT_MESSAGES.SEND_MAIL_CONFIRMATION
                });

            }
        });
    };


    _handleTemplateSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                template: true
            },
            selectedMailTemplate: selectedOption
        });
    };

    _validations = () => {
        this.setState({
            isValid: (this.state.selectedMailTemplate.length === 0 || this.state.selectedMailTemplate.label === PROPERTY_USERS.selectedMailTemplate) ? false : true
        });
        return Promise.resolve(true);
    }


    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <h4>{PROPERTY_USERS.SEND_MAIL}</h4>
                <Row className="modelForm webSource">
                    <TatvamCol s={12}>
                        <TatvamCol m={6} xl={6}>
                            <label htmlFor="source">{"Template"}</label>
                            <TatvamMultiSelect
                                classNamePrefix="react-select"
                                value={this.state.selectedMailTemplate}
                                name="template"
                                id="template"
                                onChange={this._handleTemplateSelect}
                                options={this.state.mailTemplateOptions}
                                placeholder={PROPERTY_USERS.SELECT_MAIL_TEMPLATE}
                            />
                        </TatvamCol>
                    </TatvamCol>
                </Row>
                <Row className="modalButons">
                    <TatvamCol s={12} m={12}>
                        <TatvamButton
                            waves="light"
                            className="mt-1  CreateProjectPublish btn_primary "
                            onClick={this._handleSave}
                            disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                        >
                            {FORM_OPERATIONS.SUBMIT}
                        </TatvamButton>
                        <TatvamButton waves="light" className="mt-1 mr-4 CreateProjectPublish btn_primary"
                                      onClick={this._triggerDiscard}>
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isConfirmBox={this.state.isConfirmBox}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    alert_information={this.state.alert_information}
                />
                {this.state.isSendMailBoxOpen && (
                    <AlertBox
                        open={this.state.isSendMailBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._sendMail}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default CustomerUserSendMailForm;
