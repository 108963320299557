import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteUserCustomer, fetchUserCustomer} from "../../../../../actions";
import CustomerRoleTabs from "./customerRoleTabs";

const mapStateToProps = (state, ownProps) => ({
    currentUserData: ownProps.currentUserData,
    customers: state.users.list[ownProps.currentUserName] && state.users.list[ownProps.currentUserName].customers,
    customersList: state.customer.list,
    rolePermission: state.auth.permissions && state.auth.permissions.user.customerRoles.create,
    roleDelete: state.auth.permissions && state.auth.permissions.user.customerRoles.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchUserCustomer,
            deleteUserCustomer
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRoleTabs);
