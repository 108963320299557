import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createCustomerDimension, fetchTagTreatment, updateCustomerDimension} from "../../../actions";
import CustomerDimensionForm from "./customerDimensionForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    dimensionPermission: state.auth.permissions && state.auth.permissions.customer.dimensions.create,
    tagTreatments: state.tagTreatment && state.tagTreatment.list
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createCustomerDimension,
            updateCustomerDimension,
            fetchTagTreatment
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDimensionForm);
