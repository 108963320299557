import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReportSub from "./reportSub";

const mapStateToProps = (state) => ({
    reportPermission: state.auth.permissions && state.auth.permissions.customer.property
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {},
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSub);
