import './competitorsForm.scss';

import React, {Component, Fragment,} from 'react';

/* eslint-disable no-console */
import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';
import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {ALERT_MESSAGES, COMPETITOR, FORM_OPERATIONS, VALIDATION_MESSAGES,} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {trimCapitalize} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';

class CompetitorsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            competitorsListOptions: [],
            competitorsValid: true,
            selectedValues: "",
            preLoader: false,
            is_insight: false,
        };
    }

    _selectCompetitor = selectedOption => {
        let competitorsValid = true;
        let number = "";
        this.setState({
            competitorsValid,
            number
        });
        this.setState({
            selectedValues: selectedOption,
            editFlag: {
                selectedValues: true
            },
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST
            });
        } else if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
            this.props.closeModal("alertCreateModal");
        } else {
            this.props.closeModal("alertViewModal");
        }
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    _discard = data => {
        if (data === "ok") {
            if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
                this.props.closeModal("alertCreateModal");
            } else {
                this.props.closeModal("alertViewModal");
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleSave = e => {
        e.preventDefault();
        let competitors = [];

        if (this.state.selectedValues) {
            const competitorData = {
                id: this.props.currentCustomer.id,
                competitors: [...competitors, this.state.selectedValues.value],
                is_insight: this.state.is_insight ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
            };

            this.setState({
                preLoader: true
            });

            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                this.state.selectedCompetitorValues.map((data) => {
                    competitors = [...competitors, data.value];
                });
                this.props.actions.saveCustomer(competitorData).then((response) => {
                    this.setState({
                        preLoader: false
                    });
                    if (response.status === 200) {
                        this.setAlertCompetitor(response, true);
                    } else {
                        this.setAlertCompetitor(response, false);
                    }
                });
            } else {
                const data = {
                    is_insight: this.state.is_insight ? APPLICATION_MODES.YES : APPLICATION_MODES.NO
                }

                this.props.actions.updateCustomerCompetitors(competitorData.id, this.props.selectedCompetitorData[0].id, data)
                    .then((response) => {
                        this.setState({
                            preLoader: false
                        });
                        if (response.status === 200) {
                            this.setAlertCompetitor(response, true);
                        } else {
                            this.setAlertCompetitor(response, false);
                        }
                    });
            }
        } else {
            this.setState({
                competitorsValid: false
            });

        }

    };

    setAlertCompetitor = (alertMessage, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMessage.data && alertMessage.data.message ? alertMessage.data.message : alertMessage.message
        });
    };

    componentDidMount() {
        const selectedCompetitorValues = [];
        let competitorsListOptions = [];

        if (this.props.competitorsList) {
            this.props.competitorsList.map(data => {
                selectedCompetitorValues.push({
                    label: data.name,
                    value: data.id,
                    industry: data.industry,
                    is_insight: data.is_insight
                });
            });
        }

        if (this.props.competitorsListOptions) {
            competitorsListOptions = this.props.competitorsListOptions.filter(val => (!this.props.selectedValues.includes(val.value) && val.value !== this.props.currentCustomer.id));

            competitorsListOptions = competitorsListOptions.map((item) => {
                item.label = item.full_name;
                return item;
            });
            competitorsListOptions.sort(dynamicSort("label"));
        }
        this.setState({
            competitorsListOptions,
            applicationMode: this.props.applicationMode,
            selectedValues: selectedCompetitorValues
        });

        if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({
                selectedValues: "",
                selectedCompetitorValues,
                is_insight: this.props.competitorsList.is_insight ? trimCapitalize(this.props.competitorsList.is_insight) === APPLICATION_MODES.YES : false,

            });
        } else if (this.props.applicationMode !== APPLICATION_MODES.CREATE) {
            this.setState({
                selectedValues: this.props.selectedCompetitorData[0],
                selectedCompetitorValues,
                competitorsListOptions: this.props.selectedCompetitorData[0],
                is_insight: this.props.selectedCompetitorData[0].is_insight ? trimCapitalize(this.props.selectedCompetitorData[0].is_insight) === APPLICATION_MODES.YES : false,
            });
        }
    }

    _handleInput = e => {
        if (e.target.id === "is_insight") {
            if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
                this.setState({
                    is_insight: e.target.checked,
                    editFlag: {
                        ...this.state.editFlag,
                        [e.target.id]: true
                    }
                });
            } else {
                const initial_insight_value = this.props.selectedCompetitorData[0].is_insight ? trimCapitalize(this.props.selectedCompetitorData[0].is_insight) === APPLICATION_MODES.YES : false;
                if (initial_insight_value === e.target.checked) {
                    this.setState({
                        is_insight: e.target.checked,
                        editFlag: {
                            ...this.state.editFlag,
                            [e.target.id]: false
                        }
                    });
                } else {
                    this.setState({
                        is_insight: e.target.checked,
                        editFlag: {
                            ...this.state.editFlag,
                            [e.target.id]: true
                        }
                    });
                }
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    status: true,
                    [e.target.name]: true
                }
            });
        }
    }

    render() {

        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{COMPETITOR.VIEW_TITLE_COMPETITOR}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{COMPETITOR.EDIT_COMPETITOR}</h4>) : ""}</Row>
                <Row>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <Row>
                            <div className="col s12 valign-wrapper mb-2">
                                <label htmlFor="competitor" className="col s2 competitor-label right-align">
                                    {COMPETITOR.COMPETITORS}<span style={{color: 'red'}}> *</span>:
                                </label>
                                <TatvamCol s={10} className="mt-.75">
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        value={this.state.selectedValues}
                                        name="selectedValues"
                                        id="competitor"
                                        onChange={this._selectCompetitor}
                                        options={this.state.competitorsListOptions}
                                        placeholder={COMPETITOR.SELECT_COMPETITORS}
                                        isDisabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                    />
                                </TatvamCol>
                            </div>

                            <TatvamCol m={12} className="d-flex">
                                <TatvamCol s={6} className="ml-5">
                                    <label htmlFor="industry"
                                           className="right-align tenant-label">{COMPETITOR.INDUSTRY} :</label>
                                    <span className="ml-5 s10">
                    {this.state.selectedValues && <span>{this.state.selectedValues.industry}</span>}
                  </span>
                                </TatvamCol>
                                <TatvamCol xl={6} className="mb-2">
                                    <Switch
                                        id="is_insight"
                                        name="is_insight"
                                        offLabel="Is Insight"
                                        checked={this.state.is_insight}
                                        onClick={this._handleInput}
                                        s={12}
                                    />
                                </TatvamCol>
                            </TatvamCol>
                        </Row>
                    ) : (
                        <Row>
                            <TatvamCol s={12} className="d-flex">
                                <span className="property-label col s2">{COMPETITOR.VIEW_COMPETITORS} :</span>
                                <span className="col s10">
                  {(this.props.selectedCompetitorData && this.props.selectedCompetitorData.length > 0 &&
                      <span className="mt-0 mb-0"> {this.props.selectedCompetitorData[0].label} </span>
                  )}
                </span>
                            </TatvamCol>

                            <TatvamCol m={12} className="d-flex">
                                <TatvamCol s={6} className="ml-5">
                                    <label className=" s2 property-label">{COMPETITOR.VIEW_INDUSTRY} :</label>

                                    <span className="ml-5">
                    {(this.props.selectedCompetitorData && this.props.selectedCompetitorData.length > 0 &&
                        <span> {this.props.selectedCompetitorData[0].industry} </span>
                    )}
                  </span>
                                </TatvamCol>
                                <TatvamCol xl={6} className="mb-2">
                                    <span className="property-label col s8"> {COMPETITOR.IS_INSIGHT} :</span>
                                    <span className="col s4">
                    {(this.props.selectedCompetitorData && this.props.selectedCompetitorData.length > 0 &&
                        <span> {this.props.selectedCompetitorData[0].is_insight} </span>
                    )}
                  </span>
                                </TatvamCol>
                            </TatvamCol>
                        </Row>
                    )}

                    <Row className="modalButtons">
                        <TatvamCol s={8} m={8}>
                            {/*ERROR MESSAGES*/}
                            <p className="errorText">
                                {/* {!this.state.competitorsValid && this.state.selectedValues && this.state.selectedValues.length > 0 &&
                  COMPETITOR.NO_OF_COMPETITOR_VALIDATON_1 + this.state.number + COMPETITOR.NO_OF_COMPETITOR_VALIDATON_2
                } */}
                                {!this.state.competitorsValid && VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS}
                            </p>
                        </TatvamCol>
                        <TatvamCol s={12} m={14} className="pr-2">
                            {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                    <TatvamButton
                                        waves="light"
                                        className="mt-1 CreateProjectPublish btn_primary "
                                        onClick={this._handleSave}
                                        disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                    >
                                        {FORM_OPERATIONS.SAVE}
                                    </TatvamButton>
                                )
                                :
                                this.props.competitorPermission === APP_BOOLEANS.TRUE && (
                                    <TatvamButton waves="light"
                                                  className="mt-1 CreateProjectPublish btn_primary "
                                                  name="EDIT"
                                                  onClick={this._handleAppMode}>
                                        {FORM_OPERATIONS.EDIT}
                                    </TatvamButton>
                                )
                            }
                            <TatvamButton waves="light" className="mt-1 mr-4 CreateProjectPublish btn_primary"
                                          onClick={this._triggerDiscard}>
                                {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                                !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                    FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                            </TatvamButton>
                        </TatvamCol>
                    </Row>
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                </Row>
            </Fragment>
        );
    }
}

export default CompetitorsForm;

