import './dashboard.scss';

import React, {Fragment} from 'react';

import {APPLICATION_MODES} from 'localConstants';
import Loader from 'react-loaders';
import {Col, Row, Switch,} from 'react-materialize';

import {Button} from '@material-ui/core';

import AlertBox from '../../components/applicationComponents/alertBox';
import CustomerGrid from '../../components/applicationComponents/customerGrid';
import CustomerList from '../../components/applicationComponents/customerList';
import {TatvamButton, TatvamCol,} from '../../components/functionalComponents';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preloader: true,
            customerStatus: APPLICATION_MODES.ACTIVE,
            viewGrid: false,
            isLoaded: false,
            viewLoad: 'CustomerList',
            categoryCustomer: "",
            license_pending: false
        };
    }

    handleDashboardBtn = e => {
        this.setState({
            customerStatus: e.target.value
        });
    };
    _loaderHandler = e => {
        this.setState({
            preloader: e
        });
    };
    handleGridView = () => {
        this.setState({
            viewGrid: !this.state.viewGrid
        });
    };
    _createCustomer = () => {
        this.props.history.push({
            pathname: "/customerdetails",
            state: {
                applicationMode: APPLICATION_MODES.CREATE,
                fromCategoryPage: this.state.categoryCustomer === "Dashboard" || this.state.categoryCustomer === "Group Instances" || this.state.categoryCustomer === "InActive" ? "" : this.state.categoryCustomer,
                isGroupInstance: this.state.categoryCustomer === "Group Instances"
            }
        });
    };
    _editCustomer = customerData => {
        this.props.history.push({
            pathname: "/customerdetails",
            state: {
                id: customerData[0].id,
                applicationMode: APPLICATION_MODES.VIEW,
                fromCategoryPage: this.state.categoryCustomer === "Dashboard" || this.state.categoryCustomer === "Group Instances" || this.state.categoryCustomer === "InActive" ? "" : this.state.categoryCustomer,
                isGroupInstance: this.state.categoryCustomer === "Group Instances"
            }
        });
    };

    scrollTop = () => {
        window.scrollTo(0, 0);
    };
    closeAlertBox = () => {
        this.setState({
            isAlertBoxOpen: false
        });
    };

    componentDidMount() {
        this.props.actions.fetchUserDetails().then(() => {
            if (this.props.userDetails && this.props.userDetails.profile && this.props.userDetails.profile.role) {
                if (this.props.userDetails.profile.role === "Admin" || this.props.userDetails.profile.role === "User") {
                    this.props.actions.fetchAllCustomersByUser().then(response => {
                        this.mountIntialize(response);
                    });
                } else {
                    this.props.actions.fetchAllCustomers().then(response => {
                        this.mountIntialize(response);
                    });
                }
            }
        });

    }

    mountIntialize = (response) => {
        this.setState({
            preloader: false
        });
        if (response && (response.status !== 200 && response.status !== 204)) {
            if (response.data && response.data.message) {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.data.message
                });
            } else {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.message ? response.data.message : response.data
                });
            }
        }
    };

    componentWillReceiveProps(props) {
        let customerList = [];

        if (props.customerList) {
            for (let data of Object.values(props.customerList)) {
                if (data.category === props.category) {
                    customerList = [...customerList, data];
                }
                if (props.history.location.pathname === "/dashboard" || props.history.location.pathname === "/groupinstances" || props.history.location.pathname === "/inactive-customers") {
                    customerList = [...customerList, data];
                }
            }
        }

        this.setState({
            isLoaded: true,
            customerList,
            categoryCustomer: props.category
        });
    }

    changeList = (listName) => {
        this.setState({viewLoad: listName});
    };
    renderCustomerList = () => {
        const listFlag = this.state.viewLoad;
        if (listFlag === 'CustomerGrid') {
            return <CustomerGrid customers={this.state.customerList} editCustomer={this._editCustomer}
                                 category={this.props.category}/>;
        } else {
            return <CustomerList customers={this.state.customerList} editCustomer={this._editCustomer}
                                 category={this.props.category}/>;
        }

    };


    _handleLicensePending = e => {
        let customerList = [];
        this.setState({
            license_pending: e.target.checked
        });
        console.log(this.state.categoryCustomer);
        if (e.target.checked) {
            for (let data of Object.values(this.props.customerList)) {
                if (this.state.categoryCustomer === "Live" || this.state.categoryCustomer === "Demo") {
                    if (data.valid_upto && data.category === this.state.categoryCustomer) {
                        let validUpto_date = data.valid_upto.substring(0, 10);
                        if (new Date(validUpto_date) < new Date()) {
                            customerList.push(data);
                        }
                    }
                } else if (this.state.categoryCustomer === "Group Instances") {
                    if (data.valid_upto && data.corporate === "Yes") {
                        let validUpto_date = data.valid_upto.substring(0, 10);
                        if (new Date(validUpto_date) < new Date()) {
                            customerList.push(data);
                        }
                    }
                }
            }
        } else {
            for (let data of Object.values(this.props.customerList)) {
                if (this.state.categoryCustomer === "Live" || this.state.categoryCustomer === "Demo") {
                    if (data.category === this.state.categoryCustomer) {
                        customerList.push(data);
                    }

                } else if (this.state.categoryCustomer === "Group Instances") {
                    if (data.corporate === "Yes") {
                        customerList.push(data);
                    }
                }
            }
        }

        this.setState({
            customerList
        });

    }


    render() {
        return (
            <div>
                {this.props.history.location.pathname !== "/dashboard" ? (
                    <Row>
                        {this.props.customerList && (
                            <Fragment>
                                <Row
                                    className={this.state.isLoaded ? "card-container" : "card-container" + " loader-wait"}>
                                    <Col s={12}
                                         className={this.state.preloader ? "valign-wrapper loader-overlay" : "hide"}>
                                        {this.state.preloader && <Loader type="ball-beat"/>}
                                    </Col>
                                    <Fragment>
                                        <div className="page_heading">
                                            <h5>
                                                {this.props.history.location.pathname === "/dashboard" ? "Dashboard" : this.props.category}
                                            </h5>
                                            <div className="dashboard-btn mt-1 valign-wrapper">
                                                {this.state.categoryCustomer !== "InActive" && this.state.categoryCustomer !== "Competitor"  && this.state.categoryCustomer !== "Tenants"&& (
                                                    <TatvamCol className="switch-wrapper">
                                                        <Switch
                                                            id="license_pending"
                                                            checked={this.state.license_pending}
                                                            onClick={this._handleLicensePending}
                                                            offLabel={"License Pending"}
                                                            m={6}
                                                        />
                                                    </TatvamCol>
                                                )}
                                                <Button variant="contained" color="primary" className="btn_primary"
                                                        onClick={() => this.changeList('CustomerList')}><i
                                                    className="material-icons">list</i>List</Button>
                                                <Button variant="contained" color="primary" className="btn_primary"
                                                        onClick={() => this.changeList('CustomerGrid')}><i
                                                    className="material-icons">view_module</i> Grid</Button>
                                                {this.props.customerCreate === "true" && this.state.categoryCustomer !== "InActive" && (
                                                    <TatvamButton className="left btn_primary add-button"
                                                                  onClick={this._createCustomer} title="Add Property">
                                                        <img
                                                            src={require("../../../public/assets/images/menuIcon/house.png")}
                                                            alt="Add property"/>
                                                    </TatvamButton>
                                                )
                                                }
                                            </div>
                                        </div>

                                        {this.state.customerStatus === APPLICATION_MODES.ACTIVE && (
                                            <Fragment>
                                                <div className="row  z-depth-4" ref="tableTop">
                                                    {this.renderCustomerList()}
                                                </div>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                </Row>
                            </Fragment>
                        )}
                    </Row>
                ) : (
                    <Row className="valign-wrapper commingSoon">
                        <TatvamCol s={6} className="card  offset-s3 valign-wrapper">
                            <h4 className="center-align col s12">This page is under construction</h4>
                        </TatvamCol>
                    </Row>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this.closeAlertBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </div>
        );
    }
}

export default Dashboard;
