const initialState = {
    list: {},
    sourcelist: {},
    error: {},
    apiDetails: {},
    industryClassificationList:[]
};

const SourceReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "FETCH_ALL_SOURCES_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;
        case "GET_API_SOURCES_DETAILS_SUCCESS":
            state = {
                ...state,
                apiDetails: action.payload
            };
            return state;
        case "FETCH_SOURCES_ERROR":
        case "DELETE_SOURCE_ONE_XPATH_ERROR":
        case "EDIT_SOURCE_API_ERROR":
        case "FETCH_ALL_SOURCES_BY_TYPE_ERROR":
        case "FETCH_SOURCE_ALL_XPATHS_ERROR":
        case "FETCH_SOURCE_ALL_INDUSTRY_ERROR":
        case "GET_SOURCE_ONE_XPATH_ERROR":
        case "CREATE_SOURCE_XPATH_ERROR":
        case "CREATE_SOURCE_INDUSTRY_ERROR":
        case "CREATE_SOURCE_API_ERROR":
        case "EDIT_SOURCE_XPATH_ERROR":
        case "EDIT_SOURCE_INDUSTRY_ERROR":
        case "DELETE_SOURCE_ALL_XPATH_ERROR":
        case "DELETE_SOURCE_INDUSTRY_ERROR":
        case "FETCH_XPATH_ALL_ERROR":
        case "GET_API_SOURCES_DETAILS_ERROR":
        case "EDIT_SOURCE_PREDICTO_ERROR":
        case "EDIT_CUSTOMER_INFLUENCERS_ERRORS":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        case "FETCH_ALL_SOURCES_BY_TYPE_SUCCESS":
            state = {
                ...state,
                sourcelist: action.payload
            };
            return state;
        case "FETCH_SOURCE_ALL_XPATHS_SUCCESS":
        case "EDIT_SOURCE_API_SUCCESS":
        case "EDIT_SOURCE_XPATH_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: action.payload
                }
            };
            return state;
        case "FETCH_SOURCE_ALL_INDUSTRY_SUCCESS":
        case "DELETE_SOURCE_INDUSTRY_SUCCESS":
            state = {
                ...state,
                industry: action.payload
            };
            return state;
        case "GET_SOURCE_ONE_XPATH_SUCCESS":
        case "DELETE_SOURCE_ALL_XPATH_SUCCESS":
        case "DELETE_SOURCE_ONE_XPATH_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.source_id]: action.payload
                }
            };
            return state;
        case "CREATE_SOURCE_XPATH_SUCCESS":
        case "CREATE_SOURCE_API_SUCCESS":

            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.source_id]: {
                        ...state.list[action.payload.source_id],
                        xpaths: [...state.list[action.payload.source_id].xpaths, action.payload]
                    }
                }
            };
            return state;

        case "CREATE_SOURCE_INDUSTRY_SUCCESS":
        case "EDIT_SOURCE_INDUSTRY_SUCCESS":
            state = {
                ...state,
                industry: [...state.industry, ...action.payload]
            };
            return state;

        case "FETCH_XPATH_ALL_SUCCESS":
            state = {
                ...state,
                xpaths: action.payload
            };
            return state;
        case "FETCH_SOURCE_ALL_INDUSTRY_CLASSIFICATION_SUCCESS":
            state={
                ...state,
                industryClassificationList:action.payload
            };
            return state;
        case "PURGE_STORE":
            return {};
        default:
            return state;
    }
};

export default SourceReducer;
