import React, {Fragment} from "react";
import {FILE_UPLOAD_CONSTANTS} from "../../../constants/appConstants";
import {TatvamButton, TatvamCol} from "../../functionalComponents/index";
import LinearProgress from '@material-ui/core/LinearProgress';
import {Box, Button, Typography, withStyles} from '@material-ui/core';
import "./uploadFile.scss";
import {TatvamMultiSelect} from "../../functionalComponents";
import {dynamicSort} from "../../../utils/objectUtil";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
            sizeExceedMessage: "",
            selectedFileName: undefined,
            disableFileUpload: true,
            selectedCategory: [],
            categoryListsOptions: []
        };

        this.selectFile = this.selectFile.bind(this);
        this.upload = this.upload.bind(this);
        this.reset = this.reset.bind(this);
    }


    componentDidMount() {
        this.props.actions.fetchFolderCategory().then(() => {
            this.loadCategoryDropdown(this.props);
        });
    }


    loadCategoryDropdown = (thisProps) => {
        const categoryLst = [];
        categoryLst.push({label: "", value: ""});
        thisProps.folders.map((data) => {
            categoryLst.push({label: data, value: data})

        });

        categoryLst.sort(dynamicSort("label"));
        categoryLst[0].label = FILE_UPLOAD_CONSTANTS.SELECT_CATEGORY;
        this.setState({
            categoryListsOptions: categoryLst
        });
    };


    componentWillReceiveProps(props) {
        if (props.folders) {
            this.loadCategoryDropdown(props);
        }
    }

    selectFile(event) {
        var fileSize = event.target.files[0] ? event.target.files[0].size / 1024 : 0;

        this.setState({
            progress: 0,
            selectedFiles: event.target.files[0],
            sizeExceedMessage: fileSize > 1024 ? FILE_UPLOAD_CONSTANTS.FILE_SIZE_EXCEED : undefined,
            message: "",
            selectedFileName: event.target.files[0] ? FILE_UPLOAD_CONSTANTS.SELECTED_FILE_NAME + event.target.files[0].name : "",
            disableFileUpload: fileSize > 1024 ? true : false
        });
    }


    upload() {
        if (this.state.selectedCategory.length === 0 || this.state.selectedCategory.label === FILE_UPLOAD_CONSTANTS.SELECT_CATEGORY) {
            this.setState({inValidForm: true});
        } else {
            this.setState({inValidForm: false});
            let uploadData = {};
            uploadData.currentFile = this.state.selectedFiles;
            uploadData.category = this.state.selectedCategory.label;
            console.log(uploadData.currentFile);
            console.log(uploadData.category);
            this.setState({
                progress: 0,
                currentFile: uploadData.currentFile,
                category: uploadData.category
            });

            this.props.actions.uploadUserFile(uploadData, (event) => {
                this.setState({
                    progress: Math.round((100 * event.loaded) / event.total),
                });
            })
                .then((response) => {
                    this.setState({
                        message: response.data.message,
                        isError: false,
                        selectedFileName: "",
                        disableFileUpload: true,
                        selectedCategory: []
                    });

                })
                .catch(() => {
                    this.setState({
                        progress: 0,
                        message: FILE_UPLOAD_CONSTANTS.FILE_UPLOAD_ERROR,
                        currentFile: undefined,
                        isError: true,
                        selectedFileName: "",
                        disableFileUpload: true,
                        selectedCategory: []
                    });
                });

            this.setState({
                selectedFiles: undefined,
            });
        }
    }

    reset() {
        this.setState({
            selectedFiles: undefined,
            progress: 0,
            sizeExceedMessage: "",
            message: "",
            selectedFileName: ""
        });
    }


    _handleCategorySelect = selectedOption => {
        this.setState({
            selectedCategory: selectedOption
        });
    };

    render() {
        return (
            <Fragment>
                <TatvamCol className="z-depth-2 centered mt-2 form-wrapper" s={12} m={12} l={12} xl={12}>
                    <div className="page_heading mt-0 ml-1">
                        <h5>Upload File</h5>
                    </div>

                    <TatvamCol s={6} m={6}>
                        <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
                            {(
                                <Box className="mb25" display="flex" alignItems="center">
                                    <Box width="100%" mr={1}>
                                        <BorderLinearProgress variant="determinate" value={this.state.progress}/>
                                    </Box>
                                    <Box minWidth={35}>
                                        <Typography variant="body2"
                                                    color="textSecondary">{`${this.state.progress}%`}</Typography>
                                    </Box>
                                </Box>)
                            }
                        </TatvamCol>
                        <TatvamCol m={6} className={"mt-2 select-field"}>
                            <label htmlFor="parent_entity">{"Select Category"}</label>
                            <TatvamMultiSelect
                                classNamePrefix="react-select"
                                className={(this.state.inValidForm && (this.state.selectedCategory.length === 0 || this.state.selectedCategory.label === FILE_UPLOAD_CONSTANTS.SELECT_CATEGORY)) ? "validate select-ht invalid-field" : "validate select-ht"}
                                value={this.state.selectedCategory}
                                name="file_category"
                                id="file_category"
                                onChange={this._handleCategorySelect}
                                options={this.state.categoryListsOptions}
                                placeholder={FILE_UPLOAD_CONSTANTS.SELECT_CATEGORY}
                            />
                        </TatvamCol>

                        <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
                            <i>Note: Max File Size 1MB</i>
                        </TatvamCol>
                        <TatvamCol s={12} m={12} l={12} xl={12} className="project_name">
                            <label htmlFor="btn-upload">
                                <input
                                    id="btn-upload"
                                    name="btn-upload"
                                    style={{display: 'none'}}
                                    type="file"
                                    onChange={this.selectFile}
                                    value={""}/>
                                <Button
                                    className="btn-choose btn-no-mrg"
                                    variant="outlined"
                                    component="span">
                                    Choose File
                                </Button>
                            </label>
                            <TatvamButton
                                waves="light"
                                className="btn_primary waves ml-2"
                                disabled={this.state.disableFileUpload}
                                onClick={this.upload}>
                                Upload
                            </TatvamButton>

                            <TatvamButton
                                waves="light"
                                className="btn_primary waves ml-2"
                                onClick={this.reset}>
                                Reset
                            </TatvamButton>
                        </TatvamCol>
                        <TatvamCol s={12} m={12} l={12} xl={12} className="project_name">
                            <Typography variant="subtitle2" className={`upload-message`}>
                                {this.state.selectedFileName}
                            </Typography>
                            <Typography variant="subtitle2"
                                        className={`upload-message ${this.state.isError ? "error" : ""}`}>
                                {this.state.message}
                            </Typography>
                            <Typography variant="subtitle2"
                                        className={`upload-message ${this.state.sizeExceedMessage ? "error" : ""}`}>
                                {this.state.sizeExceedMessage}
                            </Typography>
                        </TatvamCol>
                    </TatvamCol>
                </TatvamCol>
            </Fragment>
        );
    }
}

export default UploadFile;
