import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchCustomerReportSubscription} from "../../../../../actions/index";
import CustomerReportSubscription from "./customerReportSubscription";

const mapStateToProps = (state, ownProps) => ({
    reportSubscriptionLst: state.customer && state.customer.list[ownProps.currentCustomerId].reportSubscriptions ? state.customer.list[ownProps.currentCustomerId].reportSubscriptions : []
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerReportSubscription
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReportSubscription);
