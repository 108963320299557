import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerReportSubscription,
    fetchApplicationNames,
    fetchCustomerUserDetails,
    getChildProperties,
    getSubscriptionReports,
    setGlobalFilter,
    updateCustomerReportSubscription
} from "../../../actions";
import CustomerReportSubscriptionForm from "./customerReportSubscriptionForm";

const mapStateToProps = (state, ownProps) => ({
    customerUserDetailsLst: state.customer.list[ownProps.currentCustomerId] && state.customer.list[ownProps.currentCustomerId].customerUserDetails ? state.customer.list[ownProps.currentCustomerId].customerUserDetails : [],
    subscriptionReports: state.subscriptionReports && state.subscriptionReports.list,
    childProperties: state.childProperties && state.childProperties.list,
    customerList: state.customer.list,
    userName: state.auth.userName,
    globalFilter: state.globalFilter,
    applicationLst: state.applicationName.list
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerUserDetails,
            getSubscriptionReports,
            getChildProperties,
            createCustomerReportSubscription,
            updateCustomerReportSubscription,
            setGlobalFilter,
            fetchApplicationNames
        },
        dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerReportSubscriptionForm);
