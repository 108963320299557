import {apiUrl} from 'apiConstants';
import axios from 'axios';

const actions = {
    GetAllOperationsSuccess: (payload) => ({
        type: "FETCH_OPERATIONS_SUCCESS",
        payload
    }),
    GetAllOperationsError: (payload) => ({
        type: "FETCH_OPERATIONS_ERROR",
        payload
    })
};

export const fetchAllOperations = () => (dispatch, getState) => {
    const state = getState();
    if (state.operations.list.length > 0) {
        return Promise.resolve(true);
    }
    const url = apiUrl.BASE_URL + apiUrl.GET_OPERATIONS;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetAllOperationsSuccess(response.data.data));
                return response;
            }

            dispatch(actions.GetAllOperationsError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetAllOperationsError(error.message));
            return error.message;
        });
};
