import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchAllUsers} from '../../../../actions';
import UserDetails from './userDetails';

const mapStateToProps = (state, ownProps) => ({
    profile: state.auth.profile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        fetchAllUsers
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

