import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import FileUpload from "./fileUpload";

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {},
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
