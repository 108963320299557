import React from 'react';
import './predictionDetails.scss';
import {TatvamButton, TatvamTextBox} from "../../../functionalComponents";
import {Table} from 'antd';
import {ALERT_MESSAGES, API_SOURCE} from "appConstants";
import AlertBox from "../../alertBox";

class PredictionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            selectedKey: '',
            columns: []
        }
    };

    handleDelete = (key) => {
        this.setState({
            isConfirmBox: true,
            isAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION,
            shouldCloseModal: true,
            selectedKey: key
        });
    };

    _discard = (data) => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                const dataSource = [...this.state.dataSource];
                this.setState({
                    dataSource: dataSource.filter((item) => item.key !== this.state.selectedKey),
                    isConfirmBox: false,
                    isAlertBoxOpen: false,
                    isSuccess: false
                });
                this.props.onSubmit(dataSource.filter((item) => item.key !== this.state.selectedKey));
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    handleChange = (key, prop) => ({target}) => {
        this.updateRow(key, {[prop]: target.value});
    };

    updateRow = (key, partialRecord) => {
        const dataSource = this.state.dataSource.map(record => {
            let nxtRecord = record;
            if (record.key === key) {
                nxtRecord = {...nxtRecord, ...partialRecord};
            }
            return nxtRecord;
        });
        this.setState({
            dataSource: dataSource
        });
        this.props.onSubmit(dataSource);
    };

    componentDidMount() {
        this.setState({
            dataSource: Object.entries(this.props.apiDetails).map((input, index) => {
                return {key: index, rowKey: input[0], rowValue: input[1]}
            })
        })
    }

    getColumns = () => {
        let columns = [
            {
                title: "Key",
                dataIndex: "rowKey",
                width: '30%',
                render: (rowKey, {key}) => {
                    return (
                        <TatvamTextBox
                            m={12}
                            name={rowKey}
                            onChange={this.handleChange(key, 'rowKey')}
                            value={rowKey}
                            disabled={this.props.disabled}
                            autocomplete={"off"}
                            className={(this.props.inValidForm && rowKey === "") ? "labelText validate mb-1 invalid" : "labelText validate mb-1"}
                            required
                            validate
                        />
                    );
                }
            },
            {
                title: "Value",
                dataIndex: "rowValue",
                width: '60%',
                render: (rowValue, {key}) => {
                    return (
                        <TatvamTextBox
                            m={12}
                            name={rowValue}
                            onChange={this.handleChange(key, 'rowValue')}
                            value={rowValue}
                            disabled={this.props.disabled}
                            autocomplete={"off"}
                            className="labelText mb-1"
                        />
                    );
                }
            }];

        if (!this.props.disabled) {
            columns.push({
                title: 'Delete',
                width: '10%',
                render: (_, record) =>
                    this.state.dataSource.length >= 1 ? (
                        <a disabled={this.props.disabled} className="deleteColumn col-pad"
                           onClick={() => !this.props.disabled ? this.handleDelete(record.key) : null}><i
                            className="material-icons"
                            title="Delete"
                        >delete
                        </i></a>
                    ) : null,
            });
        }

        return columns;
    }

    componentDidUpdate(prevProps) {
        if (this.props.apiDetails !== prevProps.apiDetails) {
            this.setState({
                dataSource: Object.entries(this.props.apiDetails).map((input, index) => {
                    return {key: index, rowKey: input[0], rowValue: input[1]}
                })
            });
        }
    }

    handleAdd = () => {
        const dataSource = [...this.state.dataSource, {
            key: this.state.dataSource.length, ...{
                rowKey: "",
                rowValue: "",
            }
        }]

        this.setState({
            dataSource: dataSource
        });
        this.props.onSubmit(dataSource);
    };

    handleSave = () => {
        const dataSource = this.state.dataSource.map(record => {
            return record;
        })
        this.setState({
            dataSource: dataSource
        });
        this.props.onSubmit(dataSource);
    };

    render() {
        const {dataSource} = this.state;
        const columns = this.getColumns();

        return (
            <div className={"pt-1"}>
                {!this.props.disabled && (
                    <TatvamButton
                        waves="light"
                        className="mb-1 btn_primary right"
                        name="EDIT"
                        onClick={this.handleAdd}
                        disabled={this.props.disabled}
                    >
                        {API_SOURCE.ADD_NEW_ROW}
                    </TatvamButton>
                )}

                <Table
                    bordered
                    pagination={false}
                    columns={columns}
                    dataSource={dataSource}
                    id={"source_table"}
                />
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </div>
        );
    }
}

export default PredictionDetails;

