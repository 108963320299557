import 'babel-polyfill';
import 'materialize-css';
import '../public/assets/sass/style.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import CacheBuster from 'react-cache-buster';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import AppLayout from './appLayout';
import history, {persistor, store} from './utils/helpers';

const isProduction = process.env.NODE_ENV === 'production';

const version = process.env.TAG || "3.0.0";

const root = document.getElementById("root");

const renderApp = () => {
    ReactDOM.render(
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction}
            isVerboseMode={false}
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {/*<BrowserRouter>*/}
                    <AppLayout history={history}/>
                    {/*</BrowserRouter>*/}
                </PersistGate>
            </Provider>
        </CacheBuster>
        , root
    );
};

renderApp();
