import './sideMenu.scss';

/* eslint-disable no-tabs,react/no-array-index-key */
/* eslint-disable import/no-dynamic-require */
import React, {Component, Fragment,} from 'react';

import {Collapsible, CollapsibleItem, Row,} from 'react-materialize';
import {NavLink} from 'react-router-dom';

import {Icon} from '@material-ui/core';

import {alertMessages} from '../../../../constants/apiConstants';
import AlertBox from '../../alertBox';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: true,
            isSubCollap: false
        };
    }

    toggleMenuBtn = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    };
    __onMenuClick = () => {
        this.setState({
            isMenuOpen: true
        });
    };

    componentDidMount() {
        // Fetch data needed by the component by raising an action
    }

    componentWillUnmount() {
        // 1.check whether changes are compeleted
        // 2.alert the user that unsaved data will be lost if they have any uncompeleted changes
    }

    _discard = data => {
        if (data === "ok") {
            this.props.actions.UserSignOut();
        } else {
            this.setState({
                isConfirmBox: false,
                isAlertBoxOpen: false,
                isSuccess: false
            });
        }
    };

    _logoutConfirmationHandler = () => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: false,
            isConfirmBox: true,
            alert_information: alertMessages.LOGOUT_CONFIRMATION_MESSAGE
        });
    };

    _handleSubCollap = () => {
        this.setState({
            isSubCollap: !this.state.isSubCollap
        });
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.isMenuOpen ? "sideMenuCollapsed" : "sideMenuUnCollapsed"}>
                    {/* menu icons to open and close */}
                    <a className="menu-toggle" onClick={this.toggleMenuBtn}>
                        {this.state.isMenuOpen ? (
                            <i className="material-icons left menu pointer">menu</i>
                        ) : (
                            <i className="material-icons right pointer">close</i>
                        )}
                    </a>

                    {/* <div className={!(this.state.isMenuOpen) ? "customerLogo" : "customerLog collapsed_logo"}> */}
                    {/* <img src={require("../../../../../public/assets/images/default_customer.png")}/> */}
                    {/* <p className={"text-light"}> Hi, user name </p> */}
                    {/* </div> */}

                    <ul className="left-align sideNav-vertical">
                        <Fragment>
                            {this.props.menuList && this.props.menuList.map((data, index) => {

                                return (
                                    <li key={index}>
                                        {!this.state.isMenuOpen ?
                                            !data.sub && (
                                                <NavLink
                                                    onClick={data.name !== "Logout" ? this.__onMenuClick : this._logoutConfirmationHandler}
                                                    to={data.name !== "Logout" ? data.url : ""}
                                                    data-menuid={index}
                                                    data-position="right"
                                                    data-tooltip={data.name}
                                                    className="greyBorder "
                                                    activeclassname="activeMenu"
                                                >
                                                    <img
                                                        src={require(`../../../../../public/assets/images/menuIcon/white/${(data.icon)}.png`)}
                                                        alt={data.name}/>

                                                    {!this.state.isMenuOpen && (
                                                        <span>
                              {data.name}
                            </span>
                                                    )}
                                                </NavLink>
                                            ) :
                                            !data.sub && (
                                                <NavLink
                                                    onClick={data.name !== "Logout" ? this.__onMenuClick : this._logoutConfirmationHandler}
                                                    to={data.name !== "Logout" ? data.url : "#"}
                                                    className="greyBorder tooltipped"
                                                    activeClassName="activeMenu"
                                                >
                                                    <img
                                                        src={require(`../../../../../public/assets/images/menuIcon/white/${(data.icon)}.png`)}
                                                        alt={data.name}/>

                                                    {(data.name !== "Masters") &&
                                                        <span className="menu-toolTip">{data.name}</span>}
                                                </NavLink>
                                            )
                                        }

                                        {
                                            data.sub && this.state.isMenuOpen && (
                                                <Fragment>
                                                    <div className="master-menu">
                                                        <img
                                                            src={require(`../../../../../public/assets/images/menuIcon/white/${(data.icon)}.png`)}
                                                            alt={data.name}/>
                                                        {!this.state.isMenuOpen && (
                                                            <Fragment>
                                <span className="d-flex ">
                                  {data.name}
                                </span>
                                                                <Icon>chevron_right</Icon>
                                                            </Fragment>
                                                        )
                                                        }
                                                    </div>
                                                    <ul className="sub-menus">
                                                        {this.state.isMenuOpen && <li className="title"> {data.name} </li>}
                                                        {data.sub.map((val, idx) => {
                                                            return (

                                                                val.sub ? (
                                                                        <Collapsible accordion className="menuAccord ">
                                                                            <CollapsibleItem
                                                                                expanded={false}
                                                                                header={val.name}
                                                                                icon={<img
                                                                                    src={require(`../../../../../public/assets/images/menuIcon/white/${(val.icon)}.png`)}
                                                                                    alt={data.name}/>}
                                                                                node="div"
                                                                            >
                                                                                {val.sub.map((menu, i) => {
                                                                                    return (
                                                                                        <li key={i}>
                                                                                            {!this.state.isMenuOpen ?
                                                                                                (
                                                                                                    <NavLink
                                                                                                        onClick={this.__onMenuClick}
                                                                                                        to={menu.url}
                                                                                                        data-menuid={idx}
                                                                                                        data-position="right"
                                                                                                        data-tooltip={menu.name}
                                                                                                        className="greyBorder"
                                                                                                        activeClassName="activeMenu"
                                                                                                    >
                                                                                                        <img
                                                                                                            src={require(`../../../../../public/assets/images/menuIcon/white/${(menu.icon)}.png`)}
                                                                                                            alt={menu.name}
                                                                                                        />

                                                                                                        <span className="sub-1">
                                                    {menu.name}
                                                  </span>
                                                                                                    </NavLink>

                                                                                                ) :
                                                                                                (
                                                                                                    <NavLink
                                                                                                        onClick={this.__onMenuClick}
                                                                                                        className="greyBorder"
                                                                                                        data-menuid={idx}
                                                                                                        to={menu.url}
                                                                                                        activeClassName="activeMenu"
                                                                                                    >
                                                                                                        <img
                                                                                                            src={require(`../../../../../public/assets/images/menuIcon/white/${(menu.icon)}.png`)}
                                                                                                            className="ml-0 mr-1"
                                                                                                            alt={menu.name}
                                                                                                        />
                                                                                                        <p> {menu.name}</p>
                                                                                                    </NavLink>
                                                                                                )}
                                                                                        </li>
                                                                                    );
                                                                                })}
                                                                            </CollapsibleItem>
                                                                        </Collapsible>
                                                                    )
                                                                    : (

                                                                        <li key={idx}>
                                                                            {!this.state.isMenuOpen ?
                                                                                (
                                                                                    <NavLink
                                                                                        onClick={this.__onMenuClick}
                                                                                        to={val.url}
                                                                                        data-menuid={idx}
                                                                                        data-position="right"
                                                                                        data-tooltip={val.name}
                                                                                        className="greyBorder"
                                                                                        activeClassName="activeMenu"
                                                                                    >
                                                                                        <img
                                                                                            src={require(`../../../../../public/assets/images/menuIcon/white/${(val.icon)}.png`)}
                                                                                            alt={val.name}
                                                                                        />

                                                                                        <span className="sub-1">
                                              {val.name}
                                            </span>
                                                                                    </NavLink>

                                                                                ) :
                                                                                (
                                                                                    <NavLink
                                                                                        onClick={this.__onMenuClick}
                                                                                        className="greyBorder"
                                                                                        data-menuid={idx}
                                                                                        to={val.url}
                                                                                        activeClassName="activeMenu"
                                                                                    >
                                                                                        <img
                                                                                            src={require(`../../../../../public/assets/images/menuIcon/white/${(val.icon)}.png`)}
                                                                                            className="ml-0 mr-1"
                                                                                            alt={val.name}
                                                                                        />
                                                                                        <p> {val.name}</p>
                                                                                        {/*<span className="menu-toolTip">{val.name} </span>*/}
                                                                                    </NavLink>
                                                                                )}
                                                                        </li>
                                                                    )
                                                            );
                                                        })
                                                        }
                                                    </ul>
                                                </Fragment>
                                            )}
                                        {
                                            data.sub && !this.state.isMenuOpen &&
                                            (
                                                <Collapsible accordion className="menuAccord">
                                                    <CollapsibleItem
                                                        expanded={false}
                                                        header={data.name}
                                                        icon={<img
                                                            src={require(`../../../../../public/assets/images/menuIcon/white/${(data.icon)}.png`)}
                                                            alt={data.name}/>}
                                                        node="div"
                                                    >
                                                        {data.sub.map((val, idx) => {
                                                            return (
                                                                val.sub ?
                                                                    (
                                                                        <li className="subAccord">
                                                                            <a className=" collapsible-header "
                                                                               onClick={this._handleSubCollap}>
                                                                                <img
                                                                                    src={require(`../../../../../public/assets/images/menuIcon/white/${(val.icon)}.png`)}
                                                                                    alt={data.name}/>
                                                                                {val.name}
                                                                            </a>
                                                                            <div
                                                                                className={this.state.isSubCollap ? "collapsible-body show" : "collapsible-body hide"}>
                                                                                {val.sub.map((menu, i) => {
                                                                                    return (
                                                                                        <NavLink
                                                                                            onClick={this.__onMenuClick}
                                                                                            to={menu.url}
                                                                                            data-menuid={idx}
                                                                                            data-position="right"
                                                                                            data-tooltip={menu.name}
                                                                                            className="greyBorder"
                                                                                            activeClassName="activeMenu"
                                                                                        >
                                                                                            <img
                                                                                                src={require(`../../../../../public/assets/images/menuIcon/white/${(menu.icon)}.png`)}
                                                                                                alt={menu.name}
                                                                                            />

                                                                                            <span className="sub-1">
                                                {menu.name}
                                              </span>
                                                                                        </NavLink>

                                                                                    );
                                                                                })
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    )

                                                                    :
                                                                    (
                                                                        <NavLink
                                                                            onClick={this.__onMenuClick}
                                                                            to={val.url}
                                                                            data-menuid={idx}
                                                                            data-position="right"
                                                                            data-tooltip={val.name}
                                                                            className="greyBorder"
                                                                            activeClassName="activeMenu"
                                                                        >
                                                                            <img
                                                                                src={require(`../../../../../public/assets/images/menuIcon/white/${(val.icon)}.png`)}
                                                                                alt={val.name}
                                                                            />

                                                                            <span className="sub-1">
                                        {val.name}
                                      </span>
                                                                        </NavLink>
                                                                    )
                                                            );
                                                        })}
                                                    </CollapsibleItem>
                                                </Collapsible>
                                            )
                                        }
                                    </li>
                                );
                            })
                            }
                            <ul className="left-align sideNav-vertical mt-0">
                                <li>
                                    <a className="greyBorder" onClick={this._logoutConfirmationHandler}>
                                        <img
                                            src={require(`../../../../../public/assets/images/menuIcon/white/logout.png`)}
                                            alt="logout"/>
                                        {/* <Icon>exit_to_app</Icon> */}
                                        {!this.state.isMenuOpen ? (
                                            <span>Logout</span>
                                        ) : (<span className="menu-toolTip">Logout</span>)}
                                    </a>
                                </li>
                            </ul>
                        </Fragment>
                    </ul>
                </Row>
                {!this.state.isMenuOpen && <div className="sidenav-overlay" onClick={this.toggleMenuBtn}/>}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        isConfirmBox={this.state.isConfirmBox}
                        closeAlertBox={this._discard}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default SideMenu;
