import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createIndustry, deleteIndustry, updateIndustry} from "../../../actions";
import SourceIndustryForm from "./sourceIndustryForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createIndustry,
            updateIndustry,
            deleteIndustry
        },
        dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(SourceIndustryForm);
