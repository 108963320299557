import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createCustomerAlertRules, editCustomerAlertRules} from "../../../actions";
import CustomerRuleForm from "./customerRuleForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    rulePermission: state.auth.permissions && state.auth.permissions.customer.rules.create
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createCustomerAlertRules,
            editCustomerAlertRules
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRuleForm);
