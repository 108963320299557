import React from "react";
import {BaseCard} from "../../baseComponents";

const TatvamCard = (props) => {
    return (
        <BaseCard {...props} />
    )
}

export default TatvamCard;
