import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    EditCustomerUserSuccess: payload => ({
        type: "EDIT_CUSTOMER_USER_SUCCESS",
        payload
    }),
    EditCustomerUserError: payload => ({
        type: "EDIT_CUSTOMER_USER_ERROR",
        payload
    }),

    GetCustomerUserSuccess: payload => ({
        type: "FETCH_CUSTOMER_USER_SUCCESS",
        payload
    }),
    GetCustomerUserError: payload => ({
        type: "FETCH_CUSTOMER_USER_ERROR",
        payload
    }),
    CreateUserSuccess: payload => ({
        type: "CREATE_CUSTOMER_USER_SUCCESS",
        payload
    }),
    CreateUserError: payload => ({
        type: "CREATE_CUSTOMER_USER_ERROR",
        payload
    }),
    DeleteUserSuccess: payload => ({
        type: "CREATE_CUSTOMER_USER_SUCCESS",
        payload
    }),
    DeleteUserError: payload => ({
        type: "CREATE_CUSTOMER_USER_ERROR",
        payload
    }),
    GetAllUserCustomerSuccess: payload => ({
        type: "FETCH_ALL_USER_CUSTOMER_SUCCESS",
        payload
    }),
    GetAllUserCustomerError: payload => ({
        type: "FETCH_ALL_USER_CUSTOMER_ERROR",
        payload
    }),
    GenegarteApiKeySucess: payload => ({
        type: "GENERATE_USER_API_KEY_SUCESS",
        payload
    }),
    GenegarteApiKeyError: payload => ({
        type: "GENERATE_USER_API_KEY_ERROR",
        payload
    })
};

export const editCustomerUsers = user => (dispatch, getState) => {
    const state = getState();
    const customer = state.customer.list[user.customer_id];
    const payload = user;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_USERS.format(user.customer_id, user._id);

    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.users.filter(data => data._id !== response.data.data[0]._id);
                let updatedUserData = [...response.data.data, ...newCustomerData];

                customerData = {
                    ...customerData,
                    users: updatedUserData
                };

                dispatch(actions.EditCustomerUserSuccess(customerData));
                return response;
            }

            dispatch(actions.EditCustomerUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.EditCustomerUserError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerUser = id => (dispatch, getState) => {

    if (id) {
        const state = getState();
        const customer = state.customer.list;
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_USERS.format(id);
        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    customer[id] = {
                        ...customer[id],
                        users: response.data.data
                    };

                    dispatch(actions.GetCustomerUserSuccess(customer));
                    return response;
                }

                dispatch(actions.GetCustomerUserError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerUserError(error.message));
                return Promise.resolve(error.response);
            });
    }
};

export const createCustomerUsers = user => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[user.customer_id];
    const payload = user;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_USERS.format(user.customer_id);
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = JSON.parse(JSON.stringify(customer));
                if (customerData.users) {
                    if (customerData.users !== undefined) {
                        customerData.users.push(response.data.data[0]);
                    }
                } else {
                    customerData.users = [];
                    customerData.users.push(response.data.data[0]);
                }

                dispatch(actions.CreateUserSuccess(customerData));
                return response;
            }

            dispatch(actions.CreateUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.CreateUserError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteCustomerUser = user => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[user.customer_id];
    const payload = user;
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_USERS.format(user.customer_id, user._id);
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = JSON.parse(JSON.stringify(customer));
                if (customerData.users) {
                    if (customerData.users !== undefined) {
                        let usersAfterDelete = [];
                        customerData.users.map((userData) => {
                            if ((userData.user_name === user.user_name && userData.roles[0] === user.roles[0])) {
                                return true;
                            } else {
                                usersAfterDelete.push(userData);
                            }

                        });
                        customerData["users"] = usersAfterDelete;
                    }
                } else {
                    customerData["users"] = [];
                }
                dispatch(actions.DeleteUserSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteUserError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchAllUserCustomer = () => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.user_customers;
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_USER_CUSTOMERS;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                customer = {
                    ...customer,
                    user_customers: response.data.data
                };
            } else {
                customer = {
                    ...customer,
                    user_customers: []
                };
            }
            dispatch(actions.GetAllUserCustomerSuccess(customer));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetAllUserCustomerError(error.message));
            return Promise.resolve(error.response);
        });

};


export const editCustomerUsersRole = user => (dispatch, getState) => {
    const state = getState();
    const customer = state.customer.list[user.customer_id];
    const payload = user;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_USERS.format(user.customer_id, user.user_name);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.roles.filter(data => data._id === response.data.data[0]._id);
                let updatedUserData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    users: updatedUserData
                };

                dispatch(actions.EditCustomerUserSuccess(customerData));
                return response;
            }

            dispatch(actions.EditCustomerUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.EditCustomerUserError(error.message));
            return Promise.resolve(error.response);
        });
};

export const generateApiKey = generateKey => (dispatch) => {
    const payload = generateKey;
    const url = apiUrl.BASE_URL + apiUrl.GENERATE_API_KEY;
    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                const apikeyData = response.data;
                dispatch(actions.GenegarteApiKeySucess(apikeyData));
                return response;
            }

            dispatch(actions.GenegarteApiKeyError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GenegarteApiKeyError(error.message));
            return Promise.resolve(error.response);
        });
};
