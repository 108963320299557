import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchAllUserCustomer, fetchAllUsers,} from '../../actions';
import UserDashboard from './userDashboard';

const mapStateToProps = (state) => ({
    userPermission: state.auth.permissions && state.auth.permissions.user.create,
    userList: state.users.list,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllUsers,
            fetchAllUserCustomer,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
