import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerUsers,
    editCustomerUsers,
    fetchAllReports,
    fetchAllUserName,
    fetchAllUsers,
    fetchCustomerUser,
    generateApiKey
} from "../../../actions";
import CustomerUserForm from "./customerUserForm";

const mapStateToProps = (state) => ({
    userList: state.users.list,
    userName: state.auth.userName,
    userPermission: state.auth.permissions && state.auth.permissions.customer.role_users.create,
    allReports: state.reports.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            editCustomerUsers,
            createCustomerUsers,
            fetchAllUsers,
            fetchAllUserName,
            fetchAllReports,
            fetchCustomerUser,
            generateApiKey,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUserForm);
