import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './tatvamGridStyle.scss';

/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';

import {BaseGrid} from '../../baseComponents';
import ColumnContextMenu from './columnMenu';
import DeleteColumn from './deleteColumn';

class TatvamGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                height: "100%"
            },
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                minWidth: 100,
            },
            frameworkComponents: {
                DeleteColumn,
                ColumnContextMenu
            },
            isDataAvailable: true,
            suppressCellSelection: true,
            enableEditColumn: false,
            enableDeleteColumn: false,
            enableSizeToFit: true,
            enableAutoHeight: true,
            enableFloatingFilter: true,
            rowData: this.props.gridData,
            selectedRows: [],
            headerHeight: 20,
            enableRowClick: false,
            rowSelection: "single",
            overlayLoadingTemplate: `<span class="ag-overlay-loading-center ">loading...</span>`,
            overlayNoRowsTemplate:
                `<span class="ag-no-data">'No Data'</span>`,
            rowClassRules: {}
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridOptions = params.gridOptions;
        this.gridApi.setHeaderHeight(35);
        if (this.state.enableSizeToFit) {
            this.sizeToFit();
            this.gridApi.sizeColumnsToFit();
        }

        if (this.state.enableAutoHeight) {
            this.setAutoHeight();
        }

        if (this.props.sortColumnName && this.props.sortColumnName !== "" && this.props.sortOrder && this.props.sortOrder !== "") {
            var sort = [
                {
                    colId: this.props.sortColumnName,
                    sort: this.props.sortOrder
                }
            ];
            this.gridColumnApi.getColumn(this.props.sortColumnName).gridApi.setSortModel(sort);
        }

        if (this.state.enableEditColumn) {
            this.editColumn();
        }
        if (this.state.enableDeleteColumn) {
            this.deleteColumn();
        }

        if (this.state.enableFloatingFilter) {
            this.floatingFilter();
        }
    };
    floatingFilter = () => {
        if (!this.state.enableFloatingFilter) {
            console.error("tatvamGrid: floating filter is not enabled.");

        }
    };
    editColumn = () => {
        if (!this.state.enableEditColumn) {
            console.error("tatvamGrid: edit column is not enabled.");
            return;
        }

        if (!this.props.onRowEdit) {
            console.error("tatvamGrid: onRowEdit callback function is not defined.");
            return;
        }

        if (typeof this.props.onRowDelete !== "function") {
            console.error("tatvamGrid: onRowEdit should be a callback function.");
            return;
        }

        this.addColumn({
            headerName: "Edit",
            cellRenderer: "EditColumn",
            filter: false,
            sortable: false,
            resizable: false,
            suppressMovable: true,
            width: 100,
            cellRendererParams: {editAction: this.props.onRowEdit}
        });
    };
    deleteColumn = () => {
        if (!this.state.enableDeleteColumn) {
            console.error("tatvamGrid: delete column is not enabled.");
            return;
        }

        if (!this.props.onRowDelete) {
            console.error("tatvamGrid: onRowDelete callback function is not defined.");
            return;
        }

        if (typeof this.props.onRowDelete !== "function") {
            console.error("tatvamGrid: onRowDelete should be a callback function.");
            return;
        }
        this.addColumn({
            headerName: "Delete",
            cellRenderer: "DeleteColumn",
            filter: false,
            sortable: false,
            resizable: false,
            suppressMovable: true,
            width: 120,
            cellRendererParams: {deleteAction: this.props.onRowDelete}
        });
    };
    /**
     * Set new rows into the grid.
     */
    setRowData = rowData => {
        if (this.state.enablePagination) {
            this.gridOptions.api.setDatasource(rowData);
        } else {
            this.gridOptions.api.setRowData(rowData);
        }
    };
    /**
     * Get the grid data
     */
    getData = () => {
        const rowData = [];
        this.gridApi.forEachNode((node) => {
            rowData.push(node.data);
        });
        return rowData;
    };
    /**
     * Get the grid data after filter applied
     */
    getDataAfterFilter = () => {
        const rowData = [];
        this.gridApi.forEachNodeAfterFilter((node) => {
            rowData.push(node.data);
        });
        return rowData;
    };
    /**
     * Get the grid data after filter and sort applied
     */
    getDataAfterFilterAndSort = () => {
        const rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort((node) => {
            rowData.push(node.data);
        });
        return rowData;
    };
    setColumnDefs = (columnDefs) => {
        this.gridApi.setColumnDefs(columnDefs);
    };
    getColumnDefs = () => this.gridColumnApi.columnController.columnDefs;
    addColumn = (column) => {
        const columnDefs = this.getColumnDefs();
        const checkDuplicate = columnDefs.filter((coldefs) => coldefs.cellRenderer === "DeleteColumn");
        if (checkDuplicate.length === 0) {
            columnDefs.push(column);
        }
        this.setColumnDefs(columnDefs);
        this.sizeToFit();
    };
    refreshView = () => {
        this.gridApi.refreshView();
    };
    onRowSelection = () => {

    };
    /**
     * Returns a list of selected rows
     */
    getSelectedRows = () => this.gridApi.getSelectedRows();
    setSelectedRows = () => {
    };
    selectRowByIndex = () => {
    };
    /**
     *  Select all rows (even rows that are not visible due to grouping being enabled and their groups not expanded).
     */
    selectAllRows = () => {
        this.gridOptions.api.selectAll();
        this.selectedRows = this.getSelectedRows();
    };
    /**
     * ExportCSV
     */

    exportCSV = (params) => {
        this.gridApi.exportDataAsCsv(params);
    };
    /**
     * Clear all row selections.
     */
    deSelectAllRows = () => {
        this.gridOptions.api.deselectAll();
        this.selectedRows = [];
    };
    onColumnSelection = () => {
    };
    getSelectedColumns = () => {
    };
    setSelectedColumns = () => {
    };
    selectColumnByIndex = () => {
    };
    selectAllColumn = () => {
    };
    deSelectAllColumns = () => {
    };
    sizeToFit = () => {
        if (!this.state.enableSizeToFit) {
            console.error("tatvamGrid:size to fit is not enabled.");
            return;
        }
        this.gridApi.sizeColumnsToFit();
    };
    autoSizeAll = () => {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
    };
    setAutoHeight = () => {
        if (!this.state.enableAutoHeight) {
            console.error("tatvamGrid: auto height is not enabled.");
            return;
        }
        this.gridApi.setDomLayout("autoHeight");
    };
    _exportCSVData = (data) => {
        this.gridApi.exportDataAsCsv(data);
    };

    rowSelect(event) {
        let selectedRows = this.getSelectedRows();
        let checkedRows = this.getSelectedRows();
        if (selectedRows.length === 0) {
            selectedRows = [event.node.data];
            checkedRows = [];
        }
        if (this.props.onRowSelect) {
            this.props.onRowSelect(selectedRows, checkedRows);
        }
    }


    rowCheckBoxClicked() {
        const selectedRows = this.getSelectedRows();
        if (this.props.onCheckBoxSelect) {
            this.props.onCheckBoxSelect(selectedRows, this.gridApi);
        }
    }

    onFilterChanged() {
        let rowCount = this.gridApi.getDisplayedRowCount();
        if (rowCount <= 0) {
            this.gridApi.showNoRowsOverlay();
            this.setState({
                isDataAvailable: false
            });
        } else {
            this.gridApi.hideOverlay();
            this.setState({
                isDataAvailable: true
            });
        }
    }

    onFilterModified() {
        return (this.gridApi.getModel().rootNode.childrenAfterFilter.length <= 0 && this.state.overlayNoRowsTemplate);
    }

    onSortChanged() {
        let data = {};
        if (this.gridApi && this.gridApi.getSortModel() && this.gridApi.getSortModel()[0]) {
            this.setState({
                customSortColumn: this.gridApi.getSortModel()[0].colId,
                customSortOrder: this.gridApi.getSortModel()[0].sort
            });
            data.customSortColumn = this.gridApi.getSortModel()[0].colId;
            data.customSortOrder = this.gridApi.getSortModel()[0].sort

        } else {
            this.setState({
                customSortColumn: "",
                customSortOrder: ""
            });
            data.customSortColumn = "";
            data.customSortOrder = "";
        }
        if (this.props.handleStateChange) {
            this.props.handleStateChange(data);
        }
    }

    componentDidMount() {
        if (this.props.colData) {
            this.setState({
                colData: this.props.colData
            });
        }
        if (this.props.enableEditColumn) {
            this.setState({
                enableEditColumn: this.props.enableEditColumn
            });
        }
        if (this.props.enableCheckBoxCol) {
            this.setState({
                colData: [
                    {
                        "headerCheckboxSelection": true,
                        "headerCheckboxSelectionFilteredOnly": true,
                        "checkboxSelection": true,
                        "width": 50
                    },
                    ...this.props.colData
                ]
            });
        }
        if (this.props.isExportCsv) {
            const param = {
                onlySelected: "true"
            };
            this._exportCSVData(param);
        }
        if (this.props.rowSelection) {
            this.setState({
                rowSelection: this.props.rowSelection
            });
        }


        if (this.props.enablePagination) {
            this.setState({
                enablePagination: true
            });
        }
        if (this.props.enableDeleteColumn) {
            this.setState({
                enableDeleteColumn: this.props.enableDeleteColumn
            });
        }

        if (this.props.enableRowClick) {
            this.setState({
                enableRowClick: this.props.enableRowClick
            });
        }
        if (this.props.height) {
            this.setState({
                style: {
                    ...this.state.style,
                    height: this.props.height
                }
            });
        }

        if (this.props.enableSelectionChanged) {
            this.setState({
                enableSelectionChanged: this.props.enableSelectionChanged
            });
        }

        if (this.props.rowClassRules) {
            this.setState({
                rowClassRules: this.props.rowClassRules
            });
        }
    }

    componentWillReceiveProps(props) {
        if (props.colData) {
            this.setState({
                colData: props.colData
            });
        }
        if (props.enableCheckBoxCol) {
            this.setState({
                colData: [

                    {
                        "headerCheckboxSelection": true,
                        "headerCheckboxSelectionFilteredOnly": true,
                        "checkboxSelection": true,
                        "width": 50
                    },
                    ...props.colData
                ]
            });
        }

        if (props.gridData) {
            this.setState({
                rowData: props.gridData
            });
        }

        if (props.enablePagination) {
            this.setState({
                enablePagination: true
            });
        }
        if (props.enableEditColumn) {
            this.setState({
                enableEditColumn: props.enableEditColumn
            });
        }

        if (props.enableDeleteColumn) {
            this.setState({
                enableDeleteColumn: props.enableDeleteColumn
            });
        }

        if (props.enableRowClick) {
            this.setState({
                enableRowClick: props.enableRowClick
            });
        }
        if (props.isExportCsv) {
            const param = {
                onlySelected: "true"
            };
            this._exportCSVData(param);
        }

        if (props.rowSelection) {
            this.setState({
                rowSelection: props.rowSelection
            });
        }

        if (props.suppressRowClickSelection) {
            this.setState({
                suppressRowClickSelection: props.suppressRowClickSelection
            });
        }
        if (props.height) {
            this.setState({
                style: {
                    ...this.state.style,
                    height: props.height
                }
            });
        }


        if (props.rowClassRules) {
            this.setState({
                rowClassRules: props.rowClassRules
            });
        }
    }

    setWidthAndHeight(width, height) {
        this.setState((oldState) => Object.assign(oldState, {
            style: {
                width,
                height
            }
        }));
    }

    onCellKeyPress = e => {
        if (e.event.keyCode === 32) {
            const selectedRows = this.getSelectedRows();
            if (this.props.onRowSelect) {
                this.props.onRowSelect(selectedRows);
            }
        }
    };

    render() {
        return (
            <div style={{height: "100%"}}>
                <div
                    style={{height: "100%"}}
                    className="ag-theme-material tatvam-grid"
                >
                    <BaseGrid
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.props.gridData}
                        columnDefs={this.state.colData}
                        frameworkComponents={this.state.frameworkComponents}
                        rowSelection={this.state.rowSelection}
                        suppressDragLeaveHidesColumns
                        suppressRowClickSelection={this.state.suppressRowClickSelection}
                        onGridReady={this.onGridReady.bind(this)}
                        suppressMenuHide
                        onRowDoubleClicked={this.rowSelect.bind(this)}
                        onSelectionChanged={this.state.enableSelectionChanged ? this.rowCheckBoxClicked.bind(this) : null}
                        onCellKeyPress={this.onCellKeyPress.bind(this)}
                        paginationPageSize={15}
                        rowHeight={25}
                        suppressCellSelection={false}
                        getSelectedRows={this.state.getSelectedRows}
                        pagination={this.props.enablePagination && this.state.isDataAvailable}
                        overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        onFilterModified={this.onFilterModified.bind(this)}
                        onSortChanged={this.onSortChanged.bind(this)}
                        rowClassRules={this.state.rowClassRules}
                        alwaysShowHorizontalScroll={true}
                        alwaysShowVerticalScroll={true}
                    />
                </div>
            </div>

        );
    }
}

export default TatvamGrid;

