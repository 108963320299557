export * from './message';
export * from './filter';
export * from './stautscodes';
export * from './periodicity';

export const APPLICATION_NAME = 'Tatvam 3.0';
export const APPLICATION_TAG = `${process.env.TAG}`;
export const API_ENDPOINT = `/api`; //Proxying in nginx
export const IMAGE_CDN_ENDPOINT = `/images/inapp-client`; //Proxying in nginx
export const USAGE_LOG_ENDPOINT = `/`;
export const SESSION_TIMEOUT = process.env.SESSION_TIMEOUT
    ? Number(process.env.SESSION_TIMEOUT)
    : 10; //seconds
