import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchAllCustomerSources, fetchAllSources, fetchAllSourcesByType, fetchXpathsAll,} from '../../../actions';
import SourceMasters from './sourceMaster';

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    sourceList: state.sources.sourcelist,
    xpathsList: state.sources ? state.sources.xpaths : [],
    customerSourceList: state.customer ? state.customer.customerSources : [],
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllSources,
            fetchAllSourcesByType,
            fetchXpathsAll,
            fetchAllCustomerSources
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceMasters);
