/* eslint-disable no-console */
import React, {Component, Fragment,} from 'react';

import {connect} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {UserSignOut} from './actions';
import Routes from './routes/mainRoute';

const mapStateToProps = state => ({
    authToken: state && state.auth && state.auth.authToken
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            UserSignOut
        },
        dispatch
    )
});

class App extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        window.addEventListener("storage", this._onStorageChange, false);
    }

    _onStorageChange = () => {
        const val = localStorage.getItem("authToken");
        if (!val) {
            this.props.actions.UserSignOut();
        }
    };

    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <Routes/>
                    {/*<Footer/>*/}
                </BrowserRouter>
                {/*{this.props.preLoader && <div className="loader-overlay"> Text sample</div>}*/}
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
