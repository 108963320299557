/* eslint-disable react/jsx-no-target-blank */
import React, {Fragment} from "react";
import {FOOTER} from "../../../../constants/appConstants";
import "./footer.scss";

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>
                <footer className={this.props.className + " page-footer "}>
                    <div className="footer-copyright">
                        <div className="container">
              <span>
                ©2019 {FOOTER.POWERED_BY}
                  <a href="https://www.netserv-appl.com/" target="_blank"> NetServ Applications</a>
                , {FOOTER.ALL_RIGHTS_RESERVED}.
              </span>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;
