import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Row} from 'react-materialize';

import {ALERT_MESSAGES, APP_STATUS, FORM_OPERATIONS, PROPERTY_CHILDREN,} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';

class PropertiesForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            propertyListOptions: [],
            selectedValues: [],
            preLoader: false,
            isValid: true,
            editFlag: false
        };
    }

    _selectProperty = (selectedOption) => {
        this.setState({
            selectedValues: selectedOption,
            editFlag: true
        });
    };
    _triggerSubmit = () => {
        this.refs.submit.click();
    };
    _discard = data => {
        if (data === "ok") {
            this.props.closeModal();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });

    };

    _triggerDiscard = () => {
        if (this.state.editFlag) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST
            });
        } else {
            this.props.closeModal();
        }
    };
    _handleSave = (e) => {
        e.preventDefault();
        let children = [];
        if (Object.values(this.state.selectedValues).length > 0) {
            this.setState({
                isValid: true
            });
            let allSelectedValues = [...this.state.selectedValues, ...this.state.selectedPropertyData];
            for (let data of allSelectedValues) {
                children = [...children, data.value];
            }
            const property = {
                id: this.props.customerId,
                children
            };
            this.setState({preLoader: true});
            this.props.actions.saveCustomer(property).then(response => {
                this.setState({preLoader: true});
                if (response && response.status === 200) {
                    if (response.data && response.data.message) {
                        this.setAlertProperties(response.data.message, true);
                    } else {
                        this.setAlertProperties(response.message, true);
                    }
                } else {
                    this.setAlertProperties(response.message, false);
                }
            });
        } else {
            this.setState({
                isValid: false
            });
        }
    };

    setAlertProperties = (alertMessage, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMessage
        });
    };

    componentDidMount() {
        let propertyListOptions = [];
        let selectedPropertyData = [];
        if (this.props.customerList) {
            let properties = JSON.parse(JSON.stringify(this.props.customerList));
            delete properties[this.props.customerId];
            if (this.props.selectedPropertyData) {
                this.props.selectedPropertyData.map((data) => {
                    delete properties[data.id];
                });
            }
            if (Array.isArray(this.props.parent_customer_id)) {
                this.props.parent_customer_id.map((data) => {
                    delete properties[data];
                });
            } else {
                delete properties[this.props.parent_customer_id];
            }
            for (let data of Object.values(properties)) {
                // if (data.category !== "Competitor") {
                    if (data.status && data.status !== APP_STATUS.INACTIVE)
                        propertyListOptions.push({label: (data.id + " - " + data.name), value: data.id});
                // }
            }
        }
        if (this.props.selectedPropertyData) {
            this.props.selectedPropertyData.map((data) => {
                selectedPropertyData = [...selectedPropertyData, {
                    label: (data.id + " - " + data.name),
                    value: data.id
                }];
            });
            propertyListOptions.sort(dynamicSort("label"));
            this.setState({
                propertyListOptions,
                selectedPropertyData
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12}
                               className={this.state.preLoader ? "valign-wrapper property-form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row>
                    <form onSubmit={this._handleSave}>
                        <div className="col s12 valign-wrapper">
                            <label htmlFor="property" className="col s2 property-label">
                                {PROPERTY_CHILDREN.PROPERTIES} :
                            </label>
                            <TatvamCol m={10} className="mt-.75">
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    value={this.state.selectedValues}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="properties"
                                    onChange={this._selectProperty}
                                    options={this.state.propertyListOptions}
                                    placeholder={PROPERTY_CHILDREN.SELECT_PROPERTIES}
                                />
                                <p className="errorText"> {!this.state.isValid && "This field is mandatory"}</p>
                            </TatvamCol>
                        </div>
                        <button type="submit" ref="submit" className="hide">
                            Submit
                        </button>
                    </form>
                </Row>

                <Row className="pr-2 mt-2">
                    <TatvamButton
                        waves="light"
                        className="mt-1  CreateProjectPublish btn_primary "
                        onClick={this._triggerSubmit}
                        disabled={!this.state.editFlag}
                    >
                        {FORM_OPERATIONS.SAVE}
                    </TatvamButton>

                    <TatvamButton
                        waves="light"
                        className="mt-1 mr-1 CreateProjectPublish btn_primary waves"
                        onClick={this._triggerDiscard}
                    >
                        {(this.state.editFlag ? FORM_OPERATIONS.DISCARD : FORM_OPERATIONS.CLOSE)}
                    </TatvamButton>

                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>

        );
    }
}

export default PropertiesForm;
