import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetAllSourcesSuccess: (payload) => ({
        type: "FETCH_ALL_SOURCES_SUCCESS",
        payload
    }),
    GetAllSourcesError: (payload) => ({
        type: "FETCH_ALL_SOURCES_ERROR",
        payload
    }),
    GetAllSourcesByTypeSuccess: (payload) => ({
        type: "FETCH_ALL_SOURCES_BY_TYPE_SUCCESS",
        payload
    }),
    GetAllSourcesByTypeError: (payload) => ({
        type: "FETCH_ALL_SOURCES_BY_TYPE_ERROR",
        payload
    }),
    GetSourcesAllXpathSuccess: (payload) => ({
        type: "FETCH_SOURCE_ALL_XPATHS_SUCCESS",
        payload
    }),
    GetSourcesAllIndustrySuccess: (payload) => ({
        type: "FETCH_SOURCE_ALL_INDUSTRY_SUCCESS",
        payload
    }),
    GetSourcesAllIndustryClassificationSuccess: (payload) => ({
        type: "FETCH_SOURCE_ALL_INDUSTRY_CLASSIFICATION_SUCCESS",
        payload
    }),
    GetSourcesAllXpathError: (payload) => ({
        type: "FETCH_SOURCE_ALL_XPATHS_ERROR",
        payload
    }),
    GetSourcesAllIndustryError: (payload) => ({
        type: "FETCH_SOURCE_ALL_INDUSTRY_ERROR",
        payload
    }),
    GetSourcesAllIndustryClassificationError: (payload) => ({
        type: "FETCH_SOURCE_ALL_INDUSTRY_CLASSIFICATION_ERROR",
        payload
    }),
    GetSourcesOneXpathSuccess: (payload) => ({
        type: "GET_SOURCE_ONE_XPATH_SUCCESS",
        payload
    }),
    GetSourcesOneXpathError: (payload) => ({
        type: "GET_SOURCE_ONE_XPATH_ERROR",
        payload
    }),
    CreateSourceXpathSuccess: (payload) => ({
        type: "CREATE_SOURCE_XPATH_SUCCESS",
        payload
    }),
    CreateSourceXpathError: (payload) => ({
        type: "CREATE_SOURCE_XPATH_ERROR",
        payload
    }),
    CreateSourceIndustrySuccess: (payload) => ({
        type: "CREATE_SOURCE_INDUSTRY_SUCCESS",
        payload
    }),
    CreateSourceIndustryError: (payload) => ({
        type: "CREATE_SOURCE_INDUSTRY_ERROR",
        payload
    }),
    CreateSourceAPISuccess: (payload) => ({
        type: "CREATE_SOURCE_API_SUCCESS",
        payload
    }),
    CreateSourceAPIError: (payload) => ({
        type: "CREATE_SOURCE_API_ERROR",
        payload
    }),
    EditSourceAPISuccess: (payload) => ({
        type: "EDIT_SOURCE_API_SUCCESS",
        payload
    }),
    EditSourceAPIError: (payload) => ({
        type: "EDIT_SOURCE_API_ERROR",
        payload
    }),
    EditSourceXpathSuccess: (payload) => ({
        type: "EDIT_SOURCE_XPATH_SUCCESS",
        payload
    }),
    EditSourceXpathError: (payload) => ({
        type: "EDIT_SOURCE_XPATH_ERROR",
        payload
    }),
    EditSourceIndustrySuccess: (payload) => ({
        type: "EDIT_SOURCE_INDUSTRY_SUCCESS",
        payload
    }),
    EditSourceIndustryError: (payload) => ({
        type: "EDIT_SOURCE_INDUSTRY_ERROR",
        payload
    }),
    DeleteSourcesAllXpathSuccess: (payload) => ({
        type: "DELETE_SOURCE_ALL_XPATH_SUCCESS",
        payload
    }),
    DeleteSourcesAllXpathError: (payload) => ({
        type: "DELETE_SOURCE_ALL_XPATH_ERROR",
        payload
    }),
    DeleteSourcesOneXpathSuccess: (payload) => ({
        type: "DELETE_SOURCE_ONE_XPATH_SUCCESS",
        payload
    }),
    DeleteSourcesOneXpathError: (payload) => ({
        type: "DELETE_SOURCE_ONE_XPATH_ERROR",
        payload
    }),
    DeleteSourceIndustrySuccess: (payload) => ({
        type: "DELETE_SOURCE_INDUSTRY_SUCCESS",
        payload
    }),
    DeleteSourceIndustryError: (payload) => ({
        type: "DELETE_SOURCE_INDUSTRY_ERROR",
        payload
    }),
    GetXpathAllSuccess: (payload) => ({
        type: "FETCH_XPATH_ALL_SUCCESS",
        payload
    }),
    GetXpathAllError: (payload) => ({
        type: "FETCH_XPATH_ALL_ERROR",
        payload
    }),
    GetApiSourceDetailsSuccess: (payload) => ({
        type: "GET_API_SOURCES_DETAILS_SUCCESS",
        payload
    }),
    GetApiSourceDetailsError: (payload) => ({
        type: "GET_API_SOURCES_DETAILS_ERROR",
        payload
    }),
    GetPredictoSourcesSuccess: (payload) => ({
        type: "GET_PREDICTION_SOURCES_SUCCESS",
        payload
    }),
    GetPredictoSourceError: (payload) => ({
        type: "GET_PREDICTION_SOURCES_ERROR",
        payload
    }),
    GetCustomerPredictoSourcesSuccess: (payload) => ({
        type: "GET_CUSTOMER_PREDICTION_SOURCES_SUCCESS",
        payload
    }),
    GetCustomerPredictoSourceError: (payload) => ({
        type: "GET_CUSTOMER_PREDICTION_SOURCES_ERROR",
        payload
    }),
    CreatePredictoSourceSuccess: (payload) => ({
        type: "CREATE_SOURCE_PREDICTO_SUCCESS",
        payload
    }),
    CreatePredictoSourceError: (payload) => ({
        type: "CREATE_SOURCE_PREDICTO_ERROR",
        payload
    }),
    EditPredictoSourceSuccess: (payload) => ({
        type: "EDIT_SOURCE_PREDICTO_SUCCESS",
        payload
    }),
    EditPredictoSourceError: (payload) => ({
        type: "EDIT_SOURCE_PREDICTO_ERROR",
        payload
    }),
    DeleteCustomerPredictorSourceSuccess: (payload) => ({
        type: "DELETE_CUSTOMER_PREDICTO_SOURCE_SUCCESS",
        payload
    }),
    DeleteCustomerPredictorSourceErrors: (payload) => ({
        type: "DELETE_CUSTOMER_PREDICTO_SOURCE_ERRORS",
        payload
    })
};

export const getApiSourceDetails = (sourceId) => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_API_SOURCE_DETAILS.format(sourceId);
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetApiSourceDetailsSuccess(response.data.data[0].details));
                return response;
            }
            if (response.status === 204) {
                dispatch(actions.GetApiSourceDetailsSuccess([]));
                return response;
            }
            dispatch(actions.GetApiSourceDetailsError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetApiSourceDetailsError(error.message));
            return Promise.resolve(error);
        });
};

export const getPredictoSourceDetails = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_PREDICTO_SOURCE_DETAILS;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetPredictoSourcesSuccess(response.data.data[0].details));
                return response;
            }
            if (response.status === 204) {
                dispatch(actions.GetPredictoSourcesSuccess([]));
                return response;
            }
            dispatch(actions.GetPredictoSourceError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetPredictoSourceError(error.message));
            return Promise.resolve(error);
        });
};

export const getCustomerPredictoSourceDetails = (customerId) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[customerId];
    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_PREDICTO_SOURCE_DETAILS.format(customerId);
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData,
                    predictoSourceData: response.data.data
                };
                dispatch(actions.GetCustomerPredictoSourcesSuccess(customerData));
                return response;
            }
            dispatch(actions.GetCustomerPredictoSourceError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetCustomerPredictoSourceError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchAllSources = () => (dispatch, getState) => {
    const state = getState();
    if (state.sources) {
        if (state.sources.list) {
            if (Object.values(state.sources.list).length > 0) {
                return Promise.resolve(true);
            }
        }
    }
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_CUSTOMER_SOURCES;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const srcList = {};
                response.data.data.map((data) => {
                    srcList[data.id] = data;
                });

                dispatch(actions.GetAllSourcesSuccess(srcList));
                return response;

            }

            dispatch(actions.GetAllSourcesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetAllSourcesError(error.message));
            return Promise.resolve(error);
        });
};

export const fetchAllSourcesByType = (data) => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_CUSTOMER_SOURCES_BY_TYPE.format(data.type);
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const srcWebList = {};
                response.data.data.map((record) => {
                    srcWebList[record.id] = record;
                });
                dispatch(actions.GetAllSourcesByTypeSuccess(srcWebList));
                return response;

            }
            dispatch(actions.GetAllSourcesByTypeError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetAllSourcesByTypeError(error.message));
            return Promise.resolve(error);
        });
};

export const fetchAllXpaths = (sourceId) => (dispatch, getState) => {
    const state = getState();
    let source = state.sources.sourcelist[sourceId];
    const url = apiUrl.BASE_URL + apiUrl.GET_SOURCE_ALL_XPATHS.format(sourceId);

    return axios
        .get(url)
        .then(response => {
            let data = [];
            if (response.status === 200) {
                data = response.data.data;
            }

            source = {
                ...source,
                xpaths: data
            };
            dispatch(actions.GetSourcesAllXpathSuccess(source));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetSourcesAllXpathError(error.message));
            return Promise.resolve(error.response);
        });

};

export const fetchAllIndustry = () => (dispatch, getState) => {
    const state = getState();
    let source = state.sources.industry;
    const url = apiUrl.BASE_URL + apiUrl.GET_SOURCE_ALL_INDUSTRY;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                source = {
                    ...source,
                    industry: response.data.data
                };
                dispatch(actions.GetSourcesAllIndustrySuccess(source));
                return response;
            }

            dispatch(actions.GetSourcesAllIndustryError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetSourcesAllIndustryError(error.message));
            return Promise.resolve(error);
        });
};

export const fetchAllIndustryClassification = (tenantParentId) => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.TENANT_INDUSTRY_CLASSIFICATION.format(tenantParentId);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetSourcesAllIndustryClassificationSuccess(response.data.data));
                return response;
            }

            dispatch(actions.GetSourcesAllIndustryClassificationError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetSourcesAllIndustryClassificationError(error.message));
            return Promise.resolve(error);
        });
};

export const fetchOneXpath = (xpath) => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_SOURCE_ONE_XPATH.format(xpath.source_id, xpath.name);
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetSourcesOneXpathSuccess(response.data.data));
            } else {
                dispatch(actions.GetSourcesOneXpathError(response.message));
            }
            return response;
        })
        .catch(error => {
            dispatch(actions.GetSourcesOneXpathError(error.message));
            return Promise.resolve(error);
        });
};

export const createXpath = (xpath) => (dispatch) => {
    const payload = xpath;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_SOURCE_XPATH.format(xpath.source_id, xpath.name);
    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.CreateSourceXpathSuccess(response.data.data[0]));
                return response;
            }


            dispatch(actions.CreateSourceXpathError(response.message));
            return response.data;
        })
        .catch(error => {
            dispatch(actions.CreateSourceXpathError(error.message));
            return Promise.resolve(error.response);
        });
};
export const createIndustry = (industry) => (dispatch, getState) => {
    const state = getState();
    if (state.sources && state.sources.industry === undefined) {
        state.sources.industry = [];
    }
    const payload = industry;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_SOURCE_INDUSTRY.format(industry.industry_id, industry.name);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.CreateSourceIndustrySuccess(response.data.data[0]));
                return response;
            }
            dispatch(actions.CreateSourceIndustryError(response.message));
            return response.data;
        })
        .catch(error => {
            dispatch(actions.CreateSourceIndustryError(error.message));
            return Promise.resolve(error.response);
        });
};
export const createAPISource = (apiSource) => (dispatch) => {
    const payload = apiSource;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_SOURCE_API_DETAILS.format(apiSource.source_id, apiSource.name);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.CreateSourceAPISuccess(response.data.data[0]));
                return response;
            }

            dispatch(actions.CreateSourceAPIError(response.message));
            return response.data;
        })
        .catch(error => {
            dispatch(actions.CreateSourceAPIError(error.message));
            return Promise.resolve(error.response);
        });
};

export const createPredictoSource = (predictoSource, custId) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[custId];
    const payload = predictoSource[0];
    const url = apiUrl.BASE_URL + apiUrl.CREATE_PREDICTO_SOURCE_INDUSTRY.format(custId);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200 || response.status === 304) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.predictoSourceData) {
                    customerData["predictoSourceData"] = [...customerData.predictoSourceData, ...response.data.data];
                } else {
                    customerData["predictoSourceData"] = [...response.data.data];
                }

                dispatch(actions.CreatePredictoSourceSuccess(customerData));
                return response;
            }

            dispatch(actions.CreatePredictoSourceError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.CreatePredictoSourceError(error.message));
            return Promise.resolve(error.response);
        });
};

export const updateAPISource = (apiSource) => (dispatch, getState) => {
    const payload = apiSource;
    const store = getState();
    let source = store.sources.list[apiSource.source_id];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_SOURCE_API_DETAILS.format(apiSource.source_id, apiSource.name);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let sourceData = source;
                let newSourceData = source.xpaths.filter(data => data.name !== response.data.data[0].name);
                let updatedSourceData = [...response.data.data, ...newSourceData];
                sourceData = {
                    ...sourceData,
                    xpaths: updatedSourceData
                };

                dispatch(actions.EditSourceAPISuccess(sourceData));
                return response;
            }

            dispatch(actions.EditSourceAPIError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.EditSourceAPIError(error.message));
            return Promise.resolve(error);
        });
};

export const updatePredictoSource = (predictoSource, custId, sourceId) => (dispatch, getState) => {
    const payload = predictoSource[0];
    const store = getState();
    const customer = store.customer.list[custId];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_PREDICTO_SOURCE_INDUSTRY.format(custId, sourceId);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200 || response.status === 304) {

                let customerData = customer;
                let newCustomerData = customerData.predictoSourceData.filter(data => data.id !== sourceId);
                let updatedCustomerPredictorSourceData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    predictoSourceData: updatedCustomerPredictorSourceData
                };

                dispatch(actions.EditPredictoSourceSuccess(customerData));
                return response;
            }

            dispatch(actions.EditPredictoSourceError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.EditPredictoSourceError(error.message));
            return Promise.resolve(error.response);
        });
};

export const updateXpath = (xpath) => (dispatch, getState) => {
    const payload = xpath;
    const store = getState();
    let source = store.sources.list[xpath.source_id];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_SOURCE_XPATH.format(xpath.source_id, xpath.name);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let sourceData = source;
                let newSourceData = source.xpaths.filter(data => data.name !== response.data.data[0].name);
                let updatedSourceData = [...response.data.data, ...newSourceData];
                sourceData = {
                    ...sourceData,
                    xpaths: updatedSourceData
                };

                dispatch(actions.EditSourceXpathSuccess(sourceData));
                return response;
            }

            dispatch(actions.EditSourceXpathError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.EditSourceXpathError(error.message));
            return Promise.resolve(error.response);
        });
};

export const updateIndustry = (industry) => (dispatch, getState) => {
    const payload = industry;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_SOURCE_INDUSTRY.format(industry.industry_id, industry.name);

    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.EditSourceIndustrySuccess(response.data.data[0]));
                return response;
            }

            dispatch(actions.EditSourceIndustryError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.EditSourceXpathError(error.message));
            return Promise.resolve(error);
        });
};

export const deleteOneXpath = (xpath) => (dispatch, getState) => {
    const payload = xpath;
    const store = getState();
    let source = store.sources.list[xpath.source_id];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_SOURCE_XPATH.format(xpath.source_id, xpath.name);
    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let newSourceData = source.xpaths.filter(data => data.name !== xpath.name);
                source.xpaths = newSourceData;
                dispatch(actions.DeleteSourcesAllXpathSuccess(source));
                return response;
            }

            dispatch(actions.DeleteSourcesAllXpathError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteSourcesAllXpathError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteAllXpath = (xpath) => (dispatch) => {
    const payload = xpath;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_SOURCE_XPATH.format(xpath.source_id);
    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.DeleteSourcesOneXpathSuccess(response.data.data));
                return response;
            }

            dispatch(actions.DeleteSourcesOneXpathError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteSourcesOneXpathError(error.message));
            return Promise.resolve(error);
        });
};


export const deleteIndustry = (industry) => (dispatch, getState) => {
    const state = getState();
    let industryData = state.sources.industry;
    const url = apiUrl.BASE_URL + apiUrl.DELETE_SOURCE_INDUSTRY.format(industry.industry_id);
    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let datalist = [];
                industryData.industry.map((data) => {
                    if (data.industry_id !== industry.industry_id) {
                        datalist.push(data);
                    }
                });
                dispatch(actions.DeleteSourceIndustrySuccess(datalist));
                return response;
            }
            dispatch(actions.DeleteSourceIndustryError(response.message));
            return response.data;
        })
        .catch(error => {
            dispatch(actions.DeleteSourceIndustryError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deletePredictoSource = (source) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[source.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_PREDICTO_SOURCE_INDUSTRY.format(source.customer_id, source.id);
    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.predictoSourceData) {
                    const predictoSourceAfterDelete = customerData.predictoSourceData.filter((sourceData) => {
                        return sourceData.id !== source.id;
                    });
                    customerData["predictoSourceData"] = predictoSourceAfterDelete;
                } else {
                    customerData["predictoSourceData"] = [];
                }
                dispatch(actions.DeleteCustomerPredictorSourceSuccess(customerData));
                return response;
            }
            dispatch(actions.DeleteCustomerPredictorSourceErrors(response.message));
            return response;
        }).catch((error) => {
            dispatch(actions.DeleteCustomerPredictorSourceErrors(error.message))
            return Promise.resolve(error.response);
        })
};


export const fetchXpathsAll = () => (dispatch, getState) => {
    const state = getState();
    let source = state.sources.xpaths;
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_XPATHS;
    return axios
        .get(url)
        .then(response => {
            let data = [];
            if (response.status === 200) {
                data = response.data.data;
            }

            source = {
                ...source,
                xpaths: data
            };
            dispatch(actions.GetXpathAllSuccess(source));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetXpathAllError(error.message));
            return Promise.resolve(error.response);
        });
};
