import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {sendUserMail} from "../../../actions";
import CustomerUserSendMailForm from "./customerUserSendMailForm";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            sendUserMail
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUserSendMailForm);
