import Moment from 'moment';
import moment from 'moment';

moment.locale("en", {
    week: {
        dow: 7 // week start from Sunday
    }
});

const DateFormat = {
    Date: 'DD-MMM-YYYY',
    DateAndTime: 'DD-MMM-yyyy hh:mm a',
    DDMMYYYY: 'DDMMYYYY',
    MMM_YY: 'MMM\'YY',
    WEEK_RANGE_FORMAT: '[WK]WW\'YY',
    YYYYMM: 'YYYYMM',
    YYYYMMDD: 'YYYYMMDD',
    YYYYWW: 'YYYYWW',
    YYYY_MM_DD: 'YYYY-MM-DD',
}

export const ConverToDate = (date, format) => {
    if (!date) {
        return date;
    }

    if (!format) {
        format = DateFormat.DateAndTime;
    }

    return Moment(date).format(format);
}

export const GetCurrentMonthDateStartAndEndDate = (year, month, isRange) => {
    let yearMonth = `${year}-${month + 1}`
    let startDate = moment(yearMonth, "YYYY-MM").startOf('month');
    let endDate = moment(yearMonth, "YYYY-MM").endOf('month');
    let formattedStartDate = startDate.format("YYYY-MM-DD").replaceAll('-', '').trim();
    let formattedEndDate = endDate.format("YYYY-MM-DD").replaceAll('-', '').trim();

    if (isRange) {
        return [startDate.format(), endDate.format()]
    }
    return [
        parseInt(formattedStartDate), parseInt(formattedEndDate)

    ]
}

export const GetLasNMonths = (monthCount) => {
    const ranges = GetDateRanges(monthCount, DateUnit.Month);
    return GetMonthsFromRange(ranges[0], ranges[1]);
}

export const GetWeeksFromRange = (startDate, endDate) => {
    let weeks = [];
    if (startDate && endDate) {
        const from = moment(startDate);
        const to = moment(endDate);
        let currentDate = from;
        const difference = to.diff(from, 'w');
        for (let index = 0; index <= difference; index++) {
            const weekNumber = currentDate.week();
            const year = currentDate.year();
            if (weekNumber.toString().length === 1) {
                weeks.push(Number(`${year}0${weekNumber}`));
            } else {
                weeks.push(Number(`${year}${weekNumber}`));
            }

            currentDate = currentDate.add(1, 'w');
        }
    }
    return weeks;
}

export const GetMonthWeeks = (month) => {
    if (month < 1 || month > 12) {
        return [];
    }

    let monthMoment = moment().month(month - 1);
    let endOfMonth = moment(monthMoment).endOf('month');
    let startOfMonth = moment(monthMoment).startOf('month');
    return DateUtility.GetWeeksFromRange(startOfMonth, endOfMonth);
}

export const GetMonthsFromRange = (startDate, endDate) => {
    let months = [];
    if (startDate && endDate) {
        const from = moment(startDate);
        const to = moment(endDate);
        let currentDate = from;
        const difference = to.diff(from, 'months');
        for (let index = 0; index <= difference; index++) {
            const monthNumber = currentDate.month() + 1;
            const year = currentDate.year();
            if (monthNumber.toString().length === 1) {
                months.push(Number(`${year}0${monthNumber}`));
            } else {
                months.push(Number(`${year}${monthNumber}`));
            }

            currentDate = currentDate.add(1, 'months');
        }
    }
    return months;
}

export const GetQuartersFromRange = (startDate, endDate) => {
    let months = [];
    if (startDate && endDate) {
        const from = moment(startDate);
        const to = moment(endDate);
        let currentDate = from;
        const difference = to.diff(from, 'Q');
        for (let index = 0; index <= difference; index++) {
            const monthNumber = currentDate.quarter();
            const year = currentDate.year();
            if (monthNumber.toString().length === 1) {
                months.push(Number(`${year}0${monthNumber}`));
            } else {
                months.push(Number(`${year}${monthNumber}`));
            }

            currentDate = currentDate.add(1, 'Q');
        }
    }
    return months;
}

export const GetYearsFromRange = (startDate, endDate) => {
    let years = [];
    if (startDate && endDate) {
        const from = moment(startDate);
        const to = moment(endDate);
        let currentDate = from;
        const difference = to.diff(from, 'y');
        for (let index = 0; index <= difference; index++) {
            const year = currentDate.year();
            years.push(year);
            currentDate = currentDate.add(1, 'y');
        }
    }
    return years;
}

export const GetDateRanges = (count, unit) => {
    const dateRanges = [];
    const currentMonth = moment();
    let fromDate = currentMonth;
    let fromRange;
    let toRange;
    if (unit === DateUnit.Month) {
        fromDate = currentMonth.endOf('month').add(-(count - 1), 'months');
        fromRange = fromDate.startOf('month');
        toRange = moment().endOf('month');
    } else if (unit === DateUnit.Week) {
        fromDate = currentMonth.endOf('month').add(-(count - 1), 'months');
        fromRange = fromDate.startOf('month');
        toRange = moment().endOf('month');
    } else {
        fromRange = moment().add(-count, 'days');
        toRange = moment();
    }
    dateRanges.push(fromRange);
    dateRanges.push(toRange);

    return dateRanges;
}
export const GetDaysFromRange = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export const DateUnit = {
    Day: 'Day',
    Month: 'Month',
    Year: 'Year',
    Week: 'Week'
}
