import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createCustomerDimensionValue, getCustomerDimensions, updateCustomerDimensionValue} from "../../../actions";
import CustomerDimensionValueForm from "./customerDimensionValueForm";

const mapStateToProps = (state, ownProps) => (
    {
        userName: state.auth.userName,
        customerDimensions: state.customer.list[ownProps.customerId].dimensions,
        dimensionPermission: state.auth.permissions && state.auth.permissions.customer.dimensionValues.create

    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createCustomerDimensionValue,
            updateCustomerDimensionValue,
            getCustomerDimensions
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDimensionValueForm);
