export const EN_CONSTANTS = {};
export const APPLICATION_MODES = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    VIEW: "VIEW",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    YES: "Yes",
    NO: "No",
    NANO_ID_CHARACTERS: "1234567890",
    NANO_ID_LENGTH: 10,
    IN_ACTIVE: "In-Active",
    ACTIVE_UPPER_CASE: "ACTIVE",
    IN_ACTIVE_UPPER_CASE: "IN-ACTIVE",
    GROUP_INSTANCE: "Group Instance",
    TATVAM_INAPP: "TATVAM_INAPP",
    PREDICTO: "PREDICTO",
    BLMS: "BLMS",
};

export const ALERT_MESSAGES = {
    DIRTY_DISCARD: "Changes will be lost"
};
export const APP_BOOLEANS = {
    TRUE: "true"
};

export const appLicence = {
    GOLD: "Gold",
    PLATINUM: "Platinum",
    SILVER: "Silver"
};

export const appCateogry = {
    LIVE: "Live",
    DEMO: "Demo",
    COMPETITOR: "Competitor"
};
