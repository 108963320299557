import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerUsers,
    editCustomerUsersRole,
    fetchAllCustomers,
    fetchAllReports,
    fetchCustomerRoles,
    fetchCustomerUser,
    fetchUserCustomer,
    generateApiKey
} from "../../../../actions/index";

import UserCustomerForm from "./userCustomerForm";

const mapStateToProps = (state, ownProps) => ({
    customersList: state.customer.list,
    customerData: state.customer.list[ownProps.customerId],
    userPermission: state.auth.permissions && state.auth.permissions.user.create,
    userName: state.auth.userName,
    allReports: state.reports.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            editCustomerUsersRole,
            createCustomerUsers,
            fetchCustomerRoles,
            fetchCustomerUser,
            fetchUserCustomer,
            fetchAllCustomers,
            fetchAllReports,
            generateApiKey
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCustomerForm);
