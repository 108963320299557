import React, {Component, Fragment,} from 'react';

import {Row} from 'react-materialize';
import {Tab, TabList, TabPanel, Tabs,} from 'react-tabs';

import {APPLICATION_MODES} from '../../../constants/localConstants';
import {
    AlertRules,
    ApiSources,
    Competitors,
    CustomerReportSubscription,
    Dimensions,
    DimensionValues,
    PredictionInfluencer,
    PredictionSources,
    Property,
    ResponseTemplates,
    Roles,
    Users,
    WebSources,
} from './tabs/index';

class TabsProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countList: {},
            tabIndex: 0
        };
    }

    handleSelect = (index) => {
        this.setState({
            tabIndex: index
        });
    }

    countEnable = (countProp) => {
        let countState = this.state.countList;
        Object.assign(countState, countProp);
        this.setState({countList: countState});
    };

    componentDidMount() {
        this.fetchCustomerTabCount();
    }

    fetchCustomerTabCount = () => {
        this.props.actions.fetchCustomerTabCount(this.props.currentCustomerId).then(() => {
            let rulesGridData = [];
            if (this.props.customerTabData) {
                this.countEnable({
                    'webSource': {
                        'active': this.props.customerTabData.WebSourcesActive,
                        'total': this.props.customerTabData.WebSourceTotal
                    }
                });
                this.countEnable({
                    'apiSource': {
                        'active': this.props.customerTabData.APISourceActive,
                        'total': this.props.customerTabData.APISourceTotal
                    }
                });
                this.countEnable({
                    'roleSource': {
                        'active': this.props.customerTabData.CustomerRolesActive,
                        'total': this.props.customerTabData.CustomerRolesTotal
                    }
                });
                this.countEnable({
                    'ruleSource': {
                        'active': this.props.customerTabData.CustomerRulesActive,
                        'total': this.props.customerTabData.CustomerRulesTotal
                    }
                });
                this.countEnable({'competitorSource': {'total': this.props.customerTabData.CompetitorsTotal}});
                this.countEnable({
                    'userSource': {
                        'active': this.props.customerTabData.CustomerUsersActive,
                        'total': this.props.customerTabData.CustomerUsersTotal
                    }
                });
                this.countEnable({
                    'dimensionSource': {
                        'active': this.props.customerTabData.CustomerDimensionActive,
                        'total': this.props.customerTabData.CustomerDimensionTotal
                    }
                });
                this.countEnable({
                    'dimensionValueSource': {
                        'active': this.props.customerTabData.CustomerDimensionValueActive,
                        'total': this.props.customerTabData.CustomerDimensionValueTotal
                    }
                });
                this.countEnable({
                    'responseTemplateSource': {
                        'active': this.props.customerTabData.CustomerResponseTemplateActive,
                        'total': this.props.customerTabData.CustomerResponseTemplateTotal
                    }
                });
                this.countEnable({
                    'reportSubscription': {
                        'active': this.props.customerTabData.CustomerReportSubscriptionValueActive,
                        'total': this.props.customerTabData.CustomerReportSubscriptionValueTotal
                    }
                });

                this.countEnable({
                    'predictoSource': {
                        'active': this.props.customerTabData.CustomerPredictoSourcesValueActive,
                        'total': this.props.customerTabData.CustomerPredictoSourcesValueTotal
                    }
                });
                this.countEnable({
                    'predictionInfluencer': {
                        'active': this.props.customerTabData.CustomerInfluencersValueActive,
                        'total': this.props.customerTabData.CustomerInfluencersValueTotal
                    }
                });
                if (this.props.currentCustomer.category === "Competitor") {
                    this.countEnable({'propertySource': {'total': this.props.customerTabData.CompetitorLinkedPropertyTotal}});
                } else {
                    this.countEnable({'propertySource': {'total': this.props.customerTabData.ChildrensTotal}});
                }

            }
        });
    };


    render() {
        return (
            <Row className="m-0">
                {this.props.currentCustomerId && (
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={this.handleSelect}>
                        <TabList className="tabs customTabs z-depth-2 tabs-fixed-width">
                            {(this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.NO && this.props.currentCustomer.is_inapp) && (
                                <Fragment>
                                    <Tab className="tab"> Web Sources <span
                                        class="activeCountSpan">({this.state.countList.webSource ? this.state.countList.webSource.active : 0}/{this.state.countList.webSource ? this.state.countList.webSource.total : 0})</span></Tab>
                                    <Tab className="tab"> API Sources <span
                                        class="activeCountSpan">({this.state.countList.apiSource ? this.state.countList.apiSource.active : 0}/{this.state.countList.apiSource ? this.state.countList.apiSource.total : 0})</span>
                                    </Tab>
                                </Fragment>
                            )}
                            {(this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (this.props.currentCustomer.is_inapp || this.props.currentCustomer.is_predicto || this.props.currentCustomer.is_blms) && (
                                <Fragment>

                                    <Tab className="tab"> Roles <span
                                        class="activeCountSpan">({this.state.countList.roleSource ? this.state.countList.roleSource.active : 0}/{this.state.countList.roleSource ? this.state.countList.roleSource.total : 0})</span>
                                    </Tab>
                                    <Tab className="tab"> Users<span
                                        class="activeCountSpan">({this.state.countList.userSource ? this.state.countList.userSource.active : 0}/{this.state.countList.userSource ? this.state.countList.userSource.total : 0})</span>
                                    </Tab>
                                </Fragment>
                            )}

                            {/* {(this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor" && this.props.currentCustomer.is_inapp) && ( */}
                            {(this.props.currentCustomer && this.props.currentCustomer.is_inapp) && (
                                <Fragment>
                                    <Tab className="tab"> Dimensions <span
                                        class="activeCountSpan">({this.state.countList.dimensionSource ? this.state.countList.dimensionSource.active : 0}/{this.state.countList.dimensionSource ? this.state.countList.dimensionSource.total : 0})</span></Tab>
                                    <Tab className="tab"> DimensionValues <span
                                        class="activeCountSpan">({this.state.countList.dimensionValueSource ? this.state.countList.dimensionValueSource.active : 0}/{this.state.countList.dimensionValueSource ? this.state.countList.dimensionValueSource.total : 0})</span></Tab>
                                </Fragment>
                            )}

                            {(this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.NO) &&
                                (this.props.currentCustomer.category !== "Competitor") && (this.props.currentCustomer.is_inapp) && (
                                    <Fragment>
                                        <Tab className="tab"> Rules <span
                                            class="activeCountSpan">({this.state.countList.ruleSource ? this.state.countList.ruleSource.active : 0}/{this.state.countList.ruleSource ? this.state.countList.ruleSource.total : 0})</span></Tab>
                                        <Tab className="tab"> Competitors <span
                                            class="activeCountSpan">(Total {this.state.countList.competitorSource ? this.state.countList.competitorSource.total : 0})</span></Tab>
                                    </Fragment>
                                )}

                            {((this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.YES) ||
                                (this.props.currentCustomer.category === "Competitor")) && (this.props.currentCustomer.is_inapp) && (
                                <Tab
                                    className="tab"> {this.props.currentCustomer.category === "Competitor" ? "Linked Properties" : "Properties"}
                                    <span
                                        class="activeCountSpan">(Total {this.state.countList.propertySource ? this.state.countList.propertySource.total : 0})</span>
                                </Tab>
                            )}

                            {(this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.NO && this.props.currentCustomer.category !== "Competitor" && this.props.currentCustomer.is_inapp &&
                                <Fragment>
                                    <Tab className="tab">Response Template <span
                                        class="activeCountSpan">({this.state.countList.responseTemplateSource ? this.state.countList.responseTemplateSource.active : 0}/{this.state.countList.responseTemplateSource ? this.state.countList.responseTemplateSource.total : 0})</span>
                                    </Tab>
                                </Fragment>
                            )}

                            {(this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (this.props.currentCustomer.is_inapp || this.props.currentCustomer.is_predicto) && (
                                <Tab className="tab"> Report Subscription <span
                                    class="activeCountSpan">({this.state.countList.reportSubscription ? this.state.countList.reportSubscription.active : 0}/{this.state.countList.reportSubscription ? this.state.countList.reportSubscription.total : 0})</span></Tab>
                            )}

                            {(this.props.currentCustomer.is_predicto && this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (
                                <Fragment>
                                    <Tab className="tab"> Prediction Sources <span
                                        class="activeCountSpan">({this.state.countList.predictoSource ? this.state.countList.predictoSource.active : 0}/{this.state.countList.predictoSource ? this.state.countList.predictoSource.total : 0})</span>
                                    </Tab>
                                    <Tab className="tab"> Prediction Influencer <span
                                        class="activeCountSpan">({this.state.countList.predictionInfluencer ? this.state.countList.predictionInfluencer.active : 0}/{this.state.countList.predictionInfluencer ? this.state.countList.predictionInfluencer.total : 0})</span></Tab>
                                </Fragment>
                            )}
                        </TabList>


                        {(this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.NO && this.props.currentCustomer.is_inapp) && (
                            <Fragment>
                                <TabPanel>
                                    <WebSources
                                        applicationMode={this.props.applicationMode}
                                        currentCustomerId={this.props.currentCustomerId}
                                        countPropagate={this.countEnable}
                                        customerCategory={this.props.currentCustomer && this.props.currentCustomer.category}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <ApiSources
                                        applicationMode={this.props.applicationMode}
                                        currentCustomerId={this.props.currentCustomerId}
                                        countPropagate={this.countEnable}
                                    />
                                </TabPanel>
                            </Fragment>
                        )}


                        {(this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (this.props.currentCustomer.is_inapp || this.props.currentCustomer.is_predicto || this.props.currentCustomer.is_blms) && (
                            <Fragment>

                                <TabPanel>

                                    <Roles
                                        currentCustomerId={this.props.currentCustomerId}
                                        applicationMode={this.props.applicationMode}
                                        countPropagate={this.countEnable}
                                        currentCustomer={this.props.currentCustomer}
                                    />

                                </TabPanel>

                                <TabPanel>
                                    <Users
                                        currentCustomerId={this.props.currentCustomerId}
                                        applicationMode={this.props.applicationMode}
                                        countPropagate={this.countEnable}
                                        currentCustomer={this.props.currentCustomer}
                                    />
                                </TabPanel>


                            </Fragment>
                        )}

                        {/* {(this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor" && this.props.currentCustomer.is_inapp) && ( */}
                        {(this.props.currentCustomer && this.props.currentCustomer.is_inapp) && (
                            <Fragment>
                                <TabPanel>
                                    <Dimensions
                                        currentCustomer={this.props.currentCustomer}
                                        applicationMode={this.props.applicationMode}
                                        countPropagate={this.countEnable}
                                    />
                                </TabPanel>

                                <TabPanel>
                                    <DimensionValues
                                        currentCustomer={this.props.currentCustomer}
                                        applicationMode={this.props.applicationMode}
                                        countPropagate={this.countEnable}
                                    />
                                </TabPanel>
                            </Fragment>
                        )}
                        {(this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.NO) &&
                            (this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (this.props.currentCustomer.is_inapp) && (
                                <Fragment>
                                    <TabPanel>
                                        <AlertRules
                                            currentCustomerId={this.props.currentCustomerId}
                                            applicationMode={this.props.applicationMode}
                                            countPropagate={this.countEnable}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <Competitors
                                            currentCustomer={this.props.currentCustomer}
                                            applicationMode={this.props.applicationMode}
                                            countPropagate={this.countEnable}
                                        />
                                    </TabPanel>

                                </Fragment>
                            )}

                        {((this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.YES) ||
                            (this.props.currentCustomer.category === "Competitor")) && (this.props.currentCustomer.is_inapp) && (
                            <TabPanel>
                                <Property
                                    currentCustomerId={this.props.currentCustomer.id}
                                    applicationMode={this.props.applicationMode}
                                    history={this.props.history}
                                    parent_customer_id={this.props.currentCustomer && this.props.currentCustomer.parent_customer_id}
                                    countPropagate={this.countEnable}
                                    category={this.props.currentCustomer && this.props.currentCustomer.category}
                                />
                            </TabPanel>
                        )}
                        {(this.props.currentCustomer && this.props.currentCustomer.corporate === APPLICATION_MODES.NO && this.props.currentCustomer.category !== "Competitor" && this.props.currentCustomer.is_inapp &&
                            <TabPanel>
                                <ResponseTemplates
                                    currentCustomerId={this.props.currentCustomerId}
                                    applicationMode={this.props.applicationMode}
                                    countPropagate={this.countEnable}
                                    currentCustomer={this.props.currentCustomer}
                                />

                            </TabPanel>
                        )}
                        {(this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (this.props.currentCustomer.is_inapp || this.props.currentCustomer.is_predicto || this.props.currentCustomer.is_blms) && (
                            <TabPanel>
                                <CustomerReportSubscription
                                    currentCustomerId={this.props.currentCustomerId}
                                    applicationMode={this.props.applicationMode}
                                    countPropagate={this.countEnable}
                                    currentCustomer={this.props.currentCustomer}
                                />
                            </TabPanel>
                        )}
                        {this.props.currentCustomer.is_predicto &&
                            (this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (
                                <Fragment>
                                    <TabPanel>
                                        <PredictionSources
                                            applicationMode={this.props.applicationMode}
                                            currentCustomerId={this.props.currentCustomerId}
                                            countPropagate={this.countEnable}
                                            currentCustomer={this.props.currentCustomer}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <PredictionInfluencer
                                            currentCustomerId={this.props.currentCustomerId}
                                            applicationMode={this.props.applicationMode}
                                            countPropagate={this.countEnable}
                                            currentCustomer={this.props.currentCustomer}
                                        />
                                    </TabPanel>
                                </Fragment>
                            )
                        }
                        {this.props.currentCustomer.is_blms &&
                            (this.props.currentCustomer && this.props.currentCustomer.category !== "Competitor") && (
                                <Fragment>
                                    <TabPanel>
                                        <ResponseTemplates
                                            applicationMode={this.props.applicationMode}
                                            currentCustomerId={this.props.currentCustomerId}
                                            countPropagate={this.countEnable}
                                            currentCustomer={this.props.currentCustomer}
                                        />
                                    </TabPanel>
                                </Fragment>
                            )
                        }
                    </Tabs>
                )}
            </Row>
        );
    }
}

export default TabsProject;
