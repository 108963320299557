import React, {Fragment} from 'react';

import AlertBox from '../../../../components/applicationComponents/alertBox';
import {ALERT_MESSAGES, USER_FORM,} from '../../../../constants/appConstants';
import {TatvamGrid} from '../../../functionalComponents/index';
import moment from 'moment';

class UsersGrid extends React.Component {
    constructor(props) {
        super(props);
        const customComparator = (valueA, valueB) => {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };
        this.state = {
            colDef: [
                {
                    headerName: USER_FORM.VIEW_USER_NAME,
                    field: "user_name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.VIEW_FIRST_NAME,
                    field: "first_name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.VIEW_LAST_NAME,
                    field: "last_name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.VIEW_DISPLAY_NAME,
                    field: "display_name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.EMAIL,
                    field: "email",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.User_TYPE,
                    field: "user_type",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.STATUS,
                    field: "status",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_FORM.LASTLOGIN,
                    field: "last_login",
                    comparator: customComparator,
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        return !data.value? "-" : moment(data.value).format('DD-MMM-yyyy hh:mm a')
                    }
                },
                {
                    headerName: USER_FORM.TOTAL_ALERTS,
                    field: "total_alerts",
                    comparator: customComparator,
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        return !data.value? "-" : data.value
                    }
                },
                {
                    headerName: USER_FORM.TOTAL_SUBSCRIPTION,
                    field: "total_subscriptions",
                    comparator: customComparator,
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        return !data.value? "-" : data.value
                    }
                },
                {
                    headerName: USER_FORM.IS_MOBILE_ENABLE,
                    field: "is_mobile_enabled",
                    filterParams: {clearButton: true},
                },
                {
                    headerName: USER_FORM.IS_CUSTOMER_API_ENABLED,
                    field: "is_customer_api_enabled",
                    filterParams: {clearButton: true},
                },
                {
                    headerName: USER_FORM.TOTAL_CUSTOMERS,
                    field: "total_customers",
                    filterParams: {clearButton: true}
                }
            ],
            usersData: []
        };
    }

    componentWillReceiveProps(props) {
        if (props.users) {
            const tempUsers = [];
            for (let data of Object.values(props.users)) {
                if (data.role !== "TatvamAdmin") {
                    data.last_login = data.authentication && data.authentication.last_login ? data.authentication.last_login : "";
                    tempUsers.push(data);
                }
            }
            this.setState({usersData: tempUsers});
        }
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            user_name: data.user_name,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };


    _deleteRow = (data) => {
        if (data === "ok") {
            this.props.actions.deleteUser(this.state.user_name).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });
                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }

    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    render() {
        return (
            <Fragment>
                <TatvamGrid
                    gridData={this.state.usersData}
                    colData={this.state.colDef}
                    enableRowClick
                    onRowSelect={this.props.editUser}
                    enablePagination
                    enableDeleteColumn={true}
                    onRowDelete={this._handleDeleteConfirmation}
                />

                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}

                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default UsersGrid;
