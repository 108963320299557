export const AUTH_EMAIL_REQUIRED = 'Please enter email id';
export const AUTH_ERROR = 'Application is not able to authenticate, kindly try after sometime.';
export const AUTH_INVALID_CREDENTIALS = 'Incorrect email id or password!';
export const AUTH_PASSWORD_REQUIRED = 'Please enter password ';
export const CATCH_CHANGE_PASSWORD = 'Application facing problem while changing password.';
export const DATA_FETCH_ERROR = 'Application is facing problem while fetching data.';
export const EXPORT_ERROR = 'Application is facing problem while exporting data.';
export const FILE_NOT_FOUND_ERROR = 'Requested file is not available, Please contact admin'

export const FILTER_NAME = `Provided filter name already exists.`;
export const FILTER_DATA_LOAD_FAILED = `Application is facing problem while loading filter's data.`;
export const FILTER_DATA_SAVE_FAILED = `Application is facing problem while saving filter.`;
export const FETCH_FB_SOURCE_DATA_FAILED = `Application is facing problem while fetching FB source data.`;
export const FETCH_FB_APP_DEATILS_FAILED = `Application is facing problem while fetching FB app details.`;
export const FETCH_GOOGLE_SOURCE_DATA_FAILED = `Application is facing problem while fetching Google source data.`;
export const FETCH_GOOGLE_APP_DEATILS_FAILED = `Application is facing problem while fetching Google app details.`;
export const FETCH_GOOGLE_APP_LOCATION_FAILED = `Application is facing problem while fetching Google app location details.`;
export const FETCH_TWITTER_SOURCE_DATA_FAILED = `Application is facing problem while fetching Google source data.`;
export const FETCH_TWITTER_APP_DEATILS_FAILED = `Application is facing problem while fetching Google app details.`;

export const FLAG_UPDATE_FAILED = 'Application is facing problem while updating flag.';
export const FORGET_PWD_SUCCESS = 'Password reset email sent, check your inbox.';
export const KINDLY_TRY_LATER = 'Kinldy try after some time';
export const REVIEW_LABELS = `Application is facing problem while fetching review's list.`;
export const MY_DATA_DISPLAY_NAME = "Enter the Display Name";
export const MY_DATA_FIRST_NAME = "Enter the First Name";
export const MY_DATA_INVALID_MAILID = "Enter Valid Email Id";
export const MY_DATA_LAST_NAME = "Enter the Last Name";
export const MY_DATA_UPDATE_FAILED = 'Application is facing problem while updating user profile.';
export const MY_DATA_UPDATE_SUCCESS = 'User Profile Updated Successfully.';
export const NEW_CONFIRM_PSWD_NOT_MATCH = 'New and Confirm password should be same.';
export const NEW_OLD_PSWD_MATCH = 'Current and New password cannot be same.';
export const PASSWORD_CHANGE_SUCCESS = 'Password Changed Successfully';
export const PASSWORD_CHANGE_FAILED = 'Application is facing problem while updating password';
export const PROPERTY_SWITCH_ERROR = 'Application is facing problem to load the data while switching property.';
export const REVIEW_LIST_ERROR = 'Application is facing problem while fetching review list.';
export const RESET_PASSWORD_ERROR = 'Application is facing problem while resetting password.';
export const REVIEW_ACTION_UPDATE_FAILED = `Application is facing problem while updating review's action.`;
export const REVIEW_LABELS_UPDATE_FAILED = `Application is facing problem while updating review's label.`;
export const REVIEW_POST_FAILED = `Application is facing problem while posting review's response.`;
export const REVIEW_STATUS_UPDATE_FAILED = `Application is facing problem while updating review's status.`;

export const SAVE_FB_PAGE_FAILED = `Application is facing problem while saving FB page details.`;
export const SAVE_GOOGLE_LOCATION_FAILED = `Application is facing problem while saving Google location details.`;
export const SUBSCRIPTION_CREATE_FAILED = `Application is facing problem while creating report subscription.`;
export const SUBSCRIPTION_CREATE_SUCCESS = `Report Subscription Created Successfully.`;
export const SUBSCRIPTION_UPDATE_FAILED = `Application is facing problem while updating report subscription.`;
export const SUBSCRIPTION_UPDATE_SUCCESS = `Report Subscription Updated Successfully.`;
export const USER_OPTOUT_FAILED = 'Application is facing problem while opting out.';
export const USER_PERMISSION_ERROR = 'Application is facing problem while fetching user permission.';

export const EXPORT_TIME_WARNING = 'This may take a while to generate the report, are you sure you want to generate the report?';
export const EXPORT_PERIODICITY_WARNING = 'Selected periodicity should not be more than a year';
export const EXPORT_COUNT_WARNING = 'The volume of data is too high to be downloaded';

export const SELECT_SEARCHBY_VALUE = 'Please Select Search By and Value.';
export const PROPERTY_CHANGE_CONFIRMATION = 'Are you sure, you want to change the property?';
export const LOGOUT_CONFIRMATION = 'Are you sure want to logout?';
export const CANCEL_POST_CONFIRMATION = 'Are you sure, you want to cancel the post?';
export const ACCOUNT_LOCATION_ERROR = 'Your account is not linked with any locations. Please sign in with some other accounts';

export const SUBSCRIPTION_NAME_ERROR = "Subscription name cannot be blank.";
export const SUBSCRIPTION_PROPERTY_ERROR = "Please select subscription property.";
export const SUBSCRIPTION_FREQUENCY_ERROR = "Please select subscription frequency.";
export const SUBSCRIPTION_REPORT_ERROR = "Please select report";
export const SUBSCRIPTION_COVERAGE_PERIOD_ERROR = "Data coverage period cannot be blank.";
export const SUBSCRIPTION_COVERAGE_PERIOD_UNIT_ERROR = "Please select data coverage period unit.";
export const SUBSCRIPTION_DELIVERY_FORMAT_ERROR = "Please select delivery format.";
export const SUBSCRIPTION_RECIPIENTS_ERROR = "Please select recipient(s).";
export const SUBSCRIPTION_STATUS_ERROR = "Please select status.";

export const IMPERSONALIZE_CONFIRMATION = 'Are you sure you want to impersonate this user?';
export const IMPERSONALIZE_ERROR = 'System is facing issue while impersonating User.';