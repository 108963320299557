import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    createCustomer,
    createRoles,
    fetchAllCustomers,
    fetchAllIndustry,
    fetchAllStates,
    fetchAllTimeZones,
    fetchCustomerCategory,
    fetchCustomerRoles,
    saveCustomer,
    fetchAllReports,
    fetchAllIndustryClassification,
    createTenant,
    saveTenant
} from '../../../actions/index';
import CustomerForm from './customerForm';

const mapStateToProps = (state, ownProps) => (
    {
        customer: state.customer.list[ownProps.currentCustomer && ownProps.currentCustomer.id],
        customerEdit: state.auth.permissions && state.auth.permissions.customer.create,
        customerList: state.customer.list,
        categoryList: state.customerCategory.list,
        industryList: state.industry.list,
        industryClassificationList: state.sources.industryClassificationList,
        sourceIndustryList: state.sources.industry,
        userName: state.auth.userName,
        timeZoneList: state.timeZone.list,
        customerRoles: state.customer.list[ownProps.currentCustomer && ownProps.currentCustomer.id] && state.customer.list[ownProps.currentCustomer && ownProps.currentCustomer.id].roles ? state.customer.list[ownProps.currentCustomer && ownProps.currentCustomer.id].roles : [],
        states: state.states.list,
        Reports:state.reports.list
    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllCustomers,
            fetchCustomerCategory,
            fetchAllIndustry,
            saveCustomer,
            saveTenant,
            createCustomer,
            createTenant,
            createRoles,
            fetchAllTimeZones,
            fetchCustomerRoles,
            fetchAllStates,
            fetchAllReports,
            fetchAllIndustryClassification
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
