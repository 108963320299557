const initialState = {
    list: {},
    sourcelist: {},
    error: {},
    apiDetails: {},
};

const StatesReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "FETCH_ALL_STATES_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;

        case "FETCH_ALL_STATES_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        case "PURGE_STORE":
            return {};
        default:
            return state;
    }
};

export default StatesReducer;
