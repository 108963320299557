import React, {Fragment} from 'react';

import {APPLICATION_MODES} from 'localConstants';
import Loader from 'react-loaders';
import {Col} from 'react-materialize';

import {SOURCE_MASTER} from '../../../constants/appConstants';
import {TatvamGrid, TatvamModal,} from '../../functionalComponents/index';
import SourceMasterForm from '../sourceMasterForm/sourceMasterForm';

class SourceMaster extends React.Component {
    constructor(props) {
        super(props);
        const customComparator = (valueA, valueB) => {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };
        this.state = {
            preLoader: true,
            colDef: [
                {
                    headerName: SOURCE_MASTER.ID, field: "id",
                    filterParams: {clearButton: true},
                    comparator: customComparator,
                },
                {
                    headerName: SOURCE_MASTER.NAME, field: "name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.URL, field: "url",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.STATUS, field: "status",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.TOTAL_XPATHS, field: "totalXpath",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.TOTAL_CUSTOMERS, field: "totalCustomers",
                    filterParams: {clearButton: true},
                    filter: "agNumberColumnFilter"
                }

            ],
            colDef1: [
                {
                    headerName: SOURCE_MASTER.ID, field: "id",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.NAME, field: "name",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.STATUS, field: "status",
                    comparator: customComparator,
                    filterParams: {clearButton: true}
                },
                {
                    headerName: SOURCE_MASTER.TOTAL_CUSTOMERS, field: "totalCustomers",
                    filterParams: {clearButton: true},
                    filter: "agNumberColumnFilter"
                }


            ],
            sourceTitle: "",
            sourceList: [],
            sources: []
        };


    }

    handleStateChange(data) {
        this.setState({
            customSortColumn: data.customSortColumn,
            customSortOrder: data.customSortOrder
        });

    }

    _handleView = (data) => {
        const source = data[0];
        let sourceData = {
            id: source.id,
            name: source.name,
            url: source.url,
            browser: source.browser,
            code_execute: source.code_execute,
            date_formats: source.date_formats,
            logo: source.logo

        };
        this.setState({
            selectedSource: sourceData,
            modalTitle: SOURCE_MASTER.EDIT_SOURCE,
            applicationMode: "VIEW",
            isModalOpen: true
        });
    };

    _openXpaths = (data) => {
        const source = data[0];
        if (this.props.category === SOURCE_MASTER.SOURCE_TYPE_WEB) {
            this.props.history.push({
                pathname: "/source-Xpaths",
                state: {
                    webDetailsData: source,
                    sourceId: source.id,
                    sortColumnName: this.state.customSortColumn,
                    sortOrderName: this.state.customSortOrder,
                    returnPath: "/webSources"
                }
            });
        }

        if (this.props.category === SOURCE_MASTER.SOURCE_TYPE_API) {
            this.props.history.push({
                pathname: "/api-sourceDetails",
                state: {
                    apiDetailsData: source,
                    sourceId: source.id,
                    sortColumnName: this.state.customSortColumn,
                    sortOrderName: this.state.customSortOrder,
                    returnPath: "/apiSources"
                }
            });
        }
    };

    _handleCreate = () => {
        this.setState({
            selectedSource: {
                id: "",
                name: "",
                url: "",
                browser: "",
                code_execute: "",
                date_formats: ""
            },
            modalTitle: SOURCE_MASTER.CREATE_SOURCE,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };

    handleSubmit = (data) => {
        return data;
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        if (this.props.category === SOURCE_MASTER.SOURCE_TYPE_WEB) {
            this.setState({sourceTitle: SOURCE_MASTER.WEB_SOURCE_MASTER});

        } else {
            this.setState({sourceTitle: SOURCE_MASTER.API_SOURCE_MASTER});
        }


        this.props.actions.fetchAllSourcesByType({'type': this.props.category}).then(() => {
            this.setState({
                preLoader: false
            });
        });

        this.props.actions.fetchXpathsAll().then(() => {
        });

        this.props.actions.fetchAllCustomerSources().then(() => {

        });


        if (Object.values(this.props.sourceList).length) {

            let sourceList = [];
            let sources = [];

            for (let data of Object.values(this.props.sourceList)) {
                let totalXpath = 0;
                let activeXpath = 0;
                let customerCount = [];
                if (this.props.xpathsList && this.props.xpathsList.xpaths) {
                    var xpathData = Object.values(this.props.xpathsList.xpaths).filter((item) => {
                        return item.source_id === data.id
                    });
                    if (xpathData) {
                        totalXpath = xpathData.length;
                        if (xpathData.length > 0) {
                            let activeItems = xpathData.filter((item) => {
                                return item.status === APPLICATION_MODES.ACTIVE
                            });
                            if (activeItems) {
                                activeXpath = activeItems.length;
                            }
                        }
                    } else {
                        totalXpath = 0;
                        activeXpath = 0;
                    }
                }

                if (this.props.customerSourceList && this.props.customerSourceList.customerSources) {
                    for (let customerData of this.props.customerSourceList.customerSources) {
                        if (customerData.source_id !== undefined && customerData.status === APPLICATION_MODES.ACTIVE) {
                            if (customerData.source_id === data.id) {
                                customerCount.push(customerData.customer_id)

                            } else {
                                if (customerData.sources) {
                                    let matchingSources = customerData.sources.filter((items) => {
                                        return items.id === data.id
                                    });
                                    if (matchingSources && matchingSources.length > 0) {
                                        customerCount.push(customerData.customer_id)
                                    }
                                }
                            }
                        }
                    }
                }
                data.totalXpath = activeXpath + "/" + totalXpath;
                data.totalCustomers = customerCount.filter(this.distinct).length;
                sourceList = [...sourceList, {
                    ...data,
                    id: data.id
                }];
            }

            if (sourceList.length > 0) {
                for (let sourceData of sourceList) {
                    if (sourceData.type === this.props.category) {
                        sources.push(sourceData);
                    }
                }
            }
            let customSortColumn = "";
            let customSortOrder = "";
            if (this.props.history.location.state) {
                customSortColumn = this.props.history.location.state.sortColumnName;
                customSortOrder = this.props.history.location.state.sortOrderName;
            }
            this.setState({sourceList, sources, customSortColumn, customSortOrder});
        }
    }

    componentWillReceiveProps(props) {
        let sourceList = [];
        let sources = [];
        if (Object.values(props.sourceList).length) {
            for (let data of Object.values(props.sourceList)) {
                let totalXpath = 0;
                let activeXpath = 0;
                let customerCount = [];
                if (props.xpathsList && props.xpathsList.xpaths) {
                    let xpathData = Object.values(props.xpathsList.xpaths).filter((item) => {
                        return item.source_id === data.id
                    });
                    if (xpathData) {
                        totalXpath = xpathData.length;
                        if (xpathData.length > 0) {
                            let activeItems = xpathData.filter((item) => {
                                return item.status === APPLICATION_MODES.ACTIVE
                            });
                            if (activeItems) {
                                activeXpath = activeItems.length;
                            }
                        }
                    } else {
                        totalXpath = 0;
                        activeXpath = 0;
                    }
                }

                if (props.customerSourceList && props.customerSourceList.customerSources) {
                    for (let customerData of props.customerSourceList.customerSources) {
                        if (customerData.source_id !== undefined && customerData.status === APPLICATION_MODES.ACTIVE) {
                            if (customerData.source_id === data.id) {
                                customerCount.push(customerData.customer_id)

                            } else {
                                if (customerData.sources) {
                                    let matchingSources = customerData.sources.filter((items) => {
                                        return items.id === data.id
                                    });
                                    if (matchingSources && matchingSources.length > 0) {
                                        customerCount.push(customerData.customer_id)
                                    }
                                }
                            }
                        }
                    }
                }

                data.totalXpath = activeXpath + "/" + totalXpath;
                data.totalCustomers = customerCount.filter(this.distinct).length;
                sourceList = [...sourceList, {
                    ...data,
                    id: data.id
                }];
            }

            if (sourceList.length > 0) {
                for (let data of sourceList) {
                    if (data.type === props.category) {
                        sources.push(data);
                    }
                }
            }

            let customSortColumn = "";
            let customSortOrder = "";
            if (this.state.customSortColumn && this.state.customSortOrder) {
                customSortColumn = this.state.customSortColumn;
                customSortOrder = this.state.customSortOrder;
            }

            this.setState({sourceList, sources, customSortColumn, customSortOrder});
        }
    }

    distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    render() {
        return (
            <Fragment>
                <div className="page_heading">
                    <h5>
                        {this.state.sourceTitle}
                    </h5>
                    {/*<div className="dashboard-btn mt-1 valign-wrapper">*/}
                    {/*<TatvamButton className="mt-1 left btn_primary" onClick={this._handleCreate}>*/}
                    {/*<i className="large material-icons"> add </i>*/}
                    {/*</TatvamButton>*/}
                    {/*</div>*/}
                </div>
                <Col
                    s={12}
                    className={this.state.preLoader ? "valign-wrapper loader-overlay" : "hide"}
                >
                    {this.state.preLoader && <Loader type="ball-beat"/>}
                </Col>
                <div className="row  z-depth-4" ref="tableTop">
                    <TatvamGrid
                        handleStateChange={this.handleStateChange.bind(this)}
                        gridData={this.state.sources}
                        colData={this.props.category === SOURCE_MASTER.SOURCE_TYPE_WEB ? this.state.colDef : this.state.colDef1}
                        onRowSelect={this._openXpaths}
                        enablePagination
                        sortColumnName={this.state.customSortColumn}
                        sortOrder={this.state.customSortOrder}

                    />

                    {this.state.isModalOpen && (
                        <TatvamModal
                            open={this.state.isModalOpen}
                            header={this.state.modalTitle}
                            className="custom_modal"
                            options={{dismissible: false}}
                            fixedFooter={false}
                            children={(
                                <SourceMasterForm
                                    formData={this.state.selectedSource}
                                    handleSubmit={this.handleSubmit}
                                    handleDiscard={this.handleDiscard}
                                    closeModal={this.closeModal}
                                    applicationMode={this.state.applicationMode}
                                />
                            )}
                        />
                    )}
                </div>
            </Fragment>
        );
    }
}

export default SourceMaster;
