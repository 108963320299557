import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    EditCustomerRolesSuccess: (payload) => ({
        type: "EDIT_CUSTOMER_ROLE_SUCCESS",
        payload
    }),
    EditCustomerRolesError: (payload) => ({
        type: "EDIT_CUSTOMER_ROLE_ERROR",
        payload
    }),
    CreateCustomerRolesSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_ROLE_SUCCESS",
        payload
    }),
    CreateCustomerRolesError: (payload) => ({
        type: "CREATE_CUSTOMER_ROLE_ERROR",
        payload
    }),
    DeleteCustomerRolesSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_ROLE_SUCCESS",
        payload
    }),
    DeleteCustomerRolesError: (payload) => ({
        type: "CREATE_CUSTOMER_ROLE_ERROR",
        payload
    }),
    GetCustomerRolesSuccess: (payload) => ({
        type: "FETCH_CUSTOMER_ROLE_SUCCESS",
        payload
    }),
    GetCustomerRolesError: (payload) => ({
        type: "FETCH_ROLE_ERROR",
        payload
    })
};

export const editRoles = (role) => (dispatch, getState) => {
    const state = getState();
    const customer = state.customer.list[role.customer_id];
    const payload = role;

    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_ROLES.format(role.customer_id, role.name);
    return axios
        .patch(url, payload)
        .then(response => {

            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.roles.filter(data => data.name !== response.data.data[0].name);
                let updatedRoleData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    roles: updatedRoleData
                };
                dispatch(actions.EditCustomerRolesSuccess(customerData));
                return response;
            }

            dispatch(actions.EditCustomerRolesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditCustomerRolesError(error.message));
            return Promise.resolve(error.response);
        });
};

export const createRoles = (role) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[role.customer_id];
    const payload = role;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_ROLES.format(role.customer_id);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = JSON.parse(JSON.stringify(customer));
                if (customerData.roles) {
                    customerData.roles.push(response.data.data[0]);
                } else {
                    customerData.roles = [];
                    customerData.roles.push(response.data.data[0]);
                }

                dispatch(actions.CreateCustomerRolesSuccess(customerData));
                return response;
            }

            dispatch(actions.CreateCustomerRolesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateCustomerRolesError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerRoles = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        const customer = state.customer.list;
        if (customer[id].roles) {
            if (customer[id].roles.length > 0) {
                return Promise.resolve(true);
            }
        }
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_ROLES.format(id);
        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    let customerData = customer[id];
                    customerData = {
                        ...customerData,
                        roles: response.data.data
                    };
                    dispatch(actions.GetCustomerRolesSuccess(customerData));
                    return response;
                }

                dispatch(actions.GetCustomerRolesError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerRolesError(error.message));
                return Promise.resolve(error.response);
            });
    }
};
export const deleteCustomerRoles = (role) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[role.customer_id];
    const payload = role;
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_ROLES.format(role.customer_id, role.name);

    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = JSON.parse(JSON.stringify(customer));
                if (customerData.roles) {
                    const rolesAfterDelete = customerData.roles.filter((roles) => {
                        return roles.name !== role.name;
                    });
                    customerData["roles"] = rolesAfterDelete;
                } else {
                    customerData["roles"] = [];
                }

                dispatch(actions.DeleteCustomerRolesSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerRolesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteCustomerRolesError(error.message));
            return Promise.resolve(error.response);
        });
};
