import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerSource,
    fetchAllSources,
    fetchAllSourcesByType,
    fetchCategory,
    fetchCommentType,
    fetchCustomerResponses,
    getApiSourceDetails,
    updateCustomerSource
} from "../../../actions";
import ApiSourceForm from "./apiSourceForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    sourcePermission: state.auth.permissions && state.auth.permissions.customer.sources.create,
    category: state.category && state.category.list,
    sourceList: state.sources.sourcelist,
    commentTypes: state.commentType && state.commentType.list,
    apiDetails: state.sources.apiDetails,
    customerResponses: state.customerResponse && state.customerResponse.list
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllSources,
            createCustomerSource,
            updateCustomerSource,
            fetchCategory,
            fetchAllSourcesByType,
            fetchCommentType,
            getApiSourceDetails,
            fetchCustomerResponses
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(ApiSourceForm);

