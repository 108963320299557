import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {UserSignOut} from "../../../../actions";
import Header from "./header";

const mapStateToProps = (state) => ({
    userName: state.auth.displayName
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({UserSignOut}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
