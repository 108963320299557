import './userDashboard.scss';

import React, {Fragment} from 'react';

import {APP_BOOLEANS} from 'localConstants';
import Loader from 'react-loaders';
import {Col, Row,} from 'react-materialize';

import AlertBox from '../../components/applicationComponents/alertBox';
import UserImport from '../../components/applicationComponents/userImport';
import UsersGrid from '../../components/applicationComponents/users/list';
import {TatvamButton, TatvamModal,} from '../../components/functionalComponents';

class UserDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preloader: true,
            userStatus: "active",
            viewGrid: false,
            isLoaded: false,
            usersData: [],
            showImportModal: false
        };
    }

    _createUser = () => {
        this.props.history.push({
            pathname: "/userdetails",
            state: {
                applicationMode: "CREATE"
            }
        });
    };

    scrollTop = () => {
        window.scrollTo(0, 0);
    };

    _editUser = userData => {
        this.props.history.push({
            pathname: "/userdetails",
            state: {
                currentUser: userData[0],
                applicationMode: "VIEW"
            }
        });
    };
    _handleGridView = () => {
        this.setState({
            viewGrid: !this.state.viewGrid
        });
    };
    closeAlertBox = () => {
        this.setState({
            isAlertBoxOpen: false
        });
    };

    componentDidMount() {
        this.props.actions.fetchAllUsers().then(response => {
            this.setState({
                preloader: false
            });

            if (response && response.status !== 200) {
                if (response.data && response.data.message) {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.message
                    });
                }

            } else {
                this.setState({usersData: this.props.userList});
            }

        });
    }

    componentWillReceiveProps(props) {
        if (props.userList && Object.keys(props.userList).length > 0) {
            this.setState({usersData: props.userList});
        }
        this.setState({
            isLoaded: true
        });
    }

    _importUser = () => {
        this.setState({
            modalTitle: "",
            showImportModal: true
        });
    }

    closeModal = () => {
        this.setState({
            showImportModal: false
        });
    };

    render() {
        return (
            <div>
                <Row>
                    {this.state.usersData && (
                        <Fragment>
                            <Row
                                className={this.state.isLoaded ? "card-container" : "card-container loader-wait"}
                            >
                                <Col
                                    s={12}
                                    className={this.state.preloader ? "valign-wrapper loader-overlay" : "hide"}
                                >
                                    {this.state.preloader && <Loader type="ball-beat"/>}
                                </Col>
                                <Fragment>
                                    <div className="page_heading">
                                        <h5>{"Users Master"} </h5>

                                        <div className="dashboard-btn mt-1 valign-wrapper">
                                            {this.props.userPermission === APP_BOOLEANS.TRUE && (
                                                <Fragment>
                                                    <TatvamButton className="mt-1 left ad-vis-btn btn_primary"
                                                                  onClick={this._createUser} title="Add user">
                                                        <i className="large material-icons"> person_add </i>
                                                    </TatvamButton>
                                                    <TatvamButton className="mt-1 ml-2 left ad-vis-btn btn_primary"
                                                                  onClick={this._importUser} title="Import user">
                                                        <i className="large material-icons"> import_contacts </i>
                                                    </TatvamButton>
                                                </Fragment>
                                            )}
                                            {this.state.viewGrid === true && (
                                                <i
                                                    className="material-icons pointer right"
                                                    title="List View"
                                                    onClick={this._handleGridView}
                                                >
                                                    list
                                                </i>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.userStatus === "active" && (
                                        <Fragment>
                                            <div className="row  z-depth-4" ref="tableTop">
                                                <UsersGrid
                                                    history={this.props.history}
                                                    users={this.state.usersData}
                                                    editUser={this._editUser}
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>
                            </Row>
                        </Fragment>
                    )}

                </Row>
                {this.state.isAlertBoxOpen
                    && (
                        < AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this.closeAlertBox}
                            alert_information={this.state.alert_information}
                        />
                    )
                }

                {this.state.showImportModal && (
                    <TatvamModal
                        open={this.state.showImportModal}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <UserImport
                                closeModal={this.closeModal}
                            />
                        )}
                    />
                )
                }
            </div>
        );
    }
}

export default UserDashboard;
