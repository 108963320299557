import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';
import {trimCapitalize} from 'stringUtil';

import {ALERT_MESSAGES, PROPERTY_USERS,} from '../../../../../constants/appConstants';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import CustomerUserSendMailForm from '../../../customerUserSendMailForm';
import CustomerUserForm from '../../../customerUsersForm';

class Users extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            colDef: [
                {
                    headerName: PROPERTY_USERS.VIEW_ROLE,
                    field: "roles",
                    filterParams: {clearButton: true},
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true
                },
                {headerName: PROPERTY_USERS.USER_NAME, field: "user_name", filterParams: {clearButton: true}},
                {headerName: PROPERTY_USERS.DISPLAY_NAME, field: "display_name", filterParams: {clearButton: true}},
                {headerName: PROPERTY_USERS.EMAIL, field: "email", filterParams: {clearButton: true}},
                {
                    headerName: PROPERTY_USERS.DEFAULT_REPORT,
                    field: "landing_page_name",
                    filterParams: {clearButton: true}
                },
                {headerName: PROPERTY_USERS.STATUS, field: "status", filterParams: {clearButton: true}},
            ],
            usersGridData: [],
            checkedRows: []
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            _id: data._id,
            idCustomer: data.customer_id,
            userName: data.user_name,
            roles: data.roles,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let user_name = this.state.userName;
            let roles = this.state.roles;
            let _id = this.state._id
            let userDeleteData = {
                customer_id,
                user_name,
                roles,
                _id
            };
            this.props.actions.deleteCustomerUser(userDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _addUser = () => {
        this.setState({
            isModalOpen: true,
            isMailModalOpen: false,
            userData: [],
            applicationMode: APPLICATION_MODES.CREATE,
            title: PROPERTY_USERS.ADD_USER,
            customerInformation: this.props.currentCustomer ? this.props.currentCustomer : null
        });
    };
    __onEditRowClick = (data, checkedRows) => {
        this.setState({
            checkedRows: checkedRows,
            userData: data[0],
            title: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true,
            isMailModalOpen: false,
            customerInformation: this.props.currentCustomer ? this.props.currentCustomer : null
        });
    };
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    closeMailModal = () => {
        this.setState({
            isMailModalOpen: false
        });
    };


    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loader: true
        });
        this.props.actions.fetchAllUsers().then(() => {
            if (this._isMounted) {
                this.fetchCustomerRoles();
            }
        });

    }

    fetchCustomerRoles = () => {
        this.props.actions.fetchCustomerRoles(this.props.currentCustomerId).then(() => {
            if (this._isMounted) {
                this.fetchCustomerUsers();
            }
        });
    };


    fetchCustomerUsers = () => {

        this.props.actions.fetchCustomerUser(this.props.currentCustomerId).then((response) => {
            if (this._isMounted) {
                this.setState({
                    loader: false
                });
            }
            let usersGridData = [];
            if (this.props.customerUsers) {
                if (Array.isArray(this.props.customerUsers)) {
                    this.props.customerUsers.map((data) => {
                        if (data.status) {
                            let status = trimCapitalize(data.status);
                            if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                                if (this.props.userList) {
                                    for (let userData of Object.values(this.props.userList)) {
                                        if (data.user_name === userData.user_name) {
                                            data.email = userData.email;
                                            data.display_name = userData.display_name;
                                        }
                                    }
                                }
                                if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && this.props.currentCustomer.is_inapp) {
                                    usersGridData.push(data);
                                }

                                if (data.application_name === APPLICATION_MODES.PREDICTO && this.props.currentCustomer.is_predicto) {
                                    usersGridData.push(data);
                                }

                                if (data.application_name === APPLICATION_MODES.BLMS && this.props.currentCustomer.is_blms) {
                                    usersGridData.push(data);
                                }
                            }
                        }
                    });
                } else {

                    if (this.props.customerUsers.status) {
                        let status = trimCapitalize(this.props.customerUsers.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            if (this.props.userList) {
                                for (let userData of Object.values(this.props.userList)) {
                                    if (this.props.customerUsers.user_name === userData.user_name) {
                                        this.props.customerUsers.email = userData.email;
                                    }
                                }
                            }
                            if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && this.props.currentCustomer.is_inapp) {
                                usersGridData.push(data);
                            }

                            if (data.application_name === APPLICATION_MODES.PREDICTO && this.props.currentCustomer.is_predicto) {
                                usersGridData.push(data);
                            }

                            if (data.application_name === APPLICATION_MODES.BLMS && this.props.currentCustomer.is_blms) {
                                usersGridData.push(data);
                            }
                        }
                    }

                }

            }
            if (this._isMounted) {
                this.setState({

                    usersGridData
                }, () => {
                    this.setActiveCount(this.props);
                });
            }
        });

    };

    setActiveCount = (thisProps) => {
        const gridData = this.state.usersGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'userSource': {'active': noOfActive.length, 'total': gridData.length}});
    };

    componentWillUnmount() {
        this._isMounted = false;
    }


    componentWillReceiveProps(props) {
        let usersGridData = [];
        if (props.customerUsers) {
            if (Array.isArray(props.customerUsers)) {
                props.customerUsers.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            if (props.userList) {
                                for (let userData of Object.values(props.userList)) {
                                    if (data.user_name === userData.user_name) {
                                        data.email = userData.email;
                                        data.display_name = userData.display_name;
                                    }
                                }
                            }
                            if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.currentCustomer.is_inapp) {
                                usersGridData.push(data);
                            }

                            if (data.application_name === APPLICATION_MODES.PREDICTO && props.currentCustomer.is_predicto) {
                                usersGridData.push(data);
                            }

                            if (data.application_name === APPLICATION_MODES.BLMS && props.currentCustomer.is_blms) {
                                usersGridData.push(data);
                            }
                        }
                    }
                });
            } else {

                if (props.customerUsers.status) {
                    let status = trimCapitalize(props.customerUsers.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        if (props.userList) {
                            for (let userData of Object.values(props.userList)) {
                                if (props.customerUsers.user_name === userData.user_name) {
                                    props.customerUsers.email = userData.email;
                                }
                            }
                        }
                        usersGridData.push(props.customerUsers);
                    }
                }
            }
        }
        this.setState({
            usersGridData
        }, () => {
            this.setActiveCount(props);
        });
    }

    _sendMailDialog = () => {
        this.setState({
            isConfirmBox: true,
            isMailModalOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.SEND_MAIL_CONFIRMATION
        });
    };

    _sendMail = (data) => {
        if (data === "ok") {
            this.setState({
                isSendMailBoxOpen: false,
                preLoader: true
            });

            let userMap = new Map();
            let userNames = [];
            this.state.checkedRows.map(function (elem) {
                if (!userMap.has(elem.user_name)) {
                    userMap.set(elem.user_name, true);
                    userNames.push(elem.user_name);
                }
            });
            let reqObject = {"usernames": userNames};
            this.props.actions.sendUserMail(reqObject).then(response => {
                this.state.gridApi.deselectAll();
                if (response && response.status === 200) {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message,
                        shouldCloseModal: true,
                        notDiscard: true,
                        isConfirmBox: false
                    });
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message,
                        notDiscard: true
                    });
                }


            });
        } else {
            this.state.gridApi.deselectAll();
            this.setState({
                isSendMailBoxOpen: false
            });
        }

    };

    _getSelectedRows = (selectedRows, gridApi) => {
        this.setState({
            checkedRows: selectedRows,
            gridApi: gridApi
        });
    }

    render() {
        return (
            <Fragment>
                {this.props.userPermission === APP_BOOLEANS.TRUE && (
                    <Fragment>
                        <TatvamButton className="mt-1 ml-2 mr-2 right btn_primary" name="addUser"
                                      onClick={this._addUser}>
                            {PROPERTY_USERS.ADD_USER}
                        </TatvamButton>
                        <TatvamButton className="mt-1 ml-2 mr-0 right btn_primary" name="sendMail"
                                      onClick={this._sendMailDialog} disabled={this.state.usersGridData.length === 0}
                                      disabled={!(this.state.checkedRows.length > 0)}>
                            {PROPERTY_USERS.SEND_MAIL}
                        </TatvamButton>
                    </Fragment>
                )}

                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.title}
                        className="hideFooter"
                        options={{dismissible: false}}
                        children={(
                            <CustomerUserForm
                                rolesList={this.props.rolesList}
                                closeModal={this.closeModal}
                                customerId={this.props.currentCustomerId}
                                selectedUserList={this.state.usersGridData}
                                userData={this.state.userData}
                                title={this.state.title}
                                applicationMode={this.state.applicationMode}
                                gridApi={this.state.gridApi}
                                customerInformation={this.state.customerInformation}
                                default_landing_page={this.props.currentCustomer.inapp_default_landing_page}
                                 predicto_default_landing_page={this.props.currentCustomer.predicto_default_landing_page}

                            />
                        )}
                    />
                )}

                {this.state.isMailModalOpen && (
                    <TatvamModal
                        open={this.state.isMailModalOpen}
                        header={this.state.mail_title}
                        className="hideFooter"
                        options={{dismissible: false}}
                        children={(
                            <CustomerUserSendMailForm
                                closeModal={this.closeMailModal}
                                checkedRows={this.state.checkedRows}
                                title={this.state.mail_title}
                                applicationMode={this.state.applicationMode}
                                gridApi={this.state.gridApi}
                            />
                        )}
                    />
                )}

                {this.state.usersGridData.length > 0 ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.usersGridData}
                            colData={this.state.colDef}
                            enableRowClick
                            onRowSelect={this.__onEditRowClick}
                            onCheckBoxSelect={this._getSelectedRows}
                            enableDeleteColumn={this.props.userDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                            rowSelection={"multiple"}
                            suppressRowClickSelection
                            enableSelectionChanged={true}
                        />

                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {PROPERTY_USERS.NO_USERS_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Users;

