import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    createIndustryDimensionValue,
    deleteIndustryDimension,
    fetchAllIndustry,
    getDimensionValuesByIndustryName,
    updateIndustry,
    updateIndustryDimensionValue,
} from '../../../actions';
import IndustryDimensionValuesDetails from './industryDimensionValuesDetails';

const mapStateToProps = (state, ownProps) => ({
    gridData: state.industryDimensionValue && state.industryDimensionValue.list,
    customers: state.customer.list,
    industryDimensionPermission: state.auth.permissions && state.auth.permissions.industryDimensionValue.create,
    sourceIndustryList: state.sources.industry,
    userName: state.auth.userName
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getDimensionValuesByIndustryName,
            createIndustryDimensionValue,
            updateIndustryDimensionValue,
            fetchAllIndustry,
            deleteIndustryDimension,
            updateIndustry,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryDimensionValuesDetails);
