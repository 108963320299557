import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchFolderCategory, uploadUserFile} from "../../../actions";
import UploadFile from "./uploadFile";

const mapStateToProps = (state, ownProps) => ({
    folders: state.fileFolderCategory.list,
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            uploadUserFile,
            fetchFolderCategory
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
