import './userForm.scss';

import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';
import {customAlphabet} from 'nanoid';
import {Row, Switch,} from 'react-materialize';

import {FORM_OPERATIONS, USER_FORM,} from '../../../../constants/appConstants';
import {dynamicSort} from '../../../../utils/objectUtil';
import {validateUrl} from '../../../../utils/stringUtil';
import {
    TatvamButton,
    TatvamCheckbox,
    TatvamCol,
    TatvamMultiSelect,
    TatvamTextBox,
} from '../../../functionalComponents';
import AlertBox from '../../alertBox';

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: props.currentUser,
            status: APPLICATION_MODES.ACTIVE,
            isCustomerAdmin: false,
            changePassword: false,
            isPasswordMatch: true,
            inValidpass: false,
            inValidForm: false,
            invalidPasswordLength: false,
            inValidemail: false,
            propertyListOptions: [],
            userTypeListOptions: [],
            selectedProperty: [],
            selectedUserType: [],
            country: "",
            state: "",
            isRecordDirty: false,
            skipViewMode: false,
            selectedCountry: [],
            countryListOptions: [],
            selectedState: [],
            stateListOptions: []
        };
    }

    _handleEdit = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT,
            inValidForm: false
        });

    };

    _handleSave = e => {
        e.preventDefault();
        if (this.state.email) {
            if (!validateUrl(this.state.email)) {
                this.setState({inValidemail: true});
                return false;
            } else {
                this.setState({inValidemail: false});
            }
        }
        if (this.state.password && this.state.confirm_password) {
            if (this.state.password.length === 0 && this.state.confirm_password.length === 0) {
                this.setState({inValidpass: true, isPasswordMatch: true});
                return false;
            } else if ((this.state.password.length > 0 && this.state.password.length < 8) || (this.state.confirm_password.length > 0 && this.state.confirm_password.length < 8)) {
                this.setState({invalidPasswordLength: true, isPasswordMatch: true});
                return false;
            } else if (this.state.password.length >= 8 && this.state.confirm_password.length >= 8 && this.state.password !== this.state.confirm_password) {
                this.setState({inValidpass: false, isPasswordMatch: false, invalidPasswordLength: false});
                return false;
            }
        }
        if (!this.state.user_name || !this.state.display_name || !this.state.first_name || !this.state.last_name || !this.state.email || ((this.state.changePassword && this.state.applicationMode === "EDIT") && (!this.state.password || !this.state.confirm_password))) {
            this.setState({inValidForm: true});
            return false;
        } else if (this.state.applicationMode !== "EDIT" && (!this.state.password || !this.state.confirm_password)) {
            this.setState({inValidForm: true});
            return false;
        } else {
            let userForm = {};
            this.setState({preLoader: true});
            userForm = {
                ...this.state.currentUser
            };
            userForm.user_name = this.state.user_name;
            userForm.email = this.state.email;
            userForm.display_name = this.state.display_name;
            userForm.first_name = this.state.first_name;
            userForm.last_name = this.state.last_name;
            userForm.role = "User";
            userForm.status = this.state.status;
            userForm.user_type = this.state.selectedUserType.value;
            userForm.country = this.state.selectedCountry.label;
            userForm.state = this.state.selectedState.label;

            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                if (this.state.password === this.state.confirm_password) {
                    userForm.authentication = {
                        password: this.state.password,
                        reset_password: "",
                        reset_password_validity: "",
                        last_login: ""
                    };
                    userForm.created_by = this.props.userName;
                    const nanoid = customAlphabet(APPLICATION_MODES.NANO_ID_CHARACTERS, APPLICATION_MODES.NANO_ID_LENGTH);
                    let uniqueID = nanoid();
                    userForm.id = uniqueID;
                    userForm._id = uniqueID;
                    userForm.isFirstTimeLogin = "Yes";
                    this.props.actions.createUser(userForm).then(response => {

                        this.setState({preLoader: false});
                        if (response && response.status === 200) {
                            this.setState({
                                isSuccess: true,
                                alert_information: response.data.message,
                                isAlertBoxOpen: true
                            });
                            this.setState({
                                applicationMode: APPLICATION_MODES.VIEW,
                                isPasswordMatch: true
                            }, () => {
                                this.props.setId(userForm);
                                this.props.editFlag(false);
                            });
                        } else {
                            this.setState({
                                isSuccess: false,
                                alert_information: response.data.message ? response.data.message : response.message,
                                isAlertBoxOpen: true
                            });
                        }
                    });
                } else {
                    this.setState({
                        isPasswordMatch: false
                    });
                }
            } else if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                userForm.customer_id = this.state.selectedProperty.value !== undefined ? this.state.selectedProperty.value : ""
                userForm.default_property = this.state.selectedProperty.value !== undefined ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
                userForm.updated_by = this.props.userName;
                userForm.role = (this.props.currentUser && this.props.currentUser.role === "TatvamAdmin") ? "TatvamAdmin" : "User";
                userForm.old_user_name = this.props.currentUser.user_name;
                userForm.isFirstTimeLogin = this.state.isFirstTimeLogin;
                if (this.state.changePassword) {
                    if (this.state.password === this.state.confirm_password) {
                        userForm.authentication.password = this.state.password;
                        userForm.authentication.reset_password_validity = "";
                        userForm.authentication.reset_password = "";
                        userForm.isFirstTimeLogin = this.state.isFirstTimeLogin === "No" ? "Yes" : this.state.isFirstTimeLogin;
                        this.props.actions.editUser(userForm).then(response => {
                            this.setState({preLoader: false});
                            if (response && response.status === 200) {

                                if (response.data && response.data.message) {
                                    this.setAlertUser(response.data.message, true);
                                } else {
                                    this.setAlertUser(response.message, true);
                                }
                                this.setState({
                                    applicationMode: APPLICATION_MODES.VIEW,
                                    isPasswordMatch: true
                                }, () => {
                                    this.props.setId(userForm);
                                    this.props.editFlag(false);

                                });
                            } else {

                                var message = response.data.message ? response.data.message : response.message;
                                if (message.indexOf("Record has been modified by some one else.") > -1) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: false,
                                        alert_information: message + " Do you want to load the updated data?",
                                        isConfirmBox: true,
                                        isRecordDirty: true
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: false,
                                        alert_information: message
                                    });
                                }

                            }
                        });
                    } else {
                        this.setState({
                            isPasswordMatch: false,
                            inValidForm: false
                        });
                    }
                } else {
                    delete userForm.authentication;
                    this.props.actions.editUser(userForm).then(response => {

                        this.setState({preLoader: false});
                        if (response && response.status === 200) {

                            if (response.data && response.data.message) {
                                this.setAlertUser(response.data.message, true);
                            } else {
                                this.setAlertUser(response.message, true);
                            }
                            this.setState({
                                applicationMode: APPLICATION_MODES.VIEW,
                                isPasswordMatch: true,
                                inValidForm: false
                            }, () => {
                                this.props.setId(userForm);
                                this.props.editFlag(false);
                            });
                        } else {

                            var message = response.data.message ? response.data.message : response.message;
                            if (message.indexOf("Record has been modified by some one else.") > -1) {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: message + " Do you want to load the updated data?",
                                    isConfirmBox: true,
                                    isRecordDirty: true
                                });
                            } else {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: response.data.message ? response.data.message : response.message
                                });
                            }
                        }
                    });
                }
            }
        }

    };

    setAlertUser = (alertMsg, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMsg
        });
    };

    _discard = data => {
        if (data === "ok" && !this.state.isRecordDirty && !this.state.skipViewMode) {
            this.props.editFlag(false);

            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                this.setState({
                    user_name: "",
                    password: "",
                    isActive: true,
                    status: APPLICATION_MODES.ACTIVE,
                    confirm_password: "",
                    email: "",
                    display_name: "",
                    editFlag: "",
                    changePassword: false,
                    inValidForm: false,
                    invalidPasswordLength: false,
                    inValidemail: false,
                    isPasswordMatch: true,
                    selectedCountry: [],
                    selectedState: []
                });
                this.goBackToUserList();
            } else {
                this.setState({
                    ...this.state,
                    ...this.state.currentUser,
                    isActive: this.state.currentUser.status === APPLICATION_MODES.ACTIVE,
                    status: this.state.currentUser.status,
                    applicationMode: APPLICATION_MODES.VIEW,
                    isCustomerAdmin: this.state.currentUser && this.state.currentUser.role === "Admin",
                    editFlag: "",
                    changePassword: false,
                    inValidForm: false,
                    invalidPasswordLength: false,
                    inValidemail: false,
                    isPasswordMatch: true,
                    selectedUserType: {
                        label: this.state.currentUser.user_type,
                        value: this.state.currentUser.user_type
                    },
                    selectedCountry: this.state.currentUser.country ? {
                        label: this.state.currentUser.country,
                        value: this.state.currentUser.country
                    } : [],
                    selectedState: this.state.currentUser.state ? {
                        label: this.state.currentUser.state,
                        value: this.state.currentUser.state
                    } : []
                });

                this.resetPropertyValue(this.props);
                this.props.currentUserFormState(APPLICATION_MODES.VIEW);
            }
        } else if (data === "ok" && this.state.isRecordDirty && !this.state.skipViewMode) {
            this.setState({preLoader: true});
            this.props.actions.fetchUserData(this.state.user_name).then(response => {
                if (response && response.status === 200) {
                    this.setState({
                        preLoader: false,
                        currentUser: response.data.data[0],
                        isRecordDirty: false,
                        applicationMode: APPLICATION_MODES.EDIT,
                        skipViewMode: true
                    });
                }
                var message = response.data.message ? response.data.message : response.message;
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: message === undefined ? USER_FORM.DATA_REFRESH_MSG : message
                });

            });
        } else {
            this.setState({
                skipViewMode: false,
                applicationMode: APPLICATION_MODES.EDIT
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });

    };
    _triggerDiscard = e => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                alert_information: "Changes will be lost",
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false
            });
        } else if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({
                user_name: "",
                password: "",
                isActive: false,
                status: APPLICATION_MODES.ACTIVE,
                confirm_password: "",
                email: "",
                display_name: "",
                editFlag: "",
                changePassword: false,
                isCustomerAdmin: false,
                invalidPasswordLength: false,
                inValidemail: false,
                isPasswordMatch: true,
            });
            this.goBackToUserList();
        } else {
            this.setState({
                ...this.state.currentUser,
                isCustomerAdmin: this.state.currentUser && this.state.currentUser.role === "Admin",
                applicationMode: APPLICATION_MODES.VIEW,
                invalidPasswordLength: false,
                inValidemail: false,
                isPasswordMatch: true,
            });
            this.resetPropertyValue(this.props);
            this.props.currentUserFormState(APPLICATION_MODES.VIEW);
        }

    };

    goBackToUserList = () => {
        this.props.history.push({
            pathname: "/users"
        });
    };
    _handleInput = e => {
        if (e.target.id === "status") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        }
        if (e.target.id === "changePassword") {
            this.setState({
                changePassword: e.target.checked,
                password: "",
                confirm_password: "",
                editFlag: {
                    changePassword: true
                }
            });

            if (!e.target.checked && this.state.user_name && this.state.first_name && this.state.last_name && this.state.display_name && this.state.email) {

                this.setState({inValidForm: false});
            }
        }
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                [e.target.name]: true
            }
        });
        this.props.editFlag(true);
    };
    closeAlertBox = () => {
        this.setState({
            isAlertBoxOpen: false
        });
    };
    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
        this.props.currentUserFormState(APPLICATION_MODES.EDIT);
    };
    _triggerSubmit = () => {
        this.refs.submit.click();
    };


    componentDidMount() {
        this.props.actions.fetchAllCustomers().then(response => {
            this.mountIntialize(response);
        });
        this.props.actions.fetchUserType().then((response) => {
            this.loadUserTypeDropdown(this.props);
        });

        if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({changePassword: true});
        }

        if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
            this.setState({inValidForm: false});
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            this.loadPropertyDropdown(this.props);
        }

        this.props.actions.fetchAllStates().then(() => {
            this.loadCountryDropDown(this.props);
        });

        this.setState({
            applicationMode: this.props.applicationMode,
            isActive: false,
            status: APPLICATION_MODES.ACTIVE
        });

        if (this.props.currentUser) {
            this.setState({
                applicationMode: this.props.applicationMode,
                currentUser: this.props.currentUser,
                ...this.props.currentUser,
                isActive: this.props.currentUser.status === APPLICATION_MODES.ACTIVE,
                isCustomerAdmin: this.props.currentUser.role === "Admin",
                selectedCountry: this.props.currentUser.country ? {
                    label: this.props.currentUser.country,
                    value: this.props.currentUser.country
                } : [],
                selectedState: this.props.currentUser.state ? {
                    label: this.props.currentUser.state,
                    value: this.props.currentUser.state
                } : []

            });
        } else {
            this.props.actions.fetchAllUsers().then(() => {
                if (this.props.currentUser) {
                    this.setState({
                        applicationMode: this.props.applicationMode,
                        currentUser: this.props.currentUser,
                        ...this.props.currentUser,
                        isActive: this.props.currentUser.status === APPLICATION_MODES.ACTIVE,
                        isCustomerAdmin: this.props.currentUser.role === "Admin",
                        selectedCountry: this.props.currentUser.country ? {
                            label: this.props.currentUser.country,
                            value: this.props.currentUser.country
                        } : [],
                        selectedState: this.props.currentUser.state ? {
                            label: this.props.currentUser.state,
                            value: this.props.currentUser.state
                        } : []
                    });
                }
            });
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            this.loadStateDropDown(this.props, this.state.selectedCountry.label);
        }
    }

    componentWillReceiveProps(props) {
        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {

            this.loadPropertyDropdown(props);
        }

        this.loadUserTypeDropdown(props);
        if (props.states) {
            this.loadCountryDropDown(props);
        }

        this.setState({
            applicationMode: props.applicationMode,
            isActive: false,
            status: APPLICATION_MODES.ACTIVE
        });

        if (props.currentUser) {
            this.setState({
                currentUser: props.currentUser,
                ...props.currentUser,
                password: "",
                isActive: props.currentUser.status === APPLICATION_MODES.ACTIVE,
                confirm_password: "",
                isCustomerAdmin: props.currentUser.role === "Admin",
                selectedUserType: {label: props.currentUser.user_type, value: props.currentUser.user_type},
                selectedCountry: props.currentUser.country ? {
                    label: props.currentUser.country,
                    value: props.currentUser.country
                } : [],
                selectedState: props.currentUser.state ? {
                    label: props.currentUser.state,
                    value: props.currentUser.state
                } : []
            });
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            this.loadStateDropDown(props, this.state.selectedCountry.label);
        }
    }

    mountIntialize = (response) => {
        if (response && (response.status !== 200 && response.status !== 204)) {
            if (response.data && response.data.message) {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.data.message
                });
            } else {
                this.setState({
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: response.message ? response.data.message : response.data
                });
            }
        }
    };

    loadPropertyDropdown = (thisProps) => {
        const propertyLst = [];
        let selectedProperty = [];
        if (thisProps.customers) {
            const map = new Map();
            thisProps.customers.map((data) => {
                if (!map.has(data.id)) {
                    map.set(data.id, true);
                    if (data.default_property === APPLICATION_MODES.YES) {
                        selectedProperty = {label: data.name, value: data.id};
                    }
                    propertyLst.push({label: data.name, value: data.id});
                }
            });
            propertyLst.sort(dynamicSort("label"));
            this.setState({
                propertyListOptions: propertyLst,
                selectedProperty: selectedProperty
            });
        }
    }

    loadUserTypeDropdown = (thisProps) => {
        const userTypeLst = [];
        if (thisProps.userTypes) {
            thisProps.userTypes.map((data) => {
                userTypeLst.push({label: data, value: data});
            });
            userTypeLst.sort(dynamicSort("label"));
        }
        this.setState({
            userTypeListOptions: userTypeLst,
            selectedUserType: userTypeLst[0]
        });
    }

    resetPropertyValue = (thisProps) => {
        let selectedProperty = [];
        if (thisProps.customers) {
            thisProps.customers.map((data) => {
                if (data.default_property === APPLICATION_MODES.YES) {
                    selectedProperty = {label: data.name, value: data.id};
                }
            });
        }
        this.setState({
            selectedProperty: selectedProperty
        });
    }

    _handlePropertySelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                default_property: true
            },
            selectedProperty: selectedOption
        });
    };

    _handleUserTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                selectedUserType: true
            },
            selectedUserType: selectedOption
        });
    };

    loadCountryDropDown = (thisProps) => {
        const countryLst = [];
        if (thisProps.states) {
            const map = new Map();
            Object.values(thisProps.states).map((data) => {
                if (!map.has(data.country_name)) {
                    map.set(data.country_name, true);
                    countryLst.push({label: data.country_name, value: data.country_name});
                }
            });
            countryLst.sort(dynamicSort("label"));
        }
        this.setState({
            countryListOptions: countryLst
        });

    };

    loadStateDropDown = (thisProps, countryName) => {
        const statesLst = [];
        if (thisProps.states) {
            const statesData = Object.values(thisProps.states).filter(item => {
                return item.country_name === countryName
            });
            if (statesData.length > 0) {
                statesData.map((data) => {
                    statesLst.push({label: data.name, value: data.name});
                });
                statesLst.sort(dynamicSort("label"));
            }
        }
        this.setState({
            stateListOptions: statesLst
        });

    };

    _handleCountrySelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                country: true
            },
            selectedCountry: selectedOption,
            selectedState: []
        });
        this.loadStateDropDown(this.props, selectedOption.label);
        this.props.editFlag(true);
    };

    _handleStateSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                state: true
            },
            selectedState: selectedOption
        });
        this.props.editFlag(true);
    };

    render() {
        return (
            <Fragment>
                <TatvamCol className="z-depth-2 centered mt-2 form-wrapper" s={12} m={12} l={12} xl={12}>
                    <div className="page_heading mt-0 mb-1 ml-1">
                        {this.state.applicationMode === APPLICATION_MODES.CREATE ? (<h5>{USER_FORM.ADD_USER}</h5>) :
                            this.state.applicationMode === APPLICATION_MODES.EDIT ? (<h5>{USER_FORM.EDIT_USER}</h5>) :
                                this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                                    <h5>{USER_FORM.VIEW_USER}</h5>) : ""}
                    </div>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                            <form onSubmit={this._handleSave} autoComplete={"off"}>
                                <TatvamCol s={12} m={12}>
                                    <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
                                        <TatvamTextBox
                                            className={(this.state.inValidForm && !this.state.user_name) ? "labelText mt-0 project_name invalid" : "labelText mt-0 project_name"}
                                            s={3}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            label={
                                                <span>
                        {USER_FORM.USER_NAME}
                                                    <span style={{color: 'red'}}> *</span>
                      </span>
                                            }
                                            name="user_name"
                                            onChange={this._handleInput}
                                            value={this.state.user_name}
                                            autocomplete="off"
                                        />
                                        <TatvamTextBox
                                            className={(this.state.inValidForm && !this.state.display_name) ? "labelText mt-0 project_name invalid" : "labelText mt-0 project_name"}
                                            s={4}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            label={
                                                <span>
                        {USER_FORM.DISPLAY_NAME}
                                                    <span style={{color: 'red'}}> *</span>
                      </span>
                                            }
                                            name="display_name"
                                            onChange={this._handleInput}
                                            value={this.state.display_name}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                            autocomplete="off"
                                        />
                                        <TatvamTextBox
                                            className={(this.state.inValidForm && !this.state.first_name) ? "labelText mt-0 project_name invalid" : "labelText mt-0 project_name"}
                                            s={3}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            label={
                                                <span>
                        {USER_FORM.FIRST_NAME}
                                                    <span style={{color: 'red'}}> *</span>
                      </span>
                                            }
                                            name="first_name"
                                            onChange={this._handleInput}
                                            value={this.state.first_name}
                                            autocomplete="off"
                                        />
                                        <TatvamTextBox
                                            className={(this.state.inValidForm && !this.state.last_name) ? "labelText mt-0 project_name invalid" : "labelText mt-0 project_name"}
                                            s={3}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            label={
                                                <span>
                        {USER_FORM.LAST_NAME}
                                                    <span style={{color: 'red'}}> *</span>
                      </span>
                                            }
                                            name="last_name"
                                            onChange={this._handleInput}
                                            value={this.state.last_name}
                                            autocomplete="off"
                                        />
                                    </TatvamCol>

                                    <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
                                        <TatvamTextBox
                                            className={((this.state.inValidForm && !this.state.email) || this.state.inValidemail) ? "labelText mt-0 project_name invalid" : "labelText mt-0 project_name"}
                                            s={4}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            title="Email id should be in proper format"
                                            label={USER_FORM.EMAIL + "*"}
                                            name="email"
                                            placeholder={USER_FORM.EMAIL_PACEHOLDER}
                                            onChange={this._handleInput}
                                            value={this.state.email}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                            autocomplete="off"
                                        />
                                        <TatvamTextBox
                                            className={((this.state.inValidForm && !this.state.password && this.state.applicationMode !== "EDIT") || (this.state.inValidForm && !this.state.password && this.state.changePassword) || (!this.state.password && this.state.changePassword && this.state.inValidForm) || (this.state.inValidpass && !this.state.changePassword) || this.state.inValidpass) ? "labelText mt-0 project_name invalid" : "labelText mt-0 project_name"}
                                            s={3}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            password
                                            label={
                                                <span>
                        {USER_FORM.PASSWORD}
                                                    <span style={{color: 'red'}}> *</span>
                      </span>
                                            }
                                            name="password"
                                            onChange={this._handleInput}
                                            value={this.state.password}
                                            title="Password must contain minimum of 8 characters"
                                            placeholder={USER_FORM.PASSWORD_PACEHOLDER}
                                            autoComplete="off"
                                            disabled={this.state.applicationMode === APPLICATION_MODES.EDIT && !this.state.changePassword}
                                        />
                                        <TatvamTextBox
                                            className={((this.state.inValidForm && !this.state.confirm_password && this.state.applicationMode !== "EDIT") || (this.state.inValidForm && !this.state.confirm_password && this.state.changePassword) || (!this.state.confirm_password && this.state.changePassword && this.state.inValidForm) || (this.state.inValidpass && !this.state.changePassword) || this.state.inValidpass) ? "labelText mt-0 project_name confrimPass invalid" : "labelText mt-0 project_name confrimPass"}
                                            s={3}
                                            m={3}
                                            l={3}
                                            xl={3}
                                            password
                                            label={
                                                <span>
                        {USER_FORM.CONFIRM_PASSWORD}
                                                    <span style={{color: 'red'}}> *</span>
                      </span>
                                            }
                                            name="confirm_password"
                                            onChange={this._handleInput}
                                            value={this.state.confirm_password}
                                            autoComplete="off"
                                            placeholder={USER_FORM.CONFIRM_PASSWORD_PACEHOLDER}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.EDIT && !this.state.changePassword}
                                        >
                                        </TatvamTextBox>
                                        <TatvamCol s={3} className="valign-wrapper">
                                            <Switch
                                                id="status"
                                                checked={this.state.isActive}
                                                onChange={this._handleInput}
                                                offLabel={USER_FORM.STATUS}
                                                m={3}
                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                            />
                                            <TatvamCol
                                                m={0}
                                                className={this.state.applicationMode === APPLICATION_MODES.EDIT ? "col s7" : "d-flex"}
                                            >
                                                {this.state.applicationMode === APPLICATION_MODES.EDIT && (
                                                    <TatvamCheckbox
                                                        id="changePassword"
                                                        label={
                                                            <span>
                              {USER_FORM.CHANGE_PASSWORD}
                                                                <span style={{color: 'red'}}> *</span>
                            </span>
                                                        }
                                                        onChange={this._handleInput}
                                                        checked={this.state.changePassword}
                                                    />
                                                )}
                                            </TatvamCol>

                                        </TatvamCol>
                                    </TatvamCol>
                                </TatvamCol>
                                <TatvamCol s={12} m={12} l={12} xl={12} className="project_name mt-1 mb-1">
                                    <TatvamCol m={3} xl={3} className="mb-2 select-field">
                                        <label htmlFor="user_type">{USER_FORM.User_TYPE}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className={"validate select-ht"}
                                            value={this.state.selectedUserType}
                                            name="user_type"
                                            id="user_type"
                                            onChange={this._handleUserTypeSelect}
                                            options={this.state.userTypeListOptions}
                                            placeholder={USER_FORM.PLEASE_SELECT}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                        />
                                    </TatvamCol>
                                    {this.state.applicationMode === APPLICATION_MODES.EDIT &&

                                        <TatvamCol m={3} xl={3} className="mb-2 select-field">
                                            <label htmlFor="default_property">{USER_FORM.DEFAULT_PROPERTY}</label>
                                            <TatvamMultiSelect
                                                classNamePrefix="react-select"
                                                className={"validate select-ht"}
                                                value={this.state.selectedProperty}
                                                name="default_property"
                                                id="default_property"
                                                onChange={this._handlePropertySelect}
                                                options={this.state.propertyListOptions}
                                                placeholder={USER_FORM.PLEASE_SELECT}
                                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                            />
                                        </TatvamCol>
                                    }
                                    <TatvamCol m={3} className="mb-1 select-field">
                                        <label htmlFor="country">{USER_FORM.COUNTRY}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className={"validate select-ht"}
                                            value={this.state.selectedCountry}
                                            name="country"
                                            id="country"
                                            onChange={this._handleCountrySelect}
                                            options={this.state.countryListOptions}
                                            placeholder={USER_FORM.PLEASE_SELECT}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                        />
                                    </TatvamCol>
                                    <TatvamCol m={3} className="mb-1 select-field">
                                        <label htmlFor="state">{USER_FORM.STATE}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className={"validate select-ht"}
                                            value={this.state.selectedState}
                                            name="state"
                                            id="state"
                                            onChange={this._handleStateSelect}
                                            options={this.state.stateListOptions}
                                            placeholder={USER_FORM.PLEASE_SELECT}
                                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                        />
                                    </TatvamCol>
                                </TatvamCol>
                                <TatvamCol m={8} className="formRow">
                                    <p className="errorText"> {!this.state.isPasswordMatch && USER_FORM.PASSWORD_MATCH} </p>
                                    <p className="errorText"> {this.state.inValidemail && USER_FORM.INVALID_EMAIL_ID} </p>
                                    <p className="errorText"> {this.state.invalidPasswordLength && USER_FORM.INVALID_Password_Length} </p>

                                </TatvamCol>

                                <TatvamCol s={12} m={this.state.applicationMode === APPLICATION_MODES.EDIT ? 2 : 2}
                                           className="modalButtons right d-flex p-0 justify-around">
                                    <Fragment>
                                        {this.state.applicationMode === APPLICATION_MODES.VIEW && this.props.userPermission === APP_BOOLEANS.TRUE && (
                                            <TatvamButton className="mt-1 btn_primary " name="EDIT"
                                                          onClick={this._handleEdit}>
                                                {FORM_OPERATIONS.EDIT}
                                            </TatvamButton>
                                        )}

                                        {this.state.applicationMode !== APPLICATION_MODES.VIEW && (
                                            <Fragment>
                                                <TatvamButton
                                                    waves="light"
                                                    className="mt-1 btn_primary"
                                                    onClick={this._triggerSubmit}
                                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                                >
                                                    {FORM_OPERATIONS.SAVE}
                                                </TatvamButton>

                                                <TatvamButton
                                                    waves="light"
                                                    className="mt-1 mr-1  btn_primary waves"
                                                    onClick={this._triggerDiscard}
                                                >
                                                    {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                                        FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                                                </TatvamButton>


                                            </Fragment>

                                        )}
                                    </Fragment>

                                </TatvamCol>
                            </form>
                        ) :
                        (
                            <Row>

                                <TatvamCol m={12}>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.VIEW_USER_NAME} : </span>
                                        <span className="col s9 url-wrap"> {this.state.user_name} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.VIEW_DISPLAY_NAME} : </span>
                                        <span className="col s9 url-wrap"> {this.state.display_name} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.VIEW_FIRST_NAME} : </span>
                                        <span className="col s9 url-wrap"> {this.state.first_name} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.VIEW_LAST_NAME} : </span>
                                        <span className="col s9 url-wrap"> {this.state.last_name} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.EMAIL} : </span>
                                        <span className="col s9"> {this.state.email} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.STATUS} : </span>
                                        <span className="col s9"> {this.state.status} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.User_TYPE} : </span>
                                        <span className="col s9"> {this.state.user_type} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.DEFAULT_PROPERTY} : </span>
                                        <span className="col s9"> {this.state.selectedProperty.label} </span>
                                    </div>

                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.COUNTRY} : </span>
                                        <span className="col s9"> {this.state.country} </span>
                                    </div>
                                    <div className="view-label col s12 m6">
                                        <span className="property-label col s3"> {USER_FORM.STATE} : </span>
                                        <span className="col s9"> {this.state.state} </span>
                                    </div>
                                </TatvamCol>

                                <TatvamCol m={1} s={12} className="right modalButtons">
                                    {this.props.userPermission === APP_BOOLEANS.TRUE && (
                                        <TatvamButton
                                            className="mt-1  btn_primary "
                                            name="EDIT"
                                            onClick={this._handleAppMode}
                                        >
                                            {FORM_OPERATIONS.EDIT}
                                        </TatvamButton>
                                    )}
                                </TatvamCol>

                            </Row>
                        )}

                </TatvamCol>
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                <button type="submit" ref="submit" className="hide">
                    Submit
                </button>

            </Fragment>
        );
    }
}

export default UserForm;

