import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS} from 'localConstants';

import {ALERT_MESSAGES, APPLICATION_MODES, COMPETITOR,} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import CompetitorsForm from '../../../competitorsForm';

class Competitors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            competitorModal: false,
            colDef: [{headerName: COMPETITOR.COMPETITOR_ID, field: "id", filterParams: {clearButton: true}},
                {headerName: COMPETITOR.COMPETITOR_NAME, field: "name", filterParams: {clearButton: true}},
                {headerName: COMPETITOR.INDUSTRY, field: "industry", filterParams: {clearButton: true}},
                {headerName: COMPETITOR.IS_INSIGHT, field: "is_insight", filterParams: {clearButton: true}},
                {headerName: COMPETITOR.STATUS, field: "status", filterParams: {clearButton: true}},
            ],
            selectedCompetitorData: "",
            competitorsList: [],
            loader: true
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: this.props.currentCustomer.id,
            id: data.id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.id;
            let competitorDeleteData = {
                customer_id,
                id

            };
            this.props.actions.deleteCustomerCompetitor(competitorDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _addCompetitor = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.CREATE,
            competitorModal: true,
            title: COMPETITOR.ADD_COMPETITOR
        });
    };

    __onEditRowClick = data => {
        this.setState({
            selectedCompetitorData: data,
            applicationMode: APPLICATION_MODES.VIEW,
            competitorModal: true,
            title: ""

        });
    };
    closeModal = () => {
        this.setState({
            competitorModal: false,
            selectedCompetitorData: ""
        });
    };

    componentDidMount() {
        const selectedValues = [];
        let competitorsListOptions = [];
        let enableAdd = true;
        this.setState({
            loader: true
        });

        this.props.actions.fetchCustomerCompetitor(this.props.currentCustomer.id).then(() => {
            this.props.actions.fetchCustomerIndustry(this.props.currentCustomer.industry).then((response) => {
                this.setState({
                    loader: false
                });
                let competitorsList = [];
                if (response.status === 200) {
                    response.data.data.map((data) => {
                        if ((data.corporate !== undefined && data.corporate === APPLICATION_MODES.NO) && (data.category === this.props.currentCustomer.category || data.category === "Competitor"))
                            competitorsListOptions = [...competitorsListOptions, {
                                full_name: `${data.id} - ${data.name}`,
                                label: data.name,
                                value: data.id,
                                industry: data.industry,
                                is_insight: data.is_insight,
                                status: data.status
                            }];
                    });
                    this.setState({
                        competitorsListOptions
                    }, () => {
                        if (this.props.gridData && this.props.gridData.length) {
                            let compLength = this.props.gridData.length;
                            this.props.gridData.map((data) => {
                                this.state.competitorsListOptions.map((options) => {
                                    if (data === options.value) {
                                        selectedValues.push(options.value);
                                        options.is_insight = this.props.currentCustomer.insights.includes(data) ? "Yes" : "No";
                                        competitorsList = [...competitorsList, {
                                            id: options.value,
                                            label: options.full_name,
                                            full_name: options.full_name,
                                            name: options.label,
                                            industry: options.industry,
                                            is_insight: options.is_insight,
                                            status: options.status
                                        }];
                                    }
                                });
                            });
                        }
                    });
                    this.setState({
                        competitorsList,
                        enableAdd
                    }, () => {
                        let activeCompetitors = 0;
                        let totalCompetitors = 0;
                        if (this.props.gridData && this.props.gridData.length) {
                            totalCompetitors = this.props.gridData.length;
                            response.data.data.map((data) => {
                                for (var i = 0; i < this.props.gridData.length; i++) {
                                    if (data.id === this.props.gridData[i]) {
                                        if (data.status)
                                            activeCompetitors = activeCompetitors + 1;
                                    }
                                }
                            });
                        }
                        this.props.countPropagate({
                            'competitorSource': {
                                'active': activeCompetitors,
                                'total': totalCompetitors
                            }
                        });
                    });
                }

                this.setState({
                    selectedValues,
                    enableAdd,
                });

            });
        });
    }

    componentWillReceiveProps(props) {
        let competitorsList = [];
        let competitorsListOptions = [];
        let enableAdd = true;
        const selectedValues = [];
        if (props.currentCustomer.industry) {
            props.actions.fetchCustomerIndustry(props.currentCustomer.industry).then((response) => {
                if (response.status === 200) {
                    response.data.data.map((data) => {
                        if ((data.corporate !== undefined && data.corporate === APPLICATION_MODES.NO) && (data.category === props.currentCustomer.category || data.category === "Competitor"))
                            competitorsListOptions = [...competitorsListOptions, {
                                full_name: `${data.id} - ${data.name}`,
                                label: data.name,
                                value: data.id,
                                industry: data.industry,
                                is_insight: data.is_insight,
                                status: data.status
                            }];
                    });
                    this.setState({
                        competitorsListOptions
                    }, () => {

                        if (props.gridData && props.gridData.length) {
                            let compLength = props.gridData.length;

                            props.gridData.map((data) => {
                                this.state.competitorsListOptions.map((options) => {
                                    if (data === options.value) {
                                        selectedValues.push(options.value);
                                        options.is_insight = this.props.currentCustomer.insights.includes(data) ? "Yes" : "No";
                                        competitorsList = [...competitorsList, {
                                            id: options.value,
                                            label: options.full_name,
                                            full_name: options.full_name,
                                            name: options.label,
                                            industry: options.industry,
                                            is_insight: options.is_insight,
                                            status: options.status
                                        }];
                                    }
                                });
                            });
                        }
                    });
                    this.setState({
                        competitorsList,
                        enableAdd
                    }, () => {

                        let activeCompetitors = 0;
                        let totalCompetitors = 0;
                        if (props.gridData && props.gridData.length) {
                            totalCompetitors = props.gridData.length;
                            response.data.data.map((data) => {
                                for (var i = 0; i < props.gridData.length; i++) {
                                    if (data.id === props.gridData[i]) {
                                        if (data.status)
                                            activeCompetitors = activeCompetitors + 1;
                                    }
                                }
                            });
                        }

                        this.props.countPropagate({
                            'competitorSource': {
                                'active': activeCompetitors,
                                'total': totalCompetitors
                            }
                        });
                    });
                }
            });
        }
        this.setState({
            selectedValues,
            enableAdd
        });
    }

    render() {

        return (
            <Fragment>
                {this.props.competitorPermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton
                        className="mt-1 ml-2 mr-2  right btn_primary"
                        name="addCompetitor"
                        onClick={this._addCompetitor}
                        disabled={!this.state.enableAdd}
                        tooltip={this.state.enableAdd && "Limit Reached"}
                    >
                        {COMPETITOR.ADD_COMPETITOR}
                    </TatvamButton>
                )}
                {this.state.competitorModal && (
                    <TatvamModal
                        className="hideFooter"
                        open={this.state.competitorModal}
                        header={this.state.title}
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <CompetitorsForm
                                applicationMode={this.state.applicationMode}
                                closeModal={this.closeModal}
                                competitorsListOptions={this.state.competitorsListOptions}
                                competitorModal={this.state.competitorModal}
                                selectedCompetitorData={this.state.selectedCompetitorData}
                                competitorsList={this.state.competitorsList}
                                currentCustomer={this.props.currentCustomer}
                                selectedValues={this.state.selectedValues}
                                licenseType={this.props.license_type}
                            />
                        )}
                    />
                )}
                {this.state.competitorsList && this.state.competitorsList.length ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.competitorsList}
                            colData={this.state.colDef}
                            enableRowClick
                            onRowSelect={this.__onEditRowClick}
                            enableDeleteColumn={this.props.competitorDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                        />
                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {COMPETITOR.NO_COMPETITORS_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Competitors;
