import './advance-filter-item.component.scss';

import {Radio, Select} from 'antd';
import React, {Fragment, useEffect, useState} from 'react';
import _ from 'lodash';
import RangeFilter from '../../range-filter/range-filter.component';
import GroupCheckBox from '../../group-checkbox/group-checkbox.component';
import GroupTreeCheckBox from '../../tree-group-checkbox/group-tree-checkbox.component';
import MultiStarRating from '../../multi-star-rating/multi-star-rating.component';
import MultiTatvamRating from '../../multi-tatvam-rating/multi-tatvam-rating.component';
import {Icon} from 'react-materialize';
import {TatvamMultiSelect} from '../../../../../functionalComponents';
import {changeControlState, getSelectedItemValue} from "../../utility/filter.utility";

const {Option} = Select;

const FilterControlType = {
    TREE: 'Tree',
    LIST: 'List',
    BOOLEAN: 'Boolean',
    RATING: 'Rating',
}

const FilterOperator = {
    AND: 'AND',
    OR: 'OR',
}


const AdvanceFilterItem = (props) => {
    const operatorItems = [
        {
            label: FilterOperator.AND.toString(),
            value: FilterOperator.AND.toString(),
        },
        {
            label: FilterOperator.OR.toString(),
            value: FilterOperator.OR.toString(),
        }];

    const [activeAccordion, setActiveAccordion] = useState('');
    const [entityDropdownItems, setEntityDropdownItems] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [control, setControl] = useState(null);
    const [selectedFilterEntity, setSelectedFilterEntity] = useState(null);
    const [selectedFilterEntityOption, setSelectedFilterEntityOption] = useState(null);
    const [selectedFilterEntityOperator, setSelectedFilterEntityOperator] = useState(null);
    const [selectedFilterEntityValues, setSelectedFilterEntityValues] = useState(null);

    useEffect(() => {
        if (props.control) {
            setControl(props.control);
        } else {
            setControl(null);
        }
    }, [props.control]);

    useEffect(() => {
        if (control) {
            if (control && control.controls && control.controls.length > 0) {
                const dropdownItems = convertFilterEntityToDrpdownItems(control.controls);
                if (!_.isEqual(entityDropdownItems, dropdownItems)) {
                    let dropdownOption = []
                    dropdownItems.forEach((item) => {
                        dropdownOption.push({label: item.name, value: item.id})
                    })
                    setDropdownOptions(dropdownOption);
                    setEntityDropdownItems(dropdownItems);
                }
            }
        } else {
            setEntityDropdownItems([]);
        }
    }, [control]);

    useEffect(() => {
        if (props.value && props.control) {
            setSelectedFilterEntity(props.value.dimension_name);
            setSelectedFilterEntityOption({label: props.value.dimension_name, value: props.value.dimension_name})
            setSelectedFilterEntityOperator(props.value.dimension_condition);

            const clonedControl = {...props.control};
            if (clonedControl && clonedControl.controls && clonedControl.controls.length > 0) {
                clonedControl.controls = clonedControl.controls.map((control) => {
                    if (control.dimension.entity_type === props.value.dimension_name) {
                        const controlItems = changeControlState(control.controlItemSources, true, props.value.dimension_values);
                        return {
                            ...control,
                            controlItemSources: controlItems,
                        };
                    } else {
                        return control;
                    }
                });
                setControl(clonedControl);
                setSelectedFilterEntityValues(props.value.dimension_values);
            }
        } else {
            setSelectedFilterEntity(null);
            setSelectedFilterEntityValues([]);
            setSelectedFilterEntityOperator(null);
        }
    }, [props.value && props.control]);

    const convertFilterEntityToDrpdownItems = (controls) => {
        if (controls && controls.length > 0) {
            return controls.map((control) => {
                return {
                    id: control.dimension.entity_type,
                    isSelected: false,
                    name: control.dimension.entity_desc !== "" ? control.dimension.entity_desc : control.dimension.entity_type,
                    value: control.controlItemSources,
                };
            });
        }
        return [];
    };

    const getFilterControl = (entityName) => {
        if (!entityName || !control || !control.controls || control.controls.length <= 0) {
            return null;
        }

        return control.controls.filter((control) => control.dimension.entity_type === entityName)[0];
    };

    const onControlItemChanged = (items, controlName) => {
        let selectedValues = [];
        if (items && items.length > 0) {
            selectedValues = getSelectedItemValue(items);
        }

        setSelectedFilterEntityValues(selectedValues);
        raiseFilterValueChange(controlName, props.id, selectedFilterEntityOperator, selectedValues);
    };

    const onDeleteClick = () => {
        if (props.onDeleteClick) {
            props.onDeleteClick({
                entity: selectedFilterEntity,
                id: props.id,
                operator: selectedFilterEntityOperator,
                values: [],
            });
        }
    };

    const onFilterEntityChange = (value) => {
        let selectedEntity = entityDropdownItems.filter((item) => value.value === item.id);
        setSelectedFilterEntity(selectedEntity[0].id);
        setSelectedFilterEntityOption({label: value.label, value: value.value})
        setSelectedFilterEntityValues(null);
        raiseFilterValueChange(selectedEntity[0], props.id, selectedFilterEntityOperator, null);
    };

    const onOperatorChanged = (e) => {
        const operator = e.target.value;
        setSelectedFilterEntityOperator(operator);
        props.onOperatorChanged(props.id, operator);
    };

    const raiseFilterValueChange = (entity, id, operator, selectedValues) => {
        props.onValueSelected(id, {
            dimension_condition: operator,
            dimension_name: entity,
            dimension_values: selectedValues ? selectedValues : [],
        });
    };

    const renderFlagControl = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <RangeFilter
                showCollapseIcon={true}
                title={'Value'}
                name={dynamicControl.dimension.entity_type}
                onSliderStepChanged={onControlItemChanged}
                step={1}
                min={1}
                max={3}
                dotRanges={sourceItems}
                isExpanded={true}
            />
        </Fragment>;
    };

    const renderGroupCheckBox = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = (dynamicControl.controlItemSources);
        }

        return <Fragment>
            <GroupCheckBox
                caption={'Value'}
                name={dynamicControl.dimension.entity_type}
                items={sourceItems}
                isShowSearch={sourceItems.length > 5}
                onSelectionChanged={onControlItemChanged}
                isShowClear={true}
                isShowSelectAll={true}
                activeAccordion={activeAccordion}
                showCollapseIcon={true}
                usedFor={'advance_filter'}
                isExpanded={true}
            />
        </Fragment>;
    };

    const renderOperatorAndDeleteButton = () => {
        let isValueSelected = false;
        if (selectedFilterEntityValues) {
            isValueSelected = selectedFilterEntityValues && selectedFilterEntityValues.length > 0;//.filter((value) => value.isChecked).length > 0;
        }

        return <Fragment>
            <div className="col s12 m3 l2  btn-group pt-1">
                {isValueSelected &&
                    <div className="btn-group pt-10 mt-10">
                        <Radio.Group
                            disabled={!isValueSelected}
                            options={operatorItems}
                            onChange={onOperatorChanged}
                            value={selectedFilterEntityOperator}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                }
                {(props.onDeleteClick) &&
                    <div className="">
                        <button className="btn-link bg-white p-0 border-none" onClick={onDeleteClick}>
                            <Icon>delete</Icon>
                        </button>
                    </div>
                }
            </div>
        </Fragment>;
    };

    const renderSearchBy = () => {
        return <div className="col s12 m3 l4 ">
            <div>
                <label htmlFor="" className="font-weight-bold mb-0">Search By</label>
            </div>
            <TatvamMultiSelect
                classNamePrefix="react-select"
                className="validate select-ht col s12 pr-0 "
                value={selectedFilterEntityOption ? selectedFilterEntityOption : ''}
                name="search-by"
                id="search-by"
                onChange={onFilterEntityChange}
                options={dropdownOptions}
            >
            </TatvamMultiSelect>
        </div>;
    };

    const renderTatvamRating = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <MultiTatvamRating
                caption={'Value'}
                enableReset={true}
                isExpanded={true}
                name={dynamicControl.dimension.entity_type}
                usedFor='advance_filter'
                onRatingSelect={onControlItemChanged}
                ratingItems={sourceItems}
                showCollapseIcon={true}
            />
        </Fragment>;
    };

    const renderTreeCheckBox = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <GroupTreeCheckBox
                caption={'Value'}
                name={dynamicControl.dimension.entity_type}
                items={sourceItems}
                isShowSearch={sourceItems.length > 5}
                onSelectionChanged={onControlItemChanged}
                isShowClear={true}
                isShowSelectAll={true}
                activeAccordion={activeAccordion}
                showCollapseIcon={true}
                isExpanded={true}
            />
        </Fragment>;
    };

    const renderUserRating = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <MultiStarRating
                caption={'Value'}
                enableReset={true}
                isExpanded={true}
                name={dynamicControl.dimension.entity_type}
                onRatingSelect={onControlItemChanged}
                ratingItems={sourceItems}
                showCollapseIcon={true}
                usedFor='advance_filter'
            />
        </Fragment>;
    };

    const renderValues = () => {
        const control = getFilterControl(selectedFilterEntity);
        if (!control) {
            return <Fragment></Fragment>;
        }

        return <Fragment>
            <div className=" col s 12 m6 align-self-end pt-3">
                {
                    control.dimension.data_type === FilterControlType.BOOLEAN && renderFlagControl(control)
                }
                {
                    control.dimension.data_type === FilterControlType.LIST && renderGroupCheckBox(control)
                }
                {/* Rendering Tatvam Rating */}
                {
                    (control.dimension.data_type === FilterControlType.RATING && control.dimension.entity_type === 'TATVAM_RATING') &&
                    renderTatvamRating(control)
                }
                {/* Rendering User Rating */}
                {
                    (control.dimension.data_type === FilterControlType.RATING && control.dimension.entity_type === 'USER_RATING') &&
                    renderUserRating(control)
                }
                {
                    control.dimension.data_type === FilterControlType.TREE && renderTreeCheckBox(control)
                }
            </div>
        </Fragment>;
    };

    return <Fragment>
        <div className="search-entity">
            <div className="filter-input-row row">
                {renderSearchBy()}
                {renderValues()}
                {renderOperatorAndDeleteButton()}
            </div>
        </div>
    </Fragment>;
};

export default AdvanceFilterItem;
