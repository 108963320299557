import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteCustomerSource, fetchCustomerSources} from "../../../../../actions";
import ApiSources from "./apiSource";

const mapStateToProps = (state, ownProps) => ({
    sourcePermission: state.auth.permissions && state.auth.permissions.customer.sources.create,
    customerSource: state.customer.list[ownProps.currentCustomerId] && state.customer.list[ownProps.currentCustomerId].sources,
    customer: state.customer.list[ownProps.currentCustomerId],
    sourceDelete: state.auth.permissions && state.auth.permissions.customer.sources.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerSources,
            deleteCustomerSource
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiSources);
