import React from 'react';
import {Button} from "react-materialize";

const BaseButton = (props) => {
    return (
        <Button {...props}/>
    )
}

export default BaseButton;
