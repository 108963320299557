import './group-tree-checkbox.component.scss';

import {Card} from 'react-bootstrap';
import React, {Fragment, useEffect, useState} from 'react';

import {getTreeGroupCheckboxKeys} from '../utility/filter.utility';
import {Tree} from 'antd';
import _ from 'lodash';
import {Collapsible, CollapsibleItem} from "react-materialize";

const GroupTreeCheckBox = (props) => {
    const [beforeFilteredItems, setBeforeFilteredItems] = useState([]);
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (props.items && props.items.length) {
            setBeforeFilteredItems(props.items);
        } else {
            setBeforeFilteredItems([]);
        }
    }, [props.items])

    useEffect(() => {
        if (beforeFilteredItems.length > 0) {
            setFilteredItems(searchGroupCheckboxItems(searchText, beforeFilteredItems));
            setSelectedItemKeys(getSelectedItemKeys(beforeFilteredItems));
        } else {
            setFilteredItems([]);
            setSelectedItemKeys([]);
        }
    }, [beforeFilteredItems])

    useEffect(() => {
        if (searchText.trim().length >= 1) {
            setFilteredItems(searchGroupCheckboxItems(searchText, beforeFilteredItems));
        } else {
            setFilteredItems(beforeFilteredItems);
        }
    }, [searchText]);

    const searchGroupCheckboxItems = (searchText, GroupTreeCheckboxItems) => {
        if (searchText.trim().length >= 1) {
            const filteredCheckboxGroupItems = GroupTreeCheckboxItems.filter((checkBoxItem) => checkBoxItem.title.toLowerCase().includes(searchText.toLowerCase()));
            const filteredCheckboxGroupChildItems = GroupTreeCheckboxItems.filter((checkBoxItem) => checkBoxItem.children.filter((item) => item.title.toLowerCase().includes(searchText.toLowerCase())).length > 0);
            const result = _.union(filteredCheckboxGroupItems, filteredCheckboxGroupChildItems)
            return result;
        } else {
            return GroupTreeCheckboxItems;
        }
    }

    const getSelectedItemKeys = (items) => {
        let result = [];
        if (items && items.length > 0) {
            const parents = items.filter((item) => item.isChecked && item.children.length <= 0);
            const childs = items.map((item) => {
                return item.children.filter((child) => child.isChecked);
            });
            const checkedChild = _.uniq(_.flatten(childs));
            result = _.union(parents.map(child => child.key), checkedChild.map(child => child.key));
        }
        return result;
    }

    const isItemChecked = () => {
        if (!selectedItemKeys) {
            return false;
        }

        return selectedItemKeys.length > 0;
    }

    const onCheck = (checkedItems) => {
        let itemValues = [];
        if (checkedItems && checkedItems.length > 0) {
            itemValues = beforeFilteredItems.map((item) => {
                return {
                    ...item,
                    isChecked: checkedItems.includes(item.key),
                    children: item.children.map((children) => {
                        return {
                            ...children,
                            isChecked: checkedItems.includes(children.key),
                        };
                    })
                };
            });
            setSelectedItemKeys(checkedItems);
        } else {
            setSelectedItemKeys([]);
        }
        props.onSelectionChanged(itemValues, props.name);
    };

    const onClearClick = (e) => {
        setSelectedItemKeys([]);
        setSearchText('');
        setBeforeFilteredItems(beforeFilteredItems.map((item) => {
            item.isChecked = false;
            if (item.children) {
                item.children = item.children.map((childItem) => {
                    childItem.isChecked = false;
                    return childItem;
                })
            }
            return item;
        }))
        props.onSelectionChanged([], props.name);
        e.stopPropagation();
        e.preventDefault();
    };

    const onClearSearch = () => {
        onSearchTextChanged('');
    }

    const onSearchTextChanged = (value) => {
        setSearchText(value)
        if (value.trim().length >= 1) {
            setFilteredItems(searchGroupCheckboxItems(value, beforeFilteredItems));
        } else {
            setFilteredItems(beforeFilteredItems);
        }
    }

    const onSelectAllClick = (e) => {
        const checkedItemKeys = getTreeGroupCheckboxKeys(beforeFilteredItems);
        setSelectedItemKeys(checkedItemKeys);

        setSearchText('');

        props.onSelectionChanged(beforeFilteredItems.map((item) => {
            return {
                ...item,
                isChecked: true,
                children: item.children.map((child) => {
                    return {
                        ...child,
                        isChecked: true,
                    };
                }),
            };
        }), props.name);

        e.stopPropagation();
        e.preventDefault();
    };

    const renderBody = () => {
        const renderSearchBar = () => {
            return <Fragment>
                <div className="d-flex search-control">
                    <i className="fas fa-search"/>
                    <input
                        autoComplete="off"
                        type="text"
                        id="itemTreeSearch"
                        value={searchText}
                        onChange={e => onSearchTextChanged(e.target.value)}
                        className="form-control text-control"
                        placeholder={`Search ${props.caption}`}
                        aria-label={`Search ${props.caption}`}
                        maxLength={30}
                    />
                    {searchText.length > 0 &&
                        <a className="" onClick={onClearSearch}><span className="fas fa-times"/></a>}
                </div>
            </Fragment>
        }

        const cardBody = () => {
            if (filteredItems.length > 0) {
                return <Fragment>
                    <Tree
                        checkable
                        onCheck={onCheck}
                        checkedKeys={selectedItemKeys}
                        treeData={filteredItems}
                    />
                </Fragment>;
            } else {
                return <Fragment>No Record</Fragment>;
            }
        };

        if (!props.showCollapseIcon) {
            return (
                <Card.Body>{cardBody()}</Card.Body>
            );
        }
        return (
            <Fragment>
                {props.isShowSearch === true && renderSearchBar()}
                {cardBody()}
            </Fragment>
        );
    };

    const renderClear = () => {
        if (!beforeFilteredItems || beforeFilteredItems.length <= 0 || !props.isShowClear || !isItemChecked()) {
            return <Fragment></Fragment>;
        }

        return (
            <button
                type="button"
                className="btn-link border-none bg-white p-0"
                onClick={onClearClick}
            >
                clear
            </button>
        );
    };

    const renderHeader = () => {
        if (!props.showCollapseIcon) {
            return (
                <Fragment>
                    <p className="m-0 mr-2">{props.caption}</p>
                    {renderClear()}
                    {renderSelectAll()}
                </Fragment>
            );
        } else
            return (
                <Fragment>
                    <p className="m-0 mr-2">{props.caption}</p>
                    {renderClear()}
                    {renderSelectAll()}
                </Fragment>
            );
    };

    const renderSelectAll = () => {
        if (!beforeFilteredItems || beforeFilteredItems.length <= 0 || !props.isShowSelectAll || isItemChecked()) {
            return;
        }

        return (
            <button
                type="button"
                className="btn-link border-none bg-white p-0"
                onClick={onSelectAllClick}>
                select all
            </button>
        );
    };
    return (
        <div className="group-tree-checkbox" style={{height: props.height ? `${props.height}vh` : `auto`}}>
            {props.showCollapseIcon ? (

                <Collapsible accordion className="m-0">
                    <CollapsibleItem
                        expanded={props.isExpanded}
                        header={renderHeader()}
                        node="div"
                    >
                        <div>
                            {renderBody()}
                        </div>
                    </CollapsibleItem>
                </Collapsible>
            ) : (
                <Card>
                    {renderHeader()}
                    {renderBody()}
                </Card>
            )}

        </div>
    );
}

export default GroupTreeCheckBox;
