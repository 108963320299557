import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS} from 'localConstants';

import {ALERT_MESSAGES, APPLICATION_MODES, TENANT,} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import TenantsForm from '../../../tenantsForm';

class Tenants extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tenantModal: false,
            colDef: [{headerName: TENANT.TENANT_ID, field: "id", filterParams: {clearButton: true}},
                {headerName: TENANT.TENANT_NAME, field: "name", filterParams: {clearButton: true}},
                {headerName: TENANT.INDUSTRY, field: "industry", filterParams: {clearButton: true}},
            ],
            selectedTenantData: "",
            tenantsList: [],
            loader: true
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: this.props.currentCustomer.id,
            id: data.id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.id;
            let tenantDeleteData = {
                customer_id,
                id

            };
            this.props.actions.deleteCustomerTenant(tenantDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _addTenant = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.CREATE,
            tenantModal: true,
            title: TENANT.ADD_TENANT
        });
    };

    __onEditRowClick = data => {
        this.setState({
            selectedTenantData: data,
            applicationMode: APPLICATION_MODES.VIEW,
            tenantModal: true,
            title: ""
        });
    };

    closeModal = () => {
        this.setState({
            tenantModal: false,
            selectedTenantData: ""
        });
    };


    componentDidMount() {
        const selectedValues = [];
        let tenantsListOptions = [];
        let enableAdd = true;
        this.setState({
            loader: true
        });
        this.props.actions.fetchCustomerTenant(this.props.currentCustomer.id).then(() => {
            this.props.actions.fetchTenantIndustry(this.props.currentCustomer.industry).then((response) => {
                this.setState({
                    loader: false
                });
                let tenantsList = [];
                if (response.status === 200) {
                    response.data.data.map((data) => {
                        if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE && (data.corporate !== undefined && data.corporate === APPLICATION_MODES.NO) && (data.category === this.props.currentCustomer.category || data.category === "Competitor" || data.category === "Tenants"))
                            tenantsListOptions = [...tenantsListOptions, {
                                label: data.name,
                                value: data.id,
                                industry: data.industry
                            }];
                    });
                    this.setState({
                        tenantsListOptions
                    }, () => {
                        if (this.props.gridData && this.props.gridData.length) {
                            let tenLength = this.props.gridData.length;
                            if (this.props.license_type) {
                                if (this.props.license_type === "Gold") {
                                    if (tenLength >= 4)
                                        enableAdd = false;
                                }
                                if (this.props.license_type === "Silver") {
                                    if (tenLength >= 2)
                                        enableAdd = false;
                                }
                                if (this.props.license_type === "Bronze") {
                                    if (tenLength >= 1)
                                        enableAdd = false;
                                }
                            } else {
                                enableAdd = false;
                            }
                            this.props.gridData.map((data) => {
                                this.state.tenantsListOptions.map((options) => {
                                    if (data === options.value) {
                                        selectedValues.push(options.value);
                                        tenantsList = [...tenantsList, {
                                            id: options.value,
                                            name: options.label,
                                            industry: options.industry
                                        }];
                                    }
                                });
                            });
                        }
                    });
                    this.setState({
                        tenantsList,
                        enableAdd,
                    }, () => {
                        let activeCompetitors = 0;
                        let totalCompetitors = 0;
                        if (this.props.gridData && this.props.gridData.length) {
                            totalCompetitors = this.props.gridData.length;
                            response.data.data.map((data) => {
                                for (var i = 0; i < this.props.gridData.length; i++) {
                                    if (data.id === this.props.gridData[i]) {
                                        if (data.status)
                                            activeCompetitors = activeCompetitors + 1;
                                    }
                                }
                            });
                        }

                        this.props.countPropagate({
                            'tenantSource': {
                                'active': activeCompetitors,
                                'total': totalCompetitors
                            }
                        });
                    });
                }

                this.setState({
                    selectedValues,
                    enableAdd,
                });
            });
        });
    }

    componentWillReceiveProps(props) {
        let tenantsList = [];
        let tenantsListOptions = [];
        let enableAdd = true;
        if (props.currentCustomer.industry) {
            props.actions.fetchTenantIndustry(props.currentCustomer.industry).then((response) => {
                if (response.status === 200) {
                    response.data.data.map((data) => {
                        if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE && (data.corporate !== undefined && data.corporate === APPLICATION_MODES.NO) && (data.category === props.currentCustomer.category || data.category === "Competitor" || data.category === "Tenants")) {
                            tenantsListOptions = [...tenantsListOptions, {
                                label: data.name,
                                value: data.id,
                                industry: data.industry
                            }];
                        }
                    });
                    this.setState({
                        tenantsListOptions
                    }, () => {

                        if (props.gridData && props.gridData.length) {
                            let tenLength = props.gridData.length;
                            if (props.license_type) {
                                if (props.license_type === "Gold") {
                                    if (tenLength >= 4)
                                        tenLength = false;
                                }
                                if (props.license_type === "Silver") {
                                    if (tenLength >= 2)
                                        enableAdd = false;
                                }
                                if (props.license_type === "Bronze") {
                                    if (tenLength >= 1)
                                        enableAdd = false;
                                }
                            } else {
                                enableAdd = false;
                            }
                            props.gridData.map((data) => {
                                this.state.tenantsListOptions.map((options) => {
                                    if (data === options.value) {
                                        tenantsList = [...tenantsList, {
                                            id: options.value,
                                            name: options.label,
                                            industry: options.industry
                                        }];
                                    }
                                });
                            });
                        }
                    });
                    this.setState({
                        tenantsList,
                        enableAdd
                    }, () => {

                        let activeCompetitors = 0;
                        let totalCompetitors = 0;
                        if (props.gridData && props.gridData.length) {
                            totalCompetitors = props.gridData.length;
                            response.data.data.map((data) => {
                                for (var i = 0; i < props.gridData.length; i++) {
                                    if (data.id === props.gridData[i]) {
                                        if (data.status)
                                            activeCompetitors = activeCompetitors + 1;
                                    }
                                }
                            });
                        }
                        this.props.countPropagate({
                            'tenantSource': {
                                'active': activeCompetitors,
                                'total': totalCompetitors
                            }
                        });
                    });
                }
            });
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.tenantPermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton
                        className="mt-1 ml-2 mr-2  right btn_primary"
                        name="addTenant"
                        onClick={this._addTenant}
                        disabled={!this.state.enableAdd}
                        tooltip={this.state.enableAdd && "Limit Reached"}
                    >
                        {TENANT.ADD_TENANT}
                    </TatvamButton>
                )}
                {this.state.tenantModal && (
                    <TatvamModal
                        className="hideFooter"
                        open={this.state.tenantModal}
                        header={this.state.title}
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <TenantsForm
                                applicationMode={this.state.applicationMode}
                                closeModal={this.closeModal}
                                tenantsListOptions={this.state.tenantsListOptions}
                                tenantModal={this.state.tenantModal}
                                selectedTenantData={this.state.selectedTenantData}
                                tenantsList={this.state.tenantsList}
                                currentCustomer={this.props.currentCustomer}
                                selectedValues={this.state.selectedValues}
                                licenseType={this.props.license_type}
                            />
                        )}
                    />
                )}
                {this.state.tenantsList && this.state.tenantsList.length ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.tenantsList}
                            colData={this.state.colDef}
                            enableRowClick
                            onRowSelect={this.__onEditRowClick}
                            enableDeleteColumn={this.props.tenantDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                        />
                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {TENANT.NO_TENANTS_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Tenants;
