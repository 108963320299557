import React from 'react';
import {Textarea} from "react-materialize";

const BaseTextArea = (props) => {
    return (
        <Textarea {...props}/>
    )
}

export default BaseTextArea;
