import './reportSubscriptionFilter.scss';

import React, {Fragment, useEffect, useState,} from 'react';
import {Button} from 'react-bootstrap';
import {useDispatch, useSelector,} from 'react-redux';

import AdvanceFilter from './shared/advance-filter/advance-filter.component';
import {DEFAULT_PERIODICITY_MONTH, PERIODICITY_ITEMS,} from './shared/constants';
import GroupCheckBox from './shared/group-checkbox/group-checkbox.component';
import MultiStarRating from './shared/multi-star-rating/multi-star-rating.component';
import MultiTatvamRating from './shared/multi-tatvam-rating/multi-tatvam-rating.component';
import RangeFilter from './shared/range-filter/range-filter.component';
import {FilterService} from './shared/service/filter.Service';
import GroupTreeCheckBox from './shared/tree-group-checkbox/group-tree-checkbox.component';
import {
    changeControlState,
    convertFilterEntityValueToCheckBoxItems,
    convertFilterEntityValueToRatingItems,
    convertFilterEntityValueToSliderDotRange,
    convertFilterEntityValueToTreeCheckBoxItems,
    getSelectedItemDimensionValue,
} from './shared/utility/filter.utility';

const FilterControlType = {
    TREE: 'Tree',
    LIST: 'List',
    BOOLEAN: 'Boolean',
    RATING: 'Rating',
}

const SubscriptionFilter = (props) => {
    const dispatch = useDispatch();
    const accountState = useSelector((state) => state.auth);
    const globalFilterPanelState = useSelector((state) => state.globalFilter);
    const propertyState = {
        selectedProperty: {id: props.currentCustomerId},
    };

    const showSearchAfterMaxItem = 5;

    const [filterControls, setFilterControls] = useState(null);

    const [pageDataState, setPageDataState] = useState([]);

    const [advanceFilters, setAdvanceFilters] = useState([]);
    const [activeAccordion, setActiveAccordion] = useState('');
    const [filterEntities, setFilterEntities] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [isPageDataLoading, setIsPageDataLoading] = useState(false);
    const [isResetOn, setIsResetOn] = useState(false);
    const [isShowAdvancedFilter, setShowAdvancedFilter] = useState(false);
    const [selectedPeriodicity, setSelectedPeriodicity] = useState(null);
    const [selectedPeriodicityItem, setSelectedPeriodicityItem] = useState(null);
    let filterService = new FilterService(propertyState.selectedProperty.id, accountState);
    let filterEntityData = [];

    useEffect(() => {
        let filterEntitiesData;
        filterService.getFilterEntity().then((response) => {
            filterEntityData = response

            filterEntitiesData = setFilterEntities(response
                .filter((entity) => entity.is_filter === 'Yes')
                .sort((x, y) => Number(x.sequence_no) - Number(y.sequence_no)),
            );
        })
    }, [])

    useEffect(() => {
        if (propertyState.selectedProperty.id) {
            const newPeriodicityValues = globalFilterPanelState.pageDefaultFilter.periodicity;
            setSelectedPeriodicity({
                periodicityRanges: globalFilterPanelState.pageDefaultFilter.periodicityRanges,
                customPeriodictyRangeTexts: globalFilterPanelState.pageDefaultFilter.customPeriodictyRangeTexts,
                periodicityType: globalFilterPanelState.pageDefaultFilter.periodicityType,
                periodicityValues: newPeriodicityValues,
            });
            setSelectedPeriodicityItem(PERIODICITY_ITEMS.filter((item) => item.key === globalFilterPanelState.pageDefaultFilter.periodicityItemKey)[0]);
        }
    }, [propertyState.selectedProperty.id]);

    useEffect(() => {
        if (filterEntities && filterEntities.length > 0) {
            const newFilterControl = populateFilterControlsState(filterEntities);
            setFilterControls(newFilterControl);

            if (selectedPeriodicity.periodicityValues) {
                setFilterControls({
                    ...newFilterControl,
                    periodicity: {
                        periodicityType: selectedPeriodicity.periodicityType,
                        periodicityValues: selectedPeriodicity.periodicityValues,
                    },
                });
            }

            loadFilterData({
                limit: 0,
                periodicity: globalFilterPanelState.pageDefaultFilter.periodicityValues,
                periodicityType: globalFilterPanelState.pageDefaultFilter.periodicityType,
                skip: 0,
                dimensions: [],
            }, filterEntities);
        }
    }, [filterEntities]);

    useEffect(() => {
        if (filterControls &&
            filterControls.controls &&
            filterControls.controls.length > 0 &&
            globalFilterPanelState.appliedFilter &&
            !globalFilterPanelState.appliedFilter.isAdvanceFilterApplied &&
            globalFilterPanelState.appliedFilter.dimensions.length > 0) {
            let isMatched = false;
            const clonedControl = {...filterControls};
            if (clonedControl && clonedControl.controls && clonedControl.controls.length > 0) {
                for (let controlIndex = 0; controlIndex < clonedControl.controls.length; controlIndex++) {
                    const control = clonedControl.controls[controlIndex];
                    if (control.controlItemSources && control.controlItemSources.length > 0) {
                        globalFilterPanelState.appliedFilter.dimensions.forEach((dimension) => {
                            if (control.dimension.entity_type === dimension.dimension_name &&
                                dimension.dimension_values &&
                                dimension.dimension_values.length > 0) {
                                const defaulValue = isResetOn ? false : true;
                                clonedControl.controls[controlIndex].controlItemSources = changeControlState(clonedControl.controls[controlIndex].controlItemSources, defaulValue, dimension.dimension_values);
                                clonedControl.controls[controlIndex].selectedValues = getSelectedItemDimensionValue(control.controlItemSources);
                                isMatched = true;
                            }
                        });
                    }
                }
            }
            if (isMatched) {
                setFilterControls(clonedControl);
            }
        }
    }, [filterControls && filterControls.controls]);

    useEffect(() => {
        if (pageDataState && pageDataState.length > 0) {
            const clonedFilterControls = filterControls.controls.map((control) => {
                return {...control};
            });
            pageDataState.forEach((response, index) => {
                if (filterEntities && filterEntities.length > 0) {
                    const entity = filterEntities[index];
                    if (response && entity && clonedFilterControls) {
                        clonedFilterControls.forEach((control) => {
                            if (control.dimension.entity_type === entity.entity_type) {
                                const value = response;
                                control.dimensionValues = value ? value : [];
                                if (control.dimension.data_type === FilterControlType.LIST) {
                                    control.controlItemSources = convertFilterEntityValueToCheckBoxItems(control.dimensionValues, control.dimension.entity_type);
                                } else if (control.dimension.data_type === FilterControlType.BOOLEAN) {
                                    control.controlItemSources = convertFilterEntityValueToSliderDotRange(control.dimensionValues);
                                } else if (control.dimension.data_type === FilterControlType.TREE) {
                                    control.controlItemSources = convertFilterEntityValueToTreeCheckBoxItems(control.dimensionValues);
                                } else if (control.dimension.data_type === FilterControlType.RATING) {
                                    control.controlItemSources = convertFilterEntityValueToRatingItems(control.dimensionValues);
                                }
                                control.selectedValues = [];
                            }
                        });

                        setFilterControls({
                            ...filterControls,
                            periodicity: {
                                periodicityType: selectedPeriodicity.periodicityType,
                                periodicityValues: selectedPeriodicity.periodicityValues,
                            },
                            controls: clonedFilterControls,
                        });
                    }
                }
            });
        }
    }, [pageDataState]);

    const isControlVisible = (controlName, entityType) => {
        const visibles = globalFilterPanelState.configuration.visibles;
        if (visibles && filterEntities) {
            const isVisibles = visibles.filter((visible) => visible.name === controlName);
            if (entityType) {
                const isEntityExists = filterEntities.filter((entity) => entity.entity_type === entityType);
                if (isVisibles.length > 0 && isEntityExists.length > 0) {
                    return isVisibles[0].isVisible && isEntityExists[0].is_filter.toLowerCase() === 'yes';
                }
            } else if (isVisibles.length > 0) {
                return isVisibles[0].isVisible;
            }
        }

        return false;
    };

    const isPeriodicityVisible = () => {
        return isControlVisible(globalFilterPanelState.control.periodicity.name, null);
    };

    const loadFilterData = (reviewFilter, filterEntities) => {
        if (propertyState.selectedProperty.id && filterEntities && filterEntities.length > 0) {
            let filterService = new FilterService(propertyState.selectedProperty.id, accountState);
            let filterRequest = {
                limit: 0,
                periodicity: null,
                periodicityType: null,
                skip: 0,
                dimensions: [],
            };

            if (globalFilterPanelState.configuration.isDataFromPeriodicity) {
                filterRequest = {
                    ...filterRequest,
                    periodicityType: globalFilterPanelState.pageDefaultFilter.periodicityType,
                    periodicityValues: globalFilterPanelState.pageDefaultFilter.periodicity,
                };
            }

            let requests = filterEntities.map((entity) => {
                return filterService.getFilterEntityValue(entity.entity_type, filterRequest);
            });
            if (requests && requests.length > 0) {
                setIsPageDataLoading(true);
                Promise.all(requests)
                    .then((responses) => {
                        setIsPageDataLoading(false);
                        setPageDataState(responses);
                    })
                    .catch((responseError) => {
                        setIsPageDataLoading(false);
                    });
            }
        }
    };

    const onAdvanceFilterApply = (items) => {
        resetFilterPanelSearchControl();
        setAdvanceFilters(items);
        setShowAdvancedFilter(!isShowAdvancedFilter);
    };

    const onControlItemChanged = (items, controlName) => {
        updateFilterControl(items, controlName);
    };

    const onFilterApplyClick = () => {
        setIsResetOn(false);
        const appliedFilter = populateFilterCriteria();

        const isFilterApplied = appliedFilter.dimensions.length > 0;
        setIsFilterApplied(isFilterApplied);

        if (props.onFilterApplyClicked) {
            props.onFilterApplyClicked(appliedFilter);
        }
    };

    const onResetClick = (e) => {
        setIsResetOn(true);
        setDefaultState();
        setAdvanceFilters([]);
    };

    const populateFilterControlsState = (entities) => {
        return {
            periodicity: {},
            controls: entities.map((entity) => {
                return {
                    controlItemSources: [],
                    dimension: entity,
                    dimensionValues: [],
                    selectedValues: [],
                };
            }),
        };
    };

    const populateFilterCriteria = () => {
        const all = 1;
        let filter = {
            periodicityItemKey: DEFAULT_PERIODICITY_MONTH,
            limit: globalFilterPanelState.configuration.limit,
            skip: globalFilterPanelState.configuration.skip,
            isAdvanceFilterApplied: false,
            dimensions: [],
        };

        if (isPeriodicityVisible()) {
            filter.periodicity = selectedPeriodicity.periodicityValues;
            filter.periodicityItemKey = selectedPeriodicityItem.key;
            filter.periodicityType = selectedPeriodicity.periodicityType;
        }
        if (!advanceFilters || advanceFilters.length <= 0) {
            if (filterControls) {
                const controlCount = filterControls ? filterControls.controls.length : 0;
                for (let controlIndex = 0; controlIndex < controlCount; controlIndex++) {
                    const control = filterControls.controls[controlIndex];
                    if (control.selectedValues && control.selectedValues.length) {
                        const valueCount = control.selectedValues.length;
                        const filterDimension = {
                            dimension_name: control.dimension.entity_type,
                            dimension_values: [],
                            dimension_condition: null,
                        };
                        for (let valueIndex = 0; valueIndex < valueCount; valueIndex++) {
                            const selectedValue = control.selectedValues[valueIndex].value;
                            if (selectedValue && selectedValue.entity_value) {
                                filterDimension.dimension_values.push({
                                    entity_type: selectedValue.entity_type,
                                    entity_value: selectedValue.entity_value,
                                    parent_entity_type: selectedValue.parent_entity_type,
                                    parent_entity_value: selectedValue.parent_entity_value,
                                });
                            }
                        }
                        if (filterDimension.dimension_values.length > 0) {
                            filter.dimensions.push(filterDimension);
                        }
                    }
                }
            }
        } else if (advanceFilters && advanceFilters.length > 0) {
            filter.isAdvanceFilterApplied = true;
            filter.dimensions = advanceFilters;
        }
        return filter;
    };

    const renderAdvancedFilter = () => {
        if (isPageDataLoading) {
            return <Fragment></Fragment>;
        }

        return <Fragment>
            <button
                className="btn-link border-none bg-white"
                onClick={() => {
                    setShowAdvancedFilter(true);
                }}>
                {advanceFilters.length ? 'Edit Advanced Filter' : ' Advanced Filter'}
            </button>
            {isShowAdvancedFilter && <AdvanceFilter
                advanceFilterItems={advanceFilters}
                controls={filterControls}
                onAdvanceDialogCancelClick={() => setShowAdvancedFilter(!isShowAdvancedFilter)}
                onAdvanceDialogOkClick={onAdvanceFilterApply}
                periodicity={selectedPeriodicity}
                show={isShowAdvancedFilter}
                currentCustomerId={propertyState.selectedProperty.id}
            />
            }
        </Fragment>;
    };

    const renderFlagControl = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <RangeFilter
                showCollapseIcon={true}
                title={dynamicControl.dimension.entity_desc}
                name={dynamicControl.dimension.entity_type}
                onSliderStepChanged={onControlItemChanged}
                step={1}
                min={1}
                max={3}
                dotRanges={sourceItems}
                isExpanded={false}
            />
        </Fragment>;
    };

    const renderFilterControls = () => {
        if (filterControls && filterControls.controls && filterControls.controls.length > 0) {
            return filterControls.controls.map((control, index) => {
                if (control.dimension.data_type === FilterControlType.LIST) {
                    return renderGroupCheckBox(control);
                } else if (control.dimension.data_type === FilterControlType.RATING) {
                    if (control.dimension.entity_type === 'TATVAM_RATING') {
                        return renderTatvamRating(control);
                    } else {
                        return renderUserRating(control);
                    }
                } else if (control.dimension.data_type === FilterControlType.BOOLEAN) {
                    return renderFlagControl(control);
                } else if (control.dimension.data_type === FilterControlType.TREE) {
                    return renderTreeCheckBox(control);
                }
            });
        }
        return <Fragment></Fragment>;
    };

    const renderFilterSubmits = () => {
        if (isPageDataLoading) {
            return <Fragment>
                <div className="filter-buttons">
                    <span className="spinner-grow spinner-grow-sm text-primary mr-2"></span>
                    <b>Loading Filters..</b>
                </div>
                <hr></hr>
            </Fragment>;
        } else {
            return <Fragment>
                <div className="filter-buttons">
                    <Button
                        className="btn btn_primary"
                        data-toggle="tooltip"
                        disabled={isPageDataLoading}
                        onClick={onFilterApplyClick}
                        title="Apply">
                        Apply
                    </Button>

                    {props.hasFilterReset === true &&
                        <Button
                            className="btn btn-outline-primary mr-2 "
                            disabled={isPageDataLoading}
                            onClick={onResetClick}
                            data-toggle="tooltip"
                            title="Reset">
                            Reset
                        </Button>
                    }
                </div>
            </Fragment>;
        }
    };

    const renderGroupCheckBox = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <GroupCheckBox
                caption={dynamicControl.dimension.entity_desc !== "" ? dynamicControl.dimension.entity_desc : dynamicControl.dimension.entity_type}
                name={dynamicControl.dimension.entity_type}
                items={sourceItems}
                isShowSearch={sourceItems.length > showSearchAfterMaxItem}
                onSelectionChanged={onControlItemChanged}
                isShowClear={true}
                isShowSelectAll={true}
                activeAccordion={activeAccordion}
                showCollapseIcon={true}
                isExpanded={false}
            />
        </Fragment>;
    };

    const renderTatvamRating = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <MultiTatvamRating
                caption={dynamicControl.dimension.entity_desc}
                enableReset={true}
                isExpanded={false}
                name={dynamicControl.dimension.entity_type}
                onRatingSelect={onControlItemChanged}
                ratingItems={sourceItems}
                showCollapseIcon={true}
            />
        </Fragment>;
    };

    const renderTreeCheckBox = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <GroupTreeCheckBox
                caption={dynamicControl.dimension.entity_desc}
                name={dynamicControl.dimension.entity_type}
                items={sourceItems}
                isShowSearch={sourceItems.length > showSearchAfterMaxItem}
                onSelectionChanged={onControlItemChanged}
                isShowClear={true}
                isShowSelectAll={true}
                activeAccordion={activeAccordion}
                showCollapseIcon={true}
                isExpanded={false}
            />
        </Fragment>;
    };

    const renderUserRating = (dynamicControl) => {
        let sourceItems = [];

        if (!dynamicControl.dimension) {
            return <Fragment></Fragment>;
        }

        if (dynamicControl.controlItemSources) {
            sourceItems = dynamicControl.controlItemSources;
        }

        return <Fragment>
            <MultiStarRating
                caption={dynamicControl.dimension.entity_desc}
                enableReset={true}
                isExpanded={false}
                name={dynamicControl.dimension.entity_type}
                onRatingSelect={onControlItemChanged}
                ratingItems={sourceItems}
                showCollapseIcon={true}
            />
        </Fragment>;
    };

    const resetFilterPanelSearchControl = () => {
        const clonedFilterControls = {...filterControls};
        clonedFilterControls.controls.forEach((control) => {
            control.selectedValues = [];
            if (control.controlItemSources) {
                control.selectedValues = [];
                control.controlItemSources = control.controlItemSources.map((itemSource) => {
                    itemSource.isChecked = false;
                    if (itemSource.children) {
                        itemSource.children = itemSource.children.map((child) => {
                            child.isChecked = false;
                            return child;
                        });
                    }
                    return itemSource;
                });
            }
        });
        setFilterControls(clonedFilterControls);
    };

    const setDefaultState = () => {
        setActiveAccordion('');

        setSelectedPeriodicity({
            periodicityRanges: globalFilterPanelState.pageDefaultFilter.periodicityRanges,
            customPeriodictyRangeTexts: globalFilterPanelState.pageDefaultFilter.customPeriodictyRangeTexts,
            periodicityType: globalFilterPanelState.pageDefaultFilter.periodicityType,
            periodicityValues: globalFilterPanelState.pageDefaultFilter.periodicity,
        });
        setSelectedPeriodicityItem(PERIODICITY_ITEMS.filter((item) => item.key === globalFilterPanelState.pageDefaultFilter.periodicityItemKey)[0]);

        resetFilterPanelSearchControl();

        setAdvanceFilters([]);
    };

    const updateFilterControl = (items, controlName) => {
        const clonedFilterControls = {...filterControls};
        if (clonedFilterControls) {
            clonedFilterControls.controls.forEach((filterControl) => {
                let selectedDimensionValues = filterControl.selectedValues;
                if (filterControl.dimension.entity_type === controlName) {
                    if (items && items.length > 0) {
                        selectedDimensionValues = getSelectedItemDimensionValue(items);
                    } else {
                        selectedDimensionValues = [];
                    }
                }
                filterControl.selectedValues = selectedDimensionValues;
            });
        }
        setFilterControls(clonedFilterControls);
        return clonedFilterControls;
    };

    return (
        <div>
            <div className="filterPanel">
                <div className="fixed-part">
                    <div className="strong valign center pt-5 mb-3">
                        FILTERS
                    </div>
                </div>
                <div className={`scrollable-part ${advanceFilters.length ? 'disabled' : ''}`}>
                    {renderFilterControls()}
                </div>
                <div className="fixed-part valign center pb-2">
                    {renderAdvancedFilter()}
                    {renderFilterSubmits()}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionFilter;
