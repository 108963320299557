import ApiSourceDetails from "./apiSourceDetails";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteOneXpath, fetchAllXpaths} from "../../../actions";

const mapStateToProps = (state, ownProps) => ({
    source: state.sources.list[ownProps.location.state.sourceId],
    xpaths: state.sources.list[ownProps.location.state.sourceId] ? state.sources.list[ownProps.location.state.sourceId].xpaths : []
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllXpaths,
            deleteOneXpath
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiSourceDetails);
