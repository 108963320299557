import './customerDimensionForm.scss';

import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    PROPERTY_DIMENSION,
    SWITCH_TEXT,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {trimCapitalize, validateEntityName,} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerDimensionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            sourceListsOptions: [],
            selectedSource: [],
            applicationMode: props.applicationMode,
            preLoader: false,
            isFormValid: true,
            status: APPLICATION_MODES.INACTIVE,
            parentEntityListsOptions: [],
            entity_type: "",
            entity_desc: "",
            sequence_no: "",
            isActive: false,
            isFilter: false,
            isPublic: false,
            is_filter: APPLICATION_MODES.NO,
            is_public: APPLICATION_MODES.NO,
            isTagging: false,
            is_tagging: APPLICATION_MODES.NO,
            isDisplayTag: false,
            is_displaytag: APPLICATION_MODES.NO,
            isClickable: false,
            is_clickable: APPLICATION_MODES.NO,
            attribute_for_tagging: "",
            isValid: true,
            editFlag: false,
            parent_entity: "",
            validEntityName: true,
            validSequenceNumber: true,
            validAttributeTagging: true,
            selectedTagTreatment: [],
            tagTreatmentListsOptions: [],
            dimensionTypeListOptions: [
                {value: 'Public', label: 'Public'},
                {value: 'Private', label: 'Private'},
                {value: 'Both', label: 'Both'}
            ],
            selectedDimensionType: [],
        };
    }


    componentDidMount() {
        const dimensionLst = [];
        dimensionLst.push({label: "", value: ""});
        if (this.props.dimensionList) {
            for (let data of Object.values(this.props.dimensionList)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE) {
                    if (data.customer_id === PROPERTY_DIMENSION.DZERO_CUSTOMER) {
                        if (data.entity_type === "CLASSIFIER") {
                            dimensionLst.push({label: data.entity_type, value: data.entity_type});
                        }
                    } else {
                        dimensionLst.push({label: data.entity_type, value: data.entity_type});
                    }
                }
            }
            dimensionLst.sort(dynamicSort("label"));
            dimensionLst[0].label = PROPERTY_DIMENSION.SELECT_PARENT_ENTITY;
            this.setState({
                sourceListsOptions: dimensionLst
            });
        }

        this.props.actions.fetchTagTreatment().then(() => {
            if (this.props.tagTreatments) {
                this.setTagTreatment(this.props);
            }
        });

        if (!Array.isArray(this.props.dimensionData)) {
            let selectedSource;
            if (this.props.dimensionData.parent_entity) {
                selectedSource = {
                    label: this.props.dimensionData.parent_entity,
                    value: this.props.dimensionData.parent_entity
                };
            } else {
                selectedSource = {label: PROPERTY_DIMENSION.SELECT_PARENT_ENTITY, value: ""};
            }

            this.setState({
                ...this.props.dimensionData,
                selectedSource
            });
        } else {
            let selectedSource = {label: PROPERTY_DIMENSION.SELECT_PARENT_ENTITY, value: ""};
            this.setState({
                ...this.props.dimensionData,
                selectedSource
            });
        }

        if (this.props.dimensionData.tag_treatment) {
            let savedTagTreatment = {
                label: this.props.dimensionData.tag_treatment,
                value: this.props.dimensionData.tag_treatment
            };
            this.setState({
                selectedTagTreatment: savedTagTreatment
            });

        }

        if (this.props.dimensionData.dimension_type) {
            let dimensionType = {
                label: this.props.dimensionData.dimension_type,
                value: this.props.dimensionData.dimension_type
            };
            this.setState({
                selectedDimensionType: dimensionType
            });

        }

        this.setState({
            applicationMode: this.props.applicationMode,
            status: this.props.dimensionData && trimCapitalize(this.props.dimensionData.status) ? this.props.dimensionData.status : APPLICATION_MODES.INACTIVE,
            isActive: this.props.dimensionData.status ? trimCapitalize(this.props.dimensionData.status) === APPLICATION_MODES.ACTIVE : false,
            is_filter: this.props.dimensionData && trimCapitalize(this.props.dimensionData.is_filter) ? this.props.dimensionData.is_filter : APPLICATION_MODES.NO,
            isFilter: this.props.dimensionData.is_filter ? trimCapitalize(this.props.dimensionData.is_filter) === APPLICATION_MODES.YES : false,
            is_public: this.props.dimensionData && trimCapitalize(this.props.dimensionData.is_public) ? this.props.dimensionData.is_public : APPLICATION_MODES.NO,
            isPublic: this.props.dimensionData.is_public ? trimCapitalize(this.props.dimensionData.is_public) === APPLICATION_MODES.YES : false,
            is_displaytag: this.props.dimensionData && trimCapitalize(this.props.dimensionData.is_displaytag) ? this.props.dimensionData.is_displaytag : APPLICATION_MODES.NO,
            isDisplayTag: this.props.dimensionData.is_displaytag ? trimCapitalize(this.props.dimensionData.is_displaytag) === APPLICATION_MODES.YES : false,
            is_clickable: this.props.dimensionData && trimCapitalize(this.props.dimensionData.is_clickable) ? this.props.dimensionData.is_clickable : APPLICATION_MODES.NO,
            isClickable: this.props.dimensionData.is_clickable ? trimCapitalize(this.props.dimensionData.is_clickable) === APPLICATION_MODES.YES : false,
        });

    }

    componentWillReceiveProps(props) {
        const dimensionLst = [];
        dimensionLst.push({label: "", value: ""});
        if (props.dimensionList) {
            for (let data of Object.values(props.dimensionList)) {
                if (data.customer_id === PROPERTY_DIMENSION.DZERO_CUSTOMER) {
                    if (data.entity_type === "CLASSIFIER") {
                        dimensionLst.push({label: data.entity_type, value: data.entity_type});
                    }
                } else {
                    dimensionLst.push({label: data.entity_type, value: data.entity_type});
                }
            }
            dimensionLst.sort(dynamicSort("label"));
            dimensionLst[0].label = PROPERTY_DIMENSION.SELECT_PARENT_ENTITY;
            this.setState({
                sourceListsOptions: dimensionLst
            });
        }
        if (props.tagTreatments) {
            this.setTagTreatment(props);
        }
        if (!Array.isArray(props.dimensionData)) {
            let selectedSource;
            if (props.dimensionData.parent_entity) {
                selectedSource = {label: props.dimensionData.parent_entity, value: props.dimensionData.entity_type};
            } else {
                selectedSource = {label: PROPERTY_DIMENSION.SELECT_PARENT_ENTITY, value: ""};
            }

            this.setState({
                ...props.dimensionData,
                selectedSource
            });
        } else {
            let selectedSource = {label: PROPERTY_DIMENSION.SELECT_PARENT_ENTITY, value: ""};
            this.setState({
                ...props.dimensionData,
                selectedSource
            });
        }

        if (props.dimensionData.tag_treatment) {
            let savedTagTreatment = {
                label: props.dimensionData.tag_treatment,
                value: props.dimensionData.tag_treatment
            };
            this.setState({
                selectedTagTreatment: savedTagTreatment
            });
        }

        if (props.dimensionData.dimension_type) {
            let dimensionType = {label: props.dimensionData.dimension_type, value: props.dimensionData.dimension_type};
            this.setState({
                selectedDimensionType: dimensionType
            });
        }

        this.setState({
            applicationMode: props.applicationMode,
            status: props.dimensionData && trimCapitalize(props.dimensionData.status) ? props.dimensionData.status : APPLICATION_MODES.INACTIVE,
            isActive: props.dimensionData.status ? trimCapitalize(props.dimensionData.status) === APPLICATION_MODES.ACTIVE : false,
            is_filter: props.dimensionData && trimCapitalize(props.dimensionData.is_filter) ? props.dimensionData.is_filter : APPLICATION_MODES.NO,
            isFilter: props.dimensionData.is_filter ? trimCapitalize(props.dimensionData.is_filter) === APPLICATION_MODES.YES : false,
            is_public: this.props.dimensionData && trimCapitalize(this.props.dimensionData.is_public) ? this.props.dimensionData.is_public : APPLICATION_MODES.NO,
            isPublic: this.props.dimensionData.is_public ? trimCapitalize(this.props.dimensionData.is_public) === APPLICATION_MODES.YES : false,
            is_displaytag: props.dimensionData && trimCapitalize(props.dimensionData.is_displaytag) ? props.dimensionData.is_displaytag : APPLICATION_MODES.NO,
            isDisplayTag: props.dimensionData.is_displaytag ? trimCapitalize(props.dimensionData.is_displaytag) === APPLICATION_MODES.YES : false,
            is_clickable: props.dimensionData && trimCapitalize(props.dimensionData.is_clickable) ? props.dimensionData.is_clickable : APPLICATION_MODES.NO,
            isClickable: props.dimensionData.is_clickable ? trimCapitalize(props.dimensionData.is_clickable) === APPLICATION_MODES.YES : false
        });
    }


    setTagTreatment = (thisProps) => {
        const tagTreatments = Object.entries(thisProps.tagTreatments)
            .map(([key, value]) => value)
            .map(type => type.name);

        let tagTreatmentLst = [];
        tagTreatmentLst.push({label: "", value: ""});
        tagTreatments.map((data) => {
            tagTreatmentLst.push({label: data, value: data});
        });
        tagTreatmentLst.sort(dynamicSort("label"));
        tagTreatmentLst[0].label = PROPERTY_DIMENSION.SELECT_PARENT_ENTITY;
        this.setState({
            tagTreatmentListsOptions: tagTreatmentLst
        });
    };

    _handleSave = e => {
        e.preventDefault();
        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            }

            if (this.state.isDisplayTag) {
                this.state.is_clickable = this.state.isClickable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            } else {
                this.state.is_clickable = APPLICATION_MODES.NO;
            }

            if (response) {
                if (this.state.isValid && this.state.validEntityName && this.state.validSequenceNumber && this.state.validAttributeTagging) {
                    const dimension = {
                        entity_type: this.state.entity_type.toUpperCase(),
                        entity_desc: this.state.entity_desc,
                        parent_entity: this.state.selectedSource.label === PROPERTY_DIMENSION.SELECT_PARENT_ENTITY ? "" : this.state.selectedSource.label,
                        sequence_no: this.state.sequence_no === "" ? 0 : this.state.sequence_no,
                        status: this.state.status,
                        is_filter: this.state.is_filter,
                        is_public: this.state.is_public,
                        is_displaytag: this.state.is_displaytag,
                        is_clickable: this.state.is_clickable,
                        tag_treatment: this.state.selectedTagTreatment.label,
                        attribute_for_tagging: this.state.selectedTagTreatment.label === APPLICATION_MODES.BY_ATTRIBUTE ? this.state.attribute_for_tagging : "",
                        is_system: "No",
                        dimension_type: this.state.selectedDimensionType.label,
                    };

                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        let dimensionData = {
                            ...dimension,
                            customer_id: this.props.customerId,
                            created_by: this.props.userName
                        };

                        this.props.actions.createCustomerDimension(dimensionData).then((responseCustomerDimension) => {
                            this.setState({preLoader: false});
                            if (responseCustomerDimension && responseCustomerDimension.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseCustomerDimension.data && responseCustomerDimension.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseCustomerDimension.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseCustomerDimension.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseCustomerDimension.data.message,
                                });
                            }
                        });
                    } else {
                        let dimensionData = {
                            ...dimension,
                            customer_id: this.props.customerId,
                            updated_by: this.props.userName
                        };
                        this.props.actions.updateCustomerDimension(dimensionData, this.props.dimensionData._id).then((responseUpdateCustomerDimension) => {
                            this.setState({preLoader: false});
                            if (responseUpdateCustomerDimension && responseUpdateCustomerDimension.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseUpdateCustomerDimension.data && responseUpdateCustomerDimension.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseUpdateCustomerDimension.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseUpdateCustomerDimension.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseUpdateCustomerDimension.data.message,
                                    applicationMode: APPLICATION_MODES.EDIT
                                });
                            }
                        });
                    }

                }
            }
        });
    }

    _validations = () => {
        let isValidEntityName = true;
        let isValidSequenceNumber = true;
        let mandatoryFieldsValid = true;
        let isValidAttributeTagging = true;
        if (!this.state.entity_type || this.state.selectedTagTreatment.label === undefined || this.state.selectedTagTreatment.label === PROPERTY_DIMENSION.SELECT_PARENT_ENTITY || (this.state.selectedTagTreatment.label === APPLICATION_MODES.BY_ATTRIBUTE && !this.state.attribute_for_tagging ) ||  this.state.selectedDimensionType.length === 0) {
            mandatoryFieldsValid = false;
        } else if (this.state.entity_type || this.state.sequence_no) {
            isValidEntityName = validateEntityName(this.state.entity_type, /^[a-zA-Z-_ ]{2,50}$/g);
            isValidSequenceNumber = this.state.sequence_no ? validateEntityName(this.state.sequence_no, /^[0-9]{1,3}$/g) : true;

        }

        if (!mandatoryFieldsValid) {
            this.setState({
                isValid: false,
                validEntityName: isValidEntityName,
                validSequenceNumber: isValidSequenceNumber,
                validAttributeTagging: isValidAttributeTagging
            });
        } else if (mandatoryFieldsValid && !isValidEntityName) {
            this.setState({
                isValid: true,
                validEntityName: isValidEntityName,
                validSequenceNumber: true,
                validAttributeTagging: isValidAttributeTagging
            });
        } else if (mandatoryFieldsValid && !isValidSequenceNumber) {
            this.setState({
                isValid: true,
                validEntityName: true,
                validSequenceNumber: isValidSequenceNumber,
                validAttributeTagging: isValidAttributeTagging
            });
        } else {
            this.setState({
                isValid: mandatoryFieldsValid,
                validEntityName: isValidEntityName,
                validSequenceNumber: isValidSequenceNumber,
                validAttributeTagging: isValidAttributeTagging
            });
        }
        return Promise.resolve(true);
    }

    _handleInput = e => {

        if (e.target.id === "dimensionStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status: status,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else if (e.target.id === "is_filter") {
            const filterStatus = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            this.setState({
                isFilter: e.target.checked,
                is_filter: filterStatus,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else if (e.target.id === "is_public") {
            const publicStatus = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            this.setState({
                isPublic: e.target.checked,
                is_public: publicStatus,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else if (e.target.id === "is_tagging") {
            const taggingStatus = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            this.setState({
                isTagging: e.target.checked,
                is_tagging: taggingStatus,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else if (e.target.id === "is_displaytag") {
            const displayTagStatus = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            this.setState({
                isDisplayTag: e.target.checked,
                is_displaytag: displayTagStatus,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else if (e.target.id === "is_clickable") {
            const clickableStatus = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            this.setState({
                isClickable: e.target.checked,
                is_clickable: clickableStatus,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else {

            if (e.target.id === "sequence_no") {
                if (e.target.value !== "" && !Number(e.target.value)) {
                    return;
                }
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _handleSourceSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                parent_entity: true
            },
            selectedSource: selectedOption
        });
    };

    _handleTagTreatmentSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                tag_treatment: true
            },
            selectedTagTreatment: selectedOption
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    selectedSource: []

                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    _handleDimensionType = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                dimension_type: true
            },
            selectedDimensionType: selectedOption
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{PROPERTY_DIMENSION.VIEW_TITLE_DIMENSION}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{PROPERTY_DIMENSION.EDIT_DIMENSION}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12} className="align-flex-end">

                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="entity_type">{PROPERTY_DIMENSION.ENTITY_TYPE}<span
                                    style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.entity_type) ? "labelText mb-0 validate txt-no-h uppercase invalid" : "labelText mb-0 validate txt-no-h uppercase"}
                                    s={12}
                                    validate
                                    required
                                    pattern="^[a-zA-Z0-9_-]{5,25}$"
                                    name="entity_type"
                                    onChange={this._handleInput}
                                    value={this.state.entity_type}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                            <TatvamCol m={12} xl={6}>
                                <label htmlFor="parent_entity">{PROPERTY_DIMENSION.PARENT_ENTITY}</label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className="validate select-ht "
                                    value={this.state.selectedSource}
                                    name="parent_entity"
                                    id="parent_entity"
                                    onChange={this._handleSourceSelect}
                                    options={this.state.sourceListsOptions}
                                    placeholder={PROPERTY_DIMENSION.SELECT_PARENT_ENTITY}
                                />
                            </TatvamCol>
                        </TatvamCol>

                        <TatvamCol m={12} className="pt-2">
                            <TatvamCol s={3} className="no-pad-left seqInput">
                                <label htmlFor="sequence_no">{PROPERTY_DIMENSION.SEQUENCE_NO}</label>
                                <TatvamTextBox
                                    m={12}
                                    id="sequence_no"
                                    name="sequence_no"
                                    pattern="^[0-9]{1,3}$"
                                    onChange={this._handleInput}
                                    className="labelText mb-0 validate txt-no-h"
                                    value={this.state.sequence_no}
                                    autocomplete="off"
                                />
                            </TatvamCol>
                            <TatvamCol s={3} className=" switch-wrapper">
                                <Switch
                                    id="is_filter"
                                    name="is_filter"
                                    offLabel="Is Filter"
                                    checked={this.state.isFilter}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            <TatvamCol s={3} className=" switch-wrapper">
                                <Switch
                                    id="is_public"
                                    name="is_public"
                                    offLabel="Is Public"
                                    checked={this.state.isPublic}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            <TatvamCol s={3} className="switch-wrapper">
                                <Switch
                                    id="is_displaytag"
                                    name="is_displaytag"
                                    offLabel="Is Display Tag"
                                    checked={this.state.isDisplayTag}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            {this.state.isDisplayTag &&
                                <TatvamCol s={3} className="switch-wrapper">
                                    <Switch
                                        id="is_clickable"
                                        name="is_clickable"
                                        offLabel="Is Clickable"
                                        checked={this.state.isClickable}
                                        onClick={this._handleInput}
                                        s={12}
                                    />
                                </TatvamCol>
                            }
                        </TatvamCol>
                        <TatvamCol m={12}>
                            <TatvamCol m={12} xl={12} className="textarea-input">
                                <label htmlFor="entity_desc">{PROPERTY_DIMENSION.DESCRIPTION}</label>
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    className="labelText mt-0 txtarea-border mb-1"
                                    name="entity_desc"
                                    id="entity_desc"
                                    value={this.state.entity_desc}
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12}>
                            <TatvamCol s={6} className="switch-wrapper" className="hide">
                                <Switch
                                    id="is_tagging"
                                    name="is_tagging"
                                    offLabel="Is Tagging"
                                    checked={this.state.isTagging}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            <TatvamCol m={12} xl={6}>
                                <label htmlFor="tag_treatment">{PROPERTY_DIMENSION.TAG_TREATMENT}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.selectedTagTreatment.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedTagTreatment}
                                    name="tag_treatment"
                                    id="tag_treatment"
                                    onChange={this._handleTagTreatmentSelect}
                                    options={this.state.tagTreatmentListsOptions}
                                    placeholder={PROPERTY_DIMENSION.SELECT_PARENT_ENTITY}
                                />
                            </TatvamCol>
                            {(this.state.selectedTagTreatment.label !== undefined && this.state.selectedTagTreatment.label === APPLICATION_MODES.BY_ATTRIBUTE) &&
                                <TatvamCol m={12} xl={6} className="no-pad-left p-0">
                                    <label htmlFor="attributeForTagging">{PROPERTY_DIMENSION.ATTRIBUTE_FOR_TAGGING}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <TatvamTextBox
                                        m={12}
                                        required
                                        id="attribute_for_tagging"
                                        name="attribute_for_tagging"
                                        onChange={this._handleInput}
                                        className={(this.state.inValidForm && !this.state.attribute_for_tagging) ? "labelText mb-0 validate invalid" : "labelText mb-0 validate"}
                                        value={this.state.attribute_for_tagging}
                                        autocomplete="off"
                                    />
                                </TatvamCol>
                            }
                            {this.state.selectedTagTreatment.label !== APPLICATION_MODES.BY_ATTRIBUTE &&
                                <TatvamCol m={6} className="switch-wrapper">
                                    <Switch
                                        id="dimensionStatus"
                                        offLabel="Status"
                                        checked={this.state.isActive}
                                        onClick={this._handleInput}
                                        m={12}
                                    />
                                </TatvamCol>
                            }
                        </TatvamCol>
                        {(this.state.selectedTagTreatment.label !== undefined && this.state.selectedTagTreatment.label === APPLICATION_MODES.BY_ATTRIBUTE) &&
                            <TatvamCol m={12}>
                                <TatvamCol m={6} className="switch-wrapper">
                                    <Switch
                                        id="dimensionStatus"
                                        offLabel="Status"
                                        checked={this.state.isActive}
                                        onClick={this._handleInput}
                                        m={12}
                                    />
                                </TatvamCol>
                            </TatvamCol>}
                        <TatvamCol s={12} className="pt-2 formRow">
                            <TatvamCol m={6} className="mt-.75 d-flex flex-column">
                                <label htmlFor="reportType">{PROPERTY_DIMENSION.DIMENSION_TYPE}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    required
                                    validate
                                    className={(this.state.inValidForm && !this.state.selectedDimensionType) ? "labelText mb-0 validate invalid" : "labelText mb-0 validate"}
                                    value={this.state.selectedDimensionType}
                                    name="applicationName"
                                    id="applicationName"
                                    onChange={this._handleDimensionType}
                                    options={this.state.dimensionTypeListOptions}
                                    placeholder={PROPERTY_DIMENSION.SELECT_PARENT_ENTITY}
                                    isDisabled={(this.state.applicationMode === "VIEW")}
                                    autocomplete="off"
                                />
                            </TatvamCol>
                        </TatvamCol>
                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">


                            <div className="col s6 p-0">
                                <span className="property-label col s4"> {PROPERTY_DIMENSION.VIEW_ENTITY_TYPE} : </span>
                                <span className="col s8 url-wrap"> {this.state.entity_type}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span className="property-label col s4">{PROPERTY_DIMENSION.PARENT_ENTITY} :</span>
                                <span className="col s8 url-wrap">{this.state.parent_entity}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s3 p-0">
                                <span className="property-label col s8"> {PROPERTY_DIMENSION.VIEW_SEQUENCE_NO} :</span>
                                <span className="col s4">{this.state.sequence_no}</span>
                            </div>

                            <div className="col s3 p-0">
                                <span className="property-label col s8"> {PROPERTY_DIMENSION.IS_FILTER} :</span>
                                <span className="col s4">{this.state.isFilter ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                            </div>
                            <div className="col s3 p-0">
                                <span className="property-label col s8"> {PROPERTY_DIMENSION.IS_PUBLIC} :</span>
                                <span className="col s4">{this.state.isPublic ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                            </div>
                            <div className="col s3 p-0">
                                <span className="property-label col s8"> {PROPERTY_DIMENSION.IS_DISPLAY_TAG} :</span>
                                <span
                                    className="col s4">{this.state.isDisplayTag ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                            </div>
                            {this.state.isDisplayTag === true &&
                                <div className="col s2 p-0">
                                    <span
                                        className="property-label col s9 p-0"> {PROPERTY_DIMENSION.IS_CLICKABLE} :</span>
                                    <span
                                        className="col s3">{this.state.isClickable ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                </div>
                            }
                        </TatvamCol>

                        <TatvamCol m={12}>
                            <div className="col s12 p-0">
                                <span
                                    className="property-label col s2 descriptionLabel"> {PROPERTY_DIMENSION.DESCRIPTION} :</span>
                                <TatvamTextArea
                                    disabled
                                    value={this.state.entity_desc}
                                    className="mb-2"
                                    s={10}
                                />
                            </div>
                            <div className="col s4 p-0">
                                <span
                                    className="property-label col s6 descriptionLabel pl-0"> {PROPERTY_DIMENSION.VIEW_TAG_TREATMENT} :</span>
                                {/* <span className="col s7 url-wrap">  {this.state.isTagging}</span> */}
                                <span className="col s6">{this.state.tag_treatment}</span>
                            </div>
                            <div>
                                {this.state.tag_treatment === APPLICATION_MODES.BY_ATTRIBUTE &&
                                    <div className="col s8 p-0">
                                        <span
                                            className="property-label col s6"> {PROPERTY_DIMENSION.VIEW_ATTRIBUTE_FOR_TAGGING} :</span>
                                        <span className="col s6">  {this.state.attribute_for_tagging}</span>
                                    </div>
                                }
                            </div>

                            <div
                                className={this.state.tag_treatment === APPLICATION_MODES.BY_ATTRIBUTE ? "col s4 p-0" : "col s8 p-0"}>
                                <span
                                    className={this.state.tag_treatment === APPLICATION_MODES.BY_ATTRIBUTE ? "property-label col s6 descriptionLabel pl-0" : "property-label col s6"}> {PROPERTY_DIMENSION.STATUS} :</span>
                                <span
                                    className="col s6">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                            <TatvamCol className="col m12 p-0">
                                <div className="col s12 p-0">
                                    <span
                                        className="property-label col s2 pl-0">{PROPERTY_DIMENSION.DIMENSION_TYPE} :</span>
                                    <span className="col s6 url-wrap">{this.state.dimension_type}</span>
                                </div>
                            </TatvamCol>

                        </TatvamCol>
                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                    <TatvamCol s={12} m={8}>
                        {!this.state.isValid && (
                            <p className="errorText">
                                {/* {
                    VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS
                  } */}
                            </p>
                        )}
                        {!this.state.validEntityName && (
                            <p className="errorText">{
                                VALIDATION_MESSAGES.ENTITYNAME_INVALID
                            }
                            </p>
                        )}
                        {!this.state.validSequenceNumber && (
                            <p className="errorText">{
                                VALIDATION_MESSAGES.SEQUENCE_NO_INVALID
                            }
                            </p>
                        )}
                        {!this.state.validAttributeTagging && (
                            <p className="errorText">{
                                VALIDATION_MESSAGES.ATTRIBUTE_TAGGING_INVALID
                            }
                            </p>
                        )}
                        {!this.state.isValid && this.state.selectedDimensionType.length === 0 && (
                            <p className="errorText">{
                                VALIDATION_MESSAGES.SELECTED_DIMENSIONS
                            }
                            </p>
                        )}

                    </TatvamCol>
                    <TatvamCol s={12} m={4} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                            this.props.dimensionPermission === APP_BOOLEANS.TRUE && (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            )}
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-2 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default CustomerDimensionForm;
