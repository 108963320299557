import React, {Fragment} from 'react';

import {Col, Row,} from 'react-materialize';
import {Tab, TabList, TabPanel, Tabs,} from 'react-tabs';

import {ALERT_MESSAGES, APPLICATION_MODES, SOURCE_XPATH,} from '../../../constants/appConstants';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import SourceXpathForm from '../sourceXpathForm';

class SourceXpath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xPathGridData: [{name: "Loading.."}],
            xPathAttributeDetailsGridData: [],
            colDef: [
                {headerName: SOURCE_XPATH.VIEW_NAME, field: "name", filterParams: {clearButton: true}},
                {headerName: SOURCE_XPATH.STATUS, field: "status"},
                {
                    headerName: SOURCE_XPATH.MANDATE, field: "locator", cellRenderer: function (param) {
                        if (param.value === "Active") {
                            return "Yes";
                        } else {
                            return "No";
                        }

                    }
                }
            ],
            colDefAttrDetails: [
                {headerName: SOURCE_XPATH.ATTR_GRID_KEY, field: "key"},
                {headerName: SOURCE_XPATH.ATTR_GRID_VALUE, field: "value"},
            ],
            name: "",
            idSource: "",
            logoSource: "",
            nameSource: "",
            sleepTime: "",
            statusSource: "",
            urlSource: "",
            totalActive: 0,
            totalRecords: 0,
            totalAttrDetails: 0
        };
    }

    componentDidMount() {
        const sourceName = this.props.history.location.state.webDetailsData.name;
        this.props.actions.fetchAllXpaths(this.props.history.location.state.sourceId).then(() => {
            const noOfActive = this.props.xpaths.filter(eachItem => eachItem.status === 'Active');
            let detailsCount = 0;
            let keyAttrPair = [];
            if (this.props.history.location.state.webDetailsData.details && Object.keys(this.props.history.location.state.webDetailsData.details).length > 0) {
                detailsCount = Object.keys(this.props.history.location.state.webDetailsData.details).length;
                keyAttrPair = Object.keys(this.props.history.location.state.webDetailsData.details).map((key) => {
                    return {"key": key, "value": this.props.history.location.state.webDetailsData.details[key]};
                });

            }
            this.setState({
                idSource: this.props.history.location.state.webDetailsData.id,
                logoSource: this.props.history.location.state.webDetailsData.logo,
                nameSource: this.props.history.location.state.webDetailsData.name,
                sleepTime: this.props.history.location.state.webDetailsData.sleep_time,
                statusSource: this.props.history.location.state.webDetailsData.status,
                urlSource: this.props.history.location.state.webDetailsData.url,
                totalActive: noOfActive.length,
                totalRecords: this.props.xpaths.length,
                totalAttrDetails: detailsCount,
                xPathAttributeDetailsGridData: keyAttrPair
            });
            if (this.props.source && this.props.xpaths) {
                this.props.xpaths.map(function (obj) {
                    return Object.assign(obj, {source_name: sourceName});
                })
                this.setState({
                    xPathGridData: this.props.xpaths,
                    name: this.props.source.name,
                    sortColumnName: this.props.history.location.state.sortColumnName,
                    sortOrderName: this.props.history.location.state.sortOrderName,
                    returnPath: this.props.history.location.state.returnPath
                });
            } else {
                this.setState({
                    name: this.props.source.name,
                    id: this.props.source.id,
                    sortColumnName: this.props.history.location.state.sortColumnName,
                    sortOrderName: this.props.history.location.state.sortOrderName,
                    returnPath: this.props.history.location.state.returnPath
                });
            }
        });
    }

    componentWillReceiveProps(props) {
        const sourceName = props.history.location.state.webDetailsData.name;
        let detailsCount = 0;
        if (props.source && props.xpaths) {
            const noOfActive = props.xpaths.filter(eachItem => eachItem.status === 'Active');
            this.setState({
                totalActive: noOfActive.length,
                totalRecords: props.xpaths.length
            });
            props.xpaths.map(function (obj) {
                return Object.assign(obj, {source_name: sourceName});
            })

            let keyAttrPair = [];
            if (props.history.location.state.webDetailsData.details && Object.keys(props.history.location.state.webDetailsData.details).length > 0) {
                detailsCount = Object.keys(props.history.location.state.webDetailsData.details).length;
                keyAttrPair = Object.keys(props.history.location.state.webDetailsData.details).map((key) => {
                    return {"key": key, "value": props.history.location.state.webDetailsData.details[key]};
                });
            }
            this.setState({
                xPathGridData: props.xpaths,
                name: props.history.location.state.webDetailsData.name,
                sortColumnName: props.history.location.state.sortColumnName,
                sortOrderName: props.history.location.state.sortOrderName,
                returnPath: props.history.location.state.returnPath,
                totalAttrDetails: detailsCount,
                xPathAttributeDetailsGridData: keyAttrPair
            });
        } else {
            this.setState({
                name: props.history.location.state.webDetailsData.name,
                id: props.history.location.state.webDetailsData.id,
                sortColumnName: props.history.location.state.sortColumnName,
                sortOrderName: props.history.location.state.sortOrderName,
                returnPath: props.history.location.state.returnPath
            });
        }
    }

    _onEditRowClick = (data) => {
        this.setState({
            xpathData: data[0],
            xpathModal: true,
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW
        });
    };

    _openCreateModal = () => {
        this.setState({
            xpathData: [],
            xpathModal: true,
            modalTitle: "Add Xpath",
            applicationMode: APPLICATION_MODES.CREATE
        });
    };

    closeModal = () => {
        this.setState({
            xpathModal: false
        });
    };

    _goBack = () => {
        this.props.history.push({
            pathname: this.state.returnPath,
            state: {
                sortColumnName: this.state.sortColumnName,
                sortOrderName: this.state.sortOrderName
            }
        });
    };

    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idSource: data.source_id,
            name: data.name,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };

    _deleteRow = (data) => {
        if (data === "ok") {
            let source_id = this.state.idSource;
            let name = this.state.name;
            let xpathDeleteData = {
                source_id,
                name
            };
            this.props.actions.deleteOneXpath(xpathDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.props.actions.fetchAllXpaths(this.state.source_id).then(() => {

                    });
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }

            });

        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }

    render() {
        return (
            <Row className="create-project-page">
                <Row>
                    <Row>
                        <Col className="right">
                            <TatvamButton
                                waves="light"
                                onClick={this._goBack}
                                className="goBackBtn btn_primary"
                                title="go back "
                            >
                                <i className="material-icons">arrow_back</i>
                            </TatvamButton>
                        </Col>
                        <Fragment>
                            <TatvamCol className="z-depth-2 centered mt-2 form-wrapper" s={12} m={12} l={12} xl={12}>
                                <div className="page_heading mt-0 mb-1 ml-1">
                                    {<h5>{this.state.nameSource + "'s Xpath"}</h5>}
                                </div>
                                <TatvamCol s={12} m={12} l={2} className="imageWrapper">
                                    <div className="left d-flex flex-colum image-uploaded mb-2">
                                        <img
                                            className="responsive-img"
                                            src={this.state.logoSource ? this.state.logoSource :
                                                require("../../../../public/assets/images/default_customer.png")}
                                            alt="logo"
                                            title="Property Logo"
                                        />
                                    </div>
                                </TatvamCol>
                                <TatvamCol s={12} l={10} className="p-0 customerFormView">

                                    <TatvamCol s={12} m={12} class="mt-.75">
                                        <div className="mt-0 col s12 m4">
                                            <span className="property-label col s6">{SOURCE_XPATH.SOURCE_ID} : </span>
                                            <span className="col s6"> {this.state.idSource}</span>
                                        </div>
                                        <div className="mt-0 col s12 m4">
                                            <span className="property-label col s6"> {SOURCE_XPATH.STATUS} : </span>
                                            <span className="col s6"> {this.state.statusSource} </span>
                                        </div>
                                    </TatvamCol>
                                </TatvamCol>
                            </TatvamCol>
                        </Fragment>
                    </Row>
                    <Row>
                        <Col className="z-depth-2 col-centered mt-3 mb-3 p-0" s={12} m={12} l={12} xl={12}>
                            <div className="row m-0">
                                <Tabs>
                                    <TabList className="tabs customTabs z-depth-2 tabs-fixed-width">
                                        <Tab className="tab">{SOURCE_XPATH.TAB_XPATH_TITLE}<span
                                            class="activeCountSpan">({this.state.totalActive}/{this.state.totalRecords})</span></Tab>
                                        <Tab className="tab">{SOURCE_XPATH.ATTR_DETAILS}<span
                                            class="activeCountSpan">(Total {this.state.totalAttrDetails})</span></Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="">
                                            <TatvamButton className="mt-2 ml-2 mr-2 right btn_primary"
                                                          onClick={this._openCreateModal} title="Add Xpath">
                                                {SOURCE_XPATH.ADD_XPATH}
                                            </TatvamButton>
                                        </div>


                                        <Col s={12} className="roleGrid">
                                            <div className="row  z-depth-4 h-75" ref="tableTop">
                                                <TatvamGrid
                                                    gridData={this.state.xPathGridData}
                                                    colData={this.state.colDef}
                                                    enablePagination
                                                    enableRowClick
                                                    onRowSelect={this._onEditRowClick}
                                                    enableDeleteColumn={true}
                                                    onRowDelete={this._handleDeleteConfirmation}
                                                />
                                            </div>
                                        </Col>
                                        {this.state.xpathModal && (
                                            <TatvamModal
                                                open={this.state.xpathModal}
                                                header={this.state.modalTitle}
                                                className="hideFooter"
                                                options={{dismissible: false}}
                                                fixedFooter={false}
                                                children={(
                                                    <SourceXpathForm
                                                        formData={this.state.xpathData}
                                                        closeModal={this.closeModal}
                                                        applicationMode={this.state.applicationMode}
                                                        sourceId={this.props.source.id}
                                                    />
                                                )}
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel>
                                        <Col s={12} className="roleGrid">
                                            <div className="row  z-depth-4 h-75" ref="tableTop">
                                                <TatvamGrid
                                                    gridData={this.state.xPathAttributeDetailsGridData}
                                                    colData={this.state.colDefAttrDetails}
                                                    enablePagination
                                                />
                                            </div>
                                        </Col>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </Col>


                    </Row>

                    {this.state.isAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._discard}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                    {this.state.isDeleteAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isDeleteAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._deleteRow}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                </Row>
            </Row>
        );
    }
}

export default SourceXpath;
