const initialState = {
    list: [],
    error: {}
};

const CustomerReducer = (state, action) => {

    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "FETCH_CUSTOMER_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;
        case "FETCH_CUSTOMER_ERROR":
        case "SAVE_CUSTOMER_ERROR":
        case "SAVE_TENANT_ERROR" :
        case "FETCH_CUSTOMER_ROLE_ERROR":
        case "EDIT_CUSTOMER_ROLE_ERROR":
        case "CREATE_CUSTOMER_ROLE_ERROR":
        case "FETCH_CUSTOMER_USER_ERROR":
        case "EDIT_CUSTOMER_USER_ERROR":
        case "CREATE_CUSTOMER_USER_ERROR":
        case "CREATE_CUSTOMER_ERROR":
        case "FETCH_CUSTOMER_ALERT_RULE_ERROR":
        case "CREATE_CUSTOMER_ALERT_RULE_ERROR":
        case "EDIT_CUSTOMER_ALERT_RULE_ERROR":
        case "FETCH_COMPETITOR_ERROR":
        case "FETCH_TENANT_ERROR":
        case "GET_ONE_CUSTOMER_SOURCES_ERROR":
        case "GET_CUSTOMER_DIMENSION_ERROR":
        case "EDIT_CUSTOMER_SOURCES_ERROR":
        case "UPDATE_CUSTOMER_DIMENSION_ERROR":
        case "GET_CUSTOMER_DIMENSION_VALUE_ERROR":
        case "UPDATE_CUSTOMER_DIMENSION_VALUE_ERROR":
        case "CREATE_CUSTOMER_DIMENSION_ERROR":
        case "CREATE_CUSTOMER_DIMENSION_VALUE_ERROR":
        case "GET_ALL_CUSTOMER_SOURCES_ERROR":
        case "FETCH_ALL_USER_CUSTOMER_ERROR":
        case "FETCH_COMPETITOR_TENANT_CUSTOMER_ERROR":
        case "FETCH_CUSTOMER_RESPONSE_TEMPLATE_ERROR":
        case "EDIT_CUSTOMER_RESPONSE_TEMPLATE_ERROR":
        case "CREATE_CUSTOMER_RESPONSE_TEMPLATE_ERROR":
        case "FETCH_TAB_ERROR":
        case "FETCH_RESPONSE_TEMPLATE_ERROR":
        case "FETCH_REPORT_SUBSCRIPTION_ERROR":
        case "FETCH_CUSTOMER_USER_DETAILS_ERROR":
        case "CREATE_CUSTOMER_REPORT_SUBSCRIPTION_ERROR":
        case "UPDATE_CUSTOMER_REPORT_SUBSCRIPTION_ERROR":
        case "FETCH_PREDICTION_INFLUENCERS_ERROR":
        case "CREATE_CUSTOMER_INFLUENCERS_ERRORS":
        case "DELETE_CUSTOMER_INFLUENCERS_ERRORS":
        case "GET_CUSTOMER_PREDICTION_SOURCES_ERROR":
        case "CREATE_SOURCE_PREDICTO_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        case "SAVE_CUSTOMER_SUCCESS":
        case "SAVE_TENANT_SUCCESS":
        case "FETCH_CUSTOMER_ROLE_SUCCESS":
        case "EDIT_CUSTOMER_ROLE_SUCCESS":
        case "CREATE_CUSTOMER_ROLE_SUCCESS":
        case "FETCH_CUSTOMER_USER_SUCCESS":
        case "EDIT_CUSTOMER_USER_SUCCESS":
        case "CREATE_CUSTOMER_USER_SUCCESS":
        case "FETCH_CUSTOMER_ALERT_RULE_SUCCESS":
        case "CREATE_CUSTOMER_ALERT_RULE_SUCCESS":
        case "EDIT_CUSTOMER_ALERT_RULE_SUCCESS":
        case "FETCH_COMPETITOR_SUCCESS":
        case "FETCH_TENANT_SUCCESS":
        case "GET_ONE_CUSTOMER_SOURCES_SUCCESS":
        case "CREATE_CUSTOMER_SOURCES_SUCCESS":
        case "FETCH_PARENT_CUSTOMER_SUCCESS":
        case "GET_CUSTOMER_DIMENSION_SUCCESS":
        case "CREATE_CUSTOMER_DIMENSION_SUCCESS":
        case "GET_CUSTOMER_DIMENSION_VALUE_SUCCESS":
        case "CREATE_CUSTOMER_DIMENSION_VALUE_SUCCESS":
        case "FETCH_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS":
        case "EDIT_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS":
        case "CREATE_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS":
        case "FETCH_REPORT_SUBSCRIPTION_SUCCESS":
        case "FETCH_CUSTOMER_USER_DETAILS_SUCCESS":
        case "CREATE_CUSTOMER_REPORT_SUBSCRIPTION_SUCCESS":
        case "UPDATE_CUSTOMER_REPORT_SUBSCRIPTION_SUCCESS":
        case "FETCH_PREDICTION_INFLUENCERS":
        case "CREATE_CUSTOMER_INFLUENCERS_SUCCESS":
        case "DELETE_CUSTOMER_INFLUENCERS_SUCCESS":
        case "GET_CUSTOMER_PREDICTION_SOURCES_SUCCESS":
        case "CREATE_SOURCE_PREDICTO_SUCCESS":
        case "DELETE_CUSTOMER_PREDICTO_SOURCE_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: action.payload
                }
            };
            return state;
        case "EDIT_CUSTOMER_SOURCES_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: {
                        ...state.list[action.payload.id],
                        sources: [...action.payload.sources]
                    }
                }
            };
            return state;

        case "UPDATE_CUSTOMER_DIMENSION_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: {
                        ...state.list[action.payload.id],
                        dimensions: [...action.payload.dimensions]
                    }
                }
            };
            return state;

        case "UPDATE_CUSTOMER_DIMENSION_VALUE_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: {
                        ...state.list[action.payload.id],
                        dimensionValues: [...action.payload.dimensionValues]
                    }
                }
            };
            return state;
        case "FETCH_PARENT_CUSTOMER_ERROR":
            state = {
                ...state,
                error: {}
            };
            return state;
        case "GET_ALL_CUSTOMER_SOURCES_SUCCESS":
            state = {
                ...state,
                customerSources: action.payload
            };
            return state;
        case "FETCH_ALL_USER_CUSTOMER_SUCCESS":
            state = {
                ...state,
                user_customers: action.payload
            };
            return state;
        case "FETCH_COMPETITOR_TENANT_CUSTOMER_SUCCESS":
            state = {
                ...state,
                competitorTenantCustomers: action.payload
            };
            return state;
        case "FETCH_TAB_SUCCESS":
            state = {
                ...state,
                customerTabData: action.payload
            };
            return state;

        case "EDIT_CUSTOMER_INFLUENCERS_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: {
                        ...state.list[action.payload.id],
                        influencersData: [...action.payload.influencersData]
                    }
                }
            };
            return state;

        case "EDIT_SOURCE_PREDICTO_SUCCESS":
            state = {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: {
                        ...state.list[action.payload.id],
                        predictoSourceData: [...action.payload.predictoSourceData]
                    }
                }
            };
            return state;

        case "PURGE_STORE":
            return {};
        default:
            return state;

    }

};

export default CustomerReducer;

