import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    CreateCustomerDimensionValueSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    CreateCustomerDimensionValueError: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_VALUE_ERROR",
        payload
    }),
    DeleteCustomerDimensionValueSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    DeleteCustomerDimensionValueError: (payload) => ({
        type: "CREATE_CUSTOMER_DIMENSION_VALUE_ERROR",
        payload
    }),
    UpdateCustomerDimensionValueSuccess: (payload) => ({
        type: "UPDATE_CUSTOMER_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    UpdateCustomerDimensionValueError: (payload) => ({
        type: "UPDATE_CUSTOMER_DIMENSION_VALUE_ERROR",
        payload
    }),
    GetCustomerDimensionValueSuccess: (payload) => ({
        type: "GET_CUSTOMER_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    GetCustomerDimensionValueError: (payload) => ({
        type: "GET_CUSTOMER_DIMENSION_VALUE_ERROR",
        payload
    }),
};


export const createCustomerDimensionValue = (customerDimensionValue) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerDimensionValue

    const customer = store.customer.list[customerDimensionValue.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_DIMENSION_VALUE;


    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.dimensionValues) {
                    customerData["dimensionValues"] = [...customerData.dimensionValues, ...response.data.data];
                } else {
                    customerData["dimensionValues"] = [...response.data.data];
                }

                dispatch(actions.CreateCustomerDimensionValueSuccess(customerData));
                return response;
            }

            dispatch(actions.CreateCustomerDimensionValueError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateCustomerDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });

};

export const updateCustomerDimensionValue = (customerDimensionValue, id) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerDimensionValue

    const customer = store.customer.list[customerDimensionValue.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_DIMENSION_VALUE.format(id);

    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.dimensionValues.filter(data => data._id !== id);
                let updatedDimensionValueData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    dimensionValues: updatedDimensionValueData
                };
                dispatch(actions.UpdateCustomerDimensionValueSuccess(customerData));
                return response;
            }

            dispatch(actions.UpdateCustomerDimensionValueError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.UpdateCustomerDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });


};

export const getCustomerDimensionValues = (customerId) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[customerId];

    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_DIMENSION_VALUES.format(customerId);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData,
                    dimensionValues: response.data.data
                };
                dispatch(actions.GetCustomerDimensionValueSuccess(customerData));
                return response;

            }

            dispatch(actions.GetCustomerDimensionValueError(response.message));
            return response;

        })
        .catch(error => {

            dispatch(actions.GetCustomerDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteCustomerDimensionValues = (customerDimensionValue) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerDimensionValue

    const customer = store.customer.list[customerDimensionValue.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_DIMENSION_VALUE.format(customerDimensionValue.id);

    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };

                if (customerData.dimensionValues) {
                    const dimensionValuesAfterDelete = customerData.dimensionValues.filter((dimensions) => {
                        return dimensions._id !== customerDimensionValue.id;
                    });
                    customerData["dimensionValues"] = dimensionValuesAfterDelete;
                } else {
                    customerData["dimensionValues"] = [];
                }

                dispatch(actions.DeleteCustomerDimensionValueSuccess(customerData));
                return response;
            }


            dispatch(actions.DeleteCustomerDimensionValueError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteCustomerDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });
};


export const importCustomerDimensionValues = (userData) => (dispatch) => {
    let formData = new FormData();
    formData.append("dimensions", userData.currentFile);
    formData.append("customer_id", "8032");
    const url = apiUrl.BASE_URL + apiUrl.IMPORT_CUSTOMER_DIMENSION_VALUES;

    return axios
        .post(url, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;

        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};
