export const DEFAULT_PERIODICITY_NDAYS = 10;
export const DEFAULT_PERIODICITY_TYPE = 'Days';
export const DEFAULT_PERIODICITY_MONTH = 6;

export const FILTER_ALER_NAME = 'alert';
export const FILTER_CHILDE_PROPERTY_NAME = 'ChildProperty';
export const FILTER_COMMENT_TYPE_NAME = 'commentType';
export const FILTER_CLASSIFIER_NAME = 'classifier';
export const FILTER_CUSTOMER_LABEL_NAME = 'customerLabel';
export const FILTER_FLAG_NAME = 'flag';
export const FILTER_PERIODICITY_NAME = 'periodicity';
export const FILTER_RECOMMENDATION_NAME = 'recommendation';
export const FILTER_RESPONSE_TIME_NAME = 'responseTime';
export const FILTER_SUB_CLASSIFIER_NAME = 'subClassifier';
export const FILTER_SOURCE_NAME = 'source';
export const FILTER_TATVAM_RATING_NAME = 'tatvamRating';
export const FILTER_USER_RATING_NAME = 'userRating';

export const FILTER_ENTITY_ALERTS = 'ALERTS';
export const FILTER_ENTITY_CLASSIFIER = 'CLASSIFIER';
export const FILTER_ENTITY_COMMENT_TYPE = 'COMMENT_TYPE';
export const FILTER_ENTITY_FLAG = 'FLAG';
export const FILTER_ENTITY_LABEL = 'LABEL';
export const FILTER_ENTITY_PROPERTY = 'PROPERTY';
export const FILTER_ENTITY_RECOMMENDATION = 'RECOMMENDATION';
export const FILTER_ENTITY_SOURCE = 'SOURCE';
export const FILTER_ENTITY_SURVEY_SEGMENT = 'SURVEY_SEGMENT';
export const FILTER_ENTITY_TATVAM_RATING = 'TATVAM_RATING';
export const FILTER_ENTITY_USER_RATING = 'USER_RATING';
