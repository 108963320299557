import React, { Component, Fragment, } from 'react';

import { APP_BOOLEANS, APPLICATION_MODES, } from 'localConstants';
import Loader from 'react-loaders';
import { Row, Switch, } from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_STATUS,
    FORM_OPERATIONS,
    PROPERTY_USERS,
    ROLES,
    USER_PROPERTY_ROLES,
    VALIDATION_MESSAGES,
} from '../../../../constants/appConstants';
import { dynamicSort } from '../../../../utils/objectUtil';
import { TatvamButton, TatvamCol, TatvamMultiSelect, } from '../../../functionalComponents';
import AlertBox from '../../alertBox';

class UserCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleListOptions: [],
            optionsList: [],
            customersListOptions: [],
            selectedCustomer: {},
            preLoader: false,
            editFlag: { selectedCustomer: false },
            isFormValid: true,
            application_name: "",
            selectedRole: {},
            selectedDefaultReport: {},
            reportList: [],
            existsUserRoles: [],
            generateApiKeyData: {},
            editKeySecret: {},
            viewKeySecret: {},
        };
    }

    _discard = data => {
        if (data === "ok") {
            this.setState({
                roleListOptions: [],
                customersListOptions: [],
                selectedCustomer: {},
                generateApiKeyData: {},
                editKeySecret: {},
                viewKeySecret: {},
            });
            this.props.closeModal();
        }

        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST
            });
        } else {
            this.props.closeModal();
        }
    };

    _handleInput = (e) => {
        if (e.target.id === "createUserStatus" || e.target.id === "editCustomerStatus") {
            const status = e.target.checked ? "Active" : "Inactive";
            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        } else if (e.target.id === "default_property") {
            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    status: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    [e.target.name]: true
                }
            });
        }
    };

    _handleSave = (e) => {
        e.preventDefault();

        if (!this.state.selectedCustomer.value || !this.state.selectedRole.value || !this.state.selectedDefaultReport.value) {
            this.setState({ isFormValid: false });
        } else {
            this.setState({
                isFormValid: true,
                preLoader: true
            });

            let applicationName = "";
            let customerTabData;

            const selectedRoleData = this.props.customersList[this.state.selectedCustomer.value];
            if (selectedRoleData) {
                var roleData = selectedRoleData.roles.filter(data => data.name === this.state.selectedRole.value);
                if (roleData) {
                    applicationName = roleData[0].application_name;
                }
            }

            customerTabData = {
                ...this.state.CustomerData,
                user_name: this.props.currentUserName,
                roles: [this.state.selectedRole.label],
                status: this.state.status,
                customer_id: this.state.selectedCustomer.value,
                default_property: this.state.default_property === true ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                application_name: applicationName,
                landing_page_id: this.state.selectedDefaultReport.value ? this.state.selectedDefaultReport.value : "",
                landing_page_name: this.state.selectedDefaultReport.label ? this.state.selectedDefaultReport.label : ""
            };

            if (this.state.selectedRole.label === ROLES.CUSTOMER_API) {
                customerTabData = {
                    ...customerTabData,
                    key_id: this.state.editKeySecret.secretID,
                    key_secret: this.state.editKeySecret.secretKey,
                };
            }

            if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                customerTabData = {
                    ...customerTabData,
                    updated_by: this.props.userName,
                    _id: this.props.customerRoleData.role_id,
                };
                this.setState({
                    preLoader: true
                });
                this.props.actions.editCustomerUsersRole(customerTabData).then((response) => {
                    this.setState({ preLoader: false });
                    this.props.actions.fetchUserCustomer(this.props.currentUserName).then(() => {
                    });
                    if (response && response.status === 200) {
                        if (response.data && response.data.message) {
                            this.setAlertUserCustomer(response.data.message, true);
                        } else {
                            this.setAlertUserCustomer(response.message, true);
                        }
                    } else {
                        this.setAlertUserCustomer(response.data.message, false);
                    }
                });
            } else if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                const result = this.props.assignedCustomers && this.props.assignedCustomers.length > 0 ? this.props.assignedCustomers.filter(customerData => customerData.id === this.state.selectedCustomer.value && customerData.default_property === APPLICATION_MODES.YES) : [];
                customerTabData.default_property = result && result.length > 0 ? APPLICATION_MODES.YES : APPLICATION_MODES.NO
                customerTabData = {
                    ...customerTabData,
                    created_by: this.props.userName
                };
                this.props.actions.createCustomerUsers(customerTabData).then((response) => {
                    this.setState({ preLoader: false });
                    this.props.actions.fetchUserCustomer(this.props.currentUserName).then(() => {
                    });
                    if (response && response.status === 200) {
                        if (response.data && response.data.message) {
                            this.setAlertUserCustomer(response.data.message, true);
                        } else {
                            this.setAlertUserCustomer(response.message, true);
                        }
                    } else {
                        this.setAlertUserCustomer(response.data.message, false);
                    }
                });
            }
        }
    };

    _handleReportChange = optionsList => {
        this.setState({
            selectedDefaultReport: optionsList,
            editFlag: {
                selectedDefaultReport: true
            }
        });
    }

    _triggerGenerateKey = role => {
        if (this.props.currentUserName && (this.state.selectedRole.value === ROLES.CUSTOMER_API || role.value === ROLES.CUSTOMER_API)) {
            let generateUserData;
            generateUserData = {
                ...this.state.generateApiKeyData
            }
            generateUserData.customer_id = this.state.selectedCustomer.value;
            generateUserData.user_name = this.props.currentUserName;
            this.setState({ preLoader: true });
            this.props.actions.generateApiKey(generateUserData).then(response => {
                this.setState({ preLoader: false });
                if (response && response.status === 200) {
                    if (response.data) {
                        this.setState({
                            editKeySecret: {
                                secretID: response.data.data.key_id,
                                secretKey: response.data.data.key_secret,
                            }
                        })
                    }
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        alert_information: response.data && response.data.message ? response.data.message : response.message
                    });
                }
            });
        }
    };


    setAlertUserCustomer = (alertMsg, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMsg
        });
    };

    _triggerSubmit = () => {
        this.refs.submit.click();
    };

    _handleRolesChange = (optionsList) => {
        const customerRolesList = this.props.customersList[this.state.selectedCustomer.value].roles;
        this.defaultReportSelection(this.state.selectedCustomer, customerRolesList, optionsList);

        if (this.state.selectedCustomer.value) {
            this.setState({ isFormValid: true });
        }
        this._triggerGenerateKey(optionsList);
    };

    _handleAppMode = () => {
        const customerRolesList = this.props.customersList[this.state.selectedCustomer.value].roles;
        this.defaultReportSelection(this.state.selectedCustomer, customerRolesList, this.state.selectedRole)
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });

        this._triggerGenerateKey();
    };

    defaultReportName = () => {
        const reportName = this.props.allReports.find(oneReport => oneReport.id === this.props.customerRoleData.landing_page_id);
        return reportName ? reportName.name : null;
    }

    defaultReportSelection = (selectedCustomer, customerRolesList, selectedRole) => {
        let dropdownList = [];
        let assignedCustomers = [];
        let userExistsRoles = [];
        let userAllSelectedRoles = [];
        let defaultReport = {};
        let uniqueReports = [];

        if (this.props.assignedCustomers) {
            assignedCustomers = this.props.assignedCustomers.filter((oneCustomer) => {
                return oneCustomer.id === selectedCustomer.value && oneCustomer.application_name === selectedRole.application_name
            })

            assignedCustomers.map((ele) => {
                userExistsRoles.push(ele.roles[0]);
            })
        }

        if (this.state.applicationMode === "CREATE") {
            if (userExistsRoles.includes(selectedRole.label)) {
                userAllSelectedRoles = userExistsRoles;
            } else {
                userExistsRoles.push(selectedRole.label)
                userAllSelectedRoles = userExistsRoles;
            }
            if (userAllSelectedRoles.length > 0) {
                customerRolesList.map((role) => {
                    if (userAllSelectedRoles.includes(role.name)) {
                        role.enabled_reports.map((ele) => {
                            dropdownList.push({ label: ele.name, value: ele.id });
                        })
                    }
                })
            }

            uniqueReports = this.getUniqueReports(dropdownList);
           
            if (selectedCustomer.inapp_default_landing_page.length > 0 || selectedCustomer.predicto_default_landing_page.length > 0 || uniqueReports.length > 0) {
                defaultReport = uniqueReports.find((report) => report.label === selectedCustomer.inapp_default_landing_page ||
                    report.label === selectedCustomer.predicto_default_landing_page
                );
            };

            if (assignedCustomers.length > 0 && assignedCustomers[0].landing_page_id) {
                const selectedReport = uniqueReports.find((ele) => ele.value === assignedCustomers[0].landing_page_id);
                defaultReport = selectedReport ? selectedReport : "";
            }
        }

        if (this.state.applicationMode === 'EDIT' || this.state.applicationMode === 'VIEW') {
            const roleIndex = userExistsRoles.indexOf(this.props.customerRoleData.roles[0]);

            if (roleIndex >= 0) {
                userExistsRoles[roleIndex] = selectedRole.label;
            } else {
                userExistsRoles.push(selectedRole.label);
            }
            userExistsRoles[roleIndex] = selectedRole.label;
            userAllSelectedRoles = userExistsRoles;

            if (userAllSelectedRoles.length > 0) {
                customerRolesList.map((role) => {
                    if (userAllSelectedRoles.includes(role.name)) {
                        role.enabled_reports.map((ele) => {
                            dropdownList.push({ label: ele.name, value: ele.id });
                        })
                    }
                })
            }

            uniqueReports = this.getUniqueReports(dropdownList);

            const isDefaultReportExist = uniqueReports.find(oneReport => assignedCustomers[0].landing_page_id === oneReport.value);
            defaultReport = isDefaultReportExist ? isDefaultReportExist : "";
        }

        this.setState({
            reportList: uniqueReports,
            selectedDefaultReport: defaultReport ? defaultReport : "",
            selectedRole: selectedRole,
            editFlag: {
                selectedRole: true
            }
        });
    }

    _handleCustomerChange = (optionsList) => {
        if (this.state.applicationMode === APPLICATION_MODES.CREATE && this.props.customersList) {
            let roleListOptions = [];
            this.setState({
                roleListOptions: [],
                selectedDefaultReport: {},
                reportList: [],
                selectedRole: {}
            });

            this.props.actions.fetchCustomerRoles(optionsList.value).then(() => {

                if (this.props.customersList[optionsList.value].roles && this.props.customersList[optionsList.value].roles.length) {
                    var rawCustomerList;
                    if (this.props.customersList[optionsList.value].roles[0].hasOwnProperty("roles")) {
                        rawCustomerList = this.props.customersList[optionsList.value].roles[0].roles;
                    } else {
                        rawCustomerList = this.props.customersList[optionsList.value].roles;
                    }
                    rawCustomerList.map((data) => {
                        if (data.status === APP_STATUS.ACTIVE) {
                            roleListOptions = [...roleListOptions, {
                                label: data.name,
                                value: data.name,
                                application_name: data.application_name
                            }];
                        }

                        this.setState({
                            preLoader: false
                        });
                    });

                    this.setState({
                        preLoader: false
                    });

                    this.setState({
                        roleListOptions,
                        selectedCustomer: optionsList,
                        editFlag: {
                            selectedCustomer: true
                        }
                    });
                } else {
                    this.setState({
                        selectedCustomer: optionsList,
                        editFlag: {
                            selectedCustomer: true
                        }
                    });
                }
            });
        }
    };

    getUniqueReports = dropdownList => {
        let uniqueReports = dropdownList.reduce((unique, o) => {
            if (!unique.some(obj => obj.label === o.label && obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);

        return uniqueReports.sort(dynamicSort("label"));
    }

    componentDidMount() {
        let customersListOptions = [];
        let customersList = [];
        let selectedCustomer = {};
        let selectedRole = {};
        let roleListOptions = [];
        let application_name = "";
        if (this.props.customersList) {
            for (let data of Object.values(this.props.customersList)) {
                if (data.category !== "" && data.category !== null) {
                    if (data.category !== "Competitor" && data.category !== "Tenants") {
                        if (data.name && data.id && data.status !== APPLICATION_MODES.INACTIVE) {
                            customersList = [...customersList, {
                                label: (data.id + " - " + data.name),
                                value: data.id,
                                inapp_default_landing_page: data.inapp_default_landing_page,
                                predicto_default_landing_page: data.predicto_default_landing_page,
                            }];
                        }
                    }
                }
            }
            customersListOptions = customersList;
            this.setState({
                customersListOptions
            });
        }
        this.props.actions.fetchAllReports();

        if (this.props.applicationMode !== APPLICATION_MODES.CREATE) {
            this.props.actions.fetchCustomerRoles(this.props.customerData.id).then(() => {
                if (this.props.customerData) {
                    if (this.props.customerData.roles) {
                        this.props.customerData.roles.map((data) => {
                            if (data.status && data.status === APP_STATUS.ACTIVE)
                                roleListOptions = [...roleListOptions, {
                                    label: data.name,
                                    value: data.name,
                                    application_name: data.application_name
                                }];
                        });
                        if (this.props.customerRoleData) {
                            selectedRole = {
                                label: this.props.customerRoleData.roles[0],
                                value: this.props.customerRoleData.roles[0],
                                application_name: this.props.customerRoleData.application_name
                            };
                            selectedCustomer = {
                                label: this.props.customerRoleData.name,
                                value: this.props.customerRoleData.id
                            };

                            application_name = this.props.customerRoleData.application_name;

                            if (this.props.customerRoleData.roles.includes(ROLES.CUSTOMER_API)) {
                                this.setState({
                                    viewKeySecret: {
                                        secretID: this.props.customerRoleData.key_id,
                                        secretKey: this.props.customerRoleData.key_secret,
                                    }
                                });
                            }
                        }
                    }
                    customersListOptions.sort(dynamicSort("label"));

                    this.setState({
                        ...this.props.customerData,
                        status: this.props.customerRoleData.status,
                        roleListOptions,
                        selectedCustomer,
                        application_name,
                        customersListOptions,
                        selectedRole,
                        applicationMode: this.props.title === "Assign Role" ? APPLICATION_MODES.CREATE : APPLICATION_MODES.VIEW,
                        isActive: this.props.customerRoleData.status === APPLICATION_MODES.ACTIVE,
                        default_property: this.props.customerRoleData && this.props.customerRoleData.default_property !== undefined ? this.props.customerRoleData.default_property === APPLICATION_MODES.YES : false
                    });
                }
            });
        } else {
            customersListOptions.sort(dynamicSort("label"));
            this.setState({
                applicationMode: this.props.applicationMode,
                customersListOptions,
                selectedCustomer,
                roleListOptions,
                isActive: true,
                status: APPLICATION_MODES.ACTIVE
            });
        }
    }

    componentWillReceiveProps(props) {
        let customersListOptions = [];
        let selectedCustomer = {};
        let roleListOptions = [];
        let selectedRole = {};
        let customersList = [];
        let application_name = "";

        if (props.customersList) {
            for (let data of Object.values(this.props.customersList)) {
                if (data.category !== "" && data.category !== null) {
                    if (data.category !== "Competitor" && data.category !== "Tenants") {
                        if (data.status && data.status !== APP_STATUS.INACTIVE)
                            if (data.name && data.id)
                                customersList = [...customersList, {
                                    label: (data.id + " - " + data.name),
                                    value: data.id,
                                    inapp_default_landing_page: data.inapp_default_landing_page,
                                    predicto_default_landing_page: data.predicto_default_landing_page,
                                }];
                    }
                }
            }
            if (props.assignedCustomers) {
                customersListOptions = customersList.filter(val => !props.assignedCustomers.map((data) => {
                    return data.id;
                }).includes(val.id));
            } else {
                customersListOptions = customersList;
            }
            customersListOptions.sort(dynamicSort("label"));
            this.setState({
                customersListOptions
            });
        }
        this.props.actions.fetchAllReports();
        if (this.props.applicationMode !== APPLICATION_MODES.CREATE) {
            if (props.customerData) {
                if (props.customerData.users && props.customerData.roles) {
                    props.customerData.roles.map((data) => {
                        if (data.status && data.status !== APP_STATUS.INACTIVE)
                            roleListOptions = [...roleListOptions, {
                                label: data.name,
                                value: data.name,
                                application_name: data.application_name
                            }];
                    });
                    if (props.customerRoleData) {
                        selectedCustomer = { label: props.customerData.name, value: props.customerData.id };
                        selectedRole = {
                            label: props.customerRoleData.roles[0],
                            value: props.customerRoleData.roles[0],
                            application_name: props.customerRoleData.application_name
                        };
                        application_name = props.customerData.application_name;
                    }
                }
                this.setState({
                    ...props.customerData,
                    selectedCustomer,
                    application_name,
                    status: props.customerRoleData.status,
                    roleListOptions,
                    selectedRole,
                    applicationMode: props.applicationMode === APPLICATION_MODES.CREATE ? APPLICATION_MODES.CREATE : APPLICATION_MODES.VIEW,
                    isActive: props.customerRoleData.status === APPLICATION_MODES.ACTIVE,
                    default_property: props.customerRoleData && props.customerRoleData.default_property !== undefined ? props.customerRoleData.default_property === APPLICATION_MODES.YES : false
                });
            }
        } else {
            this.setState({
                applicationMode: props.applicationMode,
                status: "Active",
                selectedCustomer,
                isActive: true
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat" />}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{USER_PROPERTY_ROLES.VIEW_TITLE_ROLE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{USER_PROPERTY_ROLES.EDIT_ROLE}</h4>) : ""}</Row>

                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <form onSubmit={this._handleSave}>
                        <Row>
                            <TatvamCol s={12} className="valign-wrapper">

                                <TatvamCol m={6} className="mt-.75 pb-3 d-flex flex-column">
                                    <label htmlFor="operation" className="col s12 ">
                                        {USER_PROPERTY_ROLES.PROPERTY}<span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        className={(!this.state.isFormValid && !this.state.selectedCustomer.value) ? "validate invalid-field" : "validate"}
                                        value={this.state.selectedCustomer}
                                        name="Customer"
                                        id="Customer"
                                        onChange={this._handleCustomerChange}
                                        options={this.state.customersListOptions}
                                        isDisabled={this.state.applicationMode === "EDIT"}
                                        placeholder={USER_PROPERTY_ROLES.SELECT_PROPERTY}
                                    />
                                </TatvamCol>

                                <TatvamCol m={6} className="mt-.75 pb-3 d-flex flex-column">
                                    <label htmlFor="operation" className="col s12 ">
                                        {USER_PROPERTY_ROLES.ROLE}<span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        className={(!this.state.isFormValid && !this.state.selectedRole.value && this.state.selectedCustomer.value) ? "validate invalid-field" : "validate"}
                                        value={this.state.selectedRole}
                                        name="role"
                                        onChange={this._handleRolesChange}
                                        options={this.state.roleListOptions}
                                        isDisabled={!this.state.roleListOptions.length}
                                        placeholder={USER_PROPERTY_ROLES.SELECT_ROLE}
                                    />
                                </TatvamCol>
                                <div className="col s4 valign-wrapper">
                                    <label htmlFor="status"
                                        className="status-label"> {USER_PROPERTY_ROLES.STATUS} </label>
                                    <Switch
                                        required
                                        id={this.props.title === "Assign Role" ? "createUserStatus" : "editCustomerStatus"}
                                        checked={this.state.isActive}
                                        onChange={this._handleInput}
                                        m={3}
                                    />
                                </div>
                            </TatvamCol>
                            <TatvamCol s={12} className="valign-wrapper p-0">
                                <TatvamCol m={6} className="mt-.75 pb-3 ml-0 d-flex flex-column">
                                    <label htmlFor="defaultReport" className="col s12 ">
                                        {PROPERTY_USERS.DEFAULT_REPORT}
                                        <span
                                            style={{ color: 'red' }}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        className={(!this.state.isFormValid && !this.state.selectedDefaultReport.value) ? "validate invalid-field" : "validate"}
                                        classNamePrefix="react-select"
                                        value={this.state.selectedDefaultReport}
                                        name="defaultReport"
                                        onChange={this._handleReportChange}
                                        options={this.state.reportList}
                                        placeholder={PROPERTY_USERS.SELECT_REPORT}
                                    />
                                </TatvamCol>
                            </TatvamCol>
                            <TatvamCol s={12} className="valign-wrapper p-0">
                                {
                                    (this.state.editKeySecret.secretKey && this.state.editKeySecret.secretID && this.state.selectedRole.value === ROLES.CUSTOMER_API) &&
                                    <TatvamCol s={12} m={12} className="mt-.75 h-15v pt-2">
                                        <div className="col s12">
                                            <span className="property-label col">{PROPERTY_USERS.KEY_ID}:</span>
                                            <span
                                                className="col s9 url-wrap">{this.state.editKeySecret.secretID} </span>
                                        </div>
                                        <div className="col s12">
                                            <span className="property-label col">{PROPERTY_USERS.KEY_SECRET}:</span>
                                            <span
                                                className="col s8 url-wrap"> {this.state.editKeySecret.secretKey} </span>
                                        </div>
                                    </TatvamCol>
                                }
                            </TatvamCol>
                        </Row>
                        <button type="submit" ref="submit" className="hide">Submit</button>
                    </form>
                ) : (
                    <Fragment>
                        <Row className="mt-.75">
                            <TatvamCol s={12} m={12}>
                                <p className="view-label col s12 m4">
                                    <span
                                        className="property-label col s5"> {USER_PROPERTY_ROLES.VIEW_PROPERTY} : </span>
                                    <span
                                        className="col s7"> {this.state.selectedCustomer && this.state.selectedCustomer.label}
                                    </span>
                                </p>

                                <p className="view-label col s12 m4">
                                    <span className="property-label col s5"> {USER_PROPERTY_ROLES.VIEW_ROLE} : </span>
                                    <span className="col s7"> {this.state.selectedRole.label}</span>
                                </p>

                                <p className="view-label col s12 m4">
                                    <span className="property-label col s5"> {USER_PROPERTY_ROLES.STATUS} : </span>
                                    <span className="col s7">{this.state.status}</span>
                                </p>
                            </TatvamCol>
                            <TatvamCol s={12} m={12} className="d-flex">
                                <p className="col-6 s12 m6">
                                    <span
                                        className="property-label col"> {USER_PROPERTY_ROLES.DEFAULT_PROPERTY} : </span>
                                    <span
                                        className="s7">{this.state.default_property ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                                </p>
                                <p className="col-6 s12" style={{ "margin-left": "11rem" }}>
                                    <span className="property-label col">  {PROPERTY_USERS.DEFAULT_REPORT} :</span>
                                    <span className="s7"> {this.defaultReportName()} </span>
                                </p>
                            </TatvamCol>
                            {
                                (this.state.viewKeySecret.secretID && this.state.viewKeySecret.secretKey) &&
                                <TatvamCol s={12} m={12} className="mt-.75 h-15v">
                                    <div className="col s12 m12 pb-2">
                                        <span className="property-label col">{PROPERTY_USERS.KEY_ID} :</span>
                                        <span className="col s9 url-wrap"> {this.state.viewKeySecret.secretID} </span>
                                    </div>
                                    <div className="col s12 m12 pb-2">
                                        <span className="property-label col">{PROPERTY_USERS.KEY_SECRET} :</span>
                                        <span className="col s8 url-wrap"> {this.state.viewKeySecret.secretKey} </span>
                                    </div>
                                </TatvamCol>
                            }
                        </Row>

                    </Fragment>
                )}
                <p className="errorText col s12">
                    {!this.state.isFormValid && VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS}
                </p>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <TatvamButton
                        waves="light"
                        className="mt-1 CreateProjectPublish btn_primary "
                        onClick={this._handleSave}
                        disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                    >
                        {FORM_OPERATIONS.SAVE}
                    </TatvamButton>
                ) :
                    this.props.userPermission === APP_BOOLEANS.TRUE && (
                        <TatvamButton
                            waves="light"
                            className="mt-1 CreateProjectPublish btn_primary "
                            name="EDIT"
                            onClick={this._handleAppMode}
                        >
                            {FORM_OPERATIONS.EDIT}
                        </TatvamButton>
                    )}

                <TatvamButton
                    waves="light"
                    className="mt-1 mr-1  CreateProjectPublish btn_primary"
                    onClick={this._triggerDiscard}
                >
                    {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                        FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                </TatvamButton>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default UserCustomerForm;
