import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteOneXpath, fetchAllXpaths,} from "../../../actions";
import SourceXpaths from "./sourceXpath";

const mapStateToProps = (state, ownProps) => ({
    source: state.sources.list[ownProps.location.state.sourceId],
    xpaths: state.sources.list[ownProps.location.state.sourceId] ? state.sources.list[ownProps.location.state.sourceId].xpaths : [],
    customSortColumn: state.customSortColumn,
    customerSortOrder: state.customerSortOrder
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllXpaths,
            deleteOneXpath,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceXpaths);
