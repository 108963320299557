import React, {Component, Fragment,} from 'react';

import {trimCapitalize} from 'stringUtil';

import {ALERT_MESSAGES, PROPERTY_RULES,} from '../../../../../constants/appConstants';
import {APP_BOOLEANS, APPLICATION_MODES,} from '../../../../../constants/localConstants';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import CustomerRuleForm from '../../../customerRulesForm';

class Rules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ruleModal: false,
            rulesGridData: [],
            applicationMode: APPLICATION_MODES.VIEW,
            loader: true,
            colDef: [
                {
                    headerName: PROPERTY_RULES.VIEW_SEQ_NO, field: "seq_number",
                    filter: "agNumberColumnFilter",
                    type: "numericColumn", cellRenderer: function (param) {
                        return Number(param.value);
                    },
                    sortingOrder: ["asc", "desc", "none"], filterParams: {clearButton: true}
                },
                {headerName: PROPERTY_RULES.VIEW_ALERT_NAME, field: "alert_name", filterParams: {clearButton: true}},
                {
                    headerName: PROPERTY_RULES.VIEW_ALERT_RULE_TYPE,
                    field: "alert_type",
                    filterParams: {clearButton: true}
                },
                {headerName: PROPERTY_RULES.FIELD_PATTERN, field: "fld_pattern", filterParams: {clearButton: true}},
                {headerName: PROPERTY_RULES.TEXT_PATTERN, field: "text_pattern", filterParams: {clearButton: true}},
                {
                    headerName: PROPERTY_RULES.VIEW_RECIPIENTS,
                    field: "mail_recipients",
                    filterParams: {clearButton: true}
                },
                {headerName: PROPERTY_RULES.STATUS, field: "status", filterParams: {clearButton: true}},
            ]
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.customer_id,
            alertName: data.alert_name,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let alert_name = this.state.alertName;
            let ruleDeleteData = {
                customer_id,
                alert_name

            };
            this.props.actions.deleteCustomerAlertRules(ruleDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };


    closeModal = () => {
        this.setState({
            ruleModal: false
        });
    };
    _openViewModal = (data) => {
        this.setState({
            modalTitle: "",
            alertRuleData: data[0],
            applicationMode: APPLICATION_MODES.VIEW,
            ruleModal: true
        });
    };
    _openCreateModal = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.CREATE,
            alertRuleData: [],
            modalTitle: PROPERTY_RULES.ADD_RULE,
            ruleModal: true
        });
    };

    componentDidMount() {
        let rulesGridData = [];
        if (this.props.currentCustomerId) {
            this.setState({
                loader: true
            });
            this.props.actions.fetchCustomerAlertRules(this.props.currentCustomerId).then(() => {
                this.setState({
                    loader: false
                });
            });
        }

        if (this.props.gridData) {
            this.props.gridData.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                        rulesGridData.push(data);
                }
            });
        }
        this.setState({
            rulesGridData
        }, () => {

            this.setActiveCount();
        });
    }

    setActiveCount = () => {
        const gridData = this.state.rulesGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'ruleSource': {'active': noOfActive.length, 'total': gridData.length}});
    };

    componentWillReceiveProps(props) {
        let rulesGridData = [];
        if (props.gridData) {
            props.gridData.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                        rulesGridData.push(data);
                }
            });
        }
        this.setState({
            rulesGridData
        }, () => {

            this.setActiveCount();
        });
    }

    render() {
        return (
            <Fragment>
                {this.props.rulePermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton className="btn_primary right mt-1 mr-2" onClick={this._openCreateModal}>
                        {PROPERTY_RULES.ADD_RULE}
                    </TatvamButton>
                )}
                {this.state.ruleModal && (
                    <TatvamModal
                        open={this.state.ruleModal}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <CustomerRuleForm
                                applicationMode={this.state.applicationMode}
                                customerID={this.props.currentCustomerId}
                                closeModal={this.closeModal}
                                alertRuleData={this.state.alertRuleData}
                            />
                        )}
                    />
                )
                }

                {this.state.rulesGridData.length ? (
                    <TatvamCol s={12} className="grid-data-area">
                        <TatvamGrid
                            gridData={this.state.rulesGridData}
                            colData={this.state.colDef}
                            rowSelection="multiple"
                            isExportCsv={this.state.isExportCSV}
                            onMultiRowSelect={this._onMultiRowSelect}
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.ruleDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}

                        />
                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            No Rules available
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Rules;
