import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    deleteCustomerUser,
    fetchAllUsers,
    fetchCustomerRoles,
    fetchCustomerUser,
    sendUserMail
} from "../../../../../actions/index";
import Users from "./users";

const mapStateToProps = (state, ownProps) => ({
    rolesList: state.customer.list[ownProps.currentCustomerId].roles,
    customerUsers: state.customer.list[ownProps.currentCustomerId] && state.customer.list[ownProps.currentCustomerId].users,
    userPermission: state.auth.permissions && state.auth.permissions.customer.role_users.create,
    userList: state.users.list,
    userDelete: state.auth.permissions && state.auth.permissions.customer.role_users.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerUser,
            deleteCustomerUser,
            fetchCustomerRoles,
            fetchAllUsers,
            sendUserMail
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);

