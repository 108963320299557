/* eslint-disable import/prefer-default-export */
import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    EditCustomerSourcesSuccess: (payload) => ({
        type: "EDIT_CUSTOMER_SOURCES_SUCCESS",
        payload
    }),
    EditCustomerSourcesError: (payload) => ({
        type: "EDIT_CUSTOMER_SOURCES_ERROR",
        payload
    }),
    CreateCustomerSourcesSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_SOURCES_SUCCESS",
        payload
    }),
    CreateCustomerSourcesError: (payload) => ({
        type: "CREATE_CUSTOMER_SOURCES_ERROR",
        payload
    }),
    DeleteCustomerSourcesSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_SOURCES_SUCCESS",
        payload
    }),
    DeleteCustomerSourcesError: (payload) => ({
        type: "CREATE_CUSTOMER_SOURCES_ERROR",
        payload
    }),
    GetOneCustomerSourcesSuccess: (payload) => ({
        type: "GET_ONE_CUSTOMER_SOURCES_SUCCESS",
        payload
    }),
    GetOneCustomerSourcesError: (payload) => ({
        type: "GET_ONE_CUSTOMER_SOURCES_ERROR",
        payload
    }),
    GetAllSourcesSuccess: (payload) => ({
        type: "FETCH_ALL_SOURCES_SUCCESS",
        payload
    }),
    GetAllSourcesError: (payload) => ({
        type: "FETCH_ALL_SOURCES_ERROR",
        payload
    }),
    GetSourceCategorySuccess: (payload) => ({
        type: "GET_SOURCE_CATEGORY_SUCCESS",
        payload
    }),
    GetSourceCategoryError: (payload) => ({
        type: "GET_SOURCE_CATEGORY_ERROR",
        payload
    }),
    GetAllCustomerSourcesSuccess: (payload) => ({
        type: "GET_ALL_CUSTOMER_SOURCES_SUCCESS",
        payload
    }),
    GetAllCustomerSourcesError: (payload) => ({
        type: "GET_ALL_CUSTOMER_SOURCES_ERROR",
        payload
    })
};

export const updateCustomerSource = (source, id) => (dispatch, getState) => {
    const state = getState();
    let customer;
    let payload;
    let customerId;

    if (Array.isArray(source)) {
        customerId = source[0].customer_id;

        if (source.length === 1) {
            payload = source[0];
        } else {
            payload = source;
        }
    } else {
        customerId = source.customer_id;
        payload = {
            ...source
        };
    }
    customer = state.customer.list[customerId];

    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_SOURCES.format(customerId, id);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                let sourceIndex = "";
                customerData.sources.map((data, index) => {
                    if (data._id === response.data.data[0]._id)
                        sourceIndex = index;
                });
                if (sourceIndex > -1) {
                    customerData.sources[sourceIndex] = {
                        ...response.data.data[0]
                    };
                }
                dispatch(actions.EditCustomerSourcesSuccess(customerData));
                return response;
            }

            dispatch(actions.EditCustomerSourcesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditCustomerSourcesError(error.message));
            return Promise.resolve(error);
        });
};

export const createCustomerSource = (source) => (dispatch, getState) => {
    const store = getState();
    let payload;
    let customerId;
    if (Array.isArray(source)) {
        customerId = source[0].customer_id;
        payload = source;
    } else {
        customerId = source.customer_id;
        payload = {
            ...source
        };
    }
    const customer = store.customer.list[customerId];
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_SOURCES.format(customerId);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };
                if (customerData.sources) {
                    customerData["sources"] = [...customerData.sources, ...response.data.data];
                } else {
                    customerData["sources"] = [...response.data.data];
                }
                dispatch(actions.CreateCustomerSourcesSuccess(customerData));
                return response;
            }

            dispatch(actions.CreateCustomerSourcesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateCustomerSourcesError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerSources = (id) => (dispatch, getState) => {

    const state = getState();
    if (id) {
        if (state.customer.list[id]) {
            if (state.customer.list[id].sources) {
                return Promise.resolve(true);
            }
        }
    }
    const url = apiUrl.BASE_URL + apiUrl.GET_ONE_CUSTOMER_SOURCES.format(id);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200 && response.data.data) {
                let customerData = state.customer.list[id];
                customerData = {
                    ...customerData,
                    sources: response.data.data
                };
                dispatch(actions.GetOneCustomerSourcesSuccess(customerData));
                return response;

            }

            dispatch(actions.GetOneCustomerSourcesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetOneCustomerSourcesError(error.message));
            return Promise.resolve(error);
        });
};
export const deleteCustomerSource = (sourceDeleteData) => (dispatch, getState) => {
    const store = getState();
    let payload;
    let customerId;

    customerId = sourceDeleteData.customer_id;
    payload = {
        ...sourceDeleteData
    };

    const customer = store.customer.list[customerId];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_ONE_SOURCE.format(sourceDeleteData.customer_id, sourceDeleteData.id);

    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                customerData = {
                    ...customerData
                };
                if (customerData.sources) {
                    const sourcesAfterDelete = customerData.sources.filter((webSources) => {
                        return webSources._id !== sourceDeleteData.id;
                    });
                    customerData["sources"] = sourcesAfterDelete;
                } else {
                    customerData["sources"] = [];
                }
                dispatch(actions.DeleteCustomerSourcesSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerSourcesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteCustomerSourcesError(error.message));
            return Promise.resolve(error);
        });
};


export const fetchAllCustomerSources = () => (dispatch, getState) => {

    const state = getState();

    let customer = state.customer.customerSources;

    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_CUSTOMER;

    return axios
        .get(url)
        .then(response => {
            let data = [];
            if (response.status === 200) {
                data = response.data.data;
            }

            customer = {
                ...customer,
                customerSources: data
            };
            dispatch(actions.GetAllCustomerSourcesSuccess(customer));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetAllCustomerSourcesError(error.message));
            return Promise.resolve(error.response);
        });
};



