import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';

import {ALERT_MESSAGES, API_SOURCE,} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import ApiSourceForm from '../../../apiSourceForm';
import moment from 'moment';

class ApiSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            sourceGridData: [],
            loader: true,
            colDef: [
                {headerName: API_SOURCE.NAME, field: "unique_name", filterParams: {clearButton: true}},
                {headerName: API_SOURCE.COMMENT_TYPE, field: "comment_type", filterParams: {clearButton: true}},
                {headerName: API_SOURCE.VIEW_IS_PUBLIC, field: "is_public", filterParams: {clearButton: true}},
                {
                    headerName: API_SOURCE.VIEW_EXECUTION_DATE,
                    field: "execution_date",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: API_SOURCE.MESSAGE_DATE,
                    field: "message_date",
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        return !data.value? " " : moment(data.value).format('DD-MMM-YYYY')
                    }
                },
                {headerName: API_SOURCE.STATUS, field: "status", filterParams: {clearButton: true}},
                {headerName: API_SOURCE.IS_LIVE_FEED, field: "is_live_feed", filterParams: {clearButton: true}},
                {
                    headerName: API_SOURCE.CREDENTIAL_REQUIRED,
                    field: "is_loginrequired",
                    filterParams: {clearButton: true}
                },
            ]
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.customer_id,
            idSource: data.source_id,
            idUnique: data._id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.idUnique;
            let sourceDeleteData = {
                customer_id,
                id
            };
            this.props.actions.deleteCustomerSource(sourceDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {

                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });

        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _openViewModal = (data) => {
        this.setState({
            sourceData: data,
            title: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true
        });
    };
    _openCreateModal = () => {
        this.setState({
            sourceData: [],
            title: "Add API Source",
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        this.setState({
            loader: true
        });
        let sourceGridData = [];
        this.props.actions.fetchCustomerSources(this.props.currentCustomerId).then(() => {
            this.setState({
                loader: false
            });

            if (this.props.customerSource) {
                this.props.customerSource.map((data) => {
                    if (data.source_type && data.source_type === API_SOURCE.API_SOURCE_TYPE) {

                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            if (data.last_execution && data.last_execution.execution_date !== "") {
                                let execution_date = new Date(data.last_execution.execution_date).toDateString().replace('-', '').split(" ");
                                data.execution_date = execution_date[2] + "-" + execution_date[1] + "-" + execution_date[3];
                            }
                            data.message_date = data.last_execution.message_date;
                            data.is_live_feed = data.is_live_feed === undefined ? "No" : data.is_live_feed;
                            sourceGridData.push(data);
                        }
                    }
                });
                this.setState({
                    sourceGridData
                }, () => {
                    this.countActive();
                });
            } else {
                this.setState({
                    sourceGridData
                }, () => {
                    this.countActive();
                });
            }

        });


    }

    componentWillReceiveProps(props) {
        let sourceGridData = [];
        if (props.customerSource) {
            props.customerSource.map((data) => {
                if (data.source_type && data.source_type === API_SOURCE.API_SOURCE_TYPE) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        if (data.last_execution && data.last_execution.execution_date !== "") {
                            let execution_date = new Date(data.last_execution.execution_date).toDateString().replace('-', '').split(" ");
                            data.execution_date = execution_date[2] + "-" + execution_date[1] + "-" + execution_date[3];
                        }
                        data.message_date = data.last_execution.message_date;
                        data.is_live_feed = data.is_live_feed === undefined ? "No" : data.is_live_feed;
                        sourceGridData.push(data);
                    }
                }
            });
        }
        this.setState({
            sourceGridData
        }, () => {
            const gridData = this.state.sourceGridData;
            const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
            this.props.countPropagate({'apiSource': {'active': noOfActive.length, 'total': gridData.length}});
        });
    }

    countActive = () => {
        const gridData = this.state.sourceGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'apiSource': {'active': noOfActive.length, 'total': gridData.length}});

    };

    render() {
        return (
            <Fragment>
                {this.props.sourcePermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton className="btn_primary right mt-1 mr-2" onClick={this._openCreateModal}>
                        {API_SOURCE.ADD_SOURCE}
                    </TatvamButton>
                )}

                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.title}
                        options={{dismissible: false}}
                        fixedFooter={false}
                        className="hideFooter"
                        children={(
                            <ApiSourceForm
                                sourceData={this.state.sourceData}
                                customerId={this.props.currentCustomerId}
                                applicationMode={this.state.applicationMode}
                                customerData={this.props.customer}
                                closeModal={this.closeModal}
                            />
                        )}
                    />
                )}

                {this.state.sourceGridData.length ? (
                        <TatvamCol s={12} className="grid-data-area">
                            <TatvamGrid
                                gridData={this.state.sourceGridData}
                                colData={this.state.colDef}
                                onRowSelect={this._openViewModal}
                                enableDeleteColumn={this.props.sourceDelete === APP_BOOLEANS.TRUE}
                                onRowDelete={this._handleDeleteConfirmation}
                            />
                        </TatvamCol>
                    ) :
                    this.state.loader ? (
                        <TatvamCol s={12} className="grid-empty-area">
                            <h5>
                                Loading...
                            </h5>

                        </TatvamCol>
                    ) : (
                        <TatvamCol s={12} className="grid-empty-area">
                            <h5>
                                {API_SOURCE.NO_API_SOURCE_AVAILABLE}
                            </h5>

                        </TatvamCol>
                    )
                }
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default ApiSource;
