import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});


export const uploadUserFile = (fileData, onUploadProgress) => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.UPLOAD_FILE.format(fileData.category);
    let formData = new FormData();
    formData.append("file", fileData.currentFile);

    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};


const actions = {
    GetFolderCategorySuccess: (payload) => ({
        type: "GET_FOLDER_CATEGORY_SUCCESS",
        payload
    }),
    GetFolderCategoryError: (payload) => ({
        type: "GET_FOLDER_CATEGORY_ERROR",
        payload
    })
};

export const fetchFolderCategory = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_DIRECTORY_LISTING;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetFolderCategorySuccess(response.data.data));
                return response;

            }
            dispatch(actions.GetFolderCategoryError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetFolderCategoryError(error.message));
            return Promise.resolve(error);
        });
};

