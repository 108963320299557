import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteCustomerTenant, fetchCustomerTenant, fetchTenantIndustry} from "../../../../../actions";
import Tenants from "./tenants";

const mapStateToProps = (state, ownProps) => ({
    license_type: state.customer.list[ownProps.currentCustomer.id].license_type,
    gridData: state.customer.list[ownProps.currentCustomer.id].tenants,
    customerList: state.customer.list,
    tenantPermission: state.auth.permissions && state.auth.permissions.customer.tenants.create,
    tenantDelete: state.auth.permissions && state.auth.permissions.customer.tenants.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerTenant,
            fetchTenantIndustry,
            deleteCustomerTenant
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
