import React, {Fragment} from "react";
import {Row} from "react-materialize";
import {TatvamButton, TatvamCol, TatvamModal} from "../../functionalComponents";
import "./alert_box.scss";
import {MODAL_BUTTONS} from "../../../constants/appConstants";

class AlertBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    _handleClose = (e) => {
        this.props.closeAlertBox(e.currentTarget.name);
    };

    render() {
        return (
            <Fragment>
                <Row>
                    <TatvamCol s={3}>
                        <TatvamModal
                            open={this.props.open}
                            options={{dismissible: false}}
                            fixedFooter={false}
                            className="alert_box"
                            actions={(
                                <Row className="pb-2 pr-2">

                                    {this.props.isConfirmBox && (
                                        <TatvamButton className="mt-1 mr-2  btn_primary " name="cancel"
                                                      onClick={this._handleClose}>
                                            {MODAL_BUTTONS.CANCEL}
                                        </TatvamButton>
                                    )}
                                    <TatvamButton className="mt-1 ml-2  btn_primary " name="ok"
                                                  onClick={this._handleClose}>
                                        {MODAL_BUTTONS.OK}
                                    </TatvamButton>
                                </Row>
                            )}
                        >
                            <a href="" className="modalClose">
                                {(this.props.isModalClose !== false) && (
                                    <i className="material-icons" title="Close" onClick={this._handleClose}>close</i>
                                )}
                            </a>
                            {this.props.isSuccess ?
                                <p className="material-icons  success_info"> done </p> :
                                <p className="material-icons  error_info"> error_outline </p>}
                            <p className="alertInfo">
                                {this.props.alert_information}
                            </p>
                        </TatvamModal>
                    </TatvamCol>
                </Row>
            </Fragment>
        );
    }
}

export default AlertBox;
