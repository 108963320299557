/* eslint-disable no-param-reassign */
const initialState = {
    authToken: "",
    error: {}
};

const AuthReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }
    switch (action.type) {
        case "SIGNIN_SUCCESS":
        case "FETCH_USER_DETAILS_SUCCESS":
            state = {
                ...state,
                ...action.payload
            };
            return state;
        case "SIGNIN_ERROR":
        case "FETCH_USER_DETAILS_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        case "SIGNOUT_SUCCESS":
            state = initialState;
            return state;
        case "USER_PERMISSION_SUCCESS":
            state = {
                ...state,
                permissions: action.payload
            };
            return state;
        default:
            return state;
    }
};

export default AuthReducer;
