import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    EditCustomerAlertRulesSuccess: (payload) => ({
        type: "EDIT_CUSTOMER_ALERT_RULE_SUCCESS",
        payload
    }),
    EditCustomerAlertRulesError: (payload) => ({
        type: "EDIT_CUSTOMER_ALERT_RULE_ERROR",
        payload
    }),
    CreateCustomerAlertRulesSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_ALERT_RULE_SUCCESS",
        payload
    }),
    CreateCustomerAlertRulesError: (payload) => ({
        type: "CREATE_CUSTOMER_ALERT_RULE_ERROR",
        payload
    }),
    DeleteCustomerAlertRulesSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_ALERT_RULE_SUCCESS",
        payload
    }),
    DeleteCustomerAlertRulesError: (payload) => ({
        type: "CREATE_CUSTOMER_ALERT_RULE_ERROR",
        payload
    }),
    GetCustomerAlertRulesSuccess: (payload) => ({
        type: "FETCH_CUSTOMER_ALERT_RULE_SUCCESS",
        payload
    }),
    GetCustomerAlertRulesError: (payload) => ({
        type: "FETCH_CUSTOMER_ALERT_RULE_ERROR",
        payload
    })
};

export const createCustomerAlertRules = (ruleData) => (dispatch, getState) => {
    const state = getState();
    const payload = ruleData;

    const customer = state.customer.list[ruleData.customer_id];

    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_RULES.format(ruleData.customer_id);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (customer.rules) {
                    customerData = {
                        ...customerData,
                        rules: [...customerData.rules, response.data.data[0]]
                    };
                } else {
                    customerData = {
                        ...customerData,
                        rules: [response.data.data[0]]

                    };
                }

                dispatch(actions.CreateCustomerAlertRulesSuccess(customerData));
                return response;
            }

            dispatch(actions.CreateCustomerAlertRulesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateCustomerAlertRulesError(error.message));
            return Promise.resolve(error.response);
        });
};

export const editCustomerAlertRules = (ruleData) => (dispatch, getState) => {
    const state = getState();
    const payload = ruleData;

    const customer = JSON.parse(JSON.stringify(state.customer.list[ruleData.customer_id]));

    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_RULES.format(ruleData.customer_id, ruleData.alert_name);

    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                const customerData = customer;
                let i = "";
                customerData.rules.map((data, index) => {
                    if (data.alert_name === response.data.data[0].alert_name) {
                        i = index;
                    }
                });
                customerData.rules[i] = response.data.data[0];
                dispatch(actions.EditCustomerAlertRulesSuccess(customerData));
                return response;
            }
            dispatch(actions.EditCustomerAlertRulesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditCustomerAlertRulesError(error.message));
            return Promise.resolve(error);
        });
};

export const fetchCustomerAlertRules = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        const customer = state.customer.list[id];
        if (customer && customer.rules) {
            if (customer.rules.length > 0) {

                return Promise.resolve(true);
            }
        }
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_RULES.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    let customerData = customer;
                    customerData = {
                        ...customerData,
                        rules: response.data.data
                    };

                    dispatch(actions.GetCustomerAlertRulesSuccess(customerData));
                    return response;

                }

                dispatch(actions.GetCustomerAlertRulesError(response.message));
                return response;

            })
            .catch(error => {
                dispatch(actions.GetCustomerAlertRulesError(error.message));
                return Promise.resolve(error);
            });
    }
};
export const deleteCustomerAlertRules = (ruleData) => (dispatch, getState) => {
    const state = getState();
    const payload = ruleData;

    const customer = state.customer.list[ruleData.customer_id];

    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_RULES.format(ruleData.customer_id, ruleData.alert_name);

    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (customer.rules) {
                    const rulesAfterDelete = customerData.rules.filter((rules) => {
                        return rules.alert_name !== ruleData.alert_name;
                    });
                    customerData = {
                        ...customerData,
                        rules: rulesAfterDelete
                    };
                } else {
                    customerData = {
                        ...customerData,
                        rules: []

                    };
                }

                dispatch(actions.DeleteCustomerAlertRulesSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerAlertRulesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteCustomerAlertRulesError(error.message));
            return Promise.resolve(error.response);
        });
};
