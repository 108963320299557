import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    EditCustomerResponseTemplateSuccess: (payload) => ({
        type: "EDIT_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS",
        payload
    }),
    EditCustomerResponseTemplateError: (payload) => ({
        type: "EDIT_CUSTOMER_RESPONSE_TEMPLATE_ERROR",
        payload
    }),
    CreateCustomerResponseTemplateSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS",
        payload
    }),
    CreateCustomerResponseTemplateError: (payload) => ({
        type: "CREATE_CUSTOMER_RESPONSE_TEMPLATE_ERROR",
        payload
    }),
    DeleteCustomerResponseTemplateSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS",
        payload
    }),
    DeleteCustomerResponseTemplateError: (payload) => ({
        type: "CREATE_CUSTOMER_RESPONSE_TEMPLATE_ERROR",
        payload
    }),
    GetCustomerRolesSuccess: (payload) => ({
        type: "FETCH_CUSTOMER_RESPONSE_TEMPLATE_SUCCESS",
        payload
    }),
    GetCustomerRolesError: (payload) => ({
        type: "FETCH_RESPONSE_TEMPLATE_ERROR",
        payload
    })
};

export const editResponseTemplate = (responseTemplate) => (dispatch, getState) => {
    const state = getState();
    const customer = state.customer.list[responseTemplate.customer_id];
    const payload = responseTemplate;

    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_RESPONSE_TEMPLATE.format(responseTemplate.customer_id, responseTemplate.id);
    return axios
        .patch(url, payload)
        .then(response => {

            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.responseTemplates.filter(data => data.id !== response.data.data[0].id);

                let updatedRoleData = [...response.data.data, ...newCustomerData];
                customerData = {
                    ...customerData,
                    responseTemplates: updatedRoleData
                };
                dispatch(actions.EditCustomerResponseTemplateSuccess(customerData));
                return response;
            }

            dispatch(actions.EditCustomerResponseTemplateError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditCustomerResponseTemplateError(error.message));
            return Promise.resolve(error.response);
        });
};

export const createResponseTemplate = (responseTemplate) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[responseTemplate.customer_id];
    const payload = responseTemplate;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_RESPONSE_TEMPLATE.format(responseTemplate.customer_id);


    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = JSON.parse(JSON.stringify(customer));
                if (customerData.responseTemplates) {
                    customerData.responseTemplates.push(response.data.data[0]);
                } else {
                    customerData.responseTemplates = [];
                    customerData.responseTemplates.push(response.data.data[0]);
                }

                dispatch(actions.CreateCustomerResponseTemplateSuccess(customerData));
                return response;
            }


            dispatch(actions.CreateCustomerResponseTemplateError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateCustomerResponseTemplateError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerResponseTemplates = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        const customer = state.customer.list;
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_RESPONSE_TEMPLATE.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    let customerData = customer[id];
                    customerData = {
                        ...customerData,
                        responseTemplates: response.data.data
                    };
                    dispatch(actions.GetCustomerRolesSuccess(customerData));
                    return response;
                }

                dispatch(actions.GetCustomerRolesError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerRolesError(error.message));
                return Promise.resolve(error.response);
            });
    }
};
export const deleteCustomerResponseTemplate = (responseTemplateData) => (dispatch, getState) => {
    const store = getState();
    const customer = store.customer.list[responseTemplateData.customer_id];
    const payload = responseTemplateData;
    const url = apiUrl.BASE_URL + apiUrl.DELETE_RESPONSE_TEMPLATE.format(responseTemplateData.customer_id, responseTemplateData.id);
    return axios
        .delete(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = JSON.parse(JSON.stringify(customer));
                if (customerData.responseTemplates) {
                    const templateAfterDelete = customerData.responseTemplates.filter((template) => {
                        return template.id !== responseTemplateData.id;
                    });
                    customerData["responseTemplates"] = templateAfterDelete;
                } else {
                    customerData["responseTemplates"] = [];
                }

                dispatch(actions.DeleteCustomerResponseTemplateSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerResponseTemplateError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteCustomerResponseTemplateError(error.message));
            return Promise.resolve(error.response);
        });
};
