import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetTagTreatmentSuccess: (payload) => ({
        type: "GET_TAG_TREATMENT_SUCCESS",
        payload
    }),
    GetTagTreatmentError: (payload) => ({
        type: "GET_TAG_TREATMENT_ERROR",
        payload
    })
};


export const fetchTagTreatment = () => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_TAG_TREATMENT;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const tagTreatmentList = {};
                response.data.data.map((data) => {
                    tagTreatmentList[data.id] = data;
                });

                dispatch(actions.GetTagTreatmentSuccess(tagTreatmentList));
                return response;

            }
            dispatch(actions.GetTagTreatmentError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetTagTreatmentError(error.message));
            return Promise.resolve(error);
        });
};
