import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetCustomerTenantSuccess: payload => ({
        type: "FETCH_TENANT_SUCCESS",
        payload
    }),
    GetCustomerTenantError: payload => ({
        type: "FETCH_TENANT_ERROR",
        payload
    }),
    DeleteCustomerTenantSuccess: payload => ({
        type: "FETCH_TENANT_SUCCESS",
        payload
    }),
    DeleteCustomerTenantError: payload => ({
        type: "FETCH_TENANT_ERROR",
        payload
    })

};

export const fetchCustomerTenant = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        let customer = state.customer.list[id];
        if (customer && customer.tenants) {
            if (customer.tenants.length > 0) {
                return Promise.resolve(true);
            }
        }
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_TENANTS.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    customer = {
                        ...customer,
                        tenants: response.data.data
                    };
                    dispatch(actions.GetCustomerTenantSuccess(customer));
                    return response;
                }


                dispatch(actions.GetCustomerTenantError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerTenantError(error.message));
                return Promise.resolve(error.response);
            });
    }
};

export const deleteCustomerTenant = (tenantData) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[tenantData.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_TENANTS.format(tenantData.customer_id, tenantData.id);
    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (customer.tenants) {
                    const tenantsAfterDelete = customerData.tenants.filter((tenants) => {
                        return tenants !== tenantData.id;
                    });
                    customerData = {
                        ...customerData,
                        tenants: tenantsAfterDelete
                    };
                } else {
                    customerData = {
                        ...customerData,
                        tenants: []

                    };
                }
                dispatch(actions.DeleteCustomerTenantSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerTenantError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteCustomerTenantError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchTenantIndustry = (industry) => (dispatch, getState) => {
    if (industry) {
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_TENANT_INDUSTRY.format("Tenants");

        return axios
            .get(url)
            .then(response => {
                return response;

            })
            .catch(error => {
                return Promise.resolve(error.response);
            });
    }
};
