import React from 'react';
import {Card} from "react-materialize";

const BaseCard = (props) => {
    return (
        <Card {...props}/>
    )
}

export default BaseCard;
