import React, {Component, Fragment,} from 'react';

import {ALERT_MESSAGES} from '../../../constants/appConstants';
import {TatvamCol} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import ImageCrop from './cropImage';

class CropImageForm extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            userProfilePic: "",
            editor: null,
            scaleValue: 1,
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(props) {

    }

    setEditorRef = editor => this.setState({editor});

    onCrop = () => {
        const {editor} = this.state;
        if (editor !== null) {
            const url = editor.getImageScaledToCanvas().toDataURL();
            this.setState({userProfilePic: url});
        }
    };

    onApply = () => {
        this.props.setImageData(this.state.userProfilePic);
    };

    onCancel = () => {
        this.props.closeCropModal();
    };

    onScaleChange = (scaleChangeEvent) => {
        const scaleValue = parseFloat(scaleChangeEvent.target.value);
        this.setState({scaleValue});
    };

    profilePicChange = (fileChangeEvent) => {
        let validExtensions = ["jpg", "jpeg", "png"];
        const file = fileChangeEvent.target.files[0];
        let extension = file.name.split(".")[1].toLowerCase();
        if (validExtensions.indexOf(extension) > -1) {
            const {type} = file;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ev => {
                this.setState({
                    selectedImage: ev.target.result
                });

                if (!(type.toLowerCase().endsWith('jpeg') || type.toLowerCase().endsWith('png') || type.toLowerCase().endsWith('jpg') || type.toLowerCase().endsWith('gif'))) {
                } else {
                    this.setState({openCropper: true, selectedImage: ev.target.result, fileUploadErrors: []});
                }
            };
        } else {
            this.setState({
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.ACCEPTED_IMAGE_FORMATS
            });
            fileChangeEvent.stopPropagation();
        }
    };

    _discard = () => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    render() {
        return (
            <Fragment>
                <h4>{"Crop Image"}</h4>
                <TatvamCol s={12} className="mt-3">
                    <TatvamCol s={12} m={12} l={12} className="p-0">
                        <input type="file" name="profilePicBtn" accept="image/png, image/jpeg, image/gif"
                               onChange={this.profilePicChange}/>
                    </TatvamCol>
                    <TatvamCol s={12} m={12} l={12} className="mt-3">
                        <ImageCrop
                            imageSrc={this.state.selectedImage}
                            setEditorRef={this.setEditorRef}
                            onCrop={this.onCrop}
                            onApply={this.onApply}
                            onCancel={this.onCancel}
                            scaleValue={this.state.scaleValue}
                            onScaleChange={this.onScaleChange}
                            userProfilePic={this.state.userProfilePic}
                        />
                    </TatvamCol>
                </TatvamCol>

                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        isConfirmBox={this.state.isConfirmBox}
                        closeAlertBox={this._discard}
                        alert_information={this.state.alert_information}
                        isModalClose={this.state.isModalClose}
                    />
                )}
            </Fragment>
        );
    }
}

export default CropImageForm;
