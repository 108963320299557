import './csvExport.scss';

import React, {Fragment, useEffect, useState,} from 'react';

import {CSVLink} from 'react-csv';

import {getObjectPropValue, setObjectPropValue,} from '../../../utils/objectUtil';

const CsvExport = (props) => {
    const [formattedDatas, setFormattedDatas] = useState([]);
    const [formattedExportString, setFormattedExportString] = useState('');
    const [csvHeaderString, setCsvHeaderString] = useState('');

    useEffect(() => {
        if (props.columns && props.datas) {
            setFormattedDatas(formatCsvSourceData());
        } else {
            setFormattedDatas([]);
        }
    }, [
        (!props.csvHeader || props.csvHeader.length <= 0) && props.datas,
        props.datas,
    ]);

    useEffect(() => {
        if (props.csvHeader) {
            setCsvHeaderString(
                converCsvHeaderToCsvString(
                    props.csvHeader,
                    props.csvHeaderLineBreakCount,
                ),
            );
            if (props.columns && props.datas) {
                let dataString = converCsvColumnToCsvString(props.columns);
                const clonedDatas = props.datas.map((data) => {
                    return {...data};
                });
                clonedDatas.forEach((data, index) => {
                    dataString += '\n' + converCsvDataToCsvString(data, props.columns);
                });
                setFormattedExportString(dataString);
            }

            return;
        }
        setCsvHeaderString('');
        setFormattedExportString('');
    }, [props.csvHeader && props.csvHeader.length > 0, props.datas]);

    const converCsvDataToCsvString = (row, columns) => {
        let csvRowString = '';
        columns.map((column, index) => {
            if (index > 0) {
                csvRowString += props.delimeter;
            }
            const columnValue = column.dataCallback
                ? column.dataCallback(getObjectPropValue(row, column.key))
                : getObjectPropValue(row, column.key);
            csvRowString += `${columnValue === undefined ? '' : columnValue}`;
        });

        return csvRowString;
    };

    const converCsvColumnToCsvString = (columns) => {
        let columnString = '';
        const clonedColumns = columns.map((column) => {
            return {...column};
        });
        clonedColumns.forEach((column, index) => {
            if (index > 0) {
                columnString += props.delimeter;
            }
            columnString += column.label;
        });

        return columnString;
    };

    const converCsvHeaderToCsvString = (
        columns,
        lineBrakeCount,
    ) => {
        let columnString = '';
        const clonedColumns = columns.map(
            (column) => {
                return {...column};
            },
        );
        clonedColumns.forEach(
            (column, index) => {
                if (index > 0) {
                    columnString += '\n';
                }
                columnString += column.key + props.delimeter + column.value;
            },
        );

        if (lineBrakeCount) {
            for (let index = 0; index < lineBrakeCount; index++) {
                columnString += '\n';
                columnString += '';
            }
        }

        return columnString;
    };

    const formatCsvSourceData = () => {
        const clonedColumns = props.columns.map(
            (column) => {
                return {...column};
            },
        );
        const clonedDatas = props.datas.map((data) => {
            return {...data};
        });

        clonedColumns.forEach((column) => {
            clonedDatas.forEach((data) => {
                if (column.dataCallback) {
                    const formattedValue = column.dataCallback(
                        getObjectPropValue(data, column.key),
                        data,
                    );
                    setObjectPropValue(
                        column.key,
                        data,
                        formattedValue,
                    );
                }
            });
        });

        return clonedDatas;
    };

    return (
        <Fragment>
			<span className="pull-right cursor-pointer">
				<CSVLink
                    data={
                        props.csvHeader
                            ? csvHeaderString + '\n' + formattedExportString
                            : formattedDatas
                    }
                    filename={props.downloadFileName}
                    headers={props.csvHeader ? undefined : props.columns}
                    separator={props.delimeter}
                >
					<img
                        data-toggle="tooltip"
                        title="Export CSV"
                        className="table-export-icon"
                        src={require("../../../../public/assets/images/csv.png")}
                        alt={"export csv"}></img>
				</CSVLink>
			</span>
        </Fragment>
    );
};

export default CsvExport;

