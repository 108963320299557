import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetSubscriptionReportsSuccess: (payload) => ({
        type: "GET_SUBSCRIPTION_REPORTS_SUCCESS",
        payload
    }),
    GetSubscriptionReportsError: (payload) => ({
        type: "GET_SUBSCRIPTION_REPORTS_ERROR",
        payload
    })
};

export const getSubscriptionReports = (id) => (dispatch, getState) => {
    if (id) {
        const url = apiUrl.BASE_URL + apiUrl.GET_SUBSCRIPTION_REPORTS.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    const subscriptionReportsList = {};
                    response.data.data.map((data) => {
                        subscriptionReportsList[data.id] = data;
                    });

                    dispatch(actions.GetSubscriptionReportsSuccess(subscriptionReportsList));
                    return response;

                }
                dispatch(actions.GetSubscriptionReportsError(response.message));
                return response;

            })
            .catch(error => {
                dispatch(actions.GetSubscriptionReportsError(error.message));
                return Promise.resolve(error);
            });
    }
};
