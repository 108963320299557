import React from "react";
import {BaseMultiSelect} from "../../baseComponents";

const TatvamMultiSelect = (props) => {
    return (
        <BaseMultiSelect {...props} />
    )
}

export default TatvamMultiSelect;
