import React, {useEffect, useState} from 'react';

const EmotionRating = (props) => {
    const [currentRating, setCurrentRating] = useState(0);

    useEffect(() => {
        setCurrentRating(!props && !props.rating ? 0 : props.rating);
    }, [props && props.rating]);

    const renderEmotion = () => {
        const stars = [];
        let classes = 'emotion';
        const ratingToBeRenderCount = props && props.isAllRatingVisible === undefined || props.isAllRatingVisible ? 5 : Math.round(currentRating);
        for (let index = 1; index <= ratingToBeRenderCount; index++) {
            if (Math.round(currentRating) === index) {
                classes = classes + ' active';
            } else {
                classes = 'emotion';
            }

            stars.push(
                <img src={require(`../../../../../../public/assets/images/menuIcon/smile_0${index}.jpg`)} alt="emotion"
                     className={classes}/>,
            );
        }
        return stars;
    };

    return (
        renderEmotion()
    );
};

export default EmotionRating;
