/* eslint-disable import/prefer-default-export */
import {apiUrl} from 'apiConstants';
import axios from 'axios';

const actions = {
    GetCustomerCategorySuccess: (payload) => ({
        type: "GET_CUSTOMER_CATEGORY_SUCCESS",
        payload
    }),
    GetCustomerCategoryError: (payload) => ({
        type: "GET_CUSTOMER_CATEGORY_ERROR",
        payload
    })
};
export const fetchCustomerCategory = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_CATEGORY;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetCustomerCategorySuccess(response.data.data));
                return response;

            }
            dispatch(actions.GetCustomerCategoryError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetCustomerCategoryError(error.message));
            return Promise.resolve(error);
        });
};
