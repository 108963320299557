import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CustomerUsersUsageLogs from "./customerUsersUsageLog";
import {fetchApplicationNames, getCustomerUsersLogDetails} from "../../../actions";

const mapStateToProps = (state) => ({
    allCustomers: state.customer.list,
    applicationList: state.applicationName.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getCustomerUsersLogDetails,
            fetchApplicationNames
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsersUsageLogs);
