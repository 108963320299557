import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteUser, fetchAllUserCustomer, fetchAllUsers} from "../../../../actions";
import UsersGrid from "./usersGrid";

const mapStateToProps = (state) => ({
    userCustomersList: state.customer ? state.customer.user_customers : []
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllUsers,
            fetchAllUserCustomer,
            deleteUser
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersGrid);
