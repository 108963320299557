import TabsProject from "./tabsProject";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchCustomerReportSubscription, fetchCustomerTabCount} from "../../../actions";

const mapStateToProps = (state, ownProps) => ({
    customerTabData: state.customer.customerTabData,
    reportSubscriptionLst: state.customer.list[ownProps.currentCustomerId] && state.customer.list[ownProps.currentCustomerId].reportSubscriptions ? state.customer.list[ownProps.currentCustomerId].reportSubscriptions : []
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerTabCount,
            fetchCustomerReportSubscription
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(TabsProject);
