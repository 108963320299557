import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAllCustomers, fetchAllCustomersByUser, fetchUserDetails, UserSignOut} from "../../actions";
import Dashboard from "./dashboard";

const mapStateToProps = (state) => ({
    customerList: state.customer.list,
    userDetails: state.auth,
    customerCreate: state.auth.permissions && state.auth.permissions.customer.create
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllCustomers,
            fetchUserDetails,
            fetchAllCustomersByUser,
            UserSignOut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
