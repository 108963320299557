import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createUser,
    editUser,
    fetchAllCustomers,
    fetchAllStates,
    fetchAllUsers,
    fetchUserData,
    fetchUserType
} from "../../../../actions";
import UserForm from "./userForm";

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.users.list[ownProps.user],
    userName: state.auth.userName,
    userPermission: state.auth.permissions && state.auth.permissions.user.create,
    customers: state.users.list[ownProps.user] && state.users.list[ownProps.user].customers,
    userTypes: state.userType.list ? state.userType.list : [],
    states: state.states.list
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllCustomers,
            fetchAllUsers,
            createUser,
            editUser,
            fetchUserType,
            fetchUserData,
            fetchAllStates
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
