import './group-checkbox.component.scss';

import {Card, Form} from 'react-bootstrap';
import React, {Fragment, useEffect, useState} from 'react';
import {Collapsible, CollapsibleItem} from "react-materialize";

const GroupCheckBox = (props) => {
    const [beforeFilteredItems, setBeforeFilteredItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (props.items && props.items.length) {
            setBeforeFilteredItems(props.items);
        } else {
            setBeforeFilteredItems([]);
        }
    }, [props.items])

    useEffect(() => {
        if (beforeFilteredItems.length > 0) {
            setFilteredItems(searchGroupCheckboxItems(searchText, beforeFilteredItems));
        } else {
            setFilteredItems([]);
        }
    }, [beforeFilteredItems])

    useEffect(() => {
        if (searchText.trim().length >= 1) {
            setFilteredItems(searchGroupCheckboxItems(searchText, beforeFilteredItems));
        } else {
            setFilteredItems(beforeFilteredItems);
        }
    }, [searchText]);

    const searchGroupCheckboxItems = (searchText, groupCheckboxItems) => {
        if (searchText.trim().length >= 1) {
            return groupCheckboxItems.filter((checkBoxItem) => checkBoxItem.name.toLowerCase().includes(searchText.toLowerCase()));
        } else {
            return groupCheckboxItems;
        }
    }

    const isItemChecked = () => {
        if (beforeFilteredItems && beforeFilteredItems.length > 0) {
            return beforeFilteredItems.filter((item) => item.isChecked).length > 0;
        } else {
            return false;
        }
    }

    const onClearSelectionClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSearchText('');

        const allItemUnCheck = beforeFilteredItems.map((item) => {
            return {...item, isChecked: false};
        });
        setBeforeFilteredItems(allItemUnCheck);

        props.onSelectionChanged([], props.name,);
    };

    const onClearSearch = () => {
        onSearchTextChanged('');
    }

    const onCheckBoxItemChanged = (e) => {
        const uniqueName = props.usedFor ? (props.usedFor + '_' + props.name) : props.name;
        let id = e.target.id.toString().replace(uniqueName, '');
        let isChecked = e.target.checked;
        let clonedItems = beforeFilteredItems.map((filterItem) => {
            const item = {...filterItem, isChecked: filterItem.id === id ? isChecked : filterItem.isChecked}
            return item;
        });

        setBeforeFilteredItems(clonedItems);

        if (props.onSelectionChanged) {
            props.onSelectionChanged(clonedItems, props.name);
        }
    };

    const onSearchTextChanged = (value) => {
        setSearchText(value);
    }

    const onSelectAllClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setSearchText('');
        const allItemCheck = beforeFilteredItems.map((item) => {
            return {...item, isChecked: true};
        });
        setBeforeFilteredItems(allItemCheck);
        if (props.onSelectionChanged) {
            props.onSelectionChanged(allItemCheck, props.name);
        }
    };

    const renderBody = () => {
        const renderSearchBar = () => {
            return <Fragment>
                <div className="d-flex search-control">
                    <i className="fas fa-search"/>
                    <input
                        autoComplete="off"
                        type="text"
                        id="itemSearch"
                        value={searchText}
                        onChange={e => onSearchTextChanged(e.target.value)}
                        className="form-control text-control"
                        placeholder={`Search ${props.caption}`}
                        aria-label={`Search ${props.caption}`}
                        maxLength={30}
                    />
                    {searchText.length > 0 &&
                        <a className="" onClick={onClearSearch}><span className="fas fa-times"/></a>}
                </div>
            </Fragment>
        }

        const cardBody = () => {
            if (filteredItems.length > 0) {
                return <Fragment>
                    {filteredItems.map((item) => (
                        <div className="d-flex ml-1">
                            <Form.Check
                                inline
                                label={item.name}
                                name={item.name}
                                type={'checkbox'}
                                id={(props.usedFor ? (props.usedFor + '_' + props.name) : props.name) + item.id.toString()}
                                checked={item.isChecked}
                                onChange={onCheckBoxItemChanged}
                            />
                        </div>
                    ))}
                </Fragment>;
            } else {
                return <Fragment>No Record</Fragment>;
            }
        };

        if (!props.showCollapseIcon) {
            return (
                <Fragment>
                    <Card.Body>{cardBody()}</Card.Body>
                </Fragment>
            );
        }
        return (
            <Fragment>
                {props.isShowSearch === true && renderSearchBar()}
                {cardBody()}
            </Fragment>
        );
    };

    const renderClear = () => {
        if (!beforeFilteredItems || beforeFilteredItems.length <= 0 || !props.isShowClear || !isItemChecked()) {
            return <Fragment></Fragment>;
        }
        return (
            <button
                type="button"
                className="btn-link border-none bg-white p-0"
                onClick={onClearSelectionClick}
            >
                clear
            </button>
        );
    };

    const renderHeader = () => {
        if (!props.showCollapseIcon) {
            return (
                <Fragment>
                    <p className="m-0 mr-2">{props.caption}</p>
                    {renderClear()}
                    {renderSelectAll()}
                </Fragment>
            );
        } else
            return (
                <Fragment>
                    <p className="m-0 mr-2">{props.caption}</p>
                    {renderClear()}
                    {renderSelectAll()}
                </Fragment>
            );
    };

    const renderSelectAll = () => {
        if (!beforeFilteredItems || beforeFilteredItems.length <= 0 || !props.isShowSelectAll || isItemChecked()) {
            return;
        }

        return (
            <button
                type="button"
                className="btn-link border-none bg-white p-0"
                onClick={onSelectAllClick}>
                select all
            </button>
        );
    };

    return (
        <div className="group-checkbox">
            {props.showCollapseIcon ? (

                <Collapsible accordion className="m-0">
                    <CollapsibleItem
                        expanded={props.isExpanded}
                        header={renderHeader()}
                        node="div"
                    >
                        <div>
                            {renderBody()}
                        </div>
                    </CollapsibleItem>
                </Collapsible>
            ) : (
                <Card>
                    {renderHeader()}
                    {renderBody()}
                </Card>
            )}
        </div>
    );
}

export default GroupCheckBox;
