import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';
import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    FORM_OPERATIONS,
    PROPERTY_PREDICTION_INFLUENCER,
    SWITCH_TEXT,
} from '../../../constants/appConstants';
import {TatvamButton, TatvamCol, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class PredictionInfluencerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            preLoader: false,
            editFlag: false,
            applicationMode: props.applicationMode,
            status: false,
            isValid: true,
            ...this.props.selectedInfluencerData
        }
    }

    componentDidMount() {
        this.setState({
            status: this.props.selectedInfluencerData.status === APPLICATION_MODES.ACTIVE ? true : false
        })
    };


    componentWillReceiveProps(props) {
        this.setState({
            status: props.selectedInfluencerData.status === APPLICATION_MODES.ACTIVE ? true : false
        })
    };

    _validations = () => {
        let mandatoryFieldsValid;
        if (!this.state.influencer_name || !this.state.influencer_desc) {
            mandatoryFieldsValid = false;
        } else {
            mandatoryFieldsValid = true;
        }
        this.setState({
            isValid: mandatoryFieldsValid
        });
        return Promise.resolve(true);
    };

    setAlertResponseTemplate = (alertMessage, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMessage
        });
    };

    _handleSave = (e) => {
        e.preventDefault();
        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            } else {
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    const formData = {
                        customer_id: this.props.currentCustomerId,
                        influencer_name: this.state.influencer_name,
                        influencer_desc: this.state.influencer_desc,
                        status: this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE,
                        created_by: this.props.userName
                    }
                    this.setState({preLoader: true});
                    this.props.actions.createPredictionInfluencer(this.props.currentCustomerId, formData).then((response) => {
                        this.setState({preLoader: false});
                        if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                                this.setAlertResponseTemplate(response.data.message, true);
                            } else {
                                this.setAlertResponseTemplate(response.data.message, true);
                            }
                        } else {
                            this.setState({shouldCloseModal: false});
                            this.setAlertResponseTemplate(response.data.message, false);
                        }
                    })
                } else if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                    const influencersFormData = {
                        customer_id: this.props.currentCustomerId,
                        influencer_name: this.state.influencer_name,
                        influencer_desc: this.state.influencer_desc,
                        status: this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE,
                        updated_by: this.props.userName,
                        updated_count: this.props.selectedInfluencerData.process_data ? this.props.selectedInfluencerData.process_data.updated_count : 0,
                    }
                    this.setState({preLoader: true});
                    this.props.actions.editPredictionInfluencers(
                        this.props.currentCustomerId,
                        influencersFormData,
                        this.props.selectedInfluencerData.influencer_id
                    ).then((response) => {
                        this.setState({preLoader: false});
                        if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                                this.setAlertResponseTemplate(response.data.message, true);
                            } else {
                                this.setAlertResponseTemplate(response.message, true);
                            }
                        } else {
                            this.setState({shouldCloseModal: false});
                            this.setAlertResponseTemplate(response.data.message, false);
                        }
                    })
                }
            }
        });
    };

    _handleInput = (e) => {
        if (e.target.id === "status") {
            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>

                <Row>
                    {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                        <h4>{PROPERTY_PREDICTION_INFLUENCER.VIEW_INFLUENCER_TITLE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{PROPERTY_PREDICTION_INFLUENCER.EDIT_INFLUENCER_TITLE}</h4>) : ""}
                </Row>

                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12}>
                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="influencer_name">{PROPERTY_PREDICTION_INFLUENCER.NAME} <span
                                    style={{color: 'red'}}> *</span></label>}

                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.influencer_name) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                    s={12}
                                    required
                                    name="influencer_name"
                                    onChange={this._handleInput}
                                    value={this.state.influencer_name}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12}>
                            <TatvamCol m={12} xl={12} className="textarea-input pt-2">
                                <label htmlFor="Description">{PROPERTY_PREDICTION_INFLUENCER.DESCRIPTION}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    required
                                    className={(this.state.inValidForm && !this.state.influencer_desc) ? "firstItem labelText mb-0 validate txtarea-border mb-1 invalid" : "firstItem labelText mb-0 validate txtarea-border mb-1"}
                                    name="influencer_desc"
                                    value={this.state.influencer_desc}
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12}>

                            <TatvamCol s={6} className="switch-wrapper">
                                <Switch
                                    id="status"
                                    offLabel="Status"
                                    checked={this.state.status}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>
                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span className="property-label col s4"> {PROPERTY_PREDICTION_INFLUENCER.NAME}<span
                                    style={{color: 'red'}}> *</span> : </span>
                                <span className="col s8 url-wrap"> {this.state.influencer_name}</span>
                            </div>
                        </TatvamCol>

                        <TatvamCol m={12}>
                            <div className="col s12 p-0">
                                <span
                                    className="property-label col s2"> {PROPERTY_PREDICTION_INFLUENCER.DESCRIPTION}<span
                                    style={{color: 'red'}}> *</span> :</span>
                                <TatvamTextArea
                                    disabled
                                    value={this.state.influencer_desc}
                                    className="mb-2"
                                    s={10}
                                />
                            </div>
                            <div className="  col s12 p-0">
                                <span
                                    className="property-label col s2"> {PROPERTY_PREDICTION_INFLUENCER.STATUS} :</span>
                                <span
                                    className="col s10">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                    <TatvamCol s={12} m={12} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                            this.props.rolePermission === APP_BOOLEANS.TRUE && (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            )}
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        )
    }
}

export default PredictionInfluencerForm;
