import './customerUsersUsageLog.scss';

import React, { Fragment } from 'react';
import moment from 'moment';
import Loader from 'react-loaders';
import { Col, Row, Switch, } from 'react-materialize';

import {
    APP_CATEGORIES,
    APPLICATION_MODES,
    CATEGORY_LIST_OPTIONS,
    DATE_FORMAT,
    REPORT_FORMAT,
    USAGE_LOG_LABELS,
    USAGE_LOG_PROPERTIES,
} from '../../../constants/appConstants';
import { populateCsvHeader } from '../../../utils/csv.utility';
import dateUtil from '../../../utils/dateUtil';
import { dynamicSort } from '../../../utils/objectUtil';
import { TatvamButton, TatvamCol, TatvamDatePicker, TatvamGrid, TatvamMultiSelect, } from '../../functionalComponents';
import CsvExport from '../../functionalComponents/csvExport/csvExport';
import AlertBox from '../alertBox';

class CustomerUsersUsageLogs extends React.Component {
    constructor(props) {
        super(props);
        const customComparator = (valueA, valueB) => {
            return String(valueA).toLowerCase().localeCompare(String(valueB).toLowerCase());
        };
        const dateComparator = (date1, date2) => {
            return new Date(String(date1)) < new Date(String(date2)) ? -1 : 1;
        };
        const dateFilter = (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue === null || cellValue === 0) return -1;
            let cellDate = new Date(String(cellValue).slice(0, 11));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
        };
        this.state = {
            colDef: [
                {
                    headerName: USAGE_LOG_LABELS.CUSTOMER_ID, field: USAGE_LOG_PROPERTIES.CUSTOMER_ID,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.CUSTOMER_NAME, field: USAGE_LOG_PROPERTIES.CUSTOMER_NAME,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 190,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.CUSTOMER_STATUS, field: USAGE_LOG_PROPERTIES.CUSTOMER_STATUS,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 180,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.CATEGORY, field: USAGE_LOG_PROPERTIES.CATEGORY,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 160,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.FIRST_NAME, field: USAGE_LOG_PROPERTIES.FIRST_NAME,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.LAST_NAME, field: USAGE_LOG_PROPERTIES.LAST_NAME,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.EMAIL_ID, field: USAGE_LOG_PROPERTIES.EMAIL_ID,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 190,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.APPLICATION, field: USAGE_LOG_PROPERTIES.APPLICATION_DISPLAY_NAME,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.USER_TYPE, field: USAGE_LOG_PROPERTIES.USER_TYPE,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.USER_STATUS, field: USAGE_LOG_PROPERTIES.USER_STATUS,
                    comparator: customComparator,
                    filterParams: { clearButton: true },
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.FROM_DATE, field: USAGE_LOG_PROPERTIES.FROM_DATE,
                    comparator: dateComparator,
                    filter: "agDateColumnFilter",
                    filterParams: {
                        comparator: dateFilter,
                        clearButton: true,
                    },
                    cellStyle: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }),
                    valueFormatter: params => params.value === 0 ? '--' : params.value,
                    width: 180,
                    suppressSizeToFit: true,
                },
                {
                    headerName: USAGE_LOG_LABELS.TO_DATE, field: USAGE_LOG_PROPERTIES.TO_DATE,
                    comparator: dateComparator,
                    filter: "agDateColumnFilter",
                    filterParams: {
                        comparator: dateFilter,
                        clearButton: true,
                    },
                    width: 180,
                    suppressSizeToFit: true,
                    cellStyle: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }),
                    valueFormatter: params => params.value === 0 ? '--' : params.value,
                },
                {
                    headerName: USAGE_LOG_LABELS.ACTUAL_LAST_LOGIN, field: USAGE_LOG_PROPERTIES.ACTUAL_LAST_LOGIN,
                    comparator: dateComparator,
                    filter: "agDateColumnFilter",
                    filterParams: {
                        comparator: dateFilter,
                        clearButton: true,
                    },
                    width: 180,
                    suppressSizeToFit: true,
                    cellStyle: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }),
                    valueFormatter: params => params.value === 0 ? '--' : params.value,
                },
                {
                    headerName: USAGE_LOG_LABELS.TOTAL_LOGINS, field: USAGE_LOG_PROPERTIES.TOTAL_LOGINS,
                    filterParams: { clearButton: true },
                    filter: "agNumberColumnFilter",
                    width: 150,
                    suppressSizeToFit: true,
                    cellStyle: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }),
                    valueFormatter: params => params.value === 0 ? '--' : params.value,
                },
                {
                    headerName: USAGE_LOG_LABELS.NOT_LOGGED_IN_FOR, field: USAGE_LOG_PROPERTIES.NOT_LOGGED_IN_FOR,
                    filterParams: { clearButton: true },
                    width: 180,
                    suppressSizeToFit: true,
                    filter: "agNumberColumnFilter",
                    cellStyle: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }),
                    valueFormatter: params => params.value === 0 ? '--' : params.value,

                },
                {
                    headerName: USAGE_LOG_LABELS.AVG_TIMES_BTN_LOGINS, field: USAGE_LOG_PROPERTIES.AVG_TIMES_BTN_LOGINS,
                    filterParams: { clearButton: true },
                    filter: "agNumberColumnFilter",
                    cellStyle: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }),
                    valueFormatter: params => params.value === 0 ? "NA" : params.value,
                    width: 180,
                    suppressSizeToFit: true,
                },
            ],
            exportColumns: [
                {
                    label: USAGE_LOG_LABELS.CUSTOMER_ID,
                    key: USAGE_LOG_PROPERTIES.CUSTOMER_ID
                },
                {
                    label: USAGE_LOG_LABELS.CUSTOMER_NAME,
                    key: USAGE_LOG_PROPERTIES.CUSTOMER_NAME
                },
                {
                    label: USAGE_LOG_LABELS.CUSTOMER_STATUS,
                    key: USAGE_LOG_PROPERTIES.CUSTOMER_STATUS
                },
                {
                    label: USAGE_LOG_LABELS.CATEGORY,
                    key: USAGE_LOG_PROPERTIES.CATEGORY
                },
                {
                    label: USAGE_LOG_LABELS.FIRST_NAME,
                    key: USAGE_LOG_PROPERTIES.FIRST_NAME
                },
                {
                    label: USAGE_LOG_LABELS.LAST_NAME,
                    key: USAGE_LOG_PROPERTIES.LAST_NAME
                },
                {
                    label: USAGE_LOG_LABELS.EMAIL_ID,
                    key: USAGE_LOG_PROPERTIES.EMAIL_ID
                },
                {
                    label: USAGE_LOG_LABELS.APPLICATION,
                    key: USAGE_LOG_PROPERTIES.APPLICATION_DISPLAY_NAME
                },
                {
                    label: USAGE_LOG_LABELS.USER_TYPE,
                    key: USAGE_LOG_PROPERTIES.USER_TYPE
                },
                {
                    label: USAGE_LOG_LABELS.USER_STATUS,
                    key: USAGE_LOG_PROPERTIES.USER_STATUS
                },
                {
                    label: USAGE_LOG_LABELS.FROM_DATE,
                    key: USAGE_LOG_PROPERTIES.TO_DATE
                },
                {
                    label: USAGE_LOG_LABELS.TO_DATE,
                    key: USAGE_LOG_PROPERTIES.TO_DATE
                },
                {
                    label: USAGE_LOG_LABELS.ACTUAL_LAST_LOGIN,
                    key: USAGE_LOG_PROPERTIES.ACTUAL_LAST_LOGIN
                },
                {
                    label: USAGE_LOG_LABELS.TOTAL_LOGINS,
                    key: USAGE_LOG_PROPERTIES.TOTAL_LOGINS
                },
                {
                    label: USAGE_LOG_LABELS.NOT_LOGGED_IN_FOR,
                    key: USAGE_LOG_PROPERTIES.NOT_LOGGED_IN_FOR
                },
                {
                    label: USAGE_LOG_LABELS.AVG_TIMES_BTN_LOGINS,
                    key: USAGE_LOG_PROPERTIES.AVG_TIMES_BTN_LOGINS
                },
            ],
            gridData: [],
            exportData: [],
            customerListOptions: [],
            applicationListOptions: [],
            categoryListOption: CATEGORY_LIST_OPTIONS,
            selectedApplication: [],
            selectedCustomer: { label: USAGE_LOG_LABELS.ALL, value: USAGE_LOG_PROPERTIES.ALL },
            selectedCategory: CATEGORY_LIST_OPTIONS,
            startDate: dateUtil.substractDate(30),
            endDate: new Date(),
            is_include_internal_users: false,
            is_include_inactive_customers: false,
            is_include_inactive_users: false,
            preLoader: true,
            isAlertBoxOpen: false,
            editFlag: false,
            isFormValid: true
        };
    }

    handleCategoryChange = categories => {
        if (!categories) {
            this.setState({
                customerListOptions: [],
                selectedCustomer: { label: USAGE_LOG_LABELS.ALL, value: USAGE_LOG_PROPERTIES.ALL }
            });
        } else {
            this.loadCustomerDropDown(categories);
        }
        this.setState({
            selectedCategory: categories,
            editFlag: true,
            isFormValid: true
        });
    }

    handleCustomerChange = customerData => {
        this.setState({
            selectedCustomer: customerData,
            editFlag: true
        });
    }

    handleApplicationChange = application => {
        this.setState({
            selectedApplication: application,
            editFlag: true,
            isFormValid: true
        });
    }

    handleStartDate = date => {
        this.setState({
            startDate: date,
            editFlag: true
        });
    };

    handleEndDate = date => {
        this.setState({
            endDate: date,
            editFlag: true
        });
    }

    handleToggleChange = e => {
        if (e.target.id === USAGE_LOG_PROPERTIES.IS_INCLUDE_INTERNAL_USERS) {
            this.setState({
                is_include_internal_users: e.target.checked,
                editFlag: true
            });
        }

        if (e.target.id === USAGE_LOG_PROPERTIES.IS_INCLUDE_INACTIVE_CUSTOMERS) {
            this.setState({
                is_include_inactive_customers: e.target.checked,
                editFlag: true
            });
        }

        if (e.target.id === USAGE_LOG_PROPERTIES.IS_INCLUDE_INACTIVE_USERS) {
            this.setState({
                is_include_inactive_users: e.target.checked,
                editFlag: true
            });
        }
    }

    isValidForm = () => {
        const { selectedCategory, selectedApplication } = this.state;
        if ((selectedCategory && selectedCategory.length > 0) && (selectedApplication != null && selectedApplication.length > 0)) {
            this.setState({
                isFormValid: true
            });
            return true;
        } else {
            this.setState({
                isFormValid: false
            });
            return false;
        }
    };

    handleSave = e => {
        e.preventDefault();

        let formValid = this.isValidForm();
        if (formValid) {
            this.setState({
                preLoader: true
            });

            const payload = this.generatePayload();

            this.props.actions.getCustomerUsersLogDetails(payload).then((response) => {
                const modifiedExportData = this.modifyExportData(response.data.data);

                if (response.status !== 200 && response.status !== 204) {
                    this.setState({
                        preLoader: false,
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isConfirmBox: false,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });
                } else if (response.status === 200) {
                    this.setState({
                        editFlag: false,
                        gridData: response.data.data.sort((dynamicSort(USAGE_LOG_PROPERTIES.FIRST_NAME))),
                        exportData: modifiedExportData.sort((dynamicSort(USAGE_LOG_PROPERTIES.FIRST_NAME))),
                        preLoader: false
                    });
                } else {
                    this.setState({
                        editFlag: false,
                        gridData: [],
                        exportData: [],
                        preLoader: false
                    });
                }
            });
        }
    }

    generatePayload = () => {
        const payload = { categories: [], log_source: [] };

        if (this.state.selectedCustomer.value !== USAGE_LOG_PROPERTIES.ALL) {
            payload.customer_id = this.state.selectedCustomer.value;
        }

        this.state.selectedApplication.forEach((oneApplication) => {
            payload.log_source.push(oneApplication.value);
        });

        this.state.selectedCategory.forEach((oneCategory) => {
            payload.categories.push(oneCategory.value);
        });

        if (this.state.is_include_internal_users) {
            payload.internal_users = this.state.is_include_internal_users;
        }
        if (this.state.is_include_inactive_customers) {
            payload.inactive_customer = this.state.is_include_inactive_customers;
        }
        if (this.state.is_include_inactive_users) {
            payload.inactive_users = this.state.is_include_inactive_users;
        }
        payload.start_date = moment(this.state.startDate).format(DATE_FORMAT.YYYYMMDD);
        payload.end_date = moment(this.state.endDate).format(DATE_FORMAT.YYYYMMDD);

        return payload;
    }

    alterDiscard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    loadApplicationDropDown = (thisProps) => {
        if (thisProps.applicationList) {
            const applicationList = [];

            thisProps.applicationList.forEach((appName) => {
                applicationList.push({ label: appName.display_name, value: appName.name });
            });
            applicationList.sort(dynamicSort("label"));

            this.setState({
                applicationListOptions: applicationList,
                selectedApplication: applicationList
            });
        }
    };

    loadCustomerDropDown = (categories) => {
        let filterdCustomers;

        if (!categories) {
            filterdCustomers = Object.values(this.props.allCustomers);
        } else {
            const selectedCategories = [];
            categories.forEach((singleCategory) => {
                selectedCategories.push(singleCategory.value);
            });

            if (selectedCategories.length === 1 && selectedCategories[0] === APP_CATEGORIES.GROUP) {
                filterdCustomers = Object.values(this.props.allCustomers).filter((oneCustomer) => {
                    return oneCustomer.corporate === APPLICATION_MODES.YES;
                });
            } else if (selectedCategories.includes(APP_CATEGORIES.GROUP)) {
                filterdCustomers = Object.values(this.props.allCustomers).filter((oneCustomer) => {
                    return selectedCategories.includes(oneCustomer.category) || oneCustomer.corporate === APPLICATION_MODES.YES;
                });
            } else {
                filterdCustomers = Object.values(this.props.allCustomers).filter((oneCustomer) => {
                    return selectedCategories.includes(oneCustomer.category);
                });
            }
        }
        let customerList = [];

        filterdCustomers.forEach((ele) => {
            customerList.push({ label: ele.name, value: ele.id });
        });

        customerList.sort(dynamicSort("label"));
        customerList.unshift({ label: USAGE_LOG_LABELS.ALL, value: USAGE_LOG_PROPERTIES.ALL });

        this.setState({
            customerListOptions: customerList,
            selectedCustomer: customerList[0]
        });
    };

    componentDidMount() {
        this.props.actions.fetchApplicationNames().then(() => {
            this.loadApplicationDropDown(this.props);
        });

        this.loadCustomerDropDown();

        const payload = {
            start_date: moment(this.state.startDate).format(DATE_FORMAT.YYYYMMDD),
            end_date: moment(this.state.endDate).format(DATE_FORMAT.YYYYMMDD),
            categories: []
        };

        this.state.selectedCategory.forEach((oneCategory) => {
            payload.categories.push(oneCategory.value);
        });

        this.props.actions.getCustomerUsersLogDetails(payload).then((response) => {
            const modifiedExportData = this.modifyExportData(response.data.data);

            if (response.status !== 200 && response.status !== 204) {
                this.setState({
                    preLoader: false,
                    editFlag: true,
                    shouldCloseModal: false,
                    isAlertBoxOpen: true,
                    isConfirmBox: false,
                    isSuccess: false,
                    alert_information: response.data ? response.data.message : response.message
                });
            } else if (response.status === 200) {
                this.setState({
                    gridData: response.data.data.sort((dynamicSort(USAGE_LOG_PROPERTIES.FIRST_NAME))),
                    exportData: modifiedExportData.sort((dynamicSort(USAGE_LOG_PROPERTIES.FIRST_NAME))),
                    preLoader: false
                });
            } else {
                this.setState({
                    gridData: [],
                    exportData: [],
                    preLoader: false
                });
            }
        });
    }

    modifyExportData = (data) => {
        let modifiedData = [];

        if (data) {
            data.forEach((ele) => {
                const oneObject = { ...ele };
                for (var i in oneObject) {
                    if (oneObject[i] === 0) {
                        oneObject[i] = "";
                    }
                }
                modifiedData.push(oneObject);
            });
        }
        return modifiedData;
    }

    handleDownloadHTML = () => {
        let payload = this.generatePayload();
        payload.report_format = REPORT_FORMAT.HTML;

        this.setState({
            preLoader: true
        });
        
        this.props.actions.getCustomerUsersLogDetails(payload).then((response) => {
            if (response.status !== 200 && response.status !== 204) {
                this.setState({
                    alert_information: "Error while generating the HTML files.",
                    preLoader: false
                });
            } else if (response.status === 200) {
                const htmlContent = response.data;

                const blob = new Blob([htmlContent], { type: 'text/html' });

                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'CustomerUsersUsage.html';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                URL.revokeObjectURL(url);

                this.setState({
                    preLoader: false
                });
            } else {
                this.setState({
                    preLoader: false
                });
            }
        }).catch(error => {
            this.setState({
                alert_information: error.message,
                preLoader: false
            });
        });
    }

    render() {
        return (
            <Fragment>
                <div className="page_heading">
                    <h5>
                        {USAGE_LOG_LABELS.CUSTOMER_USERS_USAGE_LOGS}
                    </h5>
                </div>
                <div>
                    <TatvamCol className="z-depth-2 centered mt-1 form-wrapper" s={12} m={12} l={12} xl={12}>
                        <Row>
                            <form className="multi_select_height">
                                <TatvamCol s={12} xl={12} className="formRow  mt-2">
                                    <TatvamCol m={2} xl={3} className="select-field">
                                        <label htmlFor="application">{USAGE_LOG_LABELS.CUSTOMER_CATEGORY}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className={"validate"}
                                            value={this.state.selectedCategory}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            name={USAGE_LOG_LABELS.CUSTOMER_CATEGORY}
                                            id="category"
                                            onChange={this.handleCategoryChange}
                                            options={this.state.categoryListOption}
                                        />
                                    </TatvamCol>
                                    <TatvamCol m={2} xl={3} className="select-field">
                                        <label htmlFor="customers">{USAGE_LOG_LABELS.CUSTOMER}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            value={this.state.selectedCustomer}
                                            name={USAGE_LOG_LABELS.CUSTOMER}
                                            id="customers"
                                            onChange={this.handleCustomerChange}
                                            options={this.state.customerListOptions}
                                        />
                                    </TatvamCol>
                                    <TatvamCol m={2} xl={3} className="select-field">
                                        <label htmlFor="application">{USAGE_LOG_LABELS.APPLICATION}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            value={this.state.selectedApplication}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            name={USAGE_LOG_LABELS.APPLICATION}
                                            id="application"
                                            onChange={this.handleApplicationChange}
                                            options={this.state.applicationListOptions}
                                        />
                                    </TatvamCol>
                                    <TatvamCol m={2} xl={3} className="select-field">
                                        <label className="status-label">{USAGE_LOG_LABELS.START_DATE}</label>
                                        <TatvamDatePicker
                                            selected={new Date(this.state.startDate)}
                                            id="StartDate"
                                            onChange={this.handleStartDate}
                                            maxDate={this.state.endDate && new Date(this.state.endDate)}
                                            dateFormat={DATE_FORMAT.ddMMMyyy}
                                            placeholderText={DATE_FORMAT.DDMMMYYYY}
                                            showMonthDropdown
                                            showYearDropdown
                                            className="mt-4"
                                        />
                                    </TatvamCol>
                                </TatvamCol>
                                <TatvamCol s={12} xl={12} className="formRow  mt-2">
                                    <TatvamCol m={2} xl={3} className="select-field">
                                        <label className="status-label">{USAGE_LOG_LABELS.END_DATE}</label>
                                        <TatvamDatePicker
                                            selected={new Date(this.state.endDate)}
                                            onChange={this.handleEndDate}
                                            minDate={this.state.startDate && new Date(this.state.startDate)}
                                            maxDate={new Date()}
                                            dateFormat={DATE_FORMAT.ddMMMyyy}
                                            validate
                                            placeholderText={DATE_FORMAT.DDMMMYYYY}
                                            showMonthDropdown
                                            showYearDropdown
                                            className="mt-4"
                                        />
                                    </TatvamCol>
                                    <TatvamCol s={2} m={4} xl={3} className="switch-wrapper p-0">
                                        <Switch
                                            id={USAGE_LOG_PROPERTIES.IS_INCLUDE_INACTIVE_CUSTOMERS}
                                            checked={this.state.is_include_inactive_customers}
                                            onChange={this.handleToggleChange}
                                            offLabel={USAGE_LOG_LABELS.INCLUDE_INACTIVE_CUSTOMERS}
                                            m={2}
                                        />
                                    </TatvamCol>
                                    <TatvamCol s={2} m={4} xl={3} className="switch-wrapper p-0">
                                        <Switch
                                            id={USAGE_LOG_PROPERTIES.IS_INCLUDE_INTERNAL_USERS}
                                            checked={this.state.is_include_internal_users}
                                            onChange={this.handleToggleChange}
                                            offLabel={USAGE_LOG_LABELS.INCLUDE_INTERNAL_USERS}
                                            m={2}
                                        />
                                    </TatvamCol>
                                    <TatvamCol s={2} m={4} xl={3} className="switch-wrapper p-0">
                                        <Switch
                                            id={USAGE_LOG_PROPERTIES.IS_INCLUDE_INACTIVE_USERS}
                                            checked={this.state.is_include_inactive_users}
                                            onChange={this.handleToggleChange}
                                            offLabel={USAGE_LOG_LABELS.INCLUDE_INACTIVE_USERS}
                                            m={2}
                                        />
                                    </TatvamCol>
                                    {!this.state.isFormValid &&
                                        <p className="errorText col s12"> Please select minimun one category and
                                            application</p>}
                                    <TatvamCol s={12} m={6} xl={3}
                                        className="buttonWrapper right valign-wrapper formRow">
                                        <TatvamButton
                                            waves="light"
                                            className=" mt-1 mr-1 btn_primary  "
                                            onClick={this.handleSave}
                                            disabled={!this.state.editFlag}
                                        >
                                            {USAGE_LOG_LABELS.SUBMIT}
                                        </TatvamButton>
                                    </TatvamCol>
                                </TatvamCol>
                            </form>
                        </Row>
                        <Col
                            s={12}
                            className={this.state.preLoader ? "valign-wrapper loader-overlay" : "hide"}
                        >
                            {this.state.preLoader && <Loader type="ball-beat" />}
                        </Col>
                    </TatvamCol>
                </div>
                <div>
                    <TatvamCol xl={12} s={12} m={12} className="row mt-1 z-depth-4">
                        {this.state.exportData.length > 0 && (
                            <Fragment>
                                <CsvExport
                                    columns={this.state.exportColumns}
                                    datas={this.state.exportData}
                                    csvHeader={populateCsvHeader(
                                        this.state.selectedCustomer.label,
                                        false,
                                        true,
                                        this.state.startDate,
                                        this.state.endDate
                                    )}
                                    csvHeaderLineBreakCount={1}
                                    delimeter=","
                                    downloadFileName={`${USAGE_LOG_LABELS.CUSTOMER_USERS_USAGE_LOGS}.csv`.split(' ').join('')}
                                />
                                <Col
                                    s={12}
                                    className={this.state.preLoader ? "valign-wrapper loader-overlay" : "hide"}
                                >
                                    {this.state.preLoader && <Loader type="ball-beat" />}
                                </Col>
                                <img
                                    onClick={this.handleDownloadHTML}
                                    className="table-html-icon"
                                    title="Download HTML"
                                    src={require("../../../../public/assets/images/html.jpg")}
                                    alt={"Download HTML"}>
                                </img>
                            </Fragment>
                        )}
                        <TatvamGrid
                            gridData={this.state.gridData}
                            colData={this.state.colDef}
                            enablePagination
                        />
                    </TatvamCol>
                </div>
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this.alterDiscard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default CustomerUsersUsageLogs;
