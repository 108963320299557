import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Property from "./property";
import {deleteCustomerProperty, fetchCompetitorTenantCustomers} from "../../../../../actions";

const mapStateToProps = (state, ownProps) => ({
    gridData: state.customer.list[ownProps.currentCustomerId].children,
    customerList: state.customer.list,
    propertyPermission: state.auth.permissions && state.auth.permissions.customer.property.create,
    competitorTenantCustomerList: state.customer.competitorTenantCustomers,
    propertyDelete: state.auth.permissions && state.auth.permissions.customer.property.delete,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        deleteCustomerProperty,
        fetchCompetitorTenantCustomers
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
