import './customerDetails.scss';

import React, {Component, Fragment,} from 'react';

import {Col, Preloader, Row,} from 'react-materialize';

import AlertBox from '../../components/applicationComponents/alertBox';
import CustomerForm from '../../components/applicationComponents/customerForm';
import TabsProject from '../../components/applicationComponents/tabsProject';
import {TatvamButton} from '../../components/functionalComponents/index';
import {ALERT_MESSAGES} from '../../constants/appConstants';
import {APPLICATION_MODES} from '../../constants/localConstants';

class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCustomer: {},
            preloader: false,
            applicationMode: "",
            customerFormMode: "",
            selectedApplication: {
                isInapp: true,
                isPredicto: true
            }
        };
    }

    _handleAppMode = (value) => {
        this.setState({
            applicationMode: value
        });
    };
    setId = (currentCustomer) => {
        this.setState({
            currentCustomer,
            applicationMode: APPLICATION_MODES.VIEW
        }, () => {
        });
    };

    setEditFlag = (statusBool) => {
        this.setState({editFlag: statusBool});
    };
    _goBack = () => {

        if (this.state.editFlag) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                notDiscard: false

            });
        } else {
            if (this.props.location.state && !this.props.location.state.isGroupInstance && this.props.location.state.fromCategoryPage !== "") {
                if (this.props.location.state.fromCategoryPage === "Competitor") {
                    this.props.history.push({
                        pathname: "/" + (this.props.location.state.fromCategoryPage).toLowerCase() + "s",
                    });
                } else if (this.props.location.state.fromCategoryPage === "Tenants") {
                    this.props.history.push({
                        pathname: "/" + (this.props.location.state.fromCategoryPage).toLowerCase(),
                    });
                } else {
                    this.props.history.push({
                        pathname: "/" + this.props.location.state.fromCategoryPage,
                    });
                }

            } else if (this.props.location.state && this.props.location.state.isGroupInstance) {
                this.props.history.push({
                    pathname: "/groupinstances",
                });
            } else if (this.props.location.state && !this.props.location.state.isGroupInstance && this.props.location.state.fromCategoryPage === "") {
                this.props.history.push({
                    pathname: "/inactive-customers",
                });
            } else {
                this.props.history.push({
                    pathname: "/dashboard",
                });
            }

        }

    };
    _discard = data => {
        if (data === "ok") {
            this.props.history.goBack();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    selectedApplication = data => {
        this.setState({selectedApplication: {...data}})
    }

    currentCustomerFormState = (currentState) => {
        this.setState({
            customerFormMode: currentState
        })
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            this.props.actions.fetchCustomerDetailsById(this.props.location.state.id).then(() => {

                this.props.actions.fetchCustomerParent(this.props.location.state.id).then(() => {
                    const currentCustomer = this.props.customerList;
                    if (this.props.customerList && this.props.customerList.valid_from && this.props.customerList.valid_upto) {
                        let dateFrom = new Date(this.props.customerList.valid_from).toDateString().replace('-', '').split(" ");
                        let dateUpto = new Date(this.props.customerList.valid_upto).toDateString().replace('-', '').split(" ");
                        let valid_from = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[3];
                        let valid_upto = dateUpto[2] + "-" + dateUpto[1] + "-" + dateUpto[3];
                        currentCustomer.valid_from = valid_from;
                        currentCustomer.valid_upto = valid_upto;
                    }
                    this.setState({
                        currentCustomer,
                        ...currentCustomer
                    });

                });
            });
            this.setState({
                applicationMode: this.props.location.state.applicationMode
            });
        } else {
            this.setState({
                applicationMode: APPLICATION_MODES.CREATE
            });
        }
    }

    componentWillReceiveProps(props) {
        if (props.location.state !== undefined) {
            if (props.location.state.id) {
                if (props.location.state.id !== this.state.currentCustomer.id)
                    this.props.actions.fetchCustomerParent(props.location.state.id);
            }
        }
        if (props.customerList) {
            let customer = props.customerList;

            if (props.customerList.valid_from && props.customerList.valid_upto) {
                let dateFrom = new Date(props.customerList.valid_from).toDateString().replace('-', '').split(" ");
                let dateUpto = new Date(props.customerList.valid_upto).toDateString().replace('-', '').split(" ");
                let valid_from = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[3];
                let valid_upto = dateUpto[2] + "-" + dateUpto[1] + "-" + dateUpto[3];
                customer.valid_from = valid_from;
                customer.valid_upto = valid_upto;
            }

            this.setState({
                    currentCustomer: customer
                },
                () => {

                });

        }

    }

    render() {
        return (
            <Fragment>

                <Row className="create-project-page">
                    <Row>
                        <Col
                            s={12}
                            className={this.state.preloader ? "valign-wrapper leftzero loader-overlay-view" : "hide"}
                        >
                            <Preloader className="spinner" size="big" active={this.state.preloader}/>
                        </Col>
                        <Col className="right">
                            <TatvamButton
                                waves="light"
                                onClick={this._goBack}
                                className="goBackBtn btn_primary"
                                title="go back "
                            >
                                <i className="material-icons">arrow_back</i>
                            </TatvamButton>
                        </Col>
                        <CustomerForm
                            applicationMode={this.state.applicationMode}
                            currentCustomer={this.state.currentCustomer}
                            setId={this.setId}
                            history={this.props.history}
                            location={this.props.location}
                            currentCustomerFormState={this.currentCustomerFormState}
                            editFlag={this.setEditFlag}
                            selectedApplication={this.selectedApplication}
                        />
                    </Row>
                    {this.state.currentCustomer && this.state.currentCustomer.id && (
                        <Row>
                            <Col className="z-depth-2 col-centered mt-3 mb-3 p-0 tabsContainer" s={12} m={12} l={12}
                                 xl={12}>
                                <div className={this.state.customerFormMode === "EDIT" ? "disabledTabs" : ""}></div>
                                <TabsProject
                                    currentCustomer={this.state.currentCustomer}
                                    currentCustomerId={this.state.currentCustomer.id}
                                    applicationMode={this.state.applicationMode}
                                    history={this.props.history}
                                    selectedApplication={this.state.selectedApplication}
                                />
                            </Col>
                        </Row>
                    )}
                </Row>
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        isConfirmBox={this.state.isConfirmBox}
                        closeAlertBox={this._discard}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default CustomerDetails;

