import './customerDimensionValueForm.scss';

import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    PROPERTY_DIMENSION,
    PROPERTY_DIMENSION_VALUE,
    SWITCH_TEXT,
    TEXT_VALIDATION,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {trimCapitalize, validatePattern, validateSequenceNumber,} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerDimensionValueForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            selectedParentType: [],
            parentEntityTypeListsOptions: [],
            entityTypeListOptions: [],
            selectedEntityType: [],
            selectedEntityValue: [],
            entityTypeValueOptions: [],
            applicationMode: props.applicationMode,
            preLoader: false,
            status: APPLICATION_MODES.INACTIVE,
            entity_value: "",
            parent_entity_value: "",
            sequence_no: "",
            isActive: false,
            text_pattern: "",
            isValid: true,
            editFlag: false,
            parent_entity: "",
            validEntityTypeValue: true,
            validEntityParentTypeValue: true,
            validPattern: true,
            validSequenceNumber: true,
            valid: true,
            isPatternFieldMandatory: false,
            isParentDimensionMandatory: false,
            tagTreatmentType: "",
            isPublic: false,
            is_public: APPLICATION_MODES.NO,
        };
    }

    fetchCustomerDimensions = () => {
        this.props.actions.getCustomerDimensions(this.props.customerId).then(() => {
            let dimensionsGridData = [];
            if (this.props.customerDimensions) {
                this.props.customerDimensions.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            dimensionsGridData.push(data);
                        }
                    }
                });

                this.setState({
                    dimensionsGridData
                });
            }
        });
    }

    _isPatternValidationRequired = (entityType) => {
        let retData = {
            isPatternFieldMandatory: false,
            attributeForTagging: ""
        };

        if (entityType !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY) {

            if (this.props.customerDimensions) {
                for (let data of Object.values(this.props.customerDimensions)) {
                    if (data.entity_type === entityType) {
                        if (data.is_tagging === APPLICATION_MODES.YES) {
                            retData.isPatternFieldMandatory = true;
                            retData.attributeForTagging = data.attribute_for_tagging;
                            return retData;
                        } else {
                            retData.isPatternFieldMandatory = false;
                            retData.attributeForTagging = data.attribute_for_tagging;
                            return retData;
                        }
                    }
                }
            }
        } else {
            return retData;
        }
    }

    componentDidMount() {
        this.fetchCustomerDimensions();
        if (this.props.customerDimensions) {
            this.initDimensionValues(this.props);
        }

        if (!Array.isArray(this.props.dimensionValueData)) {
            let selectedParentType = this.props.dimensionValueData.parent_entity_type ? {
                label: this.props.dimensionValueData.parent_entity_type,
                value: this.props.dimensionValueData.parent_entity_type
            } : {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityType = this.props.dimensionValueData.entity_type ? {
                label: this.props.dimensionValueData.entity_type,
                value: this.props.dimensionValueData.entity_type
            } : {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityValue = this.props.dimensionValueData.parent_entity_value ? {
                label: this.props.dimensionValueData.parent_entity_value,
                value: this.props.dimensionValueData.parent_entity_value
            } : {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE, value: ""};
            let tagTreatmentType = "";
            if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
                if (this.props.dimensionValueData.parent_entity_type) {
                    this._handleParentDimensionValue(this.props.dimensionValueData.parent_entity_type);
                }
                tagTreatmentType = this._getTagTreatmentByEntityType(this.props, this.props.dimensionValueData.entity_type);


            }

            this.setState({
                ...this.props.dimensionValueData,
                selectedParentType,
                selectedEntityType,
                selectedEntityValue,
                tagTreatmentType
            });
        } else {
            let selectedParentType = {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityType = {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityValue = {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE, value: ""};

            this.setState({
                ...this.props.dimensionValueData,
                selectedParentType,
                selectedEntityType,
                selectedEntityValue
            });
        }

        this.setState({
            applicationMode: this.props.applicationMode,
            status: this.props.dimensionValueData && trimCapitalize(this.props.dimensionValueData.status) ? this.props.dimensionValueData.status : APPLICATION_MODES.INACTIVE,
            isActive: this.props.dimensionValueData && this.props.dimensionValueData.status ? trimCapitalize(this.props.dimensionValueData.status) === APPLICATION_MODES.ACTIVE : false,
            is_public: this.props.dimensionValueData && trimCapitalize(this.props.dimensionValueData.is_public) ? this.props.dimensionValueData.is_public : APPLICATION_MODES.NO,
            isPublic: this.props.dimensionValueData.is_public ? trimCapitalize(this.props.dimensionValueData.is_public) === APPLICATION_MODES.YES : false,
        });

    }

    componentWillReceiveProps(props) {

        if (props.customerDimensions) {
            this.initDimensionValues(props);
        }

        if (!Array.isArray(props.dimensionValueData)) {
            let selectedParentType = props.dimensionValueData.parent_entity_type ? {
                label: props.dimensionValueData.parent_entity_type,
                value: props.dimensionValueData.parent_entity_type
            } : {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityType = props.dimensionValueData.entity_type ? {
                label: props.dimensionValueData.entity_type,
                value: props.dimensionValueData.entity_type
            } : {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityValue = props.dimensionValueData.parent_entity_value ? {
                label: props.dimensionValueData.parent_entity_value,
                value: props.dimensionValueData.parent_entity_value
            } : {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE, value: ""};
            let tagTreatmentType = "";
            if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
                if (props.dimensionValueData.parent_entity_type) {
                    this._handleParentDimensionValue(props.dimensionValueData.parent_entity_type);
                }
                tagTreatmentType = this._getTagTreatmentByEntityType(props, props.dimensionValueData.entity_type);

            }


            this.setState({
                ...props.dimensionValueData,
                selectedParentType,
                selectedEntityType,
                selectedEntityValue,
                tagTreatmentType
            });
        } else {
            let selectedEntityType = {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedParentType = {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY, value: ""};
            let selectedEntityValue = {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE, value: ""};


            this.setState({
                ...props.dimensionValueData,
                selectedParentType,
                selectedEntityType,
                selectedEntityValue
            });
        }


        this.setState({
            applicationMode: props.applicationMode,
            status: props.dimensionValueData && trimCapitalize(props.dimensionValueData.status) ? props.dimensionValueData.status : APPLICATION_MODES.INACTIVE,
            isActive: props.dimensionValueData && props.dimensionValueData.status ? trimCapitalize(props.dimensionValueData.status) === APPLICATION_MODES.ACTIVE : false,
            is_public: this.props.dimensionValueData && trimCapitalize(this.props.dimensionValueData.is_public) ? this.props.dimensionValueData.is_public : APPLICATION_MODES.NO,
            isPublic: this.props.dimensionValueData.is_public ? trimCapitalize(this.props.dimensionValueData.is_public) === APPLICATION_MODES.YES : false,
        });
    }

    initDimensionValues = (thisProps) => {
        const parentDimensionLst = [];
        parentDimensionLst.push({label: "", value: ""});
        for (let data of Object.values(thisProps.customerDimensions)) {
            if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE && (data.tag_treatment === APPLICATION_MODES.BY_PATTERN || data.tag_treatment === APPLICATION_MODES.BY_USER)) {
                parentDimensionLst.push({label: data.entity_type, value: data.entity_type});
            }
        }
        parentDimensionLst.sort(dynamicSort("label"));
        parentDimensionLst[0].label = PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY;

        this.setState({
            parentEntityTypeListsOptions: parentDimensionLst,
            entityTypeListOptions: parentDimensionLst
        });
    };

    _getTagTreatmentValue = (thisProps) => {
        const dimensionVal = thisProps.customerDimensions.filter((property) => {
            return property.entity_type === this.props.dimensionValueData.entity_type;
        });
        return dimensionVal.length > 0 ? dimensionVal[0].tag_treatment : "";
    };

    _getTagTreatmentByEntityType = (thisProps, value) => {
        let dimensionVal = [];
        if (thisProps.customerDimensions) {
            dimensionVal = thisProps.customerDimensions.filter((dimension) => {
                return dimension.entity_type === value;
            });
        }
        return dimensionVal.length > 0 ? dimensionVal[0].tag_treatment : "";
    };

    _handleSave = e => {
        e.preventDefault();
        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            }
            if (response) {
                if (this.state.isValid && this.state.validEntityTypeValue && this.state.validEntityParentTypeValue && this.state.validPattern && this.state.validSequenceNumber) {

                    const dimensionValue = {
                        entity_type: this.state.selectedEntityType.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY ? this.state.selectedEntityType.label : "",
                        entity_value: this.state.entity_value.charAt(0).toUpperCase() + this.state.entity_value.slice(1),
                        parent_entity_type: this.state.selectedParentType.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY ? this.state.selectedParentType.label : "",
                        parent_entity_value: this.state.selectedEntityValue.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE ? this.state.selectedEntityValue.label : "",
                        sequence_no: $.trim(this.state.sequence_no) !== "" ? parseInt(this.state.sequence_no) : 0,
                        status: this.state.status,
                        is_public: this.state.is_public,
                        text_pattern: this.state.text_pattern
                    };

                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        let dimensionValueData = {
                            ...dimensionValue,
                            customer_id: this.props.customerId,
                            created_by: this.props.userName
                        };

                        this.props.actions.createCustomerDimensionValue(dimensionValueData).then((responseCustomerDimensionValue) => {
                            this.setState({preLoader: false});
                            if (responseCustomerDimensionValue && responseCustomerDimensionValue.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseCustomerDimensionValue.data && responseCustomerDimensionValue.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseCustomerDimensionValue.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseCustomerDimensionValue.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseCustomerDimensionValue.data.message,
                                });
                            }
                        });
                    } else {
                        let dimensionData = {
                            ...dimensionValue,
                            customer_id: this.props.customerId,
                            updated_by: this.props.userName
                        };
                        this.props.actions.updateCustomerDimensionValue(dimensionData, this.props.dimensionValueData._id).then((responseUpdateCustomerDimensionValue) => {
                            this.setState({preLoader: false});
                            if (responseUpdateCustomerDimensionValue && responseUpdateCustomerDimensionValue.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseUpdateCustomerDimensionValue.data && responseUpdateCustomerDimensionValue.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseUpdateCustomerDimensionValue.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseUpdateCustomerDimensionValue.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseUpdateCustomerDimensionValue.data.message,
                                    applicationMode: APPLICATION_MODES.EDIT
                                });
                            }
                        });
                    }

                }
            }
        });
    }

    _validations = () => {
        let isValidEntityTypeValue = true;
        let isValidParentEntityTypeValue = true;
        let mandatoryFieldsValid = true;
        let patternFieldValid = true;
        let sequenceNoFieldValid = true;
        if (this.state.selectedEntityType.label === PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY || !this.state.entity_value || (this.state.selectedParentType.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY && this.state.selectedEntityValue.label === PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE)) {
            mandatoryFieldsValid = false;
        } else if (this.state.selectedEntityType.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY && this.state.selectedParentType.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY && this.state.selectedEntityValue.label === PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE) {
            mandatoryFieldsValid = false;
        } else if (this.state.tagTreatmentType === "By Pattern" && !this.state.text_pattern) {
            mandatoryFieldsValid = false;
        } else {
            if (this.state.sequence_no) {
                sequenceNoFieldValid = validateSequenceNumber(this.state.sequence_no.toString());
            }

            if (this.state.text_pattern) {
                patternFieldValid = validatePattern(this.state.text_pattern);
            }
        }

        this.setState({
            isValid: mandatoryFieldsValid,
            validEntityTypeValue: isValidEntityTypeValue,
            validEntityParentTypeValue: isValidParentEntityTypeValue,
            validPattern: patternFieldValid,
            validSequenceNumber: sequenceNoFieldValid
        });

        return Promise.resolve(true);
    }

    _handleInput = e => {
        if (e.target.id === "dimensionValueStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status: status,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else if (e.target.id === "is_public") {
            const publicStatus = e.target.checked ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            this.setState({
                isPublic: e.target.checked,
                is_public: publicStatus,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else {

            if (e.target.id === "sequence_no") {
                if (e.target.value !== "" && !Number(e.target.value)) {
                    return;
                }
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _handleParentEntityTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                parent_entity_type: true
            },
            selectedParentType: selectedOption
        });

        this.setState({
            isParentDimensionMandatory: selectedOption.label !== PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY
        });

        this._handleParentDimensionValue(selectedOption.label);
        this.setState({
            selectedEntityValue: {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE, value: ""}
        });

    };

    _handleParentDimensionValue = selectedDimension => {
        if (this.props.dimensionValueList) {

            const dimensionValueLst = [];
            dimensionValueLst.push({label: "", value: ""});
            for (let data of Object.values(this.props.dimensionValueList)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE && data.entity_type === selectedDimension) {
                    dimensionValueLst.push({label: data.entity_value, value: data.entity_value});
                }
            }
            dimensionValueLst.sort(dynamicSort("label"));
            dimensionValueLst[0].label = PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE;

            this.setState({
                entityTypeValueOptions: dimensionValueLst
            });

        }

    };

    _handleEntityTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                entity_type: true
            },
            selectedEntityType: selectedOption,
            tagTreatmentType: this._getTagTreatmentByEntityType(this.props, selectedOption.label)
        });
    };

    _handleEntityValueSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                entity_value: true
            },
            selectedEntityValue: selectedOption
        });
    };

    _loadParentEntityType = selectedOption => {
        if (this.props.customerDimensions) {
            const dimensionLst = [];
            dimensionLst.push({label: "", value: ""});
            if (selectedOption.value !== "") {
                const parentEntity = this.props.customerDimensions.filter((item) => {
                    return item.entity_type === selectedOption.label
                });
                if (parentEntity && parentEntity[0].parent_entity !== "") {
                    for (let data of Object.values(this.props.customerDimensions)) {
                        if (data.entity_type === parentEntity[0].parent_entity) {
                            dimensionLst.push({label: data.entity_type, value: data.entity_type});
                        }
                    }
                    dimensionLst.sort(dynamicSort("label"));
                }
            }
            dimensionLst[0].label = PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE;
            this.setState({
                parentEntityTypeListsOptions: dimensionLst,
                selectedParentType: {label: PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE, value: ""}
            });
        }
    }

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    selectedEntityType: [],
                    selectedParentType: []

                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{PROPERTY_DIMENSION_VALUE.VIEW_DIMENSION_VALUE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{PROPERTY_DIMENSION_VALUE.EDIT_DIMENSION_VALUE}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="dimensionForm">
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12}>
                            <TatvamCol s={12} m={6} xl={6}>
                                <label htmlFor="entity_type">{PROPERTY_DIMENSION_VALUE.ENTITY_TYPE}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.selectedEntityType.value === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedEntityType}
                                    name="entity_type"
                                    id="entity_type"
                                    onChange={this._handleEntityTypeSelect}
                                    options={this.state.entityTypeListOptions}
                                    placeholder={PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY}
                                />
                            </TatvamCol>
                            <TatvamCol s={12} m={6} xl={6} className="mb-2">
                                {<label htmlFor="entity_value">{PROPERTY_DIMENSION_VALUE.ENTITY_TYPE_VALUE}<span
                                    style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    required
                                    validate
                                    className={(this.state.inValidForm && !this.state.entity_value) ? "labelText mb-0 validate captalize invalid" : "labelText mb-0 validate captalize"}
                                    s={12}
                                    id="entity_value"
                                    name="entity_value"
                                    onChange={this._handleInput}
                                    value={this.state.entity_value}
                                    autocomplete="off"
                                />
                            </TatvamCol>
                            <TatvamCol m={12} xl={12} className="mb-2">
                                <label
                                    htmlFor="text_pattern">{this.state.tagTreatmentType === "By Pattern" ? PROPERTY_DIMENSION_VALUE.PATTERN_REQUIRED : PROPERTY_DIMENSION_VALUE.PATTERN}{this.state.tagTreatmentType === "By Pattern" &&
                                    <span style={{color: 'red'}}> *</span>}</label>
                                <TatvamTextArea
                                    m={12}
                                    id="text_pattern"
                                    name="text_pattern"
                                    onChange={this._handleInput}
                                    className={(this.state.inValidForm && this.state.tagTreatmentType === "By Pattern" && !this.state.text_pattern) ? "labelText mb-0 validate captalize invalid" : "labelText mb-0 validate captalize valid"}
                                    value={this.state.text_pattern}
                                    placeholder="Please specify more keywords..."
                                />
                            </TatvamCol>

                            <TatvamCol s={12} m={6} xl={6} className="mb-2 ">
                                <label
                                    htmlFor="parent_entity_type">{PROPERTY_DIMENSION_VALUE.PARENT_ENTITY_TYPE}</label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className="validate select-ht "
                                    value={this.state.selectedParentType}
                                    name="parent_entity_type"
                                    id="parent_entity_type"
                                    onChange={this._handleParentEntityTypeSelect}
                                    options={this.state.parentEntityTypeListsOptions}
                                    placeholder={PROPERTY_DIMENSION.SELECT_PARENT_ENTITY}
                                />
                            </TatvamCol>
                            <TatvamCol s={12} m={6} xl={6} className="mb-2 ">
                                {<label
                                    htmlFor="parent_entity_value">{this.state.isParentDimensionMandatory ? PROPERTY_DIMENSION_VALUE.PARENT_ENTITY_TYPE_VALUE_REQUIRED : PROPERTY_DIMENSION_VALUE.PARENT_ENTITY_TYPE_VALUE}{this.state.isParentDimensionMandatory &&
                                    <span style={{color: 'red'}}> *</span>}</label>}
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.isParentDimensionMandatory && this.state.selectedEntityValue.label === PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY_VALUE) ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedEntityValue}
                                    name="parent_entity_value"
                                    id="parent_entity_value"
                                    onChange={this._handleEntityValueSelect}
                                    options={this.state.entityTypeValueOptions}
                                />
                            </TatvamCol>
                            <TatvamCol s={12} className="p-0">
                                <TatvamCol s={12} m={4} xl={4} className="mb-2 seqInput">
                                    <label htmlFor="sequence_no">{PROPERTY_DIMENSION_VALUE.SEQUENCE_NO}</label>
                                    <TatvamTextBox
                                        m={12}
                                        id="sequence_no"
                                        name="sequence_no"
                                        onChange={this._handleInput}
                                        className="labelText mb-0 validate txt-no-h"
                                        value={this.state.sequence_no.toString()}
                                        autocomplete="off"
                                        maxlength={3}
                                        title={TEXT_VALIDATION.SEQ_NO}
                                    />
                                </TatvamCol>
                                <TatvamCol s={12} m={4} xl={4} className="switch-wrapper">
                                    <Switch
                                        id="dimensionValueStatus"
                                        offLabel={PROPERTY_DIMENSION_VALUE.STATUS}
                                        checked={this.state.isActive}
                                        onClick={this._handleInput}
                                        s={12}
                                    />
                                </TatvamCol>
                                <TatvamCol s={12} m={4} xl={4} className="mb-2 switch-wrapper">
                                    <Switch
                                        id="is_public"
                                        name="is_public"
                                        offLabel="Is Public"
                                        checked={this.state.isPublic}
                                        onClick={this._handleInput}
                                        s={12}
                                    />
                                </TatvamCol>
                            </TatvamCol>
                        </TatvamCol>

                    </Row>
                ) : (
                    <Row>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s4 url-wrap"> {PROPERTY_DIMENSION_VALUE.VIEW_ENTITY_TYPE} : </span>
                                <span className="col s8 url-wrap"> {this.state.entity_type}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s4">{PROPERTY_DIMENSION_VALUE.VIEW_ENTITY_TYPE_VALUE} :</span>
                                <span className="col s8 url-wrap">{this.state.entity_value}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} className="mt-.75">
                            {this.state.isPatternFieldMandatory && (
                                <div className="mt-0 col s12 p-0">
                                    <span
                                        className="property-label patternlabel col s2"> {PROPERTY_DIMENSION_VALUE.VIEW_PATTERN} :</span>
                                    <TatvamTextArea
                                        className="labelText mb-0 validate"
                                        s={10}
                                        pattern="^[a-zA-Z0-9_-]{5,25}$"
                                        id="pattern_view"
                                        name="pattern_view"
                                        onChange={this._handleInput}
                                        value={this.state.text_pattern}
                                    />
                                </div>
                            )}
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s4"> {PROPERTY_DIMENSION_VALUE.VIEW_PARENT_ENTITY_TYPE} :</span>
                                <span className="col s8 url-wrap">  {this.state.parent_entity_type}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s4"> {PROPERTY_DIMENSION_VALUE.VIEW_PARENT_ENTITY_TYPE_VALUE} :</span>
                                <span className="col s8 url-wrap">  {this.state.parent_entity_value}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span className="property-label col s4"> {PROPERTY_DIMENSION.VIEW_SEQUENCE_NO} :</span>
                                <span className="col s8">{this.state.sequence_no.toString()}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s4 url-wrap">{PROPERTY_DIMENSION_VALUE.STATUS} :</span>
                                <span
                                    className="col s6">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="mt-.75">
                            <div className="mt-0 col s12">
                                <span
                                    className="property-label col s4 url-wrap"> {PROPERTY_DIMENSION_VALUE.IS_PUBLIC} :</span>
                                <span className="col s6">{this.state.isPublic ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                            </div>
                        </TatvamCol>
                        {this.state.attrForTagging && (
                            <TatvamCol s={12} m={12} className="mt-.75">
                                <div className="mt-0 col s12 p-0">
                                    <span
                                        className="property-label col s2"> {PROPERTY_DIMENSION.VIEW_ATTRIBUTE_FOR_TAGGING} :</span>
                                    <span className="col s10">{this.state.attrForTagging}</span>
                                </div>
                            </TatvamCol>
                        )}

                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                    <TatvamCol s={12} m={8}>
                        {!this.state.isValid && (
                            <p className="errorText"></p>
                        )}
                        {!this.state.validEntityTypeValue && (
                            <p className="errorText">{VALIDATION_MESSAGES.ENTITY_NAME_VALUE_INVALID}</p>
                        )}
                        {!this.state.validEntityParentTypeValue && (

                            <p className="errorText">{VALIDATION_MESSAGES.PARENT_ENTITY_NAME_VALUE_INVALID}</p>
                        )}

                        {!this.state.validPattern && (
                            <p className="errorText">{VALIDATION_MESSAGES.PATTERN_INVALID}</p>
                        )}

                        {!this.state.validSequenceNumber && (
                            <p className="errorText">{VALIDATION_MESSAGES.SEQUENCE_NO_INVALID}</p>
                        )}

                    </TatvamCol>
                    <TatvamCol s={12} m={4} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                            this.props.dimensionPermission === APP_BOOLEANS.TRUE && (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={!(this.state.tagTreatmentType === APPLICATION_MODES.BY_PATTERN || this.state.tagTreatmentType === APPLICATION_MODES.BY_USER)}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            )}
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default CustomerDimensionValueForm;
