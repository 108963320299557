import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {deleteCustomer,deleteTenant} from '../../../actions';
import CustomerList from './customerList';

const mapStateToProps = (state, ownProps) => (
    {
        allCustomers: state.customer.list,
        customerDelete: state.auth.permissions && state.auth.permissions.customer.delete
    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            deleteCustomer,
            deleteTenant
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
