import './customerReportSubscriptionForm.scss';

import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import {
    ALERT_MESSAGES,
    FORM_OPERATIONS,
    PROPERTY_REPORT_SUBSCRIPTION,
    SWITCH_TEXT,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import {APPLICATION_MODES} from '../../../constants/localConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {
    TatvamButton,
    TatvamCol,
    TatvamGrid,
    TatvamModal,
    TatvamMultiSelect,
    TatvamTextBox,
} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import SubscriptionFilter from '../reportSubscriptionFIlter/resportSubscriptionFilter';

class CustomerReportSubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationMode: props.applicationMode,
            colDef: [
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.FIRST_NAME,
                    field: "first_name",
                    filterParams: {clearButton: true},
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    cellRenderer: function (param) {
                        param.node.setSelected(param.data.isSelected);
                        return param.value;
                    }
                },
                {
                    headerName: PROPERTY_REPORT_SUBSCRIPTION.LAST_NAME,
                    field: "last_name",
                    filterParams: {clearButton: true}
                },
                {headerName: PROPERTY_REPORT_SUBSCRIPTION.EMAIL, field: "email", filterParams: {clearButton: true}},
            ],
            recipientsGridData: [],
            reportPeriodicity: "",
            status: PROPERTY_REPORT_SUBSCRIPTION.ACTIVE,
            deliveryFormat: PROPERTY_REPORT_SUBSCRIPTION.PDF,
            enableSubscriptionProperty: false,
            subscriptionProperty: "",
            subscriptionPropertyOptions: [],
            loader: true,
            subscriptionReport: "",
            subscriptionReportsOptions: [],
            subscriptionDataCoverageUnit: "",
            subscriptionDataCoverageUnitListOptions: [],
            subscriptionName: "",
            dataCoverageUnits: '',
            editFlag: false,
            isValid: true,
            validDataCoverageUnit: true,
            checkedRows: [],
            validRecipients: true,
            shouldCloseModal: false,
            currentCustomerProperty: [],
            filterDimensions: "",
            applicationListOptions: [],
            selectedApplicationName: []
        };
        this.subscriptionDataCoverageUnitList = ['Day(s)', 'Week(s)', 'Month(s)', 'Quarter(s)'];
    }

    _handleAppMode = () => {
        this.setState({applicationMode: APPLICATION_MODES.EDIT});
    };

    _triggerDiscard = () => {
        if (Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST
            });

        } else {
            this.props.closeModal();
        }
    };

    _discard = (data) => {
        if (data === "ok") {
            this.props.closeModal();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _getSelectedRows = (selectedRows, gridApi) => {
        this.setState({
            checkedRows: selectedRows,
            gridApi: gridApi,
            editFlag: {
                ...this.state.editFlag,
                checkedRows: true
            },
        })
    }

    _linkSubscriptionDataCoverageUnitList = () => {
        const dataCoverageUnitLst = [];
        if (this.subscriptionDataCoverageUnitList) {
            this.subscriptionDataCoverageUnitList.map((data) => {
                dataCoverageUnitLst.push({label: data, value: data});
            });
        }
        this.setState({
            subscriptionDataCoverageUnitListOptions: dataCoverageUnitLst,
            preLoader: false
        });
    };

    _setSubscriptionReports = (thisProps) => {
        const apiSubscriptionReports = Object.entries(thisProps.subscriptionReports)
            .map(([key, value]) => value.application_name === this.state.selectedApplicationName.label)
            .map(type => ({label: type.name, value: type.id, subscription_template: type.subscription_template}))

        if (apiSubscriptionReports.length > 0) {
            this.setState({subscriptionReportsOptions: apiSubscriptionReports});
        }
    };

    _filterSubscriptionReports = (reportPeriodicity) => {
        let reports = Object.keys(this.state.subscriptionReports).map((data) => this.state.subscriptionReports[data]);
        let filteredReports;
        if (reportPeriodicity) {
            filteredReports = reports.filter((report) => report.application_name === this.state.selectedApplicationName.label && report.subscription_frequency.includes(reportPeriodicity));
        }
        const apiSubscriptionReports = filteredReports.map(type => ({
            label: type.name,
            value: type.id,
            subscription_template: type.subscription_template
        }))
        if (apiSubscriptionReports.length > 0) {
            this.setState({subscriptionReportsOptions: apiSubscriptionReports});
        }
    }

    _setChildProperties = (thisProps) => {
        const apiChildProperties = Object.entries(thisProps.childProperties)
            .map(([key, value]) => value)
            .map(type => ({label: type.name, value: type.id}))

        if (apiChildProperties.length > 0) {
            this.setState({
                enableSubscriptionProperty: true,
                subscriptionPropertyOptions: this.state.currentCustomerProperty.concat(apiChildProperties)
            });
        }
    };

    _handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
            if (e.target.name === "subscriptionName") {
                if (e.target.value === "") {
                    this.setState({
                        subscriptionName: "",
                        reportPeriodicity: "",
                        subscriptionReport: "",
                        dataCoverageUnits: "",
                        subscriptionDataCoverageUnit: "",
                        deliveryFormat: PROPERTY_REPORT_SUBSCRIPTION.PDF,
                        status: PROPERTY_REPORT_SUBSCRIPTION.ACTIVE,
                        selectedApplicationName: [],
                        recipientsGridData: []
                    });
                }
            }
        }
    };

    _handleFilterPanelModal = (e) => {
        e.stopPropagation();
        this.setState({isFilterModal: !this.state.isFilterModal});
    };

    _handleChangeSubscriptionFrequency = (e) => {
        this._filterSubscriptionReports(e.target.value);

        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                ...this.state.editFlag,
                [e.target.name]: true
            },
            subscriptionReport: "",
            dataCoverageUnits: "",
            subscriptionDataCoverageUnit: "",
            deliveryFormat: PROPERTY_REPORT_SUBSCRIPTION.PDF,
            status: PROPERTY_REPORT_SUBSCRIPTION.ACTIVE,
        });
    };

    _handleReportSelect = selectedOption => {
        this.setState({
            subscriptionReport: selectedOption,
            editFlag: {
                ...this.state.editFlag,
                subscriptionReport: true
            },
            dataCoverageUnits: "",
            subscriptionDataCoverageUnit: "",
            deliveryFormat: PROPERTY_REPORT_SUBSCRIPTION.PDF,
            status: PROPERTY_REPORT_SUBSCRIPTION.ACTIVE,
        })
    }

    _handleApplicationName = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                status: true
            },
            selectedApplicationName: selectedOption,
            reportPeriodicity: "",
            subscriptionReport: "",
            dataCoverageUnits: "",
            subscriptionDataCoverageUnit: "",
            deliveryFormat: PROPERTY_REPORT_SUBSCRIPTION.PDF,
            status: PROPERTY_REPORT_SUBSCRIPTION.ACTIVE,
        });
        this._loadReports();
        this._loadCustomerUserDetails(selectedOption);
    };

    _handleDataCoverageFrequencySelect = selectedOption => {
        this.setState({
            subscriptionDataCoverageUnit: selectedOption,
            editFlag: {
                ...this.state.editFlag,
                subscriptionDataCoverageUnit: true
            }
        })
    }

    _handleSubscriptionSelect = selectedOption => {
        this.setState({
            subscriptionProperty: selectedOption,
            editFlag: {
                ...this.state.editFlag,
                subscriptionProperty: true
            },
            subscriptionName: "",
            reportPeriodicity: "",
            subscriptionReport: "",
            dataCoverageUnits: "",
            subscriptionDataCoverageUnit: "",
            deliveryFormat: PROPERTY_REPORT_SUBSCRIPTION.PDF,
            status: PROPERTY_REPORT_SUBSCRIPTION.ACTIVE,
            selectedApplicationName: [],
            recipientsGridData: []
        })
    }

    _onPreviousKeyDown = (e) => {
        const regex = new RegExp("[0-9\Backspace]");
        if (!regex.test(e.key)) {
            e.preventDefault();
            return false;
        }
    }

    _onSubscriptionNameKeyPress = (e) => {
        const regex = new RegExp("[a-zA-Z0-9_ ]");
        if (!regex.test(String.fromCharCode(e.charCode))) {
            e.preventDefault();
            return false;
        }
    }


    _loadApplicationDropDown = (thisProps) => {
        const appList = [];
        if (thisProps.applicationLst) {
            thisProps.applicationLst.map((appName) => {
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    if ((appName.name === APPLICATION_MODES.TATVAM_INAPP && thisProps.customerInformation.is_inapp) ||
                        (appName.name === APPLICATION_MODES.PREDICTO && thisProps.customerInformation.is_predicto)) {
                        appList.push({label: appName.name, value: appName.name});
                    }
                } else {
                    appList.push({label: appName.name, value: appName.name});
                }
            });
            appList.sort(dynamicSort("label"));
        }
        this.setState({
            applicationListOptions: appList
        });
    };

    _loadReports = () => {
        if (this.props.subscriptionReports) {
            this._setSubscriptionReports(this.props);
            this.setState({subscriptionReports: this.props.subscriptionReports});
        }
    };

    _loadCustomerUserDetails = (selectedOption) => {
        let recipientsData = [];
        if (this.props.customerUserDetailsLst) {
            this.props.customerUserDetailsLst.map((data) => {
                if (data.application_name === selectedOption.label) {
                    if (Array.isArray(this.props.selectedReportSubscriptionData)) {
                        data.isSelected = false;
                    } else {
                        const isSelectedRecipient = this.props.selectedReportSubscriptionData.report_recipients.filter(item => item.recipient_target_address === data.email);
                        if (isSelectedRecipient.length > 0) {
                            data.isSelected = true;
                        } else {
                            data.isSelected = false;
                        }
                    }
                    recipientsData.push(data);
                }
            });
        }
        this.setState({recipientsGridData: recipientsData});

    };


    componentDidMount() {
        this.setState({loader: true});

        this.props.actions.fetchApplicationNames().then(() => {
            this._loadApplicationDropDown(this.props);
        });

        this.props.actions.fetchCustomerUserDetails(this.props.currentCustomerId).then(() => {
            this.setState({loader: false});
            let recipientsData = [];
            if (this.props.customerUserDetailsLst) {
                this.props.customerUserDetailsLst.map((data) => {
                    if (data.application_name === this.props.selectedReportSubscriptionData.application_name) {
                        if (Array.isArray(this.props.selectedReportSubscriptionData)) {
                            data.isSelected = false;
                        } else {
                            const isSelectedRecipient = this.props.selectedReportSubscriptionData.report_recipients.filter(item => item.recipient_target_address === data.email);
                            if (isSelectedRecipient.length > 0) {
                                data.isSelected = true;
                            } else {
                                data.isSelected = false;
                            }
                        }
                        recipientsData.push(data);
                    }
                });
            }
            this.setState({recipientsGridData: recipientsData});
        });

        this.props.actions.getSubscriptionReports(this.props.currentCustomerId).then(() => {
        });

        this.props.actions.getChildProperties(this.props.currentCustomerId).then(() => {
            if (this.props.childProperties) {
                this._setChildProperties(this.props);
            }
        });

        this._linkSubscriptionDataCoverageUnitList();
        const currentCustomer = this.props.customerList[this.props.currentCustomerId]
        this.setState({
            currentCustomerProperty: [{label: currentCustomer.name, value: currentCustomer.id}]
        });

        if (!Array.isArray(this.props.selectedReportSubscriptionData)) {
            let subscriptionDataCoverageUnit = {
                label: this.props.selectedReportSubscriptionData.data_coverage_unit_frequency,
                value: this.props.selectedReportSubscriptionData.data_coverage_unit_frequency
            };
            let subscriptionReport = {
                label: this.props.selectedReportSubscriptionData.report_name,
                value: this.props.selectedReportSubscriptionData.report_id,
                subscription_template: this.props.selectedReportSubscriptionData.subscription_template
            };
            let subscriptionProperty = {
                label: this.props.selectedReportSubscriptionData.customer_name,
                value: this.props.selectedReportSubscriptionData.customer_id
            };
            this.setState({
                subscriptionReport,
                subscriptionDataCoverageUnit,
                subscriptionName: this.props.selectedReportSubscriptionData.subscription_name,
                reportPeriodicity: this.props.selectedReportSubscriptionData.report_periodicity,
                dataCoverageUnits: this.props.selectedReportSubscriptionData.data_coverage_units,
                deliveryFormat: this.props.selectedReportSubscriptionData.delivery_format,
                status: this.props.selectedReportSubscriptionData.status === APPLICATION_MODES.ACTIVE,
                applicationMode: this.props.applicationMode,
                checkedRows: this.props.selectedReportSubscriptionData.report_recipients,
                filterDimensions: this.props.selectedReportSubscriptionData.report_filter,
                subscriptionProperty,
                selectedApplicationName: {
                    label: this.props.selectedReportSubscriptionData.application_name,
                    value: this.props.selectedReportSubscriptionData.application_name
                }
            });
        }
        this.props.actions.setGlobalFilter({
            ...this.props.globalFilter,
            appliedFilter: this.props.selectedReportSubscriptionData.report_filter
        })
    }

    componentWillReceiveProps(props) {
        if (props.subscriptionReports) {
            this._setSubscriptionReports(props);
        }

        this._loadApplicationDropDown(props);

        if (props.childProperties) {
            this._setChildProperties(props);
        }

        if (!Array.isArray(props.selectedReportSubscriptionData)) {
            let recipientsData = [];
            if (props.customerUserDetailsLst) {
                props.customerUserDetailsLst.map((data) => {
                    if (data.application_name === props.selectedReportSubscriptionData.application_name) {
                        if (Array.isArray(props.selectedReportSubscriptionData)) {
                            data.isSelected = false;
                        } else {
                            const isSelectedRecipient = props.selectedReportSubscriptionData.report_recipients.filter(item => item.recipient_target_address === data.email);
                            if (isSelectedRecipient.length > 0) {
                                data.isSelected = true;
                            }
                        }

                        recipientsData.push(data);
                    }
                });
            }

            this.setState({recipientsGridData: recipientsData});
            let subscriptionDataCoverageUnit = {
                label: props.selectedReportSubscriptionData.data_coverage_unit_frequency,
                value: props.selectedReportSubscriptionData.data_coverage_unit_frequency
            };
            let subscriptionReport = {
                label: props.selectedReportSubscriptionData.report_name,
                value: props.selectedReportSubscriptionData.report_id,
                subscription_template: this.props.selectedReportSubscriptionData.subscription_template
            };
            let subscriptionProperty = {
                label: props.selectedReportSubscriptionData.customer_name,
                value: props.selectedReportSubscriptionData.customer_id
            };
            this.setState({
                subscriptionReport,
                subscriptionDataCoverageUnit,
                subscriptionName: props.selectedReportSubscriptionData.subscription_name,
                reportPeriodicity: props.selectedReportSubscriptionData.report_periodicity,
                dataCoverageUnits: props.selectedReportSubscriptionData.data_coverage_units,
                deliveryFormat: props.selectedReportSubscriptionData.delivery_format,
                status: props.selectedReportSubscriptionData.status === APPLICATION_MODES.ACTIVE,
                applicationMode: props.applicationMode,
                checkedRows: props.selectedReportSubscriptionData.report_recipients,
                filterDimensions: props.selectedReportSubscriptionData.report_filter,
                subscriptionProperty,
                selectedApplicationName: {
                    label: props.selectedReportSubscriptionData.application_name,
                    value: props.selectedReportSubscriptionData.application_name
                }
            });
        }
    }

    _handleSave = e => {
        e.preventDefault();
        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            }
            if (response) {
                if (this.state.isValid && this.state.validDataCoverageUnit && this.state.validRecipients) {

                    let recipientLst = [];
                    this.state.checkedRows.map((data) => {
                        recipientLst.push({
                            "recipient_first_name": data.first_name,
                            "recipient_last_name": data.last_name,
                            "recipient_display_name": data.display_name,
                            "recipient_target_address": data.email,
                        });
                    });

                    const reportSubscriptionValue = {
                        subscription_name: this.state.subscriptionName,
                        customer_id: this.state.currentCustomerProperty[0].value,
                        customer_name: this.state.currentCustomerProperty[0].label,
                        report_id: this.state.subscriptionReport.value,
                        subscription_template: this.state.subscriptionReport.subscription_template,
                        report_periodicity: this.state.reportPeriodicity,
                        data_coverage_units: this.state.dataCoverageUnits,
                        data_coverage_unit_frequency: this.state.subscriptionDataCoverageUnit.value,
                        delivery_format: this.state.deliveryFormat,
                        status: this.state.status ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.IN_ACTIVE,
                        report_filter: this.state.filterDimensions,
                        last_execution_date: "",
                        report_name: this.state.subscriptionReport.label,
                        old_schedule_id: this.state.subscriptionName,
                        report_recipients: recipientLst,
                        created_by: this.props.userName,
                        application_name: this.state.selectedApplicationName.label
                    };

                    this.props.actions.setGlobalFilter({
                        ...this.props.globalFilter,
                        appliedFilter: this.state.filterDimensions
                    })

                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        this.props.actions.createCustomerReportSubscription(reportSubscriptionValue).then((response) => {
                            this.setState({preLoader: false});

                            if (response && response.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (response.data && response.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: response.data.message,
                                });
                            }
                        });
                    } else {
                        reportSubscriptionValue.updated_count = this.props.selectedReportSubscriptionData.process_data ? this.props.selectedReportSubscriptionData.process_data.updated_count : 0;
                        this.props.actions.updateCustomerReportSubscription(reportSubscriptionValue, this.props.selectedReportSubscriptionData._id).then((response) => {
                            this.setState({preLoader: false});
                            if (response && response.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (response.data && response.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: response.data.message,
                                });
                            }
                        });
                    }
                }
            }

        });
    }

    _validations = () => {
        if (
            (this.state.enableSubscriptionProperty && this.state.subscriptionPropertyOptions === "") ||
            this.state.subscriptionName === "" ||
            this.state.reportPeriodicity === "" ||
            this.state.subscriptionReport === "" ||
            this.state.dataCoverageUnits === "" ||
            this.state.subscriptionDataCoverageUnit === ""
        ) {
            this.setState({
                isValid: false,
                inValidForm: true,
            })
        } else if (this.state.checkedRows.length === 0) {
            this.setState({
                isValid: false,
                validRecipients: false
            })
        } else if (parseInt(this.state.dataCoverageUnits) > 365) {
            this.setState({
                isValid: false,
                validDataCoverageUnit: false
            })
        } else if (this.state.checkedRows.length > 0) {
            this.setState({
                isValid: true,
                validRecipients: true
            })
        } else if (parseInt(this.state.dataCoverageUnits) <= 365) {
            this.setState({
                isValid: true,
                validDataCoverageUnit: true
            })
        }

        return Promise.resolve(true);
    }

    onFilterApplied = (appliedFilter) => {
        this.setState({
            isFilterModal: false,
            filterDimensions: appliedFilter,
            editFlag: {
                ...this.state.editFlag,
                filterDimensions: true
            },
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row className="reportSubscriptionForm">
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <form className="mb-2 h-15v">
                            {this.state.applicationMode !== APPLICATION_MODES.CREATE ? (
                                <h4>{PROPERTY_REPORT_SUBSCRIPTION.EDIT_REPORT_SUBSCRIPTION}</h4>) : ""}
                            <TatvamCol s={12}>

                                {this.state.enableSubscriptionProperty &&
                                    <TatvamCol s={12} m={6} xl={6} className="mb-1 reportSubscriptionCol">
                                        <label
                                            htmlFor="subscriptionProperty">{PROPERTY_REPORT_SUBSCRIPTION.SUBSCRIPTION_PROPERTY}<span
                                            style={{color: 'red'}}> *</span></label>
                                        <TatvamMultiSelect
                                            required
                                            m={6}
                                            classNamePrefix="react-select"
                                            className={(this.state.inValidForm && this.state.subscriptionProperty === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                            value={this.state.subscriptionProperty}
                                            name="subscriptionProperty"
                                            id="subscriptionProperty"
                                            onChange={this._handleSubscriptionSelect}
                                            options={this.state.subscriptionPropertyOptions}
                                            placeholder={PROPERTY_REPORT_SUBSCRIPTION.SELECT_SUBSCRIPTION_PROPERTY}
                                            isDisabled={this.state.applicationMode === APPLICATION_MODES.EDIT}
                                        />
                                    </TatvamCol>
                                }

                                <TatvamCol s={12} m={6} xl={6} className="mb-1 p-0 reportSubscriptionCol">
                                    <label htmlFor="subscriptionName"
                                           className="ml-2">{PROPERTY_REPORT_SUBSCRIPTION.SUBSCRIPTION_NAME}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <TatvamTextBox
                                        className={(this.state.inValidForm && this.state.subscriptionName === "") ? "labelText mb-0 validate ht-4  invalid" : "labelText mb-0 validate ht-4"}
                                        s={12}
                                        required
                                        name="subscriptionName"
                                        onChange={this._handleChange}
                                        value={this.state.subscriptionName}
                                        placeholder={PROPERTY_REPORT_SUBSCRIPTION.SUBSCRIPTION_NAME_PLACEHOLDER}
                                        autocomplete="off"
                                        maxlength={50}
                                        disabled={this.state.enableSubscriptionProperty && this.state.subscriptionProperty === ""}
                                        onKeyPress={this._onSubscriptionNameKeyPress}
                                    />
                                </TatvamCol>
                                <TatvamCol s={12} m={6} xl={6} className="mb-1 reportSubscriptionCol">
                                    <label
                                        htmlFor="subscriptionReport">{PROPERTY_REPORT_SUBSCRIPTION.APPLICATION_NAME}</label>
                                    <TatvamMultiSelect
                                        required
                                        m={6}
                                        classNamePrefix="react-select"
                                        className={(this.state.inValidForm && this.state.selectedApplicationName.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                        value={this.state.selectedApplicationName}
                                        name="applicationName"
                                        id="applicationName"
                                        onChange={this._handleApplicationName}
                                        options={this.state.applicationListOptions}
                                        placeholder={PROPERTY_REPORT_SUBSCRIPTION.SELECT_REPORT}
                                        isDisabled={this.state.subscriptionName === "" || this.state.applicationMode === APPLICATION_MODES.EDIT}
                                    />
                                </TatvamCol>
                                <TatvamCol s={12} m={6} xl={6} className="mb-1 reportSubscriptionCol">
                                    <label
                                        htmlFor="reportPeriodicity">{PROPERTY_REPORT_SUBSCRIPTION.SUBSCRIPTION_FREQUENCY}
                                        <span style={{color: 'red'}}> *</span></label>
                                    <RadioGroup
                                        aria-label={PROPERTY_REPORT_SUBSCRIPTION.SUBSCRIPTION_FREQUENCY}
                                        name="reportPeriodicity"
                                        value={this.state.reportPeriodicity}
                                        onChange={this._handleChangeSubscriptionFrequency}
                                        class="row"
                                    >
                                        <FormControlLabel value={PROPERTY_REPORT_SUBSCRIPTION.DAILY}
                                                          label={PROPERTY_REPORT_SUBSCRIPTION.DAILY}
                                                          disabled={this.state.selectedApplicationName.length === 0 || this.state.applicationMode === APPLICATION_MODES.EDIT}
                                                          control={<Radio color="default" required
                                                                          className={(this.state.inValidForm && this.state.reportPeriodicity === "") ? "labelText mb-0 validate invalid-radio-field" : "labelText mb-0 validate"}/>}/>
                                        <FormControlLabel value={PROPERTY_REPORT_SUBSCRIPTION.WEEKLY}
                                                          label={PROPERTY_REPORT_SUBSCRIPTION.WEEKLY}
                                                          disabled={this.state.selectedApplicationName.length === 0 || this.state.applicationMode === APPLICATION_MODES.EDIT}
                                                          control={<Radio color="default" required
                                                                          className={(this.state.inValidForm && this.state.reportPeriodicity === "") ? "labelText mb-0 validate invalid-radio-field" : "labelText mb-0 validate"}/>}/>
                                        <FormControlLabel value={PROPERTY_REPORT_SUBSCRIPTION.MONTHLY}
                                                          label={PROPERTY_REPORT_SUBSCRIPTION.MONTHLY}
                                                          disabled={this.state.selectedApplicationName.length === 0 || this.state.applicationMode === APPLICATION_MODES.EDIT}
                                                          control={<Radio color="default" required
                                                                          className={(this.state.inValidForm && this.state.reportPeriodicity === "") ? "labelText mb-0 validate invalid-radio-field" : "labelText mb-0 validate"}/>}/>
                                        <FormControlLabel value={PROPERTY_REPORT_SUBSCRIPTION.QUARTERLY}
                                                          label={PROPERTY_REPORT_SUBSCRIPTION.QUARTERLY}
                                                          disabled={this.state.selectedApplicationName.length === 0 || this.state.applicationMode === APPLICATION_MODES.EDIT}
                                                          control={<Radio color="default" required
                                                                          className={(this.state.inValidForm && this.state.reportPeriodicity === "") ? "labelText mb-0 validate invalid-radio-field" : "labelText mb-0 validate"}/>}/>
                                    </RadioGroup>
                                </TatvamCol>


                                <TatvamCol s={12} m={6} xl={6} className="mb-1 reportSubscriptionCol">
                                    <label htmlFor="subscriptionReport">{PROPERTY_REPORT_SUBSCRIPTION.REPORT}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <TatvamMultiSelect
                                        required
                                        m={6}
                                        classNamePrefix="react-select"
                                        className={(this.state.inValidForm && this.state.subscriptionReport === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                        value={this.state.subscriptionReport}
                                        name="subscriptionReport"
                                        id="subscriptionReport"
                                        onChange={this._handleReportSelect}
                                        options={this.state.subscriptionReportsOptions}
                                        placeholder={PROPERTY_REPORT_SUBSCRIPTION.SELECT_REPORT}
                                        isDisabled={this.state.reportPeriodicity === "" || this.state.applicationMode === APPLICATION_MODES.EDIT}
                                    />
                                </TatvamCol>

                                <TatvamCol s={12} m={6} xl={6} className="mb-1 mt-2 reportSubscriptionCol">
                                    <label htmlFor="">{PROPERTY_REPORT_SUBSCRIPTION.DATA_COVERAGE_PERIOD}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <TatvamCol s="12" className="p-0 align-flex-end">
                                        <TatvamCol s={12} m={3} xl={3} className="mb-3 p-0">
                                            <label>{PROPERTY_REPORT_SUBSCRIPTION.PREVIOUS}</label>
                                        </TatvamCol>
                                        <TatvamCol s={12} m={4} xl={4} className="mb-1 p-0">
                                            <input
                                                className={(this.state.inValidForm && this.state.dataCoverageUnits === "") ? "labelText mb-0 validate invalid" : "labelText mb-0 validate valid"}
                                                type="number"
                                                id="dataCoverageUnits"
                                                name="dataCoverageUnits"
                                                min={1}
                                                max={365}
                                                required
                                                placeholder={PROPERTY_REPORT_SUBSCRIPTION.PERIOD}
                                                onChange={this._handleChange}
                                                value={this.state.dataCoverageUnits}
                                                disabled={this.state.subscriptionReport === ""}
                                                onKeyDown={this._onPreviousKeyDown}
                                                autocomplete="off"
                                            />
                                        </TatvamCol>
                                        <TatvamCol s={12} m={5} xl={5} className="mb-1 pr-0">
                                            <TatvamMultiSelect
                                                required
                                                m={6}
                                                classNamePrefix="react-select"
                                                className={(this.state.inValidForm && this.state.subscriptionDataCoverageUnit === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                                value={this.state.subscriptionDataCoverageUnit}
                                                name="subscriptionDataCoverageUnit"
                                                id="subscriptionDataCoverageUnit"
                                                onChange={this._handleDataCoverageFrequencySelect}
                                                options={this.state.subscriptionDataCoverageUnitListOptions}
                                                placeholder={PROPERTY_REPORT_SUBSCRIPTION.UNIT}
                                                isDisabled={this.state.dataCoverageUnits === ""}
                                            />
                                        </TatvamCol>
                                    </TatvamCol>
                                </TatvamCol>

                                <TatvamCol s={12} m={6} xl={6}
                                           className={this.state.enableSubscriptionProperty ? "mb-1 mt-2 reportSubscriptionCol" : " mt-2 reportSubscriptionCol"}>
                                    <label htmlFor="deliveryFormat">{PROPERTY_REPORT_SUBSCRIPTION.DELIVERY_FORMAT}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <RadioGroup
                                        aria-label={PROPERTY_REPORT_SUBSCRIPTION.DELIVERY_FORMAT}
                                        name="deliveryFormat"
                                        value={this.state.deliveryFormat}
                                        onChange={this._handleChange}
                                        class="row"
                                    >
                                        <FormControlLabel value={PROPERTY_REPORT_SUBSCRIPTION.PDF}
                                                          control={<Radio color="default"/>}
                                                          label={PROPERTY_REPORT_SUBSCRIPTION.PDF}
                                                          disabled={this.state.subscriptionDataCoverageUnit === ""}/>
                                        <FormControlLabel value={PROPERTY_REPORT_SUBSCRIPTION.HTML}
                                                          control={<Radio color="default"/>}
                                                          label={PROPERTY_REPORT_SUBSCRIPTION.HTML}
                                                          disabled={this.state.subscriptionDataCoverageUnit === ""}/>
                                    </RadioGroup>
                                </TatvamCol>
                                {this.state.selectedApplicationName.label === "Tatvam Inapp" && (
                                    <TatvamCol s={12} m={6} xl={6}
                                               className={this.state.enableSubscriptionProperty ? "reportSubscriptionCol" : "reportSubscriptionCol pt-2"}>
                                        <div className="form-group col-12 col-md-6 col-lg-6">
                                            <label className="col-sm-4 col-1g-4 col-xs-12 pl-0"></label>
                                            <div className="pl-0 add-filter">
                                                <TatvamButton
                                                    flat
                                                    node="a"
                                                    disabled={this.state.subscriptionDataCoverageUnit === ""}
                                                    onClick={this._handleFilterPanelModal}> {this.state.filterDimensions === "" ? PROPERTY_REPORT_SUBSCRIPTION.ADD_FILTER : PROPERTY_REPORT_SUBSCRIPTION.EDIT_VIEW_FILTER}
                                                </TatvamButton>
                                            </div>
                                        </div>
                                    </TatvamCol>
                                )}

                                <TatvamCol s={12} m={6} xl={6}
                                           className={this.state.enableSubscriptionProperty ? "valign-wrapper mt-2" : "valign-wrapper mt-1 mb-2"}>
                                    <label htmlFor="status"
                                           className="status-label">{PROPERTY_REPORT_SUBSCRIPTION.STATUS}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <Switch
                                        id="status"
                                        name="status"
                                        checked={this.state.status}
                                        onClick={this._handleChange}
                                        m={3}
                                        disabled={this.state.subscriptionDataCoverageUnit === ""}
                                    />
                                </TatvamCol>

                                <TatvamCol s={12} m={12} xl={12}>
                                    <label>{PROPERTY_REPORT_SUBSCRIPTION.RECIPIENTS}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <TatvamGrid
                                        gridData={this.state.recipientsGridData}
                                        colData={this.state.colDef}
                                        onCheckBoxSelect={this._getSelectedRows}
                                        rowSelection={"multiple"}
                                        suppressRowClickSelection
                                        enableSelectionChanged={true}
                                    />
                                </TatvamCol>

                            </TatvamCol>
                        </form>
                    ) : (
                        <Row>
                            <h4>{PROPERTY_REPORT_SUBSCRIPTION.VIEW_REPORT_SUBSCRIPTION}</h4>

                            <TatvamCol m={12} className="p-0">
                                {this.state.enableSubscriptionProperty && <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0"> {PROPERTY_REPORT_SUBSCRIPTION.VIEW_SUBSCRIPTION_PROPERTY} : </span>
                                    <span className="col s7"> {this.state.subscriptionProperty.label}</span>
                                </div>
                                }

                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0">{PROPERTY_REPORT_SUBSCRIPTION.VIEW_SUBSCRIPTION_NAME} :</span>
                                    <span className="col s7">{this.state.subscriptionName}</span>
                                </div>
                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0">{PROPERTY_REPORT_SUBSCRIPTION.APPLICATION_NAME} :</span>
                                    <span className="col s7">{this.state.selectedApplicationName.label}</span>
                                </div>
                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col p-0">{PROPERTY_REPORT_SUBSCRIPTION.VIEW_SUBSCRIPTION_FREQUENCY} :</span>
                                    <span className="col s6">{this.state.reportPeriodicity}</span>
                                </div>

                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0">{PROPERTY_REPORT_SUBSCRIPTION.VIEW_REPORT} :</span>
                                    <span className="col s7">{this.state.subscriptionReport.label}</span>
                                </div>

                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0">{PROPERTY_REPORT_SUBSCRIPTION.VIEW_DATA_COVERAGE_PERIOD} :</span>
                                    <span class="col s2 pr-0">{PROPERTY_REPORT_SUBSCRIPTION.PREVIOUS}</span>
                                    <span
                                        className="col s5 pr-0">{this.state.dataCoverageUnits + " " + this.state.subscriptionDataCoverageUnit.label}</span>
                                </div>

                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0">{PROPERTY_REPORT_SUBSCRIPTION.VIEW_DELIVERY_FORMAT} :</span>
                                    <span className="col s7">{this.state.deliveryFormat}</span>
                                </div>
                                {this.state.selectedApplicationName.label === "Tatvam Inapp" && (
                                    <div className="col s6 p-0">
                                        <span
                                            className="property-label col s5 p-0">{this.state.filterDimensions === "" ? PROPERTY_REPORT_SUBSCRIPTION.ADD_FILTER : PROPERTY_REPORT_SUBSCRIPTION.EDIT_VIEW_FILTER}</span>
                                    </div>
                                )}

                                <div className="col s6 p-0">
                                    <span
                                        className="property-label col s5 p-0">{PROPERTY_REPORT_SUBSCRIPTION.VIEW_STATUS} :</span>
                                    <span
                                        className="col s7">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>

                                </div>

                                <TatvamCol s={12} m={12} xl={12}>
                                    <label>{PROPERTY_REPORT_SUBSCRIPTION.RECIPIENTS}<span
                                        style={{color: 'red'}}> *</span></label>
                                    <div className="disabled-grid">
                                        <TatvamGrid
                                            gridData={this.state.recipientsGridData}
                                            colData={this.state.colDef}
                                            onCheckBoxSelect={this._getSelectedRows}
                                            rowSelection={"multiple"}
                                            suppressRowClickSelection
                                            enableSelectionChanged={false}
                                        />
                                    </div>
                                </TatvamCol>

                            </TatvamCol>
                        </Row>
                    )}
                </Row>

                <Row>
                    {!this.state.isValid && (
                        <p className="errorText">{/* {VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS} */}</p>)}
                    {!this.state.validDataCoverageUnit && (
                        <p className="errorText">{VALIDATION_MESSAGES.DATA_COVERAGE_INVALID}</p>)}
                    {!this.state.validRecipients && (
                        <p className="errorText">{VALIDATION_MESSAGES.SELECT_RECIPIENTS}</p>)}

                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                            <TatvamButton
                                waves="light"
                                className="mt-1 CreateProjectPublish btn_primary"
                                onClick={this._handleSave}
                                disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                            >
                                {FORM_OPERATIONS.SAVE}
                            </TatvamButton>
                        ) :
                        <TatvamButton
                            waves="light"
                            className="mt-1 CreateProjectPublish btn_primary"
                            name="EDIT"
                            onClick={this._handleAppMode}
                        >
                            {FORM_OPERATIONS.EDIT}
                        </TatvamButton>
                    }
                    <TatvamButton
                        waves="light"
                        className="mt-1 mr-1  CreateProjectPublish btn_primary"
                        onClick={this._triggerDiscard}
                    >
                        {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                        !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                            FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                    </TatvamButton>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />

                {
                    this.state.isFilterModal && (
                        <TatvamModal
                            open={this.state.isFilterModal}
                            className="hideFooter filterModal"
                            options={{dismissible: false}}
                            header={<i className="material-icons right pointer pr-2 pt-4"
                                       onClick={this._handleFilterPanelModal}>close</i>}
                            fixedFooter={false}
                            children={(
                                <SubscriptionFilter
                                    hasFilterReset={false}
                                    currentCustomerId={this.props.currentCustomerId}
                                    onFilterApplyClicked={this.onFilterApplied}
                                />
                            )}
                        />
                    )}

            </Fragment>
        );
    }
}

export default CustomerReportSubscriptionForm;
