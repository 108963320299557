import {apiUrl} from 'apiConstants';
import axios from 'axios';

const actions = {
    GetCategorySuccess: (payload) => ({
        type: "GET_CATEGORY_SUCCESS",
        payload
    }),
    GetCategoryError: (payload) => ({
        type: "GET_CATEGORY_ERROR",
        payload
    })
};

export const fetchCategory = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_SOURCE_CATEGORY;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetCategorySuccess(response.data.data));
                return response;

            }
            dispatch(actions.GetCategoryError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetCategoryError(error.message));
            return Promise.resolve(error);
        });
};
