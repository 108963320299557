import {
    DIMENSION_LABEL_NAME,
    URL_ALERTS,
    URL_FILTER_CLASSIFIERS,
    URL_FILTER_COMMENT_TYPE,
    URL_FILTER_CUSTOMER_LABEL,
    URL_FILTER_PROPERTIES,
    URL_FILTER_REVIEW_SOURCE,
    URL_FILTER_SUB_CLASSIFIERS
} from '../constants';
import {APIService} from './api.service';
import {StatusCodes} from '../constants/index';
import {apiUrl} from "apiConstants";

const HttpMethodType = {
    Delete: 'DELETE',
    Get: 'GET',
    Post: 'POST',
    Patch: 'PATCH',
}

const RecordDataStatus = {
    ACTIVE: 'Active',
    INACTIVE: 'In-Active',
}
const URL_CUSTOMER_SAVED_FILTER_DELETE = 'customerUserFilters/filter/{filter_id}';
const URL_CUSTOMER_SAVED_FILTER_UPDATE = 'customerUserFilters/customer/{propertyId}/user/{user_name}/filter/{filter_id}';

export class FilterService {
    _filterEndPoint = 'filters/{propertyId}';
    _filterCustomerLabelEndPoint = `customerDimension/{propertyId}/dimensions/LABEL/dimensionValues`;
    _filterCustomerSavedEndPoint = `customerUserFilters/customer/{propertyId}/user/{user_name}`;
    _filterEntityEndPoint = `customerDimension/{propertyId}/dimensions`;
    _filterEntityValueEndPoint = `customerDimensionValue/customer/{propertyId}/dimension/{entity}/dimensionValues`;

    constructor(_propertyId, _account) {
        this._account = _account;
        this._propertyId = _propertyId;

        this._filterEndPoint = this._filterEndPoint.replace('{propertyId}', this._propertyId);
        this._filterCustomerLabelEndPoint = this._filterCustomerLabelEndPoint.replace('{propertyId}', this._propertyId);
        this._filterCustomerSavedEndPoint = this._filterCustomerSavedEndPoint.replace('{propertyId}', this._propertyId).replace('{user_name}', this._account.user_name);
        this._filterEntityEndPoint = this._filterEntityEndPoint.replace('{propertyId}', this._propertyId);
        this._filterEntityValueEndPoint = this._filterEntityValueEndPoint.replace('{propertyId}', this._propertyId);
    }

    deleteCustomerSavedFilter = async (filter_id) => {
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Delete;
        const response = await apiService.send(apiUrl.BASE_URL + URL_CUSTOMER_SAVED_FILTER_DELETE.replace('{filter_id}', filter_id), apiService.request());
        return response.status === Number(StatusCodes.OK);
    };

    getClassifiers = async (filter) => {
        let classifiers = null;
        const classifierEndoint = this._filterEndPoint + URL_FILTER_CLASSIFIERS;
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + classifierEndoint, apiService.request(filter));
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                classifiers = data.result;
            }
        }
        return classifiers;
    };

    getCommentType = async (filter) => {
        let commentType = {dimension_name: '', dimension_values: []};
        try {
            const routeUrl = this._filterEndPoint + URL_FILTER_COMMENT_TYPE;
            const apiService = new APIService(this._account);
            apiService._method = HttpMethodType.Post;
            const response = await apiService.send(apiUrl.BASE_URL + routeUrl, apiService.request(filter));
            if (response.status === Number(StatusCodes.OK)) {
                const data = response.data;
                if (data && data.code && data.code === '1') {
                    commentType = data.result;
                }
            }
        } catch (ex) {
            throw ex;
        }
        return commentType;
    };

    getCustomerSavedFilters = async () => {
        let filters = [];
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Get;
        const response = await apiService.send(apiUrl.BASE_URL + this._filterCustomerSavedEndPoint, apiService.request());
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                filters = data.data;
            }
        }
        return filters;
    };

    getCustomerLabels = async (filter) => {
        let labels = {dimension_name: '', dimension_values: []};
        const classifierEndoint = this._filterEndPoint + URL_FILTER_CUSTOMER_LABEL;
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + classifierEndoint, apiService.request(filter));
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                const filters = data.result.filter((dimension) => dimension.dimension_name === DIMENSION_LABEL_NAME);
                if (filters.length > 0) {
                    labels = filters[0];
                }
            }
        }
        return labels;
    };

    getCustomerMasterLabels = async () => {
        let dimension_label_info = {dimension_name: '', dimension_values: []};
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + this._filterCustomerLabelEndPoint, apiService.request < any > ({
            "periodicity": [],
            "periodicityType": ""
        }));
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data && data.result) {
                dimension_label_info = data.result;
            }
        }
        return dimension_label_info;
    };

    getChildProperties = async (filter) => {
        let properties = {dimension_name: '', dimension_values: []};
        const _endPoint = this._filterEndPoint + URL_FILTER_PROPERTIES;
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + _endPoint, apiService.request(filter));
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                properties = data.result;
            }
        }
        return properties;
    };

    getFilterEntity = async () => {
        let filterEntities = [];
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Get;
        const response = await apiService.send(apiUrl.BASE_URL + this._filterEntityEndPoint, apiService.request());
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                if (data.data.length > 0) {
                    filterEntities = data.data;
                }
            }
        }
        return filterEntities;
    };

    getFilterEntityValue = async (entityName, filter) => {
        let entityValue = null;
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Get;
        const response = await apiService.send(apiUrl.BASE_URL + this._filterEntityValueEndPoint.replace('{entity}', entityName), apiService.request(filter));

        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                if (data.data) {
                    entityValue = data.data;
                }
            }
        }
        return entityValue;
    };

    getReviewAlerts = async (filter) => {
        let alert = {dimension_name: '', dimension_values: []};

        const _alertEndPoint = this._filterEndPoint + URL_ALERTS;
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + _alertEndPoint, apiService.request(filter));
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                alert = data.result;
            }
        }
        return alert;
    };

    getReviewSource = async (filter) => {
        let source = {dimension_name: '', dimension_values};
        try {
            const routeUrl = this._filterEndPoint + URL_FILTER_REVIEW_SOURCE;
            const apiService = new APIService(this._account);
            apiService._method = HttpMethodType.Post;
            const response = await apiService.send(apiUrl.BASE_URL + routeUrl, apiService.request(filter));
            if (response.status === Number(StatusCodes.OK)) {
                const data = response.data;
                if (data && data.code && data.code === '1') {
                    source = data.result;
                }
            }
        } catch (ex) {
            throw ex;
        }
        return source;
    };

    getSubClassifiers = async (filter) => {
        let subClassifiers = null;
        const classifierEndoint = this._filterEndPoint + URL_FILTER_SUB_CLASSIFIERS;
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + classifierEndoint, apiService.request(filter));
        if (response.status === Number(StatusCodes.OK)) {
            const data = response.data;
            if (data) {
                subClassifiers = data.result;
            }
        }
        return subClassifiers;
    };

    saveCustomerFilter = async (filterName, filterItems) => {
        const body = {
            filter_name: filterName,
            filter: JSON.stringify(filterItems),
            is_default: 'No',
            status: RecordDataStatus.ACTIVE,
        };
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Post;
        const response = await apiService.send(apiUrl.BASE_URL + this._filterCustomerSavedEndPoint, apiService.request(body));
        return response.status === Number(StatusCodes.OK);
    };

    updateCustomerSavedFilter = async (filterToBeUpdate, filterItems) => {
        filterToBeUpdate.filter = JSON.stringify(filterItems);
        const endPoint = URL_CUSTOMER_SAVED_FILTER_UPDATE.replace('{propertyId}', this._propertyId).replace('{user_name}', this._account.userName).replace('{filter_id}', filterToBeUpdate.id.toString());
        const apiService = new APIService(this._account);
        apiService._method = HttpMethodType.Patch;
        const response = await apiService.send(apiUrl.BASE_URL + endPoint, apiService.request(filterToBeUpdate));
        return response.status === Number(StatusCodes.OK);
    };
}
