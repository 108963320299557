import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    createIndustryDimensionValue,
    deleteIndustryDimension,
    fetchAllIndustry,
    getIndustryDimensionValues,
    updateIndustryDimensionValue,
} from '../../../actions';
import IndustryDimensionValues from './industryDimensionValues';

const mapStateToProps = (state, ownProps) => ({
    gridData: state.industryDimensionValue && state.industryDimensionValue.list,
    customers: state.customer.list,
    industryDimensionPermission: state.auth.permissions && state.auth.permissions.industryDimensionValue.create,
    sourceIndustryList: state.sources.industry
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryDimensionValues,
            createIndustryDimensionValue,
            updateIndustryDimensionValue,
            fetchAllIndustry,
            deleteIndustryDimension
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryDimensionValues);
