import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getCustomerDimensionValues, importCustomerDimensionValues,} from "../../../actions";
import CusomerDimensionValueImport from "./customerDimensionValueImport";

const mapStateToProps = (state, ownProps) => ({
    customerDimensions: state.customer.list[ownProps.customerId].dimensions
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            importCustomerDimensionValues,
            getCustomerDimensionValues,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CusomerDimensionValueImport);
