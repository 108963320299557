export const APP_STATUS = {
    ACTIVE: "Active",
    INACTIVE: "Inactive"
};
export const APP_CATEGORIES = {
    LIVE: "Live",
    DEMO: "Demo",
    COMPETITOR: "Competitor",
    GROUP: "Group"
};

export const APPLICATION_MODES = {
    BY_ATTRIBUTE: "By Attribute",
    BY_PATTERN: "By Pattern",
    BY_USER: "By User",
    CREATE: "CREATE",
    EDIT: "EDIT",
    VIEW: "VIEW",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    YES: "Yes",
    NO: "No",
    GROUP_INSTANCE: "Group Instances",
    LIVE: "Live",
    NANO_ID_CHARACTERS: "1234567890",
    NANO_ID_LENGTH: 10,
    COMPETITOR: "Competitor",
    TENANT: "Tenants",
    TATVAM_INAPP: "TATVAM_INAPP",
    PREDICTO: "PREDICTO",
    BLMS: "BLMS",
};
export const APP_BOOLEANS = {
    TRUE: "true"
};
export const FORM_OPERATIONS = {
    "SUBMIT": "Submit",
    DISCARD: "Discard",
    CLOSE: "Close",
    EDIT: "Edit",
    SAVE: "Save",
    DELETE: "Delete",
    RESET_PASSWORD: "Reset Password",
    "SUBMIT": "Submit",
    GENERATE_KEY: "Generate API Key",
    CANCEL: "Cancel",
};

export const MODAL_BUTTONS = {
    OK: "Ok",
    CANCEL: "Cancel"
};

export const ALERT_MESSAGES = {
    CHANGES_WILL_BE_LOST: "Changes will be lost",
    ACCEPTED_IMAGE_FORMATS: "Accepted Formats are jpg, jpeg, png",
    LOGOUT_CONFIRMATION_MESSAGE: "Are you sure. You want to log out?",
    DIRTY_DISCARD: "Changes will be lost",
    DELETE_CONFIRMATION: "Are you sure. You want to delete?",
    INDUSTRY_EXIST: "This industry is being used in one or more properties.Are you sure, You want to modify?",
    SESSION_EXPIRED: "Your Session has expired. Please relogin.",
    SEND_MAIL_CONFIRMATION: "Are you sure. You want to send mail to the selected users?",
};

export const CARD_TEXT = {
    SORT_TITLE: "Sort By",
    SEARCH_IN_TITLE: "Search In",
    SEARCH_TITLE: "Search For"

};

export const VALIDATION_MESSAGES = {
    ENTER_MANDATORY_FIELDS: "Enter all the mandatory fields",
    SELECT_A_SOURCE: "Select a source",
    ENTER_VALID_URL: "Enter valid url for the URL field",
    ENTER_XPATH_OR_REGEX_FIELD: "Atleast one of the Locator or Regex field value is required",
    ENTER_XPATH: "Locator xpath required",
    ENTER_FIELD_OR_TEXT_PATTERN: "Atleast one of the Field Pattern or Text Pattern field value is required",
    ENTITYNAME_INVALID: "Dimension Name minimum 5 & max 25 characters. Only aphabets, underscore & hyphen allowed",
    SEQUENCE_NO_INVALID: "Sequence Number max 3 characters. Only numeric characters allowed",
    ATTRIBUTE_TAGGING_INVALID: "Attribute for tagging minimum 5 & max 25 characters. Only aphabets, underscore & hyphen allowed",
    ENTITY_NAME_VALUE_INVALID: "Dimension Value minimum 5 & max 25 characters. Only aphabets, underscore & hyphen allowed",
    PARENT_ENTITY_NAME_VALUE_INVALID: "Parent Dimension Value minimum 5 & max 25 characters. Only aphabets, underscore & hyphen allowed",
    PATTERN_INVALID: "Pattern minimum 5 & max 25 characters. Only aphabets, underscore & hyphen allowed",
    INVALID_EMAIL: "Provide valid email/s separated by comma for Recipients Field",
    ENTER_TEXT_PATTERN: "Text Pattern field value is required",
    ENTER_OPERATIONS_REPORTS: "Please enter information for atleast one of the Operations or Reports field",
    EITHER_DATE_EXIST: "Either both From and Upto date fields should be blank or should have value",
    START_DATE_SHOULD_BE_LESS_THAN_END_DATE: "From date should be less than Upto date",
    EXCEED_LICENSE_DATE: "Dates provided should be within License From and License Upto date range of the property",
    KEY_COLUMN_CANNOT_BE_BLANK: "Key column cannot be blank",
    DATA_COVERAGE_INVALID: "Data Coverage Period should be less than or equal to 365",
    SELECT_RECIPIENTS: "Please select recipient(s).",
    SELECTED_DIMENSIONS: "Please Select Dimension Type",
};

export const COMPETITOR = {
    COMPETITORS: "Competitors",
    NO_OF_COMPETITOR_VALIDATON_1: "your plan permits ",
    NO_OF_COMPETITOR_VALIDATON_2: " competitors only",
    SELECT_COMPETITORS: "Please select",
    COMPETITOR_ID: "Competitor ID",
    COMPETITOR_NAME: "Competitor Name",
    ADD_COMPETITOR: "Add Competitor",
    EDIT_COMPETITOR: "Edit competitor",
    VIEW_TITLE_COMPETITOR: "View competitor",
    NO_COMPETITORS_AVAILABLE: "No Competitors available",
    INDUSTRY: "Industry",
    VIEW_COMPETITORS: "Competitors",
    VIEW_INDUSTRY: "Industry",
    IS_INSIGHT: "Is Insight",
    STATUS: "Status"
};

export const TENANT = {
    TENANTS: "Tenants",
    NO_OF_TENANT_VALIDATON_1: "your plan permits ",
    NO_OF_TENANT_VALIDATON_2: " tenants only",
    SELECT_TENANTS: "Please select",
    TENANT_ID: "TENANT ID",
    TENANT_NAME: "Tenants Name",
    ADD_TENANT: "Add Tenants",
    EDIT_TENANT: "Edit Tenants",
    VIEW_TITLE_TENANT: "View Tenants",
    NO_TENANTS_AVAILABLE: "No Tenants available",
    INDUSTRY: "Industry",
    VIEW_TENANTS: "Tenants",
    VIEW_INDUSTRY: "Industry",
};

export const PROPERTY = {
    PROPERTY_ID: "Property ID",
    PROPERTY_NAME: "Property Name",
    INDUSTRY: "Industry",
    ADDITIONAL_INDUSTRY: "Additional Industry",
    VIEW_INDUSTRY: "Industry",
    VIEW_INDUSTRY_CLASSIFICATION:'Industry Classification',
    VIEW_ADDITIONAL_INDUSTRY: "Additional Industry",
    SELECT_INDUSTRY: "Please select",
    CATEGORY: "Category",
    SELECT_CATEGORY: "Please select",
    LICENCE_TYPE: "License Type",
    SELECT_LICENCE: "Please select",
    STATUS: "Status",
    GROUP_INSTANCE: "Group Instance",
    VIEW_PROPERTY_ID: "Property ID",
    VIEW_PROPERTY_NAME: "Property Name",
    VIEW_CATEGORY: "Category",
    VIEW_LICENCE_TYPE: "License Type",
    VIEW_LICENCE: "License",
    VIEW_LICENSE_START_DATE: "License From",
    VIEW_LICENSE_END_DATE: "License Upto",
    VIEW_STATUS: "Status",
    PARENT_PROPERTY: "Parent Property",
    ADD_PROPERTY: "Add Property",
    NO_PROPERTY_AVAILABLE: "No Property available",
    DATE_FORMAT: "DD-MMM-YYYY",
    LICENSE_START_DATE: "License From",
    LICENSE_END_DATE: "License Upto",
    UPLOAD_LOGO: "Click to upload a logo",
    EXECUTION_BATCH: "Execution Batch",
    SUBSCRIPTION_BATCH: "Subscription Batch",
    START_DATE_SHOULD_BE_LESS_THAN_END_DATE: "License from date should be less than license upto",
    ENTER_ALL_THE_MANDATORY_FIELDS: "Enter all the mandatory fields",
    VIEW_PROPERTY: "View Property",
    EDIT_PROPERTY: "Edit Property",
    NO_LINKED_PROPERTY_AVAILABLE: "No Linked Property available",
    TOTAL_CUSTOMERS: "Total Association",
    ADDRESS: "Address",
    TIME_ZONE: "Time Zone",
    VIEW_ADDRESS: "Address",
    VIEW_TIME_ZONE: "Time Zone",
    TIME_ZONE_CHANGE_ALERT_MSG: "Changing the Time Zone value requires reprocessing backend data. Please contact administrator.",
    IS_TRANSLATION: "Is Translation",
    IS_ACCURACY: "Is Accuracy",
    COUNTRY: "Country",
    VIEW_COUNTRY: "Country",
    STATE: "State",
    VIEW_STATE: "State",
    WEBSITE: "Website",
    SELECT_STATUS: "Please select",
    IS_ACTION_TRACKABLE: "Is Action Trackable",
    IS_CURSE_WORD: "Is Curse Word",
    IS_CAMPAIGNABLE: "Is Campaignable",
    IS_RESPONSE_TRACKABLE: "Is Response Trackable",
    IS_SOURCE_WEIGHTAGE: "Is Source Weightage",
    MAX_CAPACITY: "Max Capacity",
    PREDICTION_BATCH: "Prediction Batch",
    PREDICTION_PERCENTAGE: "Prediction Percentage",
    PREDICTO_SUBSCRIPTION_BATCH: "Predicto Subscription Batch",
    IS_CHATBOT: "Is Chatbot",
    DEFAULT_REPORT:"Default Report",
    SELECT_DEFAULT_REPORT:"Please Select Default Report",
    SELECT_RATING_TYPE:"Please Select Rating Type",
    RATING_TYPE:"Rating Type",
    SUPPORT_EMAIL:"Support Email",
    DATABASE_NAME: "Database Name",
    SELECT_DATABASE_TYPE:"Please Select Database Name",
};

export const PROPERTY_RULES = {
    ALERT_RULE_TYPE: "Rule Type",
    SELECT_RULE_TYPE: "Please select",
    ALERT_NAME: "Name",
    SEQ_NO: "Sequence No",
    FIELD_PATTERN: "Field Pattern",
    VALIDATE: "Validate",
    ALERT_DESCRIPTION: "Alert Description",
    RECIPIENTS: "Recipients",
    PLEASE_ENTER_MAIL_ID_OF_THE_RECIPIENTS: "Please enter mail id of the recipients",
    VIEW_ALERT_RULE_TYPE: "Rule Type",
    VIEW_ALERT_NAME: "Name",
    VIEW_SEQ_NO: "Sequence No",
    VIEW_ALERT_DESCRIPTION: "Alert Description",
    VIEW_RECIPIENTS: "Recipients",
    LAST_ALERTED: "Last alerted",
    TEXT_PATTERN: "Text Pattern",
    TIMES_ALERTED: "# Times Alerted",
    STATUS: "Status",
    ADD_RULE: "Add Rule",
    EDIT_RULE: "Edit Rule",
    VIEW_TITLE_RULE: "View Rule",
    MAIL_RECIPIENTS: "Recipients",
};

export const PROPERTY_USERS = {
    USER: "Username",
    SELECT_USER: "Please select",
    ROLE: "Role",
    SELECT_ROLE: "Please select",
    STATUS: "Status",
    VIEW_USER: "Username",
    VIEW_ROLE: "Role",
    ADD_USER: "Assign User Role",
    EDIT_USER: "Edit User Role",
    VIEW_TITLE_USER: "View User Role",
    NO_USERS_AVAILABLE: " No Users available",
    USER_NAME: "Username",
    EMAIL: "Email",
    DISPLAY_NAME: "Display Name",
    SEND_MAIL: "Send Mail",
    SELECT_MAIL_TEMPLATE: "Please select",
    DEFAULT_REPORT: "Default Report",
    SELECT_REPORT: "Please select",
    KEY_ID: "Key ID",
    KEY_SECRET: "Key Secret"
};
export const FOOTER = {
    POWERED_BY: "Powered by",
    ALL_RIGHTS_RESERVED: "All rights reserved"
};
export const PROPERTY_CHILDREN = {
    PROPERTIES: "Properties",
    VIEW_Properties: "Properties",
    SELECT_PROPERTIES: "Select properties"
};

export const PROPERTY_ROLES = {
    NAME: "Name",
    ROLE_NAME: "role name",
    STATUS: "Status",
    OPERATIONS: "Operations",
    SELECT_OPERATIONS: "Please select",
    REPORTS: "Reports",
    SELECT_REPORTS: "Please select",
    VIEW_NAME: "Name",
    VIEW_OPERATIONS: "Operations ",
    VIEW_REPORTS: "Reports",
    ADD_ROLE: "Add Role",
    EDIT_ROLE: "Edit Role",
    VIEW_TITLE_ROLE: "View Role",
    NO_ROLES_AVAILABLE: "No Roles available",
    FEEDBACK_CAMPAIGNS: "Feedback Campaigns",
    ACTION_TRACKING: "Action Tracking",
    RESPONSE_TRACKING: "Response Tracking",
    RESPONSE_DASHBOARD: "Response Dashboard",
    SOURCE_WAITHAGE: "Source Weightages",
    APPLICATION_NAME: "Application Name",
    PLEASE_SELECT: "Please select",
    TICKET_DASHBOARD: "Tickets Dashboard",
    PREDICTO_ACCESS: "Predicto Access",
    KPI_DASHBOARD_360: "360 KPI Dashboard",
    JFK_KPI_DASHBOARD_360: "JFK 360 KPI Dashboard",
    ADHOC_REPORT: "Adhoc Report",
    MANAGE_SUBSCRIPTION: "Manage Subscription",
    ATL_360_KPI_DASHBOARD: "ATL 360 KPI Dashboard",
    CUSTOMER_FEEDBACK_DASHBOARD: "Customer Feedback Dashboard",
    REPORT_TYPE: "Report Type",
    CHATBOT: "Chatbot",
    SURVEY_DESIGNER: "Survey Designer"
};
export const SOURCE_XPATH = {
    VIEW_NAME: "Name",
    XPATH: "Locator",
    VIEW_XPATH: "Locator",
    STATUS: "Status",
    SOURCE_ID: "Source ID",
    ADD_XPATH: "add Xpath",
    EDIT_XPATH: "Edit Xpath",
    VIEW_TITLE_XPATH: "View Xpath",
    MANDATE: "Is Mandatory ?",
    SOURCE_NAME: "Source Name",
    WEB_SOURCE_XPATH: "Web Source Xpath",
    VIEW_URL: "URL",
    SLEEP_TIME: "Sleep Time",
    SOURCE_STATUS: "Source Status",
    TAB_XPATH_TITLE: "Xpath Details",
    ATTR_DETAILS: "Attribute Details",
    ATTR_GRID_KEY: "Key",
    ATTR_GRID_VALUE: "Value",

};
export const SOURCE_INDUSTRY = {
    SOURCE_MASTER: "Industry Master",
    NAME: "Name",
    VIEW_NAME: "Name",
    XPATH: "Xpath",
    VIEW_XPATH: "Xpath",
    REGEX: "Regex",
    STATUS: "Status",
    SOURCE_ID: "Industry ID",
    ADD_INDUSTRY: "add Industry",
    EDIT_INDUSTRY: "Edit Industry",
    VIEW_INDUSTRY: "View Industry",
    NO_INDUSTRY_FOUND: "No Industry found",
    ID: "ID"
};
export const USER_FORM = {
    USER_NAME: "Username",
    DISPLAY_NAME: "Display Name",
    EMAIL: "Email",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    CHANGE_PASSWORD: "Change Password",
    CUSTOMER_ADMIN: "Customer Admin",
    VIEW_USER_NAME: "Username",
    VIEW_DISPLAY_NAME: "Display Name",
    EMAIL_PACEHOLDER: "example@mail.com",
    PASSWORD_PACEHOLDER: "Minimum of 8 characters",
    CONFIRM_PASSWORD_PACEHOLDER: "Minimum of 8 characters",
    STATUS: "Status",
    ADD_USER: "Add User",
    VIEW_USER: "View User",
    EDIT_USER: "Edit User",
    VIEW_FIRST_NAME: "First Name",
    VIEW_LAST_NAME: "Last Name",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    LASTLOGIN: "Last Login",
    TOTAL_CUSTOMERS: "Total Customers",
    PASSWORD_MATCH: "Password entered in the New Password and Confirm Password fields do not match",
    INVALID_EMAIL_ID: "Please enter a valid email in the Email field",
    INVALID_Password_Length: "Password and Confirm Password fields should be atleast 8 characters long",
    DEFAULT_PROPERTY: "Default Property",
    PLEASE_SELECT: "Please select",
    User_TYPE: "User Type",
    COUNTRY: "Country",
    STATE: "State",
    DATA_REFRESH_MSG: "User data has been refreshed successfully.",
    TOTAL_ALERTS:"Total Alerts",
    TOTAL_SUBSCRIPTION:"Total Subscription",
    IS_MOBILE_ENABLE:"Mobile Enabled",
    IS_CUSTOMER_API_ENABLED:"Customer Api Enabled"
};

export const USER_PROPERTY_ROLES = {
    ROLE: "Role",
    SELECT_ROLE: "Please select",
    STATUS: "Status",
    PROPERTY: "Property",
    VIEW_PROPERTY: "Property",
    SELECT_PROPERTY: "Please select",
    VIEW_ROLE: "Role",
    NAME: "Property Name",
    CUSTOMER_ID: "Property ID",
    EDIT_ROLE: "Edit Role",
    VIEW_TITLE_ROLE: "View Role",
    ASSIGN_ROLE: "Assign Role",
    ADD_PROPERTY_ROLE: "Assign A Property",
    NO_CUSTOMERS_FOUND: "No customers found",
    INSTANCE: "Instance Type",
    LAST_LOGIN: "Last Login",
    DEFAULT_PROPERTY: "Default Property",
    DEFAULT_REPORT: "Default Report"
};

export const SOURCE_MASTER = {
    SOURCE_MASTER: "Source Master",
    WEB_SOURCE_MASTER: "Web Source Master",
    API_SOURCE_MASTER: "API Source Master",
    EDIT_SOURCE: "Edit source",
    ID: "Source ID",
    NAME: "Name",
    CREATED_BY: "Created by",
    CREATE_SOURCE: "Create Source",
    STATUS: "Status",
    SOURCE_TYPE_WEB: "webSource",
    SOURCE_TYPE_API: "apiSource",
    LOGO: "Logo",
    URL: "URL",
    TOTAL_XPATHS: "Total Xpaths",
    TOTAL_CUSTOMERS: "Total Customers",
    DATE_FORMAT: "Date Format",
    LIST_LINK: " List Link",
    DETAILS_LINK: "Details Link",
    SLEEP_TIME: "Sleep Time",
    BROWSER: "Browser",
    TOTAL_REVIEW: "Total Reviews",
    SELECTOR_XPATH: "Selector XPath",
    LIST_ORDER: "List Order",
    LIST_NUMBER: "List Numbers"
};

export const WEB_SOURCE = {
    SOURCE: "Source ",
    ID: "ID",
    NAME: "Source",
    SELECT_SOURCES: "Please select",
    DATA_COVERAGE: "Data Coverage",
    START_DATE: "From",
    START_DATE_REQUIRED: "From",
    CLICK_TO_SELECT_START_DATE: "Click to select start date",
    END_DATE_REQUIRED: "Upto",
    END_DATE: "Upto",
    CLICK_TO_SELECT_END_DATE: "Click to select end date",
    CATEGORY: "Category",
    SELECT_CATEGORY: "Select Category",
    LAST_POST_ID: "Last Post ID",
    LAST_POST_DATE: "Last Post Date",
    LAST_PAGE_URL: "Last Page URL",
    VIEW_SOURCE: "Source",
    VIEW_CATEGORY: "Category",
    VIEW_SELECT_SOURCES: "Select Sources",
    VIEW_DATA_COVERAGE: "Data Coverage",
    VIEW_START_DATE: "From",
    VIEW_END_DATE: "Upto",
    VIEW_USERNAME: "Username",
    VIEW_PASSWORD: "Password",
    START_DATE_SHOULD_BE_LESS_THAN_END_DATE: "From date should be less than Upto date",
    EXCEED_LICENSE_DATE: "Dates provided should be within License From and License Upto date range of the property",
    ENTER_VALID_URL: "Please enter a valid url for Review URL field",
    ENTER_ALL_THE_MANDATORY_FIELDS: "Enter all the mandatory fields",
    STATUS: "Status",
    ADD_SOURCE: "Add Source",
    EDIT_SOURCE: "Edit Source",
    VIEW_TITLE_SOURCE: "View Source",
    NO_WEB_SOURCE_AVAILABLE: "No Web Source available",
    CREDENTIALS_REQUIRED: "Credentials Required",
    USER_NAME: "Username",
    PASSWORD: "Password",
    DATE_FORMAT: "DD-MMM-YYYY",
    DZERO_CUSTOMER: "0000",
    COMMENT_TYPE: "Comment Type",
    CREDENTIAL_REQUIRED: "Credential Required",
    SHOW_PASSWORD: "Show Password",
    WEB_SOURCE_TYPE: "webSource",
    API_SOURCE_TYPE: "apiSource",
    VIEW_EXECUTION_DATE: "Execution Date",
    MESSAGE_DATE: "Message Date",
    VIEW_IS_LIVE_FEED: "Is Live Feed",
    VIEW_IS_RESPONDABLE: "Is Respondable",
    VIEW_IS_PUBLIC: "Is Public",
    VIEW_IS_ACTION_TRACKABLE: "Is Action Trackable",
    VIEW_IS_ACCURACY:"Is Accuracy",
    RATING_TYPE:"Rating Type",
    VIEW_MESSAGE_DATE: "Message Date",
};

export const API_SOURCE = {
    ID: "ID",
    NAME: "Source",
    CATEGORY: "category",
    COMMENT_TYPE: "Comment Type",
    LAST_EXECUTION: "Last Execution",
    ADD_SOURCE: "Add Source",
    NO_API_SOURCE_AVAILABLE: "No API source available",
    STATUS: "Status",
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    GOOGLE: "Google",
    TWITTER: "Twitter",
    EDIT_SOURCE: "Edit API Source",
    VIEW_TITLE_SOURCE: "View API Source",
    SELECT_A_SOURCE: "Select a source",
    SELECT_API_SOURCE: "Please select",
    URL: "URL",
    IS_LIVE_FEED: "Is Live Feed",
    API_SOURCE_TYPE: "apiSource",
    VIEW_EXECUTION_DATE: "Execution Date",
    MESSAGE_DATE: "Message Date",
    START_DATE: "From",
    END_DATE: "Upto",
    COMMENT_TYPE_LABEL: "Comment Type",
    VIEW_START_DATE: "From",
    VIEW_END_DATE: "Upto",
    VIEW_COMMENT_TYPE: "Comment Type",
    IS_RESPONDABLE: "Is Respondable",
    DATE_FORMAT: "DD-MMM-YYYY",
    SELECT_COMMENT_TYPE: "Please select",
    ADD_NEW_ROW: "Add New Row",
    IS_PUBLIC: "Is Public",
    IS_ACTION_TRACKABLE: "Is Action Trackable",
    IS_SINGLE_REVIEW_REFRESH: "Is Single Review Refresh",
    RESPONSE_STATUS_LABEL: "Response Status",
    VIEW_IS_PUBLIC: "Is Public",
    CREDENTIAL_REQUIRED: "Credential Required",
    IS_ACCURACY:"Is Accuracy",
    RATING_TYPE:"Rating Type",
    VIEW_MESSAGE_DATE: "Message Date",
};

export const PREDICTION_SOURCE = {
    ID: "ID",
    NAME: "Source",
    CATEGORY: "category",
    COMMENT_TYPE: "Comment Type",
    LAST_EXECUTION: "Last Execution",
    ADD_SOURCE: "Add Source",
    NO_PREDICTION_SOURCE_AVAILABLE: "No Prediction source available",
    STATUS: "Status",
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    GOOGLE: "Google",
    TWITTER: "Twitter",
    EDIT_SOURCE: "Edit Prediction Source",
    VIEW_TITLE_SOURCE: "View Prediction Source",
    ADD_TITLE_SOURCE: "Add Prediction Source",
    SELECT_A_SOURCE: "Select a source",
    SELECT_PREDICTION_SOURCE: "Please Select Extractor Type",
    URL: "URL",
    IS_LIVE_FEED: "Is Live Feed",
    PREDICTION_SOURCE_TYPE: "apiSource",
    VIEW_EXECUTION_DATE: "Execution Date",
    START_DATE: "From",
    END_DATE: "Upto",
    COMMENT_TYPE_LABEL: "Comment Type",
    VIEW_START_DATE: "From",
    VIEW_END_DATE: "Upto",
    VIEW_COMMENT_TYPE: "Comment Type",
    IS_RESPONDABLE: "Is Respondable",
    DATE_FORMAT: "DD-MMM-YYYY",
    SELECT_COMMENT_TYPE: "Please select",
    ADD_NEW_ROW: "Add New Row",
    IS_PUBLIC: "Is Public",
    IS_ACTION_TRACKABLE: "Is Action Trackable",
    IS_SINGLE_REVIEW_REFRESH: "Is Single Review Refresh",
    RESPONSE_STATUS_LABEL: "Response Status",
    EXTRACTION_TYPE: "Extraction type",
    EXTRACTION_TYPE_SELECTION_TITLE: "Please Select Extractor Type",
    SOURCENAME: "Name"
};
export const SIGN_IN = {
    USER_NAME: "Email",
    PASSWORD: "Password",
    SING_IN: "Login"
};

export const API_SOURCE_DETAILS = {
    NAME: "Name",
    URL: "URL",
    ADD_API_DETAILS: "Add API Details",
    VALID_URL: "Please enter a valid url for the URL field",
    VALID_NAME: "Enter Valid Name",
    STATUS: "Status",
    EDIT_API_SOURCE_DETAILS: "Edit API Details",
    VIEW_API_SOURCE_DETAILS: "View API Details",
    API_SOURCE_DETAILS: "API Source Details",
    SOURCE_NAME: "Source Name",
    VIEW_NAME: "Name",
    VIEW_URL: "URL",
    SOURCE_ID: "Source ID",
    SLEEP_TIME: "Sleep Time",
    SOURCE_STATUS: "Source Status",
    TAB_API_DETAILS_TITLE: "API Details",
    ATTR_DETAILS: "Attribute Details",
    ATTR_GRID_KEY: "Key",
    ATTR_GRID_VALUE: "Value"
}

export const COMMENT_TYPE = {
    TYPE: "Comment Type",
    SELECT_TYPE: "Select Type",
    VIEW_TYPE: "Type"
}

export const PROPERTY_DIMENSION = {
    ENTITY_TYPE: "Name",
    PARENT_ENTITY: "Parent",
    DESCRIPTION: "Description",
    SEQUENCE_NO: "Sequence No",
    IS_FILTER: "Is Filter",
    IS_PUBLIC: "Is Public",
    IS_TAGGING: "Is Tagging",
    STATUS: "Status",
    IS_DISPLAY_TAG: "Is Display Tag",
    IS_CLICKABLE: "Is Clickable",
    ATTRIBUTE_FOR_TAGGING: "Attribute For Tagging",
    ADD_DIMENSION: "Add Dimension",
    EDIT_DIMENSION: "Edit Dimension",
    VIEW_TITLE_DIMENSION: "View Dimension",
    SELECT_PARENT_ENTITY: "Please select",
    VIEW_ENTITY_TYPE: "Name",
    VIEW_SEQUENCE_NO: "Sequence No",
    DZERO_CUSTOMER: "0000",
    SELECT_PARENT_ENTITY_VALUE: "Please select",
    VIEW_ATTRIBUTE_FOR_TAGGING: "Attribute For Tagging",
    TAG_TREATMENT: "Tag Treatment",
    VIEW_TAG_TREATMENT: "Tag Treatment",
    DIMENSION_TYPE: "Dimension Type",
}


export const PROPERTY_DIMENSION_GRID = {
    ID: "ID",
    ENTITY_TYPE: "Name",
    PARENT_ENTITY: "Parent",
    STATUS: "Status",
    SEQUENCE_NO: "Sequence No",
    IS_FILTER: "Is Filter",
    IS_TAGGING: "Is Tagging",
    IS_DISPLAY_TAG: "Is Display Tag",
    IS_CLICKABLE: "Is Clickable",
    ATTRIBUTE_FOR_TAGGING: "Attribute For Tagging",
    TAG_TREATMENT: "Tag Treatment",
    IS_PUBLIC: "Is Public",
}


export const PROPERTY_DIMENSION_VALUE_GRID = {
    ID: "ID",
    ENTITY_TYPE: "Dimension Name",
    ENTITY_VALUE: "Dimension Value",
    PARENT_ENTITY: "Parent Name",
    PARENT_ENTITY_VALUE: "Parent Value",
    SEQUENCE_NO: "Sequence No",
    STATUS: "Status",
    PATTERN: "Pattern",
    IS_PUBLIC: "Is Public",
}

export const PROPERTY_DIMENSION_VALUE = {
    ADD_DIMENSION_VALUE: "Add Dimension Value",
    EDIT_DIMENSION_VALUE: "Edit Dimension Value",
    VIEW_DIMENSION_VALUE: "View Dimension Value",
    ENTITY_TYPE: "Name",
    ENTITY_TYPE_VALUE: "Value",
    PARENT: "Parent",
    PARENT_ENTITY_TYPE: "Parent Name",
    PARENT_ENTITY_TYPE_VALUE: "Parent Value",
    PARENT_ENTITY_TYPE_VALUE_REQUIRED: "Parent Value",
    PARENT_ENTITY_TYPE_REQUIRED: "Parent Name",
    SEQUENCE_NO: "Sequence No",
    PATTERN: "Pattern",
    PATTERN_REQUIRED: "Pattern",
    STATUS: "Status",
    VIEW_ENTITY_TYPE: "Name",
    VIEW_ENTITY_TYPE_VALUE: "Value",
    VIEW_PARENT_ENTITY_TYPE: "Parent Name",
    VIEW_PARENT_ENTITY_TYPE_VALUE: "Parent Value",
    VIEW_SEQUENCE_NO: "Sequence No",
    VIEW_PATTERN: "Pattern",
    VIEW_STATUS: "Status",
    SELECT_PARENT_ENTITY: "Please select",
    SELECT_PARENT_ENTITY_VALUE: "Please select",
    ATTRIBUTE_FOR_TAGGING: "Attribute For Tagging",
    IS_PUBLIC: "Is Public"
}

export const INDUSTRY_DIMENSION_VALUE = {
    INDUSTRY_NAME: "Industry Name",
    ENTITY_TYPE: "Parent Value",
    ENTITY_TYPE_VALUE: "Dimension Value",
    SELECT_INDUSTRY: "Please select",
    ADD_INDUSTRY_DIMENSION_VALUE: "Add Industry Dimension",
    EDIT_INDUSTRY_DIMENSION_VALUE: "Edit Industry Dimension",
    VIEW_INDUSTRY_DIMENSION_VALUE: "View Industry Dimension",
    PATTERN: "Pattern",
    CLASSIFIER: "CLASSIFIER",
    SELECT_PARENT_ENTITY: "Please select",
    VIEW_INDUSTRY_NAME: "Industry Name",
    VIEW_ENTITY_TYPE: "Parent Value",
    VIEW_ENTITY_TYPE_VALUE: "Dimension Value",
    VIEW_SEQUENCE_NO: "Sequence No",
    VIEW_PATTERN: "Pattern",
    SEQUENCE_NO: "Sequence No",
    STATUS: "Status",
    NO_INDUSTRY_DIMENSION_VALUES_FOUND: "No Industry Dimension Values found",
    INDUSTRY_DIMENSION: "Industry Dimension",
}

export const INDUSTRY_DIMENSION_VALUE_GRID = {
    ID: "ID",
    ENTITY_TYPE: "Parent Value",
    ENTITY_VALUE: "Dimension Value",
    PARENT_ENTITY: "Parent Dimension Name",
    PARENT_ENTITY_VALUE: "Parent Dimension Value",
    SEQUENCE_NO: "Sequence No",
    STATUS: "Status",
    PATTERN: "Pattern",
    INDUSTRY: "Industry Name",
    DETAILS: "Dimension Details",
    NAME: "Name"

}

export const TEXT_VALIDATION = {
    EXECUTION_BATCH: "Only alphabets, numerics and underscores allowed. Max length 25",
    SEQ_NO: "Only numeric characters allowed. Max length 3",
    RECIPIENT_MAIL_PLACEHOLDER: "Please enter recipients separated by comma"
}

export const SWITCH_TEXT = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    YES: "Yes",
    NO: "No",
}

export const PROPERTY_RESPONSE_TEMPLATES = {
    VIEW_TEMPLATE_NAME: "Name",
    VIEW_SOURCE_NAME: "Source",
    VIEW_SOURCE_TYPE: "Type",
    VIEW_TEMPLATE: "Template",
    STATUS: "Status",
    ADD_RESPONSE_TEMPLATE: "Add Response Template",
    NO_RESPONSE_TEMPLATE_AVAILABLE: "No Response Templates available",
    TEMPLATE_NAME: "Name",
    SOURCE_NAME: "Source",
    TEMPLATE: "Template",
    SELECT_SOURCE: "Please select",
    VIEW_TITLE_RESPONSE_TEMPLATE: "View Response Template",
    EDIT_RESPONSE_TEMPLATE: "Edit Response Template",
    API_SOURCE_TYPE: "apiSource"

}

export const PROPERTY_REPORT_SUBSCRIPTION = {
    VIEW_SUBSCRIPTION_NAME: "Subscription Name",
    VIEW_REPORT_NAME: "Report",
    VIEW_DELIVERY_FORMAT: "Delivery Format",
    VIEW_SCHEDULE: "Schedule",
    VIEW_DATA_COVERAGE_PERIOD: "Data Coverage Period",
    NO_REPORT_SUBSCRIPTION_AVAILABLE: "No Report Subscription available",
    VIEW_STATUS: "Status",
    ADD_REPORT_SUBSCRIPTION: "Add Report Subscription",
    VIEW_REPORT_SUBSCRIPTION: "View Report Subscription",
    EDIT_REPORT_SUBSCRIPTION: "Edit Report Subscription",
    SUBSCRIPTION_NAME_PLACEHOLDER: "Enter Report Subscription Name",
    SUBSCRIPTION_PROPERTY: "Subscription Property",
    SUBSCRIPTION_NAME: "Subscription Name",
    SELECT_SUBSCRIPTION_PROPERTY: "Please Select",
    SUBSCRIPTION_FREQUENCY: "Subscription Frequency",
    REPORT: "Report",
    SELECT_REPORT: "Please Select",
    DATA_COVERAGE_PERIOD: "Data Coverage Period",
    DELIVERY_FORMAT: "Delivery Format",
    STATUS: "Status",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email",
    RECIPIENTS: "Recipient(s)",
    UNIT: "Unit",
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    PDF: 'PDF',
    HTML: 'HTML',
    ACTIVE: "Active",
    INACTIVE: "In-Active",
    PERIOD: "Period",
    VIEW_SUBSCRIPTION_PROPERTY: "Subscription Property",
    VIEW_SUBSCRIPTION_NAME: "Subscription Name",
    VIEW_SUBSCRIPTION_FREQUENCY: "Subscription Frequency",
    VIEW_REPORT: "Report",
    VIEW_DELIVERY_FORMAT: "Delivery Format",
    VIEW_STATUS: "Status",
    ADD_FILTER: "Add  Filter",
    EDIT_VIEW_FILTER: "View / Edit  Filter",
    VIEW_DATA_COVERAGE_PERIOD: "Data Coverage Period",
    PREVIOUS: "Previous",
    LAST_EXECUTION: "Last Execution",
    APPLICATION_NAME: "Application Name"
}

export const FILE_UPLOAD_CONSTANTS = {
    "SELECT_CATEGORY": "Please select",
    "FILE_SIZE_EXCEED": "File size exceeds the 1Mb file limit.",
    "SELECTED_FILE_NAME": "Selected File Name: ",
    "FILE_UPLOAD_ERROR": "Could not upload the file!",
    "DOWNLOAD_ERROR_TEXT": "Click the download link to view the error details.",
    "CSV_FILE_ALLOWED": "Only .csv extension file allowed."

}

export const PROPERTY_PREDICTION_INFLUENCER = {
    ADD_INFLUENCER_TITLE: "Add Influencer",
    INFLUENCER_NAME: "Influencer Name",
    STATUS: "Status",
    INFLUENCER_DESCRIPTION: "Influencer Description",
    NAME: "Name",
    DESCRIPTION: "Description",
    INFLUERCER_ID: "Influencer Id",
    EDIT_INFLUENCER_TITLE: "Edit Influencer",
    VIEW_INFLUENCER_TITLE: "View Influencer"
}

export const DATE_FORMAT = {
    YYYYMMDD: "YYYY-MM-DD",
    ddMMMyyy: "dd-MMM-yyyy",
    DDMMMYYYY: "DD-MMM-YYYY"
}

export const USAGE_LOG_PROPERTIES = {
    EXTERNAL: "External",
    INTERNAL: "Internal",
    CUSTOMER_ID: "customer_id",
    CUSTOMER_NAME: "customer_name",
    REPORT_NAME: "report_name",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    EMAIL_ID: "email",
    FROM_DATE: "first_login",
    TO_DATE: "last_login",
    ACTUAL_LAST_LOGIN: "actual_last_login",
    REPORT_USED_FIRST: "report_used_first",
    REPORT_USED_LAST: "report_used_last",
    TOTAL_LOGINS: "total_logins",
    NO_TIMES_USED: "no_times_used",
    NOT_LOGGED_IN_FOR: "not_logged_in_for",
    NOT_USED_FOR: "not_used_for",
    ALL: "All",
    TATVAM_WEB: "Tatvam Web",
    PREDICTO: "PREDICTO",
    LOG_SOURCE: "log_source",
    APPLICATION_DISPLAY_NAME: "application_display_name",
    USER_TYPE: "user_type",
    TATVAM_INAPP: "TATVAM_INAPP",
    CATEGORY: "category",
    INDUSTRY: "industry",
    CORPORATE: "corporate",
    CUSTOMER_STATUS: "customer_status",
    USER_STATUS: "user_status",
    IS_INCLUDE_INTERNAL_USERS: "is_include_internal_users",
    IS_INCLUDE_INACTIVE_CUSTOMERS: "is_include_inactive_customers",
    IS_INCLUDE_INACTIVE_USERS: "is_include_inactive_users",
    AVG_TIMES_BTN_LOGINS: "avg_time_between_logins"
}

export const USAGE_LOG_LABELS = {
    TATVAM_WEB: "Tatvam Web",
    CUSTOMER_ID: "Property ID",
    CUSTOMER_NAME: "Property Name",
    REPORT_NAME: "Report Name",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL_ID: "Email Id",
    FROM_DATE: "From Date",
    TO_DATE: "To Date",
    ACTUAL_LAST_LOGIN: "Actual Last Login",
    REPORT_USED_FIRST: "From Date",
    REPORT_USED_LAST: "To Date",
    TOTAL_LOGINS: "Total Logins",
    TOTAL_USED: "Total Used",
    NOT_LOGGED_IN_FOR: "Not Logged In For",
    NOT_USED_FOR: "Not Used For",
    ALL: "All",
    PREDICTO: "Predicto",
    CUSTOMER_USERS_USAGE_LOGS: "Customer Users Usage Logs",
    CUSTOMER_USAGE_LOGS: "Customer Usage Logs",
    CUSTOMER_REPORTS_USAGE_LOGS: "Customer Reports Usage Logs",
    CUSTOMER_USER_REPORTS_USAGE_LOGS: "Customer Users Reports Usage Logs",
    REPORT_USAGE_LOGS: "Report Usage Logs",
    CUSTOMER: "Property",
    APPLICATION: "Application",
    CUSTOMER_CATEGORY: "Property Category",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    INCLUDE_INTERNAL_USERS: "Include Internal Users",
    INCLUDE_INACTIVE_CUSTOMERS: "Include InActive properties",
    INCLUDE_INACTIVE_USERS: "Include InActive Users",
    INTERNAL: "Internal",
    EXTERNAL: "External",
    RESET: "Reset",
    SUBMIT: "Submit",
    USER_TYPE: "User Type",
    TATVAM_INAPP: "TATVAM_INAPP",
    CATEGORY: "Category",
    INDUSTRY: "Industry",
    CORPORATE: "Corporate",
    STATUS: "Status",
    CUSTOMER_STATUS: "Property Status",
    USER_STATUS: "User Status",
    AVG_TIMES_BTN_LOGINS: "Avg Time Between Logins"
}

export const CATEGORY_LIST_OPTIONS = [
    {label: APP_CATEGORIES.COMPETITOR, value: APP_CATEGORIES.COMPETITOR},
    {label: APP_CATEGORIES.DEMO, value: APP_CATEGORIES.DEMO},
    {label: APP_CATEGORIES.GROUP, value: APP_CATEGORIES.GROUP},
    {label: APP_CATEGORIES.LIVE, value: APP_CATEGORIES.LIVE}
]


export const ROLES = {
    CUSTOMER_API: "Customer API"
}

export const REPORT_FORMAT = {
    HTML: "HTML"
}