import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAllCustomers, fetchCustomerDetailsById, fetchCustomerParent} from "../../actions";
import CustomerDetails from "./customerDetails";

const mapStateToProps = (state, ownProps) => ({
    customerList: state.customer.list[ownProps.location.state && ownProps.location.state.id]
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        fetchAllCustomers,
        fetchCustomerParent,
        fetchCustomerDetailsById
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
