import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetCommentTypeSuccess: (payload) => ({
        type: "GET_COMMENT_TYPE_SUCCESS",
        payload
    }),
    GetCommentTypeError: (payload) => ({
        type: "GET_COMMENT_TYPE_ERROR",
        payload
    })
};


export const fetchCommentType = () => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_COMMENT_TYPE;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const commentList = {};
                response.data.data.map((data) => {
                    commentList[data.id] = data;
                });

                dispatch(actions.GetCommentTypeSuccess(commentList));
                return response;

            }
            dispatch(actions.GetCommentTypeError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetCommentTypeError(error.message));
            return Promise.resolve(error);
        });
};
