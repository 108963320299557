import React, {Fragment} from 'react';

import Loader from 'react-loaders';
import {Col, Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    API_SOURCE_DETAILS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    SWITCH_TEXT,
} from '../../../constants/appConstants';
import {trimCapitalize, validateName, validateUrl,} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class ApiSourceDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationMode: props.applicationMode,
            editFlag: {},
            isActive: false,
            status: "Inactive",
            valid: {
                name: true,
                url: true
            }
        }
    }

    componentDidMount() {
        let isActive;
        if (this.props.formData) {
            this.setState({
                ...this.props.formData,
                isActive: trimCapitalize(this.props.formData.status) === APPLICATION_MODES.ACTIVE
            });
            if (this.props.formData.status)
                isActive = trimCapitalize(this.props.formData.status) === APPLICATION_MODES.ACTIVE;
        }
        this.setState({
            isActive
        });
    }

    componentWillReceiveProps() {
        let isActive;
        if (props.formData) {
            this.setState({
                ...props.formData,
                isActive: trimCapitalize(props.formData.status) === APPLICATION_MODES.ACTIVE
            });
            if (props.formData.status)
                isActive = trimCapitalize(props.formData.status) === APPLICATION_MODES.ACTIVE;
        }
        this.setState({
            isActive
        });
    }

    _discard = (data) => {
        if (data === "ok") {
            this.props.closeModal();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                [e.target.name]: true
            }
        });

        if (e.target.id === "status") {
            this.setState({
                isActive: e.target.checked,
                [e.target.id]: e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE,
                editFlag: {
                    [e.target.id]: true
                }
            });
        }

    };

    _validations = () => {
        let valid = this.state.valid;
        if (this.state.url) {
            valid.url = validateUrl(this.state.url)
        }
        if (this.state.name) {
            valid.name = validateName(this.state.name)
        }
        this.setState({
            valid
        }, () => {
        });
        return Promise.resolve(true);
    }

    _handleSave = e => {
        e.preventDefault();
        this._validations().then((response) => {
            if (response) {
                if (this.state.valid.url && this.state.valid.name) {
                    let {name, url, status} = this.state;
                    let apiSourceData = {
                        name,
                        source_id: this.props.sourceId,
                        url,
                        status
                    };
                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        apiSourceData.created_by = this.props.userName;
                        this.setState({
                            preLoader: true
                        });
                        this.props.actions.createAPISource(apiSourceData).then((responseSource) => {
                            this.setState({
                                preLoader: false
                            });

                            if (responseSource.status === 200) {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: true,
                                    alert_information: responseSource.data.message
                                });
                            } else {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseSource.data ? responseSource.data.message : responseSource.message
                                });

                            }
                        });
                    }
                    if (this.state.applicationMode === APPLICATION_MODES.EDIT) {

                        apiSourceData = {
                            ...this.props.formData
                        };
                        apiSourceData.name = name;
                        apiSourceData.source_id = this.props.sourceId
                        apiSourceData.url = url;
                        apiSourceData.status = status;
                        apiSourceData.updated_by = this.props.userName;

                        this.setState({
                            preLoader: true
                        });
                        this.props.actions.updateAPISource(apiSourceData).then((responseSourceData) => {
                            this.setState({
                                preLoader: false
                            });

                            if (responseSourceData.status === 200) {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: true,
                                    alert_information: responseSourceData.data.message
                                });
                            } else {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseSourceData.data ? responseSourceData.data.message : responseSourceData.message
                                });
                            }
                        });
                    }
                }
            }
        });

    }


    _triggerDiscard = () => {
        if (Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST
            });

        } else {
            this.props.closeModal();
        }
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    _triggerSubmit = () => {
        this.refs.submit.click();

    };

    render() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({inValidForm: true});
                }, true);
        }
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{API_SOURCE_DETAILS.VIEW_API_SOURCE_DETAILS}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{API_SOURCE_DETAILS.EDIT_API_SOURCE_DETAILS}</h4>) : ""}</Row>
                <Row>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <form onSubmit={(e) => this._handleSave(e)} className="mt-2">
                            <TatvamTextBox
                                className={(this.state.inValidForm && !this.state.name || !this.state.valid.name) ? "labelText mt-0 validate project_name mb-8 invalid" : "labelText mt-0 validate project_name mb-8"}
                                s={6}
                                m={6}
                                l={6}
                                xl={6}
                                required
                                label={
                                    <span>
                                        {API_SOURCE_DETAILS.NAME}
                                        <span style={{color: 'red'}}> *</span>
                                    </span>
                                }
                                name="name"
                                onChange={this._handleInput}
                                value={this.state.name}
                                disabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                placeholder=""
                                autocomplete="off"
                                maxlength={255}
                            />
                            <Col m={12} l={6} className="switch-wrapper pb-3">
                                <Switch
                                    id="status"
                                    m={6}
                                    offLabel="Status"
                                    checked={this.state.isActive}
                                    onClick={this._handleInput}
                                />
                            </Col>
                            <TatvamTextArea
                                className={(this.state.inValidForm && !this.state.url || !this.state.valid.url) ? "labelText mt-0 validate project_name invalid" : "labelText mt-0 validate project_name"}
                                s={12}
                                m={12}
                                l={12}
                                xl={12}
                                required
                                label={
                                    <span>
                                        {API_SOURCE_DETAILS.URL}
                                        <span style={{color: 'red'}}> *</span>
                                    </span>
                                }
                                name="url"
                                onChange={this._handleInput}
                                value={this.state.url}
                                disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                placeholder=""
                                autocomplete="off"
                            />


                            <button type="submit" ref="submit" className="hide">
                                Submit
                            </button>
                        </form>
                    ) : (
                        <TatvamCol m={12} className="ml-20">
                            <div className="col m12 l6 pl-0">
                                <span className="property-label col s3"> {API_SOURCE_DETAILS.VIEW_NAME} : </span>
                                <span className="col s9 url-wrap">{this.state.name} </span>
                            </div>

                            <div className="col m12 l6">
                                <span className="property-label col s4">{API_SOURCE_DETAILS.STATUS} : </span>
                                <span
                                    className="col s8">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                            <div className="col s12 pl-2 ml-2">
                                <span className="property-label col s1">{API_SOURCE_DETAILS.VIEW_URL} : </span>
                                <TatvamTextArea
                                    className="labelText mt-0 validate project_name"
                                    s={11}
                                    name="url"
                                    onChange={this._handleInput}
                                    value={this.state.url}
                                    disabled
                                />
                            </div>
                        </TatvamCol>
                    )}
                    <TatvamCol s={12} className="p-0">
                        <TatvamCol m={8}>
                            {!this.state.valid.url && (
                                <p className="errorText">
                                    {API_SOURCE_DETAILS.VALID_URL}
                                </p>
                            )}
                            {!this.state.valid.name && (
                                <p className="errorText">
                                    {API_SOURCE_DETAILS.VALID_NAME}
                                </p>
                            )}
                        </TatvamCol>
                        <TatvamCol s={4} className="modalButtons">

                            {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                    <TatvamButton
                                        waves="light"
                                        className="CreateProjectPublish btn_primary "
                                        onClick={this._triggerSubmit}
                                        disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                    >
                                        {FORM_OPERATIONS.SAVE}
                                    </TatvamButton>
                                ) :
                                (
                                    <TatvamButton waves="light" className=" CreateProjectPublish btn_primary "
                                                  name="EDIT" onClick={this._handleAppMode}>
                                        {FORM_OPERATIONS.EDIT}
                                    </TatvamButton>
                                )}
                            <TatvamButton waves="light" className="mr-4 CreateProjectPublish btn_primary"
                                          onClick={this._triggerDiscard}>
                                {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                    FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                            </TatvamButton>
                        </TatvamCol>
                    </TatvamCol>

                    {this.state.isAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._discard}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                </Row>
            </Fragment>
        );
    }

}

export default ApiSourceDetailsForm
