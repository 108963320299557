import React from 'react';
import {Select} from "react-materialize";

const BaseSelect = (props) => {
    return (
        <Select {...props}/>
    )
}

export default BaseSelect;
