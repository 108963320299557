import React from "react";
import {BaseTextArea} from "../../baseComponents";

const TatvamTextArea = (props) => {
    return (
        <BaseTextArea {...props} />
    )
}

export default TatvamTextArea;
