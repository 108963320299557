import moment from "moment";
import {GetCurrentMonthDateStartAndEndDate, GetLasNMonths} from "../utility/date.utility";

const PeriodicityType = {
    DAILY: 'Daily',
    DAYS: 'Days',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Yearly',
    CUSTOM: 'Custom',
    CURRENT_MONTH: 'CurrentMonth'
}

export const PERIODICITY_ITEMS = [
    {
        key: 10,
        value: 'Last 10 Days',
        isdefaultSelected: false,
        periodicityItemType: PeriodicityType.DAYS,
        periodicityItemValues: [10]
    },
    {
        key: 20,
        value: 'Last 20 Days',
        isdefaultSelected: false,
        periodicityItemType: PeriodicityType.DAYS,
        periodicityItemValues: [20]
    },
    {
        key: 1,
        value: 'Current Month',
        isdefaultSelected: false,
        periodicityItemType: PeriodicityType.CURRENT_MONTH,
        periodicityItemValues: GetCurrentMonthDateStartAndEndDate(moment().year(), moment().month())
    },
    {
        key: 3,
        value: 'Last 3 Months',
        isdefaultSelected: false,
        periodicityItemType: PeriodicityType.MONTHLY,
        periodicityItemValues: GetLasNMonths(3)
    },
    {
        key: 6,
        value: 'Last 6 Months',
        isdefaultSelected: true,
        periodicityItemType: PeriodicityType.MONTHLY,
        periodicityItemValues: GetLasNMonths(6)
    },
    {
        key: 13,
        value: 'Last 13 Months',
        isdefaultSelected: false,
        periodicityItemType: PeriodicityType.MONTHLY,
        periodicityItemValues: GetLasNMonths(13)
    },
    {
        key: 0,
        value: 'Custom',
        isdefaultSelected: false,
        periodicityItemType: PeriodicityType.CUSTOM,
        periodicityItemValues: []
    },
];
