import './range-filter.component.scss';
import 'rc-slider/assets/index.css';

import React, {Fragment, useEffect, useState,} from 'react';

import Slider from 'rc-slider';
import {Card} from 'react-bootstrap';
import {Collapsible, CollapsibleItem,} from 'react-materialize';

const RangeFilter = (props) => {
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (props.dotRanges && props.dotRanges.length > 0) {
            const ranges = props.dotRanges.filter((range) => range.isChecked);
            let step = props.dotRanges[0].step;
            if (ranges.length > 0) {
                step = ranges[0].step;
            }

            setCurrentStep(step);

            const filterRanges = props.dotRanges
                .filter((range) => range.step === step)
                .map((range) => {
                    range.isChecked = true;
                    return range;
                });
            if (filterRanges.length > 0) {
                props.onSliderStepChanged(filterRanges, props.name);
            }
        }

    }, [props.dotRanges]);

    const getSliderDotActive = (props) => {
        return !props.isTrackBgVisible
            ? {borderColor: '#e9e9e9'}
            : {borderColor: '#337ab7'};
    };

    const getTrackBackground = (props) => {
        return !props.isTrackBgVisible
            ? {backgroundColor: 'transparent'}
            : {backgroundColor: '#337ab7'};
    };

    const onSliderStepChanged = (step) => {
        setCurrentStep(step);

        if (props.dotRanges && props.dotRanges.length > 0) {
            const filterRanges = props.dotRanges.filter((range) => range.step === step)
                .map((range) => {
                    range.isChecked = true;
                    return range;
                });
            if (filterRanges.length > 0) {
                props.onSliderStepChanged(filterRanges, props.name);
            }
        }
    };

    const renderBody = () => {
        if (!props.showCollapseIcon) {
            return (
                <Fragment>
                    <Card.Body style={{height: '45px'}}>{renderRange()}</Card.Body>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Card.Body style={{height: '45px'}}>{renderRange()}</Card.Body>
            </Fragment>
        );
    };

    const renderHeader = () => {
        if (!props.showCollapseIcon) {
            return (
                <Fragment>
                    <p className="mr-2 m-0">{props.title}</p>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <p className="mr-2 m-0">{props.title}</p>
                </Fragment>
            );
        }
    };

    const renderRange = () => {
        return (
            <Slider
                trackStyle={getTrackBackground(props)}
                activeDotStyle={getSliderDotActive(props)}
                min={props.min}
                value={currentStep}
                max={props.max}
                marks={convertRangeDotsToRCSliderMark()}
                step={props.step}
                onChange={onSliderStepChanged}
            />
        );
    };

    const convertRangeDotsToRCSliderMark = () => {
        let marks = {};

        props.dotRanges.forEach((range) => {
            marks[`${range.step.toString()}`] = range.displayText;
        });
        return marks;
    };

    return (
        <div className="range-filter">
            {props.showCollapseIcon ? (
                <Collapsible accordion className="m-0">
                    <CollapsibleItem
                        expanded={props.isExpanded}
                        header={renderHeader()}
                        node="div"
                    >
                        <div>
                            {renderBody()}
                        </div>
                    </CollapsibleItem>
                </Collapsible>

            ) : (
                <Card>
                    {renderHeader()}
                    {renderBody()}
                </Card>
            )}
        </div>
    );
};

export default RangeFilter;
