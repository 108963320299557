import React from "react";
import {BaseSelect} from "../../baseComponents";

const TatvamSelect = (props) => {
    return (
        <BaseSelect {...props} />
    )
}

export default TatvamSelect;
