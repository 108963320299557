const initialState = {
    list: [],
    error: {}
};

const IndustryReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }

    if (action.type === "GET_INDUSTRY_SUCCESS") {
        state = {
            ...state,
            list: action.payload
        };
    }
    return state;
};

export default IndustryReducer;
