import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CustomerUsageLogs from "./customerUsageLog";
import {fetchApplicationNames, getCustomerUsageLogDetails} from "../../../actions";

const mapStateToProps = (state) => ({
    applicationList: state.applicationName.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getCustomerUsageLogDetails,
            fetchApplicationNames
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsageLogs);
