import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetCustomerResponsesSuccess: (payload) => ({
        type: "GET_CUSTOMER_RESPONSES_SUCCESS",
        payload
    }),
    GetCustomerResponsesError: (payload) => ({
        type: "GET_CUSTOMER_RESPONSES_ERROR",
        payload
    })
};

export const fetchCustomerResponses = (customer_id) => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_REPONSES.format(customer_id);
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const customerResponses = [];
                response.data.data.map((data) => {
                    customerResponses.push(data);
                });

                dispatch(actions.GetCustomerResponsesSuccess(customerResponses));
                return response;

            }
            dispatch(actions.GetCustomerResponsesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetCustomerResponsesError(error.message));
            return Promise.resolve(error);
        });
};
