import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteIndustry, fetchAllIndustry} from "../../../actions";
import SourceIndustry from "./sourceIndustry";

const mapStateToProps = (state, ownProps) => ({
    sourceIndustryList: state.sources.industry
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllIndustry,
            deleteIndustry
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceIndustry);
