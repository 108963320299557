import 'react-datepicker/dist/react-datepicker.css';
import './webSourceForm.scss';

/* eslint-disable no-console */
import React, {Component, Fragment,} from 'react';

import {customAlphabet} from 'nanoid';
import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APPLICATION_MODES,
    COMMENT_TYPE,
    FORM_OPERATIONS,
    SWITCH_TEXT,
    VALIDATION_MESSAGES,
    WEB_SOURCE,
} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {trimCapitalize} from '../../../utils/stringUtil';
import {
    TatvamButton,
    TatvamCheckbox,
    TatvamCol,
    TatvamDatePicker,
    TatvamMultiSelect,
    TatvamTextBox,
} from '../../functionalComponents';
import AlertBox from '../alertBox';
import ApiDetails from '../apiSourceForm/apiDetails';

class WebSourceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            sourceListsOptions: [],
            selectedSource: {},
            selectedCommentType: {},
            // selectedRatingType:{},
            isActive: false,
            preLoader: false,
            editFlag: {
                source: false,
                startDate: false,
                endDate: false,
                reviewUrl: false,
                comment: false
            },
            isValid: true,
            valid: {
                date: true,
            },
            credentialRequired: false,
            username: null,
            password: null,
            commentTypeListsOptions: [],
            commentTypes: [],
            apiDetails: {},
            details: {},
            is_public: false,
            is_action_trackable: false,
            is_accuracy: false,
            // ratingTypeListOption:[
            //     {label:"AI Rating",value:"AI Rating"},
            //     {label:"Google Rating",value:"Google Rating"},
            //     {label:"Tatvam Rating",value:"Tatvam Rating"},
            // ]
        };
    }

    _handleInput = e => {
        if (e.target.id === "sourceStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else if (e.target.id === "credentialRequired" || e.target.id === "is_live_feed" || e.target.id === "is_respondable" || e.target.id === "is_public" || e.target.id === "is_action_trackable" || e.target.id === "is_accuracy") {
            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.id]: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    selectedSource: [],
                    selectedCommentType: [],
                    // selectedRatingType:[]
                });

                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleStartDateInput = e => {
        let datefrom = e != null ? e.toDateString().replace('-', '').split(" ") : "";
        this.setState({
            valid_from: e != null ? datefrom[2] + "-" + datefrom[1] + "-" + datefrom[3] : "",
            min_Date: e != null ? datefrom[2] + "-" + datefrom[1] + "-" + datefrom[3] : "",
            editFlag: {
                ...this.state.editFlag,
                startDate: true
            }
        });

        if (e == null) {
            this.setState({
                valid_upto: ""
            });
        }
    };

    _handleEndDateInput = e => {
        let dateupto = e != null ? e.toDateString().replace('-', '').split(" ") : "";
        this.setState({
            valid_upto: e != null ? dateupto[2] + "-" + dateupto[1] + "-" + dateupto[3] : "",
            editFlag: {
                ...this.state.editFlag,
                endDate: true
            }
        });
    };

    _validations = () => {
        let valid = this.state.valid;
        let selectedCommentType;
        let selectedSource;
        // let selectedRatingType;

        if (Array.isArray(this.state.selectedSource)) {
            selectedSource = this.state.selectedSource[0];
        } else {
            selectedSource = this.state.selectedSource;
        }

        if (Array.isArray(this.state.selectedCommentType)) {
            selectedCommentType = this.state.selectedCommentType[0];
        } else {
            selectedCommentType = this.state.selectedCommentType;
        }

        // if (Array.isArray(this.state.selectedRatingType)) {
        //     selectedRatingType = this.state.selectedRatingType[0];
        // } else {
        //     selectedRatingType = this.state.selectedRatingType;
        // }

        if ((!this.state.valid_from && this.state.valid_upto) || (this.state.valid_from && !this.state.valid_upto)) {
            return this.setState({isValid: false, eitherOneExist: true});
        }
        if (this.state.valid_from && this.state.valid_upto && this.props.customerData.category !== APPLICATION_MODES.COMPETITOR && this.props.customerData.category !== APPLICATION_MODES.TENANT) {
            if ((new Date(this.state.valid_from) < new Date(this.state.valid_from_required)) || (new Date(this.state.valid_upto) > new Date(this.state.valid_upto_required))) {
                this.setState({exceedLicenseDate: true});
                return false;
            } else {
                this.setState({exceedLicenseDate: false});
            }
        }

        const details = Object.keys(this.state.details).includes("");
        
        if (this.state.credentialRequired) {
            if (this.state.details && !details && selectedSource && selectedSource.label !== undefined && selectedCommentType && selectedCommentType.label !== undefined && this.state.username && this.state.password) {
                if (this.state.valid_from && this.state.valid_upto) {
                    valid.date = new Date(this.state.valid_from) <= new Date(this.state.valid_upto);
                } else {
                    valid.date = true
                }
                this.setState({isValid: true});
            } else {
                this.setState({
                    isValid: false
                });
            }
        } else {
            if (this.state.details && !details && selectedSource && selectedSource.label !== undefined && selectedCommentType && selectedCommentType.label !== undefined) {
                if (this.state.valid_from && this.state.valid_upto) {
                    valid.date = new Date(this.state.valid_from) <= new Date(this.state.valid_upto);
                } else {
                    valid.date = true;
                }
                this.setState({isValid: true});
            } else {
                this.setState({
                    isValid: false
                });
            }
        }

        this.setState({
            valid
        }, () => {

        });
        return Promise.resolve(true);


    };

    _handleSave = () => {
        this._validations().then((response) => {
            if (!this.state.isValid || !this.state.valid.date) {
                this.setState({inValidForm: true});
            }
            if (response) {
                if (this.state.isValid && this.state.valid.date) {
                    if (Object.entries(this.state.details).length === 0 && Object.entries(this.state.apiDetails).length > 0) {
                        let dataSource = Object.entries(this.state.apiDetails).map((input, index) => {
                            return {key: index, rowKey: input[0], rowValue: input[1]}
                        });
                        this.setState({
                            details: dataSource.reduce((obj, item) => ({
                                ...obj,
                                [item.rowKey]: item.rowValue
                            }), {})
                        })
                    }
                    const source = {
                        source_id: this.state.selectedSource.value,
                        name: this.state.applicationMode === APPLICATION_MODES.CREATE ? this.props.sourceMaster[this.state.selectedSource.value].name : this.props.sourceMaster[this.state.selectedSource[0].value].name,
                        unique_name: this.state.selectedSource.label,
                        source_type: "webSource",
                        comment_type: this.state.selectedCommentType.label,
                        // rating_type:this.state.selectedRatingType.label,
                        source_template: this.state.source_template,
                        valid_from: this.state.valid_from,
                        valid_upto: this.state.valid_upto,
                        status: this.state.status,
                        details: Object.assign(this.state.details,
                            {username: this.state.credentialRequired === true ? this.state.username : ""},
                            {password: this.state.credentialRequired === true ? this.state.password : ""}
                        ),
                        is_live_feed: this.state.is_live_feed ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                        is_respondable: this.state.is_respondable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                        is_loginrequired: this.state.credentialRequired ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                        is_public: this.state.is_public ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                        is_action_trackable: this.state.is_action_trackable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO,
                        is_accuracy:this.state.is_accuracy ? APPLICATION_MODES.YES : APPLICATION_MODES.NO
                    };

                    this.setState({preLoader: true});

                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        const nanoid = customAlphabet(APPLICATION_MODES.NANO_ID_CHARACTERS, APPLICATION_MODES.NANO_ID_LENGTH)
                        let uniqueID = nanoid();
                        let sourceData = {
                            _id: uniqueID,
                            id: uniqueID,
                            customer_id: this.props.customerId,
                            ...source,
                            created_by: this.props.userName,
                            source_template: this.props.sourceMaster[this.state.selectedSource.value].source_template ? this.props.sourceMaster[this.state.selectedSource.value].source_template : "",
                        };

                        this.props.actions.createCustomerSource(sourceData).then((responseCustomerSource) => {
                            this.setState({preLoader: false});
                            if (responseCustomerSource && responseCustomerSource.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseCustomerSource.data && responseCustomerSource.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseCustomerSource.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseCustomerSource.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseCustomerSource.data.message,
                                });
                            }
                        });
                    } else {
                        let sourceData = {
                            customer_id: this.props.customerId,
                            ...source,
                            updated_by: this.props.userName
                        };

                        this.props.actions.updateCustomerSource(sourceData, this.props.sourceData._id).then((responseUpdateCustomer) => {
                            this.setState({preLoader: false});
                            if (responseUpdateCustomer && responseUpdateCustomer.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (responseUpdateCustomer.data && responseUpdateCustomer.data.message) {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseUpdateCustomer.data.message
                                    });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: responseUpdateCustomer.message
                                    });
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: responseUpdateCustomer.message,
                                });
                            }
                        });
                    }
                }
            }
        });
    };

    _handleSourceSelect = selectedOption => {
        if (this.props.sourceMaster) {
            this.setState({apiDetails: this.props.sourceMaster[selectedOption.value] ? this.props.sourceMaster[selectedOption.value].details : {}});
        }

        this.setState({
            editFlag: {
                ...this.state.editFlag,
                source: true
            },
            selectedSource: selectedOption
        });
        this._isCredentialsRequired(selectedOption.value);
    };

    _isCredentialsRequired = (sourceId) => {
        let credentialRequired = false;
        if (this.props.sourceMaster) {
            const sourceData = Object.values(this.props.sourceMaster).filter((item) => item.id === sourceId && item.type === "webSource");
            if (sourceData && sourceData.length > 0) {
                if (sourceData[0].details) {
                    if (sourceData[0].details.default_user !== "" && sourceData[0].details.default_user !== null && sourceData[0].details.default_password !== "" && sourceData[0].details.default_password !== null) {
                        credentialRequired = true;
                    }
                }
            }
        }
        this.setState({
            credentialRequired
        });
    }

    _togglePasswordMask = (e) => {
        if (e.target.checked === true) {
            document.getElementById("webSourcePassword").type = "text";
        } else {
            document.getElementById("webSourcePassword").type = "password";
        }
    };

    _handleCommentTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                comment: true
            },
            selectedCommentType: selectedOption
        });
    };

    // _handleRatingTypeSelect = selectedOption => {
    //     this.setState({
    //         editFlag: {
    //             ...this.state.editFlag,
    //             comment: true
    //         },
    //         selectedRatingType: selectedOption
    //     });
    // };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT,
            credentialRequired: (this.props.sourceData && this.props.sourceData.is_loginrequired === APPLICATION_MODES.YES) ? true : false
        });
    };

    componentDidMount() {
        this.props.actions.fetchAllSources().then(() => {
            if (this.props.sourceMaster) {
                const sourceMasters = [];
                for (let data of Object.values(this.props.sourceMaster)) {
                    if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE && data.type === "webSource") {
                        sourceMasters.push({label: data.unique_name, value: data.id});
                    }
                }
                sourceMasters.sort(dynamicSort("label"));
                this.setState({
                    sourceListsOptions: sourceMasters
                });
            }

            if (this.props.sourceData) {
                let selectedCommentType = [];
                let selectedSource = [];

                for (let data of Object.values(this.props.commentTypes)) {
                    if (this.props.sourceData.comment_type === data.name) {
                        selectedCommentType.push({label: data.name, value: data.id});
                    }
                }

                // let selectedRatingType = [];
                // this.state.ratingTypeListOption.map((ele)=>{
                //     if(this.props.applicationMode === APPLICATION_MODES.CREATE){
                //         if(this.props.customerData.rating_type === ele.label){
                //             selectedRatingType = {
                //                 label: ele.label, value: ele.value
                //             }
                //         }
                //     }else if(this.props.sourceData.rating_type === ele.label){
                //         selectedRatingType = {
                //             label: ele.label, value: ele.value
                //         }
                //     }
                // });

                if (this.props.sourceData.details !== undefined) {
                    let sourceDetails = JSON.parse(JSON.stringify(this.props.sourceData.details));  //Deep Copy
                    delete sourceDetails.username;
                    delete sourceDetails.password;
                    this.setState({apiDetails: sourceDetails});
                }

                if (this.props.sourceData.source_id !== undefined) {
                    for (let data of Object.values(this.props.sourceMaster)) {
                        if (this.props.sourceData.source_id === data.id) {
                            selectedSource.push({label: data.unique_name, value: data.id});
                        }
                    }
                } else {
                    for (let data of Object.values(this.props.sourceMaster)) {
                        if (this.props.sourceData.id === data.id) {
                            selectedSource.push({label: data.unique_name, value: data.id});
                        }
                    }
                }

                this.setState({
                    ...this.props.sourceData,
                    selectedSource,
                    selectedCommentType,
                    // selectedRatingType
                });

                this.setState({
                    applicationMode: this.props.applicationMode,
                    status: this.props.sourceData && trimCapitalize(this.props.sourceData.status) ? this.props.sourceData.status : APPLICATION_MODES.ACTIVE,
                    isActive: this.props.sourceData.status ? trimCapitalize(this.props.sourceData.status) === APPLICATION_MODES.ACTIVE : false,
                    credentialRequired: (this.props.applicationMode === APPLICATION_MODES.VIEW && this.props.sourceData && this.props.sourceData.is_loginrequired === APPLICATION_MODES.YES) ? true : false,
                    username: this.props.sourceData.details ? this.props.sourceData.details.username : "",
                    password: this.props.sourceData.details ? this.props.sourceData.details.password : "",
                    valid_from_required: (this.props.customerData.category !== APPLICATION_MODES.COMPETITOR && this.props.customerData.category !== APPLICATION_MODES.TENANT) ? this.props.customerData.valid_from : "",
                    valid_upto_required: (this.props.customerData.category !== APPLICATION_MODES.COMPETITOR && this.props.customerData.category !== APPLICATION_MODES.TENANT) ? this.props.customerData.valid_upto : "",
                    is_live_feed: this.props.sourceData && this.props.sourceData.is_live_feed === APPLICATION_MODES.YES,
                    is_respondable: this.props.sourceData && this.props.sourceData.is_respondable === APPLICATION_MODES.YES,
                    is_public: this.props.sourceData && this.props.sourceData.is_public === APPLICATION_MODES.YES,
                    is_action_trackable: this.props.sourceData && this.props.sourceData.is_action_trackable === APPLICATION_MODES.YES,
                    is_accuracy:this.props.applicationMode === APPLICATION_MODES.CREATE ? this.props.customerData.is_accuracy === 'Yes' ? true : false : this.props.sourceData && this.props.sourceData.is_accuracy === APPLICATION_MODES.YES 
                });
            }
        });

        this.props.actions.fetchCommentType().then((resp) => {
            this.setState({
                commentTypes: resp.data ? resp.data.data : []
            });
        });

        if (this.props.commentTypes) {
            const types = [];
            for (let data of Object.values(this.props.commentTypes)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE) {
                    types.push({label: data.name, value: data.id});
                }
            }
            types.sort(dynamicSort("label"));
            this.setState({
                commentTypeListsOptions: types
            });
        }

        if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            if (this.props.customerData && this.props.customerData.valid_from !== undefined && this.props.customerData.valid_from !== "" && this.props.customerData.valid_upto !== undefined && this.props.customerData.valid_upto !== "" && this.props.customerData.category !== APPLICATION_MODES.COMPETITOR && this.props.customerData.category !== APPLICATION_MODES.TENANT) {

                let dateFrom = new Date(this.props.customerData.valid_from).toDateString().replace('-', '').split(" ");
                let dateUpto = new Date(this.props.customerData.valid_upto).toDateString().replace('-', '').split(" ");

                this.setState({
                    valid_from: dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[3],
                    valid_upto: dateUpto[2] + "-" + dateUpto[1] + "-" + dateUpto[3]
                });
            } else {
                this.setState({
                    valid_from: "",
                    valid_upto: ""
                });
            }

            this.setState({
                is_public: true
            });
        }
    }

    componentWillReceiveProps(props) {
        const sourceMasterList = [];
        const commentTypeList = [];

        if (props.sourceMaster) {
            for (let data of Object.values(props.sourceMaster)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE && data.type === "webSource") {
                    sourceMasterList.push({label: data.unique_name, value: data.id});
                }
            }
            sourceMasterList.sort(dynamicSort("label"));
        }

        if (props.sourceData.length) {
            let selectedCommentType = [];
            let selectedSource = [];
           

            for (let data of Object.values(props.commentTypes)) {
                if (props.sourceData.comment_type === data.name) {
                    selectedCommentType.push({label: data.name, value: data.id});
                }
            }

            // let selectedRatingType = []
            // this.state.ratingTypeListOption.map((ele)=>{
            //     if(props.applicationMode === APPLICATION_MODES.CREATE){
            //         if(props.customerData.rating_type === ele.label){
            //             selectedRatingType = {
            //                 label: ele.label, value: ele.value
            //             }
            //         }
            //     }else if(props.sourceData.rating_type === ele.label){
            //         selectedRatingType = {
            //             label: ele.label, value: ele.value
            //         }
            //     }
            // })
            
            if (props.sourceData.details !== undefined) {
                let sourceDetails = JSON.parse(JSON.stringify(props.sourceData.details));  //Deep Copy
                delete sourceDetails.username;
                delete sourceDetails.password;
                this.setState({apiDetails: sourceDetails});
            }

            if (props.sourceData.source_id !== undefined) {
                for (let data of Object.values(props.sourceMaster)) {
                    if (this.props.sourceData.source_id === data.id) {
                        selectedSource.push({label: data.unique_name, value: data.id});
                    }
                }
            } else {
                for (let data of Object.values(props.sourceMaster)) {
                    if (props.sourceData.id === data.id) {
                        selectedSource.push({label: data.unique_name, value: data.id});
                    }
                }
            }


            this.setState({
                ...props.sourceData,
                selectedSource,
                selectedCommentType,
                // selectedRatingType
            });
        }

        if (props.commentTypes) {
            for (let data of Object.values(props.commentTypes)) {
                if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE) {
                    commentTypeList.push({label: data.name, value: data.id});
                }
            }
            commentTypeList.sort(dynamicSort("label"));
        }

        this.setState({
            sourceListsOptions: sourceMasterList,
            commentTypeListsOptions: commentTypeList,
            applicationMode: props.applicationMode,
            status: props.sourceData && trimCapitalize(props.sourceData.status) ? props.sourceData.status : APPLICATION_MODES.INACTIVE,
            isActive: props.sourceData.status ? trimCapitalize(props.sourceData.status) === APPLICATION_MODES.ACTIVE : false,
            credentialRequired: (this.state.applicationMode === APPLICATION_MODES.VIEW && props.sourceData && props.sourceData.is_loginrequired === APPLICATION_MODES.YES) ? true : false,
            username: props.sourceData.details ? props.sourceData.details.username : "",
            password: props.sourceData.details ? props.sourceData.details.password : "",
            valid_from_required: (props.customerData.category !== APPLICATION_MODES.COMPETITOR && props.customerData.category !== APPLICATION_MODES.TENANT) ? props.customerData.valid_from : "",
            valid_upto_required: (props.customerData.category !== APPLICATION_MODES.COMPETITOR && props.customerData.category !== APPLICATION_MODES.TENANT) ? props.customerData.valid_upto : "",
        });

        if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
            if (props.customerData && props.customerData.valid_from !== undefined && props.customerData.valid_from !== "" && props.customerData.valid_upto !== undefined && props.customerData.valid_upto !== "" && props.customerData.category !== APPLICATION_MODES.COMPETITOR && props.customerData.category !== APPLICATION_MODES.TENANT) {

                let dateFrom = new Date(props.customerData.valid_from).toDateString().replace('-', '').split(" ");
                let dateUpto = new Date(props.customerData.valid_upto).toDateString().replace('-', '').split(" ");

                this.setState({
                    valid_from: dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[3],
                    valid_upto: dateUpto[2] + "-" + dateUpto[1] + "-" + dateUpto[3]

                });
            } else {
                this.setState({
                    valid_from: "",
                    valid_upto: ""
                });
            }

            this.setState({
                is_public: true
            })
        }
    }

    handleSubmit = (data) => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                details: true
            },
            details: data.reduce((obj, item) => ({...obj, [item.rowKey]: item.rowValue}), {})
        })
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="modelForm webSource">
                        {this.state.applicationMode !== APPLICATION_MODES.CREATE ? (
                            <h4>{WEB_SOURCE.EDIT_SOURCE}</h4>) : ""}
                        <TatvamCol s={12} className="align-flex-end">
                            <TatvamCol m={4} xl={4}>
                                <label htmlFor="source">{WEB_SOURCE.SOURCE}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.selectedSource.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedSource}
                                    name="source"
                                    id="source"
                                    onChange={this._handleSourceSelect}
                                    options={this.state.sourceListsOptions}
                                    placeholder={WEB_SOURCE.SELECT_SOURCES}
                                    isDisabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                />
                            </TatvamCol>
                            <TatvamCol m={4} xl={4}>
                                <label htmlFor="source">{COMMENT_TYPE.TYPE}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.selectedCommentType.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedCommentType}
                                    name="commentTypes"
                                    onChange={this._handleCommentTypeSelect}
                                    options={this.state.commentTypeListsOptions}
                                    placeholder={WEB_SOURCE.SELECT_SOURCES}
                                    isDisabled={this.state.applicationMode !== APPLICATION_MODES.CREATE && this.state.applicationMode !== APPLICATION_MODES.EDIT}
                                >
                                </TatvamMultiSelect>
                            </TatvamCol>
                            <TatvamCol s={4} className="switch-wrapper">
                                <Switch
                                    id="sourceStatus"
                                    offLabel="Status"
                                    checked={this.state.isActive}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol s={12} className="formRow">
                            <TatvamCol s={12} m={4} className="web">
                                <label className="status-label">
                                    {WEB_SOURCE.START_DATE}
                                </label>
                                <TatvamDatePicker
                                    selected={this.state.valid_from && new Date(this.state.valid_from)}
                                    onChange={this._handleStartDateInput}
                                    dateFormat="dd-MMM-yyyy"
                                    placeholderText={WEB_SOURCE.DATE_FORMAT}
                                    className={(this.state.valid_upto && !this.state.valid_from) ? "date-input invalid" : "date-input"}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </TatvamCol>
                            <TatvamCol s={12} m={4} className="web">
                                <label className="status-label">
                                    {WEB_SOURCE.END_DATE}
                                </label>
                                <TatvamDatePicker
                                    selected={this.state.valid_upto && new Date(this.state.valid_upto)}
                                    onChange={this._handleEndDateInput}
                                    minDate={this.state.min_Date && new Date(this.state.min_Date)}
                                    dateFormat="dd-MMM-yyyy"
                                    placeholderText={WEB_SOURCE.DATE_FORMAT}
                                    className={(this.state.valid_from && !this.state.valid_upto) ? "date-input invalid" : "date-input"}
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </TatvamCol>
                            <TatvamCol s={4} className="switch-wrapper">
                                <Switch
                                    id="is_live_feed"
                                    offLabel="Is Live Feed"
                                    checked={this.state.is_live_feed}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol s={12} className="">
                            <TatvamCol s={4} className="switch-wrapper">
                                <Switch
                                    id="is_public"
                                    offLabel="Is Public"
                                    checked={this.state.is_public}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            <TatvamCol s={4} className="switch-wrapper">
                                <Switch
                                    id="is_action_trackable"
                                    offLabel="Is Action Trackable"
                                    checked={this.state.is_action_trackable}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            <TatvamCol s={4} className="switch-wrapper">
                                <Switch
                                    id="is_accuracy"
                                    offLabel="Is Accuracy"
                                    checked={this.state.is_accuracy}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            {/* <TatvamCol m={4} xl={4}>
                                <label htmlFor="source">{"Rating Type"}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className={(this.state.inValidForm && this.state.selectedRatingType.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                    value={this.state.selectedRatingType}
                                    name="ratingType"
                                    onChange={this._handleRatingTypeSelect}
                                    options={this.state.ratingTypeListOption}
                                    placeholder={WEB_SOURCE.RATING_TYPE}
                                    isDisabled={this.state.applicationMode !== APPLICATION_MODES.CREATE && this.state.applicationMode !== APPLICATION_MODES.EDIT}
                                >
                                </TatvamMultiSelect>
                            </TatvamCol> */}
                            <TatvamCol s={4} className="switch-wrapper hide">
                                <Switch
                                    id="is_respondable"
                                    offLabel="Is Respondable"
                                    checked={this.state.is_respondable}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12} className="formRow">
                            <TatvamCol m={12} xl={12} className="checkbox">
                                <TatvamCheckbox
                                    checked={this.state.credentialRequired}
                                    label={WEB_SOURCE.CREDENTIALS_REQUIRED}
                                    id="credentialRequired"
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>
                            {this.state.credentialRequired &&
                                <TatvamCol s={12} className="formRow pl-0 pt-2 pr-0">
                                    <TatvamCol m={12} xl={6} className="pl-0">
                                        <TatvamTextBox
                                            m={12}
                                            required
                                            label={
                                                <span>
                          {WEB_SOURCE.USER_NAME}
                                                    <span style={{color: 'red'}}> *</span>
                        </span>
                                            }
                                            name="username"
                                            onChange={this._handleInput}
                                            className={(this.state.inValidForm && !this.state.username) ? "labelText mb-0 validate invalid" : "labelText mb-0 validate"}
                                            value={this.state.username}
                                            autocomplete="off"
                                        />
                                    </TatvamCol>
                                    <TatvamCol m={12} xl={6} className="pr-0">
                                        <TatvamTextBox
                                            m={12}
                                            required
                                            password
                                            id="webSourcePassword"
                                            label={
                                                <span>
                          {WEB_SOURCE.PASSWORD}
                                                    <span style={{color: 'red'}}> *</span>
                        </span>
                                            }
                                            name="password"
                                            onChange={this._handleInput}
                                            className={(this.state.inValidForm && !this.state.password) ? "labelText mb-0 validate pr-0 invalid" : "labelText mb-0 validate pr-0"}
                                            value={this.state.password}
                                            autocomplete="off"
                                        />
                                        <TatvamCol m={12} xl={12} className="checkbox formRow">
                                            <TatvamCheckbox
                                                label={WEB_SOURCE.SHOW_PASSWORD}
                                                id="togglePassword"
                                                onChange={this._togglePasswordMask}
                                            />
                                        </TatvamCol>
                                    </TatvamCol>
                                </TatvamCol>
                            }
                        </TatvamCol>
                    </Row>
                ) : (
                    <Row className="modelForm webSource">
                        <h4>{WEB_SOURCE.VIEW_TITLE_SOURCE}</h4>
                        <TatvamCol m={12} s={12} className="mt-.75">
                            <div className="col s12 m5">
                                <span className="property-label col s5"> {WEB_SOURCE.VIEW_SOURCE} : </span>
                                <span className="col s7 pr-0"> {this.state.unique_name}</span>
                            </div>

                            <div className="col s12 m4 p-0">
                                <span className="property-label col s5"> {COMMENT_TYPE.VIEW_TYPE} :</span>
                                <span className="col s7 pr-0">  {this.state.comment_type}</span>
                            </div>

                            <div className="col s12 m3 p-0">
                                <span className="property-label col s7"> {WEB_SOURCE.STATUS} : </span>
                                <span
                                    className="col s5 pr-0">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={12} className="mt-.75 ">
                            <div className="col s12 m5">
                                <span className="property-label col s5">{WEB_SOURCE.VIEW_START_DATE} :</span>
                                <span className="col s7 pr-0">{this.state.valid_from}</span>
                            </div>
                            <div className="col s12 m4 p-0">
                                <span className="property-label col s5"> {WEB_SOURCE.VIEW_END_DATE} :</span>
                                <span className="col s7 pr-0">{this.state.valid_upto}</span>
                            </div>
                            <div className="col s12 m3">
                                <span className="property-label col s7 pl-0">{WEB_SOURCE.VIEW_IS_LIVE_FEED} :</span>
                                <span
                                    className="col s3 pr-0">{this.state.is_live_feed === true ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol m={12} className="mt-.75 ">
                            <div className="col s12 m5">
                                <span className="property-label col s5">{WEB_SOURCE.VIEW_IS_PUBLIC} :</span>
                                <span
                                    className="col s7 pr-0">{this.state.is_public === true ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                            </div>
                            <div className="col s12 m5">
                                <span className="property-label col s5">{WEB_SOURCE.VIEW_IS_ACTION_TRACKABLE} :</span>
                                <span
                                    className="col s7 pr-0">{this.state.is_action_trackable === true ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                            </div>
                            <div className="col s12 m5">
                                <span className="property-label col s5">{WEB_SOURCE.VIEW_IS_ACCURACY} :</span>
                                <span
                                    className="col s7 pr-0">{this.state.is_accuracy === true ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                            </div>
                            {/* <div className="col s12 m4 m5">
                                <span className="property-label col s5"> {WEB_SOURCE.RATING_TYPE} :</span>
                                <span className="col s7 pr-0">  {this.state.rating_type}</span>
                            </div> */}
                            <div className="col s12 m4 p-0 hide">
                                <span className="property-label col s5"> {WEB_SOURCE.VIEW_IS_RESPONDABLE} :</span>
                                <span
                                    className="col s7 pr-0">{this.state.is_respondable === true ? APPLICATION_MODES.YES : APPLICATION_MODES.NO}</span>
                            </div>
                            <div className="mt-0 col s12 checkbox">
                                <TatvamCheckbox
                                    checked={this.state.credentialRequired}
                                    label={WEB_SOURCE.CREDENTIALS_REQUIRED}
                                    id="credentialRequired"
                                    disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                />
                            </div>
                            {this.state.credentialRequired && (
                                <Fragment>
                                    <TatvamCol m={6} className="mt-.75 mt-2">
                                        <div className="col s12 p-0">
                                            <span className="property-label col s4">{WEB_SOURCE.VIEW_USERNAME} :</span>
                                            <span className="col s8 url-wrap"> {this.state.username}</span>
                                        </div>
                                    </TatvamCol>

                                    <TatvamCol m={6} className="mt-.75 mt-2">
                                        <div className="col s12 p-0">
                                            <span className="property-label col s4">{WEB_SOURCE.VIEW_PASSWORD} :</span>
                                            <span className="col s8 url-wrap">XXXXXXX</span>
                                        </div>
                                    </TatvamCol>
                                </Fragment>
                            )}
                        </TatvamCol>
                    </Row>
                )}
                <Row className={this.state.applicationMode !== APPLICATION_MODES.VIEW ? "pt-2" : "pt-0"}>
                    <TatvamCol m={12}>
                        <ApiDetails
                            apiDetails={this.state.apiDetails}
                            onSubmit={this.handleSubmit}
                            disabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                            inValidForm={this.state.inValidForm}
                        />
                    </TatvamCol>
                </Row>
                <Row className="modalButtons mt-3 p-0">
                    <TatvamCol m={8} className="formRow">
                        {!this.state.valid.date && (
                            <p className="errorText">
                                {WEB_SOURCE.START_DATE_SHOULD_BE_LESS_THAN_END_DATE}
                            </p>
                        )}
                        {this.state.exceedLicenseDate && (
                            <p className="errorText">
                                {WEB_SOURCE.EXCEED_LICENSE_DATE}
                            </p>
                        )}
                        {(!this.state.isValid && !this.state.eitherOneExist) && (
                            <p className="errorText">
                                {/* {
                  VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS
                } */}
                            </p>
                        )}
                        {(!this.state.isValid && this.state.eitherOneExist) && (
                            <p className="errorText">{
                                VALIDATION_MESSAGES.EITHER_DATE_EXIST
                            }
                            </p>
                        )}
                    </TatvamCol>
                    <TatvamCol s={4}>
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                            <TatvamButton
                                waves="light"
                                className="mt-1  CreateProjectPublish btn_primary "
                                onClick={this._handleSave}
                                disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                            >
                                {FORM_OPERATIONS.SAVE}
                            </TatvamButton>
                        ) : this.props.sourcePermission === APP_BOOLEANS.TRUE && (
                            <TatvamButton
                                waves="light"
                                className="mt-1 CreateProjectPublish btn_primary "
                                name="EDIT"
                                onClick={this._handleAppMode}
                            >
                                {FORM_OPERATIONS.EDIT}
                            </TatvamButton>
                        )}
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default WebSourceForm;