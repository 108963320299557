import React from "react";
import {BaseButton} from "../../baseComponents";

const TatvamButton = (props) => {
    return (
        <BaseButton {...props} />
    )
}

export default TatvamButton;
