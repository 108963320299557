import React from "react";

class NotFound extends React.Component {
    render() {
        return (
            <div>
                <h1>404</h1>
            </div>
        );
    }
}

export default NotFound;
