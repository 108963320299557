import {apiUrl} from 'apiConstants';
import axios from 'axios';

import {UserSignOut} from './authActions';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetCustomerSuccess: payload => ({
        type: "FETCH_CUSTOMER_SUCCESS",
        payload
    }),
    GetCustomerError: payload => ({
        type: "FETCH_CUSTOMER_ERROR",
        payload
    }),
    DeleteCustomerPropertySuccess: payload => ({
        type: "SAVE_CUSTOMER_SUCCESS",
        payload
    }),
    DeleteCustomerPropertyError: payload => ({
        type: "SAVE_CUSTOMER_ERROR",
        payload
    }),
    DeleteCustomerSuccess: payload => ({
        type: "SAVE_CUSTOMER_SUCCESS",
        payload
    }),
    DeleteCustomerError: payload => ({
        type: "SAVE_CUSTOMER_ERROR",
        payload
    }),
    DeleteTenantSuccess: payload => ({
        type: "SAVE_TENANT_SUCCESS",
        payload
    }),
    DeleteTenantError: payload => ({
        type: "SAVE_TENANT_ERROR",
        payload
    }),
    SaveCustomerSuccess: payload => ({
        type: "SAVE_CUSTOMER_SUCCESS",
        payload
    }),
    SaveCustomerError: payload => ({
        type: "SAVE_CUSTOMER_ERROR",
        payload
    }),
    SaveTenantSuccess: payload => ({
        type: "SAVE_TENANT_SUCCESS",
        payload
    }),
    SaveTenantError: payload => ({
        type: "SAVE_TENANT_ERROR",
        payload
    }),
    FetchUserDetailsSuccess: payload => ({
        type: "FETCH_USER_DETAILS_SUCCESS",
        payload
    }),
    FetchUserDetailsError: payload => ({
        type: "FETCH_USER_DETAILS_ERROR",
        payload
    }),
    FetchCustomerParentSuccess: payload => ({
        type: "FETCH_PARENT_CUSTOMER_SUCCESS",
        payload
    }),
    FetchCustomerParentError: payload => ({
        type: "FETCH_PARENT_CUSTOMER_ERROR",
        payload
    }),
    GetTabSuccess: (payload) => ({
        type: "FETCH_TAB_SUCCESS",
        payload
    }),
    GetTabError: (payload) => ({
        type: "FETCH_TAB_ERROR",
        payload
    }),
    FetchCustomerByIdSuccess: payload => ({
        type: "SAVE_CUSTOMER_SUCCESS",
        payload
    }),

};

export const saveCustomer = customerData => (dispatch) => {
    const payload = customerData;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER.format(customerData.id);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.SaveCustomerSuccess(response.data.data[0]));
                if (!customerData.parent_customer)
                    dispatch(fetchCustomerParent(customerData.id));
                return response;
            }
            dispatch(actions.SaveCustomerError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.SaveCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};

export const saveTenant = customerData => (dispatch) => {
    const payload = customerData;
    const url = apiUrl.BASE_URL + apiUrl.TENANT_EDIT.format(customerData.id);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.SaveTenantSuccess(response.data.data[0]));
                if (!customerData.parent_customer)
                    dispatch(fetchCustomerParent(customerData.id));
                return response;
            }
            dispatch(actions.SaveTenantError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.SaveTenantError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchAllCustomers = category => (dispatch) => {
    let url = apiUrl.BASE_URL + apiUrl.READ_ALL_CUSTOMER_LIMIT_FIELDS;
    if (category) {
        url = url + apiUrl.READ_CUSTOMER_CATEGORY + category;
    }
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200 || response.status === 304) {
                const custList = {};
                response.data.data.map((data) => {
                    custList[data.id] = data;
                });
                dispatch(actions.GetCustomerSuccess(custList));
                return response;
            }
            if (response.status === 204) {
                dispatch(actions.GetCustomerSuccess([]));
                return response;
            }

            dispatch(actions.GetCustomerError(response.message));
            return response;
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 500) {
                    if (error.response.data.message === "The token that you've entered is not an valid token") {
                        // eslint-disable-next-line no-alert
                        dispatch(UserSignOut());
                    }
                }
            } else {
                dispatch(UserSignOut());
            }
            dispatch(actions.GetCustomerError(error.message));
            return Promise.resolve(error.response);

        });
};

export const fetchAllCustomersByUser = () => (dispatch, getState) => {
    const store = getState();
    let url = apiUrl.BASE_URL + apiUrl.READ_ALL_CUSTOMER_BY_USER.format(store.auth.userName);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200 || response.status === 304) {
                const custList = {};
                response.data.data.map((data) => {
                    custList[data.id] = data;
                });
                dispatch(actions.GetCustomerSuccess(custList));
                return response;
            }


            if (response.status === 204) {
                dispatch(actions.GetCustomerSuccess([]));
                return response;
            }
            dispatch(actions.GetCustomerError(response.message));
            return response;
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 500) {
                    if (error.response.data.message === "The token that you've entered is not an valid token") {
                        // eslint-disable-next-line no-alert
                        window.alert("session expired");
                        dispatch(UserSignOut());
                    }
                }
            } else {
                dispatch(UserSignOut());
            }
            dispatch(actions.GetCustomerError(error.message));
            return Promise.resolve(error.response);

        });
};


export const createCustomer = customerData => (dispatch) => {
    const payload = customerData;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER;

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.SaveCustomerSuccess(response.data.data[0]));
                return response;
            }
            dispatch(actions.SaveCustomerError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.SaveCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};

export const createTenant = customerData => (dispatch) => {
    const payload = customerData;
    const url = apiUrl.BASE_URL + apiUrl.TENANT_CREATE;

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.SaveTenantSuccess(response.data.data[0]));
                return response;
            }
            dispatch(actions.SaveTenantError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.SaveCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerParent = customerId => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_PARENT.format(customerId);
    const store = getState();
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                let customer = store.customer.list[customerId];
                customer = {
                    ...customer,
                    parent_customer: response.data.data
                };
                dispatch(actions.FetchCustomerParentSuccess(customer));
                return response;
            }

            dispatch(actions.FetchCustomerParentError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.FetchCustomerParentError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchUserDetails = () => (dispatch, getState) => {
    const store = getState();
    const url = apiUrl.BASE_URL + apiUrl.USER_DETAILS + store.auth.userName;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const userData = {
                    userName: response.data.data[0].user_name,
                    displayName: response.data.data[0].display_name,
                    profile: response.data.data[0]
                };
                dispatch(actions.FetchUserDetailsSuccess(userData));
                return response;
            }

            dispatch(actions.FetchUserDetailsError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.FetchUserDetailsError(error.message));
            return Promise.resolve(error);
        });
};

export const deleteCustomerProperty = (propertyData) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list[propertyData.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER_PROPERTY.format(propertyData.customer_id, propertyData.id);
    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (customer.children) {
                    const propertyAfterDelete = customerData.children.filter((property) => {
                        return property !== propertyData.id;
                    });
                    customerData = {
                        ...customerData,
                        children: propertyAfterDelete
                    };
                } else {
                    customerData = {
                        ...customerData,
                        children: []

                    };
                }
                dispatch(actions.DeleteCustomerPropertySuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerPropertyError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteCustomerPropertyError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteCustomer = (customerDeleteData) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list;

    const url = apiUrl.BASE_URL + apiUrl.DELETE_CUSTOMER.format(customerDeleteData.customer_id);

    return axios
        .delete(url)
        .then(response => {

            if (response.status === 200) {
                let customerData = customer;
                if (customer) {
                    const deletedCustomerId = customerDeleteData.customer_id;

                    delete customerData[deletedCustomerId];
                } else {
                    customerData = {};
                }

                dispatch(actions.DeleteCustomerSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteCustomerError(response.data.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteTenant = (customerDeleteData) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.list;

    const url = apiUrl.BASE_URL + apiUrl.TENANT_DELETE.format(customerDeleteData.customer_id);

    return axios
        .delete(url)
        .then(response => {

            if (response.status === 200) {
                let customerData = customer;
                if (customer) {
                    const deletedCustomerId = customerDeleteData.customer_id;

                    delete customerData[deletedCustomerId];
                } else {
                    customerData = {};
                }

                dispatch(actions.DeleteTenantSuccess(customerData));
                return response;
            }

            dispatch(actions.DeleteTenantError(response.data.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteTenantError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchCustomerTabCount = (id) => (dispatch, getState) => {
    const state = getState();
    let customer = state.customer.competitorTenantCustomers;
    const url = apiUrl.BASE_URL + apiUrl.CUSTOMER_TAB_COUNT.format(id);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetTabSuccess(response.data.data));
                return response;

            }
            dispatch(actions.GetTabError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetTabError(error.message));
            return Promise.resolve(error);
        });
};


export const fetchCustomerDetailsById = (id) => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_ONE_CUSTOMER.format(id);
    const store = getState();
    return axios
        .get(url)
        .then(async(response) => {
            if (response.status === 200) {
                if(Object.keys(store.customer.list).length <= 1) {
                    await dispatch(fetchAllCustomers());
                } 
                dispatch(actions.FetchCustomerByIdSuccess(response.data.data[0]));
            }
            dispatch(actions.GetCustomerError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetCustomerError(error.message));
            return Promise.resolve(error);
        });
};


