import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createResponseTemplate, editResponseTemplate} from "../../../actions";
import CustomerResponseTemplateForm from "./customerResponseTemplateForm";

const mapStateToProps = (state, ownProps) => ({
    userName: state.auth.userName,
    customerSources: ownProps.currentCustomerId ? state.customer.list[ownProps.currentCustomerId].sources : [],
    rolePermission: state.auth.permissions && state.auth.permissions.customer.roles.create
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createResponseTemplate,
            editResponseTemplate

        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerResponseTemplateForm);
