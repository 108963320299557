import React, {Fragment} from 'react';

import {Col, Row,} from 'react-materialize';
import {Tab, TabList, TabPanel, Tabs,} from 'react-tabs';

import {ALERT_MESSAGES, API_SOURCE_DETAILS, APPLICATION_MODES,} from '../../../constants/appConstants';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';
import ApiSourceDetailsForm from '../apiSourceDetailsForm';

class ApiSourceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiSourceDetailsGridData: [{name: "Loading.."}],
            apiSourceAttributeDetailsGridData: [],
            colDef: [
                {headerName: API_SOURCE_DETAILS.VIEW_NAME, field: "name", filterParams: {clearButton: true}},
                {headerName: API_SOURCE_DETAILS.VIEW_URL, field: "url"},
                {headerName: API_SOURCE_DETAILS.STATUS, field: "status"}
            ],
            colDefAttrDetails: [
                {headerName: API_SOURCE_DETAILS.ATTR_GRID_KEY, field: "key"},
                {headerName: API_SOURCE_DETAILS.ATTR_GRID_VALUE, field: "value"},
            ],
            idSource: "",
            logoSource: "",
            nameSource: "",
            sleepTime: "",
            statusSource: "",
            urlSource: "",
            totalActive: 0,
            totalRecords: 0,
            totalAttrDetails: 0
        }
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idSource: data.source_id,
            name: data.name,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let source_id = this.state.idSource;
            let name = this.state.name;
            let xpathDeleteData = {
                source_id,
                name
            };
            this.props.actions.deleteOneXpath(xpathDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    let apiDetailsDeleteSuccessMsg = "";
                    if (response.data.message) {
                        apiDetailsDeleteSuccessMsg = "API Source deleted";
                    }
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: apiDetailsDeleteSuccessMsg
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }

            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }

    componentDidMount() {
        this.props.actions.fetchAllXpaths(this.props.history.location.state.sourceId).then(() => {
            this.stateInitialization(this.props);
            let detailsCount = 0;
            let keyAttrPair = [];
            let noOfActive = []
            if (this.props.xpaths) {
                noOfActive = this.props.xpaths.filter(eachItem => eachItem.status === 'Active');
            }
            if (this.props.history.location.state.apiDetailsData.details && Object.keys(this.props.history.location.state.apiDetailsData.details).length > 0) {
                detailsCount = Object.keys(this.props.history.location.state.apiDetailsData.details).length;
                keyAttrPair = Object.keys(this.props.history.location.state.apiDetailsData.details).map((key) => {
                    return {"key": key, "value": this.props.history.location.state.apiDetailsData.details[key]};
                });
            }
            this.setState({
                idSource: this.props.history.location.state.apiDetailsData.id,
                logoSource: this.props.history.location.state.apiDetailsData.logo,
                nameSource: this.props.history.location.state.apiDetailsData.name,
                sleepTime: this.props.history.location.state.apiDetailsData.sleep_time,
                statusSource: this.props.history.location.state.apiDetailsData.status,
                urlSource: this.props.history.location.state.apiDetailsData.url,
                totalActive: noOfActive.length,
                totalRecords: this.props.xpaths ? this.props.xpaths.length : 0,
                totalAttrDetails: detailsCount,
                apiSourceAttributeDetailsGridData: keyAttrPair
            });
        });
    }

    componentWillReceiveProps(props) {
        let noOfActive = [];
        if (props.xpaths) {
            noOfActive = props.xpaths.filter(eachItem => eachItem.status === 'Active');
        }
        let detailsCount = 0;
        let keyAttrPair = [];
        if (props.history.location.state.apiDetailsData.details && Object.keys(props.history.location.state.apiDetailsData.details).length > 0) {
            detailsCount = Object.keys(props.history.location.state.apiDetailsData.details).length;
            keyAttrPair = Object.keys(props.history.location.state.apiDetailsData.details).map((key) => {
                return {"key": key, "value": props.history.location.state.apiDetailsData.details[key]};
            });
        }

        this.setState({
            totalActive: noOfActive.length,
            totalRecords: props.xpaths ? props.xpaths.length : 0,
            totalAttrDetails: detailsCount,
            apiSourceAttributeDetailsGridData: keyAttrPair
        });

        this.stateInitialization(props);
    }

    stateInitialization = (thisProps) => {
        const sourceName = thisProps.history.location.state.apiDetailsData.name;
        if (thisProps.source && thisProps.xpaths) {
            thisProps.xpaths.map(function (obj) {
                return Object.assign(obj, {source_name: sourceName});
            })
            this.setState({
                apiSourceDetailsGridData: thisProps.xpaths,
                name: thisProps.source.name,
                sortColumnName: thisProps.history.location.state.sortColumnName,
                sortOrderName: thisProps.history.location.state.sortOrderName,
                returnPath: thisProps.history.location.state.returnPath,
            });
        } else {
            this.setState({
                name: thisProps.history.location.state.apiDetailsData.name,
                id: thisProps.history.location.state.apiDetailsData.id,
                sortColumnName: thisProps.history.location.state.sortColumnName,
                sortOrderName: thisProps.history.location.state.sortOrderName,
                returnPath: thisProps.history.location.state.returnPath,
                apiSourceDetailsGridData: []
            });
        }
    };

    _onEditRowClick = (data) => {
        this.setState({
            apiSourceDetailsData: data[0],
            apiSourceModal: true,
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW
        });
    };

    _openCreateModal = () => {
        this.setState({
            apiSourceDetailsData: [],
            apiSourceModal: true,
            modalTitle: "Add API Details",
            applicationMode: APPLICATION_MODES.CREATE
        });
    }

    _goBack = () => {
        this.props.history.push({
            pathname: this.state.returnPath,
            state: {
                sortColumnName: this.state.sortColumnName,
                sortOrderName: this.state.sortOrderName
            }
        });
    };

    closeModal = () => {
        this.setState({
            apiSourceModal: false
        });
    };

    render() {
        return (
            <Fragment>
                <Row className="create-project-page">
                    <Row>
                        <Col className="right">
                            <TatvamButton
                                waves="light"
                                onClick={this._goBack}
                                className="goBackBtn btn_primary"
                                title="go back "
                            >
                                <i className="material-icons">arrow_back</i>
                            </TatvamButton>
                        </Col>
                        <Fragment>
                            <TatvamCol className="z-depth-2 centered mt-2 form-wrapper" s={12} m={12} l={12} xl={12}>
                                <div className="page_heading mt-0 mb-1 ml-1">
                                    {<h5>{this.state.nameSource + "'s API Details"}</h5>}
                                </div>
                                <TatvamCol s={12} m={12} l={2} className="imageWrapper">
                                    <div className="left d-flex flex-colum image-uploaded mb-2">
                                        <img
                                            className="responsive-img"
                                            src={this.state.logoSource !== "" ? this.state.logoSource :
                                                require("../../../../public/assets/images/default_customer.png")}
                                            alt="logo"
                                            title="Property Logo"
                                        />
                                    </div>
                                </TatvamCol>
                                <TatvamCol s={12} l={10} className="p-0 customerFormView">

                                    <TatvamCol s={12} m={12} class="mt-.75">
                                        <div className="mt-0 col s12 m4">
                                            <span
                                                className="property-label col s6">{API_SOURCE_DETAILS.SOURCE_ID} : </span>
                                            <span className="col s6"> {this.state.idSource}</span>
                                        </div>

                                        {/* <div className="mt-0 col s12 m4">
                      <span className="property-label col s6"> {API_SOURCE_DETAILS.SOURCE_NAME}: </span>
                      <span className="col s6 propertyNameView"> {this.state.nameSource} </span>
                    </div> */}

                                        <div className="mt-0 col s12 m4">
                                            <span
                                                className="property-label col s6"> {API_SOURCE_DETAILS.STATUS} : </span>
                                            <span className="col s6"> {this.state.statusSource} </span>
                                        </div>
                                    </TatvamCol>

                                    {/* <TatvamCol s={12} m={12} className="mt-.75">




                    <div className="mt-0 col s12 m4">
                      <span className="property-label col s6"> {API_SOURCE_DETAILS.VIEW_URL} : </span>
                      <span className="col s6"> {this.state.urlSource} </span>
                    </div>
                  </TatvamCol> */}

                                </TatvamCol>
                            </TatvamCol>
                        </Fragment>
                    </Row>

                    <Row>
                        <Col className="z-depth-2 col-centered mt-3 mb-3 p-0" s={12} m={12} l={12} xl={12}>
                            <Row className="m-0">
                                <Tabs>
                                    <TabList className="tabs customTabs z-depth-2 tabs-fixed-width">
                                        <Tab className="tab">{API_SOURCE_DETAILS.TAB_API_DETAILS_TITLE}<span
                                            class="activeCountSpan">({this.state.totalActive}/{this.state.totalRecords})</span></Tab>
                                        <Tab className="tab">{API_SOURCE_DETAILS.ATTR_DETAILS}<span
                                            class="activeCountSpan">(Total {this.state.totalAttrDetails})</span></Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="">
                                            {/* <div className="mt-2 ml-2 mr-2 left "><h5>Active {this.state.totalActive}/{this.state.totalRecords}</h5></div> */}
                                            <TatvamButton className="mt-2 ml-2 mr-2 right btn_primary"
                                                          onClick={this._openCreateModal} title="Add API Details">
                                                {API_SOURCE_DETAILS.ADD_API_DETAILS}
                                            </TatvamButton>

                                        </div>
                                        <Col s={12} className="roleGrid">
                                            <div className="row  z-depth-4 h-75" ref="tableTop">
                                                <TatvamGrid
                                                    gridData={this.state.apiSourceDetailsGridData}
                                                    colData={this.state.colDef}
                                                    enablePagination
                                                    enableRowClick
                                                    onRowSelect={this._onEditRowClick}
                                                    enableDeleteColumn={true}
                                                    onRowDelete={this._handleDeleteConfirmation}
                                                />
                                            </div>
                                        </Col>
                                        {this.state.apiSourceModal && (
                                            <TatvamModal
                                                open={this.state.apiSourceModal}
                                                header={this.state.modalTitle}
                                                className="hideFooter"
                                                options={{dismissible: false}}
                                                fixedFooter={false}
                                                children={(
                                                    <ApiSourceDetailsForm
                                                        formData={this.state.apiSourceDetailsData}
                                                        closeModal={this.closeModal}
                                                        applicationMode={this.state.applicationMode}
                                                        sourceId={this.props.source.id}
                                                    />
                                                )}
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel>
                                        <Col s={12} className="roleGrid">
                                            <div className="row  z-depth-4 h-75" ref="tableTop">
                                                <TatvamGrid
                                                    gridData={this.state.apiSourceAttributeDetailsGridData}
                                                    colData={this.state.colDefAttrDetails}
                                                    enablePagination
                                                />
                                            </div>
                                        </Col>
                                    </TabPanel>
                                </Tabs>

                            </Row>
                        </Col>
                    </Row>


                    {this.state.isAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._discard}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                    {this.state.isDeleteAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isDeleteAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._deleteRow}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                </Row>
            </Fragment>
        );
    }

}

export default ApiSourceDetails

