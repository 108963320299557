import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    deleteCustomerCompetitor,
    fetchCustomerCompetitor,
    fetchCustomerCompetitors,
    fetchCustomerIndustry,
    updateCustomerCompetitors
} from "../../../../../actions";
import Competitors from "./competitors";

const mapStateToProps = (state, ownProps) => ({
    license_type: state.customer.list[ownProps.currentCustomer.id].license_type,
    gridData: state.customer.list[ownProps.currentCustomer.id].competitors,
    customerList: state.customer.list,
    competitorPermission: state.auth.permissions && state.auth.permissions.customer.competitors.create,
    competitorDelete: state.auth.permissions && state.auth.permissions.customer.competitors.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerCompetitor,
            fetchCustomerIndustry,
            deleteCustomerCompetitor,
            fetchCustomerCompetitors,
            updateCustomerCompetitors
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Competitors);
