import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS} from 'localConstants';

import {
    ALERT_MESSAGES,
    APPLICATION_MODES,
    PROPERTY_DIMENSION_VALUE,
    PROPERTY_DIMENSION_VALUE_GRID,
} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import CustomerDimensionValueForm from '../../../customerDimensionValueForm';
import CustomerDimensionValueImport from '../../../customerDimensionValueImport';
import CsvExport from '../../../../functionalComponents/csvExport/csvExport';
import Loader from 'react-loaders';
import { populateCsvHeader } from '../../../../../utils/csv.utility';
import { Col } from 'react-bootstrap';

class DimensionValues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            colDef: [{
                headerName: PROPERTY_DIMENSION_VALUE_GRID.PARENT_ENTITY,
                field: "parent_entity_type",
                filterParams: {clearButton: true},
                width: 225
            },
                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.PARENT_ENTITY_VALUE,
                    field: "parent_entity_value",
                    filterParams: {clearButton: true},
                    width: 227
                },
                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.ID,
                    field: "_id",
                    filterParams: {clearButton: true},
                    hide: true
                },
                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.ENTITY_TYPE,
                    field: "entity_type",
                    filterParams: {clearButton: true},
                    width: 180
                },
                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.ENTITY_VALUE,
                    field: "entity_value",
                    filterParams: {clearButton: true},
                    width: 182
                },
                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.IS_PUBLIC,
                    field: "is_public",
                    filterParams: {clearButton: true},
                    width: 182
                },

                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    width: 108
                },
                {
                    headerName: PROPERTY_DIMENSION_VALUE_GRID.PATTERN,
                    field: "text_pattern",
                    filterParams: {clearButton: true},
                    width: 145
            }],
            exportColumns: [
                {
                    label: PROPERTY_DIMENSION_VALUE_GRID.PARENT_ENTITY,
                    key:'parent_entity_type'
                },
                {
                    label:  PROPERTY_DIMENSION_VALUE_GRID.PARENT_ENTITY_VALUE,
                    key: 'parent_entity_value'
                },
                {
                    label: PROPERTY_DIMENSION_VALUE_GRID.ENTITY_TYPE,
                    key: "entity_type",
                },
                {
                    label: PROPERTY_DIMENSION_VALUE_GRID.ENTITY_VALUE,
                    key: "entity_value",
                },
                {
                    label: PROPERTY_DIMENSION_VALUE_GRID.IS_PUBLIC,
                    key: "is_public",
                },

                {
                    label: PROPERTY_DIMENSION_VALUE_GRID.STATUS,
                    key: "status",
                },
                {
                    label: PROPERTY_DIMENSION_VALUE_GRID.PATTERN,
                    key: "text_pattern",
            }
                
            ],
            exportData: [],
            loader: true,
            showImportModal: false
        };
    }


    _handleDeleteConfirmation = (data) => {
        this.setState({
            id: data._id,
            idCustomer: data.customer_id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.id;
            let dimensionDeleteData = {
                customer_id,
                id
            };
            this.props.actions.deleteCustomerDimensionValues(dimensionDeleteData).then((response) => {
                this.setState({
                    loader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };


    _openViewModal = (data) => {
        this.setState({
            dimensionValueData: data[0],
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true,
            loader: true
        });
    };

    _openCreateModal = () => {
        this.setState({
            dimensionValueData: [],
            modalTitle: PROPERTY_DIMENSION_VALUE.ADD_DIMENSION_VALUE,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        let dimensionValuesGridData = [];
        if (this.props.currentCustomer.id) {
            this.setState({
                loader: true
            });
            this.props.actions.getCustomerDimensionValues(this.props.currentCustomer.id).then(() => {

                this.setState({
                    loader: false
                });

            });
        }

        if (this.props.gridData) {
            this.props.gridData.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        dimensionValuesGridData.push(data);
                    }
                }
            });
        }

        this.setState({
            dimensionValuesGridData,
            exportData:dimensionValuesGridData
        }, () => {

            this.setActiveCount();
        });

    }

    setActiveCount = () => {
        const gridData = this.state.dimensionValuesGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'dimensionValueSource': {'active': noOfActive.length, 'total': gridData.length}});
    };


    componentWillReceiveProps(props) {
        let dimensionValuesGridData = [];
        if (props.gridData) {
            props.gridData.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        dimensionValuesGridData.push(data);
                    }

                }
            });
        }
        this.setState({
            dimensionValuesGridData,
            exportData:dimensionValuesGridData
        }, () => {

            this.setActiveCount();
        });
    }

    _importCustomerDimensionValues = () => {
        this.setState({
            modalTitle: "",
            showImportModal: true
        });
    }

    closeImportModal = () => {
        this.setState({
            showImportModal: false
        });
    };

    render() {

        return (
            <Fragment>
                {this.props.dimensionPermission === APP_BOOLEANS.TRUE && (
                    <Fragment>
                        <TatvamButton className="btn_primary right mt-1 mr-2" onClick={this._openCreateModal}>
                            {PROPERTY_DIMENSION_VALUE.ADD_DIMENSION_VALUE}
                        </TatvamButton>
                        <TatvamButton className="mt-1 ml-2 right ad-vis-btn btn_primary mr-1"
                                      onClick={this._importCustomerDimensionValues}
                                      title="Import customer dimension values">
                            <i className="large material-icons"> import_contacts </i>
                        </TatvamButton>
                    </Fragment>
                )}

                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <CustomerDimensionValueForm
                                applicationMode={this.state.applicationMode}
                                customerId={this.props.currentCustomer.id}
                                closeModal={this.closeModal}
                                dimensionValueData={this.state.dimensionValueData}
                                dimensionValueList={this.state.dimensionValuesGridData}
                                nonEditable={this.state.nonEditable}
                            />
                        )}
                    />
                )
                }
                {this.state.dimensionValuesGridData && this.state.dimensionValuesGridData.length ? (
                    <TatvamCol s={12} className="grid-data-area">
                        

                        {this.state.exportData.length > 0 && (
                            <Fragment>
                                <CsvExport
                                    columns={this.state.exportColumns}
                                    datas={this.state.exportData}
                                    csvHeader={populateCsvHeader(
                                        false,
                                        false,
                                        true,
                                        false,
                                        false
                                    )}
                                    csvHeaderLineBreakCount={1}
                                    delimeter=","
                                    downloadFileName={`${'Dimension Values'}.csv`.split(' ').join('')}
                                />
                                <Col
                                    s={12}
                                    className={this.state.loader ? "valign-wrapper loader-overlay" : "hide"}
                                >
                                    {this.state.loader && <Loader type="ball-beat" />}
                                </Col>
                            </Fragment>
                        )}
                        <TatvamGrid
                            gridData={this.state.dimensionValuesGridData}
                            colData={this.state.colDef}
                            rowSelection="multiple"
                            isExportCsv={this.state.isExportCSV}
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.dimensionDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}

                        />
                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            No Dimension Values available
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.props.dimensionPermission === APP_BOOLEANS.TRUE && this.state.showImportModal && (
                    <TatvamModal
                        open={this.state.showImportModal}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <CustomerDimensionValueImport
                                closeModal={this.closeImportModal}
                                customerId={this.props.currentCustomer.id}
                            />
                        )}
                    />
                )
                }
            </Fragment>
        );
    }
}

export default DimensionValues;
