const initialState = {
    list: [],
    error: {}
};

const CustomerCategoryReducer = (state, action) => {
    if (state === null || state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case "GET_CUSTOMER_CATEGORY_SUCCESS":
            state = {
                ...state,
                list: action.payload
            };
            return state;
        case "GET_CUSTOMER_CATEGORY_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            return state;
        default:
            return state;
    }
};

export default CustomerCategoryReducer;
