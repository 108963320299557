import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerDimensionValue,
    deleteCustomerDimensionValues,
    getCustomerDimensionValues,
    updateCustomerDimensionValue
} from "../../../../../actions";
import DimensionValues from "./dimensionValues";

const mapStateToProps = (state, ownProps) => ({
    gridData: state.customer.list[ownProps.currentCustomer.id].dimensionValues,
    dimensionPermission: state.auth.permissions && state.auth.permissions.customer.dimensionValues.create,
    dimensionDelete: state.auth.permissions && state.auth.permissions.customer.dimensionValues.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getCustomerDimensionValues,
            createCustomerDimensionValue,
            updateCustomerDimensionValue,
            deleteCustomerDimensionValues
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(DimensionValues);
