import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    deleteCustomerSource,
    fetchCompetitorTenantCustomers,
    fetchCustomerAlertRules,
    fetchCustomerResponseTemplates,
    fetchCustomerRoles,
    fetchCustomerSources,
    fetchCustomerUser,
    getCustomerDimensions,
    getCustomerDimensionValues,
} from '../../../../../actions';
import WebSources from './webSources';

const mapStateToProps = (state, ownProps) => ({
    customer: state.customer.list[ownProps.currentCustomerId],
    customerRoles: state.customer.list[ownProps.currentCustomerId].roles,
    sourcePermission: state.auth.permissions && state.auth.permissions.customer.sources.create,
    customerRules: state.customer.list[ownProps.currentCustomerId].rules,
    customerUsers: state.customer.list[ownProps.currentCustomerId].users,
    customerDimensions: state.customer.list[ownProps.currentCustomerId].dimensions,
    customerDimensionValues: state.customer.list[ownProps.currentCustomerId].dimensionValues,
    competitorTenantCustomerList: state.customer.competitorTenantCustomers,
    responseTemplateLst: state.customer.list[ownProps.currentCustomerId].responseTemplates,
    sourceDelete: state.auth.permissions && state.auth.permissions.customer.sources.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerSources,
            deleteCustomerSource,
            fetchCustomerRoles,
            fetchCustomerUser,
            fetchCustomerAlertRules,
            getCustomerDimensions,
            getCustomerDimensionValues,
            fetchCompetitorTenantCustomers,
            fetchCustomerResponseTemplates

        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(WebSources);
