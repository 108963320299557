import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReportUsageLogs from "./reportUsageLog";
import {fetchApplicationNames, getReportsUsageLogDetails} from "../../../actions";

const mapStateToProps = (state) => ({
    applicationList: state.applicationName.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getReportsUsageLogDetails,
            fetchApplicationNames
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportUsageLogs);
