import {apiUrl} from 'apiConstants';
import axios from 'axios';

const actions = {
    GetApplicationNameSuccess: (payload) => ({
        type: "GET_APPLICATION_NAME_SUCCESS",
        payload
    }),
    GetApplicationNameError: (payload) => ({
        type: "GET_APPLICATION_NAME_ERROR",
        payload
    })
};

export const fetchApplicationNames = () => (dispatch, getState) => {
    const store = getState();
    if (store.applicationName) {
        if (store.applicationName.list) {
            if (store.applicationName.list.length > 0) {
                return Promise.resolve(true);
            }
        }
    }
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_APPLICATION_NAMES;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.GetApplicationNameSuccess(response.data.data));
                return response;

            }
            dispatch(actions.GetApplicationNameError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetApplicationNameError(error.message));
            return Promise.resolve(error);
        });
};
