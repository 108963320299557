import React from "react";
import {BaseModal} from "../../baseComponents";

const TatvamModal = (props) => {
    return (
        <BaseModal {...props} />
    )
}

export default TatvamModal;
