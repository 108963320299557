import './roleForm.scss';

/* eslint-disable camelcase */
import React, { Component, Fragment, } from 'react';

import Loader from 'react-loaders';
import { Row, Switch, } from 'react-materialize';

import { FORM_OPERATIONS, PROPERTY_ROLES, SWITCH_TEXT, VALIDATION_MESSAGES, } from '../../../constants/appConstants';
import { APP_BOOLEANS, APPLICATION_MODES, } from '../../../constants/localConstants';
import { dynamicSort } from '../../../utils/objectUtil';
import { trimCapitalize } from '../../../utils/stringUtil';
import { TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextBox, } from '../../functionalComponents/index';
import AlertBox from '../alertBox';

class RoleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: "",
            preLoader: false,
            isFormValid: true,
            enabled_operations: [],
            enabled_reports: [],
            operationListOptions: [],
            reportListOptions: [],
            status: APPLICATION_MODES.INACTIVE,
            applicationListOptions: [],
            selectedApplicationName: [],
            isPredictoAccess: false,
        };
    }

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };
    _discard = data => {

        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    enabled_operations: [],
                    enabled_reports: [],
                    operationListOptions: [],
                    reportListOptions: [],
                    name: ""
                });

                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };
    _triggerDiscard = () => {

        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: "Changes will be lost",
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };
    _handleInput = e => {
        if (e.target.id === "roleStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    [e.target.name]: true
                }
            });
        }
    };
    _handleSave = e => {
        e.preventDefault();

        let enabled_operations = [];
        let enabled_reports = [];
        if (this.state.enabled_operations) {
            this.state.enabled_operations.map(data => {
                enabled_operations = [...enabled_operations, data.value];
            });
        }
        if (this.state.enabled_reports) {
            this.state.enabled_reports.map(data => {
                const reportData = this.props.reportsList.filter((item) => item.id === data.value);

                enabled_reports = [...enabled_reports, {
                    id: data.value,
                    name: data.label,
                    application_name: data.application_name,
                    page_title: reportData[0].page_title,
                    url: reportData[0].url,
                    page_components: reportData[0].page_components,
                    menu_path: reportData[0].menu_path,
                    icon: reportData[0].icon,
                    seq_no: reportData[0].seq_no,
                    is_public: reportData[0].is_public,
                    components: data.components ? data.components : [],
                    subscription_template: data.subscription_template ? data.subscription_template : "",
                    application_enabled: reportData[0].application_enabled,
                    subscription_enabled: reportData[0].subscription_enabled,
                    report_type: reportData[0].report_type
                }];
            });
        }
        let formValid = this.isValidForm();
        if (!formValid) {
            this.setState({ inValidForm: true });
        }
        if (formValid) {
            let roleData = {
                ...this.props.roleData
            };
            roleData.name = this.state.name;
            roleData.enabled_operations = enabled_operations;
            roleData.enabled_reports = enabled_reports;
            roleData.status = this.state.status;
            roleData.application_name = this.state.selectedApplicationName.label;
            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                roleData = {
                    customer_id: this.props.currentCustomerId,
                    ...roleData,
                    created_by: this.props.userName
                };
                this.setState({ preLoader: true });
                this.props.actions.createRoles(roleData).then(response => {
                    this.setState({ preLoader: false });
                    if (response && response.status === 200) {
                        this.setState({ shouldCloseModal: true });
                        if (response.data && response.data.message) {
                            this.setAlertRole(response.data.message, true);
                        } else {
                            this.setAlertRole(response.message, true);
                        }
                    } else {
                        this.setState({ shouldCloseModal: false });
                        this.setAlertRole(response.data.message, false);
                    }
                });
            } else if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                roleData = {
                    customer_id: this.props.currentCustomerId,
                    ...roleData,
                    updated_by: this.props.userName
                };
                this.setState({ preLoader: true });
                this.props.actions.editRoles(roleData).then(response => {
                    this.setState({ preLoader: false });
                    if (response && response.status === 200) {
                        this.setState({ shouldCloseModal: true });
                        if (response.data && response.data.message) {
                            this.setAlertRole(response.data.message, true);
                        } else {
                            this.setAlertRole(response.message, true);
                        }
                    } else {
                        this.setState({ shouldCloseModal: false });
                        this.setAlertRole(response.message, false);
                    }
                });
            }

        }

    };

    setAlertRole = (alertMessage, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMessage
        });
    };

    _triggerSubmit = () => {
        this.refs.submit.click();
    };

    predictoReportsEnableInOptions = () => {
        const predictoReportFilteredListOptions = this.props.reportsList.filter(data => data.application_name === APPLICATION_MODES.PREDICTO && data.status === APPLICATION_MODES.ACTIVE);

        if (predictoReportFilteredListOptions) {
            let reportListOptions = [...this.state.reportListOptions];
            predictoReportFilteredListOptions.forEach((ele) => {
                if (ele.name !== PROPERTY_ROLES.ADHOC_REPORT && ele.name !== PROPERTY_ROLES.MANAGE_SUBSCRIPTION) {
                    reportListOptions = [...reportListOptions, {
                        label: ele.name,
                        value: ele.id,
                        application_name: ele.application_name,
                        components: ele.components,
                        subscription_template: ele.subscription_template
                    }];
                }
            })
            this.setState({
                reportListOptions: reportListOptions,
                isPredictoAccess: true
            });
        }
    }

    operationsClicked = optionsList => {
        let isPredictoAccessEnabled;

        if (optionsList) {
            isPredictoAccessEnabled = optionsList.find((oneOperation) => {
                return oneOperation.value === PROPERTY_ROLES.PREDICTO_ACCESS
            })

            if (!this.state.isPredictoAccess && isPredictoAccessEnabled) {
                this.predictoReportsEnableInOptions()
            }
        }

        if (this.state.isPredictoAccess && !isPredictoAccessEnabled) {
            if (this.state.reportListOptions) {
                const reportsWithoutPredicto = this.state.reportListOptions.filter(ele => ele.application_name !== APPLICATION_MODES.PREDICTO)
                this.setState({
                    reportListOptions: reportsWithoutPredicto
                });
            }
            if (this.state.enabled_reports) {
                const enabledReportsWithoutPredicto = this.state.enabled_reports.filter(ele => ele.application_name !== APPLICATION_MODES.PREDICTO)
                this.setState({
                    enabled_reports: enabledReportsWithoutPredicto
                });
            }
            this.setState({
                isPredictoAccess: false
            });
        }

        this.setState({
            enabled_operations: optionsList,
            editFlag: {
                enabled_operations: true
            }
        });
    };
    reportClicked = optionsList => {
        this.setState({
            enabled_reports: optionsList,
            editFlag: {
                enabled_reports: true
            }
        });
    };
    closeAlertBox = () => {
        this.setState({
            isAlertBoxOpen: false
        });
    };

    isValidForm = () => {
        const { enabled_operations, name, enabled_reports } = this.state;
        if (name === APPLICATION_MODES.TATVAM_INAPP) {
            if (!Array.isArray(this.state.selectedApplicationName) && ((enabled_operations && enabled_operations.length > 0) || (enabled_reports && enabled_reports.length > 0))) {
                this.setState({
                    isFormValid: true
                });
                return true;
            } else {
                this.setState({
                    isFormValid: false
                });
                return false;
            }
        };
        this.setState({
            isFormValid: true
        });
        return true;
    };

    _loadApplicationDropDown = (thisProps) => {
        const appList = [];
        if (thisProps.applicationLst) {
            thisProps.applicationLst.map((appName) => {
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    if ((appName.name === APPLICATION_MODES.TATVAM_INAPP && thisProps.customerInformation.is_inapp) ||
                        (appName.name === APPLICATION_MODES.PREDICTO && thisProps.customerInformation.is_predicto)) {
                        appList.push({ label: appName.name, value: appName.name });
                    } else {
                        appList.push({ label: appName.name, value: appName.name });
                    }
                } else {
                    appList.push({ label: appName.name, value: appName.name });
                }

            });
            appList.sort(dynamicSort("label"));
        }
        this.setState({
            applicationListOptions: appList
        });
    };

    componentDidMount() {
        let enabled_operations = [];
        let enabled_reports = [];
        let operationListOptions = [];
        let reportListOptions = [];

        let operationFilteredListOptions = [];
        let reportFilteredListOptions = [];
        let isPredictoAccessExistInOperations;

        this.props.actions.fetchApplicationNames().then(() => {
            this._loadApplicationDropDown(this.props);
        });

        if (this.props.roleData.enabled_operations && this.props.roleData.enabled_operations.length > 0) {
            if (this.props.roleData.enabled_operations.includes(PROPERTY_ROLES.PREDICTO_ACCESS)) {
                isPredictoAccessExistInOperations = true;
                this.setState({
                    isPredictoAccess: true
                });
            }
        }

        if (this.props.roleData.application_name) {
            reportFilteredListOptions = this.props.reportsList.filter(data => data.status === APPLICATION_MODES.ACTIVE);
            operationFilteredListOptions = this.props.operationsList.filter(data => data.application_name === this.props.roleData.application_name);
        }

        if (this.props.isCorporate === APPLICATION_MODES.YES) {
            if (reportFilteredListOptions) {
                reportFilteredListOptions.map((data) => {
                    if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(APPLICATION_MODES.GROUP_INSTANCE) > -1)) {
                        if ((data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_campaignable === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if ((data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                            data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                            data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_source_weightage === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if (data.application_name === this.props.roleData.application_name) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        } else if (data.application_name === APPLICATION_MODES.PREDICTO && isPredictoAccessExistInOperations) {
                            if (data.name !== PROPERTY_ROLES.ADHOC_REPORT && data.name !== PROPERTY_ROLES.MANAGE_SUBSCRIPTION) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        }

                    }
                });
            }

            if (operationFilteredListOptions) {
                operationFilteredListOptions.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE) {
                        if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(APPLICATION_MODES.GROUP_INSTANCE) > -1)) {
                            if (data.name === PROPERTY_ROLES.ACTION_TRACKING) {
                                if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name,
                                        application_name: data.application_name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.RESPONSE_TRACKING) {
                                if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name,
                                        application_name: data.application_name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.CHATBOT) {
                                if (this.props.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name,
                                        application_name: data.application_name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.PREDICTO_ACCESS) {
                                if (this.props.customerInformation.is_predicto) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name,
                                        application_name: data.application_name
                                    }];
                                }
                            } else {
                                operationListOptions = [...operationListOptions, {
                                    label: data.name,
                                    value: data.name,
                                    application_name: data.application_name
                                }];
                            }
                        }
                    }
                });
            }
        } else {
            if (reportFilteredListOptions) {
                reportFilteredListOptions.map((data) => {
                    if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(this.props.customerCategory) > -1)) {
                        if ((data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_campaignable === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if ((data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                            data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                            data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) && data.application_name === this.props.roleData.application_name) {
                            if (this.props.is_source_weightage === APPLICATION_MODES.YES) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        } else if (data.application_name === this.props.roleData.application_name) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        } else if (data.application_name === APPLICATION_MODES.PREDICTO && isPredictoAccessExistInOperations) {
                            if (data.name !== PROPERTY_ROLES.ADHOC_REPORT && data.name !== PROPERTY_ROLES.MANAGE_SUBSCRIPTION) {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        }
                    }
                });
            }
            if (operationFilteredListOptions) {
                operationFilteredListOptions.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE) {
                        if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(this.props.customerCategory) > -1)) {
                            if (data.name === PROPERTY_ROLES.ACTION_TRACKING) {
                                if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.RESPONSE_TRACKING) {
                                if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.CHATBOT) {
                                if (this.props.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.PREDICTO_ACCESS) {
                                if (this.props.customerInformation.is_predicto) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        }
                    }
                });

            }
        }
        reportListOptions.sort(dynamicSort("label"));
        operationListOptions.sort(dynamicSort("label"));

        this.setState({
            operationListOptions,
            reportListOptions,
            applicationMode: this.props.applicationMode,
            isActive: true,
            status: APPLICATION_MODES.ACTIVE,
            selectedApplicationName: this.props.roleData && this.props.roleData.application_name ? {
                label: this.props.roleData.application_name,
                value: this.props.roleData.application_name
            } : []
        });


        if (this.props.roleData && Object.keys(this.props.roleData).length > 0) {
            this.props.roleData.enabled_operations.map((data) => {
                if (data === PROPERTY_ROLES.ACTION_TRACKING) {
                    if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else if (data === PROPERTY_ROLES.RESPONSE_TRACKING) {
                    if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else if (data === PROPERTY_ROLES.CHATBOT) {
                    if (this.props.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else if (data === PROPERTY_ROLES.PREDICTO_ACCESS) {
                    if (this.props.customerInformation.is_predicto) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else {
                    enabled_operations = [...enabled_operations, { label: data, value: data }];
                }
            });

            this.props.roleData.enabled_reports.map((data) => {
                if (data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) {
                    if (this.props.is_campaignable === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD) {
                    if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD) {
                    if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else if (data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                    data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                    data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) {
                    if (this.props.is_source_weightage === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else {
                    enabled_reports = [...enabled_reports, {
                        label: data.name,
                        value: data.id,
                        application_name: data.application_name,
                        components: data.components,
                        subscription_template: data.subscription_template
                    }];
                }
            });

            this.setState({
                ...this.props.roleData,
                isActive: trimCapitalize(this.props.roleData.status) === APPLICATION_MODES.ACTIVE,
                enabled_operations,
                enabled_reports
            });
        }
    }

    componentWillReceiveProps(props) {
        let operationListOptions = [];
        let reportListOptions = [];
        let operationFilteredListOptions = [];
        let reportFilteredListOptions = [];
        let isPredictoAccessExistInOperations;

        this._loadApplicationDropDown(props);

        if (props.roleData.application_name) {
            reportFilteredListOptions = props.reportsList.filter(data => data.status === APPLICATION_MODES.ACTIVE);
            operationFilteredListOptions = props.operationsList.filter(data => data.application_name === props.roleData.application_name);
        }

        if (props.roleData.enabled_operations && props.roleData.enabled_operations.length > 0) {
            if (props.roleData.enabled_operations.includes(PROPERTY_ROLES.PREDICTO_ACCESS)) {
                isPredictoAccessExistInOperations = true;
                this.setState({
                    isPredictoAccess: true
                });
            }
        }

        if (props.isCorporate === APPLICATION_MODES.YES) {
            operationFilteredListOptions.map((data) => {
                if (data.status === APPLICATION_MODES.ACTIVE) {
                    if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(APPLICATION_MODES.GROUP_INSTANCE) > -1)) {
                        if (data.name === PROPERTY_ROLES.ACTION_TRACKING) {
                            if (props.is_action_trackable === APPLICATION_MODES.YES) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else if (data.name === PROPERTY_ROLES.RESPONSE_TRACKING) {
                            if (props.is_response_trackable === APPLICATION_MODES.YES) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else if (data.name === PROPERTY_ROLES.CHATBOT) {
                            if (props.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else if (data.name === PROPERTY_ROLES.PREDICTO_ACCESS) {
                            if (props.customerInformation.is_predicto) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else {
                            operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                        }
                    }
                }
            });

            reportFilteredListOptions.map((data) => {
                if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(APPLICATION_MODES.GROUP_INSTANCE) > -1)) {
                    if ((data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) && data.application_name === props.roleData.application_name) {
                        if (props.is_campaignable === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD && data.application_name === props.roleData.application_name) {
                        if (props.is_action_trackable === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD && data.application_name === props.roleData.application_name) {
                        if (props.is_response_trackable === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if ((data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                        data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                        data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) && data.application_name === props.roleData.application_name) {
                        if (props.is_source_weightage === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if (data.application_name === props.roleData.application_name) {
                        reportListOptions = [...reportListOptions, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    } else if (data.application_name === APPLICATION_MODES.PREDICTO && isPredictoAccessExistInOperations) {
                        if (data.name !== PROPERTY_ROLES.ADHOC_REPORT && data.name !== PROPERTY_ROLES.MANAGE_SUBSCRIPTION) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    }
                }
            });
        } else {
            operationFilteredListOptions.map((data) => {
                if (data.status === APPLICATION_MODES.ACTIVE) {
                    if (data.customer_type && (data.customer_type.indexOf("*") > -1 && data.customer_type.indexOf(props.customerCategory) > -1)) {
                        if (data.name === PROPERTY_ROLES.ACTION_TRACKING) {
                            if (props.is_action_trackable === APPLICATION_MODES.YES) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else if (data.name === PROPERTY_ROLES.RESPONSE_TRACKING) {
                            if (props.is_response_trackable === APPLICATION_MODES.YES) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else if (data.name === PROPERTY_ROLES.CHATBOT) {
                            if (props.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else if (data.name === PROPERTY_ROLES.PREDICTO_ACCESS) {
                            if (props.customerInformation.is_predicto) {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        } else {
                            operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                        }
                    }
                }
            });

            reportFilteredListOptions.map((data) => {
                if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(props.customerCategory) > -1)) {
                    if ((data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) && data.application_name === props.roleData.application_name) {
                        if (props.is_campaignable === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD && data.application_name === props.roleData.application_name) {
                        if (props.is_action_trackable === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD && data.application_name === props.roleData.application_name) {
                        if (props.is_response_trackable === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if ((data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                        data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                        data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) && data.application_name === props.roleData.application_name) {
                        if (props.is_source_weightage === APPLICATION_MODES.YES) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    } else if (data.application_name === props.roleData.application_name) {
                        reportListOptions = [...reportListOptions, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    } else if (data.application_name === APPLICATION_MODES.PREDICTO && isPredictoAccessExistInOperations) {
                        if (data.name !== PROPERTY_ROLES.ADHOC_REPORT && data.name !== PROPERTY_ROLES.MANAGE_SUBSCRIPTION) {
                            reportListOptions = [...reportListOptions, {
                                label: data.name,
                                value: data.id,
                                application_name: data.application_name,
                                components: data.components,
                                subscription_template: data.subscription_template
                            }];
                        }
                    }
                }
            });
        }
        this.setState({
            operationListOptions,
            reportListOptions,
            applicationMode: props.applicationMode,
            isActive: true,
            status: APPLICATION_MODES.ACTIVE,
            selectedApplicationName: props.roleData && props.roleData.application_name ? {
                label: props.roleData.application_name,
                value: this.props.roleData.application_name
            } : []
        });

        if (props.roleData && Object.keys(props.roleData).length > 0) {
            let enabled_operations = [];
            let enabled_reports = [];
            props.roleData.enabled_operations.map((data) => {
                if (data === PROPERTY_ROLES.ACTION_TRACKING) {
                    if (this.props.is_action_trackable === APPLICATION_MODES.YES) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else if (data === PROPERTY_ROLES.RESPONSE_TRACKING) {
                    if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else if (data === PROPERTY_ROLES.CHATBOT) {
                    if (this.props.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else if (data === PROPERTY_ROLES.PREDICTO_ACCESS) {
                    if (this.props.customerInformation.is_predicto) {
                        enabled_operations = [...enabled_operations, { label: data, value: data }];
                    }
                } else {
                    enabled_operations = [...enabled_operations, { label: data, value: data }];
                }
            });

            props.roleData.enabled_reports.map((data) => {
                if (data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) {
                    if (this.props.is_campaignable === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD) {
                    if (props.is_action_trackable === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD) {
                    if (this.props.is_response_trackable === APPLICATION_MODES.YES) {
                        enabled_reports = [...enabled_reports, {
                            label: data.name,
                            value: data.id,
                            application_name: data.application_name,
                            components: data.components,
                            subscription_template: data.subscription_template
                        }];
                    }
                } else {
                    enabled_reports = [...enabled_reports, {
                        label: data.name,
                        value: data.id,
                        application_name: data.application_name,
                        components: data.components,
                        subscription_template: data.subscription_template
                    }];
                }
            });

            this.setState({
                ...props.roleData,
                isActive: trimCapitalize(props.roleData.status) === APPLICATION_MODES.ACTIVE,
                enabled_operations,
                enabled_reports
            });
        }
    }

    _loadOperationReports = (thisProps, selectedOption) => {
        let operationListOptions = [];
        let reportListOptions = [];
        if (thisProps.isCorporate === APPLICATION_MODES.YES) {
            if (thisProps.reportsList) {
                thisProps.reportsList.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE && data.application_name === selectedOption.label) {
                        if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(APPLICATION_MODES.GROUP_INSTANCE) > -1)) {
                            if (data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) {
                                if (thisProps.is_campaignable === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD) {
                                if (thisProps.is_action_trackable === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD) {
                                if (thisProps.is_response_trackable === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                                data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                                data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) {
                                if (thisProps.is_source_weightage === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }
                        }
                    }
                });
            }

            if (thisProps.operationsList) {
                thisProps.operationsList.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE && data.application_name === selectedOption.label) {
                        if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(APPLICATION_MODES.GROUP_INSTANCE) > -1)) {
                            if (data.name === PROPERTY_ROLES.ACTION_TRACKING) {
                                if (thisProps.is_action_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.RESPONSE_TRACKING) {
                                if (thisProps.is_response_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.CHATBOT) {
                                if (thisProps.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.PREDICTO_ACCESS) {
                                if (thisProps.customerInformation.is_predicto) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        }
                    }
                });
            }
        } else {
            if (thisProps.reportsList) {
                thisProps.reportsList.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE && data.application_name === selectedOption.label) {
                        if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(thisProps.customerCategory) > -1)) {
                            if (data.name === PROPERTY_ROLES.FEEDBACK_CAMPAIGNS || data.name === PROPERTY_ROLES.SURVEY_DESIGNER) {
                                if (thisProps.is_campaignable === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.TICKET_DASHBOARD) {
                                if (thisProps.is_action_trackable === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.RESPONSE_DASHBOARD) {
                                if (thisProps.is_response_trackable === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.SOURCE_WAITHAGE ||
                                data.name === PROPERTY_ROLES.KPI_DASHBOARD_360 ||
                                data.name === PROPERTY_ROLES.CUSTOMER_FEEDBACK_DASHBOARD) {
                                if (thisProps.is_source_weightage === APPLICATION_MODES.YES) {
                                    reportListOptions = [...reportListOptions, {
                                        label: data.name,
                                        value: data.id,
                                        application_name: data.application_name,
                                        components: data.components,
                                        subscription_template: data.subscription_template
                                    }];
                                }
                            } else {
                                reportListOptions = [...reportListOptions, {
                                    label: data.name,
                                    value: data.id,
                                    application_name: data.application_name,
                                    components: data.components,
                                    subscription_template: data.subscription_template
                                }];
                            }

                        }
                    }
                });
            }
            if (thisProps.operationsList) {
                thisProps.operationsList.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE && data.application_name === selectedOption.label) {
                        if (data.customer_type && (data.customer_type.indexOf("*") > -1 || data.customer_type.indexOf(thisProps.customerCategory) > -1)) {
                            if (data.name === PROPERTY_ROLES.ACTION_TRACKING) {
                                if (thisProps.is_action_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.RESPONSE_TRACKING) {
                                if (thisProps.is_response_trackable === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.CHATBOT) {
                                if (thisProps.customerInformation.is_chatbot === APPLICATION_MODES.YES) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else if (data.name === PROPERTY_ROLES.PREDICTO_ACCESS) {
                                if (thisProps.customerInformation.is_predicto) {
                                    operationListOptions = [...operationListOptions, {
                                        label: data.name,
                                        value: data.name
                                    }];
                                }
                            } else {
                                operationListOptions = [...operationListOptions, { label: data.name, value: data.name }];
                            }
                        }
                    }
                });

            }
        }
        reportListOptions.sort(dynamicSort("label"));
        operationListOptions.sort(dynamicSort("label"));

        this.setState({
            operationListOptions,
            reportListOptions
        });

    };

    _handleApplicationName = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                status: true,
            },
            selectedApplicationName: selectedOption,
            enabled_operations: [],
            enabled_reports: []
        });
        this._loadOperationReports(this.props, selectedOption);
    };

    render() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({ inValidForm: true });
                }, true);
        }
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat" />}
                    </TatvamCol>
                </Row>
                <Row>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <form onSubmit={e => this._handleSave(e)} className="mb-2 h-15v mt-2">
                            {this.state.applicationMode !== APPLICATION_MODES.CREATE ? (
                                <h4>{PROPERTY_ROLES.EDIT_ROLE}</h4>) : ""}
                            <TatvamCol s={12} className="valign-wrapper roleForm mt-3">

                                <TatvamCol s={12} m={6} l={6} className="p-0">
                                    <TatvamTextBox
                                        className={(this.state.inValidForm && !this.state.name) ? "labelText mt-0 validate project_name invalid" : "labelText mt-0 validate project_name"}
                                        s={12}
                                        required
                                        label="Name*"
                                        name="name"
                                        onChange={this._handleInput}
                                        value={this.state.name}
                                        disabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                        autocomplete="off"
                                        maxlength={255}
                                    />
                                </TatvamCol>
                                <TatvamCol s={12} m={6} l={6} className="valign-wrapper">
                                    <label htmlFor="status" className="status-label">
                                        {PROPERTY_ROLES.STATUS}
                                    </label>
                                    <Switch
                                        id="roleStatus"
                                        checked={this.state.isActive}
                                        onClick={this._handleInput}
                                        m={3}
                                    />
                                </TatvamCol>
                            </TatvamCol>
                            <TatvamCol s={12} className="formRow">
                                <TatvamCol m={6} className="mt-.75 d-flex flex-column">
                                    <label htmlFor="applicationName">{PROPERTY_ROLES.APPLICATION_NAME}</label>
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        className={(this.state.inValidForm && this.state.selectedApplicationName.length === 0) ? "validate select-ht invalid-field" : "validate select-ht"}
                                        value={this.state.selectedApplicationName}
                                        name="applicationName"
                                        id="applicationName"
                                        onChange={this._handleApplicationName}
                                        options={this.state.applicationListOptions}
                                        placeholder={PROPERTY_ROLES.PLEASE_SELECT}
                                        isDisabled={(this.state.applicationMode === "EDIT") || (this.state.applicationMode === "VIEW")}
                                    />
                                </TatvamCol>
                            </TatvamCol>
                            <TatvamCol s={12} className="formRow">
                                <TatvamCol m={6} className="mt-.75 d-flex flex-column">
                                    <label htmlFor="operation" className="col s12 ">
                                        {PROPERTY_ROLES.OPERATIONS}
                                    </label>
                                    <TatvamMultiSelect
                                        className={"validate"}
                                        classNamePrefix="react-select"
                                        value={this.state.enabled_operations}
                                        label="Operation"
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="operation"
                                        onChange={this.operationsClicked}
                                        options={this.state.operationListOptions}
                                        isDisabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                        placeholder={PROPERTY_ROLES.SELECT_OPERATIONS}
                                    />
                                </TatvamCol>
                                <TatvamCol m={6} className="mt-.75 d-flex flex-column pr-0">
                                    <label htmlFor="report" className="col s12 ">
                                        {PROPERTY_ROLES.REPORTS}
                                    </label>
                                    <TatvamMultiSelect
                                        className={"validate"}
                                        classNamePrefix="react-select"
                                        value={this.state.enabled_reports}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="report"
                                        onChange={this.reportClicked}
                                        options={this.state.reportListOptions}
                                        isDisabled={this.state.applicationMode === APPLICATION_MODES.VIEW}
                                        placeholder={PROPERTY_ROLES.SELECT_REPORTS}
                                    />
                                </TatvamCol>
                                <button type="submit" ref="submit" className="hide">
                                    Submit
                                </button>
                            </TatvamCol>
                        </form>
                    ) : (
                        <Row>
                            <h4>{PROPERTY_ROLES.VIEW_TITLE_ROLE}</h4>
                            <TatvamCol m={12} className="mt-.75">
                                <div className="mt-0 col s12 m6 row1">
                                    <span className="property-label col s4">{PROPERTY_ROLES.VIEW_NAME} :</span>
                                    <span className="col s7 url-wrap">{this.state.name}</span>
                                </div>
                                <div className="mt-0 col s12 m6">
                                    <span className="property-label col s4"> {PROPERTY_ROLES.STATUS} : </span>
                                    <span
                                        className="col s7">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                                </div>
                            </TatvamCol>
                            <TatvamCol m={12} className="mt-.75">
                                <div className="mt-0 col s12 m6 row1">
                                    <span className="property-label col s4">{PROPERTY_ROLES.APPLICATION_NAME} :</span>
                                    <span className="col s7 url-wrap">{this.state.selectedApplicationName.label}</span>
                                </div>
                            </TatvamCol>
                            <TatvamCol m={12} className="mt-.75">
                                <div className="mt-0 col s12 m6 row1">
                                    <span className="property-label col s4">{PROPERTY_ROLES.VIEW_OPERATIONS} :</span>
                                    <span className="col s8 multiselectView">
                                        <ul className="m-0 ">
                                            {this.state.enabled_operations.map(data => (
                                                <span><li> {data.label} </li></span>
                                            ))}
                                        </ul>
                                    </span>
                                </div>
                                <div className="mt-0 col s12 m6">
                                    <span className="property-label col s4">{PROPERTY_ROLES.VIEW_REPORTS} :</span>
                                    <span className="col s8 multiselectView">
                                        <ul className="m-0">
                                            {this.state.enabled_reports.map(data => (
                                                <span className="mt-0 mb-0"><li> {data.label}</li>  </span>
                                            ))}
                                        </ul>
                                    </span>
                                </div>
                            </TatvamCol>
                        </Row>
                    )}
                </Row>
                <Row className="pr-1 mt-3">
                    <p className="errorText col s12">  {!this.state.isFormValid && !Array.isArray(this.state.selectedApplicationName) && VALIDATION_MESSAGES.ENTER_OPERATIONS_REPORTS}</p>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <TatvamButton
                            waves="light"
                            className="mt-1 CreateProjectPublish btn_primary "
                            onClick={this._triggerSubmit}
                            disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                        >
                            {FORM_OPERATIONS.SAVE}
                        </TatvamButton>
                    ) :
                        this.props.rolePermission === APP_BOOLEANS.TRUE && (
                            <TatvamButton
                                waves="light"
                                className="mt-1 CreateProjectPublish btn_primary "
                                name="EDIT"
                                onClick={this._handleAppMode}
                            >
                                {FORM_OPERATIONS.EDIT}
                            </TatvamButton>
                        )}
                    <TatvamButton
                        waves="light"
                        className="mt-1 mr-1  CreateProjectPublish btn_primary"
                        onClick={this._triggerDiscard}
                    >
                        {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                            FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                    </TatvamButton>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />

            </Fragment>
        );
    }
}

export default RoleForm;
