import './customerForm.scss';

import React, {Component} from 'react';

import AvatarEditor from 'react-avatar-editor';

import {TatvamButton, TatvamCol,} from '../../functionalComponents/index';

class ImageCrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: null,
            onCrop: null,
            onApply: null,
            onCancel: null,
            setEditorRef: null,
            scaleValue: 1.2,
            onScaleChange: null,
            setImageData: null
        };
    }


    componentDidMount() {
        this.setState({
            imageSrc: this.props.imageSrc,
            onCrop: this.props.onCrop,
            onApply: this.props.onApply,
            onCancel: this.props.onCancel,
            setEditorRef: this.props.setEditorRef,
            scaleValue: this.props.scaleValue,
            onScaleChange: this.props.onScaleChange,
            setImageData: this.props.setImageData,
            userProfilePic: this.props.userProfilePic

        });
    }

    componentWillReceiveProps(props) {
        this.setState({
            imageSrc: props.imageSrc,
            onCrop: props.onCrop,
            onApply: props.onApply,
            onCancel: props.onCancel,
            setEditorRef: props.setEditorRef,
            scaleValue: props.scaleValue,
            onScaleChange: props.onScaleChange,
            userProfilePic: props.userProfilePic

        });
    }

    render() {
        return (
            <div>
                <div className="editorOverlayInner">
                    <div className="editorModalContent clearfix" style={{float: "left"}}>
                        <div className="cropCnt">
                            <AvatarEditor width={180} height={100} image={this.state.imageSrc} border={50}
                                          scale={this.state.scaleValue} rotate={0} ref={this.state.setEditorRef}
                                          className="cropCanvas"/>
                            <input style={{width: '280px'}} type="range" value={this.state.scaleValue} step="0.01"
                                   min="0.1" max="2" onChange={this.state.onScaleChange}/>
                        </div>
                        {(this.state.userProfilePic &&
                            <div className={"previewContainer"}>
                                <div className={"previewArea"}>
                                    <img src={this.state.userProfilePic} alt="Profile"/>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <div style={{clear: "both"}} className={"mb-5"}>
                    <TatvamCol className="">
                        <TatvamButton
                            className="btn waves-effect waves-light mt-1 mr-1  CreateProjectPublish btn_primary"
                            name="CANCEL"
                            onClick={this.state.onCancel}
                        >
                            {"Cancel"}
                        </TatvamButton>
                        <TatvamButton
                            className="btn waves-effect waves-light mt-1 mr-1  CreateProjectPublish btn_primary"
                            name="APPLY"
                            onClick={this.state.onApply}
                            disabled={!this.state.userProfilePic}
                        >
                            {"Apply"}
                        </TatvamButton>
                        <TatvamButton
                            className="btn waves-effect waves-light mt-1 mr-1  CreateProjectPublish btn_primary"
                            name="CROP"
                            onClick={this.state.onCrop}
                            disabled={!this.state.imageSrc}
                        >
                            {"Crop"}
                        </TatvamButton>
                    </TatvamCol>
                </div>
            </div>
        );
    }
}


export default ImageCrop;
