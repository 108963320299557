/* eslint-disable no-console */
import React from 'react';

import {Redirect, Route, Switch,} from 'react-router-dom';

import APISourceDetails from '../components/applicationComponents/apiSourceDetails';
import CustomerReportsUsageLogs from '../components/applicationComponents/customerReportsUsageLog';
import CustomerUsageLogs from '../components/applicationComponents/customerUsageLog';
import customerUsersReportsUsageLog from '../components/applicationComponents/customerUsersReportsUsageLog';
import CustomerUsersUsageLogs from '../components/applicationComponents/customerUsersUsageLog';
import ReportUsageLogs from '../components/applicationComponents/reportsUsageLog';
import industryDimensionValuesDetails from '../components/applicationComponents/industryDimensionValuesDetails';
import SourceXpaths from '../components/applicationComponents/sourceXpaths';
import UserDetails from '../components/applicationComponents/users/userDetails';
import NotFound from '../views/404/notFound';
import ApplicationView from '../views/applicationView/applicationView';
import CustomerDetails from '../views/customerDetails';
import Dashboard from '../views/dashboard';
import FileUpload from '../views/fileUpload';
import IndustryDimensionValue from '../views/industryDimensionValue';
import IndustryMaster from '../views/industryMaster';
import SignIn from '../views/signIn';
import authService from '../views/signIn/authService';
import SourceMaster from '../views/sourceMasters';
import UserMaster from '../views/usersMaster';

function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => (authService.isAuthenticated() === true
                ? <Component {...props} />
                : <Redirect to="/signin"/>)}
        />
    );
}

function AuthenticateRoute({component: Component, authSuccessUrl, ...rest}) {
    return (
        <Route
            exact
            render={(props) => (authService.isAuthenticated() === true
                ? <Redirect {...rest} to={authSuccessUrl}/>
                : <Component {...props} />)}
        />
    );
}

const MainRoute = () => (
    <Switch>

        <AuthenticateRoute
            exact
            path="/"
            component={(props) => <SignIn {...props} />}
            authSuccessUrl="/dashboard"
        />

        <AuthenticateRoute
            exact
            path="/signin"
            component={(props) => <SignIn {...props} />}
            authSuccessUrl="/dashboard"
        />

        <AuthenticateRoute
            exact
            path="/signout"
            component={(props) => <SignIn {...props} />}
            authSuccessUrl="/signin"
        />

        <PrivateRoute
            exact
            path="/dashboard"
            key="customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="Dashboard"/>}
        />

        <PrivateRoute
            exact
            path="/live"
            key="live-customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="Live"/>}
        />

        <PrivateRoute
            exact
            path="/demo"
            key="demo-customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="Demo"/>}
        />

        <PrivateRoute
            exact
            path="/competitors"
            key="competitors-customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="Competitor"/>}
        />

        <PrivateRoute
            exact
            path="/tenants"
            key="tenants-customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="Tenants"/>}
        />

        <PrivateRoute
            exact
            path="/groupinstances"
            key="groupinstance-customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="Group Instances"/>}
        />


        <PrivateRoute
            exact
            path="/inactive-customers"
            key="inactive-customers"
            component={(props) => <ApplicationView Component={Dashboard} {...props} category="InActive"/>}
        />

        {/* <Route exact path='/' */}
        {/* component={(props) => <Dashboard {...props} category={"Live"}/>}/> */}

        <PrivateRoute
            exact
            path="/customer"
            key="create-customer"
            component={(props) => <ApplicationView Component={CustomerDetails} {...props} />}
        />
        <PrivateRoute
            exact
            path="/customerdetails"
            key="view-edit-customer"
            component={(props) => <ApplicationView Component={CustomerDetails} {...props} />}
        />

        <PrivateRoute
            exact
            path="/users"
            key="users"
            component={(props) => <ApplicationView Component={UserMaster} {...props} />}
        />

        <PrivateRoute
            exact
            path="/userdetails"
            key="view-edit-users"
            component={(props) => <ApplicationView Component={UserDetails} {...props} />}
        />

        <PrivateRoute
            exact
            path="/webSources"
            key="view-edit-webSource"
            component={(props) => <ApplicationView Component={SourceMaster} {...props} category="webSource"/>}
        />

        <PrivateRoute
            exact
            path="/apiSources"
            key="view-edit-apiSource"
            component={(props) => <ApplicationView Component={SourceMaster} {...props} category="apiSource"/>}
        />

        <PrivateRoute
            exact
            path="/source-Xpaths"
            key="view-edit-source-Xpaths"
            component={(props) => <ApplicationView Component={SourceXpaths} {...props} />}
        />

        <PrivateRoute
            exact
            path="/industry"
            key="view-edit-source-Xpaths"
            component={(props) => <ApplicationView Component={IndustryMaster} {...props} />}
        />

        <PrivateRoute
            exact
            path="/industryDimensionValue"
            key="view-edit-industryDimensionValue"
            component={(props) => <ApplicationView Component={IndustryDimensionValue} {...props} />}
        />

        <PrivateRoute
            exact
            path="/industryDimensionValueDetails"
            key="view-edit-industryDimensionValue"
            component={(props) => <ApplicationView Component={industryDimensionValuesDetails} {...props} />}
        />

        <PrivateRoute
            exact
            path="/api-sourceDetails"
            key="view-edit-api-sourceDetails"
            component={(props) => <ApplicationView Component={APISourceDetails} {...props} />}
        />

        <PrivateRoute
            exact
            path="/fileUpload"
            key="view-edit-fileUpload"
            component={(props) => <ApplicationView Component={FileUpload} {...props} />}
        />

        <PrivateRoute
            exact
            path="/customersUsageLog"
            key="customers-usage-logs"
            component={(props) => <ApplicationView Component={CustomerUsageLogs} {...props} />}
        />

        <PrivateRoute
            exact
            path="/customerUsersUsageLog"
            key="customer-users-usage-logs"
            component={(props) => <ApplicationView Component={CustomerUsersUsageLogs} {...props}/>}
        />

        <PrivateRoute
            exact
            path="/customerReportsUsageLog"
            key="customer-reports-usage-logs"
            component={(props) => <ApplicationView Component={CustomerReportsUsageLogs} {...props}/>}
        />

        <PrivateRoute
            exact
            path="/customerUsersReportsUsageLog"
            key="customer-users-reports-usage-logs"
            component={(props) => <ApplicationView Component={customerUsersReportsUsageLog} {...props}/>}
        />

        <PrivateRoute
            exact
            path="/reportUsageLog"
            key="reports-usage-logs"
            component={(props) => <ApplicationView Component={ReportUsageLogs} {...props}/>}
        />

        <Route path="*" component={NotFound}/>
    </Switch>
);

export default MainRoute;
