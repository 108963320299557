import './signIn.scss';

import React, {Component, Fragment,} from 'react';

import Loader from 'react-loaders';
import {Col, Row,} from 'react-materialize';

import Footer from '../../components/applicationComponents/layout/footer';
import {TatvamButton, TatvamCol, TatvamTextBox,} from '../../components/functionalComponents';
import {SIGN_IN} from '../../constants/appConstants';
import {validateUrl} from '../../utils/stringUtil';

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            preLoader: false,
            user_name: "",
            password: "",
            isUserNameValid: true,
            isPasswordValid: true,
            isUserValid: true,
            shouldActivate: false,
            isvalidEmail: true
        };
        sessionStorage.setItem("userLoggedIn", "true");
    }

    _handleSignIn = (e) => {
        e.preventDefault();
        let isEmailValid = true;
        const signInData = {};
        if (!this.state.password) {
            this.setState({
                isPasswordValid: false
            });
        } else {
            this.setState({
                isPasswordValid: true
            });
            signInData.password = this.state.password;

        }

        if (!this.state.user_name) {
            this.setState({
                isUserNameValid: false,
                isvalidEmail: true
            });

        } else {
            if (!validateUrl(this.state.user_name)) {
                isEmailValid = false;
            } else {
                isEmailValid = true;
            }
            this.setState({
                isUserNameValid: true,
                isvalidEmail: isEmailValid
            });
            signInData.user_name = this.state.user_name;
        }

        if (signInData.user_name && signInData.password && isEmailValid) {
            this.setState({
                preLoader: true
            });
            this.props.actions.UserSignIn(signInData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response) {
                    if (response.status === 200) {

                        this.props.actions.UserPermissions().then((rr) => {
                            this.props.actions.fetchUserDetails().then((resp) => {
                                let path = "/dashboard";
                                this.props.history.push({
                                    pathname: path
                                });

                            });
                        });
                    }

                    if (response.data && response.data.message) {
                        this.setState({
                            password: ""
                        });
                        this.setState({
                            isUserValid: false,
                            alert_information: response.data.message
                        });
                    } else {
                        this.setState({
                            alert_information: response.message,
                            isUserValid: false
                        });
                    }
                }
            });
        }
    };

    _handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            isUserValid: true,
            shouldActivate: true
        });
    };

    componentDidMount() {
        if (this.state.user_name !== "" || this.state.password !== "") {
            this.setState({
                shouldActivate: true
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <Col s={12} className={this.state.preLoader ? "valign-wrapper loginLoader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </Col>
                </Row>
                <Row className="signInWrapper">
                    <div className="col signIn">
                        <TatvamCol s={12} m={6} className="logo_Section">
                            <img src={require("../../../public/assets/images/comments.png")} alt="tatvam-img"/>
                            <p className="tagline-text">
                                Creating visible business impact by harnessing the power of big data and analytics
                            </p>
                        </TatvamCol>
                        <TatvamCol s={12} m={6} className="signInControlls">
                            <TatvamCol s={12} className="signIn_Card">
                                <img src={require("../../../public/assets/images/logo/tatvamLogo.png")}
                                     alt="tatvam-logo"/>
                                <form onSubmit={(e) => this._handleSignIn(e)} autoComplete="off">
                                    <TatvamTextBox
                                        className="signin_text labelText validate "
                                        s={8}
                                        required
                                        validate
                                        label={
                                            <span>
                        {SIGN_IN.USER_NAME}
                                                <span style={{color: 'red'}}> *</span>
                      </span>
                                        }
                                        name="user_name"
                                        autoComplete="username"
                                        onChange={this._handleInput}
                                        value={this.state.user_name}
                                        autoFocus
                                    />
                                    <p className={!this.state.isUserNameValid ? "errorText col s12" : "errorText col s12 hide"}>  {(!this.state.isUserNameValid && "Email is Required")}</p>
                                    <p className={!this.state.isvalidEmail ? "errorText col s12" : "errorText col s12 hide"}>  {!this.state.isvalidEmail && "Please enter valid email id"}</p>

                                    <TatvamTextBox
                                        s={8}
                                        className="signin_text validate labelText"
                                        label={
                                            <span>
                        {SIGN_IN.PASSWORD}
                                                <span style={{color: 'red'}}> *</span>
                      </span>
                                        }
                                        password
                                        name="password"
                                        onChange={this._handleInput}
                                        value={this.state.password}
                                        autoComplete="current-password"
                                    />
                                    <p className="errorText col s12"> {!this.state.isPasswordValid && "Password is Required "} </p>
                                    <TatvamCol s={12}>

                                        <p className="errorText  s12"> {!this.state.isUserValid && this.state.alert_information} </p>
                                    </TatvamCol>
                                    <button type="submit" className="hide">Submit</button>
                                    <TatvamCol s={12}>
                                        <TatvamButton
                                            s={12}
                                            onClick={this._handleSignIn}
                                            className="btn_primary mb-3 mt-2"
                                            disabled={!this.state.shouldActivate}
                                        >
                                            {SIGN_IN.SING_IN}
                                        </TatvamButton>
                                    </TatvamCol>
                                </form>
                            </TatvamCol>
                        </TatvamCol>
                    </div>
                </Row>
                <Footer/>
            </Fragment>
        );
    }
}

export default SignIn;
