import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PredictionInfluencerForm from "./predictionInfluencerForm";
import {createPredictionInfluencer, editPredictionInfluencers} from "../../../actions/index";

const mapStateToProps = (state, ownProps) => {
    return {
        userName: state.auth.userName,
        customerSources: ownProps.currentCustomerId ? state.customer.list[ownProps.currentCustomerId].sources : [],
        rolePermission: state.auth.permissions && state.auth.permissions.customer.roles.create
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createPredictionInfluencer,
            editPredictionInfluencers,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PredictionInfluencerForm);
