import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchCustomerIndustry, saveCustomer, updateCustomerCompetitors} from "../../../actions";
import CompetitorsForm from "./competitorsForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    competitorPermission: state.auth.permissions && state.auth.permissions.customer.competitors.create
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            saveCustomer,
            fetchCustomerIndustry,
            updateCustomerCompetitors
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorsForm);

