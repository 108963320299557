import './customerGrid.scss';

import React, {Component, Fragment,} from 'react';

import {Row} from 'react-materialize';

import {APPLICATION_MODES, CARD_TEXT,} from '../../../constants/appConstants';
import {trimCapitalize} from '../../../utils/stringUtil';
import {CustomerCard, TatvamCol, TatvamMultiSelect, TatvamPagination, TatvamTextBox,} from '../../functionalComponents';

class CustomerGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageOfItems: [],
            pager: [],
            customers: [],
            selectOption: '',
            selectSearch: '',
            searchName: '',
            beforeSearchCustomers: [],
            noRecords: '',
            sortListsOptions: [{value: 'id', label: "Property ID (Low - High)"},
                {value: 'id--desc', label: "Property ID (High - Low)"},
                {value: 'name', label: "Name (A - Z)"},
                {value: 'name--desc', label: "Name (Z - A)"},
                {value: 'license_type', label: "License (A - Z)"},
                {value: 'license_type--desc', label: "License (Z - A)"},
                {value: 'industry', label: "Industry (A - Z)"},
                {value: 'industry--desc', label: "Industry (Z - A)"},
                {value: 'status', label: "Status (A - Z)"},
                {value: 'status--desc', label: "Status (Z - A)"}
            ],
            searchListsOptions: [{value: 'name', label: "Name"},
                {value: 'license_type', label: "License"},
                {value: 'industry', label: "Industry"},
                {value: 'status', label: "Status"}
            ]

        };
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangePagerValues = this.onChangePagerValues.bind(this);
    }

    onChangePage(pageOfItems) {
        this.setState({pageOfItems: pageOfItems});
    }

    onChangePagerValues(pager) {
        this.setState({pager: pager});
    }

    componentDidMount() {
        if (this.props.customers && Object.keys(this.props.customers).length > 0) {
            const tempCustomers = [];
            for (let data of Object.values(this.props.customers)) {
                if (this.props.category === "Live" || this.props.category === "Demo" || this.props.category === "Competitor" || this.props.category === "Tenants") {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate === undefined || trimCapitalize(data.corporate) === APPLICATION_MODES.NO) {
                            tempCustomers.push(data);
                        }
                    }
                } else if (this.props.category === APPLICATION_MODES.GROUP_INSTANCE) {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate !== undefined && trimCapitalize(data.corporate) === APPLICATION_MODES.YES) {
                            tempCustomers.push(data);
                        }
                    }
                } else {
                    if (trimCapitalize(data.status) === APPLICATION_MODES.INACTIVE) {
                        tempCustomers.push(data);
                    }

                }
            }
            this.setState({customers: tempCustomers, beforeSearchCustomers: tempCustomers});
        }

    }

    componentWillReceiveProps(props) {
        if (props.customers.length) {
            const tempCustomers = [];
            for (let data of Object.values(props.customers)) {

                if (props.category === "Live" || props.category === "Demo" || props.category === "Competitor" || props.category === "Tenants") {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate === undefined || trimCapitalize(data.corporate) === APPLICATION_MODES.NO) {
                            tempCustomers.push(data);
                        }
                    }
                } else if (props.category === APPLICATION_MODES.GROUP_INSTANCE) {
                    if (trimCapitalize(data.status) !== APPLICATION_MODES.INACTIVE) {
                        if (data.corporate !== undefined && trimCapitalize(data.corporate) === APPLICATION_MODES.YES) {
                            tempCustomers.push(data);
                        }
                    }
                } else {
                    if (trimCapitalize(data.status) === APPLICATION_MODES.INACTIVE) {
                        tempCustomers.push(data);
                    }

                }

            }
            this.setState({customers: tempCustomers});
        }
    }

    handleSearchIn = (event) => {
        this.setState({
            selectSearch: {"value": event.value, "label": event.label},
            searchName: '',
            customers: this.state.beforeSearchCustomers,
            noRecords: ''
        });
    };

    invokeSearch = (event) => {
        const searchIn = this.state.selectSearch;
        if (searchIn.value) {
            const searchPhase = event.target.value;
            this.setState({
                searchName: event.target.value
            }, () => {

                const customersData = this.state.beforeSearchCustomers;

                const filteredCustomers = customersData.filter(eachItem => eachItem[searchIn.value].toLowerCase().indexOf(searchPhase.toLowerCase()) !== -1);


                if (filteredCustomers.length > 0) {
                    this.setState({customers: filteredCustomers, noRecords: ''});
                } else {
                    this.setState({noRecords: 'No matches found!! Showing previous result'});
                }


            });
        } else {
            this.setState({noRecords: 'Please select search in criteria'});
        }

    };

    handleSort = (event) => {
        const selOption = event.value.split("--desc")[0];
        const startIndex = this.state.pager.startIndex;
        const endIndex = this.state.pager.endIndex;
        const sortCustomer = this.state.customers;
        let sortedCustomers = sortCustomer.sort((a, b) => a[selOption].localeCompare(b[selOption]))
        if (event.value.indexOf("--desc") !== -1) {
            sortedCustomers = sortedCustomers.reverse();
        }

        const currentPager = sortedCustomers.slice(startIndex, endIndex + 1);
        this.setState({
            customers: sortedCustomers,
            selectOption: {"value": event.value, "label": event.label},
            pageOfItems: currentPager
        });
    };

    customer() {
        return this.state.pageOfItems.map(customer => (
            <CustomerCard
                key={customer.id}
                industry={customer.industry}
                license={customer.license_type}
                id={customer.id} name={customer.name}
                status={customer.status}
                edit={this.props.editCustomer}
                category={customer.category}
                valid_from={customer.valid_from}
                valid_upto={customer.valid_upto}
                logo={customer.logo}
                execution_batch={customer.execution_batch}
            />

        ))
    }


    render() {
        return (
            <Fragment>

                <Row className="customerGrid">
                    <TatvamCol s={12} m={4} xl={4}>
                        <label htmlFor="source">{CARD_TEXT.SORT_TITLE}</label>
                        <TatvamMultiSelect
                            classNamePrefix="react-select"
                            className="validate select-ht "
                            value={this.state.selectOption}
                            id="sortby"
                            onChange={this.handleSort}
                            options={this.state.sortListsOptions}
                            placeholder="Please Select"
                        />
                    </TatvamCol>
                    <TatvamCol s={12} m={4} xl={4}>
                        <label htmlFor="source">{CARD_TEXT.SEARCH_IN_TITLE}</label>
                        <TatvamMultiSelect
                            classNamePrefix="react-select"
                            className="validate select-ht "
                            value={this.state.selectSearch}
                            id="searchIn"
                            onChange={this.handleSearchIn}
                            options={this.state.searchListsOptions}
                            placeholder="Please Select"
                        />
                    </TatvamCol>
                    <TatvamCol s={12} m={4} xl={4}>
                        <TatvamTextBox
                            m={12}
                            label={CARD_TEXT.SEARCH_TITLE}
                            onChange={this.invokeSearch}
                            className="labelText mt-0 validate project_name "
                            value={this.state.searchName}
                            autocomplete="off"
                        />
                    </TatvamCol>

                </Row>


                {/* <TextField id="standard-search" label="Search for" value={this.state.searchName} onChange={this.invokeSearch} /> */}
                <div class="norecord-msg">{this.state.noRecords}</div>
                <div className="pt-2">

                    {this.customer()}
                </div>
                <Row>
                    <TatvamPagination items={this.state.customers} onChangePage={this.onChangePage}
                                      onChangePagerValues={this.onChangePagerValues}/>
                </Row>
            </Fragment>
        );
    }
}

export default CustomerGrid;
