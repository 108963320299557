import React, {Component, Fragment,} from 'react';

/* eslint-disable no-console */
import {APPLICATION_MODES} from 'localConstants';
import Loader from 'react-loaders';
import {Row} from 'react-materialize';

import {ALERT_MESSAGES, FORM_OPERATIONS, TENANT, VALIDATION_MESSAGES,} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect,} from '../../functionalComponents/index';
import AlertBox from '../alertBox';

class TenantsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tenantsListOptions: [],
            tenantsValid: true,
            selectedValues: [],
            preLoader: false
        };
    }

    _selectTenant = selectedOption => {
        let tenantsValid = true;
        let number = "";
        if (selectedOption) {
            if (Array.isArray(selectedOption)) {
                if (this.state.applicationMode !== "CREATE") {
                    if (selectedOption.length > 0) {
                        if (this.props.licenseType === "Gold") {
                            if (selectedOption.length > 4)
                                tenantsValid = false;
                            number = 4;
                        }
                        if (this.props.licenseType === "Silver") {
                            if (selectedOption.length > 2)
                                tenantsValid = false;
                            number = 2;
                        }
                        if (this.props.licenseType === "Bronze") {
                            if (selectedOption.length > 1)
                                tenantsValid = false;
                            number = 1;
                        }
                    } else {
                        tenantsValid = false;
                    }

                } else {
                    if (selectedOption.length > 0) {
                        if (this.props.licenseType === "Gold") {
                            if (selectedOption.length + this.props.tenantsList.length > 4)
                                tenantsValid = false;
                            number = 4;
                        }
                        if (this.props.licenseType === "Silver") {
                            if (selectedOption.length + this.props.tenantsList.length > 2)
                                tenantsValid = false;
                            number = 2;
                        }
                        if (this.props.licenseType === "Bronze") {
                            if (selectedOption.length + this.props.tenantsList.length > 1)
                                tenantsValid = false;
                            number = 1;
                        }
                    } else {
                        tenantsValid = false;
                    }

                }

            }
        } else {
            tenantsValid = false;
        }

        this.setState({
            tenantsValid,
            number
        });
        this.setState({
            selectedValues: selectedOption,
            editFlag: {
                selectedValues: true
            }
        });

    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST
            });
        } else if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
            this.props.closeModal("alertCreateModal");
        } else {
            this.props.closeModal("alertViewModal");
        }
    };
    _discard = data => {
        if (data === "ok") {
            if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
                this.props.closeModal("alertCreateModal");
            } else {
                this.props.closeModal("alertViewModal");
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };
    _handleSave = e => {
        e.preventDefault();
        let tenants = [];
        if (Array.isArray(this.state.selectedValues)) {
            this.state.selectedValues.map(data => {
                tenants = [...tenants, data.value];
            });
        } else {
            tenants = [...tenants, this.state.selectedValues[0].value];
        }
        if (this.state.applicationMode === "CREATE") {
            this.state.selectedTenantValues.map((data) => {
                tenants = [...tenants, data.value];
            });
        }
        const tenantData = {
            id: this.props.currentCustomer.id,
            tenants
        };

        if (this.state.tenantsValid) {
            this.setState({
                preLoader: true
            });

            this.props.actions.saveCustomer(tenantData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message ? response.data.message : response.message
                    });
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data.message ? response.data.message : response.message
                    });
                }
            });
        }

    };

    componentDidMount() {
        const selectedTenantValues = [];
        let tenantsListOptions = [];

        if (this.props.tenantsList) {
            this.props.tenantsList.map(data => {
                selectedTenantValues.push({label: data.name, value: data.id, industry: data.industry});
            });
        }

        let selectedValueOptions = selectedTenantValues.map((opt) => {
            return opt.value
        })
        if (this.props.tenantsListOptions) {
            tenantsListOptions = this.props.tenantsListOptions.filter(val => !selectedValueOptions.includes(val.value) && val.value !== this.props.currentCustomer.id);
            tenantsListOptions = tenantsListOptions.map((item) => {
                item.label = item.value + " - " + item.label;
                return item;
            });
            tenantsListOptions.sort(dynamicSort("label"));
        }

        this.setState({
            tenantsListOptions,
            applicationMode: this.props.applicationMode,
            selectedValues: selectedTenantValues
        });
        if (this.props.applicationMode === "CREATE") {
            this.setState({
                selectedValues: [],
                selectedTenantValues
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{TENANT.VIEW_TITLE_TENANT}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (<h4>{TENANT.EDIT_TENANT}</h4>) : ""}</Row>
                <Row>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <Row>
                            <div className="col s12 valign-wrapper mb-2">
                                <label htmlFor="tenant" className="col s2 right-align tenant-label">
                                    {TENANT.TENANTS}<span style={{color: 'red'}}> *</span> :
                                </label>
                                <TatvamCol s={10} className="mt-.75">
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        value={this.state.selectedValues}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        name="selectedValues"
                                        id="tenant"
                                        onChange={this._selectTenant}
                                        options={this.state.tenantsListOptions}
                                        placeholder={TENANT.SELECT_TENANTS}
                                    />
                                </TatvamCol>
                            </div>
                            <div className="col s12 valign-wrapper mb-2">
                                <label htmlFor="industry"
                                       className="col s2 right-align tenant-label">{TENANT.INDUSTRY} :</label>
                                <span className="col s10">
                  <ul className="m-0">
                    {this.state.selectedValues && this.state.selectedValues.map(data =>
                        <span className="mt-0 mb-0"><li>{data.label} - {data.industry}</li>  </span>
                    )}
                  </ul>
                </span>
                            </div>

                        </Row>
                    ) : (
                        <Row>
                            <TatvamCol s={12} className="d-flex">
                                <span className="property-label col s2">{TENANT.VIEW_TENANTS} :</span>
                                <span className="col s10">
                  <ul className="m-0">
                    {(this.props.selectedTenantData && this.props.selectedTenantData.length > 0 &&
                        <span className="mt-0 mb-0"><li> {this.props.selectedTenantData[0].name}</li>  </span>
                    )}
                  </ul>
                </span>
                            </TatvamCol>
                            <TatvamCol s={12} className="d-flex pt-1">
                                <label className="col s2 property-label">{TENANT.VIEW_INDUSTRY} :</label>
                                <span className="col s10">
                  <ul className="mt-0">
                    {(this.props.selectedTenantData && this.props.selectedTenantData.length > 0 &&
                        <span><li> {this.props.selectedTenantData[0].industry}</li>  </span>
                    )}
                  </ul>
                </span>
                            </TatvamCol>
                        </Row>
                    )}

                    <Row className="modalButtons p-0">
                        <TatvamCol s={12} m={8}>
                            {/*ERROR MESSAGES*/}

                            {!this.state.tenantsValid && this.state.selectedValues && this.state.selectedValues.length > 0 &&
                                <p className="errorText">{TENANT.NO_OF_TENANT_VALIDATON_1 + this.state.number + TENANT.NO_OF_TENANT_VALIDATON_2}</p>
                            }
                            {!this.state.tenantsValid && this.state.selectedValues && this.state.selectedValues.length <= 0 &&
                                <p className="errorText"> {VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS} </p>
                            }
                        </TatvamCol>
                        <TatvamCol s={12} m={4} className="pr-2">
                            {this.state.applicationMode !== APPLICATION_MODES.VIEW && (
                                <TatvamButton
                                    waves="light"
                                    className="mt-2  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && this.state.editFlag.selectedValues) || !this.state.tenantsValid}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            )
                            }
                            <TatvamButton waves="light" className="mt-2 mr-4 CreateProjectPublish btn_primary"
                                          onClick={this._triggerDiscard}>
                                {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                                !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                    FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                            </TatvamButton>
                        </TatvamCol>
                    </Row>

                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                </Row>
            </Fragment>
        );
    }
}

export default TenantsForm;
