export function validateUrl(url) {
    // eslint-disable-next-line no-useless-escape
    let expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);
    return !!url.match(regex);

}

export function validateNumber(number) {
    // eslint-disable-next-line no-useless-escape
    let expression = /^[0-9]{1,6}$/;
    let regex = new RegExp(expression);
    return !!number.match(regex) && parseInt(number) > 0;

}


export function validateSequenceNumber(number) {
    // eslint-disable-next-line no-useless-escape
    let expression = /^[0-9]{1,3}$/;
    let regex = new RegExp(expression);
    return !!number.match(regex) && parseInt(number) > 0;

}

export function validateName(name) {
    let expression = /^[A-Za-z]+([\ A-Za-z]+)*/;
    let regex = new RegExp(expression);
    return !!name.match(regex);
}

export function validateEntityName(name, expression) {
    let regex = new RegExp(expression);
    return !!name.match(regex);
}


export function validatePattern(name) {
    return true;
}

export function trim(string) {
    return string.replace(/\s/g, "");
}

export function trimCapitalize(string) {
    let text = "";
    if (string) {
        text = trim(string);
    }

    return text && text[0].toUpperCase() + text.slice(1);
}

export function validateEmail(name) {
    let expression = /^[^@]+@[^@]+\.[a-zA-Z]{2,6}$/;
    let regex = new RegExp(expression);
    return !!name.match(regex);
}

export function validateCustomPattern(name, expression) {
    let regex = new RegExp(expression);
    return !!name.match(regex);
}

//Format data to dd-mmm-yyyy
export function formatDate(dateValue) {
    const date = new Date(dateValue);
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate;
}
