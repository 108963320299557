import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS} from 'localConstants';

import {
    ALERT_MESSAGES,
    APPLICATION_MODES,
    PROPERTY_DIMENSION,
    PROPERTY_DIMENSION_GRID,
} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import CustomerDimensionForm from '../../../customerDimensionForm';

class Dimensions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            colDef: [{
                headerName: PROPERTY_DIMENSION_GRID.ID,
                field: "_id",
                filterParams: {clearButton: true},
                hide: true
            },
                {
                    headerName: PROPERTY_DIMENSION_GRID.PARENT_ENTITY,
                    field: "parent_entity",
                    filterParams: {clearButton: true},
                    width: 179
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.ENTITY_TYPE,
                    field: "entity_type",
                    filterParams: {clearButton: true},
                    width: 177
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.TAG_TREATMENT,
                    field: "tag_treatment",
                    filterParams: {clearButton: true},
                    width: 133
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.IS_FILTER,
                    field: "is_filter",
                    filterParams: {clearButton: true},
                    width: 115
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.IS_PUBLIC,
                    field: "is_public",
                    filterParams: {clearButton: true},
                    width: 115
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.IS_DISPLAY_TAG,
                    field: "is_displaytag",
                    filterParams: {clearButton: true},
                    width: 155
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    width: 107
                },
                {
                    headerName: PROPERTY_DIMENSION_GRID.ATTRIBUTE_FOR_TAGGING,
                    field: "attribute_for_tagging",
                    filterParams: {clearButton: true},
                    width: 194
                }

            ],
            loader: true
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.customer_id,
            entityType: data.entity_type,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let entity_type = this.state.entityType;
            let dimensionDeleteData = {
                customer_id,
                entity_type

            };
            this.props.actions.deleteCustomerDimension(dimensionDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _openViewModal = (data) => {
        this.setState({
            dimensionData: data[0],
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true,
            loader: true,
            nonEditable: (data[0].customer_id === PROPERTY_DIMENSION.DZERO_CUSTOMER) ? true : false
        });
    };

    _openCreateModal = () => {
        this.setState({
            dimensionData: [],
            modalTitle: PROPERTY_DIMENSION.ADD_DIMENSION,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        let dimensionsGridData = [];
        if (this.props.currentCustomer.id) {
            this.setState({
                loader: true
            });
            this.props.actions.getCustomerDimensions(this.props.currentCustomer.id).then(() => {

                this.setState({
                    loader: false
                });

            });
        }

        if (this.props.gridData) {
            this.props.gridData.map((data) => {
                if (data.status && data.customer_id !== PROPERTY_DIMENSION.DZERO_CUSTOMER) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        dimensionsGridData.push(data);
                    }

                }
            });
        }
        this.setState({
            dimensionsGridData
        }, () => {

            this.setActiveCount();
        });


    }

    setActiveCount = () => {
        const gridData = this.state.dimensionsGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'dimensionSource': {'active': noOfActive.length, 'total': gridData.length}});
    };


    componentWillReceiveProps(props) {
        let dimensionsGridData = [];
        if (props.gridData) {
            props.gridData.map((data) => {
                if (data.status && data.customer_id !== PROPERTY_DIMENSION.DZERO_CUSTOMER) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        dimensionsGridData.push(data);
                    }

                }
            });
        }
        this.setState({
            dimensionsGridData
        }, () => {

            this.setActiveCount();
        });
    }


    render() {
        return (
            <Fragment>
                {this.props.dimensionPermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton className="btn_primary right mt-1 mr-2" onClick={this._openCreateModal}>
                        {PROPERTY_DIMENSION.ADD_DIMENSION}
                    </TatvamButton>
                )}
                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <CustomerDimensionForm
                                applicationMode={this.state.applicationMode}
                                customerId={this.props.currentCustomer.id}
                                closeModal={this.closeModal}
                                dimensionData={this.state.dimensionData}
                                dimensionList={this.props.gridData}
                                nonEditable={this.state.nonEditable}
                            />
                        )}
                    />
                )
                }
                {this.state.dimensionsGridData && this.state.dimensionsGridData.length ? (
                    <TatvamCol s={12} className="grid-data-area">
                        <TatvamGrid
                            gridData={this.state.dimensionsGridData}
                            colData={this.state.colDef}
                            rowSelection="multiple"
                            isExportCsv={this.state.isExportCSV}
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.dimensionDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}

                        />
                    </TatvamCol>

                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            No Dimensions available
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Dimensions;
