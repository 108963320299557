import './customerRule.scss';

import React, { Component, Fragment, } from 'react';

import { APP_BOOLEANS, APPLICATION_MODES, } from 'localConstants';
import Loader from 'react-loaders';
import { Row, Switch, } from 'react-materialize';

import {
    API_SOURCE,
    ALERT_MESSAGES,
    FORM_OPERATIONS,
    PROPERTY_RULES,
    SWITCH_TEXT,
    TEXT_VALIDATION,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import { trimCapitalize, validateCustomPattern, validateEmail, } from '../../../utils/stringUtil';
import { TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextArea, TatvamTextBox, } from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerRuleForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            preLoader: false,
            alert_name: "",
            alert_type: "",
            alertTypValid: true,
            fieldHelper: false,
            textHelper: false,
            ruleTypeList: [
                { label: "NO_ALERT", value: "NO_ALERT" },
                { label: "NO_TRANSLATE", value: "NO_TRANSLATE" },
                { label: "NO_ANNOTATE", value: "NO_ANNOTATE" },
                { label: "NO_RATING", value: "NO_RATING" },
                { label: "NO_BUZZWORDS", value: "NO_BUZZWORDS" },
                { label: "MAIL_ALERT", value: "MAIL_ALERT" },
                { label: "RATING_TYPE", value: "RATING_TYPE" },
                { label: "CUSTOM", value: "CUSTOM" }],
            editFlag: {
                alert_name: false,
                seq_number: "",
                fld_pattern: false,
                text_pattern: false,
                mail_recipients: false,
                alert_description: false,
                status: false,
                selectedRatingType: false
            },
            isPatternValid: true,
            isRecipientMailValid: true,
            ratingTypeListOption: [
                { label: "AI Rating", value: "ai_rating" },
                { label: "Google Rating", value: "google_rating" },
                { label: "Tatvam Rating", value: "tatvam_rating" },
            ],
            selectedRatingType: ""
        };
    }

    _triggerSubmit = () => {
        this.refs.submit.click();
    };

    _handleInput = e => {
        if (e.target.id === "alertStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        } else if (e.target.id === "seq_number") {
            if (e.target.value !== "" && !validateCustomPattern(e.target.value, /^[0-9]{1,25}$/g)) {
                e.target.value = "";
                return false;
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    [e.target.name]: true
                }
            });
        }
    };

    _validation = () => {
        
        if (this.state.alert_type.value && (!this.state.fld_pattern && !this.state.text_pattern)) {
            this.setState({
                isPatternValid: false
            });
            return false;
        } else if (this.state.alert_type.value === "MAIL_ALERT" && this.state.mail_recipients) {
            let isValidEmail = this._validateEmail();
            this.setState({
                isPatternValid: true,
                isRecipientMailValid: isValidEmail
            });
            return isValidEmail;
        } else if (this.state.alert_type.value === "RATING_TYPE" && !this.state.selectedRatingType.value) {
            return false;
        } else {
            this.setState({
                isPatternValid: true,
                isRecipientMailValid: true
            });
            return true;
        }
    };

    _validateEmail = () => {
        let invalidCount = 0;
        let emails = this.state.mail_recipients.split(",");

        for (var i = 0; i < emails.length; i++) {
            if (!validateEmail(emails[i])) {
                invalidCount++;
            }
        }
        return invalidCount === 0;
    }

    _handleRatingTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                selectedRatingType: true
            },
            selectedRatingType: selectedOption
        });
    }

    _handleSave = e => {
        e.preventDefault();

        const ruleData = {
            customer_id: this.props.customerID,
            seq_number: this.state.seq_number,
            alert_name: this.state.alert_name,
            alert_type: this.state.alert_type.value,
            alert_description: this.state.alert_description,
            fld_pattern: this.state.fld_pattern,
            text_pattern: this.state.text_pattern,
            mail_recipients: this.state.mail_recipients,
            status: this.state.status,
        };
        if(this.state.alert_type.value === "RATING_TYPE") {
            ruleData.rating_type = this.state.selectedRatingType.value ? this.state.selectedRatingType.value : ""
        }
        if (this.state.alert_type) {
            if (this.state.alert_type.value === "MAIL_ALERT" && this.state.mail_recipients) {
                let emails = this.state.mail_recipients.split(",");
                ruleData.mail_recipients = [];
                for (var i = 0; i < emails.length; i++) {
                    ruleData.mail_recipients.push(emails[i].trim());
                }
            } else {
                ruleData.mail_recipients = [];
            }
            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                if (!this._validation()) {
                    this.setState({ inValidForm: true });
                }
                if (this._validation()) {
                    this.setState({
                        preLoader: true,
                        alertTypValid: true
                    });
                    ruleData.created_by = this.props.userName;
                    this.props.actions.createCustomerAlertRules(ruleData).then((response) => {
                        this.setState({
                            preLoader: false
                        });

                        if (response.status === 200) {
                            this.setState({ shouldCloseModal: true });
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.data.message
                            });
                        } else {

                            this.setState({
                                shouldCloseModal: false,
                                isAlertBoxOpen: true,
                                isSuccess: false,
                                alert_information: response.data ? response.data.message : response.message
                            });

                        }
                    });
                }
            } else {
                if (!this._validation()) {
                    this.setState({ inValidForm: true });
                }
                if (this._validation()) {
                    this.setState({
                        preLoader: true,
                        alertTypValid: true
                    });
                    ruleData.updated_by = this.props.userName;
                    this.props.actions.editCustomerAlertRules(ruleData).then((response) => {
                        this.setState({
                            preLoader: false
                        });
                        if (response.status === 200) {
                            this.setState({ shouldCloseModal: true });
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.data.message
                            });

                        } else {
                            this.setState({
                                shouldCloseModal: false,
                                isAlertBoxOpen: true,
                                isSuccess: false,
                                alert_information: response.data ? response.data.message : response.message
                            });

                        }
                    });
                }
            }
        } else {
            this.setState({
                alertTypValid: false
            });
        }

    };
    _discard = (data) => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
                    this.props.closeModal("alertCreateModal");
                } else {
                    this.props.closeModal("alertViewModal");
                }
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };
    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };
    _triggerDiscard = () => {
        if (Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });

        } else {
            this.props.closeModal();
        }
    };
    _handleAlertTypeChange = (selectedOption) => {
        this.setState({
            alert_type: selectedOption,
            editFlag: {
                status: true
            }
        });
    };

    componentDidMount() {
        this.setState({
            applicationMode: this.props.applicationMode,
            status: APPLICATION_MODES.INACTIVE,
            isActive: false
        });
        if (this.props.alertRuleData && this.props.applicationMode !== APPLICATION_MODES.CREATE) {
            this.setState({
                ...this.props.alertRuleData,
                alert_type: { label: this.props.alertRuleData.alert_type, value: this.props.alertRuleData.alert_type },
                isActive: trimCapitalize(this.props.alertRuleData.status) === APPLICATION_MODES.ACTIVE,
                mail_recipients: this.props.alertRuleData.mail_recipients && this.props.alertRuleData.mail_recipients.length > 0 ? this.props.alertRuleData.mail_recipients.join(",") : "",
                selectedRatingType: this.state.ratingTypeListOption.find(option => option.value === this.props.alertRuleData.rating_type)
            });
        }
    }

    componentWillReceiveProps(props) {
        if (props.alertRuleData) {
            this.setState({
                ...props.alertRuleData,
                alert_type: { label: props.alertRuleData.alert_type, value: props.alertRuleData.alert_type },
                mail_recipients: props.alertRuleData.mail_recipients && this.props.alertRuleData.mail_recipients.length > 0 ? this.props.alertRuleData.mail_recipients.join(",") : "",
                selectedRatingType: this.state.ratingTypeListOption.find(option => option.value === this.props.alertRuleData.rating_type)
            });
        }
    }

    fieldValidate = (e) => {
        e.preventDefault();
        this.setState({
            fieldValid: true
        });
    };
    textValidate = (e) => {
        e.preventDefault();
        this.setState({
            textValid: true
        });
    };

    render() {
        let self = this;
        if (document.querySelector("form")) {
            document.querySelector("form")
                .addEventListener("invalid", function (event) {
                    event.preventDefault();
                    self.setState({ inValidForm: true });
                }, true);
        }
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat" />}
                    </TatvamCol>
                </Row>
                <Row>
                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                        <Row>
                            {this.state.applicationMode !== APPLICATION_MODES.CREATE ? (
                                <h4>{PROPERTY_RULES.EDIT_RULE}</h4>) : ""}
                            <form onSubmit={(e) => this._handleSave(e)} className="mt-2">
                                <div className="row valign-wrapper custom-dropdown">

                                    <TatvamCol s={12} m={6} xl={6} className="mb-4 ">
                                        <label htmlFor="entity_type">{PROPERTY_RULES.ALERT_RULE_TYPE}<span
                                            style={{ color: 'red' }}> *</span></label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className="validate select-ht "
                                            value={this.state.alert_type}
                                            name="alertRuleType"
                                            id="alertRuleType"
                                            onChange={this._handleAlertTypeChange}
                                            isDisabled={this.state.applicationMode === APPLICATION_MODES.EDIT}
                                            options={this.state.ruleTypeList}
                                            placeholder={PROPERTY_RULES.SELECT_RULE_TYPE}
                                        />

                                    </TatvamCol>

                                    <div className="col s6">
                                        <div className="col s4 mb-3">
                                            <Switch
                                                s={3}
                                                m={3}
                                                l={3}
                                                xl={3}
                                                id="alertStatus"
                                                offLabel={PROPERTY_RULES.STATUS}
                                                checked={this.state.isActive}
                                                onClick={this._handleInput}
                                                disabled={!this.state.alert_type || this.state.alert_type === ""}
                                            />
                                        </div>
                                        <div className='col s8 mb-4'>
                                            {
                                                this.state.alert_type.label === "RATING_TYPE" &&
                                                <TatvamCol s={12} m={12} xl={12}>
                                                    <label htmlFor="apiSource">{API_SOURCE.RATING_TYPE}<span
                                                        style={{ color: 'red' }}> *</span></label>
                                                    <TatvamMultiSelect
                                                        required
                                                        m={12}
                                                        classNamePrefix="react-select"
                                                        className={(this.state.inValidForm && (this.state.selectedRatingType === "" || this.state.selectedRatingType.label === "")) ? "validate select-ht invalid-field" : "validate select-ht"}
                                                        value={this.state.selectedRatingType}
                                                        name="ratingType"
                                                        id="ratingType"
                                                        onChange={this._handleRatingTypeSelect}
                                                        options={this.state.ratingTypeListOption}
                                                        placeholder={API_SOURCE.SELECT_COMMENT_TYPE}
                                                    />
                                                </TatvamCol>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <TatvamTextBox
                                    s={6}
                                    m={6}
                                    l={6}
                                    xl={6}
                                    className={(this.state.inValidForm && !this.state.alert_name) ? "labelText mt-0 validate invalid" : "labelText mt-0 validate"}
                                    validate
                                    required
                                    label={
                                        <span>
                                            {PROPERTY_RULES.ALERT_NAME}
                                            <span style={{ color: 'red' }}> *</span>
                                        </span>
                                    }
                                    name="alert_name"
                                    onChange={this._handleInput}
                                    disabled={this.state.applicationMode === APPLICATION_MODES.EDIT ? true : this.state.applicationMode === APPLICATION_MODES.CREATE ? (!this.state.alert_type || this.state.alert_type === "") : true}
                                    value={this.state.alert_name}
                                    autocomplete="off"
                                    maxlength={255}
                                />
                                <TatvamTextBox
                                    s={6}
                                    m={6}
                                    l={6}
                                    xl={6}
                                    validate
                                    required
                                    className={(this.state.inValidForm && !this.state.seq_number) ? "labelText mt-0 validate seqInput invalid" : "labelText mt-0 validate seqInput"}
                                    label={
                                        <span>
                                            {PROPERTY_RULES.SEQ_NO}
                                            <span style={{ color: 'red' }}> *</span>
                                        </span>
                                    }
                                    id="seq_number"
                                    name="seq_number"
                                    onChange={this._handleInput}
                                    value={this.state.seq_number}
                                    title={TEXT_VALIDATION.SEQ_NO}
                                    disabled={this.state.applicationMode === APPLICATION_MODES.EDIT ? true : this.state.applicationMode === APPLICATION_MODES.CREATE ? (!this.state.alert_type || this.state.alert_type === "") : true}
                                    autocomplete="off"
                                    maxlength={3}
                                />
                                <TatvamCol s={12} className="p-0 valign-wrapper">
                                    <TatvamTextArea
                                        s={12}
                                        m={12}
                                        l={12}
                                        xl={12}
                                        className="labelText mt-0 validate"
                                        label={PROPERTY_RULES.FIELD_PATTERN}
                                        name="fld_pattern"
                                        onChange={this._handleInput}
                                        value={this.state.fld_pattern}
                                        disabled={!this.state.alert_type || this.state.alert_type === ""}
                                        autocomplete="off"
                                    />
                                    <TatvamButton waves="light"
                                        className="valid-btn btn_primary"
                                        onClick={this.fieldValidate}
                                        disabled={!this.state.fld_pattern || this.state.fld_pattern === ""}
                                    >
                                        {PROPERTY_RULES.VALIDATE}
                                    </TatvamButton>

                                    {(this.state.fieldValid === true || this.state.fieldValid === false) && (
                                        <i
                                            className={this.state.fieldValid ? "material-icons successText" : "material-icons errorText"}
                                            title={this.state.textValid ? "Valid Pattern" : "Invalid Pattern"}
                                        >
                                            {this.state.fieldValid ? "offline_pin" : "error"}
                                        </i>
                                    )}
                                    <i
                                        className="material-icons"
                                        title="field pattern helper"
                                        onClick={() => {
                                            this.setState({
                                                fieldHelper: !this.state.fieldHelper,
                                                textHelper: false
                                            });
                                        }}
                                    >info_outline
                                    </i>
                                    {this.state.fieldHelper && (
                                        <div className="form-helper">
                                            Field pattern informations
                                        </div>
                                    )}
                                </TatvamCol>
                                <TatvamCol s={12} className="p-0 valign-wrapper">
                                    <TatvamTextArea
                                        s={12}
                                        m={12}
                                        l={12}
                                        xl={12}
                                        className="labelText mt-0 validate"
                                        label={PROPERTY_RULES.TEXT_PATTERN}
                                        name="text_pattern"
                                        onChange={this._handleInput}
                                        value={this.state.text_pattern}
                                        disabled={!this.state.alert_type || this.state.alert_type === ""}
                                        autocomplete="off"
                                    />
                                    <TatvamButton waves="light"
                                        className="valid-btn btn_primary"
                                        onClick={this.textValidate}
                                        disabled={!this.state.text_pattern || this.state.text_pattern === ""}
                                    >
                                        {PROPERTY_RULES.VALIDATE}
                                    </TatvamButton>

                                    {(this.state.textValid === true || this.state.textValid === false) && (
                                        <i
                                            className={this.state.textValid ? "material-icons successText" : "material-icons errorText"}
                                            title={this.state.textValid ? "Valid Pattern" : "Invalid Pattern"}
                                        >
                                            {this.state.textValid ? "offline_pin" : "error"}
                                        </i>
                                    )}

                                    <i
                                        className="material-icons"
                                        title="text pattern helper"
                                        onClick={() => {
                                            this.setState({
                                                textHelper: !this.state.textHelper,
                                                fieldHelper: false
                                            });
                                        }}
                                    >
                                        info_outline
                                    </i>
                                    {this.state.textHelper && (
                                        <div className="form-helper">
                                            Text pattern informations
                                        </div>
                                    )}
                                </TatvamCol>
                                {(this.state.alert_type.value === "MAIL_ALERT") && (
                                    <TatvamTextArea
                                        s={12}
                                        m={12}
                                        l={12}
                                        xl={12}
                                        className={(this.state.inValidForm && !this.state.mail_recipients) ? "labelText mt-0 validate invalid" : "labelText mt-0 validate"}
                                        required
                                        validate
                                        label={
                                            <span>
                                                {PROPERTY_RULES.RECIPIENTS}
                                                <span style={{ color: 'red' }}> *</span>
                                            </span>
                                        }
                                        name="mail_recipients"
                                        onChange={this._handleInput}
                                        value={this.state.mail_recipients}
                                        disabled={!this.state.alert_type || this.state.alert_type === ""}
                                        placeholder={TEXT_VALIDATION.RECIPIENT_MAIL_PLACEHOLDER}
                                        title={TEXT_VALIDATION.RECIPIENT_MAIL_PLACEHOLDER}
                                    />
                                )}
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    className="labelText mt-0 validate "
                                    validate
                                    label={PROPERTY_RULES.ALERT_DESCRIPTION}
                                    name="alert_description"
                                    value={this.state.alert_description}
                                    onChange={this._handleInput}
                                    disabled={!this.state.alert_type || this.state.alert_type === ""}
                                />
                                <button type="submit" ref="submit" className="hide">
                                    Submit
                                </button>
                            </form>
                            <p className="errorText col s12">  {!this.state.alertTypValid && "Select a alert type"}</p>
                        </Row>
                    ) :
                        (
                            <Fragment>
                                <h4>{PROPERTY_RULES.VIEW_TITLE_RULE}</h4>
                                <Row>
                                    <TatvamCol s={12} m={6}>
                                        <div className="col s12  m-0">
                                            <span
                                                className="property-label col s4">  {PROPERTY_RULES.VIEW_ALERT_RULE_TYPE} : </span>
                                            <span className="col s8">
                                                {this.state.alert_type.value}
                                            </span>
                                        </div>
                                    </TatvamCol>
                                    <TatvamCol s={12} m={6}>
                                        <div className="col s12 m-0">
                                            <span className="property-label col s5">{PROPERTY_RULES.STATUS} :   </span>
                                            <span
                                                className="col s7">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                                        </div>
                                    </TatvamCol>
                                    <TatvamCol s={12} m={6}>
                                        <div className="col s12">
                                            <span
                                                className="property-label  col s4">  {PROPERTY_RULES.VIEW_ALERT_NAME} : </span>
                                            <span className="col s8 m-0 url-wrap">
                                                {this.state.alert_name}
                                            </span>
                                        </div>
                                    </TatvamCol>
                                    <TatvamCol s={12} m={6}>
                                        <div className="col s12 m-0">
                                            <span
                                                className="property-label col s5"> {PROPERTY_RULES.VIEW_SEQ_NO} : </span>
                                            <span className="col s7">
                                                {this.state.seq_number}
                                            </span>
                                        </div>
                                    </TatvamCol>

                                    {
                                        this.state.alert_type.label === "RATING_TYPE" &&
                                        <TatvamCol s={12}>
                                            <div className="col s12 m6 p-0">
                                                <span
                                                    className="property-label col s4 pl-0"> {API_SOURCE.RATING_TYPE} : </span>
                                                <span className="col s8">{this.state.selectedRatingType.label}</span>
                                            </div>
                                        </TatvamCol>
                                    }


                                    <TatvamCol s={12}>
                                        <div className="col s12 p-0">
                                            <span
                                                className="property-label col s2">{PROPERTY_RULES.FIELD_PATTERN} : </span>
                                            <TatvamTextArea
                                                className="mt-0 col"
                                                disabled
                                                value={this.state.fld_pattern}
                                                s={10}
                                            />
                                        </div>
                                        {this.state.lastExecution && (
                                            <div className="col s12 p-0">
                                                <span
                                                    className="property-label col s2"> {PROPERTY_RULES.LAST_ALERTED} :</span>
                                                <span className="col s10">
                                                    {this.state.lastExecution}
                                                </span>
                                            </div>
                                        )}
                                        <div className="col s12 p-0">
                                            <span
                                                className="property-label col s2"> {PROPERTY_RULES.TEXT_PATTERN} : </span>
                                            <TatvamTextArea
                                                className="mt-0 col"
                                                disabled
                                                value={this.state.text_pattern}
                                                s={10}
                                            />
                                        </div>
                                        {(this.state.alert_type.value === "MAIL_ALERT") &&
                                            <div className="col s12 p-0">
                                                <span
                                                    className="property-label col s2">{PROPERTY_RULES.VIEW_RECIPIENTS} : </span>
                                                <TatvamTextArea
                                                    className="mt-0 col"
                                                    disabled
                                                    value={this.state.mail_recipients}
                                                    s={10}
                                                />
                                            </div>
                                        }
                                        <div className="col s12 p-0">
                                            <span
                                                className="property-label col s2 p-0"> {PROPERTY_RULES.VIEW_ALERT_DESCRIPTION} : </span>
                                            <TatvamTextArea
                                                className="mt-0 col"
                                                disabled
                                                value={this.state.alert_description}
                                                s={10}
                                            />
                                        </div>
                                        {this.state.timesAlerted && (
                                            <div className="col s12 p-0">
                                                <span
                                                    className="property-label col s2">{PROPERTY_RULES.TIMES_ALERTED}:</span>
                                                <span className="col s10">{this.state.timesAlerted}</span>
                                            </div>
                                        )}
                                    </TatvamCol>
                                </Row>
                            </Fragment>
                        )}

                    <TatvamCol s={12} className="p-0">
                        <TatvamCol s={12} m={8}>
                            {!this.state.isPatternValid && this.state.alert_type !== "MAIL_ALERT" && (
                                <p className="errorText">{
                                    VALIDATION_MESSAGES.ENTER_FIELD_OR_TEXT_PATTERN
                                }
                                </p>
                            )}

                            {!this.state.isPatternValid && this.state.alert_type === "MAIL_ALERT" && (
                                <p className="errorText">{
                                    VALIDATION_MESSAGES.ENTER_TEXT_PATTERN
                                }
                                </p>
                            )}
                            {!this.state.isRecipientMailValid && (
                                <p className="errorText">{
                                    VALIDATION_MESSAGES.INVALID_EMAIL
                                }
                                </p>
                            )}
                        </TatvamCol>
                        <TatvamCol s={12} m={4}>
                            {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="CreateProjectPublish btn_primary "
                                    onClick={this._triggerSubmit}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) : this.props.rulePermission === APP_BOOLEANS.TRUE && (
                                <TatvamButton waves="light" className=" CreateProjectPublish btn_primary " name="EDIT"
                                    onClick={this._handleAppMode}>
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            )}
                            <TatvamButton waves="light" className="mr-4 CreateProjectPublish btn_primary"
                                onClick={this._triggerDiscard}>
                                {!(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                    FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                            </TatvamButton>
                        </TatvamCol>
                    </TatvamCol>
                    {this.state.isAlertBoxOpen && (
                        <AlertBox
                            open={this.state.isAlertBoxOpen}
                            isSuccess={this.state.isSuccess}
                            closeAlertBox={this._discard}
                            isConfirmBox={this.state.isConfirmBox}
                            alert_information={this.state.alert_information}
                        />
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default CustomerRuleForm;
