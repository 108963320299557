import {APPLICATION_MODES} from "localConstants";
import React, {Component} from "react";
import {Row} from "react-materialize";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import CustomerRoleTabs from "./customerRoleTab";

class UserTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <Row className="m-0">
                {this.props.applicationMode === APPLICATION_MODES.CREATE ? null : (
                    <Tabs>
                        <TabList className="tabs customTabs z-depth-2 tabs-fixed-width">
                            {/*<Tab className="tab">Property </Tab>*/}
                            <Tab className="tab">Assigned Properties</Tab>
                        </TabList>
                        {/*<TabPanel>*/}
                        {/*  <CustomerTabs*/}
                        {/*    currentUserName={this.props.currentUserName}*/}
                        {/*    applicationMode={this.props.applicationMode}*/}
                        {/*    history={this.props.history}*/}
                        {/*  />*/}
                        {/*</TabPanel>*/}
                        <TabPanel>

                            <CustomerRoleTabs
                                currentUserName={this.props.currentUserName}
                                applicationMode={this.props.applicationMode}
                                currentUserData={this.props.currentUserData}
                            />
                        </TabPanel>
                    </Tabs>
                )}
            </Row>
        );
    }
}

export default UserTabs;
