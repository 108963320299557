import React from 'react';
import {Modal} from "react-materialize";

const BaseModal = (props) => {
    return (
        <Modal {...props}/>
    )
}

export default BaseModal;
