import React, {Component, Fragment} from "react";
import {Row} from "react-materialize";
import SourceIndustry from "../../components/applicationComponents/sourceIndustry";

class IndustryMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Row>
                    <Fragment>
                        <Row
                            className={this.state.isLoaded ? "card-container" : "card-container loader-wait"}
                        >
                            <SourceIndustry history={this.props.history}/>
                        </Row>
                    </Fragment>
                </Row>
            </div>
        );
    }
}

export default IndustryMaster;
