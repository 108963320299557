import React from 'react';
import {TextInput} from "react-materialize";

const BaseTextInput = (props) => {
    return (
        <TextInput {...props}/>
    )
}

export default BaseTextInput;
