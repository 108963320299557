import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchCustomerIndustry, saveCustomer} from "../../../actions";
import TenantsForm from "./tenantsForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    tenantPermission: state.auth.permissions && state.auth.permissions.customer.tenants.create
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            saveCustomer,
            fetchCustomerIndustry
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantsForm);
