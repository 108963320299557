import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {UserSignOut} from "../../../../actions";
import SideMenu from "./sideMenu";

const mapStateToProps = (state) => ({
    menuList: state.auth.permissions && state.auth.permissions.menus
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            UserSignOut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
