import './customerUserForm.scss';

import React, { Component, Fragment, } from 'react';

import Loader from 'react-loaders';
import { Row, Switch, } from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APP_STATUS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    PROPERTY_USERS,
    ROLES,
    SWITCH_TEXT,
    VALIDATION_MESSAGES,
} from '../../../constants/appConstants';
import { dynamicSort } from '../../../utils/objectUtil';
import { trimCapitalize } from '../../../utils/stringUtil';
import { TatvamButton, TatvamCol, TatvamMultiSelect, } from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationMode: props.applicationMode,
            preLoader: false,
            rolesListOptions: [],
            CustomerUserData: {},
            selectedRole: {},
            selectedUser: {},
            isFormValid: true,
            shouldCloseModal: false,
            userNameList: [],
            selectedDefaultReport: {},
            reportList: [],
            existsUserRoles: [],
            generateApiKeyData: {},
            editKeySecret: {},
            viewKeySecret: {},
        };
    }

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.props.closeModal();
                this.setState({
                    applicationMode: "",
                    rolesListOptions: [],
                    CustomerUserData: {},
                    isActive: false,
                    reportList: [],
                    generateApiKeyData: {},
                    editKeySecret: {},
                    viewKeySecret: {},
                });
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _handleAppMode = () => {
        this.defalutReportSelection(this.state.selectedUser, this.state.selectedRole)
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
        this._triggerGenerateKey();
    };

    _handleInput = e => {
        if (e.target.id === "userStatus") {
            const status = e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            this.setState({
                isActive: e.target.checked,
                status,
                editFlag: {
                    status: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    status: true,
                    [e.target.name]: true
                }
            });
        }
    };

    _handleSave = e => {
        e.preventDefault();

        if (!this.state.selectedUser.value || !this.state.selectedRole.value || !this.state.selectedDefaultReport.value) {
            this.setState({ isFormValid: false });
        } else {
            let userTabData;
            userTabData = {
                ...this.state.CustomerUserData
            };
            userTabData.customer_id = this.props.customerId;
            userTabData.user_name = this.state.selectedUser.value;
            userTabData.roles = [this.state.selectedRole.value];
            userTabData.status = this.state.status;
            userTabData.landing_page_id = this.state.selectedDefaultReport.value ? this.state.selectedDefaultReport.value : "";
            userTabData.landing_page_name = this.state.selectedDefaultReport.label ? this.state.selectedDefaultReport.label : "";

            if (this.state.selectedRole.value === ROLES.CUSTOMER_API) {
                userTabData.key_id = this.state.editKeySecret.secretID;
                userTabData.key_secret = this.state.editKeySecret.secretKey;
            }

            if (this.props.rolesList) {
                var selectedRole = this.props.rolesList.filter(item => item.name === this.state.selectedRole.value);
                if (selectedRole.length > 0) {
                    userTabData.application_name = selectedRole[0].application_name;
                }
            }

            if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                userTabData.updated_by = this.props.userName;

                if (this.props.userData) {
                    userTabData._id = this.props.userData._id;
                }

                this.setState({ preLoader: true });
                this.props.actions.editCustomerUsers(userTabData).then(response => {
                    this.setState({ preLoader: false });
                    this.props.actions.fetchCustomerUser(this.props.customerInformation.id).then(() => {
                    });

                    if (this.props.gridApi) {
                        this.props.gridApi.deselectAll();
                    }

                    if (response && response.status === 200) {
                        this.setState({ shouldCloseModal: true });

                        if (response.data && response.data.message) {
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.data.message
                            });
                        } else {
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: false,
                                alert_information: response.message
                            });
                        }
                    } else {
                        this.setState({ shouldCloseModal: false });
                        this.setState({
                            isAlertBoxOpen: true,
                            isSuccess: false,
                            alert_information: response.data.message
                        });
                    }
                });
            } else if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                userTabData.created_by = this.props.userName;
                this.setState({ preLoader: true });

                this.props.actions.createCustomerUsers(userTabData).then(response => {
                    this.setState({ preLoader: false });
                    this.props.actions.fetchCustomerUser(this.props.customerInformation.id).then(() => {
                    });

                    if (this.props.gridApi) {
                        this.props.gridApi.deselectAll();
                    }

                    if (response && response.status === 200) {
                        this.setState({ shouldCloseModal: true });
                        if (response.data && response.data.message) {
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.data.message
                            });
                        } else {
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: false,
                                alert_information: response.message
                            });
                        }
                    } else {
                        this.setState({ shouldCloseModal: false });
                        this.setState({
                            isAlertBoxOpen: true,
                            isSuccess: false,
                            alert_information: response.data && response.data.message ? response.data.message : response.message
                        });
                    }
                });
            }
        }
    };

    _triggerGenerateKey = role => {
        if (this.state.selectedUser.value && (this.state.selectedRole.value === ROLES.CUSTOMER_API || role.value === ROLES.CUSTOMER_API)) {
            let generateUserData;
            generateUserData = {
                ...this.state.generateApiKeyData
            }
            generateUserData.customer_id = this.props.customerId;
            generateUserData.user_name = this.state.selectedUser.value;
            this.setState({ preLoader: true });
            this.props.actions.generateApiKey(generateUserData).then(response => {
                this.setState({ preLoader: false });
                if (response && response.status === 200) {
                    if (response.data) {
                        this.setState({
                            editKeySecret: {
                                secretID: response.data.data.key_id,
                                secretKey: response.data.data.key_secret,
                            }
                        })
                    }
                } else {
                    this.setState({
                        isAlertBoxOpen: true,
                        alert_information: response.data && response.data.message ? response.data.message : response.message
                    });
                }
            });
        }
    };

    _triggerSubmit = () => {
        this.refs.submit.click();
    };

    _handleRolesChange = optionsList => {
        this.defalutReportSelection(this.state.selectedUser, optionsList);

        if (this.state.selectedUser.value) {
            this.setState({ isFormValid: true });
        }

        if (optionsList.label === ROLES.CUSTOMER_API || optionsList.value === ROLES.CUSTOMER_API) {
            this._triggerGenerateKey(optionsList);
        }
    }

    getUniqueReports = dropdownList => {
        let uniqueReports = dropdownList.reduce((unique, o) => {
            if (!unique.some(obj => obj.label === o.label && obj.id === o.id)) {
                unique.push(o);
            }
            return unique;
        }, []);

        return uniqueReports.sort(dynamicSort("label"));
    }

    _handleReportChange = optionsList => {
        this.setState({
            selectedDefaultReport: optionsList,
            editFlag: {
                selectedDefaultReport: true
            }
        });
    }

    defalutReportSelection = (selectedUser, selectedRole) => {
        let dropdownList = [];
        let assignedUsers = [];
        let userExistsRoles = [];
        let userAllSelectedRoles = [];
        let defaultReport = {};
        let uniqueReports = [];

        if (this.props.selectedUserList) {
            assignedUsers = this.props.selectedUserList.filter((element) => {
                return element.user_name === selectedUser.label && element.application_name === selectedRole.application_name
            })

            assignedUsers.map((ele) => {
                userExistsRoles.push(ele.roles[0]);
            })
        }

        if (this.state.applicationMode === "CREATE") {
            if (userExistsRoles.includes(selectedRole.label)) {
                userAllSelectedRoles = userExistsRoles;
            } else {
                userExistsRoles.push(selectedRole.label);
                userAllSelectedRoles = userExistsRoles;
            }

            if (userAllSelectedRoles.length > 0) {
                this.props.rolesList.map((role) => {
                    if (userAllSelectedRoles.includes(role.name)) {
                        role.enabled_reports.map((ele) => {
                            dropdownList.push({ label: ele.name, value: ele.id });
                        })
                    }
                })
            }

            uniqueReports = this.getUniqueReports(dropdownList);

            if(this.props.default_landing_page.length > 0 || this.props.predicto_default_landing_page.length > 0 || uniqueReports.length > 0){
                defaultReport = uniqueReports.find((report)=>report.label === this.props.default_landing_page || report.label === this.props.predicto_default_landing_page );
            };

            if (assignedUsers.length > 0 && assignedUsers[0].landing_page_id) {
                const selectedReport = uniqueReports.find((ele) => ele.value === assignedUsers[0].landing_page_id);
                defaultReport = selectedReport ? selectedReport : "";
            }
        }

        if (this.state.applicationMode === 'EDIT' || this.state.applicationMode === 'VIEW') {
            const roleIndex = userExistsRoles.indexOf(this.props.userData.roles[0]);
            if (roleIndex >= 0) {
                userExistsRoles[roleIndex] = selectedRole.label;
            } else {
                userExistsRoles.push(selectedRole.label);
            }
            userAllSelectedRoles = userExistsRoles;

            if (userAllSelectedRoles.length > 0) {
                this.props.rolesList.map((role) => {
                    if (userAllSelectedRoles.includes(role.name)) {
                        role.enabled_reports.map((ele) => {
                            dropdownList.push({ label: ele.name, value: ele.id })
                        })
                    }
                })
            }

            uniqueReports = this.getUniqueReports(dropdownList);

            const isDefaultReportExist = uniqueReports.find(oneReport => assignedUsers[0].landing_page_id === oneReport.value);
            defaultReport = isDefaultReportExist ? isDefaultReportExist : this.props.default_landing_page;
        }

        this.setState({
            selectedDefaultReport: defaultReport ? defaultReport : "",
            reportList: uniqueReports,
            selectedRole: selectedRole,
            editFlag: {
                selectedRole: true
            }
        });
    }

    _handleUserChange = optionsList => {
        this.setState({
            selectedUser: optionsList,
            selectedRole: {},
            selectedDefaultReport: {},
            reportList: [],
            editFlag: {
                selectedUser: true
            }
        });

        if (this.state.selectedRole.value) {
            this.setState({ isFormValid: true });
        }
    };

    closeAlertBox = () => {
        this.setState({
            isAlertBoxOpen: false
        });
    };

    defaultReportName = () => {
        const reportName = this.props.allReports.find(oneReport => oneReport.id === this.props.userData.landing_page_id);
        return reportName ? reportName.name : null;
    }

    componentDidMount() {
        let rolesListOptions = [];
        let reportList = [];
        let userList = [];
        let userListOptions = [];
        let selectedRole = {};

        this.props.actions.fetchAllUserName().then((response) => {
            if (response && response.data && Object.values(response.data.data).length > 0) {
                this.setState({
                    userNameList: response.data.data
                });
                for (let data of Object.values(response.data.data)) {
                    if (data.status === APPLICATION_MODES.ACTIVE) {
                        userList = [...userList, { label: data.user_name, value: data.user_name }];
                    }
                }
                userListOptions = userList;
                userListOptions.sort(dynamicSort("label"));
            }

            this.setState({
                userListOptions
            });
            if (this.props.userData || this.props.userData.length > 0) {
                const selectedUser = {
                    label: this.props.userData.user_name,
                    value: this.props.userData.user_name
                };

                this.setState({
                    selectedUser
                });

                if (this.props.userData.roles.includes(ROLES.CUSTOMER_API)) {
                    this.setState({
                        viewKeySecret: {
                            secretID: this.props.userData.key_id,
                            secretKey: this.props.userData.key_secret,
                        }
                    })
                }
            }
        });

        this.props.actions.fetchAllReports();

        if (this.props.rolesList) {
            this.props.rolesList.map((data) => {
                if (data.status && data.status !== APP_STATUS.INACTIVE) {
                    if ((data.application_name === APPLICATION_MODES.TATVAM_INAPP && this.props.customerInformation.is_inapp) ||
                        (data.application_name === APPLICATION_MODES.PREDICTO && this.props.customerInformation.is_predicto) ||
                        (data.application_name === APPLICATION_MODES.BLMS && this.props.customerInformation.is_blms)) {
                        rolesListOptions = [...rolesListOptions, {
                            label: data.name,
                            value: data.name,
                            application_name: data.application_name
                        }];
                    }
                }
            });
        }

        this.setState({
            applicationMode: this.props.applicationMode,
            rolesListOptions,
            reportList,
            status: APPLICATION_MODES.INACTIVE,
            isActive: false,
        });

        if (this.props.userData.roles) {
            this.props.userData.roles.map((data) => {
                if (data.status !== APP_STATUS.INACTIVE)
                    selectedRole = { label: data, value: data, application_name: this.props.userData.application_name };
            });
        }

        this.setState({
            ...this.props.userData,
            selectedRole,
            isActive: this.props.userData.status ? trimCapitalize(this.props.userData.status) === APPLICATION_MODES.ACTIVE : false
        });
    }

    componentWillReceiveProps(props) {
        let rolesListOptions = [];
        let userListOptions = [];
        let userList = [];
        let reportList = [];

        if (this.state.userNameList && Object.values(this.state.userNameList).length > 0) {
            for (let data of Object.values(this.state.userNameList)) {
                if (data.status === APPLICATION_MODES.ACTIVE) {
                    userList = [...userList, { label: data.user_name, value: data.user_name }];
                }
            }
            userListOptions = userList;
            userListOptions.sort(dynamicSort("label"));
        }

        if (props.rolesList) {
            props.rolesList.map((data) => {
                if (data.status && data.status !== APP_STATUS.INACTIVE) {
                    if ((data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.customerInformation.is_inapp) ||
                        (data.application_name === APPLICATION_MODES.PREDICTO && props.customerInformation.is_predicto) ||
                        (data.application_name === APPLICATION_MODES.BLMS && props.customerInformation.is_blms)) {
                        rolesListOptions = [...rolesListOptions, {
                            label: data.name,
                            value: data.name,
                            application_name: data.application_name
                        }];
                    }
                }
            });
        }

        this.setState({
            applicationMode: props.applicationMode,
            rolesListOptions,
            userListOptions,
            reportList,
            status: APPLICATION_MODES.INACTIVE,
            isActive: false
        });

        if (props.userData && props.userData.roles) {
            let selectedRole = {};
            let selectedDefaultReport = {};
            props.userData.roles.map((data) => {
                selectedRole = { label: data, value: data, application_name: props.userData.application_name };
            });

            if (props.roleList) {
                props.roleList.map((data) => {
                    if (data.status !== APP_STATUS.INACTIVE)
                        rolesListOptions = [...rolesListOptions, {
                            label: data.name,
                            value: data.name,
                            application_name: data.application_name
                        }];
                });
            }
            const selectedUser = {
                label: props.userData.user_name,
                value: props.userData.user_name
            };

            this.setState({
                ...props.userData,
                selectedRole,
                selectedUser,
                selectedDefaultReport,
                isActive: props.userData.status ? trimCapitalize(props.userData.status) === APPLICATION_MODES.ACTIVE : true
            });
        }
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat" />}
                    </TatvamCol>
                </Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <form onSubmit={(e) => this._handleSave(e)} className="mb-2 h-15v">
                        {this.state.applicationMode !== APPLICATION_MODES.CREATE ? (
                            <h4>{PROPERTY_USERS.EDIT_USER}</h4>) : ""}
                        <Row>
                            <TatvamCol s={12} className="valign-wrapper p-0">
                                <TatvamCol m={6} className="mt-.75 pb-3 d-flex flex-column">
                                    <label htmlFor="users" className="col s12 ">
                                        {PROPERTY_USERS.USER}<span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        className={(!this.state.isFormValid && !this.state.selectedUser.value) ? "validate invalid-field" : "validate"}
                                        classNamePrefix="react-select"
                                        value={this.state.selectedUser}
                                        name="users"
                                        onChange={this._handleUserChange}
                                        options={this.state.userListOptions}
                                        isDisabled={this.state.applicationMode !== APPLICATION_MODES.CREATE}
                                        placeholder={PROPERTY_USERS.SELECT_USER}
                                    />
                                </TatvamCol>
                                <TatvamCol m={6} className="mt-.75 pb-3 d-flex flex-column">
                                    <label htmlFor="role" className="col s12 ">
                                        {PROPERTY_USERS.ROLE}<span style={{ color: 'red' }}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        className={(!this.state.isFormValid && !this.state.selectedRole.value) ? "validate invalid-field" : "validate"}
                                        classNamePrefix="react-select"
                                        value={this.state.selectedRole}
                                        name="role"
                                        onChange={this._handleRolesChange}
                                        options={this.state.rolesListOptions}
                                        placeholder={PROPERTY_USERS.SELECT_ROLE}
                                    />
                                </TatvamCol>
                                <div className="col s4 valign-wrapper">
                                    <label htmlFor="status" className="status-label">
                                        {PROPERTY_USERS.STATUS}
                                    </label>
                                    <Switch id="userStatus" checked={this.state.isActive} onClick={this._handleInput}
                                        m={3} />
                                </div>
                            </TatvamCol>
                            <TatvamCol s={12} className="valign-wrapper p-0">
                                <TatvamCol m={6} className="mt-.75 pb-3 ml-0 d-flex flex-column">
                                    <label htmlFor="defaultReport" className="col s12 ">
                                        {PROPERTY_USERS.DEFAULT_REPORT}
                                        <span
                                            style={{ color: 'red' }}> *</span>
                                    </label>

                                    <TatvamMultiSelect
                                        className={(!this.state.isFormValid && !this.state.selectedDefaultReport.value) ? "validate invalid-field" : "validate"}
                                        classNamePrefix="react-select"
                                        value={this.state.selectedDefaultReport}
                                        name="defaultReport"
                                        onChange={this._handleReportChange}
                                        options={this.state.reportList}
                                        placeholder={PROPERTY_USERS.SELECT_REPORT}
                                    />
                                </TatvamCol>
                            </TatvamCol>
                            <TatvamCol s={12} className="valign-wrapper p-0">
                                {
                                    (this.state.editKeySecret.secretKey && this.state.editKeySecret.secretID && this.state.selectedRole.value === ROLES.CUSTOMER_API) &&
                                    <TatvamCol s={12} m={12} className="mt-.75 h-15v pt-2">
                                        <div className="col s12">
                                            <span className="property-label col">{PROPERTY_USERS.KEY_ID}:</span>
                                            <span
                                                className="col s9 url-wrap">{this.state.editKeySecret.secretID} </span>
                                        </div>
                                        <div className="col s12">
                                            <span className="property-label col">{PROPERTY_USERS.KEY_SECRET}:</span>
                                            <span
                                                className="col s8 url-wrap"> {this.state.editKeySecret.secretKey} </span>
                                        </div>
                                    </TatvamCol>
                                }
                            </TatvamCol>
                        </Row>
                        <button type="submit" ref="submit" className="hide">
                            Submit
                        </button>
                    </form>
                ) : (
                    <Row>
                        <h4>{PROPERTY_USERS.VIEW_TITLE_USER}</h4>
                        <TatvamCol s={12} m={12} className="mt-.75 h-15v pt-2">
                            <div className="col s12 m4">
                                <span className="property-label col s5 pl-0">  {PROPERTY_USERS.VIEW_USER} :</span>
                                <span className="col s7 url-wrap"> {this.state.selectedUser.label} </span>
                            </div>
                            <div className="col s12 m4">
                                <span className="property-label col s5">  {PROPERTY_USERS.VIEW_ROLE} :</span>
                                <span className="col s7">{this.state.selectedRole.label}</span>
                            </div>
                            <div className="col s12 m4">
                                <span className="property-label col s5">  {PROPERTY_USERS.STATUS} :</span>
                                <span
                                    className="col s7">{this.state.isActive ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                        <TatvamCol s={12} m={12} className="mt-.75 h-15v pt-2">
                            <div className="col s12 m5">
                                <span className="property-label col">  {PROPERTY_USERS.DEFAULT_REPORT} :</span>
                                <span className="col s7 url-wrap"> {this.defaultReportName()} </span>
                            </div>
                        </TatvamCol>
                        {
                            (this.state.viewKeySecret.secretID && this.state.viewKeySecret.secretKey) &&
                            <TatvamCol s={12} m={12} className="mt-.75 h-15v">
                                <div className="col s12 m12 pb-2">
                                    <span className="property-label col">{PROPERTY_USERS.KEY_ID} :</span>
                                    <span className="col s9 url-wrap"> {this.state.viewKeySecret.secretID} </span>
                                </div>
                                <div className="col s12 m12 pb-2">
                                    <span className="property-label col">{PROPERTY_USERS.KEY_SECRET} :</span>
                                    <span className="col s8 url-wrap"> {this.state.viewKeySecret.secretKey} </span>
                                </div>
                            </TatvamCol>
                        }
                    </Row>
                )}
                <Row className="modalButons">
                    <TatvamCol s={12} m={8}>
                        <p className="errorText">
                            {!this.state.isFormValid && VALIDATION_MESSAGES.ENTER_MANDATORY_FIELDS}
                        </p>
                    </TatvamCol>
                    <TatvamCol s={12} m={4}>
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                            <TatvamButton
                                waves="light"
                                className="mt-1  CreateProjectPublish btn_primary "
                                onClick={this._triggerSubmit}
                                disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                            >
                                {FORM_OPERATIONS.SAVE}
                            </TatvamButton>
                        ) :
                            this.props.userPermission === APP_BOOLEANS.TRUE && (
                                <TatvamButton waves="light" className="mt-1  CreateProjectPublish btn_primary " name="EDIT"
                                    onClick={this._handleAppMode}>
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            )}
                        <TatvamButton waves="light" className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}>
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                                !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}
                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isConfirmBox={this.state.isConfirmBox}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default CustomerUserForm;
