import {store} from '../../utils/helpers';

class AuthService {
    isAuthenticated() {
        const state = store.getState();
        if (state && state.auth) {
            const isAuthenticated = state.auth.authToken;

            if (isAuthenticated) {
                return true;
            }
            return false;
        }
    }
}

const authService = new AuthService();
export default authService;
