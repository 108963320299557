import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetChildPropertySuccess: (payload) => ({
        type: "GET_CHILD_PROPERTIES_SUCCESS",
        payload
    }),
    GetChildPropertyError: (payload) => ({
        type: "GET_CHILD_PROPERTIES_ERROR",
        payload
    })
};

export const getChildProperties = (id) => (dispatch, getState) => {
    if (id) {
        const url = apiUrl.BASE_URL + apiUrl.GET_CHILD_PROPERTY.format(id);
        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    const childPropertiesList = {};
                    response.data.data.map((data) => {
                        childPropertiesList[data.id] = data;
                    });
                    dispatch(actions.GetChildPropertySuccess(childPropertiesList));
                    return response;
                }
                if (response.status === 204) {
                    dispatch(actions.GetChildPropertySuccess([]));
                    return response;
                }
                dispatch(actions.GetChildPropertyError(response.message));
                return response;

            })
            .catch(error => {
                dispatch(actions.GetChildPropertyError(error.message));
                return Promise.resolve(error);
            });
    }
};
