import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';
import {Col} from 'react-materialize';

import {ALERT_MESSAGES, USER_PROPERTY_ROLES,} from '../../../../../constants/appConstants';
import {TatvamButton, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import UserCustomerForm from '../../userCustomerForm';
import moment from 'moment';

class CustomerRoleTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            customers: [],
            colDef: [
                {headerName: USER_PROPERTY_ROLES.VIEW_ROLE, field: "roles", filterParams: {clearButton: true}},
                {headerName: USER_PROPERTY_ROLES.CUSTOMER_ID, field: "id", filterParams: {clearButton: true}},
                {headerName: USER_PROPERTY_ROLES.NAME, field: "name", filterParams: {clearButton: true}},
                {
                    headerName: USER_PROPERTY_ROLES.INSTANCE_TYPE,
                    field: "instanceType",
                    filterParams: {clearButton: true}
                },
                {headerName: USER_PROPERTY_ROLES.STATUS, field: "status", filterParams: {clearButton: true}},
                {
                    headerName: USER_PROPERTY_ROLES.DEFAULT_REPORT,
                    field: "landing_page_name",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: USER_PROPERTY_ROLES.LAST_LOGIN, 
                    field: "last_login", 
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        return !data.value? "-" : moment(data.value).format('DD-MMM-yyyy hh:mm a')
                    }
                },
                {
                    headerName: USER_PROPERTY_ROLES.DEFAULT_PROPERTY,
                    field: "default_property",
                    filterParams: {clearButton: true}
                }]
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.id,
            idUserName: data.user_name,
            idUnique: data.role_id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let user_name = this.state.idUserName;
            let _id = this.state.idUnique;
            let userCustomerDeleteData = {
                customer_id,
                user_name,
                _id
            };

            this.props.actions.deleteUserCustomer(userCustomerDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {
                    this.props.actions.fetchUserCustomer(this.props.currentUserName).then(() => {
                        this.setState({
                            shouldCloseModal: true,
                            isAlertBoxOpen: true,
                            isSuccess: true,
                            alert_information: response.data.message
                        });
                    });

                } else {

                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });

        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _openViewModal = data => {
        this.setState(
            {
                customerId: data[0].id,
                customerRoleData: data[0],
                applicationMode: APPLICATION_MODES.VIEW,
                modalTitle: "",
                isModalOpen: true
            }
        );
    };
    _openCreateModal = () => {
        this.setState({
            customerId: null,
            modalTitle: USER_PROPERTY_ROLES.ASSIGN_ROLE,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        const tempCustomers = [];
        var userData = {};
        if (this.props.currentUserData) {
            userData = this.props.currentUserData;
        }
        this.props.actions.fetchUserCustomer(this.props.currentUserName, userData).then(() => {
            if (this.props.customers) {
                this.props.customers.map((data) => {
                    data.instanceType = (data.corporate !== undefined && data.corporate === APPLICATION_MODES.YES) ? "Group" : "Individual";
                    if (data.default_property === undefined) {
                        data.default_property = APPLICATION_MODES.NO;
                    }
                    tempCustomers.push(data);
                });
            }
            this.setState({customers: tempCustomers});
        });
    }

    componentWillReceiveProps(props) {
        if (this.props.currentUserName !== props.currentUserName) {
            const tempCustomers = [];
            var userData = {};
            if (this.props.currentUserData) {
                userData = this.props.currentUserData;
            }
            this.props.actions.fetchUserCustomer(props.currentUserName, userData).then(() => {
                if (props.customers) {
                    props.customers.map((data) => {
                        data.instanceType = (data.corporate !== undefined && data.corporate === APPLICATION_MODES.YES) ? "Group" : "Individual";
                        if (data.default_property === undefined) {
                            data.default_property = APPLICATION_MODES.NO;
                        }
                        tempCustomers.push(data);
                    });
                }
                this.setState({customers: tempCustomers});
            });
        } else {
            const tempCustomers = [];
            if (props.customers) {
                props.customers.map((data) => {
                    data.instanceType = (data.corporate !== undefined && data.corporate === APPLICATION_MODES.YES) ? "Group" : "Individual";
                    if (data.default_property === undefined) {
                        data.default_property = APPLICATION_MODES.NO;
                    }
                    tempCustomers.push(data);
                });
                this.setState({customers: tempCustomers});
            }
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.rolePermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton
                        className="mt-2 ml-2 mr-2 right btn_primary"
                        name="addCustomer"
                        onClick={this._openCreateModal}
                    >
                        {USER_PROPERTY_ROLES.ADD_PROPERTY_ROLE}
                    </TatvamButton>
                )}

                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <UserCustomerForm
                                title={this.state.modalTitle}
                                currentUserName={this.props.currentUserName}
                                assignedCustomers={this.state.customers}
                                customerRoleData={this.state.customerRoleData}
                                customerId={this.state.customerId}
                                applicationMode={this.state.applicationMode}
                                closeModal={this.closeModal}
                            />
                        )}
                    />
                )}

                <Col s={12} className="roleGrid">
                    {this.state.customers && this.state.customers.length > 0 ?
                        (
                            <TatvamGrid
                                gridData={this.state.customers}
                                colData={this.state.colDef}
                                enablePagination
                                enableRowClick
                                onRowSelect={this._openViewModal}
                                enableDeleteColumn={this.props.roleDelete === APP_BOOLEANS.TRUE}
                                onRowDelete={this._handleDeleteConfirmation}
                            />
                        ) : (
                            <div className="col s12 grid-empty-area">
                                <h5>{USER_PROPERTY_ROLES.NO_CUSTOMERS_FOUND}</h5>
                            </div>
                        )}

                </Col>
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default CustomerRoleTabs;

