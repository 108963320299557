import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    CreateIndustryDimensionValueSuccess: (payload) => ({
        type: "CREATE_INDUSTRY_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    CreateIndustryDimensionValueError: (payload) => ({
        type: "CREATE_INDUSTRY_DIMENSION_VALUE_ERROR",
        payload
    }),
    UpdateIndustryDimensionValueSuccess: (payload) => ({
        type: "UPDATE_INDUSTRY_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    UpdateIndustryDimensionValueError: (payload) => ({
        type: "UPDATE_INDUSTRY_DIMENSION_VALUE_ERROR",
        payload
    }),
    GetIndustryDimensionValueSuccess: (payload) => ({
        type: "GET_INDUSTRY_DIMENSION_VALUE_SUCCESS",
        payload
    }),
    GetIndustryDimensionValueError: (payload) => ({
        type: "GET_INDUSTRY_DIMENSION_VALUE_ERROR",
        payload
    }),
    DeleteSourceIndustryDimensionSuccess: (payload) => ({
        type: "DELETE_SOURCE_INDUSTRY_DIMENSION_SUCCESS",
        payload
    }),
    DeleteSourceIndustryDimensionError: (payload) => ({
        type: "DELETE_SOURCE_INDUSTRY_DIMENSION_ERROR",
        payload
    })
};


export const createIndustryDimensionValue = (industryDimensionValueData) => (dispatch) => {

    let payload;
    payload = industryDimensionValueData


    const url = apiUrl.BASE_URL + apiUrl.CREATE_INDUSTRY_DIMENSION_VALUE;

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {

                dispatch(actions.CreateIndustryDimensionValueSuccess(response.data.data[0]));
                return response;
            }

            dispatch(actions.CreateIndustryDimensionValueError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.CreateIndustryDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });

};

export const updateIndustryDimensionValue = (industryDimensionValueData, id) => (dispatch) => {

    let payload;
    payload = industryDimensionValueData
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_INDUSTRY_DIMENSION_VALUE.format(id);

    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.UpdateIndustryDimensionValueSuccess(response.data.data[0]));
                return response;
            }

            dispatch(actions.UpdateIndustryDimensionValueError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.UpdateIndustryDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });


};

export const getIndustryDimensionValues = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_INDUSTRY_DIMENSION_VALUES;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const industryDimensionList = {};
                response.data.data.map((data) => {
                    industryDimensionList[data._id] = data;
                });

                dispatch(actions.GetIndustryDimensionValueSuccess(industryDimensionList));
                return response;
            }


            dispatch(actions.GetIndustryDimensionValueError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetIndustryDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteIndustryDimension = (industryDimension) => (dispatch, getState) => {
    const state = getState();
    let industryData = state.industryDimensionValue.list;

    const url = apiUrl.BASE_URL + apiUrl.DELETE_INDUSTRY_DIMENSION_VALUE.format(industryDimension.industry_dimension_id);

    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {

                let datalist = [];
                [industryData].map((data) => {
                    if (data._id !== industryDimension.industry_dimension_id) {
                        datalist.push(data);
                    }
                    return true;
                });
                dispatch(actions.DeleteSourceIndustryDimensionSuccess(datalist));
                return response;
            }

            dispatch(actions.DeleteSourceIndustryDimensionError(response.message));
            return response.data;
        })
        .catch(error => {
            dispatch(actions.DeleteSourceIndustryDimensionError(error.message));
            return Promise.resolve(error.response);
        });
};


export const getDimensionValuesByIndustryName = (industry_name) => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_DIMENSION_VALUES_BY_INDUSTRY.format(industry_name);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const industryDimensionList = {};
                response.data.data.map((data) => {
                    industryDimensionList[data._id] = data;
                });

                dispatch(actions.GetIndustryDimensionValueSuccess(industryDimensionList));
                return response;
            }


            dispatch(actions.GetIndustryDimensionValueError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetIndustryDimensionValueError(error.message));
            return Promise.resolve(error.response);
        });
};
