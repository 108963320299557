import React, {Component, Fragment,} from 'react';

/* eslint-disable no-console */
import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';

import {ALERT_MESSAGES, WEB_SOURCE,} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import WebSourceForm from '../../../webSourceForm';
import moment from 'moment';

class WebSources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            sourceGridData: [],
            rulesGridData: [],
            rolesGridData: [],
            usersGridData: [],
            loader: true,
            colDef: [
                {headerName: WEB_SOURCE.NAME, field: "unique_name", filterParams: {clearButton: true}},
                {headerName: WEB_SOURCE.COMMENT_TYPE, field: "comment_type", filterParams: {clearButton: true}},
                {headerName: WEB_SOURCE.VIEW_IS_PUBLIC, field: "is_public", filterParams: {clearButton: true}},
                {
                    headerName: WEB_SOURCE.VIEW_EXECUTION_DATE,
                    field: "execution_date",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: WEB_SOURCE.MESSAGE_DATE,
                    field: "message_date",
                    filterParams: {clearButton: true},
                    cellRenderer: (data) => {
                        return !data.value? " " : moment(data.value).format('DD-MMM-YYYY')
                    }
                },
                {headerName: WEB_SOURCE.STATUS, field: "status", filterParams: {clearButton: true}},
                {headerName: WEB_SOURCE.VIEW_IS_LIVE_FEED, field: "is_live_feed", filterParams: {clearButton: true}},
                {
                    headerName: WEB_SOURCE.CREDENTIAL_REQUIRED,
                    field: "is_loginrequired",
                    filterParams: {clearButton: true}
                },
            ]
        };
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.customer_id,
            idSource: data.source_id,
            idUnique: data._id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.idUnique;
            let sourceDeleteData = {
                customer_id,
                id
            };
            this.props.actions.deleteCustomerSource(sourceDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {

                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });

        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };


    _openViewModal = (data) => {
        this.setState({
            sourceData: data[0],
            title: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true,
            loader: true
        });
    };
    _openCreateModal = () => {
        this.setState({
            sourceData: [],
            title: WEB_SOURCE.ADD_SOURCE,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        this.setState({
            loader: true
        });
        let sourceGridData = [];
        let sourceGridDataApi = [];
        this.props.actions.fetchCustomerSources(this.props.currentCustomerId).then(() => {
            this.setState({
                loader: false
            });

            if (this.props.customer.sources) {
                this.props.customer.sources.map((data) => {
                    if (data.source_type === WEB_SOURCE.WEB_SOURCE_TYPE) {
                        if (data.last_execution && data.last_execution.execution_date !== "") {
                            let execution_date = new Date(data.last_execution.execution_date).toDateString().replace('-', '').split(" ");
                            data.execution_date = execution_date[2] + "-" + execution_date[1] + "-" + execution_date[3];
                        }
                        if (data.valid_from && data.valid_upto) {
                            let dateFrom = new Date(data.valid_from).toDateString().replace('-', '').split(" ");
                            let dateUpto = new Date(data.valid_upto).toDateString().replace('-', '').split(" ");
                            data.valid_from = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[3];
                            data.valid_upto = dateUpto[2] + "-" + dateUpto[1] + "-" + dateUpto[3];
                        }
                        data.message_date = data.last_execution.message_date;
                        sourceGridData.push(data);
                    }
                });
            }


            this.setState({
                sourceGridData
            }, () => {
                this.propagateCount(sourceGridData);
            });

        });


    }

    propagateCount = (sourceGridDataApi) => {
        const gridData = sourceGridDataApi;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'webSource': {'active': noOfActive.length, 'total': gridData.length}});
    };

    fetchCustomerCompetitors = () => {
        if (this.props.customer.competitors) {

            this.props.countPropagate({'competitorSource': {'total': this.props.customer.competitors.length}});

        }
    };

    fetchCustomerTenants = () => {
        if (this.props.customer.tenants) {

            this.props.countPropagate({'tenantSource': {'total': this.props.customer.tenants.length}});

        }
    };

    fetchCustomerProperty = () => {
        if (this.props.customerCategory !== "Competitor" && this.props.customerCategory !== "Tenants") {
            if (this.props.customer.children) {
                this.props.countPropagate({'propertySource': {'total': this.props.customer.children.length}});
            }
        } else {
            if (this.props.currentCustomerId && this.props.customerCategory) {
                let customerData = {customerId: this.props.currentCustomerId, type: this.props.customerCategory};
                this.props.actions.fetchCompetitorTenantCustomers(customerData).then(() => {
                    this.props.countPropagate({'propertySource': {'total': this.props.competitorTenantCustomerList ? this.props.competitorTenantCustomerList.competitorTenantCustomers.length : 0}});
                });
            }
        }
    };

    fetchCustomerRules = () => {
        this.props.actions.fetchCustomerAlertRules(this.props.currentCustomerId).then(() => {
            let rulesGridData = [];
            if (this.props.customerRules) {
                this.props.customerRules.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            rulesGridData.push(data);
                        }
                    }
                });
                this.setState({
                    rulesGridData
                }, () => {
                    const gridDataRules = this.state.rulesGridData;
                    const noOfActiveRules = gridDataRules.filter(eachItem => eachItem.status === 'Active');
                    this.props.countPropagate({
                        'ruleSource': {
                            'active': noOfActiveRules.length,
                            'total': gridDataRules.length
                        }
                    });
                });
            }
        });
    };

    fetchCustomerRoles = () => {
        this.props.actions.fetchCustomerRoles(this.props.currentCustomerId).then(() => {
            let rolesGridData = [];
            if (this.props.customerRoles) {

                this.props.customerRoles.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                            rolesGridData.push(data);
                    }
                });
                this.setState({
                    rolesGridData
                }, () => {
                    const gridDataRoles = this.state.rolesGridData;
                    const noOfActiveRoles = gridDataRoles.filter(eachItem => eachItem.status === 'Active');
                    this.props.countPropagate({
                        'roleSource': {
                            'active': noOfActiveRoles.length,
                            'total': gridDataRoles.length
                        }
                    });
                });
            }
        });
    };

    fetchCustomerUsers = () => {
        this.props.actions.fetchCustomerUser(this.props.currentCustomerId).then(() => {
            let usersGridData = [];
            if (this.props.customerUsers) {
                this.props.customerUsers.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                            usersGridData.push(data);
                    }
                });
                this.setState({
                    usersGridData
                }, () => {
                    const gridDataUsers = this.state.usersGridData;
                    const noOfActiveUsers = gridDataUsers.filter(eachItem => eachItem.status === 'Active');
                    this.props.countPropagate({
                        'userSource': {
                            'active': noOfActiveUsers.length,
                            'total': gridDataUsers.length
                        }
                    });
                });
            }

        });

    };

    componentWillReceiveProps(props) {
        let sourceGridData = [];
        if (props.customer && props.customer.sources) {
            props.customer.sources.map((data) => {
                if (data.source_type === WEB_SOURCE.WEB_SOURCE_TYPE) {
                    if (data.last_execution && data.last_execution.execution_date !== "") {
                        let execution_date = new Date(data.last_execution.execution_date).toDateString().replace('-', '').split(" ");
                        data.execution_date = execution_date[2] + "-" + execution_date[1] + "-" + execution_date[3];
                    }
                    if (data.valid_from && data.valid_upto) {
                        let dateFrom = new Date(data.valid_from).toDateString().split(" ");
                        let dateUpto = new Date(data.valid_upto).toDateString().split(" ");
                        data.valid_from = dateFrom[2] + "-" + dateFrom[1] + "-" + dateFrom[3];
                        data.valid_upto = dateUpto[2] + "-" + dateUpto[1] + "-" + dateUpto[3];
                    }
                    data.message_date = data.last_execution.message_date;
                    sourceGridData.push(data);
                }
            });
        } else {
            props.actions.fetchCustomerSources(props.currentCustomerId);
        }
        this.setState({
            sourceGridData
        }, () => {
            this.propagateCount(sourceGridData);
        });


        if (props.customerRoles) {
            let rolesGridData = [];
            props.customerRoles.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                        rolesGridData.push(data);
                }
            });
            this.setState({
                rolesGridData
            }, () => {
                const gridDataRoles = this.state.rolesGridData;
                const noOfActiveRoles = gridDataRoles.filter(eachItem => eachItem.status === 'Active');
                props.countPropagate({'roleSource': {'active': noOfActiveRoles.length, 'total': gridDataRoles.length}});
            });
        }
    }

    fetchCustomerDimensions = () => {
        this.props.actions.getCustomerDimensions(this.props.currentCustomerId).then(() => {
            let dimensionsGridData = [];
            if (this.props.customerDimensions) {

                this.props.customerDimensions.map((data) => {
                    if (data.status && data.customer_id !== WEB_SOURCE.DZERO_CUSTOMER) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            dimensionsGridData.push(data);
                        }

                    }
                });

                this.setState({
                    dimensionsGridData
                }, () => {
                    const gridDataDimensions = this.state.dimensionsGridData;
                    const noOfActiveDimensions = gridDataDimensions.filter(eachItem => eachItem.status === 'Active');
                    this.props.countPropagate({
                        'dimensionSource': {
                            'active': noOfActiveDimensions.length,
                            'total': gridDataDimensions.length
                        }
                    });
                });
            }
        });
    }


    fetchCustomerDimensionValues = () => {
        this.props.actions.getCustomerDimensionValues(this.props.currentCustomerId).then(() => {
            let dimensionValuesGridData = [];
            if (this.props.customerDimensionValues) {

                this.props.customerDimensionValues.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            dimensionValuesGridData.push(data);
                        }

                    }
                });

                this.setState({
                    dimensionValuesGridData
                }, () => {
                    const gridDataDimensionValues = this.state.dimensionValuesGridData;
                    const noOfActiveDimensions = gridDataDimensionValues.filter(eachItem => eachItem.status === 'Active');
                    this.props.countPropagate({
                        'dimensionValueSource': {
                            'active': noOfActiveDimensions.length,
                            'total': gridDataDimensionValues.length
                        }
                    });
                });
            }
        });
    }


    fetchCustomerResponseTemplates = () => {
        this.props.actions.fetchCustomerResponseTemplates(this.props.currentCustomerId).then(() => {
            let responseTemplateGridData = [];
            if (this.props.responseTemplateLst) {
                this.props.responseTemplateLst.map((data) => {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        var item = data;
                        item.type = data.source_type === "webSource" ? "Web Source" : "API Source";
                        responseTemplateGridData.push(data);
                    }
                });
            }

            this.setState({
                responseTemplateGridData
            }, () => {
                const gridData = this.state.responseTemplateGridData;
                const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
                this.props.countPropagate({
                    'responseTemplateSource': {
                        'active': noOfActive.length,
                        'total': gridData.length
                    }
                });
            });
        });
    }

    render() {
        return (
            <Fragment>
                {this.props.sourcePermission === APP_BOOLEANS.TRUE && (
                    <TatvamButton className="btn_primary right mt-1 mr-2" onClick={this._openCreateModal}>
                        {WEB_SOURCE.ADD_SOURCE}
                    </TatvamButton>
                )}

                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.title}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}

                        children={(
                            <WebSourceForm
                                sourceData={this.state.sourceData !== undefined ? this.state.sourceData : []}
                                customerId={this.props.currentCustomerId}
                                customerData={this.props.customer}
                                applicationMode={this.state.applicationMode}
                                closeModal={this.closeModal}

                            />
                        )}
                    />
                )}
                {this.state.sourceGridData.length > 0 ? (
                    <TatvamCol s={12} className="grid-data-area">
                        <TatvamGrid
                            gridData={this.state.sourceGridData}
                            colData={this.state.colDef}
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.sourceDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                        />
                    </TatvamCol>
                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {WEB_SOURCE.NO_WEB_SOURCE_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default WebSources;
