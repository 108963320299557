import {apiUrl} from 'apiConstants';
import axios from 'axios';

const actions = {
    GetUserSuccess: (payload) => ({
        type: "FETCH_USER_SUCCESS",
        payload
    }),
    GetUserError: (payload) => ({
        type: "FETCH_USER_ERROR",
        payload
    }),
    EditUserSuccess: (payload) => ({
        type: "EDIT_USER_SUCCESS",
        payload
    }),
    EditUserError: (payload) => ({
        type: "CREATE_USER_ERROR",
        payload
    }),
    CreateUserSuccess: (payload) => ({
        type: "CREATE_USER_SUCCESS",
        payload
    }),
    CreateUserError: (payload) => ({
        type: "CREATE_USER_ERROR",
        payload
    }),
    GetUserCustomerSuccess: (payload) => ({
        type: "GET_USER_CUSTOMER_SUCCESS",
        payload
    }),
    GetUserCustomerError: (payload) => ({
        type: "GET_USER_CUSTOMER_ERROR",
        payload
    }),
    DeleteUserCustomerSuccess: (payload) => ({
        type: "GET_USER_CUSTOMER_SUCCESS",
        payload
    }),
    DeleteUserCustomerError: (payload) => ({
        type: "GET_USER_CUSTOMER_ERROR",
        payload
    }),
    DeleteUserSuccess: (payload) => ({
        type: "DELETE_USER_SUCCESS",
        payload
    }),
    DeleteUserError: (payload) => ({
        type: "DELETE_USER_ERROR",
        payload
    }),
    SendUserMailSuccess: (payload) => ({
        type: "SEND_USER_MAIL_SUCCESS",
        payload
    }),
    SendUserMailError: (payload) => ({
        type: "END_USER_MAIL_ERROR",
        payload
    }),
};

export const createUser = (userData) => (dispatch) => {
    const payload = userData;

    const url = apiUrl.BASE_URL + apiUrl.CREATE_USER;
    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.CreateUserSuccess(response.data.data[0]));
                return response;
            }
            dispatch(actions.CreateUserError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateUserError(error.message));
            return Promise.resolve(error.response);
        });
};

export const editUser = (userData) => (dispatch) => {
    const payload = userData;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_USER.format(userData.id);
    return axios
        .patch(url, payload)
        .then(response => {
            if (response.status === 200) {
                dispatch(actions.EditUserSuccess(response.data.data[0]));
                return response;
            }

            dispatch(actions.EditUserError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditUserError(error.message));
            return Promise.resolve(error.response);
        });
};

export const fetchAllUsers = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_USERS;
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const userList = {};
                response.data.data.map((data) => {
                    userList[data.user_name] = data;
                });
                dispatch(actions.GetUserSuccess(userList));
                return response;
            }
            dispatch(actions.GetUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetUserError(error.message));
            return Promise.resolve(error.response);
        });
};


export const fetchAllUserName = () => (dispatch) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_USERS_BY_FIELD;
    return axios
        .get(url)
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const fetchUserCustomer = (name, userData) => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.GET_USER_CUSTOMERS.format(name);
    const store = getState();
    let user = store.users.list[name];

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                if ((user === null || user === undefined) && (userData != null && userData !== undefined)) {
                    userData.customers = response.data.data[0].customers;
                    user = userData;
                } else {
                    user = {
                        ...user,
                        customers: response.data.data[0].customers
                    };

                }
                dispatch(actions.GetUserCustomerSuccess(user));
                return response;
            }
            dispatch(actions.GetUserCustomerError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetUserCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};

export const deleteUserCustomer = userCustomer => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.DELETE_USER_CUSTOMER.format(userCustomer.customer_id, userCustomer._id);
    const store = getState();
    let user = store.users.list[userCustomer.user_name];
    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let userData = JSON.parse(JSON.stringify(user));
                if (userData.customers) {
                    const userCustomerAfterDelete = userData.customers.filter((customer) => {
                        return customer.role_id !== userCustomer._id;
                    });
                    userData["customers"] = userCustomerAfterDelete;
                } else {
                    userData["customers"] = [];
                }

                dispatch(actions.DeleteUserCustomerSuccess(userData));
                return response;
            }

            dispatch(actions.DeleteUserCustomerError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteUserCustomerError(error.message));
            return Promise.resolve(error.response);
        });
};


export const deleteUser = username => (dispatch, getState) => {
    const url = apiUrl.BASE_URL + apiUrl.DELETE_USER.format(username);
    const store = getState();
    let user = store.users.list;

    return axios
        .delete(url)
        .then(response => {
            if (response.status === 200) {
                let userData;
                if (user) {
                    userData = Object.keys(user).reduce((object, key) => {
                        if (key !== username) {
                            object[key] = user[key];
                        }
                        return object;
                    }, {});
                } else {
                    userData = {};
                }
                dispatch(actions.DeleteUserSuccess(userData));
                return response;
            }

            dispatch(actions.DeleteUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.DeleteUserError(error.message));
            return Promise.resolve(error.response);
        });
};


export const sendUserMail = (userData) => (dispatch) => {
    const payload = userData;
    const url = apiUrl.BASE_URL + apiUrl.SEND_MAIL;
    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;

        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};


export const fetchUserData = (user_name) => (dispatch, getState) => {
    const store = getState();
    var userList = store.users.list;
    const url = apiUrl.BASE_URL + apiUrl.GET_USER_DETAILS.format(user_name);
    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                response.data.data.map((data) => {
                    userList[data.user_name] = data;
                });
                dispatch(actions.GetUserSuccess(userList));
                return response;
            }
            dispatch(actions.GetUserError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetUserError(error.message));
            return Promise.resolve(error.response);
        });
};


export const importUsers = (userData) => (dispatch) => {
    let formData = new FormData();
    formData.append("tatvamusers", userData.currentFile);
    const url = apiUrl.BASE_URL + apiUrl.IMPORT_USERS;

    return axios
        .post(url, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;

        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

