import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerSource,
    createPredictoSource,
    fetchAllSources,
    fetchAllSourcesByType,
    fetchCategory,
    fetchCommentType,
    fetchCustomerResponses,
    getApiSourceDetails,
    getPredictoSourceDetails,
    updateCustomerSource,
    updatePredictoSource
} from "../../../actions";
import PredictionSourceForm from "./predictionSourceForm";

const mapStateToProps = (state, ownProps) => ({
    userName: state.auth.userName,
    sourcePermission: state.auth.permissions && state.auth.permissions.customer.sources.create,
    category: state.category && state.category.list,
    sourceList: state.sources.sourcelist,
    commentTypes: state.commentType && state.commentType.list,
    apiDetails: state.sources.apiDetails,
    customerResponses: state.customerResponse && state.customerResponse.list
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllSources,
            createCustomerSource,
            updateCustomerSource,
            fetchCategory,
            fetchAllSourcesByType,
            fetchCommentType,
            getApiSourceDetails,
            fetchCustomerResponses,
            createPredictoSource,
            updatePredictoSource,
            getPredictoSourceDetails

        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(PredictionSourceForm);

