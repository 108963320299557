import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");
format.extend(String.prototype, {});

const actions = {
    GetReportSubscriptionSuccess: (payload) => ({
        type: "FETCH_REPORT_SUBSCRIPTION_SUCCESS",
        payload
    }),
    GetReportSubscriptionError: (payload) => ({
        type: "FETCH_REPORT_SUBSCRIPTION_ERROR",
        payload
    }),
    GetCustomerUserDetailsSuccess: (payload) => ({
        type: "FETCH_CUSTOMER_USER_DETAILS_SUCCESS",
        payload
    }),
    GetCustomerUserDetailsError: (payload) => ({
        type: "FETCH_CUSTOMER_USER_DETAILS_ERROR",
        payload
    }),
    CreateCustomerReportSubscriptionSuccess: (payload) => ({
        type: "CREATE_CUSTOMER_REPORT_SUBSCRIPTION_SUCCESS",
        payload
    }),
    CreateCustomerReportSubscriptionError: (payload) => ({
        type: "CREATE_CUSTOMER_REPORT_SUBSCRIPTION_ERROR",
        payload
    }),
    UpdateCustomerReportSubscriptionSuccess: (payload) => ({
        type: "UPDATE_CUSTOMER_REPORT_SUBSCRIPTION_SUCCESS",
        payload
    }),
    UpdateCustomerReportSubscriptionError: (payload) => ({
        type: "UPDATE_CUSTOMER_REPORT_SUBSCRIPTION_ERROR",
        payload
    }),
};

export const fetchCustomerReportSubscription = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        const customer = state.customer.list;
        const url = apiUrl.BASE_URL + apiUrl.GET_REPORT_SUBSCRIPTION.format(id);
        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {

                    let customerData = customer[id];
                    customerData = {
                        ...customerData,
                        reportSubscriptions: response.data.data
                    };
                    dispatch(actions.GetReportSubscriptionSuccess(customerData));
                    return response;
                }

                dispatch(actions.GetReportSubscriptionError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetReportSubscriptionError(error.message));
                return Promise.resolve(error.response);
            });
    }
};

export const fetchCustomerUserDetails = (id) => (dispatch, getState) => {
    if (id) {
        const state = getState();
        const customer = state.customer.list[id];
        const url = apiUrl.BASE_URL + apiUrl.GET_CUSTOMER_USER_DETAILS.format(id);

        return axios
            .get(url)
            .then(response => {
                if (response.status === 200) {
                    let customerData = customer;
                    customerData = {
                        ...customerData,
                        customerUserDetails: response.data.data
                    };
                    dispatch(actions.GetCustomerUserDetailsSuccess(customerData));
                    return response;
                }
                dispatch(actions.GetCustomerUserDetailsError(response.message));
                return response;
            })
            .catch(error => {
                dispatch(actions.GetCustomerUserDetailsError(error.message));
                return Promise.resolve(error);
            });
    }
};

export const createCustomerReportSubscription = (customerReportSubscription) => (dispatch, getState) => {
    const store = getState();
    const payload = customerReportSubscription;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_CUSTOMER_REPORT_SUBSCRIPTIONS.format(customerReportSubscription.customer_id);
    const customer = store.customer.list[customerReportSubscription.customer_id];
    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                let customerData = customer;
                if (customer.reportSubscriptions) {
                    customerData = {
                        ...customerData,
                        reportSubscriptions: [...customerData.reportSubscriptions, response.data.data[0]]
                    };
                } else {
                    customerData = {
                        ...customerData,
                        reportSubscriptions: [response.data.data[0]]

                    };
                }
                dispatch(actions.CreateCustomerReportSubscriptionSuccess(customerData));
                return response;
            }
            dispatch(actions.CreateCustomerReportSubscriptionError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.CreateCustomerReportSubscriptionError(error.message));
            return Promise.resolve(error.response);
        });
};

export const updateCustomerReportSubscription = (customerReportSubscription, id) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = customerReportSubscription
    const customer = store.customer.list[customerReportSubscription.customer_id];
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_CUSTOMER_REPORT_SUBSCRIPTIONS.format(customerReportSubscription.customer_id, id);

    return axios
        .patch(url, payload)
        .then(response => {

            if (response.status === 200) {
                let customerData = customer;
                let newCustomerData = customerData.reportSubscriptions.filter(data => data._id !== id);
                let updatedReportSubscriptionsData = [...response.data.data, ...newCustomerData];

                customerData = {
                    ...customerData,
                    reportSubscriptions: updatedReportSubscriptionsData
                };
                dispatch(actions.UpdateCustomerReportSubscriptionSuccess(customerData));
                return response;
            }
            dispatch(actions.UpdateCustomerReportSubscriptionError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.UpdateCustomerReportSubscriptionError(error.message));
            return Promise.resolve(error.response);
        });
};
