import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAllUsers, importUsers,} from "../../../actions";
import UserImport from "./userImport";

const mapStateToProps = (state) => ({
    userList: state.users.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            importUsers,
            fetchAllUsers,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserImport);
