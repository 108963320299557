import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS} from 'localConstants';
import Loader from 'react-loaders';
import {Col} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APPLICATION_MODES,
    INDUSTRY_DIMENSION_VALUE,
    INDUSTRY_DIMENSION_VALUE_GRID,
} from '../../../constants/appConstants';
import {trimCapitalize} from '../../../utils/stringUtil';
import {TatvamButton, TatvamGrid, TatvamModal,} from '../../functionalComponents';
import AlertBox from '../alertBox';
import IndustryDimensionValueForm from '../industryDimensionValueForm';

class IndustryDimensionValues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            industryDimensionValuesGridData: [{industry_name: "Loading.."}],
            isModalOpen: false,
            loader: true,
            colDef: [
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.INDUSTRY,
                    field: "industry_name",
                    filterParams: {clearButton: true},
                    width: 180
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.ENTITY_TYPE,
                    field: "parent_entity_value",
                    filterParams: {clearButton: true},
                    width: 227
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.ID,
                    field: "_id",
                    filterParams: {clearButton: true},
                    hide: true
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.ENTITY_VALUE,
                    field: "entity_value",
                    filterParams: {clearButton: true},
                    width: 182
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.STATUS,
                    field: "status",
                    filterParams: {clearButton: true},
                    width: 108
                },
                {
                    headerName: INDUSTRY_DIMENSION_VALUE_GRID.PATTERN,
                    field: "pattern",
                    filterParams: {clearButton: true},
                    width: 145
                }],

        };
    }

    _handleDeleteConfirmation = (data) => {
        this.validateIfIndustryInUse(data).then((returnRes) => {
            if (returnRes) {
                this.setState({
                    idIndustryDimension: data._id,
                    name: data.name,
                    isConfirmBox: true,
                    isAlertBoxOpen: false,
                    isDeleteAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
                });
            }
        });
    };

    validateIfIndustryInUse = (industry) => {
        const industryInUse = Object.values(this.props.customers).filter(customer => customer.industry === industry.industry_name);
        if (industryInUse.length > 0) {
            this.setState({
                idIndustryDimension: industry._id,
                name: industry.name,
                isConfirmBox: true,
                isAlertBoxOpen: false,
                isDeleteAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.INDUSTRY_EXIST
            });
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    };
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let industry_dimension_id = this.state.idIndustryDimension;
            let industryDimensionData = {
                industry_dimension_id

            };
            this.props.actions.deleteIndustryDimension(industryDimensionData).then((response) => {
                this.setState({
                    preLoader: false
                });
                if (response.status === 200) {
                    this.props.actions.getIndustryDimensionValues().then(() => {

                    });

                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _openViewModal = (data) => {
        this.setState({
            dimensionValueData: data[0],
            modalTitle: "",
            applicationMode: APPLICATION_MODES.VIEW,
            isModalOpen: true
        });
    };

    _openCreateModal = () => {
        this.setState({
            dimensionValueData: [],
            modalTitle: INDUSTRY_DIMENSION_VALUE.ADD_INDUSTRY_DIMENSION_VALUE,
            applicationMode: APPLICATION_MODES.CREATE,
            isModalOpen: true
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    componentDidMount() {
        this.setState({
            loader: true
        });
        this.props.actions.getIndustryDimensionValues().then(() => {
            this.setState({
                loader: false
            });
        });
        this.props.actions.fetchAllIndustry();
    }

    componentWillReceiveProps(props) {
        let industryDimensionValuesGridDatas = [];
        if (props.gridData) {
            for (let data of Object.values(props.gridData)) {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        if (this.props.sourceIndustryList && this.props.sourceIndustryList.industry) {
                            for (var i = 0; i < props.sourceIndustryList.industry.length; i++) {
                                if (props.sourceIndustryList.industry[i].industry_id === data.industry_id) {
                                    data.name = props.sourceIndustryList.industry[i].name;
                                }
                            }
                        }
                        industryDimensionValuesGridDatas.push(data);
                    }

                }
            }
        }
        this.setState({
            industryDimensionValuesGridData: industryDimensionValuesGridDatas
        });
    }

    render() {
        return (
            <Fragment>
                <div className="page_heading">
                    <h5>{INDUSTRY_DIMENSION_VALUE.INDUSTRY_DIMENSION}</h5>
                    <div className="dashboard-btn mt-1 valign-wrapper">
                        {this.props.industryDimensionPermission === APP_BOOLEANS.TRUE && (
                            <TatvamButton className="btn_primary right" onClick={this._openCreateModal}>
                                {INDUSTRY_DIMENSION_VALUE.ADD_INDUSTRY_DIMENSION_VALUE}
                            </TatvamButton>
                        )}
                    </div>
                </div>

                <Col
                    s={12}
                    className={this.state.loader ? "valign-wrapper loader-overlay" : "hide"}
                >
                    {this.state.loader && <Loader type="ball-beat"/>}
                </Col>
                <div className="row  z-depth-4 h-75" ref="tableTop">
                    <TatvamGrid
                        gridData={this.state.industryDimensionValuesGridData}
                        colData={this.state.colDef}
                        rowSelection="multiple"
                        isExportCsv={this.state.isExportCSV}
                        onRowSelect={this._openViewModal}
                        enablePagination
                        enableDeleteColumn={true}
                        onRowDelete={this._handleDeleteConfirmation}
                    />
                </div>

                {this.state.isModalOpen && (
                    <TatvamModal
                        open={this.state.isModalOpen}
                        header={this.state.modalTitle}
                        className="hideFooter"
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <IndustryDimensionValueForm
                                applicationMode={this.state.applicationMode}
                                closeModal={this.closeModal}
                                industryDimensionValueData={this.state.dimensionValueData}
                                industryDimensionValueList={this.state.industryDimensionValuesGridData}
                                nonEditable={this.state.nonEditable}
                            />
                        )}
                    />
                )
                }
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default IndustryDimensionValues;
