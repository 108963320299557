import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

class BaseTabs extends Tabs {

}

class BaseTab extends Tab {

}

class BaseTabList extends TabList {

}

class BaseTabPanel extends TabPanel {

}

export {BaseTabPanel, BaseTabList, BaseTab, BaseTabs};
