import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    createCustomerSource,
    fetchAllSources,
    fetchCategory,
    fetchCommentType,
    updateCustomerSource,
} from '../../../actions';
import WebSourceForm from './webSourceForm';

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    sourceMaster: state.sources.list,
    sourcePermission: state.auth.permissions && state.auth.permissions.customer.sources.create,
    commentTypes: state.commentType && state.commentType.list
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchAllSources,
            createCustomerSource,
            updateCustomerSource,
            fetchCategory,
            fetchCommentType
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(WebSourceForm);

