import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

const actions = {
    GetAllStatesSuccess: (payload) => ({
        type: "FETCH_ALL_STATES_SUCCESS",
        payload
    }),
    GetAllStatesError: (payload) => ({
        type: "FETCH_ALL_STATES_ERROR",
        payload
    })
};

export const fetchAllStates = () => (dispatch, getState) => {
    const state = getState();
    if (state.states) {
        if (state.states.list) {
            if (state.states.list.length > 0) {
                return Promise.resolve(true);
            }
        }
    }
    const url = apiUrl.BASE_URL + apiUrl.GET_ALL_STATES;

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                const stateList = {};
                response.data.data.map((data) => {
                    stateList[data.id] = data;
                });

                dispatch(actions.GetAllStatesSuccess(stateList));
                return response;

            }

            dispatch(actions.GetAllStatesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.GetAllStatesError(error.message));
            return Promise.resolve(error);
        });
};
