import React, {Component, Fragment} from "react";
import TatvamDynamicForm from "../../functionalComponents/tatvamDynamicForm/tatvamDynamicForm";

import AlertBox from "../alertBox/index";

class SourceMasterForm extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    triggerDiscard = (data) => {
        if (data && Object.values(data).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: "Changes will be lost"
            });
        } else {
            this.props.closeModal();
        }
    };
    _discard = data => {
        if (data === "ok") {
            this.props.closeModal();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    render() {
        return (
            <Fragment>
                <TatvamDynamicForm
                    formData={this.props.formData}
                    handleSubmit={this.props.handleSubmit}
                    triggerDiscard={this.triggerDiscard}
                    applicationMode={this.props.applicationMode}
                />
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default SourceMasterForm;
