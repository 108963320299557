import _ from 'lodash';

import {
    DEFAULT_PERIODICITY_NDAYS,
    DEFAULT_PERIODICITY_TYPE,
    FILTER_ALER_NAME,
    FILTER_CHILDE_PROPERTY_NAME,
    FILTER_CLASSIFIER_NAME,
    FILTER_COMMENT_TYPE_NAME,
    FILTER_CUSTOMER_LABEL_NAME,
    FILTER_FLAG_NAME,
    FILTER_PERIODICITY_NAME,
    FILTER_RECOMMENDATION_NAME,
    FILTER_RESPONSE_TIME_NAME,
    FILTER_SOURCE_NAME,
    FILTER_SUB_CLASSIFIER_NAME,
    FILTER_TATVAM_RATING_NAME,
    FILTER_USER_RATING_NAME,
} from '../constants';

export const changeGroupTreeCheckboxItemState = (items, defaultState) => {
    if (!items || items.length <= 0) {
        return [];
    }

    return items.map(item => {
        item.children = this.changeGroupTreeCheckboxItemState(item.children, defaultState);
        item.isChecked = defaultState;
        return item;
    });
};

export const changeControlState = (controlItems, isCheck, valuesToBeMatch) => {
    let result = [];
    if (controlItems && controlItems.length > 0 && valuesToBeMatch && valuesToBeMatch.length > 0) {
        result = controlItems.map((controlItem) => {
            let controlChildItems = [];
            if (controlItem.children) {
                controlChildItems = controlItem.children.map((childControlItem) => {
                    const matchChildCounts = valuesToBeMatch.filter((valueToBeMatch) => {
                        return _.isEqual(childControlItem.value.value, valueToBeMatch);
                    });
                    return {
                        ...childControlItem,
                        isChecked: matchChildCounts.length > 0 ? isCheck : false
                    };
                });
            }
            const matchParentCounts = valuesToBeMatch.filter((valueToBeMatch) => {
                return _.isEqual(controlItem.value.value, valueToBeMatch);
            });
            return {
                ...controlItem,
                children: controlChildItems ? controlChildItems : [],
                isChecked: matchParentCounts.length > 0 ? isCheck : false
            };
        });
    }
    return result;
}

export const convertFilterEntityValueToSliderDotRange = (entityValues) => {
    const dotRanges = [];
    entityValues.forEach((entityValue, index) => {
        dotRanges.push({step: index + 1, displayText: entityValue.name, value: entityValue, isChecked: index === 0});
    })
    return dotRanges;
}

export const convertFilterEntityValueToCheckBoxItems = (values, controlName, defaultState = false) => {
    if (!values) {
        return [];
    }

    return values.map((value, index) => {
        return {
            id: `${controlName}_${index.toString()}`,
            name: value.name,
            isChecked: defaultState,
            value: value
        };
    });
};

export const convertFilterEntityValueToTreeCheckBoxItems = (values, defaultState = false) => {
    if (!values) {
        return [];
    }

    return values.map((value) => {
        const childrenItems = convertFilterEntityValueToTreeCheckBoxItems(value.childrens);
        return {
            children: childrenItems,
            key: value.name,
            title: value.name,
            dimension: value.value,
            isChecked: false,
            value: value,
        };
    });
};

export const convertFilterEntityValueToRatingItems = (values, defaultState = false) => {
    if (!values) {
        return [];
    }

    return values.map((entityValue) => {
        return {
            id: Number(entityValue.value.entity_value),
            isChecked: defaultState,
            displayText: '',
            value: entityValue,
        };
    });
};

export const getGroupCheckBoxSelectedItemId = (sources) => {
    let result = [];
    if (!sources || sources.length <= 0) {
        return result;
    }

    sources.forEach(source => {
        if (source.isChecked) {
            result.push(source.id);
        }
    });
    return result;
};

export const getSelectedGroupCheckBoxItemNames = (sources) => {
    let result = [];
    if (!sources || sources.length <= 0) {
        return result;
    }

    sources.forEach(source => {
        if (source.isChecked) {
            result.push(source.name);
        }
    });

    return result;
};

export const getSelectedItemValue = (items) => {
    let result = [];
    if (items && items.length > 0) {
        const parents = items.filter((item) => item.isChecked);
        const childs = items.filter((item) => item.children && item.children.length > 0)
            .map((item) => {
                return item.children.filter((child) => child.isChecked);
            });
        const checkedChild = _.uniq(_.flatten(childs));
        result = _.union(parents.map(item => item.value.value), checkedChild.map(item => item.value.value));
    }
    return result;
}

export const getSelectedItemDimensionValue = (items) => {
    let result = [];
    if (items && items.length > 0) {
        const parents = items.filter((item) => item.isChecked);
        const childs = items.filter((item) => item.children && item.children.length > 0)
            .map((item) => {
                return item.children.filter((child) => child.isChecked);
            });
        const checkedChild = _.uniq(_.flatten(childs));
        result = _.union(parents.map(item => item.value), checkedChild.map(item => item.value));
    }
    return result;
}

export const getTreeGroupCheckboxKeys = (items) => {
    if (!items) {
        return [];
    }

    const parentItemKeys = _.uniq(_.flatten(items.map((item) => {
        return item.key;
    })));
    const childItemKeys = _.uniq(_.flatten(items.map((item) => {
        return item.children.map((child) => {
            return child.key;
        });
    })));
    return _.union(parentItemKeys, childItemKeys);
}

export const isGroupCheckBoxItemChecked = (items) => {
    return items.filter(item => item.isChecked).length > 0;
};

export const populateDefaultConfiguration = () => {
    return {
        visibles: [
            {name: FILTER_ALER_NAME, isVisible: true},
            {name: FILTER_CHILDE_PROPERTY_NAME, isVisible: true},
            {name: FILTER_COMMENT_TYPE_NAME, isVisible: true},
            {name: FILTER_CLASSIFIER_NAME, isVisible: true},
            {name: FILTER_CUSTOMER_LABEL_NAME, isVisible: true},
            {name: FILTER_FLAG_NAME, isVisible: true},
            {name: FILTER_PERIODICITY_NAME, isVisible: true},
            {name: FILTER_RECOMMENDATION_NAME, isVisible: true},
            {name: FILTER_RESPONSE_TIME_NAME, isVisible: false},
            {name: FILTER_SUB_CLASSIFIER_NAME, isVisible: true},
            {name: FILTER_SOURCE_NAME, isVisible: true},
            {name: FILTER_TATVAM_RATING_NAME, isVisible: true},
            {name: FILTER_USER_RATING_NAME, isVisible: true},
        ],
        isDataFromPeriodicity: true,
        isFilterToBeStored: true,
        limit: 50,
        skip: 0,
    };
}

export const populateDefaultFilter = () => {
    return {
        isAdvanceFilterApplied: false,
        limit: 50,
        periodicity: [DEFAULT_PERIODICITY_NDAYS],
        periodicityItemKey: DEFAULT_PERIODICITY_NDAYS,
        periodicityType: DEFAULT_PERIODICITY_TYPE,
        skip: 0,
        dimensions: [],
    };
}

export const populateThreesStateFilter = (value) => {
    let result = 1; //1: 'All', 2: 'Yes', 3: 'No'
    if (!value) {
        return result;
    }

    return value === true ? 2 : 3;
}

export const populateFilterPanelControl = () => {
    return {
        alert: {
            checkBoxItems: [],
            id: 'alert01',
            name: FILTER_ALER_NAME,
            value: null,
        },
        childProperty: {
            checkBoxItems: [],
            id: 'childProperty01',
            name: FILTER_CHILDE_PROPERTY_NAME,
            value: null,
        },
        classifier: {
            treeCheckBoxItems: [],
            dimensions: [],
            id: 'classifiers01',
            name: FILTER_CLASSIFIER_NAME,
            value: null,
        },
        commentType: {
            checkBoxItems: [],
            id: 'commentType01',
            name: FILTER_COMMENT_TYPE_NAME,
            value: null,
        },
        customerLabel: {
            checkBoxItems: [],
            dimensions: [],
            id: 'label01',
            name: FILTER_CUSTOMER_LABEL_NAME,
            value: null, //all
        },
        flag: {
            id: 'flag',
            name: FILTER_FLAG_NAME,
            value: 1, //all
        },
        periodicity: {
            id: 'periodicity',
            name: FILTER_PERIODICITY_NAME,
            periodicityItems: '',
            value: null,
        },
        recommendation: {
            id: 'recommendation',
            name: FILTER_RECOMMENDATION_NAME,
            value: 1,//all
        },
        responseTime: {
            checkBoxItems: [],
            id: 'responseTime01',
            name: FILTER_RESPONSE_TIME_NAME,
            value: null,
        },
        source: {
            checkBoxItems: [],
            id: 'source01',
            name: FILTER_SOURCE_NAME,
            value: null,
        },
        tatvamRating: {
            id: 'tatvamRating',
            name: FILTER_TATVAM_RATING_NAME,
            value: 0,
        },
        userRating: {
            id: 'tatvamRating',
            name: FILTER_USER_RATING_NAME,
            value: 0,
        },
    };
}

export const setGroupCheckBoxItemCheckedState = (items, itemNames, state) => {
    if (!items || items.length <= 0) {
        return [];
    }

    if (!itemNames || itemNames.length <= 0) {
        return items;
    }

    return items.map((item) => {
        item.isChecked = itemNames.includes(item.name) ? state : item.isChecked;
        return item;
    });
};

export const isDimensionNameFound = (dimensionNames, dimensionName) => {
    if (dimensionNames && dimensionNames.length > 0) {
        return dimensionNames.filter(name => name === dimensionName).length > 0;
    } else {
        return false;
    }
}


