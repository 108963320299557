import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PredictionInfluencer from "./predictionInfluencer";
import {deletePredictionInfluencer, fetchPredictionInfluencers} from "../../../../../actions/index";

const mapStateToProps = (state, ownProps) => ({
    influencerData: state.customer.list[ownProps.currentCustomer.id].influencersData,
    responsePermission: state.auth.permissions && state.auth.permissions.customer.responseTemplate.create,
    responseDelete: state.auth.permissions && state.auth.permissions.customer.responseTemplate.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchPredictionInfluencers,
            deletePredictionInfluencer
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PredictionInfluencer);
