const env = process.env.NODE_ENV; // 'development'|'test'|'production'
// eslint-disable-next-line no-console
const development = {
    server: {
        base_url: "/3.0/"
    },
    dateformat: {
        DOBFormat: "DD/MM/YYYY"
    }
};

const production = {
    server: {
        base_url: "/3.0/"
    },
    dateformat: {
        DOBFormat: "DD/MM/YYYY"
    }
};

const config = {
    development,
    production
};

module.exports = config[env];
