import "./advance-filter.component.scss";
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AdvanceFilterItem from './advance-filter-item/advance-filter-item.component';
import {Button} from 'react-bootstrap';
import {TatvamModal, TatvamMultiSelect} from "../../../../functionalComponents/index";
import {FilterService} from '../service/filter.Service';
import {Icon} from "@material-ui/core";
import AlertBox from '../../../alertBox';

const AdvanceFilter = (props) => {
    const defaultAdvanceFilterItemValue = {
        id: (1).toString(),
        item: {
            dimension_condition: null,
            dimension_name: '',
            dimension_values: []
        },
    };
    const accountState = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [advanceFilterItems, setAdvanceFilterItems] = useState([]);
    const [customerSavedFilters, setCustomerSavedFilters] = useState([]);

    const [newFilterName, setNewFilterName] = useState('');
    const [showSaveApplyModal, setShowSaveApplyModal] = useState(false);
    const [selectedSavedFilter, setSelectedSavedFilter] = useState(null);
    const [selectedSavedFilterOptions, setSelectedSavedFilterOptions] = useState(null);
    const [savedFilterOptions, setSavedFilterOptions] = useState(null);
    const propertyState = {
        selectedProperty: {id: props.currentCustomerId},
    };
    const [isValid, setIsValid] = useState(true);
    const [isConfirmBox, setIsConfirmBox] = useState(false);
    const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [alertInformation, setAlertInformation] = useState("");

    useEffect(() => {
        setAdvanceFilterItems([]);
        setTimeout(() => {
            if (props.show) {
                setSelectedSavedFilter(null);
                if (props.advanceFilterItems && props.advanceFilterItems.length > 0) {
                    setAdvanceFilterItems(props.advanceFilterItems.map((item, index) => {
                        return {
                            id: (index + 1).toString(),
                            item: item,
                        };
                    }));
                } else {
                    setAdvanceFilterItems([defaultAdvanceFilterItemValue]);
                }
            }
        }, 300);

    }, [props.advanceFilterItems, props.show]);

    useEffect(() => {
        setAdvanceFilterItems([]);
        setTimeout(() => {
            try {
                if (selectedSavedFilter && selectedSavedFilter.filter) {
                    const filterItems = JSON.parse(selectedSavedFilter.filter);
                    setAdvanceFilterItems(filterItems.map((item, index) => {
                        return {
                            id: (index + 1).toString(),
                            item: item,
                        };
                    }));
                }
            } catch (err) {
                console.log(err);
            }
        }, 300);
    }, [selectedSavedFilter]);

    useEffect(() => {
        if (props.show) {
            getSaveFilterList();
        }
    }, [props.show]);

    const deleteSelectedSaveFilter = () => {
        if (propertyState.selectedProperty.id && selectedSavedFilter) {
            let filterService = new FilterService(propertyState.selectedProperty.id, accountState);
            filterService.deleteCustomerSavedFilter(selectedSavedFilter.id.toString())
                .then(() => {
                    setSelectedSavedFilter(null);
                    getSaveFilterList();
                    setTimeout(() => {
                        setAdvanceFilterItems([defaultAdvanceFilterItemValue]);
                    }, 300);
                });
            setSavedFilterOptions(null)
            setIsConfirmBox(false);
            setIsAlertBoxOpen(false);
            setIsSuccess(false);
        }
    }

    const getSaveFilterList = () => {
        if (propertyState.selectedProperty.id) {
            let filterService = new FilterService(propertyState.selectedProperty.id, accountState);
            filterService.getCustomerSavedFilters()
                .then((filters) => {
                    let customFilterOption = [];
                    filters.forEach((item) => customFilterOption.push({label: item.filter_name, value: item.id}))
                    setSavedFilterOptions([...customFilterOption])
                    setCustomerSavedFilters(filters);
                });
        }
    }

    const isValidAdvanceFilterItems = () => {
        const isInValid = advanceFilterItems.filter((filterItem) => !filterItem.item.dimension_name || !filterItem.item.dimension_values || filterItem.item.dimension_values.length <= 0).length > 0;
        return !isInValid;
    }

    const onCancelClick = () => {
        setSelectedSavedFilter(null);
        setAdvanceFilterItems([defaultAdvanceFilterItemValue]);
        props.onAdvanceDialogCancelClick();
    }

    const onClearAll = () => {
        setSelectedSavedFilter(null);
        setAdvanceFilterItems([]);
        setTimeout(() => {
            setAdvanceFilterItems([defaultAdvanceFilterItemValue]);
        }, 300);
    }

    const onDeleteFilterItemClick = (value) => {
        const itemsAfterDeleted = advanceFilterItems.filter((item) => item.id !== value.id)
            .map((item) => {
                return {...item};
            });
        setAdvanceFilterItems(itemsAfterDeleted);
    }

    const onFilterItemValueChanged = (id, value) => {
        const existingItems = advanceFilterItems.map((filterItem) => {
            if (filterItem.id === id) {
                return {...filterItem, item: value};
            } else {
                return {...filterItem};
            }
        });
        setAdvanceFilterItems(existingItems);
    }

    const onNewFilterNameChange = (event) => {
        setNewFilterName(event.target.value);
    };

    const onNewFilterSaveClick = () => {
        if (propertyState.selectedProperty.id) {
            const filterItems = advanceFilterItems.map((filterItem) => filterItem.item);
            let filterService = new FilterService(propertyState.selectedProperty.id, accountState);
            filterService.saveCustomerFilter(newFilterName, filterItems).then((result) => {
                if (result) {
                    setNewFilterName('');
                    getSaveFilterList();
                    props.onAdvanceDialogOkClick(filterItems);
                    setShowSaveApplyModal(false)
                }
            }).catch((responseError) => {
                console.log(responseError)
            });
        }
    }

    const onOkClick = (event) => {
        if (!isValidAdvanceFilterItems()) {
            setIsValid(false)

        } else {
            props.onAdvanceDialogOkClick(advanceFilterItems.map((filterItem) => filterItem.item));
            setSelectedSavedFilter(null);
        }
    };

    const onOperatorChanged = (itemId, operator) => {
        const existingItems = advanceFilterItems.map((filterItem) => {
            if (filterItem.id === itemId) {
                filterItem.item.dimension_condition = operator;
            }
            return {...filterItem};
        });

        const maxId = Math.max.apply(null, existingItems.map((item) => Number(item.id)));
        const isAddNewItemApplicable = existingItems.filter((item) => Number(item.id) > Number(itemId)).length > 0;
        if (!isAddNewItemApplicable) {
            existingItems.push({...defaultAdvanceFilterItemValue, id: (maxId + 1).toString()});
        }
        setAdvanceFilterItems(existingItems);
    }

    const onSaveAndApply = () => {

        if (!isValidAdvanceFilterItems()) {
            setIsValid(false)
        } else {
            if (selectedSavedFilter && propertyState.selectedProperty) {
                const filterItems = advanceFilterItems.map((filterItem) => filterItem.item);
                let filterService = new FilterService(propertyState.selectedProperty.id, accountState);
                filterService
                    .updateCustomerSavedFilter(selectedSavedFilter, filterItems)
                    .then((result) => {
                        if (result) {
                            setNewFilterName('');
                            getSaveFilterList();
                            props.onAdvanceDialogOkClick(filterItems);
                            setShowSaveApplyModal(false)
                        }
                    }).catch((responseError) => {
                });
            } else {
                setShowSaveApplyModal(true);
            }
        }
    }

    const onSavedFilterChanged = (customerSavedFilterId) => {
        const filterResults = customerSavedFilters.filter((filter) => filter.id === customerSavedFilterId.value);
        if (filterResults && filterResults.length > 0) {
            setSelectedSavedFilterOptions(customerSavedFilterId)
            setSelectedSavedFilter(filterResults[0]);
        } else {
            setSelectedSavedFilter(null);
        }
    }
    const _discard = (data) => {
        if (data === "ok") {
            deleteSelectedSaveFilter();
        } else {
            setIsConfirmBox(false);
            setIsAlertBoxOpen(false);
            setIsSuccess(false);
        }
    };

    const onSavedFilterDelete = () => {
        if (selectedSavedFilter) {
            setIsConfirmBox(true);
            setIsAlertBoxOpen(true);
            setIsSuccess(false);
            setAlertInformation(`Are you sure you want to delete ${selectedSavedFilter.filter_name} filter?`);
        }
    }

    const renderBody = () => {
        return <div className="row advance-filter-items">
            {
                advanceFilterItems.map((item, index) => {
                    return <AdvanceFilterItem
                        value={item.item}
                        control={props.controls}
                        id={item.id}
                        onDeleteClick={index > 0 ? onDeleteFilterItemClick : undefined}
                        onOperatorChanged={onOperatorChanged}
                        onValueSelected={onFilterItemValueChanged}
                        periodicity={props.periodicity}
                    />;
                })
            }
        </div>;
    }

    const renderHeader = () => {
        const savedFilterExists = savedFilterOptions !== null && selectedSavedFilterOptions !== null && savedFilterOptions.includes(selectedSavedFilterOptions)
        return <div className="row mb-1">
            <div className="col m4 l3 s12 align-flex-end">
                <label className="modal-title">Advanced Filter</label>
            </div>
            <div className="saved-filter-control col m5 l7">
                <TatvamMultiSelect
                    classNamePrefix="react-select"
                    className="select-ht col s12 pr-0 "
                    value={savedFilterExists ? selectedSavedFilterOptions ? selectedSavedFilterOptions : null : null}
                    name="search-by"
                    id="search-by"
                    onChange={onSavedFilterChanged}
                    options={savedFilterOptions}
                    placeholder="Choose saved filter..."
                ></TatvamMultiSelect>

            </div>
            <div className="col m3 l2 mt-2 p-0">
                {selectedSavedFilter &&
                    <button className="btn-link bg-white p-0 border-none" onClick={onSavedFilterDelete}>
                        <Icon>delete</Icon>

                    </button>}
                <button className="btn-link border-none bg-white p-0 ml-5" onClick={onClearAll}>
                    <Icon>remove_circle_outline</Icon>

                </button>
            </div>
        </div>;
    }

    const renderModal = () => {
        return <Fragment>
            <TatvamModal
                open={props.show}
                className="advanced-filter-modal hideFooter"
                options={{dismissible: false}}
                fixedFooter={false}
                children={(
                    <Fragment>
                        {renderHeader()}
                        {renderBody()}
                        {renderModalFooterButton()}
                    </Fragment>
                )}
            >
            </TatvamModal>
        </Fragment>;
    }
    const closeSaveModal = () => {
        setShowSaveApplyModal(false);
    }
    const renderModalFooterButton = () => {
        return <div className="row">
            {!isValid && (<p className="errorText">Please Select Search By and Value.</p>)}

            <Button
                className="btn btn_primary mr-2 right"
                data-toggle="tooltip"
                onClick={onCancelClick}
                title="Cancel">
                Cancel
            </Button>
            <Button
                className="btn btn_primary mr-2 right"
                data-toggle="tooltip"
                onClick={onSaveAndApply}
                title="Save & close">
                Save & Close
            </Button>
            <Button
                className="btn btn_primary mr-2 right"
                data-toggle="tooltip"
                onClick={onOkClick}
                title="Ok">
                Ok
            </Button>
        </div>
    }

    const renderSaveModalHeader = () => {
        return (
            <div className="">
                <span className="m-0 modal-title">New Saved Filter</span>
                <i className="material-icons right pointer" onClick={closeSaveModal}>close</i>
            </div>
        );
    };

    const renderSaveModalBody = () => {
        return (
            <Fragment>
                <label className="mandatory">Filter Name</label>
                <input
                    type="text"
                    id="filterName"
                    value={newFilterName}
                    onChange={onNewFilterNameChange}
                    className="form-control"
                />
                <Button
                    disabled={!newFilterName}
                    className="btn btn_primary right"
                    onClick={onNewFilterSaveClick}>
                    Save
                </Button>
            </Fragment>
        );
    };

    return <Fragment>
        {renderModal()}

        {showSaveApplyModal &&
            <TatvamModal
                open={showSaveApplyModal}
                className="saved-filter-modal"
                options={{dismissible: false}}
                fixedFooter={false}
                children={(
                    <Fragment>
                        {renderSaveModalHeader()}
                        {renderSaveModalBody()}
                    </Fragment>
                )}
            >
            </TatvamModal>
        }
        <AlertBox
            open={isAlertBoxOpen}
            isSuccess={isSuccess}
            closeAlertBox={_discard}
            isConfirmBox={isConfirmBox}
            alert_information={alertInformation}
        />
    </Fragment>
}

export default AdvanceFilter;
