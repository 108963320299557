import './header.scss';

/* eslint-disable react/jsx-no-target-blank */
import React, {Component, Fragment,} from 'react';

import {Col, Preloader,} from 'react-materialize';
import {Link} from 'react-router-dom';

import logo from '../../../../../public/assets/images/logo/tatvamLogo.png';
import {ALERT_MESSAGES} from '../../../../constants/appConstants';
import AlertBox from '../../alertBox';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preloader: false,
            logginStatus: true,
            ifVisited: false
        };
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    }

    clearTimeout = () => {

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout = () => {
        this.logoutTimeout = setTimeout(this.logout, 600 * 1000);
    };

    resetTimeout = () => {
        this.clearTimeout();
        this.setTimeout();
    };

    logout = () => {
        this.setState({logginStatus: false});
        this.destroyTimer();
        this.props.actions.UserSignOut();
    };

    destroyTimer = () => {
        this.clearTimeout();

        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    };

    handleClick = () => {
    };

    componentDidMount() {
        if (!sessionStorage.userLoggedIn) {
            this.logout();
        }
    }

    _discard = data => {
        if (data === "ok") {
            this.props.actions.UserSignOut();
        } else {
            this.setState({
                isConfirmBox: false,
                isAlertBoxOpen: false,
                isSuccess: false
            });
        }
    };

    _logoutConfirmationHandler = () => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: false,
            isConfirmBox: true,
            alert_information: ALERT_MESSAGES.LOGOUT_CONFIRMATION_MESSAGE
        });
    };

    render() {
        return (
            <Fragment>
                <Col s={12} className={this.state.preloader ? "valign-wrapper leftzero loader-overlay-view" : "hide"}>
                    <Preloader className="spinner" size="big" active={this.state.preloader}/>
                </Col>
                <header id="header" className="page-topbar">
                    <Col className="navbar-fixed">
                        <nav className="navbar-color">
                            <Col className="nav-wrapper d-flex">
                <span className="header-nav">
                  <Link to="/live" onClick={this.handleClick} className="pl-2" name="VIEW">
                    <img src={logo} alt="_logo"/>
                  </Link>
                </span>
                                {this.props.authStatus ? (
                                    <label
                                        className="white-text"
                                        style={{float: "right", marginRight: "62px"}}
                                        title="Environment"
                                    >
                                        {/*{env}*/}
                                    </label>
                                ) : null}

                                <a className="user_name">
                                    {this.props.userName}
                                </a>
                                <a className="nav-button">
                                    <a className="btn-outlined" href="https://live.tatvamanalytics.com/"
                                       target="_blank">Tatvam Application</a>
                                </a>
                                <a className="logoutIcon">
                                    <a onClick={this._logoutConfirmationHandler}>
                                        <img
                                            src={require("../../../../../public/assets/images/menuIcon/white/header-logout.png")}
                                            alt="logout"/>
                                    </a>
                                </a>

                            </Col>
                        </nav>
                    </Col>
                </header>

                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        isConfirmBox={this.state.isConfirmBox}
                        closeAlertBox={this._discard}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Header;
