import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {createRoles, editRoles, fetchApplicationNames} from "../../../actions";
import RoleForm from "./roleForm";

const mapStateToProps = (state) => ({
    userName: state.auth.userName,
    rolePermission: state.auth.permissions && state.auth.permissions.customer.roles.create,
    applicationLst: state.applicationName.list
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            editRoles,
            createRoles,
            fetchApplicationNames
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
