import './customerSource.scss';

/* eslint-disable no-console */
import React, {Component, Fragment,} from 'react';

import {
    ALERT_MESSAGES,
    API_SOURCE,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    PREDICTION_SOURCE,
} from 'appConstants';
import {customAlphabet} from 'nanoid';
import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {dynamicSort} from '../../../utils/objectUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';
import PredictionDetails from './predictoSourceDetails';

class PredictionSourceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            customer_id: "",
            name: "",
            unique_name: "",
            details: {},
            source_name: "",
            selectedSource: "",
            isValid: true,
            isDetailsValid: true,
            sourceData: {},
            status: APPLICATION_MODES.INACTIVE

        };
    }

    ifAnyFieldChanged = (cb) => {
        this.setState({isAnyFormChanged: cb});
    };

    _handleSourceSelect = selectedOption => {
        this.setState({
            ...this.state,
            editFlag: {
                ...this.state.editFlag,
                apiSource: true
            },
            selectedSource: selectedOption,
            source_name: selectedOption.label
        });


        if (this.props.sourceList) {
            this.setState({details: this.props.sourceList[selectedOption.value] ? this.props.sourceList[selectedOption.value].details : {}});
        }
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({});
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _validations = () => {
        let isValid;
        let isDetailsValid;


        const details = Object.keys(this.state.details).includes("");

        if (this.state.name && this.state.name !== "") {
            isValid = true;
        } else {
            isValid = false;
        }

        if (this.state.details && !details) {
            isDetailsValid = true;
            this.setState({});
        } else {
            isDetailsValid = false;
        }
        this.setState({
            isDetailsValid,
            isValid
        });


        return Promise.resolve(true);
    }

    _handleSave = () => {
        this._validations().then((response) => {
            let {data} = "";
            let selectedFormValid = [];
            let sourceData = [];
            const is_status = this.state.status;

            if (this.state.name !== "") {
                selectedFormValid.push(true);
                data = {
                    source_name: this.state.selectedSource.label,
                    name: this.state.name,
                    unique_name: this.state.name,
                    status: is_status,
                    details: this.state.details
                };
                if (this.state.applicationMode === "CREATE") {
                    const nanoid = customAlphabet(APPLICATION_MODES.NANO_ID_CHARACTERS, APPLICATION_MODES.NANO_ID_LENGTH);
                    let uniqueID = nanoid();
                    data._id = uniqueID,
                        data.id = uniqueID,
                        data.created_by = this.props.userName;
                } else {
                    data.updated_by = this.props.userName;
                    data.id = this.state.id;
                }
                sourceData.push(data);
            } else {
                selectedFormValid.push(false);
            }


            if (this.state.isValid && this.state.isDetailsValid) {
                this.setState({
                    preLoader: true,
                    formSelection: true
                });
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {

                    this.props.actions.createPredictoSource(sourceData, this.props.customerId).then((response) => {
                        this.setState({preLoader: false});

                        if (response && (response.status === 200 || response.status === 304)) {
                            this.setState({shouldCloseModal: true});
                            if (response && response.data.message) {
                                this.setAlert(response.data.message);
                            } else {
                                this.setAlert(response.data.message);
                            }
                        } else {
                            this.setState({
                                shouldCloseModal: false,
                                isAlertBoxOpen: true,
                                isSuccess: false,
                                alert_information: response.data.message
                            });
                        }
                    });
                } else {
                    this.props.actions.updatePredictoSource(sourceData, this.props.customerId, this.state.id).then((response) => {
                        this.setState({preLoader: false});
                        if (response && (response.status === 200 || response.status === 304)) {
                            this.setState({shouldCloseModal: true});
                            this.props.handleEditRecord(response.data.data[0]);
                            if (response.data && response.data.message) {
                                this.setAlert(response.data.message);
                            } else {
                                this.setAlert(response.message);
                            }
                        } else {
                            this.setState({
                                shouldCloseModal: false,
                                isAlertBoxOpen: true,
                                isSuccess: false,
                                alert_information: response.data.message
                            });
                        }
                    });
                }
            }
        });
    };

    setAlert = (alertMessage) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: true,
            alert_information: alertMessage
        });
    };

    handleAppMode = () => {
        this.setState({applicationMode: "EDIT"});
    };

    componentDidMount() {
        this.props.actions.getPredictoSourceDetails().then((response) => {

            if (response.data.data) {
                this.setApiSource(response.data.data);
                this.setState({sourceList: response.data.data});
            }
        });

        this.setState({applicationMode: this.props.applicationMode});

        if (this.props.sourceData && this.props.sourceData.length) {
            this.setState({
                sourceData: this.props.sourceData[0],
                ...this.props.sourceData[0],
                selectedSource: {
                    label: this.props.sourceData[0].source_name,
                    value: this.props.sourceData[0].source_name
                }
            })
        }

        if (this.props.category) {
            this.setState({categoryList: this.props.category});
        }

        if (this.props.customerData) {
            this.setState({
                license_from: this.props.customerData.valid_from,
                license_upto: this.props.customerData.valid_upto,
            });
        }

        if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({
                valid_from: this.props.customerData.valid_from,
                valid_upto: this.props.customerData.valid_upto,
                isPublic: true
            });
        } else {
            if (this.props.sourceData) {
                this.props.sourceData.map((data) => {
                    this.setState({details: data.details});
                });
            }
        }
    }


    componentWillReceiveProps(props) {
        if (this.state.sourceList) {
            this.setApiSource(this.state.sourceList);
        }


        if (props.sourceData && props.sourceData.length) {
            console.log(props.sourceData);
            this.setState({
                sourceData: props.sourceData[0],
                ...props.sourceData[0],
                selectedSource: {
                    label: props.sourceData[0].source_name,
                    value: props.sourceData[0].source_name
                }
            })
        }

        if (props.category) {
            this.setState({categoryList: this.props.category});
        }

        if (props.customerData) {
            this.setState({
                license_from: this.props.customerData.valid_from,
                license_upto: this.props.customerData.valid_upto,
            });
        }

        if (props.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({
                valid_from: props.customerData.valid_from,
                valid_upto: props.customerData.valid_upto,
                isPublic: true
            });
        } else {
            if (props.sourceData) {
                props.sourceData.map((data) => {
                    this.setState({details: data.details});
                });
            }
        }
    }

    setApiSource = (response) => {
        const apiSourceListFetched = Object.values(response);
        if (apiSourceListFetched.length > 0) {
            const sourceLst = apiSourceListFetched.map((data) => {
                return {label: data.name, value: data.name};
            });
            sourceLst.sort(dynamicSort("label"));
            this.setState({apiSourceList: sourceLst});
        }
    };

    _handleInput = e => {
        this.ifAnyFieldChanged(true);
        if (e.target.type === "checkbox") {
            this.setState({
                [e.target.id]: e.target.checked ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE
            });
        }
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                ...this.state.editFlag,
                [e.target.name]: true
            }
        });
    };

    handleSubmit = (data) => {
        this.ifAnyFieldChanged(true);
        this.setState({details: data.reduce((obj, item) => ({...obj, [item.rowKey]: item.rowValue}), {})})
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12}
                               className={this.state.preLoader ? "valign-wrapper form-loader-overlay api-form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                {this.state.sourceData && (
                    <Fragment>
                        <Row>
                            {this.state.applicationMode === "VIEW" ? (
                                    <TatvamCol s={12}>
                                        <h4>{API_SOURCE.VIEW_TITLE_SOURCE}</h4>
                                        <div className="col s12 l6 formView p-0">
                                            <span className="label property-label col s3">Source :</span>
                                            <span className="col s9 captialize">{this.state.selectedSource.label}</span>
                                        </div>
                                        <div className="col s12 l6 formView p-0">
                                            <span className="label property-label col s3">Name :</span>
                                            <span className="col s9 captialize">{this.state.name}</span>
                                        </div>
                                    </TatvamCol>
                                ) :
                                (
                                    <TatvamCol s={12} className="p-0">
                                        {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                                            <h4>{API_SOURCE.EDIT_SOURCE}</h4>) : ""}
                                        <TatvamCol s={12} m={6} xl={6}>
                                            <label htmlFor="apiSource">{API_SOURCE.Name}</label>
                                            <TatvamMultiSelect
                                                classNamePrefix="react-select"
                                                className="validate select-ht col s12 pr-0"
                                                value={this.state.selectedSource}
                                                name="apiSource"
                                                id="apiSource"
                                                onChange={this._handleSourceSelect}
                                                options={this.state.apiSourceList}
                                                placeholder={PREDICTION_SOURCE.SELECT_PREDICTION_SOURCE}
                                                isDisabled={(this.state.applicationMode === "EDIT") || (this.state.applicationMode === "VIEW")}
                                            />
                                        </TatvamCol>
                                        {this.state.selectedSource && this.state.selectedSource.value &&
                                            <TatvamCol s={12} m={6} xl={6}>
                                                <label htmlFor="name" className="col s12">Name</label>
                                                <TatvamTextBox
                                                    id="name"
                                                    name="name"
                                                    onChange={this._handleInput}
                                                    className="labelText mb-0 validate txt-no-h"
                                                    s={12}
                                                    m={12}
                                                    l={12}
                                                    value={this.state.name}
                                                />
                                            </TatvamCol>
                                        }
                                    </TatvamCol>
                                )
                            }

                            {this.state.applicationMode === "VIEW" ? (
                                <TatvamCol s={12} className="p-0">
                                    <TatvamCol s={12}>
                                        <div className="col s12 m6 p-0">
                                            <span className="property-label col s3"> {API_SOURCE.STATUS} : </span>
                                            <span className="col s9">{this.state.status}
                      </span>
                                        </div>
                                    </TatvamCol>
                                    {/* <TatvamCol m={12} className="p-0">
                    <PredictionDetails
                      apiDetails={this.state.details}
                      onSubmit={this.handleSubmit}
                      disabled={this.state.applicationMode === "VIEW"}
                      inValidForm={this.state.inValidForm}
                    />
                  </TatvamCol> */}
                                </TatvamCol>
                            ) : (
                                this.state.selectedSource && this.state.selectedSource.value &&
                                <TatvamCol s={12}>
                                    <TatvamCol s={12} className="formRowApi">
                                        <TatvamCol s={12} m={6} className="switch-wrapper">
                                            <Switch
                                                id="status"
                                                checked={this.state.status === APPLICATION_MODES.ACTIVE}
                                                onClick={this._handleInput}
                                                offLabel={API_SOURCE.STATUS}
                                                m={6}
                                            />
                                        </TatvamCol>

                                    </TatvamCol>
                                </TatvamCol>
                            )
                            }

                            {this.state.selectedSource.label &&
                                <TatvamCol m={12} className="p-0">
                                    <PredictionDetails
                                        apiDetails={this.state.details}
                                        onSubmit={this.handleSubmit}
                                        disabled={this.state.applicationMode === "VIEW"}
                                        inValidForm={this.state.inValidForm}
                                    />
                                </TatvamCol>
                            }
                            {!this.state.isValid && <p className="errorText col s12">Please enter a name</p>}
                            {!this.state.isDetailsValid &&
                                <p className="errorText col s12">Please fill source details</p>}
                        </Row>
                        <Row className="modalButtons">
                            {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                    <TatvamButton
                                        waves="light"
                                        className="mt-1  CreateProjectPublish btn_primary "
                                        onClick={this._handleSave}
                                        disabled={!this.state.isAnyFormChanged}
                                    >
                                        {FORM_OPERATIONS.SAVE}
                                    </TatvamButton>
                                ) :
                                this.props.sourcePermission === APP_BOOLEANS.TRUE && (
                                    <TatvamButton
                                        waves="light"
                                        className="mt-1 CreateProjectPublish btn_primary "
                                        name="EDIT"
                                        onClick={this.handleAppMode}
                                    >
                                        {FORM_OPERATIONS.EDIT}
                                    </TatvamButton>
                                )}
                            <TatvamButton
                                waves="light"
                                className="mt-1 mr-1 CreateProjectPublish btn_primary"
                                onClick={this._triggerDiscard}
                            >
                                {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                                !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                    FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                            </TatvamButton>
                        </Row>
                    </Fragment>
                )}
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default PredictionSourceForm;

