import React from 'react';
import {Col} from "react-materialize";

const BaseCol = (props) => {
    return (
        <Col {...props}/>
    )
}

export default BaseCol;
