import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    createCustomerDimension,
    deleteCustomerDimension,
    getCustomerDimensions,
    updateCustomerDimension
} from "../../../../../actions";
import Dimensions from "./dimensions";

const mapStateToProps = (state, ownProps) => ({
    gridData: state.customer.list[ownProps.currentCustomer.id].dimensions,
    dimensionPermission: state.auth.permissions && state.auth.permissions.customer.dimensions.create,
    dimensionDelete: state.auth.permissions && state.auth.permissions.customer.dimensions.delete
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getCustomerDimensions,
            createCustomerDimension,
            updateCustomerDimension,
            deleteCustomerDimension
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dimensions);
