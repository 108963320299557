import './customerResponseTemplateForm.scss';

import React, {Component, Fragment,} from 'react';

import {customAlphabet} from 'nanoid';
import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {
    ALERT_MESSAGES,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    PROPERTY_DIMENSION,
    PROPERTY_RESPONSE_TEMPLATES,
    SWITCH_TEXT,
} from '../../../constants/appConstants';
import {dynamicSort} from '../../../utils/objectUtil';
import {TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextArea, TatvamTextBox,} from '../../functionalComponents';
import AlertBox from '../alertBox';

class CustomerResponseTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            preLoader: false,
            status: false,
            parentEntityListsOptions: [],
            entity_type: "",
            entity_desc: "",
            sequence_no: "",
            isFilter: false,
            is_filter: APPLICATION_MODES.NO,
            isTagging: false,
            is_tagging: APPLICATION_MODES.NO,
            isDisplayTag: false,
            is_displaytag: APPLICATION_MODES.NO,
            attribute_for_tagging: "",
            isValid: true,
            editFlag: false,
            parent_entity: "",
            validEntityName: true,
            validSequenceNumber: true,
            validAttributeTagging: true,
            sourceListsOptions: [],
            selectedSource: []
        };
    }


    componentDidMount() {
        if (this.props.customerSources) {
            this.loadSourceDropdown(this.props);
        }
        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (this.props.responseTemplateData) {
                let selectedSource = {label: PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE, value: ""};
                if (this.props.responseTemplateData.source_name && this.props.responseTemplateData.source_id) {
                    selectedSource = {
                        label: this.props.responseTemplateData.source_name + " - " + this.props.responseTemplateData.comment_type,
                        value: this.props.responseTemplateData.source_id
                    };

                }

                this.setState({
                    ...this.props.responseTemplateData,
                    selectedSource: selectedSource,
                    status: this.props.responseTemplateData.status === APPLICATION_MODES.ACTIVE ? true : false
                });

            }
        }


    }

    componentWillReceiveProps(props) {
        if (props.customerSources) {
            this.loadSourceDropdown(props);
        }
        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (props.responseTemplateData) {
                let selectedSource = {label: PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE, value: ""};
                if (props.responseTemplateData.source_name !== "" && props.responseTemplateData.source_id !== "") {
                    selectedSource = {
                        label: props.responseTemplateData.source_name + " - " + props.responseTemplateData.comment_type,
                        value: props.responseTemplateData.source_id
                    };
                }

                this.setState({
                    selectedSource: selectedSource,
                    status: props.responseTemplateData.status === APPLICATION_MODES.ACTIVE ? true : false
                });

            }
        }

    }

    loadSourceDropdown = (thisProps) => {
        const sourceLst = [];
        sourceLst.push({label: "", value: ""});
        thisProps.customerSources.map((data) => {
            if (data.status === APPLICATION_MODES.ACTIVE && data.source_type === PROPERTY_RESPONSE_TEMPLATES.API_SOURCE_TYPE && data.is_respondable === APPLICATION_MODES.YES) {
                const sourcename = data.name + " - " + data.comment_type;
                sourceLst.push({label: sourcename, value: data.id});
            }
        });
        sourceLst.sort(dynamicSort("label"));
        sourceLst[0].label = PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE;
        this.setState({
            sourceListsOptions: sourceLst
        });
    };

    _handleSave = e => {
        e.preventDefault();
        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            } else {
                let responseTemplateData = {};
                responseTemplateData.template_name = this.state.template_name;
                responseTemplateData.template = this.state.template;
                responseTemplateData.status = this.state.status ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE;
                if (this.state.selectedSource.label === undefined || this.state.selectedSource.label === PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE) {
                    responseTemplateData.source_id = "";
                    responseTemplateData.source_name = "";
                    responseTemplateData.source_type = "";
                    responseTemplateData.comment_type = "";
                } else {
                    if (this.props.customerSources) {
                        var sourceData = this.state.selectedSource.value;
                        const customerSourceData = this.props.customerSources.filter(item => item.id === sourceData);
                        responseTemplateData.source_id = customerSourceData[0].id;
                        responseTemplateData.source_name = customerSourceData[0].name;
                        responseTemplateData.source_type = customerSourceData[0].source_type;
                        responseTemplateData.unique_name = customerSourceData[0].unique_name;
                        responseTemplateData.comment_type = customerSourceData[0].comment_type;
                    }

                }
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    const nanoid = customAlphabet(APPLICATION_MODES.NANO_ID_CHARACTERS, APPLICATION_MODES.NANO_ID_LENGTH)
                    let uniqueID = nanoid();
                    responseTemplateData = {
                        _id: uniqueID,
                        id: uniqueID,
                        customer_id: this.props.currentCustomerId,
                        ...responseTemplateData,
                        created_by: this.props.userName
                    };

                    this.setState({preLoader: true});

                    this.props.actions.createResponseTemplate(responseTemplateData).then(response => {
                        this.setState({preLoader: false});
                        if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                                this.setAlertResponseTemplate(response.data.message, true);
                            } else {
                                this.setAlertResponseTemplate(response.message, true);
                            }
                        } else {
                            this.setState({shouldCloseModal: false});
                            this.setAlertResponseTemplate(response.data.message, false);
                        }
                    });
                } else if (this.state.applicationMode === APPLICATION_MODES.EDIT) {
                    responseTemplateData = {
                        id: this.props.responseTemplateData.id,
                        _id: this.props.responseTemplateData._id,
                        customer_id: this.props.currentCustomerId,
                        ...responseTemplateData,
                        updated_by: this.props.userName,
                        updated_count: this.props.responseTemplateData.process_data ? this.props.responseTemplateData.process_data.updated_count : 0
                    };
                    this.setState({preLoader: true});

                    this.props.actions.editResponseTemplate(responseTemplateData).then(response => {
                        this.setState({preLoader: false});
                        if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                                this.setAlertResponseTemplate(response.data.message, true);
                            } else {
                                this.setAlertResponseTemplate(response.message, true);
                            }
                        } else {
                            this.setState({shouldCloseModal: false});
                            this.setAlertResponseTemplate(response.data.message, false);
                        }
                    });
                }
            }
        });
    }

    setAlertResponseTemplate = (alertMessage, boolStatus) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: boolStatus,
            alert_information: alertMessage
        });
    };

    _validations = () => {
        let mandatoryFieldsValid;
        if (!this.state.template_name || !this.state.template) {
            mandatoryFieldsValid = false;
        } else {
            mandatoryFieldsValid = true;
        }
        this.setState({
            isValid: mandatoryFieldsValid

        });
        return Promise.resolve(true);
    }

    _handleInput = e => {

        if (e.target.id === "status") {

            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _handleSourceSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                parent_entity: true
            },
            selectedSource: selectedOption
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    selectedSource: []

                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{PROPERTY_RESPONSE_TEMPLATES.VIEW_TITLE_RESPONSE_TEMPLATE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{PROPERTY_RESPONSE_TEMPLATES.EDIT_RESPONSE_TEMPLATE}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12} className="align-flex-end">

                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="template_name">{PROPERTY_RESPONSE_TEMPLATES.TEMPLATE_NAME} <span
                                    style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.template_name) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                    s={12}
                                    required
                                    name="template_name"
                                    onChange={this._handleInput}
                                    value={this.state.template_name}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                            <TatvamCol m={12} xl={6}>
                                <label htmlFor="source_id">{PROPERTY_RESPONSE_TEMPLATES.SOURCE_NAME}</label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className="validate select-ht "
                                    value={this.state.selectedSource}
                                    name="source_id"
                                    id="source_id"
                                    onChange={this._handleSourceSelect}
                                    options={this.state.sourceListsOptions}
                                    placeholder={PROPERTY_DIMENSION.SELECT_PARENT_ENTITY}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12}>
                            <TatvamCol m={12} xl={12} className="textarea-input pt-2">
                                <label htmlFor="template">{PROPERTY_RESPONSE_TEMPLATES.TEMPLATE}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    required
                                    className={(this.state.inValidForm && !this.state.template) ? "firstItem labelText mb-0 validate txtarea-border mb-1 invalid" : "firstItem labelText mb-0 validate txtarea-border mb-1"}
                                    name="template"
                                    value={this.state.template}
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>
                        </TatvamCol>
                        <TatvamCol m={12}>

                            <TatvamCol s={6} className="switch-wrapper">
                                <Switch
                                    id="status"
                                    offLabel="Status"
                                    checked={this.state.status}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>

                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {PROPERTY_RESPONSE_TEMPLATES.VIEW_TEMPLATE_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.template_name}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4">{PROPERTY_RESPONSE_TEMPLATES.VIEW_SOURCE_NAME} :</span>
                                <span
                                    className="col s8 url-wrap">{this.state.selectedSource.label === PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE ? "" : this.state.selectedSource.label}</span>
                            </div>
                        </TatvamCol>

                        <TatvamCol m={12}>
                            <div className="col s12 p-0">
                                <span
                                    className="property-label col s2"> {PROPERTY_RESPONSE_TEMPLATES.VIEW_TEMPLATE} :</span>
                                <TatvamTextArea
                                    disabled
                                    value={this.state.template}
                                    className="mb-2"
                                    s={10}
                                />
                            </div>
                            <div className="  col s12 p-0">
                                <span className="property-label col s2"> {PROPERTY_DIMENSION.STATUS} :</span>
                                <span
                                    className="col s10">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                    <TatvamCol s={12} m={12} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                            this.props.rolePermission === APP_BOOLEANS.TRUE && (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            )}
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default CustomerResponseTemplateForm;
