import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    deleteCustomerRoles,
    fetchAllOperations,
    fetchAllReports,
    fetchCustomerRoles,
    fetchCustomerUser,
} from '../../../../../actions/index';
import Roles from './roles';

const mapStateToProps = (state, ownProps) => ({
    operationsList: state.operations.list,
    reportsList: state.reports.list,
    customer: state.customer.list[ownProps.currentCustomerId].roles,
    rolePermission: state.auth.permissions && state.auth.permissions.customer.roles.create,
    customerUsers: state.customer.list[ownProps.currentCustomerId].users,
    roleDelete: state.auth.permissions && state.auth.permissions.customer.roles.delete,
    customerRecord: state.customer.list[ownProps.currentCustomerId]
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchCustomerRoles,
            fetchAllReports,
            fetchAllOperations,
            fetchCustomerUser,
            deleteCustomerRoles
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
