import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchUserDetails, UserPermissions, UserSignIn} from "../../actions";
import SignIn from "./signIn";

const mapStateToProps = (state) => ({
    role: state.auth.profile && state.auth.profile.role
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            UserSignIn,
            UserPermissions,
            fetchUserDetails
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
