import './userDetails.scss';

import React, {Component, Fragment,} from 'react';

import {APPLICATION_MODES} from 'localConstants';
import {Col, Preloader, Row,} from 'react-materialize';

import {ALERT_MESSAGES} from '../../../../constants/appConstants';
import {TatvamButton} from '../../../functionalComponents';
import AlertBox from '../../alertBox';
import UserTabs from '../tabs';
import UserForm from '../userForm';

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: {},
            preloader: false,
            applicationMode: ""
        };
        this.userName = "";
    }

    setId = (currentUser) => {
        this.setState({
            currentUser,
            applicationMode: APPLICATION_MODES.VIEW
        });
    };


    componentDidMount() {
        const userName = this.props.location.state ? (this.props.location.state.currentUser ? this.props.location.state.currentUser.user_name : "") : this.props.profile.user_name;
        if (this.props.location.state) {
            const currentUser = this.props.location.state.currentUser;

            this.setState({
                currentUser,
                ...currentUser,
                applicationMode: this.props.location.state.applicationMode
            });
        } else if (this.props.profile.role === "User" || this.props.profile.role === "Admin" || this.props.profile.role === "TatvamAdmin") {

            this.setState({
                currentUser: this.props.profile,
                applicationMode: APPLICATION_MODES.VIEW
            });
        } else {
            this.setState({
                applicationMode: APPLICATION_MODES.CREATE
            });
        }
    }

    setEditFlag = (statusBool) => {
        this.setState({editFlag: statusBool});
    };

    _goBack = () => {
        if (this.state.editFlag) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                notDiscard: false

            });
        } else {
            this.props.history.goBack();
        }
    };
    _discard = data => {
        if (data === "ok") {
            this.props.history.goBack();
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    componentWillReceiveProps(props) {
        if (props.location.state) {
            const currentUser = props.location.state.currentUser;
            this.setState({
                currentUser,
                ...currentUser,
                applicationMode: props.location.state.applicationMode
            });
        } else if (props.profile.role === "User" || props.profile.role === "Admin" || props.profile.role === "TatvamAdmin") {
            this.setState({
                currentUser: props.profile,
                applicationMode: APPLICATION_MODES.VIEW
            });
        } else {
            this.setState({
                applicationMode: APPLICATION_MODES.CREATE
            });
        }

    }

    currentUserFormState = (currentState) => {
        this.setState({
            userFormMode: currentState
        })
    }

    render() {
        return (
            <Fragment>

                <Row className="create-project-page">
                    <Row>
                        <Col
                            s={12}
                            className={this.state.preloader ? "valign-wrapper leftzero loader-overlay-view" : "hide"}
                        >
                            <Preloader className="spinner" size="big" active={this.state.preloader}/>
                        </Col>
                        <Col className="right">
                            <TatvamButton
                                waves="light"
                                onClick={this._goBack}
                                className="goBackBtn btn_primary"
                                title="go back "
                            >
                                <i className="material-icons">arrow_back</i>
                            </TatvamButton>
                        </Col>
                        <UserForm
                            applicationMode={this.state.applicationMode}
                            history={this.props.history}
                            user={this.state.currentUser && this.state.currentUser.user_name}
                            setId={this.setId}
                            editFlag={this.setEditFlag}
                            currentUserFormState={this.currentUserFormState}
                            key={this.state.currentUser && this.state.currentUser.user_name}
                        />
                    </Row>
                    <Row>
                        {this.state.currentUser && this.state.currentUser.user_name && (
                            <Col className="z-depth-2 col-centered mt-3 mb-3 p-0 tabsContainer" s={12} m={12} l={12}
                                 xl={12}>
                                <div class={this.state.userFormMode === "EDIT" ? "disabledTabs" : ""}></div>
                                <UserTabs
                                    currentUserData={this.state.currentUser}
                                    currentUserName={this.state.currentUser && this.state.currentUser.user_name}
                                    applicationMode={this.state.applicationMode}
                                    history={this.props.history}
                                />
                            </Col>
                        )}
                    </Row>
                </Row>
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        isConfirmBox={this.state.isConfirmBox}
                        closeAlertBox={this._discard}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default UserDetails;
