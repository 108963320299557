import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';

import {ALERT_MESSAGES, PROPERTY_PREDICTION_INFLUENCER,} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents';
import AlertBox from '../../../alertBox';
import PredictionInfluencerForm from '../../../predictionInfluencerForm';

class PredictionInfluencer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            predictionInfluencerModal: false,
            loader: true,
            columnDefination: [
                {
                    headerName: PROPERTY_PREDICTION_INFLUENCER.INFLUENCER_NAME,
                    field: "influencer_name",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY_PREDICTION_INFLUENCER.INFLUENCER_DESCRIPTION,
                    field: "influencer_desc",
                    filterParams: {clearButton: true}
                },
                {headerName: PROPERTY_PREDICTION_INFLUENCER.STATUS, field: "status", filterParams: {clearButton: true}},
            ]
        }
    }

    componentDidMount() {
        this.setState({
            loader: true
        });
        let influencersGridData = []
        this.props.actions.fetchPredictionInfluencers(this.props.currentCustomerId).then(() => {
            this.setState({
                loader: false
            });
        });


        if (this.props.influencerData) {
            this.props.influencerData.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        influencersGridData.push(data);
                    }
                }
            });
        }
        this.setState({
            influencersGridData
        }, () => {

            this.setActiveCount();
        });
    }

    componentWillReceiveProps(props) {
        let influencersGridData = [];
        if (props.influencerData) {
            props.influencerData.map((data) => {
                if (data.status) {
                    let status = trimCapitalize(data.status);
                    if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                        influencersGridData.push(data);
                    }

                }
            });
        }
        this.setState({
            influencersGridData
        }, () => {

            this.setActiveCount();
        });
    }

    _addPredictionInfuencer = () => {
        this.setState({
            selectedInfluencerData: [],
            applicationMode: APPLICATION_MODES.CREATE,
            predictionInfluencerModal: true,
            title: PROPERTY_PREDICTION_INFLUENCER.ADD_INFLUENCER_TITLE
        }, () => {
            this.setState({
                predictionInfluencerModal: true
            });
        })
    };

    closeModal = () => {
        this.setState({
            predictionInfluencerModal: false
        });
    };

    setActiveCount = () => {
        const gridData = this.state.influencersGridData;
        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
        this.props.countPropagate({'predictionInfluencer': {'active': noOfActive.length, 'total': gridData.length}});
    };

    _openViewModal = (data) => {
        this.setState({
            selectedInfluencerData: data[0],
            applicationMode: APPLICATION_MODES.VIEW,
            predictionInfluencerModal: false,
            title: ""
        }, () => {
            this.setState({
                predictionInfluencerModal: true
            });
        })
    };

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.customer_id,
            id: data.influencer_id,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };

    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let id = this.state.id;
            this.props.actions.deletePredictionInfluencer(customer_id, id).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {
                    let newGridData = this.state.influencersGridData.filter((data) => data.id !== id);
                    this.setState({
                        influencersGridData: newGridData
                    })
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });
                }
            })
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    };
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    render() {
        return (
            <Fragment>
                {this.props.responsePermission === APP_BOOLEANS.TRUE && (
                    < TatvamButton
                        className="mt-1 ml-2 mr-2 right btn_primary"
                        name="addRole"
                        onClick={this._addPredictionInfuencer}
                    >
                        {PROPERTY_PREDICTION_INFLUENCER.ADD_INFLUENCER_TITLE}
                    </TatvamButton>
                )}

                {this.state.predictionInfluencerModal && (
                    <TatvamModal
                        open={this.state.predictionInfluencerModal}
                        className="hideFooter"
                        header={this.state.title}
                        options={{dismissible: false}}
                        fixedFooter={false}
                        children={(
                            <PredictionInfluencerForm
                                selectedInfluencerData={this.state.selectedInfluencerData}
                                currentCustomerId={this.props.currentCustomerId}
                                closeModal={this.closeModal}
                                title={this.state.title}
                                operationsList={this.props.operationsList}
                                reportsList={this.props.reportsList}
                                applicationMode={this.state.applicationMode}
                            />
                        )}
                    />
                )}

                {this.state.influencersGridData && this.state.influencersGridData.length > 0 ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.influencersGridData}
                            colData={this.state.columnDefination}
                            enableRowClick
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.responseDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                        />

                    </TatvamCol>
                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {"No Prediction Influencer records available"}
                        </h5>
                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        )
    }
}

export default PredictionInfluencer;
