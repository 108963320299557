import React from 'react';

import {Col, Row,} from 'react-materialize';

import {APPLICATION_MODES} from '../../../constants/localConstants';

class TatvamDynamicForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: []
        };
    }

    _handleInputs = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            },
            editFlag: {
                [e.target.name]: true
            }
        });
    };
    _handleSubmit = e => {
        e.preventDefault();
        this.props.handleSubmit(this.state.formData);
    };
    _triggerDiscard = () => {
        this.props.triggerDiscard(this.state.editFlag);
    };
    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    componentDidMount() {
        this.setState({
            applicationMode: this.props.applicationMode,
            formData: {
                ...this.props.formData
            }
        });
    }

    render() {
        return (
            <Row className="ml-8">
                <h6>{this.props.formTitle} </h6>
                <form onSubmit={this._handleSubmit}>
                    <Col s={12}>
                        {Object.keys(this.state.formData).map(data => (
                            <div className="d-flex valign-wrapper">
                                <label className="col s3  mt-3">{data}</label>
                                <label className="col s1">:</label>
                                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                    <input
                                        type="text"
                                        className="col s7"
                                        value={this.state.formData[data]}
                                        name={data}
                                        disabled={this.props.fromDisabled}
                                        onChange={this._handleInputs}
                                    />
                                ) : (
                                    <p className="col s7">
                                        {this.state.formData[data]}
                                    </p>
                                )}
                            </div>
                        ))}
                    </Col>
                </form>
            </Row>
        );
    }
}

export default TatvamDynamicForm;
