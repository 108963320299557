import React, {Component, Fragment,} from 'react';

import {APP_BOOLEANS, APPLICATION_MODES,} from 'localConstants';

import {ALERT_MESSAGES, PROPERTY_ROLES,} from '../../../../../constants/appConstants';
import {trimCapitalize} from '../../../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamGrid, TatvamModal,} from '../../../../functionalComponents/index';
import AlertBox from '../../../alertBox';
import RoleForm from '../../../roleForm';

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleModal: false,
            rolesGridData: [],
            loader: true,
            colDef: [
                {headerName: PROPERTY_ROLES.VIEW_NAME, field: "name", filterParams: {clearButton: true}},
                {
                    headerName: PROPERTY_ROLES.VIEW_OPERATIONS,
                    field: "enabled_operations",
                    filterParams: {clearButton: true}
                },
                {
                    headerName: PROPERTY_ROLES.VIEW_REPORTS,
                    field: "enabled_reports",
                    filterParams: {clearButton: true},
                    cellRenderer: function (param) {
                        const reportsCell = param.value.map((item) => {
                            return item.name
                        })
                        return "" + reportsCell.toString() + "";
                    }
                },
                {headerName: PROPERTY_ROLES.STATUS, field: "status", filterParams: {clearButton: true}}]
        };
        this.roles = "";
    }

    _handleDeleteConfirmation = (data) => {
        this.setState({
            idCustomer: data.customer_id,
            name: data.name,
            isConfirmBox: true,
            isAlertBoxOpen: false,
            isDeleteAlertBoxOpen: true,
            isSuccess: false,
            alert_information: ALERT_MESSAGES.DELETE_CONFIRMATION
        });
    };
    _deleteRow = (data) => {
        if (data === "ok") {
            let customer_id = this.state.idCustomer;
            let name = this.state.name;
            let roleDeleteData = {
                customer_id,
                name

            };
            this.props.actions.deleteCustomerRoles(roleDeleteData).then((response) => {
                this.setState({
                    preLoader: false
                });

                if (response.status === 200) {
                    this.setState({
                        shouldCloseModal: true,
                        isAlertBoxOpen: true,
                        isSuccess: true,
                        alert_information: response.data.message
                    });
                } else {
                    this.setState({
                        shouldCloseModal: false,
                        isAlertBoxOpen: true,
                        isSuccess: false,
                        alert_information: response.data ? response.data.message : response.message
                    });

                }
            });
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false,
            isDeleteAlertBoxOpen: false
        });
    }
    _discard = (data) => {
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _addRole = () => {
        this.setState({
            selectedRoleData: [],
            applicationMode: APPLICATION_MODES.CREATE,
            roleModal: true,
            title: PROPERTY_ROLES.ADD_ROLE,
            isCorporate: this.props.currentCustomer ? this.props.currentCustomer.corporate : "No",
            customerCategory: this.props.currentCustomer ? this.props.currentCustomer.category : "",
            is_campaignable: this.props.customerRecord ? this.props.customerRecord.is_campaignable : "No",
            is_action_trackable: this.props.customerRecord ? this.props.customerRecord.is_action_trackable : "No",
            is_response_trackable: this.props.customerRecord ? this.props.customerRecord.is_response_trackable : "No",
            is_source_weightage: this.props.customerRecord ? this.props.customerRecord.is_source_weightage : "No",
            customerInformation: this.props.currentCustomer ? this.props.currentCustomer : null
        }, () => {
            this.setState({
                roleModal: true
            });
        });
    };
    closeModal = () => {
        this.setState({
            roleModal: false
        });
    };
    _openViewModal = data => {
        this.setState({
            selectedRoleData: data[0],
            applicationMode: APPLICATION_MODES.VIEW,
            roleModal: false,
            title: "",
            isCorporate: this.props.currentCustomer ? this.props.currentCustomer.corporate : "No",
            customerCategory: this.props.currentCustomer ? this.props.currentCustomer.category : "",
            is_campaignable: this.props.customerRecord ? this.props.customerRecord.is_campaignable : "No",
            is_action_trackable: this.props.customerRecord ? this.props.customerRecord.is_action_trackable : "No",
            is_response_trackable: this.props.customerRecord ? this.props.customerRecord.is_response_trackable : "No",
            is_source_weightage: this.props.customerRecord ? this.props.customerRecord.is_source_weightage : "No",
            customerInformation: this.props.currentCustomer ? this.props.currentCustomer : null
        }, () => {
            this.setState({
                roleModal: true
            });
        });
    };


    fetchCustomerUsers = () => {
        this.props.actions.fetchCustomerUser(this.props.currentCustomer.id).then(() => {
            let usersGridData = [];
            if (this.props.customerUsers) {
                this.props.customerUsers.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                            usersGridData.push(data);
                    }
                });
                this.setState({
                    usersGridData
                }, () => {
                    const gridDataUsers = this.state.usersGridData;
                    const noOfActiveUsers = gridDataUsers.filter(eachItem => eachItem.status === 'Active');
                    this.props.countPropagate({
                        'userSource': {
                            'active': noOfActiveUsers.length,
                            'total': gridDataUsers.length
                        }
                    });
                });
            }
        });

    };

    fetchCustomerProperty = () => {
        if (this.props.currentCustomer && this.props.currentCustomer.children) {
            this.props.countPropagate({'propertySource': {'total': this.props.currentCustomer.children.length}});
        }
    };

    componentDidMount() {
        this.setState({
            loader: true
        });
        this.props.actions.fetchCustomerRoles(this.props.currentCustomerId).then(() => {
            this.props.actions.fetchAllReports().then(() => {
                this.props.actions.fetchAllOperations().then(() => {
                    this.setState({
                        loader: false
                    });
                    let rolesGridData = [];
                    if (this.props.customer) {
                        this.props.customer.map((data) => {
                            if (data.status) {
                                let status = trimCapitalize(data.status);
                                if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {

                                    if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && this.props.currentCustomer.is_inapp) {
                                        rolesGridData.push(data);
                                    }
                                    if (data.application_name === APPLICATION_MODES.PREDICTO && this.props.currentCustomer.is_predicto) {
                                        rolesGridData.push(data);
                                    }
                                    if (data.application_name === APPLICATION_MODES.BLMS && this.props.currentCustomer.is_blms) {
                                        rolesGridData.push(data);
                                    }
                                }
                            }
                        });
                    }
                    this.setState({
                        rolesGridData
                    }, () => {

                        let gridData = [];
                        if (this.props.currentCustomer.is_inapp && this.props.currentCustomer.is_predicto) {
                            gridData = this.state.rolesGridData;
                        } else if (this.props.currentCustomer.is_inapp) {
                            gridData = this.state.rolesGridData.filter(eachItem => eachItem.application_name === APPLICATION_MODES.TATVAM_INAPP);
                        } else if (this.props.currentCustomer.is_predicto) {
                            gridData = this.state.rolesGridData.filter(eachItem => eachItem.application_name === APPLICATION_MODES.PREDICTO);
                        } else if (this.props.currentCustomer.is_blms) {
                            gridData = this.state.rolesGridData.filter(eachItem => eachItem.application_name === APPLICATION_MODES.BLMS);
                        }
                        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
                        this.props.countPropagate({
                            'roleSource': {
                                'active': noOfActive.length,
                                'total': gridData.length
                            }
                        });
                    });
                });
            });
        });
    }

    componentWillReceiveProps(props) {
        let rolesGridData = [];
        if (props.customer) {
            if (Array.isArray(props.customer)) {
                props.customer.map((data) => {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.currentCustomer.is_inapp) {
                                rolesGridData.push(data);
                            }
                            if (data.application_name === APPLICATION_MODES.PREDICTO && props.currentCustomer.is_predicto) {
                                rolesGridData.push(data);
                            }
                            if (data.application_name === APPLICATION_MODES.BLMS && props.currentCustomer.is_blms) {
                                rolesGridData.push(data);
                            }
                        }

                    }
                });
            } else {
                for (let data of props.customer) {
                    if (data.status) {
                        let status = trimCapitalize(data.status);
                        if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE) {
                            if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.currentCustomer.is_inapp) {
                                rolesGridData.push(data);
                            }
                            if (data.application_name === APPLICATION_MODES.PREDICTO && props.currentCustomer.is_predicto) {
                                rolesGridData.push(data);
                            }
                            if (data.application_name === APPLICATION_MODES.BLMS && props.currentCustomer.is_blms) {
                                rolesGridData.push(data);
                            }
                        }

                    }
                }
            }

            this.setState({
                rolesGridData
            }, () => {

                const gridData = this.state.rolesGridData;
                const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
                this.props.countPropagate({'roleSource': {'active': noOfActive.length, 'total': gridData.length}});
            });
        } else {
            this.props.actions.fetchCustomerRoles(props.currentCustomerId).then(() => {
                if (props.customer) {
                    props.customer.map((data) => {
                        if (data.status) {
                            let status = trimCapitalize(data.status);
                            if (status === APPLICATION_MODES.ACTIVE || status === APPLICATION_MODES.INACTIVE)
                                if (data.application_name === APPLICATION_MODES.TATVAM_INAPP && props.currentCustomer.is_inapp) {
                                    rolesGridData.push(data);
                                } else if (data.application_name === APPLICATION_MODES.PREDICTO && props.currentCustomer.is_predicto) {
                                    rolesGridData.push(data);
                                } else if (data.application_name === APPLICATION_MODES.BLMS && props.currentCustomer.is_blms) {
                                    rolesGridData.push(data);
                                }
                        }
                    });

                    this.setState({
                        rolesGridData
                    }, () => {

                        const gridData = this.state.rolesGridData;
                        const noOfActive = gridData.filter(eachItem => eachItem.status === 'Active');
                        this.props.countPropagate({
                            'roleSource': {
                                'active': noOfActive.length,
                                'total': gridData.length
                            }
                        });
                    });
                }

            });

        }

    }

    render() {
        return (
            <Fragment>
                {this.props.rolePermission === APP_BOOLEANS.TRUE && (
                    < TatvamButton
                        className="mt-1 ml-2 mr-2 right btn_primary"
                        name="addRole"
                        onClick={this._addRole}
                    >
                        {PROPERTY_ROLES.ADD_ROLE}
                    </TatvamButton>
                )}

                {
                    this.state.roleModal && (
                        <TatvamModal
                            open={this.state.roleModal}
                            className="hideFooter"
                            header={this.state.title}
                            options={{dismissible: false}}
                            fixedFooter={false}
                            children={(
                                <RoleForm
                                    roleData={this.state.selectedRoleData}
                                    currentCustomerId={this.props.currentCustomerId}
                                    closeModal={this.closeModal}
                                    title={this.state.title}
                                    operationsList={this.props.operationsList}
                                    reportsList={this.props.reportsList}
                                    applicationMode={this.state.applicationMode}
                                    isCorporate={this.state.isCorporate}
                                    customerCategory={this.state.customerCategory}
                                    is_campaignable={this.state.is_campaignable}
                                    is_action_trackable={this.state.is_action_trackable}
                                    is_response_trackable={this.state.is_response_trackable}
                                    is_source_weightage={this.state.is_source_weightage}
                                    customerInformation={this.state.customerInformation}
                                />
                            )}
                        />

                    )}

                {this.state.rolesGridData && this.state.rolesGridData.length > 0 ? (
                    <TatvamCol s={12} className="roleGrid grid-data-area">
                        <TatvamGrid
                            gridData={this.state.rolesGridData}
                            colData={this.state.colDef}
                            enableRowClick
                            onRowSelect={this._openViewModal}
                            enableDeleteColumn={this.props.roleDelete === APP_BOOLEANS.TRUE}
                            onRowDelete={this._handleDeleteConfirmation}
                        />

                    </TatvamCol>
                ) : this.state.loader ? (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            Loading...
                        </h5>

                    </TatvamCol>
                ) : (
                    <TatvamCol s={12} className="grid-empty-area">
                        <h5>
                            {PROPERTY_ROLES.NO_ROLES_AVAILABLE}
                        </h5>

                    </TatvamCol>
                )}
                {this.state.isAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._discard}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
                {this.state.isDeleteAlertBoxOpen && (
                    <AlertBox
                        open={this.state.isDeleteAlertBoxOpen}
                        isSuccess={this.state.isSuccess}
                        closeAlertBox={this._deleteRow}
                        isConfirmBox={this.state.isConfirmBox}
                        alert_information={this.state.alert_information}
                    />
                )}
            </Fragment>
        );
    }
}

export default Roles;
