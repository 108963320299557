import React from "react";
import {BaseCol} from "../../baseComponents";

const TatvamCol = (props) => {
    return (
        <BaseCol {...props} />
    )
}

export default TatvamCol;
