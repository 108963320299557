import axios from "axios";
import {apiUrl} from "apiConstants";


export const getCustomerUsageLogDetails = (inputPyalod) => (dispatch) => {
    const payload = inputPyalod;
    const url = apiUrl.BASE_URL + apiUrl.CUSTOMER_USAGE_LOG;

    return axios
        .post(url, payload)
        .then(response => {
                return response;
            }
        )
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const getCustomerUsersLogDetails = (inputPyalod) => (dispatch) => {
    const payload = inputPyalod;
    const url = apiUrl.BASE_URL + apiUrl.CUSTOMER_USER_USAGE_LOG;

    return axios
        .post(url, payload)
        .then(response => {
                return response;
            }
        )
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const getCustomerReportsLogDetails = (inputPyalod) => (dispatch) => {
    const payload = inputPyalod;
    const url = apiUrl.BASE_URL + apiUrl.CUSTOMER_REPORTS_USAGE_LOG;

    return axios
        .post(url, payload)
        .then(response => {
                return response;
            }
        )
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const getCustomerUsersReportsLogDetails = (inputPyalod) => (dispatch) => {
    const payload = inputPyalod;
    const url = apiUrl.BASE_URL + apiUrl.CUSTOMER_USER_REPORTS_USAGE_LOG;

    return axios
        .post(url, payload)
        .then(response => {
                return response;
            }
        )
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const getReportsUsageLogDetails = (inputPyalod) => (dispatch) => {
    const payload = inputPyalod;
    const url = apiUrl.BASE_URL + apiUrl.REPORTS_USAGE_LOG;

    return axios
        .post(url, payload)
        .then(response => {
                return response;
            }
        )
        .catch(error => {
            return Promise.resolve(error.response);
        });
};