import axios from 'axios';

const HttpMethodType = {
    Delete: 'DELETE',
    Get: 'GET',
    Post: 'POST',
    Patch: 'PATCH',
}


export class APIService {
    _account;

    constructor(account) {
        this._account = account;

        this._headers['Accept'] = 'application/json';
        this._headers['Content-Type'] = 'application/json';
        if (this._account.authToken) {
            this._headers['Authorization'] = `Bearer ${this._account.authToken}`;
        }
    }

    _headers = {};

    get headers() {
        return this._headers;
    }

    _method = HttpMethodType.Post;

    set method(newMethod) {
        this._method = newMethod;
    }

    _responseType = '';

    get responseType() {
        return this._responseType;
    }

    set responseType(value) {
        this._responseType = value;
    }

    get authToken() {
        return this._account.authToken;
    }

    set authToken(newAuthToken) {
        this._account.authToken = newAuthToken;
    }

    request(body) {
        return {
            headers: this._headers,
            method: this._method.toString(),
            params: body,
        };
    }

    send(url, config) {
        if (!config || !config.headers) {
            config = {headers: this._headers};
        }

        if (this.responseType) {
            config['responseType'] = this.responseType;
        }

        switch (this._method) {
            case HttpMethodType.Get: {
                return this.get(url, config);
            }
            case HttpMethodType.Post: {
                return this.post(url, config);
            }
            case HttpMethodType.Patch: {
                return this.patch(url, config);
            }
            case HttpMethodType.Delete: {
                return this.delete(url, config);
            }
            default: {
                throw new Error('Not Implemented');
            }
        }
    }

    populateRequestConfig(config) {
        if (!config) {
            return undefined;
        }

        let requestConfig = {};
        if (config.headers) {
            requestConfig.headers = config.headers;
        }
        if (config.baseURL) {
            requestConfig.baseURL = config.baseURL;
        }

        if (config.responseType) {
            requestConfig.responseType = config.responseType;
        }
        return requestConfig;
    }

    delete(url, config) {
        return axios.delete(url, this.populateRequestConfig(config));
    }

    get(url, config) {
        return axios.get(url, this.populateRequestConfig(config));
    }

    post(url, config) {
        return axios.post(url, config.params, this.populateRequestConfig(config));
    }

    patch(url, config) {
        return axios.patch(url, config.params, this.populateRequestConfig(config));
    }

}

export default APIService;
