import moment from "moment";
import dateUtil from "./dateUtil";


export function populateCsvHeader(customer, applicationName, periodicityRangeRequired, startDate, endDate) {
    const headers = [];
    const periodicityRanges = periodicityRangeRequired;
    let dateFormat = "DD-MMM-YYYY";
    if (customer) {
        headers.push({key: 'Customer Name', value: customer});
    }

    if (applicationName) {
        headers.push({
            key: 'Application Name',
            value: applicationName
        })
    }

    if (periodicityRanges === false) {
        headers.push({key: 'Exported On', value: dateUtil.converToDate(new Date(), "DD-MMM-YYYY")});
    } else {
        if (startDate) {
            headers.push({key: 'Period From', value: moment(startDate).format(dateFormat)})
        }
        if (endDate) {
            headers.push({key: 'Period Upto', value: moment(endDate).format("DD-MMM-YYYY")})
        }
        headers.push({key: 'Exported On', value: dateUtil.converToDate(new Date(), "DD-MMM-YYYY")});
    }
    return headers;
}


